import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.dataTestId]
 * @param {Object} props.children
 * @param {string} props.label
 * @param {Function} [props.onClose]
 * @param {boolean} props.open
 * @param {string} [props.buttonText]
 * @param {boolean} [props.showButtons]
 */
export default function AlertDialog({
  'data-testid': dataTestId = undefined,
  children,
  label,
  onClose = () => {},
  open,
  buttonText = 'OK',
  showButtons = true,
}) {
  return (
    <Dialog
      data-testid={dataTestId}
      open={open}
      onClose={onClose}
      disableScrollLock
      maxWidth="xs"
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, px: 4 }}
      >
        {label}
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" sx={{ mx: 4 }} />
      <DialogContent sx={{ py: 0, px: 4, maxWidth: '400px' }}>{children}</DialogContent>
      {showButtons && (
        <DialogActions
          disableSpacing
          sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}
        >
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mb: 2 }}
          >
            {buttonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
