import { useEffect, useRef, useState } from 'react'

import { Group } from 'react-konva'

import {
  HANDLE_HEIGHT,
  RECT_MARGIN,
  TIMELINE_GRID_END,
  TIMELINE_GRID_ORIGIN,
  VERTICAL_GRID_SPACE,
} from './constants'
import { DurationLabel, NRSLabel, SettingLabel } from './Labels'
import { BottomRect, TopRect } from './Rects'
import ResizeHandle from './ResizeHandle'
import {
  getDurationLabel,
  handleResizeWrappedSettingEnd,
  handleResizeWrappedSettingStart,
  hourToCanvasGridLine,
  roundToNearestGridLine,
  roundToNearestHour,
  setCursorStyle,
  settingToRectBounds,
} from './utils'
import { BottomHoverOverlayRect, TopHoverOverlayRect } from './WrappedHoverOverlayRect'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.id
 * @param {number} props.NRS
 * @param {number} props.start
 * @param {number} props.end
 * @param {number} [props.next]
 * @param {number} [props.previous]
 * @param {boolean} props.label
 * @param {boolean} props.color
 * @param {Function} props.onDelete
 * @param {Function} props.onEdit
 * @param {boolean} props.isDragging
 * @param {Function} props.onResizeStart
 * @param {Function} props.onResizeEnd
 * @param {Function} props.onDragStart
 * @param {Function} props.onDragEnd
 * @param {boolean} props.isEditing
 * @param {number|string} [props.editingId]
 * @param {number} [props.editorWidth]
 */
export default function WrappedThresholdRect({
  id,
  NRS,
  start,
  end,
  label,
  color,
  next = null,
  previous = null,
  onDelete,
  onEdit,
  onResizeStart,
  onResizeEnd,
  onDragStart,
  onDragEnd,
  isDragging,
  editingId = null,
  isEditing,
  editorWidth = undefined,
}) {
  const { top, bottom } = settingToRectBounds(start, end)
  const durationLabel = getDurationLabel(start, end)

  const bottomLabelsRef = useRef(null)
  const topLabelsRef = useRef(null)
  const bottomRectRef = useRef(null)
  const topRectRef = useRef(null)
  const startResizeHandleRef = useRef(null)
  const endResizeHandleRef = useRef(null)
  const topOverlayRectRef = useRef(null)
  const bottomOverlayRectRef = useRef(null)
  const touchTimeoutRef = useRef()

  const [isFocused, setIsFocused] = useState(false)

  const handleMouseEnter = (e) => {
    setIsFocused(true)
    setCursorStyle(e, 'move')
  }
  const handleMouseLeave = (e) => {
    setIsFocused(false)
    setCursorStyle(e, 'default')
  }

  const handleLongPress = (e) => {
    setIsFocused(true)
    setCursorStyle(e, 'move')
  }

  const handleTouchStart = (e) => {
    touchTimeoutRef.current = setTimeout(() => {
      handleLongPress(e)
    }, 500)
  }

  const handleTouchEnd = (e) => {
    clearTimeout(touchTimeoutRef.current)
    touchTimeoutRef.current = setTimeout(() => {
      handleMouseLeave(e)
    }, 2000)
  }

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => clearTimeout(touchTimeoutRef.current)
  }, [])

  const opacity = (isEditing && editingId === id) || !isEditing ? 1 : 0.5

  /**
   *
   * @param {{x,y}} pos coordinates of top-left corner of dragged segment
   * @returns destination coordinates
   */
  const handleDragTop = ({ y }) => {
    const topRect = topRectRef.current
    const topOverlayRect = topOverlayRectRef.current
    const bottomRect = bottomRectRef.current
    const bottomLabels = bottomLabelsRef.current
    const height = topOverlayRect.getAttr('height')
    const otherHeight = bottomRect.getAttr('height')
    const { y: currentTopOfOtherRect } = bottomRect.getAbsolutePosition()
    const { y: currentTopOfRect } = topOverlayRect.getAbsolutePosition()

    const shift = currentTopOfRect - y
    const newTopOfRect = VERTICAL_GRID_SPACE * Math.round(y / VERTICAL_GRID_SPACE)
    const newBottomOfRect = height + newTopOfRect + TIMELINE_GRID_ORIGIN - RECT_MARGIN
    // collision detection
    const topCollidesWithNextSetting =
      next !== null && newBottomOfRect >= hourToCanvasGridLine(next)
    const topCollidesWithCanvasTop =
      newBottomOfRect < TIMELINE_GRID_ORIGIN + VERTICAL_GRID_SPACE / 2
    const bottomCollidesWithPreviousSetting =
      previous !== null &&
      currentTopOfOtherRect - shift < hourToCanvasGridLine(previous)
    const bottomCollidesWithCanvasBottom =
      currentTopOfOtherRect - shift > TIMELINE_GRID_END - VERTICAL_GRID_SPACE
    if (
      topCollidesWithNextSetting ||
      bottomCollidesWithPreviousSetting ||
      bottomCollidesWithCanvasBottom ||
      topCollidesWithCanvasTop
    ) {
      return { x: 0, y: currentTopOfRect }
    }
    // update geometry
    if (newTopOfRect !== currentTopOfRect) {
      const changeInTop = currentTopOfRect - newTopOfRect
      bottomRect.setAttrs({
        y: currentTopOfOtherRect - changeInTop,
        height: otherHeight + changeInTop,
      })
      bottomLabels.setAttr('y', currentTopOfOtherRect - changeInTop)
      topRect.setAttrs({
        y: newBottomOfRect,
        height: topRect.getAttr('height') - changeInTop,
      })
      return { x: 0, y: newTopOfRect }
    }

    return { x: 0, y: currentTopOfRect }
  }

  /**
   *
   * @param {{x,y}} pos coordinates of top-left corner of dragged segment
   * @returns destination coordinates
   */
  const handleDragBottom = (pos) => {
    const bottomRect = bottomRectRef.current
    const bottomOverlayRect = bottomOverlayRectRef.current
    const topRect = topRectRef.current
    const bottomLabels = bottomLabelsRef.current
    const { x, y: currentTopOfRect } = bottomOverlayRect.getAbsolutePosition()
    const { y: currentTopOfOtherRect } = topRect.getAbsolutePosition()
    const otherHeight = topRect.getAttr('height')
    const shift = currentTopOfRect - pos.y
    // collision detection
    const bottomCollidesWithPreviousSetting =
      previous !== null && pos.y < hourToCanvasGridLine(previous)
    const topCollidesWithNextSetting =
      next !== null && currentTopOfOtherRect - shift > hourToCanvasGridLine(next)
    const bottomCollidesWithCanvasBottom =
      pos.y > TIMELINE_GRID_END - VERTICAL_GRID_SPACE
    const topCollidesWithCanvasTop =
      currentTopOfOtherRect - shift < TIMELINE_GRID_ORIGIN + VERTICAL_GRID_SPACE
    if (
      bottomCollidesWithPreviousSetting ||
      topCollidesWithNextSetting ||
      bottomCollidesWithCanvasBottom ||
      topCollidesWithCanvasTop
    ) {
      return { x, y: currentTopOfRect }
    }
    // update geometry
    const newTopOfRect = roundToNearestGridLine(pos.y)
    if (newTopOfRect !== currentTopOfRect) {
      const changeInTop = newTopOfRect - currentTopOfRect
      bottomRect.setAttrs({
        y: newTopOfRect + RECT_MARGIN,
        height: bottomRect.getAttr('height') - changeInTop,
      })
      topRect.setAttrs({
        y: currentTopOfOtherRect + changeInTop,
        height: otherHeight + changeInTop,
      })
      bottomLabels.setAttr('y', newTopOfRect)
      return { x, y: newTopOfRect }
    }
    return { x, y: currentTopOfRect }
  }

  const getStartAndEndFromRefs = () => {
    const getEndHourFromTopRect = (topRect) => {
      const currentBottomOfRect = topRect.getAbsolutePosition().y
      return roundToNearestHour(currentBottomOfRect)
    }
    const getStartHourFromBotttomRect = (bottomRect) => {
      const currentTopOfRect = bottomRect.getAbsolutePosition().y
      return roundToNearestHour(currentTopOfRect)
    }
    return [
      getStartHourFromBotttomRect(bottomRectRef.current),
      getEndHourFromTopRect(topRectRef.current),
    ]
  }
  return (
    <Group>
      <TopRect
        editorWidth={editorWidth}
        bottom={bottom}
        ref={topRectRef}
        color={color}
        opacity={opacity}
      />
      <Group ref={topLabelsRef}>
        <NRSLabel editorWidth={editorWidth} NRS={NRS} y={0} dy={end > 1 ? 9 : 7} />
        {end > 1 && (
          <DurationLabel
            editorWidth={editorWidth}
            label={durationLabel}
            y={0}
            dy={33}
          />
        )}
        <SettingLabel
          editorWidth={editorWidth}
          label={label}
          y={0}
          dy={end > 1 ? 15 : 7}
        />
      </Group>
      <TopHoverOverlayRect
        ref={topOverlayRectRef}
        editorWidth={editorWidth}
        bottom={bottom}
        visible={isFocused && !isEditing && !isDragging}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onEdit={onEdit}
        onDelete={onDelete}
        draggable={!isEditing}
        onDragStart={onDragStart}
        dragBoundFunc={handleDragTop}
        onDragEnd={() => onDragEnd(...getStartAndEndFromRefs())}
      />

      <BottomRect
        editorWidth={editorWidth}
        top={top}
        ref={bottomRectRef}
        color={color}
        opacity={opacity}
      />
      <Group ref={bottomLabelsRef} y={top}>
        <NRSLabel editorWidth={editorWidth} NRS={NRS} y={0} dy={start < 23 ? 9 : 7} />
        <SettingLabel
          editorWidth={editorWidth}
          label={label}
          y={0}
          dy={start < 23 ? 15 : 7}
        />
        {start < 23 && (
          <DurationLabel
            editorWidth={editorWidth}
            label={durationLabel}
            y={0}
            dy={33}
          />
        )}
      </Group>
      <BottomHoverOverlayRect
        ref={bottomOverlayRectRef}
        editorWidth={editorWidth}
        top={top}
        visible={isFocused && !isEditing && !isDragging}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onEdit={onEdit}
        onDelete={onDelete}
        draggable={!isEditing}
        onDragStart={onDragStart}
        dragBoundFunc={handleDragBottom}
        onDragEnd={() => onDragEnd(...getStartAndEndFromRefs())}
      />
      <ResizeHandle
        editorWidth={editorWidth}
        y={top}
        ref={startResizeHandleRef}
        draggable={!isEditing}
        onDragStart={onResizeStart}
        onDragEnd={(newStart) => onResizeEnd(newStart, end)}
        dragBoundFunc={(pos) =>
          handleResizeWrappedSettingStart(
            pos,
            startResizeHandleRef.current,
            endResizeHandleRef.current,
            bottomRectRef.current,
            bottomLabelsRef.current,
            previous,
          )
        }
      />
      <ResizeHandle
        editorWidth={editorWidth}
        y={bottom - HANDLE_HEIGHT}
        ref={endResizeHandleRef}
        draggable={!isEditing}
        onDragStart={onResizeStart}
        onDragEnd={(newEnd) => onResizeEnd(start, newEnd)}
        dragBoundFunc={(pos) =>
          handleResizeWrappedSettingEnd(
            pos,
            endResizeHandleRef.current,
            startResizeHandleRef.current,
            topRectRef.current,
            next,
          )
        }
      />
    </Group>
  )
}
