/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { equals } from 'ramda'

import { AxisRight } from '@visx/axis'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.scale === next.scale &&
    prev.hideAxisLine === next.hideAxisLine &&
    prev.hideTicks === next.hideTicks &&
    prev.numTicks === next.numTicks &&
    prev.tickValues === next.tickValues &&
    prev.tickFormat?.toString() === next.tickFormat?.toString() &&
    prev.left === next.left &&
    prev.orientation === next.orientation &&
    prev.label === next.label &&
    prev.labelOffset === next.labelOffset &&
    prev.stroke === next.stroke &&
    prev.tickStroke === next.tickStroke &&
    prev.tickLabelProps?.toString() === next.tickLabelProps?.toString() &&
    prev.graphWidth === next.graphWidth &&
    equals(prev.labelProps, next.labelProps) &&
    equals(prev.padding, next.padding)
  )
}

/**
 * 'graphWidth' and 'padding' not used directly in the component
 * but only in `propsAreEqual` function to properly rerender component
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Function} props.scale
 * @param {boolean} [props.hideAxisLine]
 * @param {boolean} [props.hideTicks]
 * @param {number} [props.numTicks]
 * @param {number[]} [props.tickValues]
 * @param {Function} [props.tickFormat]
 * @param {number} props.left
 * @param {string} props.orientation
 * @param {string} props.label
 * @param {Object} [props.labelProps]
 * @param {number} [props.labelOffset]
 * @param {string} [props.stroke]
 * @param {string} [props.tickStroke]
 * @param {Function} [props.tickLabelProps]
 * @param {number} [props.graphWidth]
 * @param {Object} [props.padding]
 */
const MemoAxisRight = memo(
  // eslint-disable-next-line no-unused-vars
  ({
    id,
    scale,
    hideAxisLine = false,
    hideTicks = false,
    numTicks = undefined,
    tickValues = undefined,
    tickFormat = undefined,
    left,
    orientation,
    label,
    labelProps = undefined,
    labelOffset = undefined,
    stroke = undefined,
    tickStroke = undefined,
    tickLabelProps = undefined,
    graphWidth = undefined,
    padding = undefined,
  }) => (
    <AxisRight
      key={id}
      orientation={orientation}
      hideAxisLine={hideAxisLine}
      hideTicks={hideTicks}
      numTicks={numTicks}
      tickValues={tickValues}
      tickFormat={tickFormat}
      left={left}
      scale={scale}
      label={label}
      labelProps={labelProps}
      labelOffset={labelOffset}
      stroke={stroke}
      tickStroke={tickStroke}
      tickLabelProps={tickLabelProps}
      pointerEvents="none"
    />
  ),
  propsAreEqual,
)

export default MemoAxisRight
