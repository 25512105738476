import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  AddOutlined,
  Delete,
  Edit,
  FileDownload,
  MoreVertRounded,
  People,
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'

import { MobileList, MobileListCardRow } from '@common/components'
import { getValueFromColumnDef } from '@common/components/MobileList'
import { downloadFile, useSmallScreen } from '@common/utils'
import { ConfirmationDialog, List } from '@rest/UI/components'
import { PrivilegeCheck } from '@rest/Utils'
import { formatCSVData } from '@rest/Utils/csv'

import PropertyUserForm from './PropertyUserForm'

function PropertyUsersEmptyState() {
  return (
    <Stack
      sx={{ mt: 5 }}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <People sx={{ fontSize: 60, mb: 2 }} />
      No users have been created for this property yet. Get started above.
    </Stack>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string} props.property.name
 * @param {boolean} props.property.liveSmokeAlerts
 * @param {string[]} [props.property.expandedFlags]
 */
export default function PropertyUsers({ property }) {
  const {
    propertyUserList,
    propertyUserListIsLoading,
    doMarkPropertyUserListAsOutdated,
    doPropertyUserListSetPage,
    doPropertyUserDelete,
    doPropertyUserListExport,
  } = useConnect(
    'selectPropertyUserList',
    'selectPropertyUserListIsLoading',
    'doMarkPropertyUserListAsOutdated',
    'doPropertyUserListSetPage',
    'doPropertyUserDelete',
    'doPropertyUserListExport',
  )

  const isSmallScreen = useSmallScreen()

  const [userFormOpen, setUserFormOpen] = useState(false)
  const [userDeleteOpen, setUserDeleteOpen] = useState(false)
  const [userFormInstance, setUserFormInstance] = useState(null)
  const [itemMenuData, setItemMenuData] = useState(null)

  useEffect(() => {
    doMarkPropertyUserListAsOutdated()
  }, [property])

  const columns = [
    { field: 'name', headerName: 'Name', flex: 0.25, sortable: false },
    { field: 'email', headerName: 'Email', flex: 0.35, sortable: false },
    { field: 'roleName', headerName: 'Role Name', flex: 0.25, sortable: false },
    {
      field: 'outageAlert',
      headerName: 'Outage Alerts',
      type: 'boolean',
      flex: 0.15,
      sortable: false,
      renderMobile: ({ row }) => (row.outageAlert ? 'Yes' : 'No'),
    },
    {
      field: 'noiseAlert',
      headerName: 'Noise Alerts',
      type: 'boolean',
      flex: 0.15,
      sortable: false,
      renderMobile: ({ row }) => (row.noiseAlert ? 'Yes' : 'No'),
    },
    {
      field: 'smokeAlert',
      headerName: 'Smoke Alerts',
      type: 'boolean',
      flex: 0.15,
      sortable: false,
      renderMobile: ({ row }) => (row.smokeAlert ? 'Yes' : 'No'),
    },
    {
      field: 'smokeDigest',
      headerName: 'Smoke Digest',
      type: 'boolean',
      flex: 0.15,
      sortable: false,
      renderMobile: ({ row }) => (row.smokeDigest ? 'Yes' : 'No'),
    },
    {
      field: 'smokePerformanceReport',
      headerName: 'Smoke Performance',
      type: 'boolean',
      flex: 0.15,
      sortable: false,
      renderMobile: ({ row }) => (row.smokePerformanceReport ? 'Yes' : 'No'),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <PrivilegeCheck
          permissions={['invite_property_user']}
          alternate={
            <GridActionsCellItem
              data-testid="edit_user_btn"
              icon={<Edit />}
              label="Edit"
              disabled
            />
          }
        >
          <GridActionsCellItem
            data-testid="edit_user_btn"
            icon={<Edit />}
            label="Edit"
            onClick={() => {
              setUserFormInstance({ ...params.row, id: params.row.userId })
              setUserFormOpen(true)
            }}
          />
        </PrivilegeCheck>,
        <PrivilegeCheck
          permissions={['invite_property_user']}
          alternate={
            <GridActionsCellItem
              data-testid="delete_user_btn"
              icon={<Delete />}
              label="Delete"
              disabled
            />
          }
        >
          <GridActionsCellItem
            data-testid="delete_user_btn"
            icon={<Delete />}
            label="Delete"
            onClick={() => {
              setUserFormInstance({ ...params.row, id: params.row.userId })
              setUserDeleteOpen(true)
            }}
          />
        </PrivilegeCheck>,
      ],
    },
  ]

  // remove smokeAlert column if not a live smoke alerts property
  if (!property.liveSmokeAlerts) {
    columns.splice(
      columns.findIndex((c) => c.field === 'smokeAlert'),
      1,
    )
  }

  // Remove noiseAlert column if the property doesn't have noise enabled
  if (!property.expandedFlags?.includes('NOISE')) {
    columns.splice(
      columns.findIndex((c) => c.field === 'noiseAlert'),
      1,
    )
  }

  const MobileListItem = useCallback(
    (row) => {
      const filteredColumns = columns.filter(
        (column) => !['name', 'actions'].includes(column.field),
      )
      return (
        <Stack spacing={0.5}>
          <Box display="flex" sx={{ '&&': { mb: 1 } }}>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontWeight="bold" fontSize={14}>
                {row.name}
              </Typography>
              <PrivilegeCheck permissions={['invite_property_user']}>
                <IconButton
                  key={row.id}
                  size="small"
                  onClick={(e) =>
                    setItemMenuData({ data: row, anchor: e.currentTarget })
                  }
                >
                  <MoreVertRounded fontSize="10" />
                </IconButton>
              </PrivilegeCheck>
            </Box>
          </Box>
          {filteredColumns.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return (
              <MobileListCardRow
                key={column.field}
                label={column.headerName}
                value={value}
              />
            )
          })}
        </Stack>
      )
    },
    [columns],
  )

  return (
    <>
      <Menu
        anchorEl={itemMenuData?.anchor}
        open={!!itemMenuData}
        onClose={() => setItemMenuData(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          dense
          onClick={() => {
            const { data } = itemMenuData
            setUserFormInstance({ ...data, id: data.userId })
            setUserFormOpen(true)
            setItemMenuData(null)
          }}
          sx={{ fontSize: 14 }}
        >
          <Edit sx={{ mr: 1, fontSize: 18 }} />
          Edit
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            const { data } = itemMenuData
            setUserFormInstance({ ...data, id: data.userId })
            setUserDeleteOpen(true)
            setItemMenuData(null)
          }}
          sx={{ fontSize: 14 }}
        >
          <Delete sx={{ mr: 1, fontSize: 18 }} />
          Remove
        </MenuItem>
      </Menu>

      <PropertyUserForm
        property={property}
        open={userFormOpen}
        instance={userFormInstance}
        onClose={(success) => {
          setUserFormOpen(false)
          if (success === true) {
            doMarkPropertyUserListAsOutdated()
          }
        }}
      />

      <ConfirmationDialog
        data-testid="delete_user_access_dialog"
        label="Remove Property Access"
        onClose={() => setUserDeleteOpen(false)}
        onSave={async () => {
          await doPropertyUserDelete({
            propertyId: property.id,
            id: userFormInstance.id,
          })
          doMarkPropertyUserListAsOutdated()
        }}
        open={userDeleteOpen}
        buttonText="Remove User"
        successMessage="Successfully removed user from property"
        formatError={() => 'Insufficient permissions to remove user'}
        isFormik={false}
      >
        <Typography variant="body1" sx={{ my: 2.5 }}>
          Please confirm you wish to remove access to <strong>{property.name}</strong>{' '}
          from <strong>{userFormInstance?.name}</strong>.
        </Typography>
      </ConfirmationDialog>

      <Stack direction="row-reverse" gap={1} mt={1.8}>
        <Button
          fullWidth={isSmallScreen}
          data-testid="export_events"
          variant="contained"
          onClick={async () => {
            const result = await doPropertyUserListExport()
            const data = formatCSVData(result, {
              name: 'Name',
              email: 'Email',
              phoneNumber: 'Phone',
              roleName: 'Role Name',
              smokeAlert: 'Smoke Alert',
              smokeDigest: 'Smoke Digest',
              smokePerformanceReport: 'Smoke Performance',
            })
            downloadFile({
              data: [data],
              fileName: `${property.name}_users_export.csv`,
              fileType: 'text/csv',
            })
          }}
          startIcon={<FileDownload />}
        >
          Export
        </Button>
        <PrivilegeCheck
          permissions={['invite_property_user']}
          alternate={
            <Button
              fullWidth={isSmallScreen}
              data-testid="new-user-btn"
              variant="contained"
              disabled
            >
              NEW USER
            </Button>
          }
        >
          <Button
            fullWidth={isSmallScreen}
            data-testid="new-user-btn"
            variant="contained"
            startIcon={<AddOutlined />}
            onClick={() => {
              setUserFormInstance(null)
              setUserFormOpen(true)
            }}
          >
            NEW USER
          </Button>
        </PrivilegeCheck>
      </Stack>
      {isSmallScreen ? (
        <MobileList
          data-testid="users_list"
          title="Users"
          getRowId={(row) => row.userId}
          rows={propertyUserList?.results ?? []}
          itemBuilder={MobileListItem}
          page={propertyUserList?.current ?? 0}
          rowCount={propertyUserList?.count ?? 0}
          pageChange={doPropertyUserListSetPage}
          pageSizeOptions={[25]}
          slots={{ noRowsOverlay: PropertyUsersEmptyState }}
          hideFooter={(propertyUserList?.numPages ?? 0) <= 1}
          loading={propertyUserListIsLoading}
        />
      ) : (
        <List
          autoHeight
          disableColumnMenu
          data-testid="users_list"
          getRowId={(row) => row.userId}
          columns={columns}
          rows={propertyUserList?.results ?? []}
          page={propertyUserList?.current ?? 0}
          rowCount={propertyUserList?.count ?? 0}
          pageChange={doPropertyUserListSetPage}
          pageSizeOptions={[25]}
          slots={{ noRowsOverlay: PropertyUsersEmptyState }}
          hideFooter={(propertyUserList?.numPages ?? 0) <= 1}
          loading={propertyUserListIsLoading}
        />
      )}
    </>
  )
}
