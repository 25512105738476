export const closeIconStyles = {
  position: 'absolute',
  top: 25,
  right: 25,
  zIndex: 1001,
}

export const exportButtonStyles = {
  position: 'absolute',
  top: 25,
  right: 75,
  zIndex: 1001,
}

export const canvasStyles = {
  position: 'relative',
  height: '8.5in',
  width: '11in',
  border: '1px solid #000',
  margin: '0 auto',
  padding: 25,
  paddingTop: 100,
}

export const headerStyles = {
  position: 'absolute',
  top: 25,
  right: 25,
  textAlign: 'right',
  fontSize: 16,
  lineHeight: 1.3,
}

export const propertyHeaderStyles = {
  position: 'absolute',
  width: 330,
  top: 25,
  left: 25,
  fontSize: 18,
}

export const titleStyles = {
  position: 'absolute',
  top: 35,
  left: '50%',
  transform: 'translateX(-50%)',
  lineHeight: 0.65,
  textAlign: 'center',
}
