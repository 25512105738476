import { forwardRef } from 'react'

import { Group } from 'react-konva'

import { noop } from '@common/utils'

import { DeleteButton, EditButton } from './Buttons'
import { RECT_HORIZONTAL_ORIGIN } from './constants'
import { getRectWidth, Rect } from './Rects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.visible
 * @param {number} props.top
 * @param {number} props.bottom
 * @param {Function} props.onMouseEnter
 * @param {Function} [props.onMouseLeave]
 * @param {Function} [props.onTouchStart]
 * @param {Function} [props.onTouchEnd]
 * @param {Function} props.onEdit
 * @param {Function} props.onDelete
 * @param {number} [props.editorWidth]
 */
const HoverRectOverlay = forwardRef(
  (
    {
      editorWidth = undefined,
      top,
      bottom,
      onMouseEnter,
      onMouseLeave = noop,
      onTouchStart = noop,
      onTouchEnd = noop,
      onEdit,
      onDelete,
      visible,
      ...props
    },
    ref,
  ) => {
    const color = visible ? '#757575D8' : '#FFFFFF00'
    const rectWidth = getRectWidth(editorWidth)

    return (
      <Group
        ref={ref}
        y={top}
        draggable
        {...props}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <Rect editorWidth={editorWidth} top={0} bottom={bottom - top} color={color} />
        {visible && (
          <>
            <EditButton
              onClick={onEdit}
              y={(bottom - top) / 2 - 16}
              x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 - (onDelete ? 70 : 25))}
            />
            {onDelete && (
              <DeleteButton
                onClick={onDelete}
                y={(bottom - top) / 2 - 16}
                x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 + 12)}
              />
            )}
          </>
        )}
      </Group>
    )
  },
)

export default HoverRectOverlay
