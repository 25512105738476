import { createEntityBundle } from '@common/utils'

const roleBundle = createEntityBundle({
  name: 'roles',
  // customActions: [
  //   {
  //     actionName: 'fetch_users',
  //     handler: ({ apiFetch, apiUrl, payload }) =>
  //       apiFetch(`/${apiUrl}/${payload}/users/`, {}, { method: 'GET' }),
  //   },
  //   {
  //     actionName: 'fetch_assignable',
  //     handler: ({ apiFetch, apiUrl, payload }) =>
  //       apiFetch(`/${apiUrl}/assignable/`, payload, { method: 'GET' }),
  //   },
  //   {
  //     actionName: 'fetch_assignable',
  //     handler: ({ apiFetch, apiUrl, payload }) =>
  //       apiFetch(`/${apiUrl}/assignable`, payload, { method: 'GET' }),
  //   },
  // ],
})

export default roleBundle
// export default {
//   ...roleBundle,
//   selectRoleUsers: createSelector(
//     'selectRole',
//     (role) => role?.fetch_users?.response?.results,
//   ),
//   selectAssignableRoles: createSelector(
//     'selectRoleFetchAssignableResponse',
//     (rolesAssignable) => rolesAssignable || [],
//   ),
// }
