const faqData = {
  'Summary of Smoking Violation Report':
    'This report has been generated based on air quality data collected from the specific hotel room during this reservation. The data indicates that smoking occurred during this period.',
  'Sensor Accuracy':
    'This hotel room is using air quality sensors to monitor for smoking violations. The air quality sensors used in this room are self-cleaning and calibrated according to the manufacturer’s specifications. The sensors are designed to monitor for air quality irregularities consistent with smoking activities, including but not limited to marijuana, tobacco, vaporizer, e-cigarettes or other smoking products. The sensor was verified as functioning accurately during the period on this report.',
  'Data on this Report':
    "The air quality sensor continuously monitors the environment, and once enough evidence is gathered to confidently indicate smoking activity, a report is generated. It can take up to 30 minutes from the start of smoking for the sensor to detect a significant change in air quality, depending on the sensor's location in the room and air movement before, during, and after the smoking event. Therefore, the time is always an approximate estimate. The data, including the referenced Air Quality Index (AQI), aligns with patterns typically associated with smoking activities such as marijuana, tobacco, vaporizers, e-cigarettes, or other similar smoke-producing products.",
  'Additional Information on Detection':
    'The sensor conducted comprehensive checks on: humidity, particulate matter, and Total Volatile Organic Compounds (TVOCs) to rule out interference from non-smoking related sources, such as steam, moisture, or aerosol products.',
  'What is not detected':
    'The sensor will not trigger alerts for air quality changes caused by showering activities, hair products, sprays, perfumes, colognes or other aerosol and gas-emitting products.',
  'In Summary':
    'The data on this report indicates that the sensor located in the specified room detected a smoking violation at or around the indicated time and day.',
}

const getFooterText = (propertyName) => `The smoke detection technology used by ${
  propertyName || '--'
} measures
      several aspects of air quality in guest rooms. Measurements from sensors
      are passed to a proprietary algorithm that has been verified by rigorous
      statistical testing. The algorithm is specifically designed to detect
      combusted or vaporized tobacco, marijuana, nicotine products, and
      e-cigarette devices.`

const dataQualityBasicNameMapping = {
  mc_2p5_mean: 'PM 2.5',
  mc_10p0_mean: 'PM 10.0',
  smoke_index: 'Smoke Index',
  temp_mean: 'Temperature',
  hum_mean: 'Humidity',
  eco2_mean: 'CO2',
  tvoc_mean: 'TVOC',
}

const dataQualityNormalRanges = {
  mc_2p5_mean: '0.1 - 20',
  mc_10p0_mean: '0.1 - 26',
  smoke_index: '0 - 10',
  temp_mean: '60 - 85',
  temp_mean_celsius: '15.5 - 29.4',
  hum_mean: '20 - 90',
  eco2_mean: '400 - 2500',
  tvoc_mean: '20 - 2000',
}

export { dataQualityBasicNameMapping, dataQualityNormalRanges, faqData, getFooterText }
