import { createListBundle, defaultInitialState } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'

const entityName = 'propertyInvoice'

const propertyInvoicesListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) =>
    apiFetch(
      '/property_invoices/',
      { property: store.selectRouteParams().id, ...params },
      { cancelationPrefix: entityName },
    ),
  initialState: {
    ...defaultInitialState,
    ordering: ['invoiceCreatedDate', 'desc'],
  },
  urlTest: (_, pattern, hash) => propertyUrls.entity === pattern && hash === 'property',
})

export default propertyInvoicesListBundle
