import { Group, Path, Rect, Text } from 'react-konva'

import { setCursorStyle } from './utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.x
 * @param {number} props.y
 * @param {string} props.text
 * @param {string} props.svgPath
 * @param {Function} props.onClick
 */
function LabeledIconButton({ x, y, text, svgPath, onClick }) {
  return (
    <Group
      onClick={onClick}
      onTap={onClick}
      onMouseEnter={(e) => setCursorStyle(e, 'pointer')}
      onMouseLeave={(e) => setCursorStyle(e, 'default')}
    >
      <Rect x={x} y={y} width={70} height={35} />
      <Path
        x={x + 5}
        y={y + 5}
        stroke="white"
        fill="white"
        strokeWidth={0}
        data={svgPath}
      />
      <Text text={text} y={y + 13} x={x + 32} fontSize={10} fill="white" />
    </Group>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.x
 * @param {number} props.y
 * @param {Function} props.onClick
 */
export function EditButton({ x, y, onClick }) {
  return (
    <LabeledIconButton
      x={x}
      y={y}
      text="EDIT"
      onClick={onClick}
      svgPath="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
    />
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.x
 * @param {number} props.y
 * @param {Function} props.onClick
 */
export function DeleteButton({ x, y, onClick }) {
  return (
    <LabeledIconButton
      x={x}
      y={y}
      text="DELETE"
      onClick={onClick}
      svgPath="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
    />
  )
}
