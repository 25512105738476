import reduceReducers from 'reduce-reducers'

import { createListBundle } from '@common/utils'

import propertyUrls from '../../urls'

const INSTALLATION_PHOTO_VERIFY = 'INSTALLATION_PHOTO_VERIFY'
const INSTALLATION_PHOTO_REMOVE = 'INSTALLATION_PHOTO_REMOVE'

const entityName = 'installation'

const installationListBundleRaw = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) =>
    apiFetch(
      '/installations/',
      { property: store.selectRouteParams().id, ...params },
      { cancelationPrefix: entityName },
    ),
  searchTest: (payload = '') => payload.length >= 2 || payload.length === 0,
  urlTest: (_, pattern) => pattern === propertyUrls.entity,
})

const installationListBundle = {
  ...installationListBundleRaw,
  reducer: reduceReducers(installationListBundleRaw.reducer, (state, action) => {
    switch (action.type) {
      case INSTALLATION_PHOTO_VERIFY:
      case INSTALLATION_PHOTO_REMOVE:
        return { ...state, ...action.payload, isOutdated: true }
      default:
        return state
    }
  }),
  doInstallationListVerify:
    (id) =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/installations/${id}/verify/`,
          {},
          { method: 'POST', cancelationPrefix: entityName },
        )
        return dispatch({ type: INSTALLATION_PHOTO_VERIFY, response })
      } catch (err) {
        return err
      }
    },
  doInstallationListRemove:
    (id) =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          '/installations/',
          { id },
          { method: 'DELETE', cancelationPrefix: entityName },
        )
        return dispatch({ type: INSTALLATION_PHOTO_REMOVE, response })
      } catch (err) {
        return err
      }
    },
}

export default installationListBundle
