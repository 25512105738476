/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { equals } from 'ramda'

import { LinePath } from '@visx/shape'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.data === next.data &&
    prev.graphWidth === next.graphWidth &&
    prev.curve === next.curve &&
    prev.stroke === next.stroke &&
    prev.yScale === next.yScale &&
    equals(prev.padding, next.padding)
  )
}

/**
 * 'graphWidth', 'padding' and 'yScale' not used directly in the component
 * but only in `propsAreEqual` function to properly rerender component
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Function} props.defined
 * @param {Object[]} props.data
 * @param {Function} props.curve
 * @param {Function} props.x
 * @param {Function} props.y
 * @param {Function} props.yScale
 * @param {string} [props.stroke]
 * @param {number} [props.graphWidth]
 * @param {Object} [props.padding]
 */
const MemoLine = memo(
  ({
    id,
    defined,
    data,
    curve,
    x,
    y,
    stroke = undefined,
    graphWidth = undefined,
    padding = undefined,
    yScale,
  }) => (
    <LinePath
      key={id}
      defined={defined}
      data={data}
      curve={curve}
      x={x}
      y={y}
      stroke={stroke}
    />
  ),
  propsAreEqual,
)

export default MemoLine
