import { Box, Button, Popover } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.items
 * @param {string} props.items[].label
 * @param {Function} props.items[].handler
 * @param {Object} [props.anchorOrigin]
 * @param {string} [props.anchorOrigin.horizontal]
 * @param {string} [props.anchorOrigin.vertical]
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.anchorEl]
 */
export default function BulkPopover({
  items,
  open,
  onClose,
  anchorEl = null,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
}) {
  const generateKey = (label) =>
    `${label}_${Number(new Date().getTime()) / Math.random()}`

  return (
    <Popover
      id={generateKey('bulk_popover')}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {items.map((item) => (
          <Button key={generateKey(item.label)} onClick={item.handler}>
            {item.label}
          </Button>
        ))}
      </Box>
    </Popover>
  )
}
