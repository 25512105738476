import { createListBundle, defaultInitialState } from '@common/utils'

import userUrls from './urls'

const USER_LIST_BULK_REACTIVATE_STARTED = 'USER_LIST_BULK_REACTIVATE_STARTED'
const USER_LIST_BULK_REACTIVATE_SUCCEEDED = 'USER_LIST_BULK_REACTIVATE_SUCCEEDED'
const USER_LIST_BULK_REACTIVATE_FAILED = 'USER_LIST_BULK_REACTIVATE_FAILED'

const entityName = 'user'

const userListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/users/', params, { cancelationPrefix: entityName }),
  initialState: {
    ...defaultInitialState,
    ordering: ['name', 'asc'],
  },
  urlTest: (url) => url === userUrls.list,
})

export default {
  ...userListBundle,
  doUserListBulkReactivate:
    (ids) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: USER_LIST_BULK_REACTIVATE_STARTED })

        const payload = ids.map((id) => ({ id }))
        await apiFetch('/users/reactivate/', payload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        })

        return dispatch({ type: USER_LIST_BULK_REACTIVATE_SUCCEEDED })
      } catch (err) {
        dispatch({ type: USER_LIST_BULK_REACTIVATE_FAILED })
        return err
      }
    },
}
