import { useCallback, useMemo } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Field } from 'formik'
import * as Yup from 'yup'

import { TextField } from '@common/components/Form'
import { parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'

export const REVIEW_TYPE_APPROVE = 'event_approve'
export const REVIEW_TYPE_REJECT = 'event_reject'
export const REVIEW_TYPE_COMMENT = 'event_comment'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.eventData]
 * @param {string} props.eventData.id
 * @param {string} props.eventData.eventType
 * @param {string} props.eventData.reviewComments
 * @param {REVIEW_TYPE_APPROVE|REVIEW_TYPE_REJECT|REVIEW_TYPE_COMMENT} props.type
 */
export default function ReviewModal({
  eventData = undefined,
  type = undefined,
  open,
  onClose,
}) {
  const { doEventReview } = useConnect('doEventReview')

  const onReviewSave = useCallback(
    async (data) => {
      let typePayload = eventData.eventType
      if (type === REVIEW_TYPE_APPROVE) {
        typePayload = 'REVIEW_APPROVED'
      } else if (type === REVIEW_TYPE_REJECT) {
        typePayload = 'REVIEW_REJECTED'
      }

      const payload = { ...data, id: eventData.id, eventType: typePayload }
      const result = await doEventReview(payload)
      if (result.error) throw parseApiErrors(result)
      return result
    },
    [eventData, doEventReview],
  )

  const validationSchema = Yup.object().shape({
    reviewComments: Yup.string().required(),
  })

  const initialValues = {
    reviewComments: eventData?.reviewComments ?? '',
  }

  const sumbitOptions = useMemo(() => {
    let label
    let color

    if (type === REVIEW_TYPE_APPROVE) {
      label = 'Approve'
      color = 'success'
    } else if (type === REVIEW_TYPE_REJECT) {
      label = 'Reject'
      color = 'error'
    }

    return { label, color }
  }, [type])

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      label="Event Review"
      validationSchema={validationSchema}
      onSave={onReviewSave}
      successMessage="Event has been successfully reviewed"
      submitOptions={sumbitOptions}
    >
      <Field
        required
        component={TextField}
        name="reviewComments"
        label="Comments"
        InputProps={{ multiline: true }}
        sx={{ width: '100%' }}
      />
    </FormDialog>
  )
}
