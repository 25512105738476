import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

const entityName = 'system'

const systemBundle = createAsyncResourceBundle({
  name: entityName,
  retryAfter: ms.seconds(10),
  staleAfter: ms.hours(12),
  persist: true,
  getPromise: async ({ apiFetch }) =>
    apiFetch('/system/', null, {
      useOrgHeader: false,
      useAccountHeader: false,
      cancelationPrefix: entityName,
    }),
})

export default {
  ...systemBundle,
  reactSystemFetch: createSelector(
    'selectIsAuthenticated',
    'selectSystemShouldUpdate',
    (authenticated, shouldUpdate) => {
      if (authenticated && shouldUpdate) {
        return { actionCreator: 'doFetchSystem' }
      }
      return undefined
    },
  ),
  selectSystemDeviceModels: createSelector('selectSystem', (system) =>
    system?.deviceModels?.map((model) => ({
      id: model.id,
      key: model.key,
      name: model.name,
    })),
  ),
  selectSmokeAdjustmentReasons: createSelector('selectSystem', (system) =>
    Object.keys(system?.smokeAdjustmentReasons ?? {}).reduce(
      (acc, val) => [
        ...acc,
        {
          id: val,
          label: system.smokeAdjustmentReasons[val],
        },
      ],
      [],
    ),
  ),
  selectSystemDataTypes: createSelector(
    'selectSystem',
    (system) => system?.dataTypes ?? [],
  ),
  selectSmokeTypes: createSelector('selectSystem', (system) =>
    Object.keys(system?.smokeTypes ?? {}).reduce(
      (acc, val) => [
        ...acc,
        {
          label: system.smokeTypes[val],
          id: val,
        },
      ],
      [],
    ),
  ),
  selectSystemLocations: createSelector(
    'selectSystem',
    (system) => system?.systemLocations,
  ),
  selectReportMetrics: createSelector(
    'selectSystem',
    (system) => system?.reportMetrics,
  ),
  selectRoleLevels: createSelector('selectSystem', (system) => system?.roleLevels),
  selectOrgLevel: createSelector('selectRoleLevels', (roleLevels) =>
    Math.max(
      ...(roleLevels
        ?.filter((role) => role.name.includes('Organization'))
        .map((role) => role.value) ?? []),
    ),
  ),
  selectAccountLevel: createSelector('selectRoleLevels', (roleLevels) =>
    Math.max(
      ...(roleLevels
        ?.filter((role) => role.name.includes('Account'))
        .map((role) => role.value) ?? []),
    ),
  ),
  selectDeviceFilterStatuses: createSelector(
    'selectSystem',
    (system) => system?.deviceStatuses?.filter((status) => status.user) ?? [],
  ),
  selectUnitFilterStatuses: createSelector(
    'selectSystem',
    (system) => system?.unitStatuses?.filter((status) => status.user) ?? [],
  ),
}
