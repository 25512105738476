import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { DragHandleOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'

import { formatItemCount, formatSubitemCount } from '@rest/Utils/formatMenuStrings'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.currentAccount]
 * @param {Object} [props.currentAccountDetails]
 * @param {string} [props.currentAccountDetails.name]
 * @param {Function} props.doSetCurrentAccount
 * @param {Object[]} props.availableAccounts
 * @param {string} props.availableAccounts[].id
 * @param {string} [props.availableAccounts[].organization]
 * @param {string} [props.availableAccounts[].name]
 * @param {number} [props.availableAccounts[].propertyCount]
 * @param {number} [props.availableAccounts[].unitCount]
 * @param {number} [props.availableAccounts[].deviceCount]
 * @param {string[]} [props.availableAccounts[].flags]
 * @param {Object[]} [props.availableAccounts[].roles]
 */
export default function AccountsMenu({
  currentAccount = null,
  currentAccountDetails = {},
  doSetCurrentAccount,
  availableAccounts,
}) {
  const { doSetCurrentProperty } = useConnect('doSetCurrentProperty')
  const [acctsAnchor, setAcctsAnchor] = useState(null)
  const openAcctsMenu = Boolean(acctsAnchor)

  const handleAccountClick = (id) => {
    setAcctsAnchor(null)
    doSetCurrentAccount(id)
    doSetCurrentProperty(null)
  }

  if (!currentAccount) {
    return null
  }

  return (
    <>
      <Typography color="grey.300">/</Typography>
      <Button
        data-testid="account-button"
        onClick={(e) => setAcctsAnchor(e.currentTarget)}
        endIcon={<KeyboardArrowDown />}
        sx={{ p: 0, px: '3px', mx: 1, color: 'white' }}
      >
        {currentAccountDetails.name}
      </Button>
      <Menu
        disableScrollLock
        data-testid="account-menu"
        open={openAcctsMenu}
        anchorEl={acctsAnchor}
        onClose={() => setAcctsAnchor(null)}
        sx={{ '& .MuiMenu-list': { minWidth: '300px', maxHeight: '400px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant="caption" color="text.secondary" ml={2}>
          {formatItemCount('Account', availableAccounts.length)}
        </Typography>
        {availableAccounts.map((acct) => (
          <MenuItem
            key={acct.id}
            selected={acct.id === currentAccount}
            onClick={() => {
              handleAccountClick(acct.id)
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <DragHandleOutlined color="text.secondary" />
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Typography>{acct.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {formatSubitemCount('Property', acct.propertyCount)}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
