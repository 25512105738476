import { Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.children
 */
export default function PageTitle({ children, ...props }) {
  const isSmallScreen = useSmallScreen()
  return (
    <Typography variant={isSmallScreen ? 'h5' : 'h4'} gutterBottom {...props}>
      {children}
    </Typography>
  )
}
