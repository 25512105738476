import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Circle as CircleIcon, CopyAll, Delete, Edit } from '@mui/icons-material'
import { Box, Button, Chip, Typography } from '@mui/material'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useHashFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import MembershipsList from '@portal/pages/Memberships/Memberships'
import RolesTab from '@portal/pages/RolesAndPermissions/RolesTab'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import DeleteModal from '@portal/UI/components/DeleteModal'
import EventLogTab from '@portal/UI/components/EventLogTab'
import ListPageTitle from '@portal/UI/components/ListPageTitle'

import OrganizationForm from './OrganizationForm'
import OrganizationTab from './Tabs/Organization'
import orgUrls from './urls'

export default function OrganizationDetail() {
  const [tabValue, setTabValue] = useState(0)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [currentOrganization, setCurrentOrganization] = useState(null)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [deleteError, setDeleteError] = useState('')

  const {
    doOrganizationDelete,
    doOrganizationFetch,
    doShowSnackbar,
    doUpdateUrl,
    hashObject,
    routeParams: { id: orgId },
    organizationFetch: organization,
    organizationFetchStatus,
    isAtLeastAdmin,
  } = useConnect(
    'doOrganizationDelete',
    'doOrganizationFetch',
    'doShowSnackbar',
    'doUpdateUrl',
    'selectHashObject',
    'selectRouteParams',
    'selectOrganizationFetch',
    'selectOrganizationFetchStatus',
    'selectIsAtLeastAdmin',
  )

  const isSmallScreen = useSmallScreen()

  const tabs = [
    { label: 'Organization', component: <OrganizationTab />, hash: 'organization' },
    {
      label: 'Memberships',
      component: <MembershipsList renderAsTab />,
      hash: 'memberships',
    },
    {
      label: 'Roles & Permissions',
      component: <RolesTab />,
      hash: 'roles',
    },
    {
      label: 'Event Log',
      component: <EventLogTab entity="Organization" />,
      hash: 'eventLog',
    },
    {
      label: 'Audit Log',
      component: <AuditLogTab objectId={orgId} />,
      hash: 'auditLog',
    },
  ]

  useEffect(() => {
    async function fetch() {
      try {
        await doOrganizationFetch(orgId)
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
    fetch()
  }, [])

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  const handleEditClick = () => {
    setEditFormOpen(true)
    setCurrentOrganization(organization)
  }

  const handleDeleteClick = () => {
    setDeleteFormOpen(true)
    setCurrentOrganization(organization)
  }

  const deleteOrganization = async () => {
    try {
      await doOrganizationDelete(currentOrganization.id)
      doShowSnackbar('Successfully deleted Organization')
      setDeleteFormOpen(false)
      doUpdateUrl(orgUrls.list)
    } catch (error) {
      setDeleteError(error)
    }
  }

  const StatusChip = useCallback(
    () => (
      <Chip
        icon={<CircleIcon />}
        variant="outlined"
        size="small"
        label={organization?.deletedOn ? 'Inactive' : 'Active'}
        color={organization?.deletedOn ? 'error' : 'success'}
      />
    ),
    [organization],
  )

  if (organizationFetchStatus === 'failed') {
    return <Typography>An error occurred while retrieving organization.</Typography>
  }

  if (organizationFetchStatus === 'loading') {
    return <Loading />
  }

  return (
    <Box sx={{ px: isSmallScreen ? 0 : 2, m: isSmallScreen ? 3 : 4 }}>
      <Breadcrumbs
        links={[
          { label: 'Home', href: homeUrls.home },
          { label: 'Organizations', href: orgUrls.list },
          { label: organization?.name || 'Loading...' },
        ]}
      />
      <Box display="flex" alignItems="center">
        <Typography variant="caption" color="text.secondary">
          id: {orgId}
        </Typography>
        <CopyAll
          sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
          onClick={() => {
            navigator.clipboard.writeText(orgId)
            doShowSnackbar('ID copied to clipboard')
          }}
        />
      </Box>
      {isSmallScreen ? (
        <Box mb={1}>
          <ListPageTitle
            title={organization?.name}
            menuItems={
              isAtLeastAdmin
                ? [
                    { label: 'Edit', onClick: handleEditClick },
                    { label: 'Delete', onClick: handleDeleteClick },
                  ]
                : null
            }
          />
          <StatusChip />
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" mb={1}>
              {organization?.name}
            </Typography>
            <StatusChip />
          </Box>
          {isAtLeastAdmin && (
            <Box>
              <Button
                startIcon={<Edit />}
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Button
                startIcon={<Delete />}
                variant="outlined"
                color="error"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </Box>
          )}
        </Box>
      )}
      <OrganizationForm
        open={editFormOpen}
        onClose={() => {
          setEditFormOpen(false)
          doOrganizationFetch(currentOrganization)
          setCurrentOrganization(null)
        }}
        instance={currentOrganization}
      />
      <DeleteModal
        open={deleteFormOpen}
        error={deleteError}
        onConfirmDelete={deleteOrganization}
        onCancelDelete={() => {
          setDeleteFormOpen(false)
          setCurrentOrganization(null)
        }}
      />
      <TabComponent
        tabs={tabs}
        externalState={{ value: tabValue, setValue: setTabValue }}
      />
    </Box>
  )
}
