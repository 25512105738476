import { useCallback, useEffect, useState } from 'react'

import { pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { SmokingRooms as SmokingRoomsIcon } from '@mui/icons-material'
import { Box, Button, Chip, Link, Stack, Tooltip, Typography } from '@mui/material'

import { DateTime } from 'luxon'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { useQueryFilter, useSmallScreen } from '@common/utils'
import deviceUrls from '@portal/pages/Devices/urls'
import { homeUrls } from '@portal/pages/Home'
import ScenarioExportDialog from '@portal/pages/Scenarios/ScenarioExportDialog'
import ScenarioForm from '@portal/pages/Scenarios/ScenarioForm'
import userUrls from '@portal/pages/Users/urls'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import Picker from '@portal/UI/components/Picker'
import boolOptions from '@portal/Utils/constants'

import scenarioUrls from './urls'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 */
export default function Scenarios({ renderAsTab = false }) {
  const [pageSize, setPageSize] = useState('')
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [selectedScenario, setSelectedScenario] = useState({})
  const [selectedIds, setSelectedIds] = useState([])
  const [exportByTagFormOpen, setExportByTagFormOpen] = useState(false)
  const [uploadingScenario, setUploadingScenario] = useState(false)
  const [rowId, setRowId] = useState(null)
  const [error, setError] = useState('')
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    doScenarioListExport,
    doMarkScenarioListAsOutdated,
    doScenarioListSetPageSize,
    doScenarioListSetPage,
    doScenarioListSetOrdering,
    doScenarioListSetFilter,
    doScenarioListClearParams,
    doScenarioDelete,
    doUpdateUrl,
    scenarioList,
    scenarioListRaw: { ordering = [] },
    scenarioListIsLoading,
    scenarioListApiParams,
    queryObject,
    systemScenarioTags,
    isAtLeastDataScience,
    isStaff,
  } = useConnect(
    'doScenarioListExport',
    'doMarkScenarioListAsOutdated',
    'doScenarioListSetPageSize',
    'doScenarioListSetPage',
    'doScenarioListSetOrdering',
    'doScenarioListSetFilter',
    'doScenarioListClearParams',
    'doScenarioDelete',
    'doUpdateUrl',
    'selectScenarioList',
    'selectScenarioListRaw',
    'selectScenarioListIsLoading',
    'selectScenarioListApiParams',
    'selectQueryObject',
    'selectSystemScenarioTags',
    'selectIsAtLeastDataScience',
    'selectIsStaff',
  )

  const isSmallScreen = useSmallScreen()

  useQueryFilter({
    query: queryObject,
    apiParams: scenarioListApiParams,
    setFilter: doScenarioListSetFilter,
    setPageSize: doScenarioListSetPageSize,
  })

  useEffect(() => {
    doMarkScenarioListAsOutdated()
  }, [])

  const handleClear = () => {
    doScenarioListClearParams()
    doScenarioListSetFilter()
  }

  const handlePageChange = async (pageNumber) => {
    doScenarioListSetPage(pageNumber)
  }

  const handleRowClick = (params) => {
    const updatedId = scenarioUrls.entity.replace(':id', params.id)
    doUpdateUrl(updatedId)
  }

  const handleSortChange = async (sort) => {
    doScenarioListSetOrdering(sort)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doScenarioListSetPageSize(size)
  }

  const handleScenarioSave = (row) => {
    setEditFormOpen(true)
    setSelectedScenario(row)
  }

  const openConfirmDeleteModal = (id) => {
    setRowId(id)
    setDeleteFormOpen(true)
  }

  const handleDelete = async () => {
    try {
      await doScenarioDelete(rowId)
      await doMarkScenarioListAsOutdated()
      setRowId(null)
      setDeleteFormOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  const bulkExportScenario = (scenarios) => doScenarioListExport({ scenarios })

  const listActions = {
    delete: openConfirmDeleteModal,
    create: handleScenarioSave,
    update: handleScenarioSave,
    bulk: [{ action: bulkExportScenario, title: 'Export Selected' }],
  }

  const listActionsPermission = {
    default: isAtLeastDataScience,
    bulk: isStaff,
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      isMainCell: true,
      sortable: true,
      maxWidth: 400,
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      sortField: 'created_by__name',
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.createdByName}
          url={userUrls.entity.replace(':id', row.createdBy)}
        />
      ),
      flex: 0.7,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      valueGetter: (_, row) =>
        DateTime.fromISO(row.createdOn).toLocaleString(DateTime.DATETIME_MED),
      flex: 0.7,
    },
    {
      field: 'event',
      headerName: 'From Event',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) =>
        row.event ? (
          <Box display="flex" justifyContent="center" width="100%">
            <Tooltip
              placement="right"
              title="This scenario was generated from an event."
            >
              <SmokingRoomsIcon />
            </Tooltip>
          </Box>
        ) : (
          ' '
        ),
      flex: 0.5,
    },
    ...(renderAsTab
      ? []
      : [
          {
            field: 'sourceDevice',
            headerName: 'Source Device',
            sortable: false,
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.sourceDeviceMac}
                url={deviceUrls.entity.replace(':id', row.sourceDevice)}
              />
            ),
            flex: 0.7,
          },
          {
            field: 'feedback',
            headerName: 'Event Feedback',
            sortable: false,
            renderCell: ({ row }) => row.feedback ?? '',
            flex: 0.8,
            maxWidth: 200,
          },
        ]),
    {
      field: 'scenarioTags',
      headerName: 'Tags',
      flex: 1.2,
      maxWidth: 200,
      renderCell: ({ row }) =>
        row.scenarioTags.length > 0 ? (
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            sx={{ px: 1, pt: 0.5, pb: 1 }}
          >
            {row.scenarioTags.map((item) => (
              <Chip
                key={item}
                variant="outlined"
                size="small"
                label={item}
                color="primary"
                sx={{ mr: 0.5, mt: 0.5 }}
              />
            ))}
          </Box>
        ) : (
          '--'
        ),
      renderMobile: ({ row }) =>
        row.scenarioTags?.length > 0 ? (
          <Stack textAlign="end">
            {row.scenarioTags.map((tag) => (
              <Typography variant="caption">{tag}</Typography>
            ))}
          </Stack>
        ) : null,
      sortable: false,
    },
  ]

  const rows = scenarioList?.results.map((scenario) => ({
    id: scenario.id,
    createdOn: scenario.createdOn,
    createdBy: scenario.createdBy,
    createdByName: scenario.createdByName,
    name: scenario.name,
    description: scenario.description,
    deviceModel: scenario.deviceModel,
    sourceDevice: scenario.sourceDevice,
    sourceDeviceMac: scenario.sourceDeviceMac,
    scenarioTags: scenario.scenarioTags,
    event: scenario.event,
    feedback: scenario.feedback,
    readingStart: scenario.readingStart,
    readingEnd: scenario.readingEnd,
  }))

  const MobileItemHeader = useCallback(
    ({ row }) => (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={scenarioUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.name}
          </Typography>
        </Link>
        {row.event && <SmokingRoomsIcon sx={{ fontSize: 18, ml: 1 }} />}
      </Box>
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name', 'event'],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  if (!scenarioList.results)
    return <ErrorComponent title="Scenarios" callback={handleClear} />

  return (
    <>
      <ScenarioForm
        open={editFormOpen}
        onClose={() => {
          setSelectedScenario({})
          setEditFormOpen(false)
          doMarkScenarioListAsOutdated()
          setUploadingScenario(false)
        }}
        instance={selectedScenario}
        showReadingsField={uploadingScenario}
      />
      <ScenarioExportDialog
        open={exportByTagFormOpen}
        onClose={() => setExportByTagFormOpen(false)}
      />
      <DeleteModal
        open={deleteFormOpen}
        error={error}
        onConfirmDelete={handleDelete}
        onCancelDelete={() => setDeleteFormOpen(false)}
      />
      <Box m={renderAsTab ? 0 : 3} display="flex" flexDirection="column">
        {!renderAsTab && (
          <>
            <Breadcrumbs
              links={[{ label: 'Home', href: homeUrls.home }, { label: 'Scenarios' }]}
            />
            <ListPageTitle
              title="Scenarios"
              onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
              menuItems={
                isSmallScreen && (isAtLeastDataScience || isStaff)
                  ? [
                      {
                        label: 'Upload Scenario',
                        onClick: () => {
                          setUploadingScenario(true)
                          setEditFormOpen(true)
                        },
                      },
                      {
                        label: 'Export By Tag',
                        onClick: () => setExportByTagFormOpen(true),
                      },
                      ...(selectedIds?.length
                        ? [
                            {
                              onClick: () => bulkExportScenario(selectedIds),
                              label: 'Export Selected',
                            },
                          ]
                        : []),
                    ]
                  : null
              }
              mb={2}
            />
          </>
        )}
        <Box display="flex">
          {!renderAsTab && (
            <Filter
              mode={isSmallScreen ? 'modal' : 'drawer'}
              disabled={scenarioListIsLoading}
              apiParams={scenarioListApiParams}
              setApiParams={doScenarioListSetFilter}
              dialogOpen={filterModalOpen}
              dialogOnClose={() => setFilterModalOpen(false)}
            >
              <StaticSelect
                label="Created from event"
                filterName="event"
                options={boolOptions}
              />
              <DynamicSelect
                label="Created By"
                endpoint="users"
                filterName="createdBy"
                filters={{ active: true, isStaff: true }}
              />
              <Picker
                range
                type="dateTime"
                label="Created at"
                disableMaskedInput
                conditionSeparator=""
                filterName="createdOn"
                lowerCondition="After"
                upperCondition="Before"
                value={pick(
                  ['createdOnBefore', 'createdOnAfter'],
                  scenarioListApiParams,
                )}
              />
              <StaticSelect
                multiple
                label="Scenario Tags"
                filterName="tags"
                options={systemScenarioTags}
              />
              <DynamicSelect
                label="Device"
                endpoint="devices"
                filterName="sourceDevice"
                primaryTextAttr="mainMac"
                secondaryTextAttr="id"
              />
            </Filter>
          )}
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                dynamicRowHeight
                title="Scenarios"
                loading={scenarioListIsLoading}
                actions={renderAsTab ? null : listActions}
                actionsPermission={listActionsPermission}
                itemBuilder={MobileListItem}
                page={scenarioList.current || 1}
                pageChange={handlePageChange}
                pageSize={scenarioList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={scenarioList.count || 0}
                rowClick={handleRowClick}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch={!renderAsTab}
                dynamicRowHeight
                title="Scenarios"
                loading={scenarioListIsLoading}
                actions={renderAsTab ? null : listActions}
                actionsPermission={listActionsPermission}
                columns={columns}
                showAddButton={!renderAsTab}
                customAddButton={
                  !renderAsTab && (
                    <>
                      <Button
                        onClick={() => setExportByTagFormOpen(true)}
                        variant="outlined"
                      >
                        export by tag
                      </Button>
                      <Button
                        onClick={() => {
                          setUploadingScenario(true)
                          setEditFormOpen(true)
                        }}
                        variant="contained"
                      >
                        Upload Scenario
                      </Button>
                    </>
                  )
                }
                page={scenarioList.current || 1}
                pageChange={handlePageChange}
                pageSize={scenarioList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={scenarioList.count || 0}
                rowClick={handleRowClick}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                sortChange={handleSortChange}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
