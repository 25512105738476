export default (timezone) => {
  switch (timezone) {
    case 'America/New_York':
      return 'Eastern'
    case 'America/Los_Angeles':
      return 'Pacific'
    case 'America/Chicago':
      return 'Central'
    case 'America/Denver':
      return 'Mountain time'
    default:
      return timezone
  }
}
