import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import { createListBundle, defaultInitialState, isAbortError } from '@common/utils'

import orgUrls from './urls'

const ORGANIZATION_GROUP_LIST_FETCH_ALL = 'ORGANIZATION_GROUP_LIST_FETCH_ALL'

const entityName = 'organizationGroup'

const organizationGroupBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/organization_groups/', params, { cancelationPrefix: entityName }),
  initialState: {
    ...defaultInitialState,
    ordering: ['name', 'asc'],
  },
  urlTest: (url) => url === orgUrls.list,
})

export default {
  ...organizationGroupBundle,
  reducer: reduceReducers(organizationGroupBundle.reducer, (state, action) => {
    switch (action.type) {
      case ORGANIZATION_GROUP_LIST_FETCH_ALL:
        return { ...state, allOrganizationGroups: action.payload }
      default:
        return state
    }
  }),
  doOrganizationGroupListFetchAll:
    (payload) =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/organization_groups/`,
          { ordering: 'name', pageSize: 500, ...payload },
          { method: 'GET', cancelationPrefix: entityName },
        )
        return dispatch({
          type: ORGANIZATION_GROUP_LIST_FETCH_ALL,
          payload: response.results,
        })
      } catch (err) {
        if (!isAbortError(err)) return err
        return null
      }
    },
  selectAllOrganizationGroups: createSelector(
    'selectOrganizationGroupListRaw',
    (orgGroupsListRaw) => orgGroupsListRaw?.allOrganizationGroups,
  ),
}
