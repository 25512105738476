import { memo } from 'react'

import { equals } from 'ramda'

import ChartMinimap from '../components/ChartMinimap'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.show === next.show &&
    prev.height === next.height &&
    prev.data === next.data &&
    prev.onChange?.toString() === next.onChange?.toString() &&
    prev.onMove?.toString() === next.onMove?.toString() &&
    equals(prev.selectedArea, next.selectedArea)
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {number} [props.height]
 * @param {boolean} [props.show]
 * @param {Object[]} props.data
 * @param {Object} [props.data[].time]
 * @param {Object} [props.selectedArea]
 * @param {Object} props.selectedArea.start
 * @param {Object} props.selectedArea.end
 * @param {Function} [props.onChange]
 * @param {Function} [props.onMove]
 */
const MemoChartMinimap = memo(
  ({
    id,
    show = true,
    height = 70,
    data,
    selectedArea = null,
    onChange = undefined,
    onMove = undefined,
  }) => (
    <ChartMinimap
      key={id}
      height={height}
      show={show}
      data={data}
      selectedArea={selectedArea}
      onChange={onChange}
      onMove={onMove}
    />
  ),
  propsAreEqual,
)

export default MemoChartMinimap
