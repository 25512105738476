import { useCallback, useEffect, useMemo, useState } from 'react'

import { equals } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'

import { ThresholdModalContent } from '@common/components/thresholdManagement'
import { parseApiErrors, useSmallScreen, useWindowSize } from '@common/utils'
import { formatRule } from '@rest/pages/Configure/ThresholdProfiles/thresholdApiUtils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.profileData
 * @param {string} props.profileData.id
 * @param {string} props.profileData.name
 * @param {string} props.profileData.description
 * @param {string} props.profileData.property
 * @param {Object[]} props.profileData.rules
 * @param {string} [props.profileData.rules[].name]
 * @param {string} [props.profileData.rules[].deviceStyle]
 * @param {string} [props.profileData.rules[].startTime]
 * @param {string} [props.profileData.rules[].endTime]
 * @param {number} [props.profileData.rules[].lowValue]
 * @param {number} [props.profileData.rules[].highValue]
 * @param {Function} props.onSuccess
 */
export default function EmbeddedProfileEditor({ profileData, onSuccess }) {
  const { doThresholdProfileUpdate, doShowSnackbar } = useConnect(
    'doThresholdProfileUpdate',
    'doShowSnackbar',
  )

  const [indoorThresholdSettings, setIndoorThresholdSettings] = useState([])
  const [outdoorThresholdSettings, setOutdoorThresholdSettings] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const [settingData, setSettingData] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  const isSmallScreen = useSmallScreen()
  const [width] = useWindowSize()

  const formatProfile = useCallback(
    (rule) => {
      const parseTime = (time) => {
        const timeArray = time.split(':')
        return parseInt(timeArray[0], 10)
      }
      return {
        ...rule,
        id: profileData?.rules.indexOf(rule),
        startTime: parseTime(rule.startTime),
        endTime: parseTime(rule.endTime),
      }
    },
    [profileData],
  )

  const formatRules = useCallback(
    (style) =>
      profileData?.rules
        .filter((rule) => rule.deviceStyle === style)
        .map(formatProfile) ?? [],
    [profileData],
  )

  useEffect(() => {
    if (profileData) {
      setIndoorThresholdSettings(formatRules('INDOOR'))
      setOutdoorThresholdSettings(formatRules('OUTDOOR'))
    }
  }, [profileData])

  const rulesChanged = useMemo(() => {
    const profileIndoorRules = formatRules('INDOOR')
    const profileOutdoorRules = formatRules('OUTDOOR')

    return (
      !equals(profileIndoorRules, indoorThresholdSettings) ||
      !equals(profileOutdoorRules, outdoorThresholdSettings)
    )
  }, [profileData, indoorThresholdSettings, outdoorThresholdSettings])

  const handleEditSetting = (setting) => {
    setIsEditing(true)
    setEditingId(setting.id)
    setSettingData(setting)
  }

  const cancelEditSetting = () => {
    setIsEditing(false)
    setEditingId(null)
    setSettingData(null)
  }

  const confirmEditSetting = (updatedThreshold) => {
    const newThreshArr =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const updateIndex = newThreshArr.findIndex((obj) => obj.id === updatedThreshold.id)
    if (updateIndex !== -1) {
      newThreshArr[updateIndex] = updatedThreshold
    } else {
      newThreshArr.push(updatedThreshold)
    }

    const setFn =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleAddSetting = (startTime, deviceStyle) => {
    setIsEditing(true)
    setEditingId('add_new')
    setSettingData({
      id: Math.random(),
      startTime,
      endTime: startTime === 23 ? 0 : startTime + 1,
      highValue: 50,
      deviceStyle,
      name: 'Quiet Hours',
    })
  }

  const handleDeleteSetting = (setting) => {
    const newThreshArr =
      setting.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const deleteIndex = newThreshArr.findIndex((obj) => obj.id === setting.id)
    if (deleteIndex !== -1) {
      newThreshArr.splice(deleteIndex, 1)
    }

    const setFn =
      setting.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleSubmit = async () => {
    const formattedSettings = [
      ...indoorThresholdSettings,
      // ...outdoorThresholdSettings, // do not include outdoor yet
    ].map((setting) => formatRule(setting))

    const payload = {
      id: profileData.id,
      name: profileData.name,
      description: profileData.description,
      property: profileData.property,
      dataType: 'NOISE',
      rules: formattedSettings,
    }
    try {
      setIsSaving(true)

      const response = await doThresholdProfileUpdate(payload)
      if (response.error) throw response

      doShowSnackbar(`Threshold profile has been updated`, 'success')
      onSuccess(response)
    } catch (error) {
      const parsedError = parseApiErrors(error?.response)
      doShowSnackbar(parsedError || `Unable to update threshold profile`, 'error')
    } finally {
      setIsSaving(false)
    }
  }

  const handleReset = useCallback(() => {
    setIndoorThresholdSettings(formatRules('INDOOR'))
    setOutdoorThresholdSettings(formatRules('OUTDOOR'))
  }, [profileData])

  return (
    <Box display="flex" sx={{ mt: isSmallScreen ? 1 : 0 }}>
      <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
        {isSaving && <CircularProgress sx={{ position: 'absolute' }} />}
        <Box
          sx={{
            width: '100%',
            transitionProperty: 'opacity',
            transitionDuration: '300ms',
            opacity: isSaving ? 0.35 : 1,
            pointerEvents: isSaving ? 'none' : 'initial',
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant={isSmallScreen ? 'h6' : 'h5'} ml={0.5}>
              Manage Noise Threshold
            </Typography>
            <Box display="flex" gap={1.5}>
              {rulesChanged && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleReset}
                  disabled={isEditing}
                >
                  Reset
                </Button>
              )}
              <Button
                size="small"
                variant="contained"
                onClick={handleSubmit}
                disabled={!rulesChanged || isEditing}
              >
                Save
              </Button>
            </Box>
          </Stack>
          <ThresholdModalContent
            singleMode={isSmallScreen ? 'indoor' : null}
            editorWidth={isSmallScreen ? width - 108 : null}
            indoorThresholdSettings={indoorThresholdSettings}
            setIndoorThresholdSettings={setIndoorThresholdSettings}
            outdoorThresholdSettings={outdoorThresholdSettings}
            setOutdoorThresholdSettings={setOutdoorThresholdSettings}
            handleAdd={handleAddSetting}
            handleEdit={handleEditSetting}
            handleDelete={
              indoorThresholdSettings.length > 1 ? handleDeleteSetting : null
            }
            cancelEdit={cancelEditSetting}
            confirmEdit={confirmEditSetting}
            isEditing={isEditing}
            editingId={editingId}
            settingData={settingData}
            setFieldValue={() => {}}
          />
        </Box>
      </Box>
    </Box>
  )
}
