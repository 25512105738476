import { useEffect } from 'react'

import { useFormikContext } from 'formik'

/**
  Used to pass formik props back up to the parent component
* */
export default function FormikStatePropagator({ propSetter }) {
  const context = useFormikContext()
  useEffect(() => {
    propSetter(context)
  }, [context.values, context.errors])
}
