import { useCallback, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import FormDialog from '@portal/UI/components/FormDialog'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object[]} [props.properties]
 */
export default function AlertingEnsembleForm({ open, onClose, properties = null }) {
  const [loading, setLoading] = useState(false)
  const [confirmationData, setConfirmationData] = useState(null)

  const {
    systemSmokeProfileEnsembles,
    doPropertySetAlertingEnsembleProfile,
    doShowSnackbar,
  } = useConnect(
    'selectSystemSmokeProfileEnsembles',
    'doPropertySetAlertingEnsembleProfile',
    'doShowSnackbar',
  )

  const initialValues = {
    profile: '',
  }

  const validationSchema = yup.object().shape({
    profile: yup.string().uuid().nullable(),
  })

  const options = useMemo(
    () =>
      systemSmokeProfileEnsembles
        ?.filter((profile) =>
          ['AVAILABLE', 'DEFAULT_ALERTING'].includes(profile.status),
        )
        ?.map((profile) => ({ id: profile.id, label: profile.name })),
    [systemSmokeProfileEnsembles],
  )

  const handleSave = useCallback(
    async (profileId) => {
      setLoading(true)
      try {
        await doPropertySetAlertingEnsembleProfile({
          ids: properties.map((property) => property.id),
          profileId: profileId || null,
        })
        doShowSnackbar('Alerting ensemble has been changed', 'success')
        onClose(true)
      } catch (err) {
        const errorMessage = parseApiErrors(err)
        doShowSnackbar(errorMessage, 'error')
      } finally {
        setLoading(false)
      }
    },
    [properties],
  )

  return (
    <>
      <ConfirmationDialog
        open={!!confirmationData}
        title="Confirm Alerting Profile Ensemble Change"
        message={`Affected properties:\n\n ${properties
          ?.map((property) => property.name)
          .join('\n')}`}
        fullWidth={false}
        onConfirm={() => {
          setConfirmationData(null)
          handleSave(confirmationData.profile)
        }}
        onCancel={() => setConfirmationData(null)}
      />
      <FormDialog
        label="Modify Alerting Profile Ensemble"
        open={open}
        onSave={(data) => setConfirmationData(data)}
        onClose={onClose}
        isLoading={loading}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '2rem' }}>
          <Field
            fullWidth
            alwaysShowPlaceholder
            name="profile"
            label="Smoke Profile Ensemble"
            variant="standard"
            component={StaticSelect}
            options={options}
            placeholder="Default"
            inputStyle={{ '& ::placeholder': { fontStyle: 'italic' } }}
          />
        </Box>
      </FormDialog>
    </>
  )
}
