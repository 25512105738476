import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Card, CardContent, Stack, Typography } from '@mui/material'

import { parseApiErrors, useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.eventList
 * @param {Function} props.fetch
 * @param {Function} props.formatCurrency
 */
export default function ChargeSummary({ eventList, fetch, formatCurrency }) {
  const [summary, setSummary] = useState(null)

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const isSmallScreen = useSmallScreen()

  const fetchSummary = useCallback(async () => {
    try {
      const result = await fetch()
      if (result?.error) {
        throw result.error
      }

      setSummary(result)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(
        parsedError || 'Cannot get charge summary. An unexpected error occurred',
        'error',
      )
    }
  }, [fetch])

  useEffect(() => {
    fetchSummary()
    return () => setSummary(null)
  }, [eventList])

  const renderChargePct = (chargePct) =>
    isNaN(chargePct) ? '0' : parseFloat(chargePct).toFixed(1)

  const rowStyles = { width: isSmallScreen ? 50 : 125 }

  return (
    <Card>
      <CardContent
        sx={{
          '&.MuiCardContent-root': { p: isSmallScreen ? 1 : 2 },
        }}
      >
        <Stack direction="column">
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography variant={isSmallScreen ? 'body2' : 'h6'} sx={rowStyles}>
              Net Charges
            </Typography>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                Selected Dates
              </Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.netCharges, '--')}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                All Time
              </Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.allTimeCharges, '--')}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" sx={{ mt: 2 }}>
            <Typography variant={isSmallScreen ? 'body2' : 'h6'} sx={rowStyles}>
              All Events
            </Typography>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                Parent/Charged (%)
              </Typography>
              <Typography variant="body2">
                {summary?.eventsTotal ?? 0}/{summary?.eventsCharged ?? 0} (
                {renderChargePct(summary?.chargePct)}%)
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                $/Charged
              </Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.perChargedEvent, '--')}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" sx={{ mt: 2 }}>
            <Typography variant={isSmallScreen ? 'body2' : 'h6'} sx={rowStyles}>
              Billable Events
            </Typography>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                Parent/Charged (%)
              </Typography>
              <Typography variant="body2">
                {summary?.billableEventsTotal ?? 0}/
                {summary?.billableEventsCharged ?? 0} (
                {renderChargePct(summary?.billableChargePct)}%)
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant={isSmallScreen ? 'caption' : 'subtitle2'}>
                $/Charged
              </Typography>
              <Typography variant="body2">
                {formatCurrency(summary?.billablePerChargedEvent, '--')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
