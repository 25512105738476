import { useCallback, useEffect, useRef, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  AutorenewRounded,
  CheckCircleOutlineRounded,
  DoNotDisturbRounded,
  QuestionMarkRounded,
} from '@mui/icons-material'
import {
  Box,
  Link,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'

import { DateTime } from 'luxon'

import {
  ClickableCell,
  MobileList,
  MobileListDefaultCard,
  SearchBox,
} from '@common/components'
import { useSmallScreen } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'
import unitUrls from '@portal/pages/Units/urls'
import userUrls from '@portal/pages/Users/urls'
import List from '@portal/UI/components/List'
import { darkTheme } from '@portal/UI/Theme'

import EventViewModal from '../../EventModal/EventViewModal'
import CommentsRow from './CommentsRow'
import ReviewActions from './ReviewActions'
import ReviewModal, { REVIEW_TYPE_APPROVE, REVIEW_TYPE_REJECT } from './ReviewModal'

const availableEventTypes = [
  {
    value: 'REVIEW_PENDING',
    label: 'Pending Review',
    tooltip: 'Awaiting staff review',
  },
  { value: 'REVIEW_APPROVED', label: 'Approved', tooltip: 'Waiting to finalize' },
  { value: 'REVIEW_REJECTED', label: 'Rejected', tooltip: 'Suspected false positive' },
]

export const EVENT_REVIEW_HASH = 'eventReview'

export default function EventReviewContent() {
  const isSmallScreen = useSmallScreen()

  const queryHolder = useRef(null)

  const [reviewModalData, setReviewModalData] = useState(null)

  const {
    eventReviewsList,
    eventReviewsListRaw: { ordering = [] },
    eventReviewsListIsLoading,
    eventReviewsListApiParams,
    queryObject,
    doEventReviewsListSetSearch,
    doEventReviewsListSetFilter,
    doEventReviewsListSetPage,
    doEventReviewsListSetPageSize,
    doEventReviewsListSetOrdering,
    doMarkEventReviewsListAsOutdated,
    doUpdateHash,
    doUpdateQuery,
  } = useConnect(
    'selectEventReviewsList',
    'selectEventReviewsListRaw',
    'selectEventReviewsListIsLoading',
    'selectEventReviewsListApiParams',
    'selectQueryObject',
    'doEventReviewsListSetSearch',
    'doEventReviewsListSetFilter',
    'doEventReviewsListSetPage',
    'doEventReviewsListSetPageSize',
    'doEventReviewsListSetOrdering',
    'doMarkEventReviewsListAsOutdated',
    'doUpdateHash',
    'doUpdateQuery',
  )

  useEffect(() => {
    doUpdateHash(EVENT_REVIEW_HASH)
    doEventReviewsListSetFilter({ eventType: 'REVIEW_PENDING' })
  }, [])

  const StatusIcon = useCallback(({ status }) => {
    let Icon
    let tooltip

    switch (status) {
      case 'REVIEW_PENDING':
        Icon = AutorenewRounded
        tooltip = 'Pending'
        break
      case 'REVIEW_APPROVED':
        Icon = CheckCircleOutlineRounded
        tooltip = 'Approved'
        break
      case 'REVIEW_REJECTED':
        Icon = DoNotDisturbRounded
        tooltip = 'Rejected'
        break
      default:
        Icon = QuestionMarkRounded
        tooltip = 'Unknown'
    }

    return (
      <Tooltip title={tooltip}>
        <Icon />
      </Tooltip>
    )
  }, [])

  const isPendingSelected = eventReviewsListApiParams?.eventType === 'REVIEW_PENDING'

  const columns = [
    {
      field: 'eventType',
      headerName: 'Status',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: isPendingSelected ? 0.06 : 0.15,
      renderCell: ({ row }) => <StatusIcon status={row.eventType} />,
    },
    {
      field: 'property',
      headerName: 'Property',
      sortable: false,
      flex: 0.4,
      renderCell: ({ row }) => {
        if (row.property) {
          return (
            <ClickableCell
              label={row.propertyName}
              url={propertyUrls?.entity.replace(':id', row.property)}
            />
          )
        }
        return row.propertyName
      },
    },
    {
      field: 'unit',
      headerName: 'Unit',
      sortable: false,
      flex: 0.2,
      renderCell: ({ row }) => {
        if (row.unit) {
          return (
            <ClickableCell
              label={row.unitName}
              url={unitUrls?.entity.replace(':id', row.unit)}
            />
          )
        }
        return row.unitName
      },
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      sortable: true,
      flex: 0.3,
      renderCell: ({ row }) =>
        DateTime.fromISO(row.createdOn, { zone: row.timezone }).toLocaleString(
          DateTime.DATETIME_MED,
        ),
    },
    ...(!isPendingSelected
      ? [
          {
            field: 'reviewedBy',
            headerName: 'Reviewed By',
            sortable: false,
            flex: 0.4,
            renderCell: (params) => {
              const { colDef, row } = params
              if (row.reviewedBy) {
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={colDef?.computedWidth}
                    alignItems={isSmallScreen ? 'end' : 'start'}
                  >
                    <Link href={userUrls.entity.replace(':id', row.reviewedBy)}>
                      <Typography noWrap variant={isSmallScreen ? 'caption' : 'body2'}>
                        {row.reviewedByName}
                      </Typography>
                    </Link>

                    <Typography noWrap variant="caption">
                      {row.reviewedByEmail}
                    </Typography>
                    <Typography noWrap variant="caption">
                      {DateTime.fromISO(row.reviewedOn, {
                        zone: row.timezone,
                      }).toLocaleString(DateTime.DATETIME_MED)}
                    </Typography>
                  </Box>
                )
              }
              return 'N/A'
            },
          },
          {
            field: 'reviewComments',
            headerName: 'Comments',
            sortable: false,
            flex: 0.7,
            renderCell: (params) => (
              <CommentsRow {...params} setReviewModalData={setReviewModalData} />
            ),
            renderMobile: ({ row }) => row.reviewComments,
          },
        ]
      : []),
    {
      field: 'grafana',
      headerName: 'Grafana',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 0.15,
      renderCell: ({ row }) => (
        <Typography
          onClick={() => {
            queryHolder.current = queryObject
            doUpdateQuery({ grafanaEvent: row.id }, { maintainScrollPosition: true })
          }}
          sx={{
            fontSize: isSmallScreen ? 12 : 14,
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#4840ba',
          }}
        >
          View
        </Typography>
      ),
    },
    {
      field: 'eventActions',
      headerName: 'Actions',
      sortable: false,
      headerAlign: 'center',
      align: 'end',
      minWidth: 240,
      renderCell: ({ row }) => (
        <ReviewActions
          row={row}
          onApprovePressed={(event) =>
            setReviewModalData({ type: REVIEW_TYPE_APPROVE, data: event })
          }
          onRejectPressed={(event) =>
            setReviewModalData({ type: REVIEW_TYPE_REJECT, data: event })
          }
        />
      ),
    },
  ]

  const MobileItemFooter = useCallback(
    ({ row }) => (
      <Box sx={{ '&&': { mt: 1, mb: 0.5 } }}>
        <ReviewActions
          fullWidth
          row={row}
          onApprovePressed={(event) =>
            setReviewModalData({ type: REVIEW_TYPE_APPROVE, data: event })
          }
          onRejectPressed={(event) =>
            setReviewModalData({ type: REVIEW_TYPE_REJECT, data: event })
          }
        />
      </Box>
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['eventActions'],
        footer: <MobileItemFooter row={row} />,
      }),
    [columns],
  )

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <EventViewModal
          id={queryObject?.grafanaEvent}
          entity="event"
          onClose={() => {
            doUpdateQuery(queryHolder.current, { maintainScrollPosition: true })
            queryHolder.current = null
          }}
        />
      </ThemeProvider>
      <ReviewModal
        open={!!reviewModalData}
        eventData={reviewModalData?.data}
        type={reviewModalData?.type}
        onClose={(success) => {
          if (success === true) {
            doMarkEventReviewsListAsOutdated()
          }
          setReviewModalData(null)
        }}
      />
      <Box>
        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleButtonGroup
            exclusive
            color="primary"
            size="small"
            value={eventReviewsListApiParams.eventType}
            onChange={(_, selectedValue) => {
              if (selectedValue !== null) {
                doEventReviewsListSetFilter({
                  ...eventReviewsListApiParams,
                  eventType: selectedValue,
                  page: 1,
                })
              }
            }}
            disabled={eventReviewsListIsLoading}
            sx={{ width: isSmallScreen ? 1 : null }}
          >
            {availableEventTypes.map((typeObj) => (
              <ToggleButton
                key={typeObj.value}
                value={typeObj.value}
                disabled={eventReviewsListApiParams?.eventType === typeObj.value}
                sx={{ py: 0.5, width: isSmallScreen ? 1 : null }}
              >
                <Tooltip title={typeObj.tooltip}>
                  <div>{typeObj.label}</div>
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <SearchBox
            title="Review Queue"
            initialValue={eventReviewsListApiParams.search}
            onSetSearch={doEventReviewsListSetSearch}
            sx={{ width: isSmallScreen ? 1 : '400px', paddingBottom: 1 }}
          />
        </Box>
        {isSmallScreen ? (
          <MobileList
            title="Review Queue"
            rows={eventReviewsList?.results ?? []}
            itemBuilder={MobileListItem}
            page={eventReviewsList?.current ?? 0}
            pageChange={doEventReviewsListSetPage}
            pageSize={eventReviewsList?.pageSize}
            pageSizeChange={doEventReviewsListSetPageSize}
            rowCount={eventReviewsList?.count ?? 0}
            loading={eventReviewsListIsLoading}
          />
        ) : (
          <List
            autoHeight
            disableColumnMenu
            showAddButton={false}
            showActions={false}
            title="Review Queue"
            columns={columns}
            rows={eventReviewsList?.results ?? []}
            page={eventReviewsList?.current ?? 0}
            pageChange={doEventReviewsListSetPage}
            pageSize={eventReviewsList?.pageSize}
            pageSizeChange={doEventReviewsListSetPageSize}
            rowCount={eventReviewsList?.count ?? 0}
            sortChange={doEventReviewsListSetOrdering}
            loading={eventReviewsListIsLoading}
            currentOrdering={ordering}
          />
        )}
      </Box>
    </>
  )
}
