import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import {
  createListBundle,
  defaultInitialState,
  getEntityFromUrl,
  isAbortError,
} from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'

const entityName = 'propertyOutages'

const OUTAGE_LIST_FETCH_ALL = 'OUTAGE_LIST_FETCH_ALL'

const propertyOutagesListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)
    const { allOutages, ...defaultParams } = params

    return apiFetch(
      '/property_outages/',
      {
        ...defaultParams,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: defaultInitialState,
  urlTest: (_, pattern, hash) =>
    (propertyUrls.entity === pattern && hash === 'property') || hash === 'outages',
})

export default {
  ...propertyOutagesListBundle,
  reducer: reduceReducers(propertyOutagesListBundle.reducer, (state, action) => {
    switch (action.type) {
      case OUTAGE_LIST_FETCH_ALL:
        return { ...state, allOutages: action.payload }
      default:
        return state
    }
  }),
  doPropertyOutagesListFetchAll:
    () =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/property_outages/`,
          { resolved: false, pageSize: 500 },
          { method: 'GET', cancelationPrefix: entityName },
        )
        return dispatch({ type: OUTAGE_LIST_FETCH_ALL, payload: response?.results })
      } catch (err) {
        if (!isAbortError(err)) return err
        return null
      }
    },
  selectAllPropertyOutages: createSelector(
    'selectPropertyOutagesListRaw',
    (propListRaw) => propListRaw?.allOutages || [],
  ),
}
