import { createEntityBundle } from '@common/utils'

const entityName = 'units'

const unitBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'import_csv',
      handler: async ({ apiFetch, payload }) =>
        apiFetch(`/accounts/${payload?.account}/unit_csv/`, payload?.data, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'move',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload.id}/move/`, payload.payload, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'update_demo_mode',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/`,
          {
            id: payload.id,
            demoModeStart: payload.demoModeStart || null,
            demoModeEnd: payload.demoModeEnd || null,
          },
          {
            method: 'PUT',
            cancelationPrefix: entityName,
          },
        ),
    },
  ],
})

export default unitBundle
