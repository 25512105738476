import { useCallback, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { LoadingButton } from '@mui/lab'
import { MenuItem } from '@mui/material'

import { DateTime } from 'luxon'

import { downloadFile, getApiFetch, parseApiErrors } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.apiParams
 * @param {'organizations' | 'accounts' | 'properties' | 'units' | 'devices' | 'reservations' | 'scheduled_report_preferences'} props.entity
 * @param {'button' | 'menuItem'} [props.mode]
 * @param {Function} [props.postExport]
 */
export default function ListExportButton({
  apiParams,
  entity,
  mode = 'button',
  postExport = undefined,
}) {
  const [isLoading, setIsLoading] = useState(false)

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const prepareCsvData = useCallback(
    (data) => {
      let result = ''

      const columnNames = []
      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!columnNames.includes(key)) {
            columnNames.push(key)
          }
        })
      })
      result += `${columnNames.join(',')}\r\n`

      data.forEach((item) => {
        const row = []
        columnNames.forEach((columnName) => {
          let columnContent = item[columnName] ?? ''
          if (columnContent !== null && typeof columnContent === 'object') {
            columnContent = JSON.stringify(columnContent).replaceAll('"', '""')
          }
          row.push(`"${columnContent}"`)
        })
        result += `${row.join(',')}\r\n`
      })

      return result
    },
    [apiParams, entity],
  )

  const onClick = useCallback(async () => {
    setIsLoading(true)
    try {
      const result = await apiFetch(
        `/${entity}/`,
        {
          ...apiParams,
          page: 1,
          pageSize: 99999,
        },
        { cancelationPrefix: 'list_export_btn' },
      )

      const csvData = prepareCsvData(result?.results)
      const formattedDate = DateTime.now().toFormat('yyyyMMdd')
      downloadFile({
        data: [csvData],
        fileName: `${formattedDate}_${entity}.csv`,
        fileType: 'text/csv',
      })
    } catch (err) {
      const errMessage = parseApiErrors(err?.response)
      doShowSnackbar(errMessage, 'error')
    } finally {
      setIsLoading(false)
      if (postExport) {
        postExport()
      }
    }
  }, [apiParams, entity, postExport])

  if (mode === 'menuItem') {
    return (
      <MenuItem key="export_menu_item" onClick={onClick}>
        Export
      </MenuItem>
    )
  }

  return (
    <LoadingButton
      loading={isLoading}
      disabled={isLoading}
      variant="outlined"
      onClick={onClick}
    >
      Export
    </LoadingButton>
  )
}
