import { intersection } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

export function checkPermissions(permissions, activePermissions) {
  const intersect = intersection(permissions, activePermissions)
  return intersect.length === permissions.length
}

function checkFlags(flags, activeFlags) {
  return flags.every((flag) => activeFlags.includes(flag))
}

export function useFlag(flags, property = null) {
  const { flags: activeFlags } = useConnect('selectFlags')
  const propertyFlags = property?.expandedFlags ?? []
  const mergedFlags = [...activeFlags, ...propertyFlags]
  return checkFlags(flags, mergedFlags)
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string[]} [props.permissions]
 * @param {string[]} [props.flags]
 * @param {React.Node} props.children
 * @param {Object} [props.property]
 * @param {string[]} [props.property.expandedFlags]
 * @param {React.Node} [props.alternate]
 */
export default function PrivilegeCheck({
  permissions = [],
  flags = [],
  children,
  property = null,
  alternate = null,
}) {
  const flagCheck = useFlag(flags, property)
  const { currentRole: role } = useConnect('selectCurrentRole')
  const activePermissions = role?.permissions ?? []
  const permissionCheck = checkPermissions(permissions, activePermissions)

  return permissionCheck && flagCheck ? children : alternate
}
