import { useState } from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.icon
 * @param {string} props.primaryText
 * @param {boolean} [props.disabled]
 * @param {Object[]} props.subitems
 * @param {string} props.subitems[].id
 * @param {string} props.subitems[].label
 * @param {boolean} props.subitems[].checked
 * @param {Function} props.onAddPermissions
 * @param {Function} props.onRemovePermissions
 */
export default function PermissionsAccordion({
  icon,
  disabled = false,
  primaryText,
  subitems,
  onAddPermissions,
  onRemovePermissions,
}) {
  if (!subitems.length) {
    return null
  }

  const [openList, setOpenList] = useState(false)
  const IconComponent = icon

  const handleCheck = (item) => {
    if (item.checked) {
      onRemovePermissions([item.id])
    } else {
      onAddPermissions([item.id])
    }
  }

  const handleAllCheck = (type) => {
    const allIds = subitems.map((p) => p.id)

    if (type === 'deselect') {
      onRemovePermissions(allIds)
    } else {
      onAddPermissions(allIds)
    }
  }

  const getSecondaryText = () => {
    const totalCount = subitems.length
    const totalChecked = subitems.filter((s) => s.checked).length
    let checkedLabel = ''

    if (totalChecked === totalCount) {
      checkedLabel = 'All'
    } else {
      checkedLabel = `${totalChecked} of ${totalCount}`
    }

    return `${checkedLabel} permissions selected`
  }

  return (
    <>
      <ListItem
        sx={{
          borderRadius: '7px',
          bgcolor: 'grey.50',
          boxShadow: 1,
          padding: 0,
          minHeight: '68px',
          mb: 2,
        }}
        onClick={() => setOpenList(!openList)}
      >
        <ListItemButton disableRipple>
          <ListItemIcon>
            <IconComponent />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{ textTransform: 'uppercase' }}
            >
              {primaryText}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {getSecondaryText()}
            </Typography>
          </ListItemText>
          <ListItemIcon>{openList ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        </ListItemButton>
      </ListItem>
      <Collapse in={openList}>
        <List component="div">
          <ButtonGroup variant="text" color="secondary" size="small" sx={{ ml: 1 }}>
            <Button disabled={disabled} onClick={() => handleAllCheck('select')}>
              <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                Select All
              </Typography>
            </Button>
            <Button disabled={disabled} onClick={() => handleAllCheck('deselect')}>
              <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                Deselect All
              </Typography>
            </Button>
          </ButtonGroup>
          {subitems?.map((subitem) => (
            <ListItem
              key={subitem.id}
              disablePadding
              sx={{
                borderBottom: 1,
                borderColor: 'grey.300',
                '&:last-child': { border: 0, mb: 2 },
              }}
            >
              <ListItemButton
                onClick={() => handleCheck(subitem)}
                disabled={disabled}
                disableRipple
              >
                <ListItemIcon>
                  <Checkbox edge="start" checked={subitem.checked} />
                </ListItemIcon>
                <Stack>
                  <Typography variant="body2">{subitem.label}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {subitem.description}
                  </Typography>
                </Stack>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}
