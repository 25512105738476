import { useCallback, useEffect } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, Divider, Typography } from '@mui/material'

import { titleize, underscore } from 'inflection'

import { parseApiErrors, useSmallScreen } from '@common/utils'
import organizationUrls from '@portal/pages/Organizations/urls'
import propertyUrls from '@portal/pages/Properties/urls'
import unitUrls from '@portal/pages/Units/urls'
import DetailItem from '@portal/UI/components/DetailItem'
import NestedDetailItem from '@portal/UI/components/NestedDetailItem'
import VerticalTabs from '@portal/UI/components/VerticalTabs'

import DeviceDataBrowser from '../DeviceDataBrowser'

export default function DeviceTab() {
  const {
    deviceFetch: device,
    propertyFetch: property,
    doPropertyFetch,
    doShowSnackbar,
  } = useConnect(
    'selectDeviceFetch',
    'selectPropertyFetch',
    'doPropertyFetch',
    'doShowSnackbar',
  )
  const isSmallScreen = useSmallScreen()
  const deviceAttrs = device.deviceAttributes
    ? Object.entries(device?.deviceAttributes)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    : {}

  const manufacturerInfo = device.manufacturerInfo
    ? Object.entries(device.manufacturerInfo)
        .filter((entries) => ['string', 'number'].includes(typeof entries[1]))
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    : {}

  const nestedManufacturerInfo = device.manufacturerInfo
    ? Object.entries(device.manufacturerInfo)
        .filter((entries) => !(entries[0] in manufacturerInfo))
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    : {}

  const fetchProperty = useCallback(async (id) => {
    try {
      await doPropertyFetch(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error fetching property')
    }
  }, [])

  useEffect(() => {
    fetchProperty(device?.propertyId)
  }, [])

  const verticalTabs = [
    {
      label: 'Device Details',
      component: (
        <Box
          display="flex"
          flexDirection="column"
          flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
          maxHeight={isSmallScreen ? 'unset' : 300}
          pt={isSmallScreen ? 1 : 2}
          gap={isSmallScreen ? 1 : 2}
        >
          <DetailItem label="Main Mac" value={device.mainMac} />
          <DetailItem label="Model" value={device.modelName} />
          <DetailItem
            label="Organization"
            value={device.organizationName}
            linkTo={
              device.organization
                ? organizationUrls.entity.replace(':id', device.organization)
                : null
            }
          />
          <DetailItem
            label="Property"
            value={device.propertyName}
            linkTo={
              device.propertyId
                ? propertyUrls.entity.replace(':id', device.propertyId)
                : null
            }
          />
          <DetailItem
            label="Unit"
            value={device.unitName}
            linkTo={
              device.unitId ? unitUrls.entity.replace(':id', device.unitId) : null
            }
          />
          <DetailItem label="Zone" value={device.zoneName} />
          {property?.expandedFlags.includes('SSID_WIFI_CRED_GET_UPDATE') && (
            <>
              <DetailItem label="Current Wifi SSID" value={device.currentWifiSsid} />
              <DetailItem label="Current Wifi PW" value={device.currentWifiPw} />
              <DetailItem label="Desired Wifi SSID" value={device.desiredWifiSsid} />
              <DetailItem label="Desired Wifi PW" value={device.desiredWifiPw} />
            </>
          )}
        </Box>
      ),
    },
    {
      label: 'Device Attributes',
      disabled: isEmpty(deviceAttrs),
      component: (
        <Box
          display="flex"
          flexDirection="column"
          pt={isSmallScreen ? 1 : 2}
          gap={isSmallScreen ? 1 : 2}
          flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
          maxHeight={isSmallScreen ? 'unset' : 450}
        >
          {Object.entries(deviceAttrs).map(([field, value]) => (
            <DetailItem label={titleize(underscore(field))} value={value} />
          ))}
        </Box>
      ),
    },
    {
      label: 'Manufacturer Info',
      disabled: isEmpty(manufacturerInfo),
      component: (
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            pt={isSmallScreen ? 1 : 2}
            gap={isSmallScreen ? 1 : 2}
            flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
            maxHeight={isSmallScreen ? 'unset' : 550}
          >
            {Object.entries(manufacturerInfo).map(([field, value]) => (
              <DetailItem label={titleize(underscore(field))} value={value} />
            ))}
          </Box>
          {Object.entries(nestedManufacturerInfo).map(
            ([label, data]) =>
              !isEmpty(data) && (
                <Box
                  pt={isSmallScreen ? 1 : 2}
                  gap={isSmallScreen ? 1 : 2}
                  maxHeight={isSmallScreen ? 'unset' : 600}
                  flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
                >
                  <NestedDetailItem label={titleize(underscore(label))} data={data} />
                </Box>
              ),
          )}
        </Box>
      ),
    },
  ]

  return (
    <>
      <Divider />
      <div>
        {device ? (
          <Box>
            <VerticalTabs tabs={verticalTabs} tabsMinWidth={218} sx={{ pb: 2 }} />
            <Divider sx={{ mb: 3 }} />
            <DeviceDataBrowser device={device} />
          </Box>
        ) : (
          <Typography>Device not found.</Typography>
        )}
      </div>
    </>
  )
}
