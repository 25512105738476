import { createListBundle, parseApiErrors } from '@common/utils'

import propertyUrls from '../urls'

const entityName = 'propertyUnit'

const propertyUnitListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const propertyId = params?.id || store.selectRouteParams().id
    return apiFetch(
      `/units/`,
      { ...params, property: [propertyId] },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (_, pattern) => pattern === propertyUrls.entity,
})

export default {
  ...propertyUnitListBundle,
  doPropertyUnitListExport:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectPropertyUnitListApiParams()
      const propertyId = store.selectRouteParams().id
      const { count = 9999 } = store.selectPropertyUnitList()
      try {
        const response = await apiFetch(
          '/units/',
          {
            ...apiParams,
            property: propertyId,
            pageSize: count,
          },
          { cancelationPrefix: entityName },
        )
        if (response.error) throw response.error
        return response.results
      } catch (error) {
        const parsedError = parseApiErrors(error?.response)
        store.doShowSnackbar(parsedError || 'Unable to export rooms', 'error')
      }
      return []
    },
}
