import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {string} [props.title]
 * @param {string} [props.message]
 * @param {string} [props.error]
 * @param {Function} props.onConfirm
 * @param {Function} [props.onCancel]
 * @param {Function} [props.onClose]
 * @param {string} [props.maxWidth]
 * @param {boolean} [props.fullWidth]
 * @param {number} [props.contentFontSize]
 * @param {React.Element} [props.content]
 */
export default function ConfirmationDialog({
  open,
  title = '',
  content = null,
  message = '',
  onConfirm,
  onCancel = () => {},
  onClose = () => {},
  error = '',
  maxWidth = 'sm',
  fullWidth = true,
  contentFontSize = 16,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle color="primary" fontSize="1.5rem">
        {title}
      </DialogTitle>
      <DialogContent>
        {error && (
          <Box mb="1rem" display="flex" flexDirection="column" gap=".5rem">
            <Alert key={error} severity="error">
              <Typography fontSize={contentFontSize}>{error}</Typography>
            </Alert>
          </Box>
        )}
        {content}
        <DialogContentText whiteSpace="pre-line" fontSize={contentFontSize}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} color="secondary" disabled={!!error} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
