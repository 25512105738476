import { createSelector } from 'redux-bundler'

import { createEntityBundle } from '@common/utils'

const entityName = 'devices'

const deviceBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'fetch_commands',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload}/commands/`, null, {
          method: 'GET',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'send_command',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/commands/`, payload, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'assign',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload?.id}/assign/`, payload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'unassign',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/unassign/`, payload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'export',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload?.id}/export/`, payload.data, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default {
  ...deviceBundle,
  selectDeviceCommands: createSelector(
    'selectDevice',
    (device) => device?.fetch_commands?.response,
  ),
  selectDeviceCommandsIsLoading: createSelector(
    'selectDevice',
    (device) => device?.fetch_commands?.meta?.status === 'loading',
  ),
}
