export const DIAGNOSTIC_CATEGORY = 'diagnostic'
export const NOISE_CATEGORY = 'noise'
export const OCCUPANCY_GATEGORY = 'occupancy'
export const ENVIRONMENT_CATEGORY = 'environment'
export const SMOKE_CATEGORY = 'smoke'
export const SMOKE_MASS_CONCENTRATION_CATEGORY = 'smoke - mass concentration'
export const SMOKE_NUMBER_COUNT_CATEGORY = 'smoke - number count'

const deviceMetaData = {
  tvoc: {
    label: 'Total Volatile Organic Compounds',
    color: '#669CC3',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  tpm: {
    label: 'Typical Particle Size',
    color: '#7F66B3',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  sen_voc_raw: {
    label: 'Volatile Organic Compound',
    color: '#CF8473',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  sen_nox_raw: {
    label: 'Nitrogen Oxide',
    color: '#344B78',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_1p0: {
    label: '< 1µm',
    color: '#7F66B3',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_2p5: {
    label: '< 2.5µm',
    color: '#669CC3',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_4p0: {
    label: '< 4µm',
    color: '#CF8473',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_10p0: {
    label: '< 10µm',
    color: '#344B78',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_0p5: {
    label: '< 0.5µm',
    color: '#7F66B3',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_1p0: {
    label: '< 1µm',
    color: '#669CC3',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_2p5: {
    label: '< 2.5µm',
    color: '#CF8473',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_4p0: {
    label: '< 4µm',
    color: '#344B78',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_10p0: {
    label: '< 10µm',
    color: '#547E7C',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nrs: {
    label: 'NRS',
    color: '#7F66B3',
    category: NOISE_CATEGORY,
    visible: true,
    hasMinMax: false,
  },
  temp: {
    label: 'Temperature',
    color: '#7F66B3',
    category: ENVIRONMENT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  hum: {
    label: 'Humidity',
    color: '#669CC3',
    category: ENVIRONMENT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  eco2: {
    label: 'CO₂',
    color: '#CF8473',
    category: ENVIRONMENT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  rssi: {
    label: 'RSSI',
    color: '#7F66B3',
    category: DIAGNOSTIC_CATEGORY,
    visible: false,
    hasMinMax: false,
  },
  battery: {
    label: 'Battery',
    color: '#669CC3',
    category: DIAGNOSTIC_CATEGORY,
    visible: false,
    hasMinMax: false,
  },
  ble_ors: {
    label: 'BLE',
    color: '#7F66B3',
    category: OCCUPANCY_GATEGORY,
    visible: false,
    hasMinMax: false,
  },
  wifi_ors: {
    label: 'WiFi',
    color: '#669CC3',
    category: OCCUPANCY_GATEGORY,
    visible: false,
    hasMinMax: false,
  },
  ens_ors: {
    label: 'ENS',
    color: '#CF8473',
    category: OCCUPANCY_GATEGORY,
    visible: false,
    hasMinMax: false,
  },
}

export function getDeviceMetaData(systemDataTypes) {
  const getUnit = (systemDataType) => {
    if (systemDataType.key === 'nrs') {
      return 'NRS'
    }
    if (
      systemDataType.key.includes('sen_voc_raw') ||
      systemDataType.key.includes('sen_nox_raw')
    ) {
      return 'raw'
    }
    return systemDataType.template.replace('{{ value }}', '').trim()
  }

  return Object.fromEntries(
    Object.entries(deviceMetaData).map(([k, v]) => {
      const systemDataType = systemDataTypes?.find((type) => type.key.startsWith(k))
      let template = '{{ value }} ?'
      let unit = '?'
      if (systemDataType) {
        template = systemDataType.template
        unit = getUnit(systemDataType)

        if (unit.startsWith('/')) {
          unit = `#${unit}`
        }
      }
      return [k, { ...v, unit, template }]
    }),
  )
}
