import { useEffect, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { FormikDynamicSelect, FreeSoloSelect } from '@common/components/Selects'
import { getApiFetch, parseApiErrors } from '@common/utils'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} [props.instance.id]
 * @param {string} [props.instance.zone]
 * @param {string} [props.instance.zoneName]
 * @param {string} [props.instance.unit]
 * @param {string} [props.instance.mainMac]
 */
export default function ReassignForm({ open, onClose, instance = {} }) {
  const defaultFormikState = {
    values: {},
    setValues: () => {},
    setFieldValue: () => {},
  }

  const [formikProps, setFormikProps] = useState(defaultFormikState)
  const [currentUnitName, setCurrentUnitName] = useState('')
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  const [zoneOptions, setZoneOptions] = useState([])

  const { doDeviceAssign, doShowSnackbar } = useConnect(
    'doDeviceAssign',
    'doShowSnackbar',
  )

  const initialValues = {
    id: instance?.id ?? '',
    property: '',
    zoneName: '',
    zone: '',
    unit: '',
  }

  const validationSchema = yup.object().shape({
    id: yup.string(),
    property: yup.string().nullable(),
    unit: yup.string().nullable(),
    zoneName: yup.string().nullable(),
    zone: yup.string().when(['property', 'zoneName'], {
      is: (property, zoneName) => {
        const noProperty = Boolean(!property) && Boolean(zoneName)
        const hasPropertyAndNoZoneName = Boolean(property) && Boolean(!zoneName)
        return noProperty || hasPropertyAndNoZoneName
      },
      then: () =>
        yup.string().required('Zone is required when property or unit is selected'),
      otherwise: () => yup.string().nullable(),
    }),
  })

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)
  const { setFieldValue, setErrors, setValues, values } = formikProps

  const fetchZoneOptions = async () => {
    try {
      const response = await apiFetch(
        `/zones/`,
        { unit: values?.unit, active: true },
        { cancelationPrefix: 'reassign_form' },
      )
      return response?.results?.map((option) => ({ label: option.name, id: option.id }))
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')

      return null
    }
  }

  const handlePropertyOnChange = (value) => {
    setValues({
      ...initialValues,
      property: value?.id ?? '',
    })
    setCurrentUnitName('')
  }

  const handleUnitOnChange = (value) => {
    setValues({
      ...values,
      unit: value?.id ?? '',
      zone: '',
      zoneName: '',
    })
    setCurrentUnitName(value?.name ?? value)
  }

  const handleSave = async (data) => {
    if (!data?.property && !data?.unit && !data?.zone) {
      return setConfirmationDialogOpen(true)
    }
    const { property, ...payload } = data
    return doDeviceAssign(payload)
  }

  const confirmUnassignDevice = async () => {
    const { property, ...payload } = initialValues
    const response = await doDeviceAssign(payload)
    if (response?.error) {
      setErrors(response.error.response)
    } else if (response) {
      doShowSnackbar(
        `Successfully unassigned ${instance?.mainMac ?? 'this device'}`,
        'success',
      )
    }
    setConfirmationDialogOpen(false)
    onClose()
  }

  useEffect(() => {
    const fetchAndSetZones = async () => {
      const options = await fetchZoneOptions()
      const sameNameZone = options?.find((zone) => zone.label === currentUnitName)
      if (sameNameZone?.id) setFieldValue('zone', sameNameZone.id)
      setZoneOptions(options)
    }

    if (values?.unit) fetchAndSetZones()
  }, [values?.unit])

  return (
    <>
      <ConfirmationDialog
        title="Unassign Device"
        message={`Are you sure you want to unassign ${
          instance?.mainMac ?? 'this device'
        } ?`}
        open={confirmationDialogOpen}
        onConfirm={confirmUnassignDevice}
        onCancel={() => setConfirmationDialogOpen(false)}
      />
      <FormDialog
        label="Reassign Device"
        open={open}
        onSave={handleSave}
        onClose={onClose}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Box
          px={5}
          gap={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
        >
          <Field
            getFullEntity
            name="property"
            label="Property"
            filterName="property"
            endpoint="properties"
            component={FormikDynamicSelect}
            onChange={handlePropertyOnChange}
          />
          <Field
            getFullEntity
            name="unit"
            label="Unit"
            filterName="unit"
            endpoint="units"
            component={FormikDynamicSelect}
            onChange={handleUnitOnChange}
            filters={values?.property ? { property: values.property } : {}}
            disabled={!values?.property}
            secondaryTextAttr="propertyName"
          />
          <Field
            disabled={!values?.unit}
            autoSelect
            name="zone"
            label="Zone"
            component={FreeSoloSelect}
            variant="standard"
            options={zoneOptions}
            altField="zoneName"
            sx={{ mt: 1 }}
          />
        </Box>
        <FormikStatePropagator propSetter={setFormikProps} />
      </FormDialog>
    </>
  )
}
