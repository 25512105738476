import { createListBundle } from '@common/utils'
import { reservationUrls } from '@rest/pages/Reservations'

const entityName = 'unit'

const unitListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/units/', params, { cancelationPrefix: entityName }),
  urlTest: (url) => url === reservationUrls.reservations,
})

export default unitListBundle
