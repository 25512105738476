import React, { useState } from 'react'

import { Box, Popover, Stack, Typography } from '@mui/material'

import { Group } from '@visx/group'
import { PatternLines } from '@visx/pattern'
import { Line } from '@visx/shape'
import { titleize, underscore } from 'inflection'

const defaultStyle = {
  dashedBackground: true,
  filledBackgorund: false,
  handle: {
    width: 1.5,
    color: 'black',
    opacity: 0.05,
    dashArray: undefined,
  },
  area: {
    color: 'black',
    opacity: 0.05,
  },
  pattern: {
    width: 6,
    height: 6,
    opacity: 0.05,
    color: 'black',
    strokeWidth: 1,
    background: 'transparent',
    orientation: ['diagonal'],
  },
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number|string} props.id
 * @param {number} props.width
 * @param {number} props.height
 * @param {number} props.x0
 * @param {number} props.x1
 * @param {number} [props.offsetLeft]
 * @param {number} [props.offsetTop]
 * @param {number} [props.minWidth]
 * @param {Object} [props.tooltipData]
 * @param {Object} [props.style]
 * @param {boolean} [props.style.dashedBackground]
 * @param {boolean} [props.style.filledBackground]
 * @param {Object} [props.style.handle]
 * @param {number} [props.style.handle.width]
 * @param {number[]} [props.style.handle.dashArray]
 * @param {string} [props.style.handle.color]
 * @param {number} [props.style.handle.opacity]
 * @param {Object} [props.style.area]
 * @param {string} [props.style.area.color]
 * @param {number} [props.style.area.opacity]
 * @param {Object} [props.style.pattern]
 * @param {number} [props.style.pattern.height]
 * @param {number} [props.style.pattern.width]
 * @param {number} [props.style.pattern.strokeWidth]
 * @param {number} [props.style.pattern.opacity]
 * @param {string} [props.style.pattern.color]
 * @param {string} [props.style.pattern.background]
 * @param {string[]} [props.style.pattern.orientation]
 */
export default function HighlightedArea({
  id,
  width,
  height,
  offsetLeft = 0,
  offsetTop = 0,
  minWidth = undefined,
  x0,
  x1,
  tooltipData = undefined,
  style = null,
}) {
  const xMin = offsetLeft
  const xMax = width + offsetLeft

  if (typeof x0 !== 'number' || typeof x1 !== 'number') {
    return null
  }

  const limitedX0 = Math.max(xMin, x0)
  const limitedX1 = Math.min(xMax, x1)

  const xDelta = minWidth
    ? Math.max(limitedX1 - limitedX0, minWidth)
    : limitedX1 - limitedX0
  const finalX1 = minWidth ? xDelta + x0 : x1

  const dashedBackground = style?.dashedBackground ?? defaultStyle.dashedBackground
  const filledBackground = style?.filledBackground ?? defaultStyle.filledBackground

  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <React.Fragment key={id}>
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 12, horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ my: 1, mx: 1.5 }}>
          {tooltipData && (
            <Stack gap={0.5}>
              {Object.entries(tooltipData).map(([key, value]) => {
                if (value) {
                  return (
                    <pre key={key} style={{ margin: 0 }}>
                      <Typography sx={{ p: 0, fontSize: 12 }}>
                        <b>{titleize(underscore(key))}</b>: {value.toString()}
                      </Typography>
                    </pre>
                  )
                }
                return null
              })}
            </Stack>
          )}
        </Box>
      </Popover>
      <Group top={offsetTop} width={width} height={height}>
        <PatternLines
          id={`${id}_fill_pattern`}
          height={style?.pattern?.height ?? defaultStyle.pattern.height}
          width={style?.pattern?.width ?? defaultStyle.pattern.width}
          stroke={style?.pattern?.color ?? defaultStyle.pattern.color}
          strokeWidth={style?.pattern?.strokeWidth ?? defaultStyle.pattern.strokeWidth}
          background={style?.pattern?.background ?? defaultStyle.pattern.background}
          orientation={style?.pattern?.orientation ?? defaultStyle.pattern.orientation}
        />
        {filledBackground && (
          <rect
            x={limitedX0}
            width={Math.max(0, xDelta)}
            height={height}
            fill={style?.area?.color ?? defaultStyle.area.color}
            opacity={style?.area?.opacity ?? defaultStyle.area.opacity}
          />
        )}
        {dashedBackground && (
          <rect
            x={limitedX0}
            width={Math.max(0, xDelta)}
            height={height}
            fill={`url(#${id}_fill_pattern)`}
            opacity={style?.pattern?.opacity ?? defaultStyle.pattern.opacity}
          />
        )}
        {tooltipData && (
          <Group>
            <rect
              x={limitedX0}
              width={Math.max(0, xDelta)}
              height={5}
              y={height + 1}
              fill={style?.area?.color ?? defaultStyle.area.color}
              opacity={0.9}
              onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
              onMouseLeave={() => setAnchorEl(null)}
            />
            <rect
              x={limitedX0}
              width={Math.max(0, xDelta)}
              height={10}
              y={height + 1}
              fill="transparent"
              onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
              onMouseLeave={() => setAnchorEl(null)}
            />
          </Group>
        )}
        {x0 >= limitedX0 && x0 <= limitedX1 && (
          <Line
            from={{ x: x0, y: 0 }}
            to={{ x: x0, y: height }}
            stroke={style?.handle?.color ?? defaultStyle.handle.color}
            opacity={style?.handle?.opacity ?? defaultStyle.handle.opacity}
            strokeWidth={style?.handle?.width ?? defaultStyle.handle.width}
            strokeDasharray={style?.handle?.dashArray ?? defaultStyle.handle.dashArray}
            pointerEvents="none"
          />
        )}
        {x1 <= limitedX1 && x1 >= limitedX0 && (
          <Line
            from={{ x: finalX1, y: 0 }}
            to={{ x: finalX1, y: height }}
            stroke={style?.handle?.color ?? defaultStyle.handle.color}
            opacity={style?.handle?.opacity ?? defaultStyle.handle.opacity}
            strokeWidth={style?.handle?.width ?? defaultStyle.handle.width}
            strokeDasharray={style?.handle?.dashArray ?? defaultStyle.handle.dashArray}
            pointerEvents="none"
          />
        )}
      </Group>
    </React.Fragment>
  )
}
