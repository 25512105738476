import { useState } from 'react'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import FormDialog from '@portal/UI/components/FormDialog'

import CommandField from './CommandField'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string[]} props.devices
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Function} props.onSave
 */
export default function CommandForm({ devices, open, onClose, onSave }) {
  const [commandValue, setCommandValue] = useState(null)

  // https://github.com/NoiseAware/platform/blob/master/portal/serializers/device.py#L83
  const availableCommands = [
    'version',
    'esp_restart',
    'blink_led',
    'set_bt_rssi_limit',
    'set_wifi_rssi_limit',
    'get_bt_rssi_limit',
    'get_wifi_rssi_limit',
    'set_monitor',
    'get_monitor',
    'get_cc',
    'cc_flash',
    'get_destination_prod',
    'set_destination_prod',
    'get_destination_dev',
    'set_destination_dev',
    'start_cleaning_fan',
    'sgp30_rebase_init',
  ]
  const nonValueCommands = [
    'version',
    'esp_restart',
    'get_bt_rssi_limit',
    'get_wifi_rssi_limit',
    'get_monitor',
    'get_destination_prod',
    'get_destination_dev',
    'start_cleaning_fan',
  ]

  const validationSchema = yup.object().shape({
    command: yup.string().required('Please select a command'),
    operation: yup.mixed().when('command', {
      is: (item) => !nonValueCommands.includes(item),
      then: () => yup.number().nullable().required('Please enter a value'),
    }),
  })

  const initialValues = {
    devices,
    command: '',
    operation: null,
  }

  const sharedStyles = { flex: 1 }

  return (
    <FormDialog
      label="Send Command"
      open={open}
      onSave={onSave}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      successMessage="Command sent successfully"
    >
      <Box gap="2rem" display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <CommandField
            availableCommands={availableCommands}
            nonValueCommands={nonValueCommands}
            commandValue={commandValue}
            setCommandValue={setCommandValue}
            sx={sharedStyles}
          />
          {commandValue && !nonValueCommands.includes(commandValue) && (
            <Field
              required
              type="number"
              component={TextField}
              label="Value"
              name="operation"
              sx={sharedStyles}
            />
          )}
        </Box>
      </Box>
    </FormDialog>
  )
}
