import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import clsx from 'clsx'
import { useConnect } from 'redux-bundler-hook'

import { InfoOutlined, Settings } from '@mui/icons-material'
import {
  Alert,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Popover,
  Slide,
  Snackbar,
  Stack,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { DataGridPro, gridStringOrNumberComparator } from '@mui/x-data-grid-pro'

import { MobileList, MobileListCardRow } from '@common/components'
import ClickableCell from '@common/components/ClickableCell'
import { getValueFromColumnDef } from '@common/components/MobileList'
import { StaticMultiSelect } from '@common/components/Selects'
import ToggleButtonWithTooltip from '@common/components/ToggleButtonWithTooltip'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import { formatCurrency } from '@common/utils/formatters'
import accUrls from '@portal/pages/Accounts/urls'
import propertyUrls from '@portal/pages/Properties/urls'

import metrics from '../SmokeMetrics/metrics'
import getSmokeScoreboardConstants from './constants'
import { roundUp } from './scoreboardBundle'

const { availableIntervals } = getSmokeScoreboardConstants()

const noDataPlaceholder = '--'

const cardStyle = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  p: 1.5,
  justifyContent: 'space-between',
}

const periodBorderColor = '#1f2a44'
const periodBorderStyle = `2px solid ${periodBorderColor} !important`
const defaultBorderColor = '#f0f0f0'
const defaultBorderStyle = `1px solid ${defaultBorderColor}`

export default function PropertyScoreboard() {
  const {
    allOrganizations,
    smokeScoreboardData,
    smokeScoreboardHealthScores,
    smokeScoreboardDataIsLoading,
    smokeScoreboardHealthScoresIsLoading,
    doOrganizationListFetchAll,
    doFetchSmokeScoreboardData,
    doFetchSmokeScoreboardHeathScores,
    doShowSnackbar,
  } = useConnect(
    'selectAllOrganizations',
    'selectSmokeScoreboardData',
    'selectSmokeScoreboardHealthScores',
    'selectSmokeScoreboardDataIsLoading',
    'selectSmokeScoreboardHealthScoresIsLoading',
    'doOrganizationListFetchAll',
    'doFetchSmokeScoreboardData',
    'doFetchSmokeScoreboardHeathScores',
    'doShowSnackbar',
  )

  const isSmallScreen = useSmallScreen()

  const abortControllerRef = useRef(new AbortController())

  const [initialLoading, setInitialLoading] = useState(true)
  const [selectedOrgs, setSelectedOrgs] = useState(null)
  const [selectedInterval, setSelectedInterval] = useState(availableIntervals.monthly)

  const [settingsAnchor, setSettingsAnchor] = useState(false)
  const [hideNonLiveProperties, setHideNonLiveProperties] = useState(true)

  useEffect(() => {
    doOrganizationListFetchAll({ active: true })
  }, [])

  useEffect(() => {
    if (smokeScoreboardDataIsLoading && smokeScoreboardHealthScoresIsLoading) {
      abortControllerRef.current.abort('AbortError')
    }
  }, [smokeScoreboardDataIsLoading, smokeScoreboardHealthScoresIsLoading])

  const fetchHealthScores = useCallback(async () => {
    try {
      abortControllerRef.current = new AbortController()

      await doFetchSmokeScoreboardHeathScores({
        properties: smokeScoreboardData.requestData.properties,
        start: smokeScoreboardData.requestData.start,
        end: smokeScoreboardData.requestData.end,
        signal: abortControllerRef.current.signal,
      })
    } catch (e) {
      const isAbortError = e?.name === 'AbortError' || e === 'AbortError'
      if (!isAbortError) {
        const parsedError = parseApiErrors(e?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [smokeScoreboardData])

  useEffect(() => {
    if (
      smokeScoreboardData &&
      !smokeScoreboardDataIsLoading &&
      selectedInterval === availableIntervals.monthly &&
      !initialLoading
    ) {
      fetchHealthScores()
    }
  }, [smokeScoreboardData, smokeScoreboardDataIsLoading, selectedInterval])

  const properties = useMemo(() => {
    if (!smokeScoreboardData?.properties) return null

    if (smokeScoreboardHealthScores) {
      return smokeScoreboardData.properties.map((property) => {
        const currentPeriodHealthScore =
          smokeScoreboardHealthScores[property.id]?.current?.score
        const previousPeriodHealthScore =
          smokeScoreboardHealthScores[property.id]?.previous?.score

        return {
          ...property,
          currentPeriod: {
            ...property.currentPeriod,
            healthScore: {
              raw: currentPeriodHealthScore,
              formatted:
                typeof currentPeriodHealthScore === 'number'
                  ? `${Math.round(currentPeriodHealthScore)}%`
                  : currentPeriodHealthScore,
            },
          },
          previousPeriod: {
            ...property.previousPeriod,
            healthScore: {
              raw: previousPeriodHealthScore,
              formatted:
                typeof previousPeriodHealthScore === 'number'
                  ? `${Math.round(previousPeriodHealthScore)}%`
                  : previousPeriodHealthScore,
            },
          },
        }
      })
    }
    return smokeScoreboardData.properties
  }, [smokeScoreboardData, smokeScoreboardHealthScores])

  const filteredProperties = useMemo(() => {
    if (hideNonLiveProperties) {
      return properties?.filter((property) => property.isLive)
    }
    return properties
  }, [properties, hideNonLiveProperties])

  const fetchScoreBoardData = useCallback(async (payload) => {
    try {
      await doFetchSmokeScoreboardData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      if (initialLoading) setInitialLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!selectedOrgs && allOrganizations) {
      setSelectedOrgs(allOrganizations)
      fetchScoreBoardData({
        organizations: allOrganizations.map((org) => org.id),
        interval: selectedInterval,
      })
    }
  }, [allOrganizations])

  const livePropertiesHealthScores = useMemo(() => {
    const result = { low: null, medium: null, high: null }
    if (filteredProperties) {
      filteredProperties
        .filter((property) => property.isLive)
        .forEach((property) => {
          const score = property.currentPeriod?.healthScore?.raw
          if (typeof score !== 'number') {
            return
          }

          if (score < 25) {
            result.low = result.low !== undefined ? result.low + 1 : 1
          } else if (score > 25 && score <= 50) {
            result.medium = result.medium !== undefined ? result.medium + 1 : 1
          } else {
            result.high = result.high !== undefined ? result.high + 1 : 1
          }
        })
    }
    return result
  }, [filteredProperties])

  const sensorsCount = useMemo(() => {
    if (!filteredProperties) return null

    let installed = 0
    let live = 0
    filteredProperties.forEach((property) => {
      installed += property.installedSensorCount ?? 0
      live += property.liveSensorCount ?? 0
    })

    return { installed, live }
  }, [filteredProperties])

  const formatPeriodRange = useCallback((range) => {
    const format = 'MM/dd/yy'
    const [start, end] = range
    return `${start.toFormat(format)} to ${end.toFormat(format)}`
  }, [])

  const formattedPeriodsRange = useMemo(() => {
    if (smokeScoreboardData) {
      return {
        current: formatPeriodRange(smokeScoreboardData.currentPeriodRange),
        previous: formatPeriodRange(smokeScoreboardData.previousPeriodRange),
      }
    }
    return null
  }, [smokeScoreboardData])

  const isLastRow = useCallback(
    (params) =>
      params.api.getRowIdFromRowIndex(params.api.getRowsCount() - 1) === params.row.id,
    [],
  )

  const shouldRenderHealthScore = useMemo(
    () => selectedInterval !== availableIntervals.weekly,
    [selectedInterval],
  )

  const processPeriodTotal = useCallback(
    ({ period }) => {
      const totalData = {}
      filteredProperties.forEach((property) => {
        if (property[period]) {
          Object.entries(property[period]).forEach(([metric, data]) => {
            if (data && metric !== 'healthScore') {
              if (metric in totalData) {
                totalData[metric] = {
                  ...totalData[metric],
                  data: [...totalData[metric].data, data.total?.value],
                }
              } else {
                totalData[metric] = {
                  data: [data.total?.value],
                  totalType: data.total?.type,
                  formatting: {
                    template: data.formatting?.template,
                    roundTo: data.formatting?.roundTo,
                    symbol: data.formatting?.symbol,
                  },
                }
              }
            }
          })
        }
      })

      const result = Object.keys(totalData).reduce(
        (acc, metric) => ({
          ...acc,
          [metric]: null,
        }),
        {},
      )

      Object.entries(totalData).forEach(([metric, data]) => {
        let rawValue = data.data.reduce((acc, value) => acc + value, 0)
        if (data.totalType === 'avg') {
          rawValue /= data.data.length
        }

        const { symbol, template, roundTo } = data.formatting
        const formattedValue =
          symbol === '$'
            ? template.replace('{{value}}', formatCurrency(rawValue))
            : template.replace(
                '{{value}}',
                roundTo ? roundUp(rawValue, roundTo) : rawValue,
              )

        result[metric] = { raw: rawValue, formatted: formattedValue }
      })

      return result
    },
    [filteredProperties],
  )

  const periodTotalsData = useMemo(
    () => ({
      current: filteredProperties
        ? processPeriodTotal({
            properties: filteredProperties,
            period: 'currentPeriod',
          })
        : null,
      previous: filteredProperties
        ? processPeriodTotal({
            properties: filteredProperties,
            period: 'previousPeriod',
          })
        : null,
    }),
    [filteredProperties],
  )

  const isTotalRow = useCallback(
    (row) => row.id === 'total_header' || row.id === 'total_footer',
    [],
  )

  const customColumnComparator = useCallback((v1, v2, param1, param2) => {
    const ignoreRows = ['total_header', 'total_footer']
    if (ignoreRows.includes(param1.id) || ignoreRows.includes(param2.id)) {
      return 0
    }

    return gridStringOrNumberComparator(v1, v2, param1, param2)
  }, [])

  const periodFields = useMemo(
    () => [
      {
        label: 'Events',
        metric: metrics.eventsTotal.apiValue,
        field: 'EventsTotal',
        minWidth: 80,
        cellClassName: (params) =>
          clsx('period-first-col', { last: isLastRow(params) }),
        headerClassPrefixBuilder: () => 'first',
      },
      {
        label: 'Incident Rate',
        metric: metrics.incidentRate.apiValue,
        field: 'IncidentRate',
        minWidth: 140,
        cellClassName: (params) => clsx('period-col', { last: isLastRow(params) }),
      },
      {
        label: 'Gross Charge Rate',
        metric: metrics.grossChargeRate.apiValue,
        field: 'GrossChargeRate',
        minWidth: 178,
        cellClassName: (params) => clsx('period-col', { last: isLastRow(params) }),
      },
      {
        label: 'Net Charge Rate',
        metric: metrics.chargeRate.apiValue,
        field: 'ChargeRate',
        minWidth: 162,
        cellClassName: (params) => clsx('period-col', { last: isLastRow(params) }),
      },
      {
        label: 'Net $ Collected',
        metric: metrics.netCharges.apiValue,
        field: 'NetCharges',
        minWidth: 156,
        cellClassName: (params) => clsx('period-col', { last: isLastRow(params) }),
      },
      {
        label: 'Connectivity',
        metric: metrics.connectivity.apiValue,
        field: 'Connectivity',
        minWidth: 138,
        cellClassName: (params, period) =>
          clsx(
            shouldRenderHealthScore && period === 'current'
              ? 'period-col'
              : 'period-last-col',
            { last: isLastRow(params) },
          ),
        headerClassPrefixBuilder: (period) => {
          if (shouldRenderHealthScore) {
            return period === 'current' ? null : 'monthly-last'
          }
          return 'last'
        },
      },
      ...(shouldRenderHealthScore
        ? [
            {
              label: 'Health Score',
              metric: 'healthScore',
              field: 'HealthScore',
              minWidth: 140,
              cellClassName: (params, period) =>
                period === 'current'
                  ? clsx('period-last-col', { last: isLastRow(params) })
                  : null,
              headerClassPrefixBuilder: (period) =>
                period === 'current' ? 'last' : null,
            },
          ]
        : []),
    ],
    [metrics, shouldRenderHealthScore],
  )

  const buildPeriodFields = useCallback(
    (period) => {
      const buildCellClassName = (params, field) =>
        field.cellClassName
          ? `${period}-${field.cellClassName(params, period)}`
          : `${period}-period-col`
      const buildHeaderClassName = (field) => {
        const prefix = field.headerClassPrefixBuilder
          ? field.headerClassPrefixBuilder(period)
          : null
        return prefix
          ? `${prefix}-${period}-period-header-cell`
          : `${period}-period-header-cell`
      }

      return periodFields.map((field) => ({
        display: 'flex',
        field: `${period}${field.field}`,
        headerName: field.label,
        sortable: true,
        minWidth: field.minWidth,
        type: 'number',
        sortComparator: customColumnComparator,
        cellClassName: (params) => buildCellClassName(params, field),
        headerClassName: buildHeaderClassName(field),
        renderHeader:
          field.field === 'HealthScore' && period === 'prev'
            ? () => (
                <Box>
                  <Typography variant="body2" fontWeight={500}>
                    {field.label}
                  </Typography>
                  <Typography fontSize={10}>Full Last Period</Typography>
                </Box>
              )
            : null,
        valueGetter: (_, row) => {
          const periodData =
            period === 'current' ? row.currentPeriod : row.previousPeriod
          return isTotalRow(row)
            ? periodTotalsData[period]?.[field.metric]?.raw
            : periodData?.[field.metric]?.raw
        },
        renderCell: ({ row }) => {
          const totalRow = isTotalRow(row)

          let periodData
          if (period === 'current') {
            periodData = totalRow ? periodTotalsData.current : row.currentPeriod
          } else {
            periodData = totalRow ? periodTotalsData.previous : row.previousPeriod
          }

          let color
          let icon

          const getHealthScoreColor = (score) => {
            if (typeof score !== 'number') return null

            if (score < 25) {
              return 'error.main'
            }
            if (score >= 25 && score <= 50) {
              return 'warning.main'
            }
            return 'success.main'
          }

          if (period === 'current') {
            const currentValue = totalRow
              ? periodTotalsData.current?.[field.metric]?.raw
              : row.currentPeriod?.[field.metric]?.raw
            const prevValue = totalRow
              ? periodTotalsData.previous?.[field.metric]?.raw
              : row.previousPeriod?.[field.metric]?.raw
            if (currentValue > prevValue) {
              color = 'success.main'
              icon = '↑'
            } else if (currentValue < prevValue) {
              color = 'error.main'
              icon = '↓'
            }
            if (field.metric === 'healthScore') {
              color = getHealthScoreColor(currentValue)
            }
          } else if (period === 'prev') {
            if (field.metric === 'healthScore') {
              const score = row.previousPeriod?.[field.metric]?.raw
              color = getHealthScoreColor(score)
            }
          }

          const value =
            field.metric === 'healthScore' && totalRow
              ? ''
              : periodData?.[field.metric]?.formatted
          const noValuePlaceholder =
            field.metric === 'healthScore' && smokeScoreboardHealthScoresIsLoading
              ? noDataPlaceholder
              : 'N/A'

          const fontSize = totalRow || isSmallScreen ? 12 : 14
          return (
            <Box display="flex" sx={{ opacity: totalRow ? 0.6 : 1 }}>
              <Typography fontSize={fontSize} color={color}>
                {icon ?? ''}
              </Typography>
              <Typography fontSize={fontSize} color={color}>
                {value ?? noValuePlaceholder}
              </Typography>
            </Box>
          )
        },
      }))
    },
    [
      isSmallScreen,
      periodFields,
      periodTotalsData,
      smokeScoreboardHealthScoresIsLoading,
    ],
  )

  const columns = [
    {
      display: 'flex',
      field: 'name',
      headerName: 'Property',
      sortable: true,
      minWidth: 220,
      sortComparator: customColumnComparator,
      renderCell: ({ row }) => {
        if (isTotalRow(row)) {
          return (
            <Typography fontSize={12} color="grey.700">
              Total / Weighted Average
            </Typography>
          )
        }
        return (
          <ClickableCell
            label={row.name}
            url={propertyUrls.entity.replace(':id', row.id)}
          />
        )
      },
    },
    {
      display: 'flex',
      field: 'accountName',
      headerName: 'Account',
      sortable: true,
      minWidth: 220,
      sortComparator: customColumnComparator,
      renderCell: ({ row }) => {
        if (isTotalRow(row)) return null
        return (
          <ClickableCell
            label={row.accountName}
            url={accUrls.entity.replace(':id', row.account)}
          />
        )
      },
    },
    {
      display: 'flex',
      field: 'installedSensorCount',
      headerName: 'Installed Sensors',
      sortable: true,
      type: 'number',
      minWidth: 140,
      sortComparator: customColumnComparator,
      valueGetter: (_, row) =>
        isTotalRow(row) ? '' : row.installedSensorCount ?? noDataPlaceholder,
    },
    {
      display: 'flex',
      field: 'liveSensorCount',
      headerName: 'Live Sensors',
      sortable: true,
      minWidth: 140,
      type: 'number',
      sortComparator: customColumnComparator,
      valueGetter: (_, row) =>
        isTotalRow(row) ? '' : row.liveSensorCount ?? noDataPlaceholder,
    },
    ...buildPeriodFields('prev'),
    ...buildPeriodFields('current'),
  ]

  const buildGroupingFields = useCallback(
    (prefix) => {
      if (prefix === 'prev') {
        return periodFields
          .filter((field) => field.field !== 'HealthScore')
          .map((field) => ({ field: `${prefix}${field.field}` }))
      }
      return periodFields.map((field) => ({ field: `${prefix}${field.field}` }))
    },
    [periodFields],
  )

  const totalPropertiesCount = useMemo(() => {
    if (hideNonLiveProperties) {
      return filteredProperties?.length
    }
    return smokeScoreboardData?.propertiesTotal
  }, [smokeScoreboardData, hideNonLiveProperties, filteredProperties])

  const livePropertiesCount = useMemo(() => {
    if (hideNonLiveProperties) {
      return filteredProperties?.length
    }
    return smokeScoreboardData?.propertiesLive
  }, [smokeScoreboardData, hideNonLiveProperties, filteredProperties])

  const MobileListItem = useCallback(
    (row) => {
      const prevPeriodFields = columns
        .filter((column) => column.field.startsWith('prev'))
        .map((column) => column.field)
      const currentPeriodFields = columns
        .filter((column) => column.field.startsWith('current'))
        .map((column) => column.field)

      const ignoredFields = ['name', ...prevPeriodFields, ...currentPeriodFields]
      const filteredColumns = columns.filter(
        (column) => !ignoredFields.includes(column.field),
      )
      const prevHealthScoreColumn = columns.find(
        (column) => column.field === 'prevHealthScore',
      )

      const periodBoxStyle = { border: 1, borderRadius: 1.5, p: 1 }

      return (
        <Stack spacing={0.5}>
          <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
            <Link href={propertyUrls.entity.replace(':id', row.id)}>
              <Typography variant="caption" fontWeight="bold">
                {row.name}
              </Typography>
            </Link>
          </Box>
          {filteredColumns.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return (
              <MobileListCardRow
                key={column.field}
                label={column.headerName}
                value={value}
              />
            )
          })}
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              sx={{ '&&': { mt: 2 }, ...periodBoxStyle }}
            >
              <Typography variant="caption" fontStyle="italic">
                {formattedPeriodsRange?.previous
                  ? `Last period ${formattedPeriodsRange.previous}`
                  : 'Last period'}
              </Typography>
              <Box>
                {columns
                  .filter(
                    (column) =>
                      prevPeriodFields.includes(column.field) &&
                      column.field !== 'prevHealthScore',
                  )
                  .map((column) => {
                    const value = getValueFromColumnDef({ row, column })
                    return (
                      <MobileListCardRow
                        key={column.field}
                        label={column.headerName}
                        value={value}
                      />
                    )
                  })}
              </Box>
            </Box>
            {prevHealthScoreColumn && (
              <Box sx={{ mt: 1, mx: 1.2 }}>
                <MobileListCardRow
                  key={prevHealthScoreColumn.field}
                  label="Health Score (Full Last Period)"
                  value={getValueFromColumnDef({ row, column: prevHealthScoreColumn })}
                />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            sx={{ '&&': { mt: 1 }, ...periodBoxStyle }}
          >
            <Typography variant="caption" fontStyle="italic">
              {formattedPeriodsRange?.current
                ? `This period ${formattedPeriodsRange.current}`
                : 'This period'}
            </Typography>
            <Box>
              {columns
                .filter((column) => currentPeriodFields.includes(column.field))
                .map((column) => {
                  const value = getValueFromColumnDef({ row, column })
                  return (
                    <MobileListCardRow
                      key={column.field}
                      label={column.headerName}
                      value={value}
                    />
                  )
                })}
            </Box>
          </Box>
        </Stack>
      )
    },
    [columns],
  )

  return (
    <>
      <Snackbar
        open={smokeScoreboardHealthScoresIsLoading}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert variant="filled" severity="info">
          Loading health scores...
        </Alert>
      </Snackbar>
      <Popover
        open={!!settingsAnchor}
        onClose={() => setSettingsAnchor(null)}
        anchorEl={settingsAnchor}
        anchorOrigin={{ vertical: 40, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack direction="column" px={2} py={0.5}>
          <FormControlLabel
            slotProps={{ typography: { fontSize: 14 } }}
            control={
              <Checkbox
                checked={hideNonLiveProperties}
                size="small"
                onChange={() => setHideNonLiveProperties((v) => !v)}
              />
            }
            label="Hide properties that are not live"
          />
        </Stack>
      </Popover>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems={isSmallScreen ? 'center' : 'end'}
        gap={2}
      >
        <Box display="flex" width={isSmallScreen ? '100%' : null}>
          <IconButton
            sx={{ mr: 1 }}
            onClick={(e) => setSettingsAnchor(e.currentTarget)}
          >
            <Settings />
          </IconButton>
          <StaticMultiSelect
            required
            onlyButton
            dynamicWidth
            fullWidth={isSmallScreen}
            disableSaveOnClear
            size="small"
            label="Organizations"
            sortByFields={['groupName', 'deviceCount']}
            sortOrder="desc"
            onSave={(orgs) =>
              fetchScoreBoardData({
                organizations: orgs.map((org) => org.id),
                interval: selectedInterval,
              })
            }
            groupBy={(opt) => opt.groupName}
            onOpen={() => doOrganizationListFetchAll({ active: true })}
            onChange={(orgs) => setSelectedOrgs(orgs)}
            options={allOrganizations}
            value={selectedOrgs ?? []}
            disabled={smokeScoreboardDataIsLoading}
            sx={{ minWidth: isSmallScreen ? null : 434 }}
            limitTags={isSmallScreen ? 2 : 3}
            renderGroup={(params) => (
              <li key={params.key}>
                <Typography
                  variant="h6"
                  sx={{
                    px: 2,
                    py: 1,
                    display: 'flex',
                    '&:hover > button': { visibility: 'visible' },
                    backgroundColor: 'primary.main',
                    color: 'white',
                  }}
                >
                  {params.group ?? 'No Group'}
                  <Button
                    variant="text"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      const filteredOrgs = allOrganizations.filter(
                        (org) => org.groupName === params.group,
                      )
                      setSelectedOrgs(filteredOrgs)
                    }}
                    sx={{
                      marginLeft: 'auto',
                      visibility: 'hidden',
                      color: 'white',
                    }}
                  >
                    only
                  </Button>
                </Typography>
                <ul style={{ padding: 0 }}>{params.children}</ul>
              </li>
            )}
          />
        </Box>
        <ToggleButtonGroup
          fullWidth={isSmallScreen}
          color="primary"
          value={selectedInterval}
          exclusive
          onChange={(_, selectedValue) => {
            if (selectedValue !== null) {
              setSelectedInterval(selectedValue)
              fetchScoreBoardData({
                organizations: selectedOrgs.map((org) => org.id),
                interval: selectedValue,
              })
            }
          }}
          disabled={smokeScoreboardDataIsLoading}
        >
          {Object.keys(availableIntervals).map((key) => {
            const interval = availableIntervals[key]
            return (
              <ToggleButtonWithTooltip
                key={key}
                value={interval}
                tooltip={interval.tooltip}
                disabled={selectedInterval === interval}
                sx={{ py: 0.8 }}
              >
                <div>{interval.label}</div>
              </ToggleButtonWithTooltip>
            )
          })}
        </ToggleButtonGroup>
      </Box>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        gap={2}
        mt={2}
      >
        <Card sx={cardStyle}>
          <Typography variant="h6" fontSize={18}>
            Properties
          </Typography>
          <Typography variant="h5">
            {totalPropertiesCount ?? noDataPlaceholder}
          </Typography>
        </Card>
        <Card sx={cardStyle}>
          <Typography variant="h6" fontSize={18}>
            Live Properties
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h5">
              {livePropertiesCount ?? noDataPlaceholder}
            </Typography>
            {selectedInterval === availableIntervals.monthly && (
              <Box display="flex" gap={0.5}>
                [
                <Typography variant="h6" fontSize={16}>
                  <Box display="inline" color="success.main">
                    {livePropertiesHealthScores.high ?? noDataPlaceholder}
                  </Box>{' '}
                  /{' '}
                  <Box display="inline" color="warning.main">
                    {livePropertiesHealthScores.medium ?? noDataPlaceholder}
                  </Box>{' '}
                  /{' '}
                  <Box display="inline" color="error.main">
                    {livePropertiesHealthScores.low ?? noDataPlaceholder}
                  </Box>
                </Typography>
                ]
              </Box>
            )}
          </Box>
        </Card>
        <Card sx={cardStyle}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Tooltip title="Live / Installed">
              <InfoOutlined sx={{ fontSize: 16, color: 'primary.main' }} />
            </Tooltip>
            <Typography variant="h6" fontSize={18}>
              Live Sensors
            </Typography>
          </Box>
          <Typography variant="h5">
            {sensorsCount
              ? `${sensorsCount.live} / ${sensorsCount.installed}`
              : noDataPlaceholder}
          </Typography>
        </Card>
      </Box>

      {isSmallScreen ? (
        <MobileList
          hideFooter
          title="Dashboard"
          loading={smokeScoreboardDataIsLoading}
          itemBuilder={MobileListItem}
          page={1}
          pageSize={9999}
          rows={filteredProperties ?? []}
          rowCount={filteredProperties?.length ?? 0}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: 'calc(100vh - 320px)',
            mt: 2,
            '& .MuiDataGrid-root, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeaders':
              { border: 'none' },
            '& .MuiDataGrid-root': {
              '--DataGrid-rowBorderColor': 'transparent !important',
            },
            '& .prev-period-first-col, & .first-prev-period-header-cell': {
              borderLeft: periodBorderStyle,
            },
            '& .first-current-period-header-cell': {
              borderLeft:
                selectedInterval === availableIntervals.monthly
                  ? periodBorderStyle
                  : null,
            },
            '& .monthly-last-prev-period-header-cell': {
              borderRight: periodBorderStyle,
            },
            '& .current-period-first-col': {
              borderLeft:
                selectedInterval === availableIntervals.monthly
                  ? periodBorderStyle
                  : null,
            },
            '& .current-period-last-col': {
              borderRight: periodBorderStyle,
            },
            '& .prev-period-first-col.last, & .prev-period-last-col.last, & .prev-period-col.last, & .current-period-first-col.last, & .current-period-last-col.last, & .current-period-col.last':
              {
                borderBottom: periodBorderStyle,
                borderBottomColor: `${periodBorderColor} !important`,
              },
            ' & .prev-period-last-col, & .current-period-last-col': {
              borderRight: periodBorderStyle,
            },
            '& .last-prev-period-header-cell, & .last-current-period-header-cell': {
              borderRight: periodBorderStyle,
            },
            '& .prev-period-header-group': {
              borderTop: periodBorderStyle,
              borderLeft: periodBorderStyle,
              borderRight: periodBorderStyle,
            },
            '& .current-period-header-group': {
              borderLeft:
                selectedInterval === availableIntervals.monthly
                  ? periodBorderStyle
                  : null,
              borderTop: periodBorderStyle,
              borderRight: periodBorderStyle,
            },
          }}
        >
          <DataGridPro
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            hideFooter
            columnHeaderHeight={32}
            rowHeight={20}
            initialState={{ pinnedColumns: { left: ['name'] } }}
            columnGroupingModel={[
              {
                groupId: 'current_period',
                headerName: formattedPeriodsRange?.current
                  ? `This period ${formattedPeriodsRange.current}`
                  : 'This period',
                headerAlign: 'center',
                headerClassName: 'current-period-header-group',
                children: buildGroupingFields('current'),
              },
              {
                groupId: 'previous_period',
                headerName: formattedPeriodsRange?.previous
                  ? `Last period ${formattedPeriodsRange.previous}`
                  : 'Last period',
                headerAlign: 'center',
                headerClassName: 'prev-period-header-group',
                children: buildGroupingFields('prev'),
              },
            ]}
            columns={columns}
            loading={smokeScoreboardDataIsLoading}
            density="comfortable"
            rows={
              filteredProperties
                ? [
                    { id: 'total_header' },
                    ...filteredProperties,
                    { id: 'total_footer' },
                  ]
                : []
            }
            sx={{
              '& .MuiDataGrid-columnHeader': {
                borderTop: defaultBorderStyle,
                borderBottom: defaultBorderStyle,
              },
              '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                borderLeft: defaultBorderStyle,
              },
              '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                borderBottom: defaultBorderStyle,
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                marginBottom: 1,
                marginRight: 1,
              },
              '& .MuiDataGrid-container--top:after': { height: 0 },
              '& .MuiDataGrid-columnSeparator': { display: 'none' },
              '& .MuiDataGrid-filler--pinnedLeft': { borderRight: 'none' },
              '& .MuiDataGrid-row': { '--rowBorderColor': 'transparent' },
              '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell--withRightBorder, & .MuiDataGrid-columnHeader--withRightBorder':
                {
                  borderRight: 'none',
                  boxShadow: `0.5px 0px 0px ${defaultBorderColor}`,
                },
              '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
              '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns': {
                boxShadow: `0.5px 0px 0px ${defaultBorderColor}`,
              },
              '& .clickableCell': (theme) => ({
                cursor: 'pointer',
                color: 'primary.main',
                ...theme.typography.subtitle2,
              }),
            }}
          />
        </Box>
      )}
    </>
  )
}
