import { DateTime, Interval } from 'luxon'

export default (value) => {
  const determineAgo = Interval.fromDateTimes(value, DateTime.now())
    .toDuration(['weeks', 'days', 'hours', 'minutes'])
    .toHuman({ unitDisplay: 'short' })
    .split(',')
    .filter((time) => parseInt(time.trim().charAt(0), 10) > 0)
    .map((selectedTime) => {
      const arr = selectedTime.trim().split(' ')
      arr[0] = Math.round(arr[0])
      return `${arr[0]} ${arr[1]}`
    })
    .join(' ')
  return `${determineAgo} ago`
}
