import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CloseOutlined, DeleteSweepOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { parseApiErrors, useSmallScreen } from '@common/utils'
import deviceUrls from '@portal/pages/Devices/urls'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.value
 * @param {Function} props.onValueClick
 * @param {number} props.complete
 * @param {number} props.total
 */
function LinearProgressWithLabel({ value, onValueClick, complete, total }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ width: '90%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box sx={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          variant="body2"
          color="text.secondary"
          component={Link}
          onClick={onValueClick}
          sx={{ cursor: 'pointer' }}
        >{`${Math.round(value)}% (${complete} of ${total})`}</Typography>
      </Box>
    </Box>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object[]} [props.devices]
 * @param {string} [props.devices[].id]
 * @param {string} [props.devices[].mainMac]
 * @param {string} [props.devices[].unit]
 */
function UnaffectedDevicesForm({ open, onClose, devices = undefined }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 3, pb: 2, px: 4 }}
      >
        Devices without desired credentials
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ px: 2 }}>
        <TableContainer sx={{ alignSelf: 'center' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>MAC</TableCell>
                <TableCell>Unit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices?.map((device) => (
                <TableRow
                  key={device.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Link href={deviceUrls.entity.replace(':id', device.id)}>
                      {device.mainMac}
                    </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {device.unitName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.property]
 * @param {string} props.property.id
 * @param {string} [props.property.currentWifiSsid]
 * @param {string} [props.property.currentWifiPw]
 * @param {string} [props.property.desiredWifiSsid]
 * @param {string} [props.property.desiredWifiPw]
 * @param {Function} [props.onEdit]
 * @param {number} [props.margin]
 * @param {number} [props.gap]
 */
export default function WifiComponent({
  property = undefined,
  margin = 0,
  gap = 0,
  onEdit = undefined,
}) {
  const isSmallScreen = useSmallScreen()

  const [openClearConfirmation, setOpenClearConfirmation] = useState(false)
  const [openUnaffectedDevices, setOpenUnaffectedDevices] = useState(false)

  const {
    propertyDetailsDeviceConnectivityIsLoading,
    propertyDetailsDeviceConnectivity,
    doFetchPropertyDeviceConnectivity,
    doFetchPropertyDetails,
    doPropertySave,
    doShowSnackbar,
  } = useConnect(
    'selectPropertyDetailsDeviceConnectivityIsLoading',
    'selectPropertyDetailsDeviceConnectivity',
    'doFetchPropertyDeviceConnectivity',
    'doFetchPropertyDetails',
    'doPropertySave',
    'doShowSnackbar',
  )

  const numDevicesAffected = propertyDetailsDeviceConnectivity?.numDevicesAffected || 0
  const numDevicesUnaffected =
    propertyDetailsDeviceConnectivity?.devicesUnaffected?.length || 0

  const fetchConnectivityData = useCallback(async () => {
    try {
      await doFetchPropertyDeviceConnectivity(property?.id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [property])

  const clearDesiredCredentials = useCallback(async () => {
    try {
      await doPropertySave({
        ...property,
        desiredWifiSsid: '',
        desiredWifiPw: '',
      })
      await doFetchPropertyDetails(property.id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [property])

  useEffect(() => {
    if (property) {
      fetchConnectivityData()
    }
  }, [property])

  useEffect(() => {
    if (property && propertyDetailsDeviceConnectivityIsLoading) {
      setTimeout(fetchConnectivityData, 60000)
    }
  }, [propertyDetailsDeviceConnectivity])

  const DetailRow = useCallback(
    ({ label, ssid, pw, onClear }) => (
      <Stack spacing={2}>
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography
            variant="body2"
            color="text.secondary"
            textTransform="uppercase"
            sx={{ fontSize: isSmallScreen ? 10 : 14 }}
          >
            {label}
          </Typography>
          {onClear && (
            <IconButton size="small" onClick={onClear}>
              <DeleteSweepOutlined sx={{ fontSize: isSmallScreen ? 18 : 22 }} />
            </IconButton>
          )}
        </Box>
        <Stack direction="row" gap={2}>
          <DetailItem label="SSID" value={ssid} />
          <DetailItem label="Password" value={pw} />
        </Stack>
      </Stack>
    ),
    [isSmallScreen],
  )

  return (
    <>
      <ConfirmationDialog
        fullWidth={false}
        title="Clear credentials"
        message="Are you sure you want to clear desired WiFi credentials?"
        open={openClearConfirmation}
        onConfirm={() => {
          setOpenClearConfirmation(false)
          clearDesiredCredentials()
        }}
        onCancel={() => setOpenClearConfirmation(false)}
      />
      <UnaffectedDevicesForm
        open={openUnaffectedDevices}
        onClose={() => setOpenUnaffectedDevices(false)}
        devices={propertyDetailsDeviceConnectivity?.devicesUnaffected}
      />
      <Stack direction="row" alignItems="start">
        <DetailContainer margin={margin} gap={gap}>
          <DetailRow
            label="Current Wi-Fi credentials"
            ssid={property?.currentWifiSsid}
            pw={property?.currentWifiPw}
          />
          <Divider />
          <DetailRow
            label="Desired Wi-Fi credentials"
            ssid={property?.desiredWifiSsid}
            pw={property?.desiredWifiPw}
            onClear={
              property?.desiredWifiSsid ? () => setOpenClearConfirmation(true) : null
            }
          />
          {property?.desiredWifiSsid && (
            <Stack sx={{ width: 1 }}>
              <Divider sx={{ mb: 2 }} />
              <Typography
                variant="body2"
                color="text.secondary"
                textTransform="uppercase"
                sx={{ fontSize: isSmallScreen ? 10 : 14, mb: 1 }}
              >
                Migration Progress
              </Typography>
              {propertyDetailsDeviceConnectivityIsLoading && (
                <Box sx={{ display: 'flex', minHeight: '24px', alignItems: 'center' }}>
                  <LinearProgress sx={{ width: 1 }} />
                </Box>
              )}
              {!propertyDetailsDeviceConnectivityIsLoading && (
                <LinearProgressWithLabel
                  value={propertyDetailsDeviceConnectivity?.percentage ?? 0}
                  onValueClick={() => setOpenUnaffectedDevices(true)}
                  complete={numDevicesAffected}
                  total={numDevicesAffected + numDevicesUnaffected}
                />
              )}
            </Stack>
          )}
        </DetailContainer>
        {onEdit && (
          <Button onClick={onEdit} sx={{ mt: 1, fontSize: isSmallScreen ? 12 : 14 }}>
            Edit
          </Button>
        )}
      </Stack>
    </>
  )
}
