import { Box, Typography } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.label
 * @param {Function} [props.value]
 * @param {Function} [props.renderValue]
 * @param {Function} [props.bold]
 * @param {Function} [props.sx]
 */
export default function MobileDetailRow({
  label,
  value = undefined,
  renderValue = undefined,
  bold = false,
  sx = {},
}) {
  return (
    <Box display="flex" justifyContent="space-between" sx={sx}>
      <Typography fontFamily="Degular" fontSize={16} fontWeight={bold ? 600 : 500}>
        {label}
      </Typography>
      {renderValue ? (
        renderValue(value)
      ) : (
        <Typography fontSize={14}>{value}</Typography>
      )}
    </Box>
  )
}
