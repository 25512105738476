import { useCallback, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Apartment,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
  ListAlt,
} from '@mui/icons-material'
import {
  Box,
  Collapse,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { useSmallScreen } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'
import smokeProfilesUrls from '@portal/pages/SmokeProfiles/urls'
import DetailItem from '@portal/UI/components/DetailItem'

const cardStyles = { borderRadius: '5px', boxShadow: 2 }
const cardHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mx: 2,
  my: 1,
}

const ALERTING_PROPERTIES_HINT =
  'Properties that are currently using this ensemble as their default alerting mechanism, going out to end users.'
const SECONDARY_PROFILES_HINT =
  'For ensembles that require additional votes, when the primary profile triggers an alarm, we search in a window for alarms from these secondary profiles. The number of secondary profiles that also triggered in that window is the number of votes, which determines if we approve or reject the event.'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {'profiles'|'properties'} props.type
 */
function CardEmptyState({ type }) {
  const Icon = type !== 'profiles' ? Apartment : ListAlt
  const text =
    type !== 'profiles' ? 'No alerting properties found' : 'No secondary profiles found'
  return (
    <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
      <Icon sx={{ fontSize: 60, mb: 2 }} />
      {text}
    </Stack>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.data
 * @param {string} props.data.primaryProfile
 * @param {string[]} [props.data.secondaryProfiles]
 * @param {Object[]} [props.data.alertingProperties]
 * @param {number} [props.data.additionalVotesNeeded]
 * @param {string} [props.data.windowType]
 */
export default function EnsembleTab({ data }) {
  const isSmallScreen = useSmallScreen()

  const { systemSmokeProfiles, systemSmokeProfileEnsembleWindowTypes } = useConnect(
    'selectSystemSmokeProfiles',
    'selectSystemSmokeProfileEnsembleWindowTypes',
  )

  const [openAlertingProperties, setOpenAlertingProperties] = useState(true)
  const [openSecondaryProfiles, setOpenSecondaryProfiles] = useState(true)

  const secondaryProfilesData = useMemo(() => {
    if (data?.secondaryProfiles) {
      return data.secondaryProfiles.map((id) =>
        systemSmokeProfiles.find((profile) => profile.id === id),
      )
    }
    return []
  }, [data, systemSmokeProfiles])

  const getProfileName = useCallback(
    (profile) => `${profile.modelKey} - ${profile.name}`,
    [],
  )

  const primaryProfileData = useMemo(() => {
    if (data?.primaryProfile) {
      return systemSmokeProfiles.find((profile) => profile.id === data?.primaryProfile)
    }
    return null
  }, [data, systemSmokeProfiles])

  const getWindowTypeFullName = useCallback(
    (windowType) =>
      systemSmokeProfileEnsembleWindowTypes.find((type) => type.value === windowType)
        ?.name ?? '',
    [systemSmokeProfileEnsembleWindowTypes],
  )

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap={6} mt={2}>
        <DetailItem
          label="Primary Profile"
          value={getProfileName(primaryProfileData)}
          linkTo={
            primaryProfileData
              ? smokeProfilesUrls.entity.replace(':id', primaryProfileData.id)
              : null
          }
        />
        <DetailItem
          label="Window Type"
          value={getWindowTypeFullName(data.windowType)}
        />
        <DetailItem
          label="Additional Votes Needed"
          value={data.additionalVotesNeeded}
        />
      </Box>
      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        gap={isSmallScreen ? 0 : 3}
        alignItems="start"
      >
        <Stack
          direction="column"
          display="flex"
          width="100%"
          sx={{ ...cardStyles, mt: 2 }}
        >
          <Box {...cardHeaderStyles}>
            <Box display="flex" alignItems="center">
              <Tooltip title={SECONDARY_PROFILES_HINT}>
                <InfoOutlined fontSize="small" color="primary" />
              </Tooltip>
              <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 0.5 }}>
                Secondary Profiles {`(${secondaryProfilesData.length})`}
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenSecondaryProfiles((prev) => !prev)}>
              {openSecondaryProfiles ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={openSecondaryProfiles}>
            <Box
              display="grid"
              justifyContent={isEmpty(secondaryProfilesData) ? 'center' : 'start'}
              gap={2}
              mx={2}
              mb={2}
            >
              {isEmpty(secondaryProfilesData) && <CardEmptyState type="profiles" />}
              {secondaryProfilesData.map((profile) => (
                <Link
                  key={profile.id}
                  href={smokeProfilesUrls.entity.replace(':id', profile.id)}
                >
                  <Typography>{`${profile.modelKey} - ${profile.name}`}</Typography>
                </Link>
              ))}
            </Box>
          </Collapse>
        </Stack>
        <Stack
          direction="column"
          display="flex"
          width="100%"
          sx={{ ...cardStyles, mt: 2 }}
        >
          <Box {...cardHeaderStyles}>
            <Box display="flex" alignItems="center">
              <Tooltip title={ALERTING_PROPERTIES_HINT}>
                <InfoOutlined fontSize="small" color="primary" />
              </Tooltip>
              <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 0.5 }}>
                Alerting Properties {`(${data.alertingProperties.length})`}
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenAlertingProperties((prev) => !prev)}>
              {openAlertingProperties ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={openAlertingProperties}>
            <Box
              display="grid"
              justifyContent={isEmpty(data.alertingProperties) ? 'center' : 'start'}
              gap={2}
              mx={2}
              mb={2}
            >
              {isEmpty(data.alertingProperties) && <CardEmptyState type="properties" />}
              {data.alertingProperties.map((property) => (
                <Link
                  key={property.id}
                  href={propertyUrls.entity.replace(':id', property.id)}
                >
                  <Typography>{property.name}</Typography>
                </Link>
              ))}
            </Box>
          </Collapse>
        </Stack>
      </Stack>
    </Box>
  )
}
