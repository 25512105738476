import { useCallback, useMemo } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { InputAdornment, Stack } from '@mui/material'

import { Field } from 'formik'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { merge, parseApiErrors } from '@common/utils'
import { getAdjustmentReasonsOptions } from '@common/utils/adjustmentReasonsUtils'
import { FormDialog } from '@rest/UI/components'

const validationSchema = Yup.object().shape({
  adjustmentAmount: Yup.number()
    .typeError('Please enter a valid amount')
    .test(
      'is-decimal',
      'Please enter a valid amount',
      (value) =>
        value === undefined ||
        value === 0 ||
        `${value}`.match(/^[1-9]\d*(\.\d{0,2})?$/),
    ),
  adjustmentReason: Yup.string().when('adjustmentAmount', {
    is: (val) => val !== '' && val !== undefined,
    then: (field) => field.required('Please select a reason for adjustment'),
    otherwise: (field) => field.nullable(),
  }),
  comments: Yup.string().when('adjustmentReason', {
    is: (val) => val === 'OTHER',
    then: (field) =>
      field
        .required('Please enter a comment for adjustment')
        .matches(/\s*(?:[\w.]\s*){10,}$/, 'Please enter at least 10 characters'),
    otherwise: (field) => field.nullable(),
  }),
})

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {Object} [props.instance.smokeFeedback]
 */
export default function EditFeedback({
  onClose,
  instance: { smokeFeedback: feedback } = {},
}) {
  const { doEventFeedbackSave, smokeAdjustmentReasons } = useConnect(
    'doEventFeedbackSave',
    'selectSmokeAdjustmentReasons',
  )

  const initialValues = merge(
    {
      adjustmentAmount: '',
      adjustmentReason: null,
      chargeAmount: '',
      comments: '',
    },
    {
      ...feedback,
      adjustmentAmount: parseFloat(feedback.adjustmentAmount)
        ? feedback.netChargeAmount
        : '',
      adjustmentReason:
        feedback.adjustmentReason === '' ? null : feedback.adjustmentReason,
    },
  )

  const adjustmentReasonsOptions = useMemo(
    () =>
      getAdjustmentReasonsOptions({
        reasons: smokeAdjustmentReasons,
        initialReason: initialValues.adjustmentReason,
      }),
    [initialValues, smokeAdjustmentReasons],
  )

  const onFeedbackSave = useCallback(
    async (data) => {
      const final = {
        ...feedback,
        ...data,
        id: feedback.id,
        adjustmentAmount: data.adjustmentAmount || 0,
        adjustmentReason: data.adjustmentReason || '',
      }

      if (data.adjustmentAmount) {
        const initialCharge = parseFloat(feedback.chargeAmount)
        const newAdjustment = parseFloat(data.adjustmentAmount)

        final.adjustmentAmount = parseFloat(initialCharge - newAdjustment).toFixed(2)

        if (final.adjustmentAmount !== parseFloat(feedback.adjustmentAmount)) {
          final.adjustedOn = DateTime.now().toISO()
        }
        if (final.adjustmentAmount === data.netChargeAmount) {
          final.adjustmentAmount = 0
        }
        final.adjustmentAmount *= -1
      }

      const result = await doEventFeedbackSave(final)
      if (result.error) throw parseApiErrors(result)
      return result
    },
    [doEventFeedbackSave, feedback],
  )

  return (
    <FormDialog
      open
      data-testid="edit_feedback_dialog"
      onClose={onClose}
      initialValues={initialValues}
      label="Smoking Event Feedback"
      validationSchema={validationSchema}
      onSave={onFeedbackSave}
      successMessage="Successfully adjusted feedback information"
    >
      <Stack spacing={2} mt={0}>
        <Field
          component={TextField}
          name="chargeAmount"
          label="Original Charge Amount"
          disabled
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            disabled: true,
          }}
        />

        <Field
          component={TextField}
          name="adjustmentAmount"
          label="Adjusted Charge Amount"
          placeholder="If adjusted, enter final dollar value for this event"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />

        <Field
          component={StaticSelect}
          name="adjustmentReason"
          label="Adjustment Reason"
          options={adjustmentReasonsOptions}
          defaultValue={adjustmentReasonsOptions.find(
            (reason) => reason.id === initialValues.adjustmentReason,
          )}
        />

        <Field
          component={TextField}
          name="comments"
          label="Comments"
          InputProps={{ multiline: true }}
        />
      </Stack>
    </FormDialog>
  )
}
