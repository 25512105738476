import { useConnect } from 'redux-bundler-hook'

import {
  Box,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import urls from '@rest/pages/Properties/urls'
import { formatSubitemCount } from '@rest/Utils/formatMenuStrings'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.anchor]
 * @param {Function} props.onClose
 */
export default function PlacesMenu({ anchor = undefined, onClose }) {
  const {
    routeInfo: { pattern },
    availableOrganizations,
    availableAccounts,
    currentOrganization,
    currentAccount,
    currentProperty,
    currentAccountDetails,
    header: propertyList,
    headerLevel,
    doSetCurrentOrganization,
    doSetCurrentAccount,
    doSetCurrentProperty,
    doUpdateUrl,
  } = useConnect(
    'selectRouteInfo',
    'selectAvailableOrganizations',
    'selectAvailableAccounts',
    'selectCurrentOrganization',
    'selectCurrentAccount',
    'selectCurrentProperty',
    'selectCurrentAccountDetails',
    'selectHeader',
    'selectHeaderLevel',
    'doSetCurrentOrganization',
    'doSetCurrentAccount',
    'doSetCurrentProperty',
    'doUpdateUrl',
  )

  return (
    <Menu
      disableScrollLock
      data-testid="placesMenu"
      open={!!anchor}
      anchorEl={anchor}
      onClose={onClose}
      sx={{ '& .MuiMenu-list': { minWidth: '300px', maxHeight: '400px' } }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Box display="flex" flexDirection="column" px={1.5} py={1} gap={2}>
        <FormControl fullWidth>
          <InputLabel>Organization</InputLabel>
          <Select
            size="small"
            value={currentOrganization}
            label="Organization"
            MenuProps={{ disableScrollLock: true }}
          >
            {availableOrganizations?.map((org) => {
              const caption = formatSubitemCount('Account', org.accountCount)
              return (
                <MenuItem
                  key={org.id}
                  selected={org.id === currentOrganization}
                  value={org.id}
                  onClick={() => {
                    onClose()
                    doSetCurrentOrganization(org.id)
                    doUpdateUrl('/')
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>{org.name}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {caption}
                    </Typography>
                  </Box>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        {availableAccounts?.length > 1 && headerLevel !== 'organization' && (
          <FormControl fullWidth>
            <InputLabel>Account</InputLabel>
            <Select
              size="small"
              value={currentAccount}
              label="Account"
              MenuProps={{ disableScrollLock: true }}
            >
              {availableAccounts?.map((acc) => {
                const caption = formatSubitemCount('Property', acc.propertyCount)
                return (
                  <MenuItem
                    key={acc.id}
                    selected={acc.id === currentAccount}
                    value={acc.id}
                    onClick={() => {
                      onClose()
                      doSetCurrentAccount(acc.id)
                      doSetCurrentProperty(null)
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>{acc.name}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {caption}
                      </Typography>
                    </Box>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
        {currentAccountDetails?.propertyCount > 0 && currentProperty && (
          <FormControl fullWidth>
            <InputLabel>Property</InputLabel>
            <Select
              size="small"
              value={currentProperty?.id}
              label="Property"
              MenuProps={{ disableScrollLock: true }}
            >
              {propertyList?.results?.map((property) => {
                const caption = formatSubitemCount('Property', 1)
                return (
                  <MenuItem
                    key={property.id}
                    selected={property.id === currentProperty?.id}
                    value={property.id}
                    onClick={() => {
                      onClose()
                      doSetCurrentProperty(property.id)
                      if (pattern.indexOf('/properties/:id') !== -1) {
                        doUpdateUrl(pattern.replace(':id', property.id))
                      } else {
                        doUpdateUrl(urls.entity.replace(':id', property.id))
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>{property.name}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {caption}
                      </Typography>
                    </Box>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </Box>
    </Menu>
  )
}
