import { useCallback, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Typography,
} from '@mui/material'

import { parseApiErrors } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Function} props.onContinue
 * @param {Function} props.onOverwrite
 * @param {Object[]} props.scenarios
 * @param {Object[]} props.timeRange
 */
export default function ScenarioOverlapConfirmation({
  open,
  onClose,
  scenarios,
  timeRange,
  onContinue,
  onOverwrite,
}) {
  const overwriteAvailable = scenarios.length === 1

  const [overwriting, setOverwriting] = useState(false)

  const { doScenarioUpdateFromReadings, doShowSnackbar } = useConnect(
    'doScenarioUpdateFromReadings',
    'doShowSnackbar',
  )

  const handleScenarioOverwrite = useCallback(
    async (scenario) => {
      setOverwriting(true)
      try {
        const result = await doScenarioUpdateFromReadings({
          scenario,
          timeRange: { readingStart: timeRange[0], readingEnd: timeRange[1] },
        })
        if (result.error) {
          throw result.error
        }
        onOverwrite()
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      } finally {
        setOverwriting(false)
      }
    },
    [timeRange],
  )

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <Box>
        <DialogContent>
          <Typography>
            The scenario you are trying to save overlaps with other{' '}
            {overwriteAvailable ? 'scenario' : 'scenarios'}. Are you sure you wish to
            continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onContinue} color="secondary">
            Ignore and continue
          </Button>
          <Tooltip
            title={
              !overwriteAvailable && !overwriting
                ? 'Overwrite available only for single scenario'
                : null
            }
            placement="top"
          >
            <span>
              <Button
                onClick={() => handleScenarioOverwrite(scenarios[0])}
                color="secondary"
                disabled={!overwriteAvailable || overwriting}
              >
                Overwrite existing scenario
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
