const availableIntervals = {
  daily: { value: 1, label: '1D', tooltip: 'Daily', type: 'days' },
  weekly: { value: 7, label: '1W', tooltip: 'Weekly', type: 'weeks' },
  monthly: { value: 30, label: '1M', tooltip: 'Monthly', type: 'months' },
}

const weeklyRanges = {
  one: { value: 1, label: '1w', tooltip: '1 Week' },
  two: { value: 2, label: '2w', tooltip: '2 Weeks' },
  three: { value: 3, label: '3w', tooltip: '3 Weeks' },
  four: { value: 4, label: '4w', tooltip: '4 Weeks' },
  custom: { value: 'custom' },
}

const monthlyRanges = {
  one: { value: 1, label: '1m', tooltip: '1 Month' },
  two: { value: 2, label: '2m', tooltip: '2 Months' },
  three: { value: 3, label: '3m', tooltip: '3 Months' },
  six: { value: 4, label: '4m', tooltip: '4 Months' },
  custom: { value: 'custom' },
}

const datePresets = {
  week: {
    value: '1w',
    tooltip: '1 Week',
  },
  month: {
    value: '30d',
    tooltip: '30 Days',
  },
  twoMonths: {
    value: '60d',
    tooltip: '60 Days',
  },
  threeMonths: {
    value: '90d',
    tooltip: '90 Days',
  },
  monthToDate: {
    value: 'mtd',
    tooltip: 'Month To Date',
  },
  lastMonth: {
    value: 'lm',
    tooltip: 'Last Month',
  },
  yearToDate: {
    value: 'ytd',
    tooltip: 'Year To Date',
  },
  custom: {
    value: 'custom',
  },
}

export default function getSmokeMetricsConstants() {
  return {
    availableIntervals,
    datePresets,
    weeklyRanges,
    monthlyRanges,
  }
}
