import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CancelOutlined, CheckCircleOutline, LineAxis } from '@mui/icons-material'
import { Box, Button, Link, Stack, Typography } from '@mui/material'

import { MobileList, MobileListDefaultCard, SearchBox } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { useSmallScreen } from '@common/utils'
import AddThreshold from '@portal/pages/ThresholdManagement/NewThresholdWizard/AddThreshold'
import RulesRow from '@portal/pages/ThresholdManagement/RulesRow'
import thresholdManagementUrls from '@portal/pages/ThresholdManagement/urls'
import { BooleanCell, MainCell } from '@portal/UI/components/cells'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/ProList'
import boolOptions from '@portal/Utils/constants'

function ListEmptyState() {
  const {
    thresholdProfileListRaw: { filter, search },
  } = useConnect('selectThresholdProfileListRaw')

  const activeFilters = search !== '' || filter?.active

  return (
    <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
      <LineAxis sx={{ fontSize: 60, mb: 2 }} />
      {activeFilters
        ? 'No threshold profiles match the selected filters'
        : 'No threshold profiles have been assigned to this property yet.'}
    </Stack>
  )
}

export default function ThresholdsTab() {
  const [pageSize, setPageSize] = useState('')

  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const isSmallScreen = useSmallScreen()

  const {
    isAtLeastAdmin,
    routeParams: { id: propertyId },
    thresholdProfileList,
    thresholdProfileListApiParams,
    thresholdProfileListIsLoading,
    doThresholdProfileListSetOrdering,
    doThresholdProfileListClearParams,
    doThresholdProfileListSetFilter,
    doThresholdProfileListSetPage,
    doThresholdProfileListSetPageSize,
    doThresholdProfileListSetSearch,
    doMarkThresholdProfileListAsOutdated,
    doUpdateUrl,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectRouteParams',
    'selectThresholdProfileList',
    'selectThresholdProfileListApiParams',
    'selectThresholdProfileListIsLoading',
    'doThresholdProfileListSetOrdering',
    'doThresholdProfileListClearParams',
    'doThresholdProfileListSetFilter',
    'doThresholdProfileListSetPage',
    'doThresholdProfileListSetPageSize',
    'doThresholdProfileListSetSearch',
    'doMarkThresholdProfileListAsOutdated',
    'doUpdateUrl',
  )

  const handleInitialParams = () => {
    doThresholdProfileListClearParams()
    doThresholdProfileListSetFilter({})
  }

  useEffect(() => handleInitialParams(), [])

  const handlePageChange = async (page) => doThresholdProfileListSetPage(page)

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doThresholdProfileListSetPageSize(size)
  }

  const handleRowClick = (profile) => {
    const updatedId = thresholdManagementUrls.entity.replace(':id', profile.id)
    doUpdateUrl(updatedId)
  }

  const handleCreateAction = () => {
    setCreateDialogOpen(true)
  }

  const detailRowContent = useCallback(
    ({ row }) => (row.rules.length === 0 ? null : <RulesRow rules={row.rules} />),
    [thresholdProfileList],
  )

  const detailRowHeight = useCallback(() => 'auto', [])

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }) => (
        <MainCell label={row.name} onClick={() => handleRowClick(row)} />
      ),
      flex: 0.5,
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 1,
    },
    {
      field: 'assigned',
      headerName: 'Assigned',
      flex: 0.2,
      type: 'boolean',
      renderCell: BooleanCell,
      headerAlign: 'center',
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      sortable: false,
      flex: 0.2,
    },
    {
      field: 'rules',
      headerName: 'Rules',
      sortable: false,
      valueGetter: (_, row) => row.rules.length,
      flex: 0.15,
    },
  ]

  const rows = thresholdProfileList?.results?.map((profile) => ({
    id: profile.id,
    property: profile.property,
    propertyName: profile.propertyName,
    name: profile.name,
    description: profile.description,
    dataType: profile.dataType,
    rules: profile.rules.filter((rule) => rule.deviceStyle === 'INDOOR'), // remove outdoor for now
    assigned: profile.assigned,
  }))

  const MobileItemHeader = useCallback(({ row }) => {
    const StatusIcon = row.assigned ? CheckCircleOutline : CancelOutlined
    return (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Typography variant="caption" fontWeight="bold">
          <Link href={thresholdManagementUrls.entity.replace(':id', row.id)}>
            {row.name}
          </Link>
        </Typography>
        <StatusIcon
          color={row.assigned ? 'success' : 'warning'}
          sx={{ fontSize: 16, ml: 0.5 }}
        />
      </Box>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name', 'assigned'],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  return (
    <>
      <AddThreshold
        propertyId={propertyId}
        open={createDialogOpen}
        onClose={(success) => {
          setCreateDialogOpen(false)
          if (success) {
            doMarkThresholdProfileListAsOutdated()
          }
        }}
      />
      <Box display="flex">
        <Box display="flex" marginRight={isSmallScreen ? 0 : 1} marginTop={1.5}>
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={thresholdProfileListIsLoading}
            apiParams={thresholdProfileListApiParams}
            setApiParams={doThresholdProfileListSetFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect
              label="Assigned"
              filterName="assigned"
              options={boolOptions}
            />
          </Filter>
        </Box>
        {isSmallScreen ? (
          <Box width="100%">
            <MobileList
              autoHeight
              title="Threshold Profiles"
              loading={thresholdProfileListIsLoading}
              itemBuilder={MobileListItem}
              onFilterPressed={() => setFilterModalOpen(true)}
              listActions={
                isAtLeastAdmin
                  ? [{ label: 'Add Threshold Profile', onClick: handleCreateAction }]
                  : null
              }
              page={thresholdProfileList.current || 1}
              pageChange={handlePageChange}
              pageSize={thresholdProfileList.pageSize}
              pageSizeChange={handlePageSizeChange}
              rows={rows}
              setSearch={doThresholdProfileListSetSearch}
              rowCount={thresholdProfileList.count || 0}
              hideFooter={(thresholdProfileList?.numPages ?? 0) <= 1}
              getDetailPanelContent={detailRowContent}
              getDetailPanelHeight={detailRowHeight}
            />
          </Box>
        ) : (
          <Box
            mt={1.5}
            ml={1}
            display="flex"
            flex={1}
            height="100%"
            overflow="hidden"
            flexDirection="column"
          >
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <SearchBox
                  title="Threshold Profiles"
                  onSetSearch={doThresholdProfileListSetSearch}
                />
                <Box display="flex" gap={1} alignItems="center">
                  {isAtLeastAdmin && (
                    <Button
                      onClick={handleCreateAction}
                      startIcon={<LineAxis />}
                      variant="outlined"
                    >
                      Add Threshold Profile
                    </Button>
                  )}
                </Box>
              </Box>
              <Box display="flex">
                <Box ml={2} flex={1} overflow="hidden" minHeight="900px">
                  <List
                    autoHeight
                    loading={thresholdProfileListIsLoading}
                    columns={columns}
                    page={thresholdProfileList.current || 1}
                    pageChange={handlePageChange}
                    pageSize={thresholdProfileList.pageSize}
                    pageSizeChange={handlePageSizeChange}
                    rows={rows}
                    rowCount={thresholdProfileList.count || 0}
                    sortChange={doThresholdProfileListSetOrdering}
                    hideFooter={(thresholdProfileList?.numPages ?? 0) <= 1}
                    getDetailPanelContent={detailRowContent}
                    getDetailPanelHeight={detailRowHeight}
                    slots={{ noRowsOverlay: ListEmptyState }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}
