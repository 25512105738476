import { useConnect } from 'redux-bundler-hook'

import {
  AutorenewRounded,
  CheckCircleOutlineRounded,
  DeleteOutlineRounded,
  DoNotDisturbOnRounded,
  DoNotDisturbRounded,
  GroupAddRounded,
  PendingOutlined,
  PersonOffRounded,
  PublicOutlined,
  QuestionMarkRounded,
  TimerOutlined,
  WhatshotRounded,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @param {Object} props - The props for the component.
 * @param {string} props.type
 */
export default function EventTypeCell({ type }) {
  const isSmallScreen = useSmallScreen()

  const { systemEventTypes } = useConnect('selectSystemEventTypes')

  const typeData = systemEventTypes.find((t) => t.value === type)
  let TypeIcon = QuestionMarkRounded

  switch (type) {
    case 'INTERNAL': {
      TypeIcon = PendingOutlined
      break
    }
    case 'EXTERNAL': {
      TypeIcon = PublicOutlined
      break
    }
    case 'VACANT': {
      TypeIcon = PersonOffRounded
      break
    }
    case 'SPIKE': {
      TypeIcon = WhatshotRounded
      break
    }
    case 'BACKOFF': {
      TypeIcon = TimerOutlined
      break
    }
    case 'REMOVED': {
      TypeIcon = DeleteOutlineRounded
      break
    }
    case 'REVIEW_PENDING': {
      TypeIcon = AutorenewRounded
      break
    }
    case 'REVIEW_REJECTED': {
      TypeIcon = DoNotDisturbRounded
      break
    }
    case 'REVIEW_APPROVED': {
      TypeIcon = CheckCircleOutlineRounded
      break
    }
    case 'SECOND_OPINION_PENDING': // DEPRECATED
    case 'ENSEMBLE_PENDING': {
      TypeIcon = GroupAddRounded
      break
    }
    case 'SECOND_OPINION_REJECTED': // DEPRECATED
    case 'ENSEMBLE_REJECTED': {
      TypeIcon = DoNotDisturbOnRounded
      break
    }
    default: {
      TypeIcon = QuestionMarkRounded
    }
  }

  return (
    <Tooltip title={typeData?.name ?? 'Unknown'}>
      <TypeIcon color="action" sx={{ fontSize: isSmallScreen ? 16 : 24 }} />
    </Tooltip>
  )
}
