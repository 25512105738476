export const headerStyles = {
  height: '64px',
  position: 'sticky',
  justifyContent: 'center',
}

export const logoStyles = {
  height: 32,
}

export const userMenuStyles = {
  float: 'right',
}

export const miscStyles = {}
