export const objToHash = (obj) => {
  let hash = ''
  const entries = Object.entries(obj)
  entries.forEach(([k, v]) => {
    hash += `${k}:${v}&`
  })
  return hash.slice(0, -1)
}

export const hashToObj = (hash) =>
  hash.split('&').reduce((acc, hashEntry) => {
    const [key, value] = hashEntry.split(/:(.*)/s) // split at first ':'
    return { ...acc, [key]: value }
  }, {})
