import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  ApartmentOutlined as ApartmentIcon,
  Circle as CircleIcon,
  CopyAll,
  Delete,
  DoubleArrowRounded,
  Edit,
} from '@mui/icons-material'
import { Box, Button, Chip, Typography } from '@mui/material'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useHashFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import MembershipsList from '@portal/pages/Memberships/Memberships'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import CSVPicker from '@portal/UI/components/CSVPicker'
import DeleteModal from '@portal/UI/components/DeleteModal'
import EventLogTab from '@portal/UI/components/EventLogTab'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import MoveEntityModal from '@portal/UI/components/MoveEntityModal'

import RolesTab from '../RolesAndPermissions/RolesTab'
import AccountForm from './AccountForm'
import AccountTab from './Tabs/Account'
import accountUrls from './urls'

export default function AccountDetail() {
  const [tabValue, setTabValue] = useState(0)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [moveFormOpen, setMoveFormOpen] = useState(false)
  const [currentAccount, setCurrentAccount] = useState(null)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const {
    doAccountDelete,
    doUnitImportCsv,
    doMarkUnitListAsOutdated,
    doAccountFetch,
    doShowSnackbar,
    doUpdateUrl,
    hashObject,
    routeParams: { id: accountId },
    accountFetch: account,
    accountFetchStatus,
    isAtLeastAdmin,
    isSuperuser,
  } = useConnect(
    'doAccountDelete',
    'doUnitImportCsv',
    'doMarkUnitListAsOutdated',
    'doAccountFetch',
    'doShowSnackbar',
    'doUpdateUrl',
    'selectHashObject',
    'selectRouteParams',
    'selectAccountFetch',
    'selectAccountFetchStatus',
    'selectIsAtLeastAdmin',
    'selectIsSuperuser',
  )

  const isSmallScreen = useSmallScreen()

  const tabs = [
    { label: 'Account', component: <AccountTab />, hash: 'account' },
    {
      label: 'Memberships',
      component: <MembershipsList renderAsTab />,
      hash: 'memberships',
    },
    {
      label: 'Roles & Permissions',
      component: <RolesTab />,
      hash: 'roles',
    },
    {
      label: 'Event Log',
      component: <EventLogTab entity="Account" />,
      hash: 'eventLog',
    },
    {
      label: 'Audit Log',
      component: <AuditLogTab objectId={accountId} />,
      hash: 'auditLog',
    },
  ]

  const fetchAccount = useCallback(async (id) => {
    try {
      await doAccountFetch(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useEffect(() => {
    fetchAccount(accountId)
  }, [])

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  const handleEditClick = () => {
    setCurrentAccount(account)
    setEditFormOpen(true)
  }

  const handleDeleteClick = () => {
    setDeleteFormOpen(true)
    setCurrentAccount(account)
  }

  const handleMoveClick = () => {
    setMoveFormOpen(true)
    setCurrentAccount(account)
  }

  const deleteAccount = async () => {
    try {
      await doAccountDelete(currentAccount.id)
      doShowSnackbar('Successfully deleted account')
      setDeleteFormOpen(false)
      doUpdateUrl(accountUrls.list)
    } catch (error) {
      setDeleteError(error)
    }
  }

  const StatusChip = useCallback(
    () => (
      <Chip
        icon={<CircleIcon />}
        variant="outlined"
        size="small"
        label={account?.deletedOn ? 'Inactive' : 'Active'}
        color={account?.deletedOn ? 'error' : 'success'}
      />
    ),
    [account],
  )

  if (accountFetchStatus === 'failed') {
    return <Typography>Oh nooo, something went wrong!</Typography>
  }

  if (accountFetchStatus === 'loading') {
    return <Loading />
  }

  return (
    <Box sx={{ px: isSmallScreen ? 0 : 2, m: isSmallScreen ? 3 : 4 }}>
      <Breadcrumbs
        links={[
          { label: 'Home', href: homeUrls.home },
          { label: 'Accounts', href: accountUrls.list },
          { label: account?.name || 'Loading...' },
        ]}
      />
      <Box display="flex" alignItems="center">
        <Typography variant="caption" color="text.secondary">
          id: {accountId}
        </Typography>
        <CopyAll
          sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
          onClick={() => {
            navigator.clipboard.writeText(accountId)
            doShowSnackbar('ID copied to clipboard')
          }}
        />
      </Box>
      {isSmallScreen ? (
        <Box mb={1}>
          <ListPageTitle
            title={account?.name}
            menuItems={
              isAtLeastAdmin
                ? [
                    ...(isSuperuser
                      ? [{ label: 'Move', onClick: handleMoveClick }]
                      : []),
                    { label: 'Edit', onClick: handleEditClick },
                    { label: 'Delete', onClick: handleDeleteClick },
                  ]
                : null
            }
          />
          <StatusChip />
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" mb={2}>
              {account?.name}
            </Typography>
            <StatusChip />
          </Box>
          {isAtLeastAdmin && (
            <Box>
              {isSuperuser && (
                <Button
                  startIcon={<DoubleArrowRounded />}
                  variant="outlined"
                  onClick={handleMoveClick}
                >
                  Move
                </Button>
              )}
              <Button
                startIcon={<Edit />}
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Button
                startIcon={<Delete />}
                variant="outlined"
                sx={{ mx: 1 }}
                color="error"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
              <CSVPicker
                entity="Units"
                title="units csv"
                uploadTitle="upload csv"
                startIcon={<ApartmentIcon />}
                onImport={(data) => doUnitImportCsv({ data, account: account?.id })}
                onClose={doMarkUnitListAsOutdated}
              />
            </Box>
          )}
        </Box>
      )}
      <AccountForm
        open={editFormOpen}
        onClose={() => {
          setCurrentAccount(null)
          fetchAccount(currentAccount)
          setEditFormOpen(false)
        }}
        instance={currentAccount}
      />
      <DeleteModal
        open={deleteFormOpen}
        error={deleteError}
        onConfirmDelete={deleteAccount}
        onCancelDelete={() => {
          setDeleteFormOpen(false)
          setCurrentAccount(null)
        }}
      />
      <MoveEntityModal
        open={moveFormOpen}
        entity="account"
        entityData={
          currentAccount && {
            id: currentAccount?.id,
            parentId: currentAccount?.organization,
          }
        }
        onClose={(success) => {
          setMoveFormOpen(false)
          setCurrentAccount(null)

          if (success === true) {
            fetchAccount(accountId)
          }
        }}
      />
      <TabComponent
        tabs={tabs}
        externalState={{ value: tabValue, setValue: setTabValue }}
      />
    </Box>
  )
}
