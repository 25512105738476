import { Grid2, Stack } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {React.ReactNode} props.children
 * @param {number} [props.margin]
 * @param {number} [props.gap]
 */
export default function DetailContainer({ children, margin = 0, gap = 0 }) {
  const isSmallScreen = useSmallScreen()

  return isSmallScreen ? (
    <Grid2 container my={margin} gap={gap} width={1}>
      {children}
    </Grid2>
  ) : (
    <Stack spacing={margin} py={2} width={1}>
      {children}
    </Stack>
  )
}
