import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  CancelOutlined,
  CheckCircleOutline,
  EmailOutlined,
  HomeWorkOutlined as HomeWorkIcon,
} from '@mui/icons-material'
import { Link, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { titleize, underscore } from 'inflection'
import { DateTime } from 'luxon'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { SmokeIcon } from '@common/icons'
import GoogleMaps from '@common/icons/GoogleMaps'
import TripAdvisor from '@common/icons/TripAdvisor'
import {
  humanizePropertyType,
  parseApiErrors,
  useQueryFilter,
  useSmallScreen,
} from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import { homeUrls } from '@portal/pages/Home'
import { digestDeliveryOptions } from '@portal/pages/Properties/utils'
import userUrls from '@portal/pages/Users/urls'
import { BooleanCell } from '@portal/UI/components/cells'
import CSVPicker from '@portal/UI/components/CSVPicker'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListAddressItem from '@portal/UI/components/ListAddressItem'
import ListExportButton from '@portal/UI/components/ListExportButton'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import boolOptions from '@portal/Utils/constants'

import PropertyForm from './Form/PropertyForm'
import PropertyListColumnsPopover from './Form/PropertyListColumnsPopover'
import ProcessCell from './ProcessCell'
import propertyUrls from './urls'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 * @param {string} [props.organizationId]
 * @param {string} [props.accountId]
 */
export default function PropertyList({
  renderAsTab = false,
  organizationId = '',
  accountId = '',
}) {
  const isSmallScreen = useSmallScreen()

  const [pageSize, setPageSize] = useState('')
  const [saveFormOpen, setSaveFormOpen] = useState(false)
  const [currentProperty, setCurrentProperty] = useState({})
  const [open, setOpen] = useState(false)
  const [rowId, setRowId] = useState('')
  const [error, setError] = useState(null)

  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [settingsPopoverAnchor, setSettingsPopoverAnchor] = useState(null)

  const [columns, setColumns] = useState([
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.25,
      isMainCell: true,
      sortable: true,
      visible: true,
      minWidth: 260,
    },
    {
      field: 'active',
      renderCell: BooleanCell,
      headerAlign: 'center',
      headerName: 'Active',
      flex: 0.5,
      sortable: false,
      visible: true,
      minWidth: 60,
    },
    ...(accountId
      ? []
      : [
          {
            field: 'accountName',
            headerName: 'Account',
            sortField: 'account__name',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.accountName}
                url={accountUrls.entity.replace(':id', row.account)}
              />
            ),
            flex: 1,
            sortable: true,
            visible: true,
            minWidth: 200,
          },
        ]),
    {
      field: 'address1',
      headerName: 'Address',
      flex: 1.5,
      sortable: false,
      visible: renderAsTab,
      renderCell: ({ row }) => <ListAddressItem item={row} />,
    },
    {
      field: 'goLiveDate',
      headerName: 'Go Live',
      sortField: 'goLiveDate',
      flex: 0.5,
      sortable: true,
      minWidth: 125,
      visible: !renderAsTab,
    },
    ...(organizationId
      ? []
      : [
          {
            field: 'groupName',
            headerName: 'Group',
            sortField: 'group__name',
            flex: 0.8,
            sortable: true,
            visible: false,
            minWidth: 140,
          },
        ]),
    {
      field: 'unitCount',
      sortField: 'unitCount',
      headerName: 'Units',
      flex: 0.4,
      sortable: true,
      visible: true,
      minWidth: 60,
    },
    {
      field: 'deviceCount',
      sortField: 'deviceCount',
      headerName: 'Devices',
      flex: 0.6,
      sortable: true,
      visible: true,
      minWidth: 100,
    },
    ...(renderAsTab
      ? []
      : [
          {
            field: 'noiseEnabled',
            headerName: 'Noise',
            type: 'boolean',
            sortable: false,
            visible: false,
          },
        ]),
    {
      field: 'process',
      headerName: 'Process',
      renderCell: ({ row }) => <ProcessCell row={row} />,
      sortable: false,
      visible: true,
      minWidth: 250,
    },
    {
      field: 'csmOwnerName',
      headerName: 'CSM Owner',
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.csmOwnerName}
          url={userUrls.entity.replace(':id', row.csmOwnerId)}
        />
      ),
      sortable: false,
      visible: true,
      minWidth: 150,
    },
    {
      field: 'billingAccountType',
      headerName: 'Billing Type',
      sortable: false,
      visible: false,
    },
    {
      field: 'smokingFee',
      headerName: 'Smoking Fee',
      sortable: false,
      visible: false,
    },
    {
      field: 'timezone',
      headerName: 'Timezone',
      sortable: true,
      visible: false,
      minWidth: 170,
    },
    {
      field: 'emailIngestionIdentifier',
      headerName: 'Email Identifier',
      sortable: false,
      visible: false,
      minWidth: 130,
    },
    {
      field: 'liveSmokeAlerts',
      headerName: 'Live Alerts',
      type: 'boolean',
      sortable: false,
      visible: false,
    },
    {
      field: 'installationApproved',
      headerName: 'Installation Approved',
      type: 'boolean',
      sortable: false,
      visible: false,
      minWidth: 160,
    },
    {
      field: 'reservationGrouping',
      headerName: 'Reservation Grouping',
      type: 'boolean',
      sortable: false,
      visible: false,
      minWidth: 164,
    },
    {
      field: 'salesforceAccountId',
      headerName: 'Salesforce ID',
      sortable: false,
      visible: false,
      minWidth: 190,
    },
    {
      field: 'stripeCustomerID',
      headerName: 'Stripe ID',
      sortable: false,
      visible: false,
    },
    {
      field: 'smokeDigestHours',
      headerName: 'Digest Hours',
      sortable: false,
      visible: false,
      minWidth: 150,
      renderCell: ({ row }) => {
        const labels = digestDeliveryOptions
          .filter((opt) => row.smokeDigestHours.includes(Number(opt.id)))
          .map((opt) => opt.label)
        return (
          <Typography variant="body1" fontSize={labels.length > 1 ? 13 : 14}>
            {labels.join(', ')}
          </Typography>
        )
      },
    },
    {
      field: 'integrations',
      headerName: 'Integrations',
      sortable: false,
      visible: false,
      renderCell: ({ row }) => {
        const hasIntegrations =
          row.smokeAutocharge ||
          row.emailIngestionIdentifier ||
          row.tripadvisorId ||
          row.googlePlaceId
        if (hasIntegrations) {
          return (
            <Box display="flex" gap={2}>
              {row.tripadvisorId && (
                <Tooltip title="Tripadvisor">
                  <span>
                    <TripAdvisor />
                  </span>
                </Tooltip>
              )}
              {row.googlePlaceId && (
                <Tooltip title="Google Maps">
                  <span>
                    <GoogleMaps />
                  </span>
                </Tooltip>
              )}
              {row.smokeAutocharge && (
                <Tooltip title={`Smoke Integration: ${row.smokeAutocharge}`}>
                  <span>
                    <SmokeIcon />
                  </span>
                </Tooltip>
              )}
              {row.emailIngestionIdentifier && (
                <Tooltip
                  title={`PMS Email indetifier: ${row.emailIngestionIdentifier}`}
                >
                  <span>
                    <EmailOutlined />
                  </span>
                </Tooltip>
              )}
            </Box>
          )
        }
        return '--'
      },
      renderMobile: ({ row }) => {
        const integrations = [
          ...(row.smokeAutocharge ? [`Smoke Integration: ${row.smokeAutocharge}`] : []),
          ...(row.emailIngestionIdentifier
            ? [`PMS Email indetifier: ${row.emailIngestionIdentifier}`]
            : []),
          ...(row.tripadvisorId ? ['Tripadvisor'] : []),
          ...(row.googlePlaceId ? ['Google Maps'] : []),
        ]
        if (!isEmpty(integrations)) {
          return (
            <Box display="flex" flexDirection="column" alignItems="end">
              {integrations.map((integration) => (
                <Typography variant="caption" key={integration}>
                  {integration}
                </Typography>
              ))}
            </Box>
          )
        }
        return '--'
      },
    },
  ])

  const filteredColumns = useMemo(
    () =>
      columns.filter((column) => {
        if ('visible' in column) {
          return column.visible
        }
        return true
      }),
    [columns],
  )

  const {
    doMarkPropertyListAsOutdated,
    doPropertyListSetPageSize,
    doPropertyListSetPage,
    doPropertyListSetOrdering,
    doPropertyListSetFilter,
    doPropertyListClearParams,
    propertyList,
    propertyListRaw: { ordering = [] },
    propertyListIsLoading,
    doUpdateUrl,
    doPropertyImportCsv,
    doUpdateQuery,
    doPropertyDelete,
    propertyBrands,
    systemPropertyTypes,
    propertyListApiParams,
    queryObject,
  } = useConnect(
    'doMarkPropertyListAsOutdated',
    'doPropertyListSetPageSize',
    'doPropertyListSetPage',
    'doPropertyListSetOrdering',
    'doPropertyListSetFilter',
    'doPropertyListClearParams',
    'selectPropertyList',
    'selectPropertyListRaw',
    'selectPropertyListIsLoading',
    'doUpdateUrl',
    'doPropertyImportCsv',
    'doUpdateQuery',
    'doPropertyDelete',
    'selectPropertyBrands',
    'selectSystemPropertyTypes',
    'selectPropertyListApiParams',
    'selectQueryObject',
  )

  useEffect(() => {
    doMarkPropertyListAsOutdated()
  }, [])

  useQueryFilter({
    query: queryObject,
    apiParams: propertyListApiParams,
    setFilter: doPropertyListSetFilter,
    setPageSize: doPropertyListSetPageSize,
  })

  const handleClear = () => {
    doPropertyListClearParams()
    doPropertyListSetFilter()
  }

  const handlePageChange = async (pageNumber) => {
    doPropertyListSetPage(pageNumber)
  }

  const handleRowClick = (params) => {
    const updatedId = propertyUrls.entity.replace(':id', params.id)
    doUpdateUrl(updatedId)
  }

  const handleSortChange = async (sort) => {
    doPropertyListSetOrdering(sort)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doPropertyListSetPageSize(size)
  }

  const handlePropertySave = (row) => {
    setSaveFormOpen(true)
    setCurrentProperty(row)
  }

  const openConfirmDeleteModal = (id) => {
    setRowId(id)
    setOpen(true)
  }

  const handleDelete = async () => {
    try {
      await doPropertyDelete(rowId)
      await doMarkPropertyListAsOutdated()
      setRowId('')
      setOpen(false)
    } catch (err) {
      const apiError = parseApiErrors(err?.response) || 'Ooops. Something went wrong'
      setError(apiError)
    }
  }

  const exportListConfig = {
    apiParams: propertyListApiParams,
    entity: 'properties',
  }

  const listActions = {
    delete: openConfirmDeleteModal,
    create: handlePropertySave,
    update: handlePropertySave,
  }

  const rows = propertyList?.results.map((property) => ({
    id: property.id,
    name: property.name,
    organizationName: property.organizationName,
    account: property.account,
    accountName: property.accountName,
    propertyType: property.propertyType,
    group: property.group,
    groupName: property.groupName,
    address1: property.address1,
    address2: property.address2,
    city: property.city,
    state: property.state,
    country: property.country,
    zipCode: property.zipCode,
    unitCount: property.unitCount,
    deviceCount: property.deviceCount,
    timezone: property.timezone,
    active: !property.deletedOn,
    propertyScore: property.propertyScore,
    thirdParty: property.thirdParty,
    noiseEnabled: property.expandedFlags.includes('NOISE'),
    smokeEnabled: property.expandedFlags.includes('SMOKE'),
    goLiveDate: property.goLiveDate
      ? DateTime.fromISO(property.goLiveDate).toLocaleString(DateTime.DATE_SHORT)
      : null,
    billingAccountType: property.billingAccountType,
    smokingFee: property.smokingFee,
    emailIngestionIdentifier: property.emailIngestionIdentifier,
    liveSmokeAlerts: property.liveSmokeAlerts,
    installationApproved: property.installationApproved,
    reservationGrouping: property.reservationGrouping,
    salesforceAccountId: property.salesforceAccountId,
    stripeCustomerID: property.stripeCustomerID,
    smokeDigestHours: property.smokeDigestHours,
    tripadvisorId: property.tripadvisorId,
    googlePlaceId: property.googlePlaceId,
    smokeAutocharge: property.smokeAutocharge,
    csmOwnerName: property.csmOwnerName,
  }))

  const MobileItemHeader = useCallback(({ row }) => {
    const StatusIcon = row.active ? CheckCircleOutline : CancelOutlined
    return (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={propertyUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.name}
          </Typography>
        </Link>
        <StatusIcon
          color={row.active ? 'success' : 'warning'}
          sx={{ fontSize: 16, ml: 0.5 }}
        />
      </Box>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns: filteredColumns,
        ignoredFields: ['name', 'active'],
        multiRowFields: [
          ['goLiveDate', 'noiseEnabled'],
          ['unitCount', 'deviceCount'],
        ],
        header: <MobileItemHeader row={row} />,
      }),
    [filteredColumns],
  )

  const addPropertyButton = (
    <CSVPicker
      entity="Properties"
      title="Add properties"
      startIcon={<HomeWorkIcon />}
      uploadTitle="from csv"
      overwrite={{ account: accountId }}
      customActions={[{ title: 'Through form', call: () => setSaveFormOpen(true) }]}
      onImport={(data) => doPropertyImportCsv({ data, account: accountId })}
      onClose={() => doMarkPropertyListAsOutdated()}
    />
  )

  if (!propertyList.results)
    return <ErrorComponent title="Properties" callback={handleClear} />

  return (
    <>
      <PropertyForm
        open={saveFormOpen}
        onClose={() => {
          setCurrentProperty({})
          doMarkPropertyListAsOutdated()
          setSaveFormOpen(false)
        }}
        instance={currentProperty}
      />
      <PropertyListColumnsPopover
        anchorEl={settingsPopoverAnchor}
        onClose={() => setSettingsPopoverAnchor(null)}
        onSave={setColumns}
        currentColumns={columns}
      />
      <Box m={renderAsTab ? 0 : 3} display="flex" flexDirection="column">
        {!renderAsTab && (
          <>
            <Breadcrumbs
              links={[{ label: 'Home', href: homeUrls.home }, { label: 'Properties' }]}
            />
            <ListPageTitle
              title="Properties"
              onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
              menuItems={
                isSmallScreen
                  ? [
                      { label: 'Add Property', onClick: handlePropertySave },
                      {
                        label: 'Export',
                        onClick: () => {},
                        render: ({ onClose }) => (
                          <ListExportButton
                            mode="menuItem"
                            postExport={onClose}
                            {...exportListConfig}
                          />
                        ),
                      },
                      {
                        label: 'Columns Settings',
                        onClick: (e) => setSettingsPopoverAnchor(e.currentTarget),
                      },
                    ]
                  : null
              }
              mb={2}
            />
          </>
        )}
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={propertyListIsLoading}
            apiParams={propertyListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            {!renderAsTab && (
              <>
                <DynamicSelect
                  label="Organization Groups"
                  filterName="organization_group"
                  size="small"
                />
                <DynamicSelect
                  label="Organizations"
                  filterName="organization"
                  size="small"
                />
                <DynamicSelect label="Accounts" filterName="account" size="small" />
                <StaticSelect
                  size="small"
                  label="Property Types"
                  filterName="propertyType"
                  options={systemPropertyTypes}
                  optionLabelFormatter={humanizePropertyType}
                />
                <StaticSelect
                  size="small"
                  label="Integration enabled"
                  filterName="integration"
                  options={['email_ingestion', 'smoke_autocharge', 'property_reviews']}
                  optionLabelFormatter={(val) => titleize(underscore(val))}
                />
                <DynamicSelect
                  label="CSM Owners"
                  filterName="csmOwner"
                  endpoint="users"
                  filters={{ isCsmOwner: true }}
                  size="small"
                />
                <StaticSelect
                  label="Brand"
                  filterName="brand"
                  options={propertyBrands}
                />
              </>
            )}
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title="Properties"
                loading={propertyListIsLoading}
                actions={listActions}
                itemBuilder={MobileListItem}
                onFilterPressed={renderAsTab ? () => setFilterModalOpen(true) : null}
                listActions={
                  renderAsTab
                    ? [
                        { label: 'Add Property', onClick: () => setSaveFormOpen(true) },
                        {
                          label: 'Columns Settings',
                          onClick: (e) => setSettingsPopoverAnchor(e.currentTarget),
                        },
                      ]
                    : null
                }
                page={propertyList.current || 1}
                pageChange={handlePageChange}
                pageSize={propertyList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={propertyList.count || 0}
                rowClick={handleRowClick}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                resizableColumns
                title="Properties"
                loading={propertyListIsLoading}
                exportListConfig={renderAsTab ? null : exportListConfig}
                customAddButton={accountId ? addPropertyButton : null}
                actions={listActions}
                columns={filteredColumns}
                page={propertyList.current || 1}
                pageChange={handlePageChange}
                pageSize={propertyList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={propertyList.count || 0}
                rowClick={handleRowClick}
                sortChange={handleSortChange}
                currentOrdering={ordering}
                onListSettingsPressed={(event) =>
                  setSettingsPopoverAnchor(event.currentTarget)
                }
              />
            )}
          </Box>
          <DeleteModal
            open={open}
            error={error}
            onConfirmDelete={handleDelete}
            onCancelDelete={() => {
              setOpen(false)
              setError(null)
            }}
          />
        </Box>
      </Box>
    </>
  )
}
