export const getTemplate = (entity, overwrite = {}) => {
  const headers = {
    units: 'property,name,group,floor,unitSize\n',
    properties:
      'account,group,propertyType,name,timezone,address1,address2,city,state,country,zipCode\n',
  }
  const getData = () => {
    const headerList = headers[entity].trim().split(',')
    const key = Object.keys(overwrite)?.[0]

    const defaultRow = headerList
      .map((header) => (header === key ? overwrite[header] : ','))
      .join('')

    return [headers[entity], overwrite && defaultRow]
  }

  return {
    data: getData(),
    fileName: `${entity}.csv`,
    fileType: 'text/csv',
  }
}

export const arrayToCsv = (data) =>
  data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(','), // comma-separated
    )
    .join('\r\n') // rows starting on new lines
