import { useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'

export default function useHashFilter(initialHash, onHashChanged) {
  const { hashObject, doUpdateHash } = useConnect('selectHashObject', 'doUpdateHash')

  useEffect(() => {
    let hashObj = ''
    if (typeof initialHash === 'object') {
      if (Object.keys(initialHash).length !== 0) {
        const hash = Object.keys(initialHash)[0]
        const hashParams = initialHash[hash]
        hashObj = hashParams ? { [`${hash}`]: hashParams } : hash
      }
    } else {
      hashObj = initialHash
    }
    doUpdateHash(hashObj, { replace: true })
  }, [initialHash])

  useEffect(() => {
    const hash = Object.keys(hashObject)[0]
    const hashParams = hashObject[hash]
    onHashChanged(hash, hashParams || null)
  }, [hashObject])
}
