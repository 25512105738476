import { useCallback, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, FormGroup, FormLabel } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'

import ScenarioTagCheckBox from './ScenarioTagCheckBox'
import ScenarioUploadButton from './UploadButton'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Function} [props.onSave]
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} props.instance.name
 * @param {string} [props.instance.description]
 * @param {string} [props.instance.deviceModel]
 * @param {string} [props.instance.sourceDevice]
 * @param {string[]} [props.instance.readings]
 * @param {string[]} [props.instance.scenarioTags]
 * @param {string} [props.instance.deviceId]
 * @param {boolean} [props.showReadingsField]
 */
export default function ScenarioForm({
  open,
  onClose,
  onSave = undefined,
  instance = {},
  showReadingsField = false,
}) {
  const [disabled, setDisabled] = useState([])
  const { doScenarioSave, doShowSnackbar, systemDeviceModels, systemScenarioTags } =
    useConnect(
      'doScenarioSave',
      'doShowSnackbar',
      'selectSystemDeviceModels',
      'selectSystemScenarioTags',
    )

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required('Please enter a name'),
    description: yup.string(),
    deviceModel: yup.string().nullable().required('Please select device model'),
    scenarioTags: yup.array(),
  })

  const initialValues = {
    id: '',
    name: '',
    deviceModel: '',
    description: '',
    event: null,
    readingStart: '',
    readingEnd: '',
    scenarioTags: [],
    readings: [],
    sourceDevice: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      const defaultValue = ['scenarioTags', 'readings'].includes(field) ? [] : ''
      initialValues[field] = instance[field] ?? defaultValue
    })
  }

  const sharedStyles = { flex: 1 }

  const isObject = useCallback(
    (value) => value && typeof value === 'object' && value.constructor === Object,
    [],
  )

  const save = async (props) => {
    try {
      const saveFn = onSave ?? doScenarioSave
      const result = await saveFn(props)
      if (result.error) {
        throw result.error
      }
      onClose(true)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      const nonDefaultMessage =
        !parsedError && err?.response && isObject(err?.response)
          ? Object.entries(err.response)
              .map(([key, value]) => `${key}: ${value}`)
              .join('\n')
          : ''
      doShowSnackbar(nonDefaultMessage, 'error')
    }
  }

  const toggleTags = ['confirmed_false', 'confirmed_true', 'suspicious']

  return (
    <FormDialog
      label="Scenario"
      open={open}
      onSave={save}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box gap="2rem" display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-evenly" gap="2rem">
          <Field
            required
            component={TextField}
            label="Name"
            name="name"
            sx={sharedStyles}
          />
          {!instance?.deviceModel && (
            <Field
              required
              component={StaticSelect}
              label="Device Model"
              name="deviceModel"
              variant="standard"
              options={systemDeviceModels?.map((item) => item.id) ?? []}
              optionLabelFormatter={(id) =>
                systemDeviceModels?.find((model) => model.id === id)?.name
              }
              sx={sharedStyles}
            />
          )}
        </Box>
        <Field
          multiline
          component={TextField}
          label="Description"
          name="description"
          sx={sharedStyles}
        />
        <Field
          multiple
          variant="standard"
          component={StaticSelect}
          label="Tags"
          name="scenarioTags"
          options={systemScenarioTags}
          optionLabelFormatter={(opt) => opt.replace('_', ' ')}
          getOptionDisabled={(option) => disabled.includes(option)}
          sx={sharedStyles}
        />
        <FormGroup>
          <FormLabel>Toggle Tags</FormLabel>
          <Box display="flex" justifyContent="space-between">
            {toggleTags.map((tag) => (
              <ScenarioTagCheckBox tag={tag} key={tag} setDisabled={setDisabled} />
            ))}
          </Box>
        </FormGroup>

        {showReadingsField && (
          <Field
            multiple
            variant="standard"
            component={ScenarioUploadButton}
            name="readings"
          />
        )}
      </Box>
    </FormDialog>
  )
}
