import { createTheme } from '@mui/material/styles'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'

export const lightTheme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
      dark: '#10171f',
      chartMinimap: '#0000001A',
    },
    primary: {
      main: '#4840ba',
      contrastText: '#F9F7F3',
    },
    secondary: {
      main: '#1f2a44',
    },
    accent: {
      main: '#EFEADE',
      contrastText: '#F9F7F3',
    },
    muted: {
      main: '#1f2a44',
      light: '#50847B',
      contrastText: '#fff',
    },
    error: {
      main: '#D16565',
    },
    warning: {
      main: '#E19147',
    },
    info: {
      main: '#524B50',
    },
    success: {
      main: '#33A08E',
    },
    cancel: {
      main: '#E0E0E0',
      contrastText: '#000',
    },
    veryLowSensitivity: { main: '#44A797', contrastText: '#FFF' },
    lowSensitivity: { main: '#3F857B', contrastText: '#FFF' },
    mediumSensitivity: { main: '#3689C2', contrastText: '#FFF' },
    highSensitivity: { main: '#D3720F', contrastText: '#FFF' },
    veryHighSensitivity: { main: '#E85453', contrastText: '#FFF' },
  },
  typography: {
    bodyText: { fontFamily: 'Inter' },
    headerText: { fontFamily: 'Degular' },
    h1: {
      fontFamily: 'Degular',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontFamily: 'Degular',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontFamily: 'Degular',
    },
    h4: {
      fontFamily: 'Degular',
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: 'Degular',
    },
    h6: {
      fontFamily: 'Degular',
    },
    subtitle1: {
      fontFamily: 'Inter',
      letterSpacing: '0.15px',
    },
    subtitle2: { fontFamily: 'Inter', letterSpacing: '0.1px' },
    body1: { fontFamily: 'Inter' },
    body2: { fontFamily: 'Inter' },
    button: { fontFamily: 'Inter' },
  },
  components: {
    MuiDesktopTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDesktopDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
  },
})

export const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: 'dark',
    background: {
      default: '#303030',
      paper: '#303030',
      chartMinimap: '#FFFFFF33',
    },
    primary: {
      main: '#E6E6FA',
    },
    secondary: {
      main: '#EFEADE',
    },
    accent: {
      main: '#5E475D',
      contrastText: '#F9F7F3',
    },
    muted: {
      main: '#456E6C',
      light: '#50847B',
      contrastText: '#fff',
    },
    error: {
      main: '#D16565',
    },
    warning: {
      main: '#E19147',
    },
    info: {
      main: '#524B50',
    },
    success: {
      main: '#33A08E',
    },
  },
})
