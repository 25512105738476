import { CalendarTodayOutlined } from '@mui/icons-material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'

import { DateTime } from 'luxon'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.label]
 * @param {Object} [props.form]
 * @param {Function} [props.form.setFieldValue]
 * @param {Object} [props.form.errors]
 * @param {Object} [props.field]
 * @param {string} [props.field.name]
 * @param {string|Object} [props.field.value]
 * @param {DateTime} [props.value]
 * @param {Function} [props.onChange]
 * @param {string} [props.error]
 * @param {string} [props.placeholder]
 * @param {string} [props.timezone]
 * @param {boolean} [props.isNew]
 * @param {boolean} [props.fullWidth]
 * @param {string} [props.size]
 * @param {Object} [props.sx]
 * @param {boolean} [props.preallocateHelperTextSpace]
 */
export default function DateTimePickerCell({
  label = undefined,
  field = null,
  form = null,
  value = null,
  onChange = () => {},
  error = '',
  placeholder = '',
  timezone = undefined,
  isNew = false,
  fullWidth = false,
  size = 'small',
  sx = {},
  preallocateHelperTextSpace = false,
  ...rest
}) {
  const isFormik = !!form && !!field
  const currentValue = isFormik ? field.value : value
  const errorMessage = isFormik ? form.errors[`${field.name}`] || '' : error.trim()

  const handleChange = (data) => {
    if (isFormik) {
      form.setFieldValue(field.name, data)
    } else {
      onChange(data)
    }
  }

  return (
    <DateTimePicker
      disableMaskedInput
      label={label}
      // format="DD t" // https://github.com/mui/mui-x/issues/7615
      format="DD hh:mm a"
      timezone={timezone}
      slots={{ openPickerIcon: CalendarTodayOutlined }}
      minDate={DateTime.now()}
      value={
        typeof currentValue === 'string' ? DateTime.fromISO(currentValue) : currentValue
      }
      onChange={handleChange}
      slotProps={{
        tabs: { hidden: false },
        textField: {
          placeholder,
          fullWidth,
          size,
          error: errorMessage.length > 0,
          helperText: preallocateHelperTextSpace ? errorMessage || ' ' : errorMessage,
          sx: {
            ...(isNew &&
              !errorMessage.length &&
              !rest?.isSmallScreen && {
                border: '1px solid black',
                borderRadius: '4px',
              }),
            ...sx,
          },
        },
      }}
    />
  )
}
