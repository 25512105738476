import { useCallback } from 'react'

import { isEmpty } from 'ramda'

import ConfirmationDialog from './ConfirmationDialog'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.previewData]
 * @param {string[]} props.previewData.ids
 * @param {string} props.previewData.type
 * @param {Object[]} props.previewData.messages
 * @param {string} props.previewData.messages[].kind
 * @param {string} props.previewData.messages[].propertyName
 * @param {string} props.previewData.messages[].message
 * @param {Function} props.onConfirm
 * @param {Function} props.onCancel
 */
export default function SmokeProfilePreviewDialog({
  previewData,
  onConfirm,
  onCancel,
}) {
  const buildConfirmationTitle = useCallback((type) => {
    if (type === 'archive') {
      return 'Confirm Archive'
    }
    if (type === 'make_default') {
      return 'Confirm Make Default'
    }
    return ''
  }, [])

  const buildConfirmationMessage = useCallback((messages) => {
    if (messages && !isEmpty(messages)) {
      return messages
        .filter((data) => data.kind !== 'error')
        .map((data) =>
          data.propertyName ? `${data.propertyName}: ${data.message}` : data.message,
        )
        .join('\n\n')
    }
    return 'Are you sure you want to archive this profile?'
  }, [])

  return (
    <ConfirmationDialog
      open={!!previewData}
      title={buildConfirmationTitle(previewData?.type)}
      maxWidth="xl"
      fullWidth={false}
      contentFontSize={14}
      message={buildConfirmationMessage(previewData?.messages)}
      error={previewData?.messages
        .filter((data) => data.kind === 'error')
        .map((data) => data.message)
        .join('\n\n')}
      onConfirm={() => onConfirm(previewData)}
      onCancel={onCancel}
    />
  )
}
