export default {
  name: 'appIdle',
  reducer: (state, { type }) => {
    if (type !== 'APP_IDLE') {
      return Date.now()
    }
    return state
  },
  doResetAppIdle:
    () =>
    ({ dispatch }) => {
      dispatch({ type: 'RESET_APP_IDLE' })
    },
  selectAppIdle: (state) => state.appIdle,
}
