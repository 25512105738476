export const CANVAS_WIDTH = 415
export const VERTICAL_GRID_SPACE = 24 // vertical spacing between hours
export const CANVAS_HEIGHT = VERTICAL_GRID_SPACE * 25 // height of canvas (12AM-11PM + 12AM)
export const TIMELINE_GRID_ORIGIN = 5.5 // line at 12 AM
export const TIMELINE_LABEL_WIDTH = 42 // width of timeline labels (12AM, 1AM ...)
export const TIMELINE_LABEL_PADDING = 11 // horizontal padding between timeline labels and lines
export const RECT_HORIZONTAL_ORIGIN = TIMELINE_LABEL_WIDTH + TIMELINE_LABEL_PADDING + 10 // horizontal alignment of setting rectangles
export const RECT_RADIUS = 11 // corner radius of NRS setting rectangle
export const RECT_MARGIN = 2 // top, bottom margin of NRS setting rectangle
export const HANDLE_HEIGHT = 8 // height of resize handle rectangle
export const TIMELINE_GRID_END = TIMELINE_GRID_ORIGIN + 24 * VERTICAL_GRID_SPACE // line at 11:59 + 1 PM

export const colors = {
  95: '#44A797',
  90: '#429B8D',
  85: '#419184',
  80: '#3F857B',
  75: '#3E7A72',
  70: '#3D6F69',
  65: '#3B635F',
  60: '#3A5856',
  55: '#3DA0DA',
  50: '#3689C2',
  45: '#2F75AE',
  40: '#25568E',
  35: '#1E4078',
  30: '#E69B03',
  25: '#DB8608',
  20: '#D3720F',
  15: '#E85453',
}
