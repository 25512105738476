import { createEntityBundle } from '@common/utils'

const entityName = 'custom_notification_templates'

const customNotificationTemplateBundle = createEntityBundle({
  name: entityName,
  allowCancelation: false,
  customActions: [
    {
      actionName: 'create',
      handler: ({ apiFetch, payload, store, allowCancelation }) => {
        const accountId = store.selectCurrentAccount()

        return apiFetch(
          '/custom_notification_templates/',
          { account: accountId, ...payload },
          {
            method: 'POST',
            cancelationPrefix: entityName,
            allowCancelation,
          },
        )
      },
    },
  ],
})

export default customNotificationTemplateBundle
