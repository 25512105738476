import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  ArchiveOutlined,
  Circle,
  CopyAll,
  Edit,
  SettingsSuggest,
  UnarchiveOutlined,
} from '@mui/icons-material'
import { Box, Button, Chip, Stack, Typography } from '@mui/material'

import { titleize } from 'inflection'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import smokeProfileEnsemblesUrls from '@portal/pages/SmokeProfileEnsembles/urls'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import SmokeProfilePreviewDialog from '@portal/UI/components/SmokeProfilePreviewDialog'

import SmokeProfileEnsebleForm from './SmokeProfileEnsembleForm'
import EnsembleTab from './Tabs/Ensemble'

export default function SmokeProfileEnsembleDetail() {
  const {
    isAtLeastAdmin,
    routeParams: { id: ensembleId },
    doSmokeProfileEnsemblesListBulkPreview,
    doSmokeProfileEnsemblesListBulkArchive,
    doSmokeProfileEnsemblesListBulkUnarchive,
    doSmokeProfileEnsembleMakeDefault,
    doSmokeProfileEnsembleFetch,
    doFetchSystem,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectRouteParams',
    'doSmokeProfileEnsemblesListBulkPreview',
    'doSmokeProfileEnsemblesListBulkArchive',
    'doSmokeProfileEnsemblesListBulkUnarchive',
    'doSmokeProfileEnsembleMakeDefault',
    'doSmokeProfileEnsembleFetch',
    'doFetchSystem',
    'doShowSnackbar',
  )

  const isSmallScreen = useSmallScreen()

  const [tabValue, setTabValue] = useState(0)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [previewData, setPreviewData] = useState(null)
  const [profileToEdit, setProfileToEdit] = useState(null)

  const tabs = [
    { label: 'Ensemble', component: <EnsembleTab data={data} />, hash: 'ensembles' },
    {
      label: 'Audit Log',
      component: (
        <AuditLogTab showObjectName objectId={ensembleId} include="property" />
      ),
      hash: 'auditLog',
    },
  ]

  const fetchInitialData = useCallback(async () => {
    setLoading(true)
    try {
      const profileResponse = await doSmokeProfileEnsembleFetch({ id: ensembleId })
      if (profileResponse?.error) throw profileResponse.error
      if (profileResponse) {
        setData(profileResponse)
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchInitialData()
  }, [])

  const archiveProfiles = useCallback(async (ids) => {
    try {
      await doSmokeProfileEnsemblesListBulkArchive({ ids })
      fetchInitialData()
      doFetchSystem()
      doShowSnackbar(`Ensemble successfully archived`, 'success')
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const makeProfileAsDefault = useCallback(async (id) => {
    try {
      await doSmokeProfileEnsembleMakeDefault(id)
      fetchInitialData()
      doFetchSystem()
      doShowSnackbar('Ensemble successfully set as default', 'success')
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const handleEditClick = () => setProfileToEdit(data)

  const handleArchiveClick = useCallback(async () => {
    try {
      const previewResult = await doSmokeProfileEnsemblesListBulkPreview({
        ids: [ensembleId],
        operation: 'archive',
      })
      setPreviewData({ type: 'archive', messages: previewResult, ids: [ensembleId] })
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [ensembleId])

  const handleUnarchiveClick = useCallback(async () => {
    try {
      await doSmokeProfileEnsemblesListBulkUnarchive({ ids: [ensembleId] })
      fetchInitialData()
      doFetchSystem()
      doShowSnackbar(`Ensemble successfully unarchived`, 'success')
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [ensembleId])

  const handleSetDefaultClick = useCallback(async () => {
    try {
      const previewResult = await doSmokeProfileEnsemblesListBulkPreview({
        ids: [ensembleId],
        operation: 'make_default',
      })
      setPreviewData({
        type: 'make_default',
        messages: previewResult,
        ids: [ensembleId],
      })
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [ensembleId])

  const isProfileArchived = data?.status === 'ARCHIVED'

  const isProfileDefault = data?.status === 'DEFAULT_ALERTING'

  const StatusChip = useCallback(
    () => (
      <Chip
        icon={<Circle />}
        variant="outlined"
        size="small"
        label={titleize(data.status)}
        color={!isProfileArchived ? 'success' : 'error'}
      />
    ),
    [data, isProfileArchived],
  )

  return (
    <>
      <SmokeProfileEnsebleForm
        open={!!profileToEdit}
        onClose={(success) => {
          if (success === true) {
            fetchInitialData()
          }
          setProfileToEdit(null)
        }}
        profile={isEmpty(profileToEdit) ? null : profileToEdit}
      />
      <SmokeProfilePreviewDialog
        previewData={previewData}
        onConfirm={(result) => {
          if (result.type === 'archive') {
            archiveProfiles(result.ids)
          }
          if (result.type === 'make_default') {
            makeProfileAsDefault(result.ids[0])
          }
          setPreviewData(null)
        }}
        onCancel={() => setPreviewData(null)}
      />

      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[
            { label: 'Home', href: homeUrls.home },
            { label: 'Smoke Profile Ensembles', href: smokeProfileEnsemblesUrls.list },
            { label: data ? `${data.name}` : 'Loading...' },
          ]}
        />
        {loading && <Loading />}
        {!loading && data && (
          <Box mb={2}>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" color="text.secondary">
                id: {ensembleId}
              </Typography>
              <CopyAll
                sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
                onClick={() => {
                  navigator.clipboard.writeText(ensembleId)
                  doShowSnackbar('ID copied to clipboard')
                }}
              />
            </Box>
            {isSmallScreen ? (
              <Box mb={1}>
                <ListPageTitle
                  title={data.name}
                  menuItems={[
                    ...(isAtLeastAdmin
                      ? [
                          { label: 'Edit', onClick: handleEditClick },
                          ...(!isProfileArchived && !isProfileDefault
                            ? [
                                {
                                  label: 'Set As Default',
                                  onClick: handleSetDefaultClick,
                                },
                              ]
                            : []),
                          {
                            label: isProfileArchived ? 'Un-Archive' : 'Archive',
                            onClick: isProfileArchived
                              ? handleUnarchiveClick
                              : handleArchiveClick,
                          },
                        ]
                      : []),
                  ]}
                />
                <Box display="flex" mt={0.5} gap={0.5}>
                  <StatusChip />
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="start" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  <Typography variant="h4">{data.name}</Typography>
                  <StatusChip />
                </Stack>
                {isAtLeastAdmin && (
                  <Box>
                    <Button
                      startIcon={
                        isProfileArchived ? <ArchiveOutlined /> : <UnarchiveOutlined />
                      }
                      variant="outlined"
                      color={!isProfileArchived ? 'error' : 'primary'}
                      sx={{ mr: 2 }}
                      onClick={
                        isProfileArchived ? handleUnarchiveClick : handleArchiveClick
                      }
                    >
                      {isProfileArchived ? 'Un-Archive' : 'Archive'}
                    </Button>
                    {!isProfileDefault && !isProfileArchived && (
                      <Button
                        startIcon={<SettingsSuggest />}
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={handleSetDefaultClick}
                      >
                        Set As Default
                      </Button>
                    )}
                    <Button
                      startIcon={<Edit />}
                      variant="outlined"
                      sx={{ mr: 2 }}
                      onClick={handleEditClick}
                    >
                      Edit
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {data.description && (
              <Typography variant="h6" fontSize={isSmallScreen ? 14 : 20} mt={1}>
                {data.description}
              </Typography>
            )}
            <TabComponent
              tabs={tabs}
              externalState={{ value: tabValue, setValue: setTabValue }}
              sx={{ mt: 2 }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
