import { lightTheme } from '@portal/UI/Theme'

export const loginStyles = {
  backgroundColor: lightTheme.palette.background.dark,
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: '1 1 auto',
  maxWidth: '100vw',
}

export const contentContainer = {
  marginLeft: 'auto',
  marginRight: 'auto',
}

export const splashContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#edf1f4',
  borderRadius: '5px',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
}

export const cardContent = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  minWidth: '500px',
  minHeight: '500px',
}

export const logoStyles = {
  width: '160px',
  margin: '65px auto 0px',
}

export const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 20px',
  marginTop: '65px',
  alignItems: 'center',
}

export const loginButton = {
  color: lightTheme.palette.accent.contrastText,
  width: '170px',
  marginTop: '40px',
  marginBottom: '40px',
}
