import {
  CancelOutlined as CancelIcon,
  CheckCircleOutline as CheckIcon,
  HelpOutline as HelpIcon,
} from '@mui/icons-material'
import { Box } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.row
 * @param {bool} [props.row.active]
 * @param {string} props.field
 */
export default function BooleanCell(props) {
  const { row, field } = props
  return (
    <Box width="100%" display="flex" justifyContent="center">
      {row?.[field] === undefined && <HelpIcon color="info" />}
      {row?.[field] === false && <CancelIcon color="warning" />}
      {row?.[field] === true && <CheckIcon color="success" />}
    </Box>
  )
}
