import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

const CURRENT_PROPERTY_UPDATED = 'CURRENT_PROPERTY_UPDATED'
const SET_HEADER_LEVEL = 'SET_HEADER_LEVEL'

const entityName = 'header'

const headerBundle = createAsyncResourceBundle({
  name: entityName,
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: async ({ apiFetch, params }) =>
    apiFetch('/properties/', params, { cancelationPrefix: entityName }),
})

export default {
  ...headerBundle,
  reducer: (state, action) => {
    if (action.type === CURRENT_PROPERTY_UPDATED || action.type === SET_HEADER_LEVEL) {
      return { ...state, ...action.payload }
    }
    return headerBundle.reducer(state, action)
  },
  reactHeaderFetch: createSelector(
    'selectHeaderShouldUpdate',
    'selectIsAuthenticated',
    'selectCurrentOrganization',
    'selectCurrentAccount',
    (shouldUpdate, authenticated, currentOrganization) => {
      if (shouldUpdate && authenticated && currentOrganization) {
        return { actionCreator: 'doFetchHeader' }
      }
      return undefined
    },
  ),
  selectCurrentProperty: createSelector('selectHeaderRaw', (header) =>
    header.data?.results?.find(
      (property) => property.id === header.currentProperty || '',
    ),
  ),
  doSetCurrentProperty:
    (payload) =>
    async ({ dispatch, store }) => {
      const properties = store.selectHeader()?.results ?? []
      const selectedProperty = properties.find((prop) => prop.id === payload)
      if (selectedProperty) {
        dispatch({
          type: CURRENT_PROPERTY_UPDATED,
          payload: { currentProperty: selectedProperty.id },
        })
      } else {
        dispatch({
          type: CURRENT_PROPERTY_UPDATED,
          payload: { currentProperty: null },
        })
      }
    },
  selectHeaderLevel: createSelector(
    'selectHeaderRaw',
    (header) => header?.level ?? 'account',
  ),
  doSetHeaderLevel:
    (payload) =>
    async ({ dispatch }) => {
      dispatch({
        type: SET_HEADER_LEVEL,
        payload: { level: payload },
      })
    },
  persistActions: [...headerBundle.persistActions, CURRENT_PROPERTY_UPDATED],
}
