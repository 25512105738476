import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Loading } from '@common/components'
import { TextField } from '@common/components/Form'
import { ThresholdModalContent } from '@common/components/thresholdManagement'
import { merge, parseApiErrors } from '@common/utils'

import { formatRule } from './thresholdApiUtils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {string} props.editId
 * @param {Object} props.profileToEdit
 * @param {string} [props.profileToEdit.id]
 * @param {string} [props.profileToEdit.name]
 * @param {string} [props.profileToEdit.description]
 * @param {string} [props.profileToEdit.dataType]
 * @param {string} [props.profileToEdit.property]
 * @param {Object[]} [props.profileToEdit.rules]
 */
export default function EditThreshold({ open, onClose, editId, profileToEdit }) {
  const [initialValues, setInitialValues] = useState({})
  const [indoorThresholdSettings, setIndoorThresholdSettings] = useState([])
  const [outdoorThresholdSettings, setOutdoorThresholdSettings] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const [settingData, setSettingData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { doNewThresholdProfileSave, doThresholdProfileUpdate, doShowSnackbar } =
    useConnect(
      'doNewThresholdProfileSave',
      'doThresholdProfileUpdate',
      'doShowSnackbar',
    )

  useEffect(() => {
    const formatProfile = (rule) => {
      const parseTime = (time) => {
        const timeArray = time.split(':')
        return parseInt(timeArray[0], 10)
      }
      return {
        ...rule,
        id: profileToEdit?.rules.indexOf(rule),
        startTime: parseTime(rule.startTime),
        endTime: parseTime(rule.endTime),
      }
    }
    setIndoorThresholdSettings(
      profileToEdit?.rules
        .filter((rule) => rule.deviceStyle === 'INDOOR')
        .map(formatProfile) ?? [],
    )
    setOutdoorThresholdSettings(
      profileToEdit?.rules
        .filter((rule) => rule.deviceStyle === 'OUTDOOR')
        .map(formatProfile) ?? [],
    )
    const values = merge(
      { name: '', description: '', rules: [], assignedUnits: [] },
      profileToEdit,
    )
    setInitialValues(values)

    setIsLoading(false)
  }, [editId])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s.-]+$/,
        'Only alphanumeric characters, dashes, spaces, and commas are allowed.',
      )
      .required('Please enter a name'),
    description: Yup.string().matches(
      /^[a-zA-Z0-9\s.-]+$/,
      'Only alphanumeric characters, dashes, spaces, and commas are allowed.',
    ),
  })

  const formattedSettings = [
    ...indoorThresholdSettings,
    ...outdoorThresholdSettings,
  ].map((setting) => formatRule(setting))

  const handleSave = async (values) => {
    const payload = {
      id: profileToEdit.id,
      name: values.name,
      description: values.description,
      property: profileToEdit.property,
      dataType: 'NOISE',
      rules: formattedSettings,
    }
    try {
      const response = await doThresholdProfileUpdate(payload)
      doShowSnackbar(`${values.name} Updated`, 'success')
      onClose(true)
      if (response.error) throw parseApiErrors(response)
    } catch (error) {
      doShowSnackbar(`Unable to update ${values.name}`, 'error')
      captureException(error)
    }
  }

  // eslint-disable-next-line consistent-return
  const handleSaveNew = async (values, setFieldError) => {
    if (values.name === profileToEdit.name) {
      return setFieldError('name', 'Please use a new name to save a new profile.')
    }

    const payload = {
      name: values.name,
      description: values.description || '',
      dataType: 'NOISE',
      property: profileToEdit.property,
      rules: formattedSettings,
    }
    try {
      const response = await doNewThresholdProfileSave(payload)
      doShowSnackbar(`${values.name} Created`, 'success')
      onClose(true)
      if (response.error) throw parseApiErrors(response)
    } catch (error) {
      doShowSnackbar(`Unable to create ${values.name}`, 'error')
      captureException(error)
    }
  }

  const handleEditSetting = (setting) => {
    setIsEditing(true)
    setEditingId(setting.name)
    setSettingData(setting)
  }

  const cancelEditSetting = () => {
    setIsEditing(false)
    setEditingId(null)
    setSettingData(null)
  }

  const confirmEditSetting = (updatedThreshold) => {
    const newThreshArr =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const updateIndex = newThreshArr.findIndex((obj) => obj.id === updatedThreshold.id)
    if (updateIndex !== -1) {
      newThreshArr[updateIndex] = updatedThreshold
    } else {
      newThreshArr.push(updatedThreshold)
    }

    const setFn =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleAddSetting = (startTime, deviceStyle) => {
    setIsEditing(true)
    setEditingId('add_new')
    setSettingData({
      id: Math.random(),
      startTime,
      endTime: startTime === 23 ? 0 : startTime + 1,
      highValue: 50,
      deviceStyle,
      name: 'Quiet Hours',
    })
  }

  const handleDeleteSetting = (setting) => {
    const newThreshArr =
      setting.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const deleteIndex = newThreshArr.findIndex((obj) => obj.id === setting.id)
    if (deleteIndex !== -1) {
      newThreshArr.splice(deleteIndex, 1)
    }

    const setFn =
      setting.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Dialog open={open} onClose={cancelEditSetting} disableScrollLock maxWidth="xl">
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, pb: 0, px: 4 }}
      >
        Edit Threshold Profile
        <IconButton onClick={onClose} sx={{ padding: 0, width: '32px' }}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSave}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid, setFieldValue, values, setFieldError }) => (
          <Form>
            <DialogContent sx={{ py: 0, px: 4, maxWidth: '1000px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack my={2} sx={{ width: '315px' }}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Profile Name"
                    size="small"
                    autoFocus
                  />
                  <Field
                    component={TextField}
                    name="description"
                    label="Description (optional)"
                    size="small"
                    margin="normal"
                  />
                </Stack>
                <Stack direction="row" sx={{ height: '36px', mt: 1 }} spacing={2}>
                  <Button size="small" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={!isValid || !dirty}
                  >
                    Save
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleSaveNew(values, setFieldError)}
                    disabled={!isValid || !dirty}
                  >
                    Save as new
                  </Button>
                </Stack>
              </Box>
              <ThresholdModalContent
                indoorThresholdSettings={indoorThresholdSettings}
                setIndoorThresholdSettings={setIndoorThresholdSettings}
                outdoorThresholdSettings={outdoorThresholdSettings}
                setOutdoorThresholdSettings={setOutdoorThresholdSettings}
                handleAdd={handleAddSetting}
                handleEdit={handleEditSetting}
                handleDelete={
                  indoorThresholdSettings.length > 1 ? handleDeleteSetting : null
                }
                cancelEdit={cancelEditSetting}
                confirmEdit={confirmEditSetting}
                isEditing={isEditing}
                editingId={editingId}
                settingData={settingData}
                setFieldValue={setFieldValue}
              />
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
