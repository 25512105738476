import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CloseRounded, HouseRounded, Inventory } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'

import { pluralize } from 'inflection'

import {
  Breadcrumbs,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors, useQueryFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import boolOptions from '@portal/Utils/constants'

import MatchPropertyModal from './MatchPropertyModal'

function ListEmptyState() {
  return (
    <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
      <Inventory sx={{ fontSize: 60, mb: 2 }} />
      All caught up!
    </Stack>
  )
}

export default function ShipbobOrders() {
  const pageTitle = 'ShipBob Orders'
  const defaultQueryParams = { page: 1, pageSize: 25, unmatched: true }

  const [idsToDismiss, setIdsToDismiss] = useState(null)
  const [orderToMatch, setOrderToMatch] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    queryObject,
    isAtLeastAdmin,
    shipbobOrdersList,
    shipbobOrdersListRaw: { ordering = [] },
    shipbobOrdersListIsLoading,
    shipbobOrdersListApiParams,
    doShipbobOrdersListSetFilter,
    doShipbobOrdersListSetPageSize,
    doShipbobOrdersListSetPage,
    doShipbobOrdersListClearParams,
    doShipbobOrdersListSetOrdering,
    doShipbobOrdersListSetSearch,
    doMarkShipbobOrdersListAsOutdated,
    doShipbobOrderDelete,
    doShowSnackbar,
    doUpdateQuery,
  } = useConnect(
    'selectQueryObject',
    'selectIsAtLeastAdmin',
    'selectShipbobOrdersList',
    'selectShipbobOrdersListRaw',
    'selectShipbobOrdersListIsLoading',
    'selectShipbobOrdersListApiParams',
    'doShipbobOrdersListSetFilter',
    'doShipbobOrdersListSetPageSize',
    'doShipbobOrdersListSetPage',
    'doShipbobOrdersListClearParams',
    'doShipbobOrdersListSetOrdering',
    'doShipbobOrdersListSetSearch',
    'doMarkShipbobOrdersListAsOutdated',
    'doShipbobOrderDelete',
    'doShowSnackbar',
    'doUpdateQuery',
  )

  const isSmallScreen = useSmallScreen()

  useQueryFilter({
    query: queryObject,
    apiParams: shipbobOrdersListApiParams,
    setFilter: doShipbobOrdersListSetFilter,
    setPageSize: doShipbobOrdersListSetPageSize,
    defaultParams: defaultQueryParams,
  })

  useEffect(() => {
    doMarkShipbobOrdersListAsOutdated()
  }, [])

  const handleClear = () => {
    doShipbobOrdersListClearParams()
    doShipbobOrdersListSetFilter()
  }

  const handlePageChange = async (page) => {
    doShipbobOrdersListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    doShipbobOrdersListSetPageSize(size)
  }

  const columns = [
    {
      field: 'orderId',
      headerName: 'Order ID',
      flex: 0.5,
    },
    {
      field: 'propertyName',
      headerName: 'Property Name',
      flex: 0.5,
    },
    {
      field: 'deviceCount',
      headerName: 'Devices',
      flex: 0.25,
      type: 'number',
    },
  ]

  const handleOrdersDismiss = useCallback(async () => {
    try {
      const payload = idsToDismiss.map((id) => ({ id }))
      const response = await doShipbobOrderDelete(payload)
      if (response?.error) {
        throw response?.error
      }

      doShowSnackbar(`Orders have been dismissed`, 'success')
      doMarkShipbobOrdersListAsOutdated()
      setIdsToDismiss(null)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [idsToDismiss])

  const listActions = isAtLeastAdmin
    ? {
        bulk: [{ action: setIdsToDismiss, title: 'Dismiss Orders' }],
      }
    : null

  const otherActions = isAtLeastAdmin
    ? [
        {
          label: 'Match Property',
          icon: <HouseRounded />,
          onClick: setOrderToMatch,
        },
        {
          label: 'Dismiss Order',
          icon: <CloseRounded />,
          onClick: (row) => setIdsToDismiss([row.id]),
        },
      ]
    : null

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  if (!shipbobOrdersList.results) {
    return <ErrorComponent title={pageTitle} callback={handleClear} />
  }

  return (
    <>
      <MatchPropertyModal
        open={!!orderToMatch}
        onClose={(success) => {
          if (success === true) {
            doMarkShipbobOrdersListAsOutdated()
          }
          setOrderToMatch(null)
        }}
        orderData={orderToMatch}
      />
      <ConfirmationDialog
        title="Dismiss Orders"
        message={`Are you sure you want to dismiss ${`${idsToDismiss?.length ?? ''} ${
          idsToDismiss?.length > 1 ? pluralize('order') : 'order'
        }`}?`}
        open={!!idsToDismiss}
        onConfirm={handleOrdersDismiss}
        onCancel={() => setIdsToDismiss(null)}
      />
      <Box m={3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: pageTitle }]}
        />
        <ListPageTitle
          title={pageTitle}
          onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
          menuItems={
            isSmallScreen
              ? [
                  ...(selectedIds?.length && isAtLeastAdmin
                    ? [
                        {
                          label: 'Dismiss Orders',
                          onClick: () => setIdsToDismiss(selectedIds),
                        },
                      ]
                    : []),
                ]
              : null
          }
          mb={2}
        />
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={shipbobOrdersListIsLoading}
            apiParams={shipbobOrdersListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect
              label="Unmatched"
              filterName="unmatched"
              options={boolOptions}
            />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title={pageTitle}
                loading={shipbobOrdersListIsLoading}
                actions={listActions}
                otherActions={otherActions}
                itemBuilder={MobileListItem}
                page={shipbobOrdersList.current || 1}
                pageChange={handlePageChange}
                pageSize={shipbobOrdersList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={shipbobOrdersList.results ?? []}
                rowCount={shipbobOrdersList.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
              />
            ) : (
              <List
                autoHeight
                columnsAutosize
                queryDrivenSearch
                title={pageTitle}
                loading={shipbobOrdersListIsLoading}
                showAddButton={false}
                actions={listActions}
                otherActions={otherActions}
                columns={columns}
                page={shipbobOrdersList.current || 1}
                pageChange={handlePageChange}
                pageSize={shipbobOrdersList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={shipbobOrdersList.results ?? []}
                rowCount={shipbobOrdersList.count || 0}
                setSearch={doShipbobOrdersListSetSearch}
                sortChange={doShipbobOrdersListSetOrdering}
                currentOrdering={ordering}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                slots={{ noRowsOverlay: ListEmptyState }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
