import { titleize } from 'inflection'

export default function humanizePropertyType(type) {
  switch (type.toLowerCase()) {
    case 'apt':
      return 'Apartment'
    case 'sfh':
      return 'Single Family Home'
    case 'mu':
      return 'Multi Unit'
    default:
      return titleize(type)
  }
}
