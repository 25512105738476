import { createListBundle } from '@common/utils'

import legacyOrgUrls from './urls'

const entityName = 'legacyOrganization'

const legacyOrganizationListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/legacy_organizations/', params, { cancelationPrefix: entityName }),
  urlTest: (url) => url === legacyOrgUrls.list,
})

export default legacyOrganizationListBundle
