/* eslint-disable import/prefer-default-export */
export const formatRule = (rule) => {
  const formatTime = (time) =>
    time.toString().length === 2 ? `${time}:00` : `0${time}:00`
  return {
    name: rule.name,
    startTime: formatTime(rule.startTime),
    endTime: formatTime(rule.endTime),
    deviceStyle: rule.deviceStyle,
    lowValue: 0,
    highValue: rule.highValue,
  }
}
