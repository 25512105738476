import { humanize } from 'inflection'
import { DateTime } from 'luxon'

export const METRICS_7_DAYS_INTERVAL = 'Last 7 days'
export const METRICS_30_DAYS_INTERVAL = 'Last 30 days'
export const METRICS_MTD_INTERVAL = 'Month To Date'
export const METRICS_PREV_MONTH_INTERVAL = 'Previous Month'
export const METRICS_CUSTOM_INTERVAL = 'Custom'

export const getMetricPosition = (metric) => {
  switch (metric) {
    case 'eventsTotal':
      return 0
    case 'eventsCharged':
      return 1
    case 'chargeRate':
      return 2
    case 'netCharges':
      return 3
    case 'netChargesPerEvent':
      return 4
    case 'healthScorePrev':
      return 5
    case 'healthScoreCurrent':
      return 6
    default:
      return 99
  }
}

export const getMetricFormattedName = (metric) => {
  const prevMonthsDate = DateTime.now().minus({ months: 1 })
  switch (metric) {
    case 'eventsTotal':
      return 'Events'
    case 'eventsCharged':
      return 'Charged'
    case 'chargeRate':
      return 'Net Charge Rate'
    case 'netCharges':
      return 'Net Charges'
    case 'netChargesPerEvent':
      return 'Net $/Event'
    case 'healthScorePrev':
      return `Health Score (${prevMonthsDate.toFormat('LLL yyyy')})`
    case 'healthScoreCurrent':
      return 'Health Score (MTD)'
    default:
      return humanize(metric)
  }
}

export const getMetricsRequestTimeframe = (interval) => {
  const now = DateTime.now()

  let endTime
  let startTime
  if (interval === METRICS_7_DAYS_INTERVAL) {
    endTime = now
    startTime = endTime.minus({ days: 7 })
  } else if (interval === METRICS_30_DAYS_INTERVAL) {
    endTime = now
    startTime = endTime.minus({ days: 30 })
  } else if (interval === METRICS_MTD_INTERVAL) {
    endTime = now
    startTime = endTime.startOf('month')
  } else if (interval === METRICS_PREV_MONTH_INTERVAL) {
    endTime = now.minus({ months: 1 }).endOf('month')
    startTime = endTime.startOf('month')
  } else {
    throw Error(`Unsupported interval: ${interval}`)
  }

  return { startTime, endTime }
}
