import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'

import { getAvatarInitials } from '@common/components/Avatar'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.details
 * @param {string} [props.details.image]
 * @param {string} props.details.name
 */
export default function OrgAvatar({ details }) {
  if (details.image)
    return <Avatar alt={`Avatar for ${details.name}`} src={details.image} />

  return (
    <Tooltip placement="right" title={details.name}>
      <Avatar
        alt={`Avatar for ${details.name}`}
        sx={{ bgcolor: 'secondary.main', fontSize: 16, mr: 1 }}
      >
        {getAvatarInitials(details.name)}
      </Avatar>
    </Tooltip>
  )
}
