import { DateTime } from 'luxon'

import { createListBundle, isAbortError, parseApiErrors } from '@common/utils'

import unitUrls from './urls'

const entityName = 'unitEvent'

const unitEventListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params, store }) => {
    const unitId = params?.id || store.selectRouteParams().id
    const property = store.selectCurrentProperty()

    let { startDate, endDate } = params

    if (!DateTime.isDateTime(startDate) || !DateTime.isDateTime(endDate)) {
      endDate = DateTime.now().setZone(property.timezone)
      startDate = endDate.minus({ days: 7 })
    }

    return apiFetch(
      '/events/',
      {
        localCreatedOnDateAfter: startDate.toISODate(),
        localCreatedOnDateBefore: endDate.toISODate(),
        includeInternalEvents: true,
        eventClass: params.eventClass || 'SMOKE',
        pageSize: params?.pageSize,
        page: params?.page,
        ordering: params?.ordering,
        search: params?.search,
        unit: [unitId],
      },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (_, pattern) => pattern === unitUrls.entity,
})

export default {
  ...unitEventListBundle,
  doUnitEventListExport:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectUnitEventListApiParams()
      const unitId = store.selectRouteParams().id
      const { count = 9999 } = store.selectUnitEventList()
      try {
        const response = await apiFetch(
          '/events/',
          {
            ...apiParams,
            unit: unitId,
            pageSize: count,
          },
          { cancelationPrefix: entityName },
        )
        if (response?.error) throw response.error
        return response?.results
      } catch (error) {
        if (!isAbortError) {
          const parsedError = parseApiErrors(error?.response)
          store.doShowSnackbar(parsedError || 'Unable to export events', 'error')
        }
      }
      return []
    },
}
