import { Dialog, DialogContent } from '@mui/material'

import EventListRowDetail from './EventListRowDetail'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.row]
 * @param {Function} props.setScenarioData
 * @param {Function} props.setScenarioFormOpen
 */
export default function AdditionalOccurenciesModal({
  open,
  onClose,
  row,
  setScenarioData,
  setScenarioFormOpen,
}) {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <EventListRowDetail
          modalView
          row={row}
          setScenarioData={setScenarioData}
          setScenarioFormOpen={setScenarioFormOpen}
        />
      </DialogContent>
    </Dialog>
  )
}
