/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { equals } from 'ramda'

import { AreaClosed } from '@visx/shape'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.data === next.data &&
    prev.curve === next.curve &&
    prev.strokeWidth === next.strokeWidth &&
    prev.stroke === next.stroke &&
    prev.fill === next.fill &&
    prev.fillOpacity === next.fillOpacity &&
    prev.graphWidth === next.graphWidth &&
    equals(prev.padding, next.padding)
  )
}

/**
 * 'graphWidth' and 'padding' not used directly in the component
 * but only in `propsAreEqual` function to properly rerender component
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Function} props.defined
 * @param {Object[]} props.data
 * @param {Function} props.x
 * @param {Function} [props.y]
 * @param {Function} [props.y0]
 * @param {Function} [props.y1]
 * @param {Function} props.yScale
 * @param {Function} props.curve
 * @param {number} props.strokeWidth
 * @param {string} [props.stroke]
 * @param {string} [props.fill]
 * @param {number} [props.fillOpacity]
 * @param {number} [props.graphWidth]
 * @param {Object} [props.padding]
 */
const MemoAreaClosed = memo(
  ({
    id,
    defined,
    data,
    x,
    y = undefined,
    y0 = undefined,
    y1 = undefined,
    yScale,
    curve,
    strokeWidth = undefined,
    stroke = undefined,
    fill = undefined,
    fillOpacity = undefined,
    graphWidth = undefined,
    padding = undefined,
  }) => (
    <AreaClosed
      key={id}
      defined={defined}
      data={data}
      x={x}
      y={y}
      y0={y0}
      y1={y1}
      yScale={yScale}
      curve={curve}
      strokeWidth={strokeWidth}
      stroke={stroke}
      fill={fill}
      fillOpacity={fillOpacity}
    />
  ),
  propsAreEqual,
)

export default MemoAreaClosed
