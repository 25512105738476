import { path } from 'ramda'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import {
  downloadFile,
  fetchAndProcessMetricsData,
  isAbortError,
  prepareMetricsCsvData,
} from '@common/utils'

import metricsData from './metrics'

const SMOKE_METRICS_DATA_LOADING = 'SMOKE_METRICS_DATA_LOADING'
const SMOKE_METRICS_DATA_LOADED = 'SMOKE_METRICS_DATA_LOADED'
const SMOKE_METRICS_DATA_FAILED = 'SMOKE_METRICS_DATA_FAILED'

const SMOKE_METRICS_EXPORT_LOADING = 'SMOKE_METRICS_EXPORT_LOADING'
const SMOKE_METRICS_EXPORT_SUCCESS = 'SMOKE_METRICS_EXPORT_SUCCESS'
const SMOKE_METRICS_EXPORT_FAILED = 'SMOKE_METRICS_EXPORT_FAILED'

const SMOKE_METRICS_RESET_STATE = 'SMOKE_METRICS_RESET_STATE'

const SMOKE_METRICS_SET_PROPERTIES = 'SMOKE_METRICS_SET_PROPERTIES'

const defaultState = {}

const chartColors = [
  '#7F66B3',
  '#669CC3',
  '#CF8473',
  '#344B78',
  '#547E7C',
  '#C55A86',
  '#F5B961',
  '#B8B8B8',
]

const metricsToProject = [metricsData.eventsTotal, metricsData.eventsCharged]

const entityName = 'smokeMetric'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === SMOKE_METRICS_RESET_STATE) {
      return defaultState
    }
    if (action.type === SMOKE_METRICS_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === SMOKE_METRICS_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === SMOKE_METRICS_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === SMOKE_METRICS_EXPORT_LOADING) {
      return { ...state, export: { ...action.meta, payload: action.payload } }
    }
    if (action.type === SMOKE_METRICS_EXPORT_SUCCESS) {
      return { ...state, export: { ...action.meta, data: action.payload } }
    }
    if (action.type === SMOKE_METRICS_EXPORT_FAILED) {
      return { ...state, export: { ...action.meta, error: action.payload } }
    }
    if (action.type === SMOKE_METRICS_SET_PROPERTIES) {
      return { ...state, properties: action.payload }
    }
    return state || defaultState
  },
  selectSmokeMetricsIsLoading: ({ smokeMetric }) => {
    const status = path(['data', 'status'], smokeMetric)
    return status === 'loading'
  },
  selectSmokeMetricsExportIsLoading: ({ smokeMetric }) => {
    const status = path(['export', 'status'], smokeMetric)
    return status === 'loading'
  },
  selectSmokeMetrics: ({ smokeMetric }) => path(['data', 'data'], smokeMetric),
  selectSmokeMetricsError: ({ smokeMetric }) => path(['data', 'error'], smokeMetric),
  doResetSmokeMetricsState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: SMOKE_METRICS_RESET_STATE }),
  doFetchSmokeMetrics:
    (payload, intervalType, interval) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: SMOKE_METRICS_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const processedData = await fetchAndProcessMetricsData({
          apiFetch,
          payload,
          intervalType,
          interval,
          chartColors,
          metricsToProject,
        })

        // for property grouping add a `live` prop to the object that
        // determines if the property is live
        if (payload.grouping === 'property') {
          const properties = await apiFetch(
            '/properties/',
            {
              pageSize: 9999,
              ...(payload.organizations
                ? { organization: payload.organizations.join(',') }
                : {}),
              ...(payload.accounts ? { account: payload.accounts.join(',') } : {}),
              ...(payload.propertyGroups
                ? { propertyGroup: payload.propertyGroups.join(',') }
                : {}),
            },
            { cancelationPrefix: entityName },
          )
          const livePropertiesIds =
            properties?.results
              ?.filter(
                (property) =>
                  property.goLiveDate &&
                  DateTime.fromISO(property.goLiveDate) <= DateTime.now(),
              )
              ?.map((property) => property.id) ?? []

          Object.values(processedData).forEach((arr) => {
            arr.forEach((data) => {
              const propId = data.key.split(':')[1]
              Object.assign(data, { live: livePropertiesIds.includes(propId) })
            })
          })
        }

        dispatch({
          type: SMOKE_METRICS_DATA_LOADED,
          payload: processedData,
          meta: { status: 'succeeded' },
        })
        return processedData
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: SMOKE_METRICS_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doExportSmokeMetrics:
    (payload, intervalType, interval) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: SMOKE_METRICS_EXPORT_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const processedData = await fetchAndProcessMetricsData({
          apiFetch,
          payload,
          intervalType,
          interval,
          chartColors,
          metricsToProject,
        })
        const csvData = prepareMetricsCsvData({
          metrics: processedData,
          headerFormatter: (header) =>
            titleize(header === 'name' ? 'property_name' : header),
        })
        downloadFile({
          data: [csvData],
          fileName: `metrics_export_${DateTime.now().toFormat('yyyy-MM-dd_hh-mma')}`,
          fileType: 'text/csv',
        })

        dispatch({
          type: SMOKE_METRICS_EXPORT_SUCCESS,
          payload: csvData,
          meta: { status: 'succeeded' },
        })
        return csvData
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: SMOKE_METRICS_EXPORT_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
