import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { hoursOfTheDay, thresholdSensitivityValues } from '../constants'
import { CANVAS_WIDTH, colors } from '../editor/constants'
import { getBlockedHours } from '../editor/utils'
import SettingsExplanation from './SettingsExplanation'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.currentSetting
 * @param {number} [props.currentSetting.startTime]
 * @param {number} [props.currentSetting.endTime]
 * @param {string} [props.currentSetting.name]
 * @param {number} [props.currentSetting.highValue]
 * @param {string} [props.currentSetting.deviceStyle]
 * @param {Object[]} props.settings
 * @param {number} [props.settings[].startTime]
 * @param {number} [props.settings[].endTime]
 * @param {string} [props.settings[].name]
 * @param {number} [props.settings[].highValue]
 * @param {string} [props.settings[].deviceStyle]
 * @param {string|number} props.editingId
 * @param {Function} props.cancelEdit
 * @param {Function} props.confirmEdit
 * @param {Function} [props.confirmDelete]
 * @param {Function} props.setFieldValue
 * @param {number} [props.editorWidth]
 */
export default function ThresholdWidget({
  currentSetting,
  settings,
  editingId,
  cancelEdit,
  confirmEdit,
  confirmDelete = undefined,
  setFieldValue,
  editorWidth = undefined,
}) {
  const labelOptions = ['Quiet Hours', 'Operational Hours', 'Check In', 'Check Out']
  const [quietStart, setQuietStart] = useState(
    hoursOfTheDay[currentSetting?.startTime] ?? hoursOfTheDay[0],
  )
  const [quietEnd, setQuietEnd] = useState(
    hoursOfTheDay[currentSetting?.endTime] ?? hoursOfTheDay[1],
  )
  const [labelUpdate, setLabelUpdate] = useState(currentSetting.name)
  const [sensitivityLevel, setSensitivityLevel] = useState(
    thresholdSensitivityValues[0],
  )
  const [settingsOpen, setSettingsOpen] = useState(false)

  useEffect(() => {
    const initialLevel = thresholdSensitivityValues.find(
      (level) => level.value === currentSetting.highValue,
    )
    setSensitivityLevel(initialLevel)
  }, [])

  const saveEditLevel = () => {
    const updatedThreshold = {
      ...currentSetting,
      endTime: hoursOfTheDay.indexOf(quietEnd),
      startTime: hoursOfTheDay.indexOf(quietStart),
      name: labelUpdate,
      highValue: sensitivityLevel.value,
    }
    setFieldValue('setting', updatedThreshold)
    confirmEdit(updatedThreshold)
  }

  const findAvailableHours = useCallback((startHour, blockedHours) => {
    const availableHours = []

    let nearestAvailable = startHour
    while (blockedHours.includes(nearestAvailable)) {
      nearestAvailable = (nearestAvailable + 1) % 24
    }

    let currentHour = (nearestAvailable + 1) % 24
    while (currentHour !== startHour && !blockedHours.includes(currentHour)) {
      availableHours.push(currentHour)
      currentHour = (currentHour + 1) % 24
    }
    if (!isEmpty(availableHours) && availableHours.at(-1) !== 23) {
      availableHours.push(availableHours.at(-1) + 1)
    }

    return availableHours
  }, [])

  const hoursAvailability = useMemo(() => {
    const blocked = getBlockedHours(settings)
    const currentHours = new Set(getBlockedHours([currentSetting]))
    const filteredBlocked = blocked
      .filter((x) => !currentHours.has(x))
      .sort((a, b) => a - b)

    const startIndex = hoursOfTheDay.indexOf(quietStart)
    const availableHours = findAvailableHours(startIndex, filteredBlocked)

    return { blockedStart: filteredBlocked, availableEnd: availableHours }
  }, [settings, currentSetting, quietStart])

  return (
    <Paper
      variant="outlined"
      sx={{
        px: 3,
        py: 2,
        bgcolor: 'grey.50',
        boxShadow: 2,
        width: (editorWidth ?? CANVAS_WIDTH) + 34,
        mr: currentSetting.deviceStyle === 'OUTDOOR' ? 5 : 0,
      }}
    >
      {settingsOpen && (
        <SettingsExplanation open={settingsOpen} close={() => setSettingsOpen(false)} />
      )}
      <Typography variant="h6">
        {editingId === 'add_new' ? 'Add' : 'Edit'} Threshold
      </Typography>
      <Autocomplete
        disableClearable
        options={labelOptions}
        value={labelUpdate}
        onChange={(_, newValue) => setLabelUpdate(newValue)}
        renderInput={(params) => <TextField {...params} margin="normal" />}
        getOptionLabel={(option) => option || '--'}
      />
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Autocomplete
          fullWidth
          disableClearable
          options={hoursOfTheDay}
          value={quietStart}
          onChange={(_, newValue) => {
            const startIndex = hoursOfTheDay.indexOf(newValue)
            const endIndex = startIndex === 23 ? 0 : startIndex + 1

            setQuietStart(newValue)
            setQuietEnd(hoursOfTheDay[endIndex])
          }}
          getOptionLabel={(option) => option || '--'}
          getOptionDisabled={(option) =>
            hoursAvailability.blockedStart.includes(hoursOfTheDay.indexOf(option))
          }
          renderInput={(params) => <TextField {...params} margin="normal" />}
        />
        <Autocomplete
          fullWidth
          disableClearable
          options={hoursOfTheDay}
          value={quietEnd}
          onChange={(_, newValue) => setQuietEnd(newValue)}
          getOptionLabel={(option) => option || '--'}
          getOptionDisabled={(option) =>
            !hoursAvailability.availableEnd.includes(hoursOfTheDay.indexOf(option))
          }
          renderInput={(params) => <TextField {...params} margin="normal" />}
        />
      </Stack>
      <Autocomplete
        disableClearable
        options={thresholdSensitivityValues}
        value={sensitivityLevel}
        onChange={(_, newValue) => setSensitivityLevel(newValue)}
        renderInput={(params) => <TextField {...params} margin="normal" />}
        renderOption={(props, option) => (
          <Box sx={{ display: 'flex' }} {...props}>
            <Typography mr={10}>{option.label}</Typography>
            <Chip
              sx={{ bgcolor: colors[option.value], color: 'muted.contrastText' }}
              label={option.sensitivity}
            />
          </Box>
        )}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(opt, val) => opt.value === val.value}
      />
      <Typography
        variant="caption"
        onClick={() => setSettingsOpen(true)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          color: 'primary.light',
          ':hover': { cursor: 'pointer' },
        }}
      >
        What settings are right for me?
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Button onClick={saveEditLevel} variant="contained" fullWidth sx={{ mb: 2 }}>
          Apply
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="cancel"
          onClick={cancelEdit}
          sx={{ mb: 2, ':hover': { bgcolor: 'grey.400' } }}
        >
          Cancel
        </Button>
        {confirmDelete && editingId !== 'add_new' && (
          <Button
            fullWidth
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={() => {
              confirmDelete(currentSetting)
              setFieldValue('setting', null)
            }}
          >
            Delete
          </Button>
        )}
      </Box>
    </Paper>
  )
}
