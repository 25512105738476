import { DateTime } from 'luxon'

import { createEntityBundle } from '@common/utils'

const entityName = 'scenarios'

const scenarioBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'create_from_readings',
      handler: ({ apiFetch, payload }) => {
        const { readingStart, readingEnd } = payload
        const bufferedStart = DateTime.fromISO(readingStart).minus({ hours: 1 }).toISO()
        const bufferedEnd = DateTime.fromISO(readingEnd).plus({ hours: 1 }).toISO()
        const bufferedPayload = {
          ...payload,
          labels: [{ start: readingStart, end: readingEnd }],
          readingStart: bufferedStart,
          readingEnd: bufferedEnd,
        }
        return apiFetch(`/scenarios/from_readings/`, bufferedPayload, {
          method: 'POST',
          cancelationPrefix: entityName,
        })
      },
    },
    {
      actionName: 'update_from_readings',
      handler: ({ apiFetch, payload }) => {
        const { scenario, timeRange } = payload

        const { readingStart, readingEnd } = timeRange
        const bufferedStart = DateTime.fromISO(readingStart).minus({ hours: 1 }).toISO()
        const bufferedEnd = DateTime.fromISO(readingEnd).plus({ hours: 1 }).toISO()
        const bufferedPayload = {
          ...scenario,
          labels: [{ start: readingStart, end: readingEnd }],
          readingStart: bufferedStart,
          readingEnd: bufferedEnd,
        }

        // TODO: make sure `labelStart` and `labelEnd` won't be sent
        delete bufferedPayload.labelStart
        delete bufferedPayload.labelEnd

        return apiFetch(`/scenarios/from_readings/`, bufferedPayload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        })
      },
    },
  ],
})

export default scenarioBundle
