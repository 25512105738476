import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import { createListBundle, defaultInitialState, getEntityFromUrl } from '@common/utils'

import accountUrls from './urls'

const ACCOUNT_LIST_FETCH_ALL = 'ACCOUNT_LIST_FETCH_ALL'

const entityName = 'account'

const accountListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/accounts/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['deviceCount', 'desc'],
  },
  urlTest: (url, _, hash) =>
    url === accountUrls.list || accountUrls.tabHash.includes(hash),
})

export default {
  ...accountListBundle,
  reducer: reduceReducers(accountListBundle.reducer, (state, action) => {
    switch (action.type) {
      case ACCOUNT_LIST_FETCH_ALL:
        return { ...state, allAccounts: action.payload }
      default:
        return state
    }
  }),
  doAccountListFetchAll:
    (payload) =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/accounts/`,
          { ordering: 'organization__name,name', pageSize: 500, ...payload },
          { method: 'GET', cancelationPrefix: entityName },
        )
        return dispatch({ type: ACCOUNT_LIST_FETCH_ALL, payload: response.results })
      } catch (err) {
        return err
      }
    },
  selectAllAccounts: createSelector(
    'selectAccountListRaw',
    (accListRaw) => accListRaw?.allAccounts,
  ),
}
