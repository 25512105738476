import { AxisBottom, AxisLeft } from '@visx/axis'
import { ParentSize } from '@visx/responsive'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { Bar, BarStack } from '@visx/shape'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.data
 * @param {string} [props.data[].x]
 * @param {number} [props.data[].y]
 */
export default function BarChart({ data }) {
  const getX = (d) => d.x
  const flattenedData = data.flatMap((datum, i) =>
    datum.map(({ x, y }) => ({ x, [`${i + 1}`]: y })),
  )
  const categories = [...new Set(flattenedData.map(getX))]

  const dataByCategory = Object.values(
    flattenedData.reduce(
      (acc, curr) =>
        acc[curr.x]
          ? { ...acc, [curr.x]: { ...acc[curr.x], ...curr } }
          : { ...acc, [curr.x]: curr },
      {},
    ),
  )

  const xScale = scaleBand({
    domain: categories,
    paddingInner: 0.4,
    paddingOuter: 0.3,
  })

  const yScale = scaleLinear({
    domain: [0, 100],
  })

  const keys = ['1', '2']
  const colorScale = scaleOrdinal({
    domain: keys,
    range: ['#033663', '#DAECFC'],
  })

  const padding = { top: 35, bottom: 35, left: 50, right: 20 }

  return (
    <div style={{ maxHeight: 500, maxWidth: 250, height: '100%', width: '100%' }}>
      <ParentSize>
        {({ width, height }) => {
          const [xMin, xMax] = [padding.left, width - padding.right]
          const [yMin, yMax] = [height - padding.bottom, padding.top]
          xScale.range([xMin, xMax])
          yScale.range([yMin, yMax])
          return (
            <svg width={width} height={height}>
              <AxisLeft
                scale={yScale}
                left={padding.left}
                tickFormat={(tick) => `${tick}%`}
                tickStroke="none"
                numTicks={5}
                rangePadding={{ end: -25 }}
                tickValues={[20, 40, 60, 80, 100]}
                tickLabelProps={() => ({
                  fontSize: 12,
                  textAnchor: 'end',
                  dominantBaseline: 'middle',
                })}
              />
              <BarStack
                data={dataByCategory}
                xScale={xScale}
                yScale={yScale}
                keys={keys}
                x={(d) => d.x}
                color={colorScale}
              >
                {(barStacks) =>
                  barStacks.reverse().map((barStack, s) =>
                    barStack.bars.map((bar, i) => (
                      <g key={`${bar.x},${bar.y}`}>
                        <Bar
                          x={bar.x}
                          y={bar.y}
                          width={Math.max(0, bar.width)}
                          height={Math.max(0, bar.height)}
                          fill={bar.color}
                        />
                        {bar.height !== 0 && (
                          <text
                            x={bar.x + bar.width / 2}
                            y={bar.y + (i === 1 && bar.height < 20 ? -15 : 3)}
                            fill={s === 0 ? 'black' : 'white'}
                            textAnchor="middle"
                            dominantBaseline="hanging"
                            fontSize={10}
                          >
                            {`${dataByCategory[i][barStack.key].toFixed(2)}%`}
                          </text>
                        )}
                      </g>
                    )),
                  )
                }
              </BarStack>
              <AxisBottom
                tickStroke="none"
                scale={xScale}
                tickLabelProps={() => ({ fontSize: 12, textAnchor: 'middle' })}
                top={height - padding.bottom}
                orientation="bottom"
              />
            </svg>
          )
        }}
      </ParentSize>
    </div>
  )
}
