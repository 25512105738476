import { ToggleButton, Tooltip } from '@mui/material'

/**
 * workaround to be able to show a tooltip on the disabled button
 * @component
 * @param {Object} props - The props for the component.
 * @param {React.Node} props.children
 * @param {string|number|Object} props.value
 * @param {string} [props.tooltip]
 * @param {boolean} [props.disabled]
 * @param {Object} [props.sx]
 */
export default function ToggleButtonWithTooltip({
  children,
  value,
  disabled = false,
  tooltip = '',
  sx = {},
  ...rest
}) {
  const styles = { ...sx }

  if (disabled) {
    Object.assign(styles, {
      color: 'grey.400',
      cursor: 'default',
      '&.MuiButtonBase-root:hover': {
        bgcolor: rest.selected ? 'rgba(94, 71, 93, 0.08)' : 'transparent',
      },
    })
  }

  return (
    <ToggleButton
      value={value}
      sx={styles}
      {...rest}
      onChange={disabled ? null : rest.onChange}
      disableRipple={disabled ? true : rest.disableRipple}
    >
      <Tooltip title={tooltip}>
        <span style={{ display: 'flex', alignItems: 'center' }}>{children}</span>
      </Tooltip>
    </ToggleButton>
  )
}
