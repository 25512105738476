import { useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'

import Box from '@mui/material/Box'

import { APIProvider, Map } from '@vis.gl/react-google-maps'

import { config } from '@common/config'

import ClusteredPropertyMarkers from './ClusteredPropertyMarkers'

export default function ClientMap() {
  const {
    allProperties,
    allPropertyOutages,
    doPropertyListFetchAll,
    doPropertyOutagesListFetchAll,
  } = useConnect(
    'selectAllProperties',
    'selectAllPropertyOutages',
    'doPropertyListFetchAll',
    'doPropertyOutagesListFetchAll',
  )

  useEffect(() => {
    doPropertyListFetchAll({ active: true, hasGoLiveDate: true })
    doPropertyOutagesListFetchAll()
  }, [])

  const properties = allProperties?.map((p) => {
    const outage = allPropertyOutages?.find((o) => o.property === p.id)
    return {
      ...p,
      outageCategory: outage?.category,
      outageComments: outage?.comments,
      outageStart: outage?.start,
      outageEnd: outage?.end,
      outageSnoozedUntil: outage?.snoozedUntil,
    }
  })

  return (
    <Box>
      <APIProvider apiKey={config.GOOGLE_MAPS_KEY}>
        <Map
          disableDefaultUI
          defaultZoom={5}
          gestureHandling="greedy"
          mapId="242635a03229aece"
          defaultCenter={{ lat: 38.5, lng: -95 }}
          style={{ width: '100%', height: '90vh' }}
        />
        <ClusteredPropertyMarkers properties={properties || []} />
      </APIProvider>
    </Box>
  )
}
