import { RectClipPath } from '@visx/clip-path'
import { Group } from '@visx/group'
import { LinePath } from '@visx/shape'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Object[]} props.data
 * @param {string} props.color
 * @param {Function} props.x
 * @param {Function} props.y
 * @param {Function} props.curve
 * @param {number} props.upperBound
 * @param {number} props.height
 * @param {number} props.width
 * @param {Function} props.defined
 */
export default function HorizontalThresholdedLine({
  id,
  data,
  color,
  x,
  y,
  upperBound,
  height,
  width,
  curve,
  defined,
}) {
  const positiveHeight = Math.max(0, height)
  const positiveWidth = Math.max(0, width)
  const positiveNowX = Math.max(0, upperBound)

  return (
    <Group>
      <RectClipPath
        id={`after-${id}`}
        x={positiveNowX}
        height={positiveHeight}
        width={positiveWidth}
      />
      <RectClipPath id={`before-${id}`} height={positiveHeight} width={positiveNowX} />
      <LinePath
        key={`${id}_before`}
        data={data}
        defined={defined}
        x={x}
        y={y}
        curve={curve}
        stroke={color}
        strokeWidth={1}
        clipPath={`url(#before-${id})`}
      />
      <LinePath
        key={`${id}_after`}
        data={data}
        defined={defined}
        x={x}
        y={y}
        curve={curve}
        stroke={color}
        strokeWidth={1}
        strokeDasharray={[4, 4]}
        clipPath={`url(#after-${id})`}
      />
    </Group>
  )
}
