const dynamicColors = [
  '#7F66B3',
  '#669CC3',
  '#CF8473',
  '#344B78',
  '#547E7C',
  '#C55A86',
  '#F5B961',
  '#B8B8B8',
]

const initialEventTypesMeta = [
  'connectivity',
  'gross_charge_rate',
  'adjustment_rate',
  'charge_rate',
  'events_charged',
  'events_total',
  'incident_rate',
  'missed_revenue',
  'net_charges',
  'net_charges_per_charged_event',
  'net_charges_per_device',
  'net_charges_per_event',
].reduce((acc, type) => ({ ...acc, [type]: {} }), {})

export const staticMetaKeys = ['bucketStart', 'displayData', 'time']

export default function getMetricTypesMeta(data) {
  return Object.entries(initialEventTypesMeta)
    .map(([k, v]) => {
      const processedEntries = data
        .filter((dt) => dt.metric === k)
        .reduce((acc, dt) => {
          const unit = dt.symbol ?? ''
          const template =
            dt.symbolPosition === 'right' ? `{{ value }}${unit}` : `${unit}{{ value }}`
          const total =
            !acc.total && unit !== '%'
              ? {
                  ...v,
                  key: 'total',
                  unit,
                  template,
                  name: 'total',
                  tooltipLabel: 'Total',
                  visible: true,
                }
              : null

          const key = `${k}:${dt.name}`
          return {
            ...acc,
            [key]: {
              ...v,
              key,
              unit,
              template,
              name: key,
              tooltipLabel: dt.name,
              visible: true,
            },
            ...(total ? { total } : {}),
          }
        }, {})
      return processedEntries
    })
    .filter((value) => Object.keys(value).length !== 0)
    .map((item) => {
      const modifiedObj = { ...item }
      Object.keys(modifiedObj).forEach((key, index) => {
        modifiedObj[key].color =
          key === 'total' ? 'red' : dynamicColors[index % dynamicColors.length]
      })
      return modifiedObj
    })
    .reduce((acc, item) => ({ ...acc, ...item }), {})
}
