const defaultState = {
  message: '',
  severity: 'info',
  open: false,
}

const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
const HIDE_SNACKBAR = 'HIDE_SNACKBAR'

const snackbar = {
  name: 'snackbar',
  reducer: (state, action) => {
    switch (action.type) {
      case SHOW_SNACKBAR: {
        return {
          ...state,
          open: true,
          severity: action.severity,
          message: action.message,
        }
      }
      case HIDE_SNACKBAR:
        return {
          ...state,
          open: false,
          severity: 'info',
          message: '',
        }
      default:
        return state || defaultState
    }
  },
  selectSnackbar: (state) => state.snackbar,
  doCloseSnackbar:
    () =>
    ({ dispatch }) =>
      dispatch({ type: HIDE_SNACKBAR }),
  doShowSnackbar:
    (message, severity) =>
    ({ dispatch }) =>
      dispatch({ type: SHOW_SNACKBAR, severity, message }),
}

export default snackbar
