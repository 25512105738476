import { useState } from 'react'

import { isEmpty } from 'ramda'

import { Apartment, ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Collapse,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { useSmallScreen } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'

const cardStyles = { borderRadius: '5px', boxShadow: 2 }
const cardHeaderStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mx: 2,
  my: 1,
}

const ALERTING_PROPERTIES_HINT =
  'Properties that are currently using an ensemble which includes this profile as their default alerting mechanism, going out to end users.'
const SHADOW_PROPERTIES_HINT =
  'Properties that are currently generating silent alarms for internal evaluation of this profile, not going out to end users.'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {'shadow'|'alerting'} props.type
 */
function PropertiesEmptyState({ type }) {
  return (
    <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
      <Apartment sx={{ fontSize: 60, mb: 2 }} />
      {`No ${type} properties found`}
    </Stack>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.data
 * @param {Object} props.data.profile
 * @param {Object[]} props.data.profile.alertingProperties
 * @param {Object[]} props.data.profile.shadowProperties
 * @param {Object[]} props.data.schema
 */
export default function ProfileTab({ data }) {
  const [openParams, setOpenParams] = useState(true)
  const [openShadowProperties, setOpenShadowProperties] = useState(true)
  const [openAlertingProperties, setOpenAlertingProperties] = useState(true)

  const isSmallScreen = useSmallScreen()

  return (
    <Box>
      <Stack direction="column" sx={{ ...cardStyles, mt: 3 }}>
        <Box {...cardHeaderStyles}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Model Parameters {`(${data.schema.length})`}
          </Typography>
          <IconButton onClick={() => setOpenParams((prev) => !prev)}>
            {openParams ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={openParams}>
          <TableContainer
            component={Paper}
            sx={{ alignSelf: 'center', mt: 2, maxWidth: '100%' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Parameter</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.schema.map((field) => {
                  const value = data.profile[field.name]
                  return (
                    <TableRow
                      key={field.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {field.label}
                      </TableCell>
                      <TableCell align="right">
                        {Array.isArray(value) ? value.join(', ') : value ?? '--'}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Stack>
      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        gap={isSmallScreen ? 0 : 3}
        alignItems="start"
      >
        <Stack
          direction="column"
          display="flex"
          width="100%"
          sx={{ ...cardStyles, mt: 2 }}
        >
          <Box {...cardHeaderStyles}>
            <Box display="flex" alignItems="center">
              <Tooltip title={ALERTING_PROPERTIES_HINT}>
                <InfoOutlined fontSize="small" color="primary" />
              </Tooltip>
              <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 0.5 }}>
                Alerting Properties {`(${data.profile.alertingProperties.length})`}
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenAlertingProperties((prev) => !prev)}>
              {openAlertingProperties ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={openAlertingProperties}>
            <Box
              display="grid"
              justifyContent={
                isEmpty(data.profile.alertingProperties) ? 'center' : 'start'
              }
              gap={2}
              mx={2}
              mb={2}
            >
              {isEmpty(data.profile.alertingProperties) && (
                <PropertiesEmptyState type="alerting" />
              )}
              {data.profile.alertingProperties.map((property) => (
                <Link
                  key={property.id}
                  href={propertyUrls.entity.replace(':id', property.id)}
                >
                  <Typography>{property.name}</Typography>
                </Link>
              ))}
            </Box>
          </Collapse>
        </Stack>
        <Stack
          direction="column"
          display="flex"
          width="100%"
          sx={{ ...cardStyles, mt: 2 }}
        >
          <Box {...cardHeaderStyles}>
            <Box display="flex" alignItems="center">
              <Tooltip title={SHADOW_PROPERTIES_HINT}>
                <InfoOutlined fontSize="small" color="primary" />
              </Tooltip>
              <Typography variant="body2" sx={{ fontWeight: 'bold', ml: 0.5 }}>
                Shadow Properties {`(${data.profile.shadowProperties.length})`}
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenShadowProperties((prev) => !prev)}>
              {openShadowProperties ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={openShadowProperties}>
            <Box
              display="grid"
              justifyContent={
                isEmpty(data.profile.shadowProperties) ? 'center' : 'start'
              }
              gap={2}
              mx={2}
              mb={2}
            >
              {isEmpty(data.profile.shadowProperties) && (
                <PropertiesEmptyState type="shadow" />
              )}
              {data.profile.shadowProperties.map((property) => (
                <Link
                  key={property.id}
                  href={propertyUrls.entity.replace(':id', property.id)}
                >
                  <Typography>{property.name}</Typography>
                </Link>
              ))}
            </Box>
          </Collapse>
        </Stack>
      </Stack>
    </Box>
  )
}
