import { singularize } from 'inflection'

export default function getEntityFromUrl(store) {
  const { pathname } = store.selectUrlObject()
  const pathList = pathname.split('/')

  if (pathList.length >= 2) {
    const entity =
      pathList[1] === 'portal' && pathList.length >= 3 ? pathList[2] : pathList[1]
    return {
      entityType: singularize(entity).toLowerCase(),
      entityId: store.selectRouteParams().id,
    }
  }
  return {}
}
