import { Checkbox, FormControlLabel } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 */
export default function CheckboxControl({ label, ...rest }) {
  const { checked, onChange, size } = rest
  return (
    <FormControlLabel
      control={<Checkbox />}
      label={label}
      checked={checked ?? false}
      onChange={onChange}
      size={size}
      role="checkbox"
    />
  )
}
