export const canvasStyles = {
  position: 'relative',
  width: '11in',
  margin: '0 auto',
  padding: 25,
  backgroundColor: '#1f2a44',
}

export const closeIconStyles = {
  position: 'absolute',
  top: 25,
  right: 15,
  zIndex: 1001,
  color: 'white',
  '&.Mui-disabled': {
    color: 'grey',
  },
}

export const exportButtonStyles = {
  ...closeIconStyles,
  right: 50,
}

export const noteStyles = {
  background: '#24345c',
  color: 'white',
  borderRadius: 5,
}
