import { useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Alert, Box } from '@mui/material'

import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

export function FormObserver({ onUpdate }) {
  const { values, dirty, isSubmitting, isValid } = useFormikContext()

  useEffect(() => {
    onUpdate(dirty && !isSubmitting && isValid)
  }, [values, isSubmitting, isValid])
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.children
 * @param {Object} props.initialValues
 * @param {string} [props.successMessage]
 * @param {Object} [props.validationSchema]
 * @param {Function} props.onSave
 * @param {Function} [props.onUpdate]
 * @param {Object} props.formRef
 */
export default function FormBase({
  children,
  initialValues,
  successMessage = 'Updates successfully saved.',
  onSave,
  onUpdate = () => {},
  validationSchema = Yup.object().shape({}),
  formRef,
}) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const handleSubmit = async (values, { setErrors }) => {
    try {
      const response = await onSave({ ...values })

      if (response?.error) {
        setErrors(response.error.response)
      } else if (response) {
        doShowSnackbar(successMessage, 'success')
      }
    } catch (err) {
      if (err?.response) {
        setErrors(err.response)
      }
    }
  }

  const displayNonFieldErrors = (nonFieldErrors) => {
    if (nonFieldErrors) {
      return (
        <Box mb="1rem" display="flex" flexDirection="column" gap=".5rem">
          {nonFieldErrors.map((error) => (
            <Alert key={error} severity="error">
              {error}
            </Alert>
          ))}
        </Box>
      )
    }

    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={validationSchema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ errors }) => (
        <Form>
          <FormObserver onUpdate={onUpdate} />
          {displayNonFieldErrors(errors?.nonFieldErrors)}
          {children}
        </Form>
      )}
    </Formik>
  )
}
