import { useEffect } from 'react'

import { Field, useFormikContext } from 'formik'

import countryList from '@assets/country_list.json'
import { StaticSelect } from '@common/components/Selects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.sx
 * @param {Function} props.setCountryValue
 * @param {string} [props.countryStateValue]
 */
export default function CountryField({ sx, countryStateValue = '', setCountryValue }) {
  const form = useFormikContext()
  const humanizeCountryLabel = (value) => {
    const countryObject = countryList.find(
      (country) =>
        country.iso3.toLowerCase() === value?.toLowerCase() ||
        country.iso2.toLowerCase() === value?.toLowerCase(),
    )
    if (countryObject) {
      return countryObject.name
    }
    return value
  }

  const { value: countryFieldValue } = form.getFieldProps('country')

  useEffect(() => {
    if (countryFieldValue !== countryStateValue) {
      setCountryValue(countryFieldValue)
    }
  }, [countryFieldValue, countryStateValue])

  return (
    <Field
      required
      component={StaticSelect}
      label="Country"
      name="country"
      variant="standard"
      options={countryList.map((country) => country.iso2)}
      optionLabelFormatter={humanizeCountryLabel}
      onChange={(value) => {
        setCountryValue(value)
        form.setFieldValue('state', '')
      }}
      sx={sx}
    />
  )
}
