import { useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Button, TextField, Tooltip, Typography } from '@mui/material'

import { DateTime, Duration } from 'luxon'

import { formatDuration as formatDurationUtil, parseApiErrors } from '@common/utils'
import IntegrationCard from '@portal/pages/Properties/Tabs/Integrations/Card'
import DetailItem from '@portal/UI/components/DetailItem'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string} props.property.name
 * @param {string} [props.property.emailIngestionIdentifier]
 * @param {string} [props.property.smokeAutocharge]
 * @param {string} [props.property.lastReservationEmailTs]
 * @param {string} [props.property.lastReceivedEmailTs]
 * @param {string} [props.property.reservationEmailCadence]
 * @param {Function} props.saveProperty
 * @param {Function} props.fetchProperty
 * @param {Function} props.showSnackBar
 * @param {boolean} props.isAtLeastAdmin
 * @param {Function} props.setEmailLogIsOpen
 */
export default function EmailIngestCard({
  property,
  saveProperty,
  fetchProperty,
  showSnackBar,
  isAtLeastAdmin,
  setEmailLogIsOpen,
}) {
  const [error, setError] = useState('')
  const [pmsIdentifier, setPmsIdentifier] = useState(
    property?.emailIngestionIdentifier || '',
  )

  const fieldDirty = property?.emailIngestionIdentifier !== pmsIdentifier

  const handleIngestSave = async (pmsId) => {
    try {
      setError('')
      const res = await saveProperty({
        ...property,
        emailIngestionIdentifier: pmsId,
      })

      if (res?.error) {
        const fieldError = res.error.response?.emailIngestionIdentifier?.join('\n')
        if (fieldError) setError(fieldError)
        else showSnackBar(res?.error?.message, 'error')
      }
      if (res?.id) {
        showSnackBar('PMS email ingestion updated successfully.')
        fetchProperty(property?.id)
      }
    } catch (err) {
      const parsedError = parseApiErrors(err)
      showSnackBar(parsedError, 'error')
    }
  }

  const formatDuration = (value) => {
    const parts = value.split(' ')
    const days = parts.length === 2 ? value[0] : 0
    const [hours, minutes, seconds] = parts[parts.length - 1].split(':')
    return Duration.fromObject({
      days,
      hours,
      minutes,
      seconds,
    }).toHuman()
  }

  const renderReservationEmailCadance = (value) => (
    <Typography variant="h6">
      {value ? `Every ${formatDuration(value)}` : 'N/A'}
    </Typography>
  )

  return (
    <IntegrationCard
      title="PMS Email Ingest"
      subheader={`Manage the PMS email ingestion integration for ${property?.name}.`}
      enabled={Boolean(property?.emailIngestionIdentifier)}
      cardStyles={{ width: 420 }}
      content={
        <Box display="flex" flexDirection="column" with="100%" gap={2}>
          <Box display="flex" with="100%" gap={2} justifyContent="space-between">
            <DetailItem
              label="Last Valid Email"
              value={property?.lastReservationEmailTs}
              renderValue={(value) => (
                <Typography variant="h6">
                  {value ? `${formatDurationUtil(value, DateTime.now())} ago` : 'N/A'}
                </Typography>
              )}
              labelIcon={
                <Tooltip title="Last time a reservation email was successfully processed for this property">
                  <InfoIcon fontSize="small" color="primary" />
                </Tooltip>
              }
            />
            <DetailItem
              label="Last Received Email"
              value={property?.lastReceivedEmailTs}
              renderValue={(value) => (
                <Typography variant="h6">
                  {value ? `${formatDurationUtil(value, DateTime.now())} ago` : 'N/A'}
                </Typography>
              )}
              labelIcon={
                <Tooltip title="Last time a reservation email was received for this property">
                  <InfoIcon fontSize="small" color="primary" />
                </Tooltip>
              }
            />
          </Box>
          <DetailItem
            label="Reservation Email Cadence"
            value={property?.reservationEmailCadence}
            renderValue={renderReservationEmailCadance}
          />
          <TextField
            variant="standard"
            name="emailIngestionIdentifier"
            label="Email *"
            value={pmsIdentifier}
            helperText={error && error}
            error={!!error}
            disabled={!isAtLeastAdmin}
            onChange={(e) => {
              setPmsIdentifier(e.target.value.trim())
            }}
            InputProps={{
              endAdornment: (
                <Typography variant="body2" color="text.secondary">
                  @reservations.noiseaware.com
                </Typography>
              ),
            }}
          />
          <Button sx={{ paddingY: 1 }} onClick={() => setEmailLogIsOpen(true)}>
            View email logs
          </Button>
        </Box>
      }
      actions={
        <Box width="100%" display="flex" justifyContent="space-between">
          <Button
            color="error"
            disabled={!isAtLeastAdmin}
            onClick={() => handleIngestSave(null)}
            sx={{
              visibility: property?.emailIngestionIdentifier ? 'visible' : 'hidden',
            }}
          >
            Disable
          </Button>
          <Button
            color="secondary"
            disabled={!fieldDirty || pmsIdentifier === '' || !isAtLeastAdmin}
            onClick={() => handleIngestSave(pmsIdentifier)}
          >
            {property?.emailIngestionIdentifier ? 'Update' : 'Enable'}
          </Button>
        </Box>
      }
    />
  )
}
