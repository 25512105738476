import { Circle } from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.title
 * @param {string} [props.subheader]
 * @param {boolean} [props.enabled]
 * @param {React.Element} props.actions
 * @param {React.Element} props.content
 * @param {Object} [props.cardStyles]
 * @param {Object} [props.contentStyles]
 */
export default function IntegrationCard({
  title,
  subheader = '',
  actions,
  enabled = false,
  content,
  cardStyles = {},
  contentStyles = {},
}) {
  return (
    <Card elevation={3} sx={{ width: 400, ...cardStyles, borderRadius: 1, p: 1 }}>
      <CardHeader
        action={
          <Chip
            size="small"
            icon={<Circle />}
            variant="outlined"
            label={enabled ? 'Enabled' : 'Disabled'}
            color={enabled ? 'success' : 'error'}
          />
        }
        title={title}
        subheader={subheader}
      />
      <Divider light variant="middle" />
      <CardContent sx={{ py: 2, ...contentStyles }}>{content}</CardContent>
      <Divider light variant="middle" />
      <CardActions sx={{ pt: 2 }}>{actions}</CardActions>
    </Card>
  )
}
