import { useMemo } from 'react'

import { Chip } from '@mui/material'

import { titleize } from 'inflection'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.status
 */
export default function StatusChip({ status }) {
  const statusLabel = useMemo(() => {
    switch (status) {
      case 'RMA':
        return status
      default:
        return titleize(status.toLowerCase())
    }
  }, [status])

  const statusColor = useMemo(() => {
    switch (status) {
      case 'ONLINE':
      case 'PROVISIONED':
        return 'success.main'
      case 'OFFLINE':
      case 'RMA':
      case 'FAILURE':
      case 'NO_DEVICE':
        return 'error.main'
      default:
        return 'grey.500'
    }
  }, [status])

  return (
    <Chip
      size="small"
      label={statusLabel}
      sx={{
        backgroundColor: statusColor,
        '& .MuiChip-label': { color: 'white' },
      }}
    />
  )
}
