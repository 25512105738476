import { createListBundle, getEntityFromUrl } from '@common/utils'
import { thresholdManagementUrls } from '@portal/pages/ThresholdManagement'

export const initialParams = {}

const entityName = 'thresholdProfile'

const thresholdProfileListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/threshold_profiles/',
      {
        ...initialParams,
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (url, _, hash) =>
    url === thresholdManagementUrls.list ||
    thresholdManagementUrls.tabHash.includes(hash),
})

export default thresholdProfileListBundle
