export const logoStyles = {
  width: 250,
  position: 'absolute',
  top: 25,
  left: 25,
}

export const closeIconStyles = {
  position: 'absolute',
  top: 25,
  right: 25,
  zIndex: 1001,
}

export const exportButtonStyles = {
  position: 'absolute',
  top: 25,
  right: 75,
  zIndex: 1001,
}

export const basicPaperStyles = {
  bgcolor: 'grey.50',
  p: 2,
}

export const summaryPaperStyles = {
  ...basicPaperStyles,
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  mt: 1,
}

export const definitionPaperStyles = {
  ...basicPaperStyles,
  mt: 1,
}

export const chartHeaderStyles = {
  width: '100%',
  textAlign: 'center',
  pt: 1,
  pb: 1,
}

export const chartPaperStyles = {
  ...basicPaperStyles,
  flexGrow: 1,
  p: 0,
}

export const graphStackStyles = {
  height: '100%',
  alignItems: 'center',
}
