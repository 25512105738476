import { useEffect, useState } from 'react'

export default function useLocalStorage(key, defaultState) {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(key)) ?? defaultState,
  )

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value, key])

  return [value, setValue]
}
