import { createListBundle, defaultInitialState, getEntityFromUrl } from '@common/utils'

import scenarioUrls from './urls'

const entityName = 'scenario'

const scenarioListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/scenarios/',
      {
        ...params,
        ...(entityType === 'device' && entityId ? { sourceDevice: entityId } : {}),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['createdOn', 'desc'],
  },
  urlTest: (url, _, hash) =>
    url === scenarioUrls.list || scenarioUrls.tabHash.includes(hash),
})

export default {
  ...scenarioListBundle,
  doScenarioListExport:
    (payload) =>
    async ({ apiFetch, store }) => {
      try {
        store.doShowSnackbar('Scenario export started, you will receive an email soon.')
        return await apiFetch('/scenarios/export/', payload, {
          method: 'POST',
          cancelationPrefix: entityName,
        })
      } catch (err) {
        return err
      }
    },
}
