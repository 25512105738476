import { getIn } from 'formik'
import { humanize, underscore } from 'inflection'

export const formatError = (err) => humanize(underscore(err))

export const formatErrors = ({ field, form }) => {
  const error = getIn(form?.errors, field?.name)
  if (!error) return [false, '']
  const errorText = Array.isArray(error) ? error.map(formatError) : formatError(error)
  return [true, errorText]
}
