import { mergeArrays } from '@common/utils'

const adjustmentReasonsSnapshot = {
  AIRLINE_GROUP: 'Airlines/Groups',
  CARD_DECLINED: 'Card Declined/Insufficient Funds',
  NO_CARD: 'No Card On File',
  GUEST_COMPLAINT: 'Guest Complaint',
  NO_EVIDENCE: 'Investigation - No Evidence',
  REPEAT_OFFENSE: 'Repeat Offense',
  UNBOOKED: 'Unbooked Room',
  VIP: 'VIP',
  CHARGEBACK: 'Chargeback',
  CHARGED_LATER: 'Will Charge/Charged Later',
  GUEST_WARNED: 'Guest Warned',
  OTHER: 'Other (add comments below)',
}

export const buildReasonsById = (reasons) => {
  const processedSnapshot = Object.entries(adjustmentReasonsSnapshot).map(
    ([key, value]) => ({
      id: key,
      label: value,
    }),
  )
  const allPossibleReasons = mergeArrays(
    reasons,
    processedSnapshot,
    (a, b) => a.id === b.id,
  )

  return allPossibleReasons.reduce((acc, cur) => {
    const label = cur.label
      .replace(/ *\([^)]*\) */g, '')
      .split(' - ')
      .pop()
    acc[cur.id] = label.length > 15 ? label.split('/').pop() : label
    return acc
  }, {})
}

export const getAdjustmentReasonsOptions = ({ reasons, initialReason }) => {
  if (initialReason) {
    const reasonData = reasons.find((reason) => reason.id === initialReason)
    const legacyReason = adjustmentReasonsSnapshot[initialReason]

    if (reasonData || !legacyReason) return reasons

    return [...reasons, { id: initialReason, label: legacyReason }]
  }
  return reasons
}
