import { createListBundle, defaultInitialState, getEntityFromUrl } from '@common/utils'

import deviceUrls from './urls'

const entityName = 'device'

const deviceListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/devices/',
      {
        ...params,
        ...(entityType !== 'event' ? { [entityType]: entityId } : {}),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['seenOn', 'asc'],
  },
  urlTest: (url, _, hash) =>
    url === deviceUrls.list || deviceUrls.tabHash.includes(hash),
})

export default deviceListBundle
