import { useCallback, useEffect, useRef, useState } from 'react'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Box, Button, Typography } from '@mui/material'

import { useFormikContext } from 'formik'

export default function UploadButton() {
  const fileReader = new FileReader()
  const { values, setValues } = useFormikContext()
  const [file, setFile] = useState(null)

  const inputRef = useRef(null)

  const splitRow = useCallback((row) => {
    const result = []
    let current = ''
    let withinQuotes = false

    for (let i = 0; i < row.length; i += 1) {
      const char = row[i]
      if (char === ',' && !withinQuotes) {
        result.push(current.trim())
        current = ''
      } else if (char === '"') {
        withinQuotes = !withinQuotes
      } else {
        current += char
      }
    }

    result.push(current.trim())
    return result
  }, [])

  const processScenarioCsv = (string) => {
    const headerRow = string.slice(0, string.indexOf('\n')).trim().split(',')
    const dataRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const processedRows = dataRows.filter(Boolean).map((i) => {
      const rowValues = splitRow(i.trim())
      const obj = headerRow.reduce((acc, header, index) => {
        acc[header] =
          header === 'time'
            ? new Date(rowValues[index]).toISOString()
            : parseFloat(rowValues[index].replaceAll(',', '.'))
        return acc
      }, {})
      return obj
    })

    const readingStart = processedRows?.[0]?.time
    const readingEnd = processedRows?.[processedRows.length - 1]?.time

    setValues({
      ...values,
      readings: readingStart && readingEnd ? processedRows : [],
      readingStart: readingStart ?? '',
      readingEnd: readingEnd ?? '',
    })
  }

  useEffect(() => {
    if (file) {
      fileReader.onload = (e) => processScenarioCsv(e.target.result)
      fileReader.readAsText(file)
    }
  }, [file])

  return (
    <Box display="flex" gap={2} alignItems="baseline">
      <Button endIcon={<AttachFileIcon />} onClick={() => inputRef.current.click()}>
        Upload readings
        <input
          hidden
          ref={inputRef}
          type="file"
          accept=".csv"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </Button>
      <Typography variant="body2" color="text.secondary">
        {file?.name ?? '...'}
      </Typography>
    </Box>
  )
}
