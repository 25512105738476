import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import { titleize } from 'inflection'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect, StaticSelect } from '@common/components/Selects'
import FormDialog from '@portal/UI/components/FormDialog'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} props.instance.name
 * @param {boolean} [props.instance.mutable]
 * @param {string} [props.instance.organization]
 * @param {string} [props.instance.account]
 * @param {boolean} props.isOrgTab
 */
export default function RoleForm({ open, onClose, instance = {}, isOrgTab }) {
  const [roleLevel, setRoleLevel] = useState('')

  const { systemRoleLevels, doRoleSave, doMarkRolesListAsOutdated } = useConnect(
    'selectSystemRoleLevels',
    'doRoleSave',
    'doMarkRolesListAsOutdated',
  )

  const validationSchema = yup.object().shape({
    name: yup.string().max(30),
  })

  const initialValues = {
    id: '',
    name: '',
    account: '',
    organization: '',
    permissions: [],
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? initialValues[field]
    })
  }

  const handleSave = async (values) => {
    const res = await doRoleSave(values)
    if (res?.id) doMarkRolesListAsOutdated()
    return res
  }

  const handleClose = () => {
    setRoleLevel('')
    onClose()
  }

  const sharedStyles = { width: '90%' }
  const mutable = instance?.mutable ?? true

  const roleLevelOptions = systemRoleLevels.map((lvl) => lvl.name)
  const accRoleLevelOptions = roleLevelOptions.filter((lvl) =>
    lvl.toLowerCase().includes('account'),
  )

  const orgLevelRoleSelected = roleLevel?.toLowerCase().includes('organization')
  const selectedEntityType = orgLevelRoleSelected ? 'organization' : 'account'

  const entityFilters = {
    active: true,
    ...{ [orgLevelRoleSelected ? 'id' : 'organization']: instance?.organization }, // organization tab
    ...(!orgLevelRoleSelected && !isOrgTab && { id: instance?.account }), // account tab
  }

  const getEndpoint = () =>
    orgLevelRoleSelected ? `organizations/${instance?.organization}` : 'accounts'

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Role`}
      open={open}
      onSave={handleSave}
      onClose={handleClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      submitOptions={{ disabled: !mutable }}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <Field
          required
          sx={sharedStyles}
          component={TextField}
          label="Name"
          name="name"
          disabled={!mutable}
          error={!mutable}
          helperText={!mutable && 'Cannot edit immutable Roles'}
        />
        {!instance?.id && (
          <StaticSelect
            required
            sx={sharedStyles}
            label="At which level do you wish to create this role?"
            value={roleLevel?.value}
            variant="standard"
            options={isOrgTab ? roleLevelOptions : accRoleLevelOptions}
            onChange={setRoleLevel}
          />
        )}
        {roleLevel && (
          <Field
            required
            sx={sharedStyles}
            name={orgLevelRoleSelected ? 'organization' : 'account'}
            endpoint={getEndpoint()}
            label={titleize(selectedEntityType)}
            isOptionEqualToValue={(opt, val) => opt.id === val}
            component={FormikDynamicSelect}
            filters={entityFilters}
            secondaryTextAttr={orgLevelRoleSelected ? '' : 'organizationName'}
            disabled={orgLevelRoleSelected || !isOrgTab}
          />
        )}
      </Box>
    </FormDialog>
  )
}
