import { createSvgIcon } from '@mui/material'

const ShipBobIcon = createSvgIcon(
  <svg viewBox="0 0 150 150" fill="currentColor">
    <path d="M33.57,69.76v31.27l41.36,25.01,41.4-25.01V59.93l-35.3,21.26c-3.88,2.32-8.32,2.32-12.2,0L20.8,52.2c-5.06-3.08-5.06-10.81,0-13.85L68.83,9.32c3.88-2.32,8.32-2.32,12.2,0l35.3,21.35-16.26,9.78-25.15-15.14-33.03,20.05,33.03,20.06,44.63-26.89c2.53-1.47,5.97-1.3,8.5,.04,2.75,1.47,4.79,4.38,4.79,7.95v57.22c0,4.47-2.18,8.35-5.93,10.63l-45.89,27.74c-3.83,2.32-8.32,2.32-12.2,0l-45.89-27.74c-3.75-2.28-5.88-6.12-5.88-10.63V59.84l16.52,9.92Z" />
  </svg>,
  'ShipBob',
)

export default ShipBobIcon
