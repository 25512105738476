import { useState } from 'react'

import { Edit } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'

import { GridCellExpand } from '@portal/UI/components/cells'

import { REVIEW_TYPE_COMMENT } from './ReviewModal'

export default function CommentsRow(params) {
  const [overflow, setOverflow] = useState(false)

  const { colDef, row, setReviewModalData } = params

  const hasComment = !!row.reviewComments
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width={colDef.computedWidth - (overflow ? 46 : 0)}
    >
      <GridCellExpand
        {...params}
        overflowChanged={(overflowing) => setOverflow(overflowing)}
      />
      {hasComment && (
        <IconButton
          size="small"
          onClick={() => setReviewModalData({ type: REVIEW_TYPE_COMMENT, data: row })}
        >
          <Edit sx={{ fontSize: 18 }} />
        </IconButton>
      )}
    </Box>
  )
}
