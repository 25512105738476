import { CircularProgress } from '@mui/material'

import { Project } from '@common/config'

const loadingContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: Project.isRest ? 'calc(100vh - 143px)' : '100vh',
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.containerStyles]
 * @param {number} [props.size]
 * @param {string} [props.color]
 * @param {number|string} [props.height]
 */
export default function Loading({
  containerStyles = {},
  size = 100,
  color = undefined,
  height = undefined,
}) {
  return (
    <div
      data-testid="loading"
      style={{
        ...loadingContainerStyles,
        ...containerStyles,
        ...(height ? { height } : {}),
      }}
    >
      <CircularProgress size={size} style={{ color }} />
    </div>
  )
}
