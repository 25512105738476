import './AdjustmentsPieChart.css'

import { useCallback } from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import { ParentSize } from '@visx/responsive'
import { scaleOrdinal } from '@visx/scale'
import { Pie } from '@visx/shape'
import { useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { humanize } from 'inflection'

const dynamicColors = [
  '#7F66B3',
  '#669CC3',
  '#CF8473',
  '#344B78',
  '#547E7C',
  '#C55A86',
  '#F5B961',
  '#B8B8B8',
]

const legendGlyphSize = 15

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.data
 * @param {string} props.data[].reason
 * @param {number} props.data[].count
 * @param {Object} props.reasonsById
 * @param {number} [props.width]
 * @param {number} [props.height]
 * @param {Object} [props.margin]
 * @param {number} [props.margin.left]
 * @param {number} [props.margin.top]
 * @param {number} [props.margin.right]
 * @param {number} [props.margin.bottom]
 * @param {Function} [props.onAdjustmentClick]
 * @param {Object} [props.sx]
 */
export default function AdjustmentsPieChart({
  width = 300,
  height = 300,
  margin = undefined,
  data,
  reasonsById,
  onAdjustmentClick = undefined,
  sx = {},
}) {
  const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 }

  const getCount = useCallback((d) => d.count, [])

  const ordinalScale = scaleOrdinal({
    domain: data.map((d) => d.reason),
    range: dynamicColors,
  })

  const {
    hideTooltip,
    showTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  })

  const handleTooltipChage = useCallback(
    (event, arcData) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 }
      showTooltip({
        tooltipData: arcData,
        tooltipLeft: x,
        tooltipTop: y,
      })
    },
    [showTooltip],
  )

  const getFormattedReason = useCallback(
    (reason) => reasonsById[reason] || humanize(reason),
    [reasonsById],
  )

  return (
    <Stack direction="row" sx={{ ...sx }}>
      <Box width={width} height={height}>
        <ParentSize>
          {({ width: pWidth, height: pHeight }) => {
            const finalMargin = margin ?? defaultMargin

            const innerWidth = pWidth - finalMargin.left - finalMargin.right
            const innerHeight = pHeight - finalMargin.top - finalMargin.bottom

            const centerY = innerHeight / 2
            const centerX = innerWidth / 2
            const top = centerY + finalMargin.top
            const left = centerX + finalMargin.left

            const radius = Math.min(innerWidth, innerHeight) / 2

            return (
              <svg width={pWidth} height={pHeight} ref={containerRef}>
                <Group top={top} left={left}>
                  <Pie data={data} pieValue={getCount} outerRadius={radius}>
                    {(pie) =>
                      pie.arcs.map((arc) => {
                        const { reason, count } = arc.data

                        const [centroidX, centroidY] = pie.path.centroid(arc)
                        const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.25

                        const arcPath = pie.path(arc)
                        const arcFill = ordinalScale(reason)

                        return (
                          <g
                            key={reason}
                            onMouseEnter={(event) =>
                              handleTooltipChage(event, arc.data)
                            }
                            onMouseMove={(event) => handleTooltipChage(event, arc.data)}
                            onMouseLeave={() => hideTooltip()}
                            onMouseUp={() => {
                              if (onAdjustmentClick) {
                                onAdjustmentClick(reason)
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <path d={arcPath} fill={arcFill} />
                            {hasSpaceForLabel && (
                              <text
                                x={centroidX}
                                y={centroidY}
                                fill="#ffffff"
                                fontSize={14}
                                textAnchor="middle"
                                pointerEvents="none"
                              >
                                {count}
                              </text>
                            )}
                          </g>
                        )
                      })
                    }
                  </Pie>
                </Group>
                {tooltipData && tooltipOpen && (
                  <TooltipInPortal left={tooltipLeft} top={tooltipTop}>
                    <Typography variant="caption">
                      {getFormattedReason(tooltipData.reason)} - {tooltipData.count}
                    </Typography>
                  </TooltipInPortal>
                )}
              </svg>
            )
          }}
        </ParentSize>
      </Box>
      <Box ml={4}>
        <LegendOrdinal
          scale={ordinalScale}
          direction="column"
          labelFormat={getFormattedReason}
        >
          {(labels) =>
            labels.map((label) => {
              const reasonData = data.find((d) => d.reason === label.datum)
              return (
                <LegendItem
                  key={label.datum}
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onMouseUp={() => {
                    if (onAdjustmentClick) {
                      onAdjustmentClick(reasonData.reason)
                    }
                  }}
                >
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect
                      fill={label.value}
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 8px">
                    {`${label.text} (${reasonData.count})`}
                  </LegendLabel>
                </LegendItem>
              )
            })
          }
        </LegendOrdinal>
      </Box>
    </Stack>
  )
}
