import { useCallback, useMemo } from 'react'

import { isEmpty } from 'ramda'

import { Box, Typography, useTheme } from '@mui/material'

import { DateTime } from 'luxon'

import { Chart } from '@common/components/chart'
import { formatMetricValue, getMetricsDateRange } from '@common/utils'

import { staticMetaKeys } from './metricTypesMeta'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Object[]} props.data
 * @param {string} props.data[].key
 * @param {Object[]} props.data[].data
 * @param {string} props.data[].symbol
 * @param {string} props.data[].color
 * @param {Object} props.dataInterval
 * @param {number} props.dataInterval.value
 * @param {string} props.dataInterval.type
 * @param {Object[]} props.requestedDateRange
 * @param {Object} props.dataTypes
 * @param {boolean} props.showTotal
 */
export default function MetricChart({
  id,
  data,
  dataTypes,
  dataInterval,
  requestedDateRange,
  showTotal,
}) {
  const theme = useTheme()

  const transformedData = useMemo(
    () =>
      data
        .reduce((acc, dt) => {
          const points = dt.data.reduce((arr, pointData) => {
            const d = [
              ...arr,
              {
                time: DateTime.fromISO(pointData.bucketEndDate ?? pointData.date),
                bucketStart: pointData.bucketEndDate
                  ? DateTime.fromISO(pointData.date)
                  : null,
                displayData: pointData.displayData,
                [`${dt.metric}:${dt.name}`]: pointData.value,
              },
            ]
            return d
          }, [])
          return [...acc, ...points].reduce((result, point) => {
            const { time, ...rest } = point
            const existingPoint = result.find((item) => item.time.ts === time.ts)

            if (existingPoint) {
              Object.assign(existingPoint, rest)
            } else {
              result.push(point)
            }

            return result
          }, [])
        }, [])
        .sort((a, b) => a.time - b.time)
        .map((tdata) => {
          const total = Object.entries(tdata).reduce((acc, [key, value]) => {
            if (!staticMetaKeys.includes(key) && typeof value === 'number') {
              return acc + value
            }
            return acc
          }, 0)

          return { ...tdata, total }
        }),
    [data, dataTypes],
  )

  const TooltipItemComponent = useCallback(({ dataType, key, value, date, i }) => {
    const itemData = transformedData.find((item) => item.time.ts === date.ts)
    const displayPointDate = itemData?.displayData?.end ?? date
    const displayBucketStart = itemData?.displayData?.start ?? itemData?.bucketStart

    const suffix = (() => {
      if (!itemData || !itemData.displayData?.showSuffix) {
        return null
      }
      return itemData.displayData.isProjected ? 'projected' : 'actual'
    })()

    const formattedValue = formatMetricValue({
      value,
      symbol: dataType.unit,
      showSymbol: false,
    })

    return (
      <span
        key={key}
        style={{
          fontFamily: theme.typography.subtitle2.fontFamily,
          fontSize: 12,
        }}
      >
        {i === 0 && (
          <>
            <span style={{ fontSize: 12 }}>
              <strong>
                {getMetricsDateRange(displayPointDate, displayBucketStart, suffix)}
              </strong>
            </span>
            <div style={{ display: 'block', marginBottom: 10 }} />
          </>
        )}
        <span style={{ color: dataType.color }}>
          <strong>{dataType.tooltipLabel}: </strong>
          {dataType.template.replace('{{ value }}', formattedValue ?? '--').trim()}
        </span>
        <br />
      </span>
    )
  }, [])

  const chartDataTypes = useMemo(() => {
    if (showTotal) {
      return Object.values(dataTypes)
    }
    return Object.entries(dataTypes)
      .filter(([typeKey]) => typeKey !== 'total')
      .map((entries) => entries[1])
  }, [dataTypes, showTotal])

  return (
    <Box data-testid={`${id}_graph_card`} position="relative" textAlign="center">
      <Chart
        id={id}
        data={!isEmpty(chartDataTypes) ? transformedData : []}
        dataTypes={chartDataTypes}
        xThresholdBound={
          dataInterval.value !== 1
            ? DateTime.now().startOf('day')
            : requestedDateRange[1]
        }
        graphPadding={{ top: 22, right: 4 }}
        tooltipItemBuilder={TooltipItemComponent}
        settings={{
          showGridRows: true,
          showDateTooltip: false,
          highlightSelectedPoints: true,
          alwaysShowAllPoints: true,
          tooltipInPortal: true,
          bottomAxisProps: {
            hideTicks: true,
            hideAxisLine: true,
            tick: {
              fill: 'rgba(0, 0, 0, 0.5)',
              fontSize: '10px',
            },
          },
          rightAxisProps: {
            hideTicks: true,
            hideAxisLine: true,
            offsetWithoutUnit: 20,
            spacingBuilder: (textLength) => 12 + (4 * textLength - 1),
            tick: {
              fill: 'rgba(0, 0, 0, 0.5)',
              fontSize: '10px',
            },
            label: {
              fill: 'rgba(0, 0, 0, 0.5)',
              fontSize: '10px',
              rotate: '270',
              x: 12,
              y: -10,
            },
          },
        }}
      />
      {isEmpty(chartDataTypes) && (
        <Box
          position="absolute"
          sx={{
            left: '0px',
            top: '0px',
            paddingTop: '7em',
            marginTop: '1em',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(225, 225, 225, 0.15)',
          }}
        >
          <Typography variant="caption" fontSize={16}>
            No Data
          </Typography>
        </Box>
      )}
    </Box>
  )
}
