const OPEN_DRAWER = 'OPEN_DRAWER'
const CLOSE_DRAWER = 'CLOSE_DRAWER'

export const defaultState = {
  open: false,
}

const drawer = {
  name: 'drawer',
  reducer: (state, action) => {
    switch (action.type) {
      case OPEN_DRAWER: {
        return { open: true }
      }
      case CLOSE_DRAWER:
        return { open: false }
      default:
        return state || defaultState
    }
  },
  selectDrawerOpen: (state) => state.drawer.open,
  doCloseDrawer:
    () =>
    ({ dispatch }) =>
      dispatch({ type: CLOSE_DRAWER }),
  doOpenDrawer:
    () =>
    ({ dispatch }) =>
      dispatch({ type: OPEN_DRAWER }),
}

export default drawer
