import {
  BedOutlined,
  DescriptionOutlined,
  DeviceHubOutlined,
  DoorSlidingOutlined,
  ErrorOutline,
  FolderOpenOutlined,
  IntegrationInstructionsOutlined,
  NotificationsOutlined,
  PeopleOutlineOutlined,
} from '@mui/icons-material'

const iconMap = {
  bed: BedOutlined,
  device: DeviceHubOutlined,
  document: DescriptionOutlined,
  door: DoorSlidingOutlined,
  folder: FolderOpenOutlined,
  integration: IntegrationInstructionsOutlined,
  notification: NotificationsOutlined,
  people: PeopleOutlineOutlined,
}

export default function getIcon(name) {
  return iconMap[name] || ErrorOutline
}
