import { useCallback } from 'react'

import { Box, Link, Typography } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {string|number|React.Node} [props.value]
 * @param {string} [props.linkTo]
 * @param {string} [props.valueFontStyle]
 * @param {string} [props.valueFontColor]
 * @param {'horizontal'|'vertical'} [props.mode]
 */
export default function MobileListCardRow({
  label,
  value = undefined,
  valueFontStyle = undefined,
  valueFontColor = undefined,
  linkTo = undefined,
  mode = 'horizontal',
}) {
  const TextComponent = useCallback(
    ({ children }) => (
      <Typography
        variant="caption"
        ml={mode === 'horizontal' ? 0.5 : 0}
        textAlign={mode === 'horizontal' ? 'end' : 'start'}
        fontStyle={valueFontStyle}
        color={valueFontColor}
      >
        {children || '--'}
      </Typography>
    ),
    [valueFontStyle, valueFontColor, mode],
  )

  const ValueComponent = useCallback(() => {
    if (typeof value === 'string' || typeof value === 'number') {
      if (linkTo) {
        return (
          <TextComponent>
            <Link href={linkTo}>{value || '--'}</Link>
          </TextComponent>
        )
      }
      return <TextComponent>{value || '--'}</TextComponent>
    }
    return (
      <Box display="flex" ml={0.5}>
        {value || (
          <Typography
            variant="caption"
            fontStyle={valueFontStyle}
            color={valueFontColor}
          >
            --
          </Typography>
        )}
      </Box>
    )
  }, [value, linkTo])

  return mode === 'horizontal' ? (
    <Box display="flex" alignItems="start" justifyContent="space-between">
      <Typography variant="caption" fontWeight="bold" mr={1}>
        {`${label}:`}
      </Typography>
      <ValueComponent />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" fontWeight="bold" mr={1}>
        {`${label}`}
      </Typography>

      <ValueComponent />
    </Box>
  )
}
