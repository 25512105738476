import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { ApartmentRounded } from '@mui/icons-material'
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'

import logo from '@assets/logo.svg'
import { Loading } from '@common/components'
import { useSmallScreen } from '@common/utils'
import { formatItemCount, formatSubitemCount } from '@rest/Utils/formatMenuStrings'

import { OrgAvatar } from '../components'
import AccountsMenu from './AccountsMenu'
import { headerStyles, logoStyles, userMenuStyles } from './HeaderNav.styles'
import PlacesMenu from './PlacesMenu'
import PropertyMenu from './PropertyMenu'
import UserMenu from './UserMenu'

export default function TopNav() {
  const {
    me,
    isAuthenticated,
    currentAccount,
    currentAccountDetails,
    availableAccounts,
    doSetCurrentAccount,
    currentOrganization,
    currentOrganizationDetails,
    availableOrganizations,
    headerLevel,
    doSetCurrentOrganization,
    header: propertyList,
    currentProperty,
    doUpdateUrl,
    doFetchHeader,
  } = useConnect(
    'selectMe',
    'selectIsAuthenticated',
    'selectCurrentAccount',
    'selectCurrentAccountDetails',
    'selectAvailableAccounts',
    'doSetCurrentAccount',
    'selectCurrentOrganization',
    'selectCurrentOrganizationDetails',
    'selectAvailableOrganizations',
    'selectHeaderLevel',
    'doSetCurrentOrganization',
    'selectHeader',
    'selectCurrentProperty',
    'doUpdateUrl',
    'doFetchHeader',
  )

  const isSmallScreen = useSmallScreen()

  const [placesMenuAnchor, setPlacesMenuAnchor] = useState(null)
  const [orgAnchor, setOrgAnchor] = useState(null)
  const openOrgMenu = Boolean(orgAnchor)

  useEffect(() => {
    if (isAuthenticated) {
      doFetchHeader(currentAccount)
    }
  }, [])

  const handleOrgButtonClick = (e) => {
    if (availableAccounts?.length) setOrgAnchor(e.currentTarget)
    // eslint-disable-next-line no-console
    else console.log('This click will go to whatever main page a single AccountOrg has')
  }

  if (!me && isAuthenticated) return <Loading />

  return (
    <>
      <PlacesMenu anchor={placesMenuAnchor} onClose={() => setPlacesMenuAnchor(null)} />
      <AppBar style={headerStyles} sx={{ bgcolor: 'background.dark' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <a href="/">
              <img src={logo} alt="Rest Logo" style={logoStyles} />
            </a>
            {isAuthenticated && !isSmallScreen && (
              <>
                <Button
                  data-testid="org-button"
                  sx={{ m: 0, ml: 3, mr: 1, p: 0, px: '3px', color: 'white' }}
                  onClick={(e) => handleOrgButtonClick(e)}
                >
                  {currentOrganizationDetails?.name}
                </Button>
                <Menu
                  disableScrollLock
                  data-testid="org-menu"
                  open={openOrgMenu}
                  anchorEl={orgAnchor}
                  onClose={() => setOrgAnchor(null)}
                  sx={{ '& .MuiMenu-list': { minWidth: '300px', maxHeight: '400px' } }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography variant="caption" color="text.secondary" ml={2}>
                    {formatItemCount('Organization', availableOrganizations.length)}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', mx: 3.5, my: 1.5 }}>
                    <OrgAvatar details={currentOrganizationDetails} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                      <Typography variant="body2">
                        {currentOrganizationDetails?.name}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {formatSubitemCount('Account', availableAccounts.length)}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider variant="middle" />
                  {availableOrganizations?.map((org) => {
                    const caption = formatSubitemCount('Account', org.accountCount)
                    return (
                      <MenuItem
                        key={org.id}
                        selected={org.id === currentOrganization}
                        onClick={() => {
                          setOrgAnchor(null)
                          doSetCurrentOrganization(org.id)
                          doUpdateUrl('/')
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            <Typography>{org.name}</Typography>
                            <Typography variant="caption" color="text.secondary">
                              {caption}
                            </Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                    )
                  })}
                </Menu>
                {availableAccounts?.length > 1 && headerLevel !== 'organization' && (
                  <AccountsMenu
                    currentAccount={currentAccount}
                    currentAccountDetails={currentAccountDetails}
                    doSetCurrentAccount={doSetCurrentAccount}
                    availableAccounts={availableAccounts}
                  />
                )}
                {currentAccountDetails?.propertyCount > 0 && currentProperty && (
                  <PropertyMenu properties={propertyList.results} />
                )}
              </>
            )}
          </Box>
          <Box display="flex" flexDirection="row">
            {isAuthenticated && isSmallScreen && (
              <IconButton onClick={(e) => setPlacesMenuAnchor(e.currentTarget)}>
                <ApartmentRounded sx={{ color: 'white' }} />
              </IconButton>
            )}
            <UserMenu
              me={me}
              isAuthenticated={isAuthenticated}
              style={userMenuStyles}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
