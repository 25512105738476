import { createListBundle } from '@common/utils'

import scheduledReportPreferenceUrls from './urls'

const entityName = 'scheduledReportPreference'

const scheduledReportPreferenceListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/scheduled_report_preferences/', params, {
      cancelationPrefix: entityName,
    }),
  urlTest: (url, _, hash) =>
    url === scheduledReportPreferenceUrls.list ||
    scheduledReportPreferenceUrls.tabHash.includes(hash),
})

export default scheduledReportPreferenceListBundle
