import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, Button, CircularProgress, Stack } from '@mui/material'

import { captureException } from '@sentry/react'

import { ThresholdModalContent } from '@common/components/thresholdManagement'
import { parseApiErrors } from '@common/utils'
import { formatRule } from '@rest/pages/Configure/ThresholdProfiles/thresholdApiUtils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.onClose
 * @param {Function} props.setStep
 * @param {Object} props.profileData
 * @param {string} [props.profileData.level]
 * @param {number} [props.profileData.startTime]
 * @param {number} [props.profileData.endTime]
 * @param {number} [props.profileData.nrsIndoorValue]
 * @param {number} [props.profileData.nrsOutdoorValue]
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string} props.property.name
 */
export default function Step3({ onClose, setStep, profileData, property }) {
  const { doNewThresholdProfileSave, doThresholdProfileAssign, doShowSnackbar } =
    useConnect(
      'doNewThresholdProfileSave',
      'doThresholdProfileAssign',
      'doShowSnackbar',
    )

  const [indoorThresholdSettings, setIndoorThresholdSettings] = useState([
    {
      id: Math.random(),
      startTime: profileData?.startTime,
      endTime: profileData?.endTime,
      highValue: profileData?.nrsIndoorValue,
      deviceStyle: 'INDOOR',
      name: 'Quiet Hours',
    },
  ])
  const [outdoorThresholdSettings, setOutdoorThresholdSettings] = useState([
    {
      id: Math.random(),
      startTime: profileData?.startTime,
      endTime: profileData?.endTime,
      highValue: profileData?.nrsOutdoorValue,
      deviceStyle: 'OUTDOOR',
      name: 'Quiet Hours',
    },
  ])
  const [isEditing, setIsEditing] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const [settingData, setSettingData] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  const handleEditSetting = (setting) => {
    setIsEditing(true)
    setEditingId(setting.id)
    setSettingData(setting)
  }

  const cancelEditSetting = () => {
    setIsEditing(false)
    setEditingId(null)
    setSettingData(null)
  }

  const confirmEditSetting = (updatedThreshold) => {
    const newThreshArr =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const updateIndex = newThreshArr.findIndex((obj) => obj.id === updatedThreshold.id)
    if (updateIndex !== -1) {
      newThreshArr[updateIndex] = updatedThreshold
    } else {
      newThreshArr.push(updatedThreshold)
    }

    const setFn =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleAddSetting = (startTime, deviceStyle) => {
    setIsEditing(true)
    setEditingId('add_new')
    setSettingData({
      id: Math.random(),
      startTime,
      endTime: startTime === 23 ? 0 : startTime + 1,
      highValue: profileData?.nrsIndoorValue,
      deviceStyle,
      name: 'Quiet Hours',
    })
  }

  const handleDeleteSetting = (setting) => {
    const newThreshArr =
      setting.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const deleteIndex = newThreshArr.findIndex((obj) => obj.id === setting.id)
    if (deleteIndex !== -1) {
      newThreshArr.splice(deleteIndex, 1)
    }

    const setFn =
      setting.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleSubmit = async () => {
    const formattedSettings = [
      ...indoorThresholdSettings,
      // ...outdoorThresholdSettings, // do not include outdoor yet
    ].map((setting) => formatRule(setting))

    const payload = {
      property: property.id,
      name: property.name,
      description: '',
      dataType: 'NOISE',
      rules: formattedSettings,
    }

    try {
      setIsSaving(true)

      const response = await doNewThresholdProfileSave(payload)
      if (response.error) {
        throw response.error
      }

      const assignResponse = await doThresholdProfileAssign({
        thresholdProfile: response.id,
        property: property.id,
      })
      if (assignResponse.error) {
        doShowSnackbar(`Unable to assign profile`, 'error')
      }

      doShowSnackbar(`Threshold profile has been created`, 'success')
      setStep('one')
      onClose(true)
    } catch (error) {
      captureException(error)

      const parsedError = parseApiErrors(error?.response)
      doShowSnackbar(parsedError || `Unable to create threshold profile`, 'error')
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Box
      display="flex"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        mt: 1,
      }}
    >
      {isSaving && <CircularProgress sx={{ position: 'absolute' }} />}
      <Box
        sx={{
          width: '100%',
          transitionProperty: 'opacity',
          transitionDuration: '300ms',
          opacity: isSaving ? 0.35 : 1,
          pointerEvents: isSaving ? 'none' : 'initial',
        }}
      >
        <ThresholdModalContent
          indoorThresholdSettings={indoorThresholdSettings}
          setIndoorThresholdSettings={setIndoorThresholdSettings}
          outdoorThresholdSettings={outdoorThresholdSettings}
          setOutdoorThresholdSettings={setOutdoorThresholdSettings}
          handleAdd={handleAddSetting}
          handleEdit={handleEditSetting}
          handleDelete={indoorThresholdSettings.length > 1 ? handleDeleteSetting : null}
          cancelEdit={cancelEditSetting}
          confirmEdit={confirmEditSetting}
          isEditing={isEditing}
          editingId={editingId}
          settingData={settingData}
          setFieldValue={() => {}}
        />
        <Stack
          direction="row"
          justifyContent="end"
          spacing={2}
          sx={{ height: '36px', mt: 1, mb: 2 }}
        >
          <Button size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="cancel"
            sx={{ ':hover': { bgcolor: 'grey.400' } }}
            onClick={() => setStep('two')}
          >
            Back
          </Button>
          <Button size="small" variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
