import { useConnect } from 'redux-bundler-hook'

import { Box, Divider } from '@mui/material'

import { parseApiErrors, useSmallScreen } from '@common/utils'
import organizationUrls from '@portal/pages/Organizations/urls'
import PropertyList from '@portal/pages/Properties/Properties'
import DetailItem from '@portal/UI/components/DetailItem'
import DetailsFeatureFlagsRow from '@portal/UI/components/DetailsFeatureFlagsRow'

export default function AccountTab() {
  const {
    accountFetch: account,
    doAccountFetch,
    doShowSnackbar,
  } = useConnect('selectAccountFetch', 'doAccountFetch', 'doShowSnackbar')
  const isSmallScreen = useSmallScreen()

  return (
    <Box>
      <Box my={isSmallScreen ? 1 : 2} gap={isSmallScreen ? 1.5 : 10} display="flex">
        <DetailItem
          label="Organization"
          value={account?.organizationName}
          linkTo={organizationUrls.entity.replace(':id', account?.organization)}
        />
      </Box>
      <Divider />
      <DetailsFeatureFlagsRow
        entity={account}
        entityType="account"
        onEditSuccess={async () => {
          try {
            await doAccountFetch(account?.id)
          } catch (err) {
            const parsedError = parseApiErrors(err?.response)
            doShowSnackbar(parsedError, 'error')
          }
        }}
      />
      <PropertyList renderAsTab accountId={account?.id} />
    </Box>
  )
}
