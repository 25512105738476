import { Typography } from '@mui/material'

import { ListTextTooltipWrapper } from '@common/components'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {Function} props.onClick
 */
export default function MainCell({ label, onClick }) {
  return (
    <ListTextTooltipWrapper tooltip={label}>
      <Typography
        noWrap
        onClick={(e) => {
          e.stopPropagation()
          onClick(e)
        }}
        sx={{ fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
      >
        {label || 'N/A'}
      </Typography>
    </ListTextTooltipWrapper>
  )
}
