import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect, StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'

import deviceUrls from './urls'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} [props.instance.id]
 * @param {string} [props.instance.zone]
 * @param {string} [props.instance.mainMac]
 * @param {string} [props.instance.model]
 * @param {string} [props.instance.property]
 * @param {string} [props.instance.unit]
 */
export default function SimulatedDeviceForm({ open, onClose, instance = {} }) {
  const { doDeviceSave, doUpdateUrl, doShowSnackbar, systemDeviceModels } = useConnect(
    'doDeviceSave',
    'doUpdateUrl',
    'doShowSnackbar',
    'selectSystemDeviceModels',
  )

  const handleOnSave = async (values) => {
    try {
      const response = await doDeviceSave(values)
      if (response?.id) doUpdateUrl(deviceUrls.entity.replace(':id', response.id))
      return response
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
    return null
  }

  const initialValues = {
    id: '',
    model: '',
    scenario: '',
    simulatedInterval: '00:01:00',
  }

  const validationSchema = yup.object().shape({
    id: yup.string().uuid(),
    model: yup.string().uuid().required('Please select a model'),
    scenario: yup.string().uuid(),
    simulatedInterval: yup.string().required('Please enter an interval'),
  })

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      if (field === 'simulatedInterval') {
        initialValues[field] = instance[field] ?? '00:01:00'
        return
      }
      initialValues[field] = instance[field] ?? ''
    })
  }

  const flexOne = { flex: 1 }

  return (
    <FormDialog
      label={`${instance?.id ? 'Edit' : 'Add'} Simulated Device`}
      open={open}
      onSave={handleOnSave}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      successMessage={`Successfully ${
        instance?.id ? 'updated' : 'created'
      } simulated device`}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        gap="1rem"
      >
        <Box display="flex" justifyContent="center" alignContent="center" gap="2rem">
          <Field
            component={StaticSelect}
            options={systemDeviceModels?.map((item) => item.id) ?? []}
            optionLabelFormatter={(id) =>
              systemDeviceModels?.find((model) => model.id === id)?.name
            }
            variant="standard"
            label="Model"
            name="model"
            sx={flexOne}
          />
          <Field
            component={TextField}
            name="simulatedInterval"
            label="Simulated Interval"
            placeholder="00:00:00"
            sx={{ flex: 0.4 }}
          />
        </Box>
        <Box display="flex" justifyContent="center" alignContent="center" gap="2rem">
          <Field
            component={FormikDynamicSelect}
            name="scenario"
            label="Scenario"
            endpoint="scenarios"
            sx={flexOne}
          />
        </Box>
      </Box>
    </FormDialog>
  )
}
