import { memo } from 'react'

import {
  TableChart as TableChartIcon,
  Timeline as TimelineIcon,
} from '@mui/icons-material'
import { Stack, ToggleButtonGroup, Typography } from '@mui/material'

import { ToggleButtonWithTooltip } from '@common/components'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.selectedView
 * @param {Function} props.setSelectedView
 * @param {boolean} props.disabled
 */
const DisplaySelector = memo(({ selectedView, setSelectedView, disabled }) => (
  <Stack direction="column" spacing={1}>
    <Typography variant="body1">Display</Typography>
    <ToggleButtonGroup
      color="primary"
      value={selectedView}
      exclusive
      onChange={(_, selectedValue) => {
        if (selectedValue !== null) {
          setSelectedView(selectedValue)
        }
      }}
      disabled={disabled}
    >
      <ToggleButtonWithTooltip
        value="graph"
        tooltip="Graph view"
        disabled={selectedView === 'graph'}
        sx={{ py: 0.8 }}
      >
        <TimelineIcon />
      </ToggleButtonWithTooltip>
      <ToggleButtonWithTooltip
        value="table"
        tooltip="Table view"
        disabled={selectedView === 'table'}
        sx={{ py: 0.8 }}
      >
        <TableChartIcon />
      </ToggleButtonWithTooltip>
    </ToggleButtonGroup>
  </Stack>
))

export default DisplaySelector
