import { createListBundle, getEntityFromUrl } from '@common/utils'

import notificationUrls from './urls'

const entityName = 'notification'

const notificationListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/notifications/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (url, _, hash) =>
    url === notificationUrls.list || notificationUrls.tabHash.includes(hash),
})

export default notificationListBundle
