import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {boolean} props.loading
 * @param {Object} props.visibleDataByCategory
 * @param {Object} [props.data]
 * @param {Object[]} props.data.dataTypes
 * @param {string} props.data.key
 * @param {Function} props.data.handleToggleDtVisibility
 */
export default function DataFilterModal({
  open,
  onClose,
  loading,
  visibleDataByCategory,
  data = null,
}) {
  if (!data) {
    return null
  }

  const { dataTypes, key, handleToggleDtVisibility } = data
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h6" textAlign="center" mb={2}>
          Data Filter
        </Typography>
        <Stack spacing={1}>
          {dataTypes.map(({ color, name, label }) => (
            <FormControlLabel
              sx={{ color, mr: 0 }}
              key={name}
              disabled={loading}
              label={
                <Box>
                  {label}
                  <Box sx={{ fontSize: 12 }}>{name}</Box>
                </Box>
              }
              control={
                <Checkbox
                  sx={{ color, '&.Mui-checked': { color } }}
                  checked={visibleDataByCategory[key][name]}
                  onChange={() => {
                    handleToggleDtVisibility(name, key)
                  }}
                />
              }
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
