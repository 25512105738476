import { useCallback, useEffect, useState } from 'react'

import { pipe, replace, toUpper, trim } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

import { titleize } from 'inflection'

import { DynamicSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.profile]
 * @param {string} [props.profile.id]
 * @param {string} [props.profile.name]
 * @param {string} [props.profile.property]
 * @param {string} [props.profile.propertyName]
 * @param {Object[]} [props.profile.rules]
 */
export default function AssignThreshold({ open, onClose, profile = undefined }) {
  const [currentEntity, setCurrentEntity] = useState('unitGroup')

  const [selectedProperty, setSelectedProperty] = useState(null)
  const [selectedUnitGroup, setSelectedUnitGroup] = useState(null)

  const { doThresholdProfileAssign, doShowSnackbar } = useConnect(
    'doThresholdProfileAssign',
    'doShowSnackbar',
  )

  useEffect(() => {
    const defaultEntity = 'unitGroup'
    setCurrentEntity(defaultEntity)

    if (!open) {
      setSelectedProperty(null)
      setSelectedUnitGroup(null)
    }
  }, [open])

  const entityMap = {
    property: {
      endpoint: 'properties',
      selected: selectedProperty,
      setSelected: setSelectedProperty,
    },
    unitGroup: {
      endpoint: 'unit_groups',
      selected: selectedUnitGroup,
      setSelected: setSelectedUnitGroup,
    },
  }

  const hasSelectedEntities = selectedProperty || selectedUnitGroup

  const resetAdjacentEntities = (entity) => {
    Object.keys(entityMap)
      .filter((e) => e !== entity)
      .map((ent) => entityMap[ent].setSelected(null))
  }

  const handleAssign = useCallback(async () => {
    try {
      const response = await doThresholdProfileAssign({
        thresholdProfile: profile.id,
        ...(selectedUnitGroup ? { unitGroup: selectedUnitGroup.id } : {}),
        ...(selectedProperty ? { property: selectedProperty.id } : {}),
      })
      if (response.error) {
        throw response.error
      }

      const { name: entityName } = selectedProperty || selectedUnitGroup
      doShowSnackbar(`Profile assigned to ${entityName}`, 'success')
      onClose(true)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [profile, selectedProperty, selectedUnitGroup])

  useEffect(() => {
    if (profile?.property && currentEntity === 'property') {
      entityMap[currentEntity].setSelected({
        id: profile.property,
        name: profile.propertyName,
      })
    }
  }, [currentEntity, profile])

  const formatEntity = pipe(replace(/([A-Z])/g, ' $1'), toUpper, trim)

  return (
    <Dialog fullWidth open={open} onClose={onClose} disableScrollLock maxWidth="sm">
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, pb: 0, px: 4 }}
      >
        Assign Profile To
        <IconButton onClick={onClose} sx={{ padding: 0, width: '32px' }}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 4, px: 4, maxWidth: '1000px' }}>
        <Stack direction="column" spacing={2} mt={3}>
          {Object.keys(entityMap).length > 1 && (
            <ToggleButtonGroup
              exclusive
              color="primary"
              value={currentEntity}
              size="small"
              onChange={(_, selectedValue) => {
                resetAdjacentEntities(selectedValue)
                setCurrentEntity(selectedValue)
              }}
            >
              {Object.keys(entityMap).map((ent) => (
                <ToggleButton
                  key={ent}
                  value={ent}
                  disabled={ent === currentEntity || ent === 'propertyGroup'}
                  sx={{ py: 0.4, fontSize: 12 }}
                >
                  {formatEntity(ent)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
          {entityMap[currentEntity] && (
            <DynamicSelect
              fullWidth
              getFullEntity
              useValueInRequest={false}
              label={titleize(formatEntity(currentEntity))}
              endpoint={entityMap[currentEntity].endpoint}
              initialInput={
                profile?.property && currentEntity === 'property'
                  ? profile.propertyName
                  : null
              }
              disabled={currentEntity === 'property'}
              filters={{
                ...(profile?.property && { property: profile.property }),
              }}
              value={entityMap[currentEntity].selected}
              onChange={(value) => entityMap[currentEntity].setSelected(value)}
              variant="outlined"
              size="small"
            />
          )}
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={!hasSelectedEntities}
              onClick={handleAssign}
            >
              Assign
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
