import { createListBundle, getEntityFromUrl } from '@common/utils'

export const initialParams = { active: true }

const entityName = 'membership'

const membershipListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return entityType
      ? apiFetch(
          '/memberships/',
          { [entityType]: entityId, ...params },
          { cancelationPrefix: entityName },
        )
      : null
  },
})

export default membershipListBundle
