import { useCallback } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Button, Stack } from '@mui/material'

import { DateTime } from 'luxon'

import { useSmallScreen } from '@common/utils'
import {
  digestDeliveryOptions,
  getExistingPropertyProcess,
} from '@portal/pages/Properties/utils'
import { smokeProfileEnsemblesUrls } from '@portal/pages/SmokeProfileEnsembles'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.smokeEnsembleProfileData]
 * @param {string} props.smokeEnsembleProfileData.id
 * @param {string} props.smokeEnsembleProfileData.name
 * @param {Object} [props.property]
 * @param {string} [props.property.goLiveDate]
 * @param {string} [props.property.smokingFee]
 * @param {boolean} [props.property.autopopulateSmokeFee]
 * @param {boolean} [props.property.installationApproved]
 * @param {number[]} [props.property.smokeDigestHours]
 * @param {string} [props.property.smokingReportVersion]
 * @param {string} [props.property.smokingPolicy]
 * @param {Function} [props.onEdit]
 * @param {number} [props.margin]
 * @param {number} [props.gap]
 */
export default function SmokeComponent({
  smokeEnsembleProfileData = undefined,
  property = undefined,
  margin = 0,
  gap = 0,
  onEdit = undefined,
}) {
  const { smokeReportVersions } = useConnect('selectSmokeReportVersions')
  const isSmallScreen = useSmallScreen()

  const getDeliveryLabel = useCallback(
    (hours) =>
      digestDeliveryOptions
        .filter((opt) => hours?.includes(Number(opt.id)))
        .map((opt) => opt.label)
        .join(' & '),
    [digestDeliveryOptions],
  )

  const getReportVersionLabel = useCallback(
    (version) => smokeReportVersions.find((v) => v.id === version)?.label ?? version,
    [smokeReportVersions],
  )

  const showSmokingPolicy = property?.smokingReportVersion === 'DATA_QUALITY'

  return (
    <Stack direction="row" alignItems="start">
      <DetailContainer margin={margin} gap={gap}>
        <DetailItem
          label="Alerting Smoke Profile Ensemble"
          value={smokeEnsembleProfileData ? smokeEnsembleProfileData.name : '--'}
          linkTo={
            smokeEnsembleProfileData
              ? smokeProfileEnsemblesUrls.entity.replace(
                  ':id',
                  smokeEnsembleProfileData.id,
                )
              : null
          }
        />
        <DetailItem
          label="Go Live Date"
          value={
            property?.goLiveDate
              ? DateTime.fromISO(property.goLiveDate).toLocaleString(
                  DateTime.DATE_SHORT,
                )
              : 'N/A'
          }
        />
        <DetailItem label="Smoking Fee" value={`$${property?.smokingFee}`} />
        <DetailItem
          label="Process"
          value={getExistingPropertyProcess(property) ?? 'Undefined'}
        />
        <DetailItem
          label="Smoke Digest Hour(s)"
          value={getDeliveryLabel(property.smokeDigestHours)}
        />
        <DetailItem
          label="Autopopulate Smoke Fee"
          value={property?.autopopulateSmokeFee ? 'Yes' : 'No'}
        />
        <DetailItem
          label="Installation Approved"
          value={property?.installationApproved ? 'Yes' : 'No'}
        />
      </DetailContainer>
      <DetailContainer margin={margin} gap={gap}>
        <DetailItem
          label="Smoking Report Version"
          value={getReportVersionLabel(property?.smokingReportVersion)}
        />
        <DetailItem
          label="Smoking Policy"
          value={showSmokingPolicy ? property?.smokingPolicy : '--'}
        />
      </DetailContainer>
      {onEdit && (
        <Button onClick={onEdit} sx={{ mt: 1, fontSize: isSmallScreen ? 12 : 14 }}>
          Edit
        </Button>
      )}
    </Stack>
  )
}
