import { memo } from 'react'

import { Stack, ToggleButtonGroup, Typography } from '@mui/material'

import { pluralize, titleize } from 'inflection'

import { ToggleButtonWithTooltip } from '@common/components'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.aggregatedDateRanges]
 * @param {Object} props.selectedInterval
 * @param {number} props.selectedInterval.value
 * @param {string[]} props.visibleDatePresetKeys
 * @param {string} props.datePreset
 * @param {Object} props.datePresets
 * @param {Object} [props.datePresets.custom]
 * @param {string} [props.datePresets.custom.value]
 * @param {Object[]} props.dateRangeValue
 * @param {Function} props.dateRangeValue[].toFormat
 * @param {Function} props.handlePresetChange
 * @param {Function} props.handleAggregatedDateRangeChange
 * @param {boolean} props.disabled
 */
const DateRangeSelector = memo(
  ({
    aggregatedDateRanges = undefined,
    selectedAggregationRange,
    selectedInterval,
    visibleDatePresetKeys,
    datePreset,
    datePresets,
    dateRangeValue,
    handlePresetChange,
    handleAggregatedDateRangeChange,
    disabled,
  }) => (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">Date Range</Typography>
      {!aggregatedDateRanges && (
        <ToggleButtonGroup color="primary" variant="outlined" disabled={disabled}>
          {visibleDatePresetKeys.map((key) => {
            const customTooltipFormat = 'yyyy-MM-dd'
            const { value } = datePresets[key]
            const tooltip = (() => {
              const showCustomDateTooltip =
                value === datePresets.custom.value &&
                datePreset === datePresets.custom.value
              if (showCustomDateTooltip) {
                const start = dateRangeValue[0]?.toFormat(customTooltipFormat)
                const end = dateRangeValue[1]?.toFormat(customTooltipFormat)
                return `${start} - ${end}`
              }
              return datePresets[key]?.tooltip
            })()
            return (
              <ToggleButtonWithTooltip
                key={value}
                tooltip={tooltip}
                value={value}
                selected={datePreset === value}
                onClick={(_, selectedValue) => handlePresetChange(selectedValue)}
                sx={{ py: 0.8 }}
              >
                {value.toUpperCase()}
              </ToggleButtonWithTooltip>
            )
          })}
        </ToggleButtonGroup>
      )}
      {aggregatedDateRanges && (
        <ToggleButtonGroup color="primary" variant="outlined" disabled={disabled}>
          {Object.keys(aggregatedDateRanges).map((key) => {
            const { value, label } = aggregatedDateRanges[key]
            const isCustomValue = !Object.values(aggregatedDateRanges)
              .map((i) => i.value)
              .includes(selectedAggregationRange)
            const tooltip = (() => {
              if (isCustomValue && value === 'custom') {
                const entity = selectedInterval.value === 7 ? 'week' : 'month'
                return `${selectedAggregationRange} ${titleize(pluralize(entity))}`
              }
              return aggregatedDateRanges[key]?.tooltip
            })()
            return (
              <ToggleButtonWithTooltip
                key={value}
                tooltip={tooltip}
                value={value}
                selected={
                  selectedAggregationRange === value ||
                  (isCustomValue && value === 'custom')
                }
                onClick={(_, selectedValue) =>
                  handleAggregatedDateRangeChange(selectedValue)
                }
                sx={{ py: 0.8 }}
              >
                {(label ?? value.toString()).toUpperCase()}
              </ToggleButtonWithTooltip>
            )
          })}
        </ToggleButtonGroup>
      )}
    </Stack>
  ),
)

export default DateRangeSelector
