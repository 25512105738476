import { createListBundle } from '@common/utils'

import unitUrls from './urls'

export const initialParams = { active: true }

const entityName = 'unitZone'

const unitZoneListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) =>
    apiFetch(
      '/zones/',
      {
        unit: store.selectRouteParams().id,
        ...(!store.selectUnitZoneListLastSuccess() ? initialParams : {}),
        ...params,
      },
      { cancelationPrefix: entityName },
    ),
  urlTest: (_, pattern) => pattern === unitUrls.entity,
})

export default unitZoneListBundle
