import { KeyboardTabOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'

import {
  baseArrowStyles,
  leftArrowStyles,
  leftEventDrawerArrowStyles,
  rightArrowStyles,
  rightEventDrawerArrowStyles,
} from '@portal/pages/Sidebar/Sidebar.styles'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.handleDrawer
 * @param {boolean} props.leftOpen
 */
export function RightDrawerButton({ handleDrawer, leftOpen }) {
  return (
    <IconButton
      size="small"
      onClick={handleDrawer}
      sx={
        leftOpen
          ? { ...baseArrowStyles, ...rightArrowStyles }
          : { ...baseArrowStyles, ...rightEventDrawerArrowStyles }
      }
    >
      <KeyboardTabOutlined fontSize="small" sx={{ color: 'white' }} />
    </IconButton>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.handleDrawer
 * @param {boolean} props.leftOpen
 */
export function LeftDrawerButton({ handleDrawer, leftOpen }) {
  return (
    <IconButton
      size="small"
      onClick={handleDrawer}
      sx={
        leftOpen
          ? { ...baseArrowStyles, ...leftArrowStyles }
          : { ...baseArrowStyles, ...leftEventDrawerArrowStyles }
      }
    >
      <KeyboardTabOutlined sx={{ rotate: '180deg', color: 'white' }} fontSize="small" />
    </IconButton>
  )
}
