import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Animation as AnimationIcon,
  ApartmentOutlined as ApartmentIcon,
  AppSettingsAltRounded as SettingsIcon,
  Book as BookIcon,
  Circle,
  CopyAll,
  DeveloperMode as DeveloperModeIcon,
  DomainDisabled as DomainDisabledIcon,
  SendToMobile as SendToMobileIcon,
} from '@mui/icons-material'
import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material'

import { titleize } from 'inflection'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useHashFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import ScenarioForm from '@portal/pages/Scenarios/ScenarioForm'
import ScenariosList from '@portal/pages/Scenarios/Scenarios'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import BulkPopover from '@portal/UI/components/BulkPopover'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import EventLogTab from '@portal/UI/components/EventLogTab'
import ListPageTitle from '@portal/UI/components/ListPageTitle'

import ReassignForm from './ReassignForm'
import SimulatedDeviceForm from './SimulatedDeviceForm'
import CommandTab from './Tabs/Command/Command'
import CommandForm from './Tabs/Command/CommandForm'
import DeviceTab from './Tabs/Device'
import deviceUrls from './urls'

export const getStatusColor = (status) => {
  switch (status) {
    case 'ONLINE':
      return 'success'
    case 'RETIRED':
    case 'FAILURE':
      return 'error'
    default:
      return 'warning'
  }
}

export default function SingleDevice() {
  const {
    isAtLeastAdmin,
    hashObject,
    routeParams: { id: deviceId },
    deviceFetchStatus,
    deviceFetch: device,
    doDeviceFetch,
    doScenarioFetch,
    doDeviceUnassign,
    doDeviceSendCommand,
    doDeviceSave,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectHashObject',
    'selectRouteParams',
    'selectDeviceFetchStatus',
    'selectDeviceFetch',
    'doDeviceFetch',
    'doScenarioFetch',
    'doDeviceUnassign',
    'doDeviceSendCommand',
    'doDeviceSave',
    'doShowSnackbar',
  )
  const [reassignFormOpen, setReassignFormOpen] = useState(false)
  const [editScenarioForm, setEditScenarioForm] = useState(false)
  const [commandFormOpen, setCommandFormOpen] = useState(false)
  const [statusPopoverOpen, setStatusPopoverOpen] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [unassignConfirmationOpen, setUnassignConfirmationOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [simulatedDeviceFormOpen, setSimulatedDeviceFormOpen] = useState(false)
  const [deviceScenario, setDeviceScenario] = useState({})

  const isSmallScreen = useSmallScreen()

  const fetchDevice = useCallback(async (id) => {
    try {
      await doDeviceFetch(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useEffect(() => {
    fetchDevice(deviceId)
  }, [])

  const tabs = [
    { label: 'Device', component: <DeviceTab />, hash: 'device' },
    { label: 'Command', component: <CommandTab />, hash: 'command' },
    { label: 'Scenarios', component: <ScenariosList renderAsTab />, hash: 'scenarios' },
    {
      label: 'Event Log',
      component: <EventLogTab entity="Device" />,
      hash: 'eventLog',
    },
    {
      label: 'Audit Log',
      component: <AuditLogTab objectId={deviceId} />,
      hash: 'auditLog',
    },
  ]

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  const handleEditScenario = async () => {
    const scenario = await doScenarioFetch(device?.scenario)
    setDeviceScenario(scenario)
    setEditScenarioForm(true)
  }

  const handleUnassignDevice = async () => {
    const response = await doDeviceUnassign([{ id: deviceId }])
    if (response?.error) {
      doShowSnackbar(`An unexpected error occurred`, 'error')
      return
    }
    doShowSnackbar(`Successfully unassigned device`, 'success')
    fetchDevice(deviceId)
    setUnassignConfirmationOpen(false)
  }

  const openUpdateStatus = (e) => {
    setStatusPopoverOpen(true)
    setPopoverAnchor(e.target)
  }

  const closeUpdateStatus = () => {
    setStatusPopoverOpen(false)
    setPopoverAnchor(null)
  }

  const updateStatus = (status) => async () => {
    const response = await doDeviceSave([{ id: deviceId, status }])
    if (response.error) {
      doShowSnackbar('Error in updating devices', 'error')
    } else {
      doShowSnackbar('Successfully updated devices', 'success')
      fetchDevice(deviceId)
      closeUpdateStatus()
    }
  }

  const DetailChips = useCallback(
    () => (
      <>
        <Chip
          icon={<Circle />}
          variant="outlined"
          size="small"
          label={
            device?.status === 'RMA' ? device.status : titleize(device?.status || '')
          }
          color={getStatusColor(device?.status)}
        />
        {device?.simulatedInterval && (
          <Box display="flex " alignItems="center" gap={1}>
            <Tooltip placement="right" title="This is a simulated device.">
              <AnimationIcon color="primary" />
            </Tooltip>
            <Typography variant="caption" color="text.secondary">
              Simulated device
            </Typography>
          </Box>
        )}
      </>
    ),
    [device],
  )

  if (deviceFetchStatus === 'failed') {
    return <p>Invalid ID or something or other</p>
  }

  if (deviceFetchStatus === 'loading' || device?.id !== deviceId) {
    return <Loading />
  }

  return (
    <Box sx={{ px: isSmallScreen ? 0 : 2, m: isSmallScreen ? 3 : 4 }}>
      <BulkPopover
        items={[
          { label: 'Mark as In Service', handler: updateStatus('OFFLINE') },
          { label: 'Mark as Failure', handler: updateStatus('FAILURE') },
          { label: 'Mark as RMA', handler: updateStatus('RMA') },
          {
            label: 'Mark as RMA Replacement Shipped',
            handler: updateStatus('RMA_REPLACEMENT_SHIPPED'),
          },
          { label: 'Mark as Retired', handler: updateStatus('RETIRED') },
          { label: 'Mark as Provisioned', handler: updateStatus('PROVISIONED') },
          { label: 'Mark as Installed', handler: updateStatus('INSTALLED') },
          {
            label: 'Mark as Awaiting Service',
            handler: updateStatus('AWAITING_SERVICE'),
          },
        ]}
        open={statusPopoverOpen}
        anchorEl={popoverAnchor}
        onClose={closeUpdateStatus}
      />
      <Breadcrumbs
        links={[
          { label: 'Home', href: homeUrls.home },
          { label: 'Devices', href: deviceUrls.list },
          { label: device?.mainMac || 'Loading...' },
        ]}
      />
      {device?.id && (
        <Box display="flex" alignItems="center" mx={1}>
          <Typography variant="caption" color="text.secondary">
            id: {device.id}
          </Typography>
          <CopyAll
            sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
            onClick={() => {
              navigator.clipboard.writeText(device.id)
              doShowSnackbar('ID copied to clipboard')
            }}
          />
        </Box>
      )}
      {isSmallScreen ? (
        <Box mb={1}>
          <ListPageTitle
            title={`${device?.mainMac} (${device?.zoneName || null})`}
            menuItems={
              isAtLeastAdmin
                ? [
                    { label: 'Assign', onClick: () => setReassignFormOpen(true) },
                    {
                      label: 'Unassign',
                      onClick: () => setUnassignConfirmationOpen(true),
                    },
                    { label: 'Send Command', onClick: () => setCommandFormOpen(true) },
                    { label: 'Update Status', onClick: (e) => openUpdateStatus(e) },
                    ...(device?.simulatedInterval && device?.scenario
                      ? [
                          {
                            label: 'Edit assigned scenario',
                            onClick: handleEditScenario,
                          },
                        ]
                      : []),
                  ]
                : null
            }
          />
          <Stack direction="row" alignItems="center" spacing={1.5} marginY={1.5}>
            <DetailChips />
          </Stack>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Stack>
            <Typography variant="h3" mr={2}>
              {`${device?.mainMac} (${device?.zoneName || null})`}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1.5} marginY={1.5}>
              <DetailChips />
            </Stack>
          </Stack>
          {isAtLeastAdmin && (
            <Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  startIcon={<ApartmentIcon />}
                  variant="outlined"
                  sx={{ mr: 2, mt: 1.5 }}
                  onClick={() => setReassignFormOpen(true)}
                >
                  assign
                </Button>
                <Button
                  startIcon={<DomainDisabledIcon />}
                  variant="outlined"
                  sx={{ mr: 2, mt: 1.5 }}
                  onClick={() => setUnassignConfirmationOpen(true)}
                >
                  unassign
                </Button>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  startIcon={<SendToMobileIcon />}
                  variant="outlined"
                  sx={{ mr: 2, mt: 1.5 }}
                  onClick={() => setCommandFormOpen(true)}
                >
                  send command
                </Button>
                <Button
                  startIcon={<DeveloperModeIcon />}
                  variant="outlined"
                  sx={{ mr: 2, mt: 1.5 }}
                  onClick={openUpdateStatus}
                >
                  update status
                </Button>
                {device?.simulatedInterval && device?.scenario && (
                  <Button
                    startIcon={<BookIcon />}
                    variant="outlined"
                    sx={{ mr: 2, mt: 1.5 }}
                    onClick={handleEditScenario}
                  >
                    edit assigned scenario
                  </Button>
                )}
                {device?.simulatedInterval && (
                  <Button
                    startIcon={<SettingsIcon />}
                    variant="outlined"
                    sx={{ mr: 2, mt: 1.5 }}
                    onClick={() => setSimulatedDeviceFormOpen(true)}
                  >
                    Edit Device
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
      <ScenarioForm
        open={editScenarioForm}
        onClose={() => {
          setEditScenarioForm(false)
          setDeviceScenario({})
        }}
        instance={deviceScenario}
      />
      <SimulatedDeviceForm
        open={simulatedDeviceFormOpen}
        onClose={(success) => {
          setSimulatedDeviceFormOpen(false)
          if (success === true) {
            fetchDevice(deviceId)
          }
        }}
        instance={device}
      />
      <CommandForm
        devices={[deviceId]}
        open={commandFormOpen}
        onClose={(success) => {
          setCommandFormOpen(false)
          if (success === true) {
            fetchDevice(deviceId)
          }
        }}
        onSave={doDeviceSendCommand}
      />
      <ReassignForm
        open={reassignFormOpen}
        onClose={(success) => {
          setReassignFormOpen(false)
          if (success === true) {
            fetchDevice(deviceId)
          }
        }}
        instance={device}
      />
      <ConfirmationDialog
        fullWidth={false}
        title="Unassign Device"
        message="Are you sure you want to unassign this device?"
        open={unassignConfirmationOpen}
        onConfirm={handleUnassignDevice}
        onCancel={() => setUnassignConfirmationOpen(false)}
      />
      <TabComponent
        tabs={tabs}
        externalState={{ value: tabValue, setValue: setTabValue }}
      />
    </Box>
  )
}
