import { createListBundle } from '@common/utils'
import { EVENT_HASH } from '@portal/pages/EventDashboard/Tabs/Dashboard/EventDashboardContent'
import eventUrls from '@portal/pages/EventDashboard/urls'
import { DASHBOARD_OUTAGES_HASH } from '@portal/pages/Home/Dashboard'

const entityName = 'auditLog'

const auditLogListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) => {
    // prevent unscoped calls
    if (!params.objectId && !params.modifiedBy) {
      return null
    }
    return apiFetch('/audit_logs/', params, { cancelationPrefix: entityName })
  },
  urlTest: (url, _, hash, queryObject) =>
    ['auditLog', 'actions'].includes(hash) ||
    [eventUrls.list].includes(url) ||
    ((hash === DASHBOARD_OUTAGES_HASH || hash === EVENT_HASH) &&
      !!queryObject?.auditLog),
})

export default auditLogListBundle
