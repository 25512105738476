import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.margin]
 * @param {number} [props.gap]
 */
export default function NoiseComponent({ margin = 0, gap = 0 }) {
  return (
    <DetailContainer margin={margin} gap={gap}>
      <DetailItem label="Noise Enabled" value="Yes" />
    </DetailContainer>
  )
}
