/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { equals } from 'ramda'

import VerticalThresholdedLine from '../components/VerticalThresholdedLine'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    equals(prev.data, next.data) &&
    prev.color === next.color &&
    prev.yMin === next.yMin &&
    prev.yMax === next.yMax &&
    prev.graphWidth === next.graphWidth &&
    prev.yScale === next.yScale &&
    equals(prev.padding, next.padding)
  )
}

/**
 * 'graphWidth' and 'padding' not used directly in the component
 * but only in `propsAreEqual` function to properly rerender component
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Object[]} props.data
 * @param {string} props.color
 * @param {Function} props.x
 * @param {Function} props.y
 * @param {Function} props.curve
 * @param {number} props.yMin
 * @param {number} props.yMax
 * @param {Function} props.defined
 * @param {Function} props.threshold
 * @param {Function} props.yScale
 * @param {number} [props.graphWidth]
 * @param {Object} [props.padding]
 */
const MemoVerticalThresholdedLine = memo(
  ({
    id,
    data,
    x,
    y,
    yMin,
    yMax,
    defined,
    threshold,
    curve,
    color,
    graphWidth = undefined,
    padding = undefined,
    yScale,
  }) => (
    <VerticalThresholdedLine
      id={id}
      data={data}
      x={x}
      y={y}
      yMin={yMin}
      yMax={yMax}
      defined={defined}
      threshold={threshold}
      curve={curve}
      color={color}
    />
  ),
  propsAreEqual,
)

export default MemoVerticalThresholdedLine
