import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { noop } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.dataTestId]
 * @param {Object} props.children
 * @param {string} props.label
 * @param {Object} [props.initialValues]
 * @param {Function} props.onClose
 * @param {Function} props.onSave
 * @param {boolean} props.open
 * @param {string} [props.buttonText]
 * @param {Object} [props.validationSchema]
 * @param {string} [props.successMessage]
 * @param {boolean} [props.isFormik]
 * @param {Function} [props.formatError]
 */
export default function ConfirmationDialog({
  'data-testid': dataTestId = undefined,
  children,
  label,
  initialValues = {},
  onClose,
  onSave,
  open,
  buttonText = 'Confirm',
  successMessage = 'Successfully completed the action',
  validationSchema = Yup.object().shape({}),
  isFormik = true,
  formatError = noop,
}) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')
  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = async (values) => {
    setIsSaving(true)
    try {
      await onSave({ id: initialValues?.id, ...values })
      doShowSnackbar(successMessage, 'success')
      onClose(true)
    } catch (error) {
      captureException(error)
      doShowSnackbar(formatError(error) || 'Unable to complete request', 'error')
    }
    setIsSaving(false)
  }

  return (
    <div>
      <Dialog
        data-testid={dataTestId}
        open={open}
        onClose={onClose}
        disableScrollLock
        maxWidth="xs"
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, px: 4 }}
        >
          {label}
          <IconButton onClick={onClose} sx={{ padding: 0, width: '32px' }}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <Divider variant="middle" sx={{ mx: 4 }} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ dirty, isValid }) => (
            <Form>
              <DialogContent sx={{ py: 0, px: 4, maxWidth: '400px' }}>
                {children}
              </DialogContent>
              <DialogActions
                disableSpacing
                sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}
              >
                <Button
                  type="submit"
                  color={
                    buttonText.includes('Remove') || buttonText.includes('Delete')
                      ? 'error'
                      : 'primary'
                  }
                  variant="contained"
                  fullWidth
                  disabled={(isFormik && (!isValid || !dirty || isSaving)) || isSaving}
                  sx={{ mb: 2 }}
                >
                  {buttonText}
                </Button>
                <Button
                  onClick={onClose}
                  variant="contained"
                  fullWidth
                  sx={{
                    mb: 2,
                    bgcolor: 'grey.300',
                    color: 'text.primary',
                    '&:hover': { bgcolor: 'grey.200' },
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}
