import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  backgroundColor: 'white',
  border: '2px solid #000',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
}

const buttonBoxStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: 2,
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {string} [props.error]
 * @param {Function} props.onConfirmResetPassword
 * @param {Function} [props.onCancelResetPassword]
 */
export default function ResetPasswordModal({
  open,
  onConfirmResetPassword,
  onCancelResetPassword = () => {},
  error = '',
}) {
  return (
    <Modal open={open}>
      <Box sx={boxStyle}>
        <Typography variant="h6">
          Are you sure you want to reset the password?
        </Typography>
        {error && <Typography variant="h5">There was an error: {error}</Typography>}
        <Box sx={buttonBoxStyle}>
          <Button variant="contained" onClick={onConfirmResetPassword}>
            Reset Password
          </Button>
          <Button color="secondary" onClick={onCancelResetPassword}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
