import { useCallback, useEffect, useState } from 'react'

import { pipe, replace, trim } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, Link, Stack, Tooltip, Typography } from '@mui/material'

import { capitalize } from 'inflection'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { formatCron, useQueryFilter, useSmallScreen } from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import { homeUrls } from '@portal/pages/Home'
import organizationUrls from '@portal/pages/Organizations/urls'
import propUrls from '@portal/pages/Properties/urls'
import userUrls from '@portal/pages/Users/urls'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListExportButton from '@portal/UI/components/ListExportButton'
import ListPageTitle from '@portal/UI/components/ListPageTitle'

import ScheduledReportPreferenceForm from './ScheduledReportPreferenceForm'

// TODO: render filter and actions in tab mode

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 * @param {Object} [props.user]
 * @param {string} props.user.id
 * @param {string} props.user.name
 */
export default function ScheduledReportPreferences({ renderAsTab = false, user }) {
  const [pageSize, setPageSize] = useState('')
  const [deleteError, setDeleteError] = useState(null)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [rowIdForDelete, setRowIdForDelete] = useState(null)
  const [preferenceFormOpen, setPreferenceFormOpen] = useState(false)
  const [currentPreference, setCurrentPreference] = useState(undefined)
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    doShowSnackbar,
    doScheduledReportPreferenceDelete,
    doScheduledReportPreferenceListSetPageSize,
    doScheduledReportPreferenceListSetPage,
    doScheduledReportPreferenceListSetOrdering,
    doScheduledReportPreferenceListSetFilter,
    doScheduledReportPreferenceListClearParams,
    doMarkScheduledReportPreferenceListAsOutdated,
    scheduledReportPreferenceList,
    scheduledReportPreferenceListRaw: { ordering = [] },
    scheduledReportPreferenceListIsLoading,
    scheduledReportPreferenceListApiParams,
    systemReportClasses,
    queryObject,
  } = useConnect(
    'doShowSnackbar',
    'doScheduledReportPreferenceDelete',
    'doScheduledReportPreferenceListSetPageSize',
    'doScheduledReportPreferenceListSetPage',
    'doScheduledReportPreferenceListSetOrdering',
    'doScheduledReportPreferenceListSetFilter',
    'doScheduledReportPreferenceListClearParams',
    'doMarkScheduledReportPreferenceListAsOutdated',
    'selectScheduledReportPreferenceList',
    'selectScheduledReportPreferenceListRaw',
    'selectScheduledReportPreferenceListIsLoading',
    'selectScheduledReportPreferenceListApiParams',
    'selectSystemReportClasses',
    'selectQueryObject',
  )

  const isSmallScreen = useSmallScreen()

  const lookbackOptions = [
    { label: '1 Day', value: '1 00:00:00' }, // 1 day
    { label: '1 Week', value: '7 00:00:00' }, // 7 days
    { label: '1 Month', value: '30 00:00:00' }, // 30 days
    { label: '1 Year', value: '365 00:00:00' }, // 365 days
    { label: 'Custom', value: 'Custom' },
  ]

  const intervalOptions = [
    { label: 'Daily', value: '0 9 * * *' }, // 9am daily.
    { label: 'Weekly', value: '0 9 * * 1' }, // 9am every Monday.
    { label: 'Monthly', value: '0 9 1 * *' }, // At 9am on day 1 of every month
    { label: 'Custom', value: 'Custom' },
  ]

  const reportPreferenceTabFilterWrapper = (filter) =>
    doScheduledReportPreferenceListSetFilter({
      ...filter,
      ...(renderAsTab && user ? { user: user.id } : {}),
    })

  useQueryFilter({
    query: queryObject,
    apiParams: scheduledReportPreferenceListApiParams,
    setFilter: reportPreferenceTabFilterWrapper,
    setPageSize: doScheduledReportPreferenceListSetPageSize,
  })

  const handleClear = () => {
    doScheduledReportPreferenceListClearParams()
    reportPreferenceTabFilterWrapper()
  }

  useEffect(() => {
    handleClear()
    return () => doScheduledReportPreferenceListClearParams()
  }, [])

  const handlePageChange = async (pageNumber) => {
    doScheduledReportPreferenceListSetPage(pageNumber)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doScheduledReportPreferenceListSetPageSize(size)
  }

  const handlePreferenceSave = (row) => {
    setPreferenceFormOpen(true)
    if (renderAsTab && !row.users) {
      setCurrentPreference({ users: [user] })
    } else {
      setCurrentPreference(row)
    }
  }

  const handlePreferenceDelete = (row) => {
    setDeleteFormOpen(true)
    setRowIdForDelete(row)
  }

  const handleDeleteClose = () => {
    setDeleteFormOpen(false)
    setRowIdForDelete(null)
  }

  const handleDeletePref = async () => {
    try {
      await doScheduledReportPreferenceDelete(rowIdForDelete)
      doMarkScheduledReportPreferenceListAsOutdated()
      doShowSnackbar('Successfully deleted report preference.')
      handleDeleteClose()
    } catch (e) {
      setDeleteError(e)
    }
  }

  const formatEntity = pipe(replace(/([A-Z])/g, ' $1'), capitalize, trim)

  const exportListConfig = {
    apiParams: scheduledReportPreferenceListApiParams,
    entity: 'scheduled_report_preferences',
  }

  const listActions = {
    create: handlePreferenceSave,
    update: handlePreferenceSave,
    delete: handlePreferenceDelete,
  }

  const usersLimit = 3

  const getEntityCellData = useCallback((row) => {
    const entity = (() => {
      switch (true) {
        case !!row.organization:
          return 'organization'
        case !!row.account:
          return 'account'
        case !!row.propertyGroup:
          return 'propertyGroup'
        case !!row.property:
          return 'property'
        default:
          return null
      }
    })()
    const urls = (() => {
      switch (true) {
        case !!row.organization:
          return organizationUrls
        case !!row.account:
          return accountUrls
        case !!row.property:
          return propUrls
        default:
          return null
      }
    })()

    return { entity, urls }
  }, [])

  const columns = [
    {
      field: 'users',
      headerName: 'Users',
      flex: 1.2,
      renderCell: ({ row }) => (
        <Box>
          {row.users.slice(0, usersLimit).map((u) => (
            <Link key={u.id} href={userUrls.entity.replace(':id', u?.id)}>
              <Typography variant="body2">{u.email}</Typography>
            </Link>
          ))}
          {row.users.length > 3 && (
            <Tooltip
              title={
                <Box>
                  {row.users.slice(usersLimit).map((u) => (
                    <Link key={u.id} href={userUrls.entity.replace(':id', u?.id)}>
                      <Typography variant="body2" color="white">
                        {u.email}
                      </Typography>
                    </Link>
                  ))}
                </Box>
              }
              style={{ whiteSpace: 'pre-line' }}
            >
              <Typography variant="body2">+{row.users.length - 3}</Typography>
            </Tooltip>
          )}
        </Box>
      ),
      renderMobile: ({ row }) => (
        <Stack textAlign="end">
          {row.users.slice(0, usersLimit).map((u) => (
            <Typography key={u.id} variant="caption">
              <Link href={userUrls.entity.replace(':id', u?.id)}>{u.email}</Link>
            </Typography>
          ))}
          {row.users.length > 3 && (
            <Typography variant="caption">+{row.users.length - 3}</Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'entity',
      headerName: 'Entity',
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => {
        const { entity, urls } = getEntityCellData(row)
        if (entity && entity !== 'propertyGroup') {
          return (
            <ClickableCell
              label={`[${formatEntity(entity)}] ${row[`${entity}Name`]}`}
              url={urls?.entity.replace(':id', row[entity])}
            />
          )
        }
        return entity ? `[${formatEntity(entity)}] ${row[`${entity}Name`]}` ?? '-' : '-'
      },
    },
    {
      field: 'report',
      headerName: 'Report Class',
      flex: 0.9,
      valueGetter: (_, row) =>
        systemReportClasses?.find((rep) => rep.id === row.report)?.label,
    },
    {
      field: 'interval',
      headerName: 'Interval',
      flex: 1.1,
      renderCell: ({ row }) => (
        <Stack>
          <Typography fontSize="small" color="text.secondary">
            {intervalOptions.find((opt) => opt.value === row.interval)?.label}
          </Typography>
          <Tooltip
            placement="top"
            sx={{ fontSize: '50px' }}
            title={
              <Typography fontSize="small" sx={{ mr: 1 }}>
                {row.interval}
              </Typography>
            }
          >
            <Typography fontSize="small" sx={{ mr: 1 }}>
              {formatCron(row.interval)}
            </Typography>
          </Tooltip>
        </Stack>
      ),
      renderMobile: ({ row }) => (
        <Stack textAlign="end">
          <Typography variant="caption" color="text.secondary">
            {intervalOptions.find((opt) => opt.value === row.interval)?.label}
          </Typography>
          <Typography variant="caption">{formatCron(row.interval)}</Typography>
        </Stack>
      ),
    },
    {
      field: 'lookback',
      headerName: 'Lookback',
      flex: 0.7,
      renderCell: ({ row }) => (
        <Stack>
          <Typography fontSize="small" color="text.secondary">
            {lookbackOptions.find((opt) => opt.value === row.lookback)?.label}
          </Typography>
          {row.lookback ? row.lookback : '-'}
        </Stack>
      ),
      renderMobile: ({ row }) => (
        <Stack textAlign="end">
          <Typography variant="caption" color="text.secondary">
            {lookbackOptions.find((opt) => opt.value === row.lookback)?.label}
          </Typography>
          <Typography variant="caption">{row.lookback || '--'}</Typography>
        </Stack>
      ),
    },
    {
      field: 'timezone',
      headerName: 'Timezone',
      flex: 0.8,
    },
  ]

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  const containerProps = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    ...(renderAsTab
      ? { marginRight: 1, marginTop: isSmallScreen ? 0 : 1.5 }
      : { margin: 3 }),
  }

  if (!scheduledReportPreferenceList.results)
    return (
      <ErrorComponent title="Scheduled Report Preferences" callback={handleClear} />
    )

  return (
    <Box {...containerProps}>
      <DeleteModal
        open={deleteFormOpen}
        error={deleteError}
        onConfirmDelete={handleDeletePref}
        onCancelDelete={handleDeleteClose}
      />
      <ScheduledReportPreferenceForm
        isTab={renderAsTab}
        instance={currentPreference}
        lookbackOptions={lookbackOptions}
        intervalOptions={intervalOptions}
        open={preferenceFormOpen}
        onClose={() => {
          setCurrentPreference(undefined)
          doMarkScheduledReportPreferenceListAsOutdated()
          setPreferenceFormOpen(false)
        }}
      />
      {!renderAsTab && (
        <>
          <Breadcrumbs
            links={[
              { label: 'Home', href: homeUrls.home },
              { label: 'Scheduled Reports' },
            ]}
          />
          <ListPageTitle
            title="Scheduled Reports"
            onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
            menuItems={
              isSmallScreen
                ? [
                    { label: 'Add Scheduled Report', onClick: handlePreferenceSave },
                    {
                      label: 'Export',
                      onClick: () => {},
                      render: ({ onClose }) => (
                        <ListExportButton
                          mode="menuItem"
                          postExport={onClose}
                          {...exportListConfig}
                        />
                      ),
                    },
                  ]
                : null
            }
            mb={2}
          />
        </>
      )}
      <Box display="flex" flex={1}>
        <Filter
          open={!renderAsTab}
          mode={isSmallScreen ? 'modal' : 'drawer'}
          disabled={scheduledReportPreferenceListIsLoading}
          apiParams={scheduledReportPreferenceListApiParams}
          setApiParams={reportPreferenceTabFilterWrapper}
          dialogOpen={filterModalOpen}
          dialogOnClose={() => setFilterModalOpen(false)}
        >
          {!renderAsTab && <DynamicSelect label="Users" filterName="user" />}
          <DynamicSelect label="Organization" filterName="organization" />
          <DynamicSelect label="Account" filterName="account" />
          <DynamicSelect label="Property Groups" filterName="property_group" />
          <DynamicSelect label="Property" filterName="property" />
          <StaticSelect
            label="Report Class"
            filterName="report"
            options={systemReportClasses}
          />
        </Filter>
        <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
          {isSmallScreen ? (
            <MobileList
              dynamicRowHeight
              queryDrivenSearch
              title="Scheduled Reports"
              loading={scheduledReportPreferenceListIsLoading}
              onFilterPressed={renderAsTab ? () => setFilterModalOpen(true) : null}
              listActions={
                renderAsTab
                  ? [{ label: 'Add Scheduled Report', onClick: handlePreferenceSave }]
                  : null
              }
              actions={listActions}
              itemBuilder={MobileListItem}
              page={scheduledReportPreferenceList.current || 1}
              pageChange={handlePageChange}
              pageSize={scheduledReportPreferenceList.pageSize}
              pageSizeChange={handlePageSizeChange}
              rows={scheduledReportPreferenceList?.results || []}
              rowCount={scheduledReportPreferenceList.count || 0}
            />
          ) : (
            <List
              columnsAutosize
              dynamicRowHeight
              queryDrivenSearch
              title="Scheduled Reports"
              addButtonText="Add Scheduled Report"
              loading={scheduledReportPreferenceListIsLoading}
              actions={listActions}
              exportListConfig={
                !renderAsTab && !isSmallScreen ? exportListConfig : null
              }
              columns={
                renderAsTab ? columns.filter((c) => c.field !== 'userEmail') : columns
              }
              page={scheduledReportPreferenceList.current || 1}
              pageChange={handlePageChange}
              pageSize={scheduledReportPreferenceList.pageSize}
              pageSizeChange={handlePageSizeChange}
              rows={scheduledReportPreferenceList?.results || []}
              rowCount={scheduledReportPreferenceList.count || 0}
              sortChange={doScheduledReportPreferenceListSetOrdering}
              currentOrdering={ordering}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
