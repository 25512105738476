import { createListBundle, isAbortError } from '@common/utils'

import eventUrls from '../../urls'
import { EVENT_REVIEW_HASH } from './EventReviewContent'

const entityName = 'eventReviews'

const eventReviewsListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/event_reviews/', params, { cancelationPrefix: entityName }),
  urlTest: (url, _, hash) => url === eventUrls.list && hash === EVENT_REVIEW_HASH,
})

export default {
  ...eventReviewsListBundle,
  doEventReview:
    (payload) =>
    async ({ apiFetch }) => {
      try {
        const response = await apiFetch(`/event_reviews/`, payload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        })
        return response
      } catch (err) {
        if (!isAbortError(err)) return err
        return null
      }
    },
}
