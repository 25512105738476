import { createEntityBundle } from '@common/utils'

const entityName = 'event_feedback'

const eventFeedbackBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'create',
      handler: ({ apiFetch, payload, store }) => {
        const { id: propertyId } = store.selectCurrentProperty()
        return apiFetch(
          '/event_feedback/',
          { property: propertyId, ...payload },
          { method: 'POST', cancelationPrefix: entityName },
        )
      },
    },
  ],
})

export default eventFeedbackBundle
