import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import DownloadIcon from '@mui/icons-material/Download'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'

import { DateTime } from 'luxon'

import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import List from '@portal/UI/components/List'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 */
export default function EmailLogCard({ open, onClose }) {
  const [downloadConfirmationOpen, setDownloadConfirmationOpen] = useState(false)
  const [currentEmail, setCurrentEmail] = useState(false)
  const {
    isSuperuser,
    isAtLeastAdmin,
    propertyEmailList,
    propertyEmailListIsLoading,
    doPropertyEmailListDownloadEmail,
    doPropertyEmailListSetOrdering,
    doPropertyEmailListSetPage,
    doPropertyEmailListSetPageSize,
  } = useConnect(
    'selectIsSuperuser',
    'selectIsAtLeastAdmin',
    'selectPropertyEmailList',
    'selectPropertyEmailListIsLoading',
    'doPropertyEmailListDownloadEmail',
    'doPropertyEmailListSetOrdering',
    'doPropertyEmailListSetPage',
    'doPropertyEmailListSetPageSize',
  )
  const columns = [
    {
      field: 'emailTs',
      sortField: 'email_ts',
      headerName: 'Email Timestamp',
      renderCell: ({ row }) =>
        DateTime.fromISO(row.emailTs).toLocaleString(DateTime.DATETIME_MED),
      flex: 1,
    },
    {
      field: 'validReservationCount',
      headerName: 'Reservations',
      sortable: false,
      type: 'number',
      flex: 1,
    },
    {
      field: 'resultCode',
      headerName: 'Result Code',
      sortable: false,
      flex: 1,
    },
    {
      field: 'decoderUsed',
      headerName: 'Decoder',
      sortable: false,
      flex: 1,
    },
    ...(isSuperuser
      ? [
          {
            field: '-',
            sortable: false,
            headerName: 'Download',
            headerAlign: 'right',
            flex: 1,
            renderCell: (params) => (
              <Box flex={1} display="flex" gap={2} justifyContent="center">
                <Tooltip placement="top" title="View Event">
                  <span
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      size="small"
                      startIcon={<DownloadIcon />}
                      variant="outlined"
                      onClick={() => {
                        setCurrentEmail(params.row)
                        setDownloadConfirmationOpen(true)
                      }}
                      disabled={!isAtLeastAdmin}
                    >
                      Download
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            ),
          },
        ]
      : [{}]),
  ]

  useEffect(() => {
    doPropertyEmailListSetPage(1)
    doPropertyEmailListSetPageSize(25)
  }, [onClose])

  return (
    <>
      <ConfirmationDialog
        fullWidth={false}
        title="Download Email Attachments from S3"
        message="Are you sure you want to download this email and attachments from S3?"
        open={downloadConfirmationOpen}
        onConfirm={() => {
          doPropertyEmailListDownloadEmail(currentEmail)
          setDownloadConfirmationOpen(false)
        }}
        onCancel={() => setDownloadConfirmationOpen(false)}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        sx={{ maxHeight: '1200px', marginY: 'auto' }}
      >
        <DialogTitle sx={{ fontSize: '1.7rem', textAlign: 'center' }}>
          Email Log
        </DialogTitle>
        <DialogContent>
          <List
            title="Property Email Log"
            loading={propertyEmailListIsLoading}
            columns={columns}
            rows={propertyEmailList?.results || []}
            page={propertyEmailList.current || 1}
            pageChange={doPropertyEmailListSetPage}
            pageSize={propertyEmailList.pageSize}
            sortChange={doPropertyEmailListSetOrdering}
            pageSizeChange={doPropertyEmailListSetPageSize}
            rowCount={propertyEmailList.count || 0}
            showAddButton={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
