import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Box, Button, Paper, Typography } from '@mui/material'

import { titleize } from 'inflection'

import ThresholdEditor from '../editor/ThresholdEditor'
import { getOpenHours } from '../editor/utils'
import ThresholdWidget from './ThresholdWidget'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.indoorThresholdSettings
 * @param {Function} props.setIndoorThresholdSettings
 * @param {Object[]} props.outdoorThresholdSettings
 * @param {Function} props.setOutdoorThresholdSettings
 * @param {boolean} [props.showOutdoor]
 * @param {Function} [props.handleAdd]
 * @param {Function} [props.handleDelete]
 * @param {Function} props.handleEdit
 * @param {Function} props.cancelEdit
 * @param {Function} props.confirmEdit
 * @param {boolean} props.isEditing
 * @param {number|string} [props.editingId]
 * @param {Object} [props.settingData]
 * @param {string} [props.settingData.deviceStyle]
 * @param {Function} props.setFieldValue
 * @param {'indoor'|'outdoor'} props.singleMode
 * @param {number} [props.editorWidth]
 */
export default function ThresholdModalContent({
  indoorThresholdSettings,
  setIndoorThresholdSettings,
  outdoorThresholdSettings,
  setOutdoorThresholdSettings,
  showOutdoor = false,
  handleAdd = undefined,
  handleDelete = undefined,
  handleEdit,
  cancelEdit,
  confirmEdit,
  isEditing,
  editingId = null,
  settingData = {},
  setFieldValue,
  singleMode = undefined,
  editorWidth = undefined,
}) {
  const updateSetting = ({ id, startTime, endTime, deviceStyle }) => {
    const determineSetting = (prevSettings) =>
      prevSettings.map((setting) => {
        if (setting.id === id)
          return {
            ...setting,
            startTime,
            endTime,
          }
        return setting
      })

    if (deviceStyle === 'OUTDOOR') {
      return setOutdoorThresholdSettings((prevSettings) => {
        const newSetting = determineSetting(prevSettings)
        setFieldValue('setting', newSetting)
        return newSetting
      })
    }
    return setIndoorThresholdSettings((prevSettings) => {
      const newSetting = determineSetting(prevSettings)
      setFieldValue('setting', newSetting)
      return newSetting
    })
  }

  const openHoursIndoor = getOpenHours(indoorThresholdSettings)
  const openHoursOutdoor = getOpenHours(outdoorThresholdSettings)

  const settingTitleStyle = { variant: editorWidth < 300 ? 'h6' : 'h5' }

  if (singleMode && ['indoor', 'outdoor'].includes(singleMode)) {
    return (
      <Box sx={{ display: 'flex', my: 2 }}>
        {settingData?.deviceStyle ? (
          <ThresholdWidget
            editorWidth={editorWidth}
            settings={
              settingData?.deviceStyle === 'INDOOR'
                ? indoorThresholdSettings
                : outdoorThresholdSettings
            }
            currentSetting={settingData}
            editingId={editingId}
            cancelEdit={cancelEdit}
            confirmEdit={confirmEdit}
            confirmDelete={handleDelete}
            setFieldValue={setFieldValue}
          />
        ) : (
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1.5,
              }}
            >
              <Typography {...settingTitleStyle}>
                {titleize(singleMode)} Threshold
              </Typography>
              {handleAdd && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    if (singleMode === 'indoor') {
                      return handleAdd(openHoursIndoor[0], 'INDOOR')
                    }
                    return handleAdd(openHoursOutdoor[0], 'OUTDOOR')
                  }}
                  disabled={
                    singleMode === 'indoor'
                      ? openHoursIndoor.length === 0
                      : openHoursOutdoor.length === 0
                  }
                  sx={{ height: '26px', minWidth: '26px', padding: 0.5 }}
                >
                  <AddOutlinedIcon fontSize="small" />
                </Button>
              )}
            </Box>
            <ThresholdEditor
              editorWidth={editorWidth}
              thresholdSettings={
                singleMode === 'indoor'
                  ? indoorThresholdSettings
                  : outdoorThresholdSettings
              }
              deviceStyle={singleMode.toUpperCase()}
              onAddSetting={handleAdd}
              onEditSetting={handleEdit}
              isEditing={isEditing}
              editingId={editingId}
              onDeleteSetting={handleDelete}
              onResizeSetting={updateSetting}
              onDragSetting={updateSetting}
              setFieldValue={setFieldValue}
            />
          </Paper>
        )}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', my: 2 }}>
      {settingData?.deviceStyle === 'OUTDOOR' ? (
        <ThresholdWidget
          editorWidth={editorWidth}
          settings={outdoorThresholdSettings}
          currentSetting={settingData}
          editingId={editingId}
          cancelEdit={cancelEdit}
          confirmEdit={confirmEdit}
          confirmDelete={handleDelete}
          setFieldValue={setFieldValue}
        />
      ) : (
        <Paper variant="outlined" sx={{ p: 2, mr: 5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <Typography {...settingTitleStyle}>Indoor Threshold</Typography>
            {handleAdd && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleAdd(openHoursIndoor[0], 'INDOOR')}
                disabled={openHoursIndoor.length === 0}
                sx={{ height: '26px', minWidth: '26px', padding: 0.5 }}
              >
                <AddOutlinedIcon fontSize="small" />
              </Button>
            )}
          </Box>
          <ThresholdEditor
            editorWidth={editorWidth}
            thresholdSettings={indoorThresholdSettings}
            deviceStyle="INDOOR"
            onAddSetting={handleAdd}
            onEditSetting={handleEdit}
            isEditing={isEditing}
            editingId={editingId}
            onDeleteSetting={handleDelete}
            onResizeSetting={updateSetting}
            onDragSetting={updateSetting}
            setFieldValue={setFieldValue}
          />
        </Paper>
      )}
      {settingData?.deviceStyle === 'INDOOR' ? (
        <ThresholdWidget
          editorWidth={editorWidth}
          settings={indoorThresholdSettings}
          currentSetting={settingData}
          editingId={editingId}
          cancelEdit={cancelEdit}
          confirmEdit={confirmEdit}
          confirmDelete={handleDelete}
          setFieldValue={setFieldValue}
        />
      ) : (
        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          {!showOutdoor && (
            <div
              style={{
                backgroundColor: '#ccc',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1000,
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              visibility: showOutdoor ? 'visible' : 'hidden',
              mb: 1.5,
            }}
          >
            <Typography {...settingTitleStyle}>Outdoor Threshold</Typography>
            {handleAdd && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleAdd(openHoursOutdoor[0], 'OUTDOOR')}
                disabled={openHoursOutdoor.length === 0}
                sx={{ height: '26px', minWidth: '26px', padding: 0.5 }}
              >
                <AddOutlinedIcon fontSize="small" />
              </Button>
            )}
          </Box>
          <ThresholdEditor
            editorWidth={editorWidth}
            thresholdSettings={outdoorThresholdSettings}
            deviceStyle="OUTDOOR"
            onAddSetting={handleAdd}
            onEditSetting={handleEdit}
            isEditing={isEditing}
            editingId={editingId}
            onDeleteSetting={handleDelete}
            onResizeSetting={updateSetting}
            onDragSetting={updateSetting}
            setFieldValue={setFieldValue}
          />
        </Paper>
      )}
    </Box>
  )
}
