import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  AutoFixHigh,
  Circle,
  CopyAll,
  Delete,
  Edit,
  SmokingRooms,
  VolumeUp,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { titleize } from 'inflection'

import { Breadcrumbs, Loading } from '@common/components'
import { parseApiErrors } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import propertyUrls from '@portal/pages/Properties/urls'
import DeleteModal from '@portal/UI/components/DeleteModal'

import AssignThreshold from './AssignThreshold'
import ChangeProfile from './ChangeProfile'
import EditThreshold from './EditThreshold'
import HierarchyTree from './HierarchyTree'
import thresholdManagementUrls from './urls'

export default function ThresholdDetails() {
  const {
    isAtLeastAdmin,
    routeParams: { id: profileId },
    doThresholdProfileHierarchyFetch,
    doThresholdProfileFetch,
    doThresholdProfileDelete,
    doShowSnackbar,
    doUpdateUrl,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectRouteParams',
    'doThresholdProfileHierarchyFetch',
    'doThresholdProfileFetch',
    'doThresholdProfileDelete',
    'doShowSnackbar',
    'doUpdateUrl',
  )

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const [profileToEdit, setProfileToEdit] = useState(null)
  const [profileToAssign, setProfileToAssign] = useState(null)
  const [hierarchyToChange, setHierarchyToChange] = useState(null)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)

  const [selectedHierarchyData, setSelectedHierarchyData] = useState({
    properties: [],
    unitGroups: [],
  })

  const haveSelectedData = useMemo(
    () =>
      selectedHierarchyData.properties.length > 0 ||
      selectedHierarchyData.unitGroups.length > 0,
    [selectedHierarchyData],
  )

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const result = await doThresholdProfileFetch({ id: profileId })
      const hierarchy = await doThresholdProfileHierarchyFetch({ id: profileId })

      const finalData = {
        profile: {
          ...result,
          rules: result.rules.filter((rule) => rule.deviceStyle === 'INDOOR'), // remove outdoor for now
        },
        hierarchy,
      }
      setData(finalData)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const handleEditClick = () => setProfileToEdit(data.profile)

  const handleDeleteClick = () => setDeleteFormOpen(true)

  const handleAssignClick = () => setProfileToAssign(data.profile)

  const handleChangeProfileClick = () => {
    setHierarchyToChange({ unitGroups: selectedHierarchyData.unitGroups })
  }

  const deleteProfile = async () => {
    try {
      const result = await doThresholdProfileDelete({ id: data.profile.id })
      setDeleteFormOpen(false)

      if (result.error) {
        throw result.error
      }

      doShowSnackbar(`${data.profile.name} profile has been deleted`)
      doUpdateUrl(thresholdManagementUrls.list)
    } catch (err) {
      const parsedError = err?.response?.detail ?? parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }

  function groupSelectedByIds(inputData, ids) {
    const result = {
      properties: [],
      unitGroups: [],
    }
    inputData.propertyGroups.forEach((propertyGroup) => {
      propertyGroup.properties.forEach((property) => {
        if (ids.includes(property.id)) {
          result.properties.push(property)
        }
        property.unitGroups.forEach((unitGroup) => {
          if (ids.includes(unitGroup.id)) {
            result.unitGroups.push(unitGroup)
          }
        })
      })
    })
    return result
  }

  return (
    <>
      <EditThreshold
        open={!!profileToEdit}
        profileToEdit={profileToEdit}
        onClose={(success) => {
          setProfileToEdit(null)
          if (success === true) {
            fetchData()
          }
        }}
      />
      <DeleteModal
        open={deleteFormOpen}
        onConfirmDelete={deleteProfile}
        onCancelDelete={() => setDeleteFormOpen(false)}
      />
      <AssignThreshold
        open={!!profileToAssign}
        profile={profileToAssign}
        onClose={(success) => {
          setProfileToAssign(null)
          if (success === true) {
            fetchData()
          }
        }}
      />
      <ChangeProfile
        open={!!hierarchyToChange}
        currentProfile={data?.profile}
        hierarchy={hierarchyToChange}
        onClose={(success) => {
          setHierarchyToChange(null)
          if (success === true) {
            fetchData()
          }
        }}
      />
      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[
            { label: 'Home', href: homeUrls.home },
            { label: 'Threshold Management', href: thresholdManagementUrls.list },
            { label: loading ? 'Loading...' : data?.profile.name ?? 'Error...' },
          ]}
        />
        {loading && <Loading />}
        {!loading && data && (
          <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <Typography variant="h3">{data.profile.name}</Typography>
                <Chip
                  icon={<Circle />}
                  variant="outlined"
                  size="small"
                  label={data.profile.assigned ? 'Assigned' : 'Not Assigned'}
                  color={data.profile.assigned ? 'success' : 'error'}
                />
                <Chip
                  icon={
                    data.profile.dataType === 'NOISE' ? <VolumeUp /> : <SmokingRooms />
                  }
                  variant="outlined"
                  size="small"
                  label={titleize(data.profile.dataType)}
                  color="primary"
                />
              </Stack>
              {isAtLeastAdmin && (
                <Box>
                  <Button
                    startIcon={<AutoFixHigh />}
                    variant="outlined"
                    sx={{ mr: 2 }}
                    onClick={handleAssignClick}
                  >
                    Assign To
                  </Button>
                  <Button
                    startIcon={<Edit />}
                    variant="outlined"
                    sx={{ mr: 2 }}
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<Delete />}
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" color="text.secondary">
                id: {data.profile.id}
              </Typography>
              <CopyAll
                sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
                onClick={() => {
                  navigator.clipboard.writeText(data.profile.id)
                  doShowSnackbar('ID copied to clipboard')
                }}
              />
            </Box>
            <Typography variant="h6" mb={2}>
              {data.profile.description}
            </Typography>
            <Typography variant="body2" my={2}>
              <Box fontWeight="600" display="inline">
                Property:
              </Box>{' '}
              <Link href={propertyUrls.entity.replace(':id', data.profile.property)}>
                {data.profile.propertyName}
              </Link>
            </Typography>
            <Typography variant="body2" my={1} sx={{ fontWeight: 'bold' }}>
              Rules
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ alignSelf: 'center', maxWidth: '100%' }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Start Time</TableCell>
                    <TableCell align="right">End Time</TableCell>
                    <TableCell align="right">Threshold</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.profile.rules
                    .sort((a, b) => {
                      const startHourA = parseInt(a.startTime.split(':')[0], 10)
                      const startHourB = parseInt(b.startTime.split(':')[0], 10)
                      return startHourA - startHourB
                    })
                    .map((rule) => (
                      <TableRow
                        key={`${rule.name}_${rule.deviceStyle}_${rule.startTime}_${rule.endTime}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {rule.name}
                        </TableCell>
                        <TableCell>{rule.deviceStyle}</TableCell>
                        <TableCell align="right">{rule.startTime}</TableCell>
                        <TableCell align="right">{rule.endTime}</TableCell>
                        <TableCell align="right">{rule.highValue} NRS</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" alignItems="center" mt={4} mb={1}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Assigned To
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={handleChangeProfileClick}
                sx={{ visibility: haveSelectedData ? 'visible' : 'hidden', ml: 2 }}
              >
                Change Profile
              </Button>
            </Box>
            <HierarchyTree
              hierarchy={data.hierarchy}
              onChange={(ids) => {
                const grouped = groupSelectedByIds(data.hierarchy, ids)
                setSelectedHierarchyData(grouped)
              }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
