import { createListBundle } from '@common/utils'
import propertiesUrls from '@rest/pages/Properties/urls'
import reservationUrls from '@rest/pages/Reservations/urls'

import configureUrls from '../urls'

const entityName = 'customNotificationTemplate'

const customNotificationTemplateListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/custom_notification_templates/', params, {
      cancelationPrefix: entityName,
    }),
  urlTest: (url, pattern) =>
    url === configureUrls.messaging ||
    url === reservationUrls.reservations ||
    pattern === propertiesUrls.entity,
})

export default customNotificationTemplateListBundle
