import { createSelector } from 'redux-bundler'

import { createEntityBundle } from '@common/utils'

const entityName = 'shipbob_orders'

const shipbobOrderBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'fetch_recipient',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload}/recipient/`, null, {
          method: 'GET',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default {
  ...shipbobOrderBundle,
  selectShipbobOrderRecipient: createSelector(
    'selectShipbobOrder',
    (order) => order?.fetch_recipient?.response,
  ),
  selectShipbobOrderRecipientIsLoading: createSelector(
    'selectShipbobOrder',
    (order) => order?.fetch_recipient?.meta?.status === 'loading',
  ),
}
