import { path } from 'ramda'

import { isAbortError } from '@common/utils'

const PROPERTY_DETAILS_DATA_LOADING = 'PROPERTY_DETAILS_DATA_LOADING'
const PROPERTY_DETAILS_DATA_LOADED = 'PROPERTY_DETAILS_DATA_LOADED'
const PROPERTY_DETAILS_DATA_FAILED = 'PROPERTY_DETAILS_DATA_FAILED'

const PROPERTY_DETAILS_RESET_STATE = 'PROPERTY_DETAILS_RESET_STATE'

const defaultState = {}

const entityName = 'propertyDetails'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === PROPERTY_DETAILS_RESET_STATE) {
      return defaultState
    }
    if (action.type === PROPERTY_DETAILS_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectPropertyDetailsIsLoading: ({ propertyDetails }) => {
    const status = path(['data', 'status'], propertyDetails)
    return status === 'loading'
  },
  selectPropertyDetails: ({ propertyDetails }) =>
    path(['data', 'data'], propertyDetails),
  selectPropertyDetailsError: ({ propertyDetails }) =>
    path(['data', 'error'], propertyDetails),
  doResetPropertyDetailsState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: PROPERTY_DETAILS_RESET_STATE }),
  doFetchPropertyDetails:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: PROPERTY_DETAILS_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const apiResults = await Promise.all([
          apiFetch(`/properties/${payload}`, null, { cancelationPrefix: entityName }),
          apiFetch(`/properties/${payload}/card_summary/`, null, {
            cancelationPrefix: entityName,
          }),
        ])
        const propertyResult = apiResults[0]
        const summaryResult = apiResults[1]

        const data = {
          property: propertyResult,
          summary: summaryResult,
        }

        await dispatch({
          type: PROPERTY_DETAILS_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_DETAILS_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
