import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { KeyboardArrowDown } from '@mui/icons-material'
import { FormControl, MenuItem, Select } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.variant]
 * @param {Object[]} props.tabs
 * @param {string} props.tabs[].label
 * @param {React.Element} props.tabs[].component
 * @param {string} [props.tabs[].hash]
 * @param {string} [props.tabs[].hashParams]
 * @param {Object} [props.externalState]
 * @param {number} props.externalState.value
 * @param {Function} props.externalState.setValue
 * @param {string} [props.color]
 * @param {Object} [props.contentSx]
 * @param {Object} [props.tabsSx]
 * @param {Object} [props.sx]
 */
export default function TabComponent({
  tabs,
  externalState = undefined,
  variant = 'standard',
  color = undefined,
  contentSx = undefined,
  tabsSx = undefined,
  sx = undefined,
}) {
  const isSmallScreen = useSmallScreen()

  const [tabValue, setTabValue] = useState(0)
  const { doUpdateHash } = useConnect('doUpdateHash')

  const handleHashUpdate = ({ hash, hashParams }) => {
    if (hash) {
      const hashObj = hashParams ? { [`${hash}`]: hashParams } : hash
      doUpdateHash(hashObj)
    } else {
      doUpdateHash('')
    }
  }

  const handleChange = (_, value) => {
    if (tabs[value]?.hash) handleHashUpdate(tabs[value])
    if (externalState) {
      externalState.setValue(value)
    } else {
      setTabValue(value)
    }
  }

  const value = externalState?.value || tabValue

  return (
    <Box flex sx={sx}>
      {isSmallScreen ? (
        <FormControl fullWidth size="small" sx={{ my: 1 }}>
          <Select
            value={value}
            IconComponent={KeyboardArrowDown}
            onChange={(event) => handleChange(null, event.target.value)}
          >
            {tabs.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label} View
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Tabs
          display="flex"
          value={value}
          onChange={handleChange}
          variant={variant}
          textColor={color}
          indicatorColor={color}
          sx={tabsSx}
        >
          {tabs.map((tab) => (
            <Tab
              label={tab.label}
              key={tab.label}
              sx={{ display: tab.visible === false ? 'none' : 'block' }}
            />
          ))}
        </Tabs>
      )}
      <Box sx={contentSx ?? { p: isSmallScreen ? 0 : 1 }}>{tabs[value].component}</Box>
    </Box>
  )
}
