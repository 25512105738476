import { path } from 'ramda'

import { DateTime } from 'luxon'

import { isAbortError } from '@common/utils'

const ADJUSTMENT_SUMMARY_DATA_LOADING = 'ADJUSTMENT_SUMMARY_DATA_LOADING'
const ADJUSTMENT_SUMMARY_DATA_LOADED = 'ADJUSTMENT_SUMMARY_DATA_LOADED'
const ADJUSTMENT_SUMMARY_DATA_FAILED = 'ADJUSTMENT_SUMMARY_DATA_FAILED'

const ADJUSTMENT_SUMMARY_RESET_STATE = 'ADJUSTMENT_SUMMARY_RESET_STATE'

const defaultState = {}

const roundOneDigit = (value) => Math.round(value * 10) / 10

const entityName = 'adjustmentSummary'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === ADJUSTMENT_SUMMARY_RESET_STATE) {
      return defaultState
    }
    if (action.type === ADJUSTMENT_SUMMARY_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === ADJUSTMENT_SUMMARY_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === ADJUSTMENT_SUMMARY_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectAdjustmentSummaryIsLoading: ({ adjustmentSummary }) => {
    const status = path(['data', 'status'], adjustmentSummary)
    return status === 'loading'
  },
  selectAdjustmentSummary: ({ adjustmentSummary }) =>
    path(['data', 'data'], adjustmentSummary),
  selectAdjustmentSummaryError: ({ adjustmentSummary }) =>
    path(['data', 'error'], adjustmentSummary),
  doResetAdjustmentSummaryState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: ADJUSTMENT_SUMMARY_RESET_STATE }),
  doFetchAdjustmentSummary:
    ({ range, unitId }) =>
    async ({ dispatch, store, apiFetch }) => {
      try {
        dispatch({
          type: ADJUSTMENT_SUMMARY_DATA_LOADING,
          range,
          meta: { status: 'loading' },
        })

        const property = store.selectCurrentProperty()

        let [startDate, endDate] = range

        if (!DateTime.isDateTime(startDate) || !DateTime.isDateTime(endDate)) {
          endDate = DateTime.now().setZone(property.timezone)
          startDate = endDate.minus({ days: 7 })
        }

        const events = await apiFetch(
          '/events/',
          {
            localCreatedOnDateAfter: startDate.toISODate(),
            localCreatedOnDateBefore: endDate.toISODate(),
            property: !unitId ? store.selectRouteParams().id : undefined,
            unit: unitId,
            eventClass: 'SMOKE',
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )

        const eventsTotal = events?.count ?? 0
        const eventsByAdjustment = {}
        let eventWithAdjustments = 0

        events?.results?.forEach((event) => {
          const reason = event.smokeFeedback?.adjustmentReason
          if (reason) {
            eventWithAdjustments += 1
            if (eventsByAdjustment[reason] !== undefined) {
              eventsByAdjustment[reason].push(event)
            } else {
              eventsByAdjustment[reason] = [event]
            }
          }
        })

        const eventWithAdjustmentsPercentage =
          (eventWithAdjustments * 100) / eventsTotal

        const data = {
          eventsTotal,
          eventsByAdjustment,
          eventWithAdjustments,
          eventWithAdjustmentsPercentage: roundOneDigit(eventWithAdjustmentsPercentage),
          adjustmentsCount: Object.entries(eventsByAdjustment).map(([key, value]) => ({
            reason: key,
            count: value.length,
          })),
        }

        await dispatch({
          type: ADJUSTMENT_SUMMARY_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: ADJUSTMENT_SUMMARY_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
