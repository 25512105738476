import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, Button, Typography } from '@mui/material'

import { DateTime } from 'luxon'

import { Breadcrumbs, ErrorComponent } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { useQueryFilter } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import boolOptions from '@portal/Utils/constants'

export default function LegacyOrganizations() {
  const [pageSize, setPageSize] = useState('')

  const {
    doLegacyOrganizationListClearParams,
    doLegacyOrganizationListSetFilter,
    doLegacyOrganizationListSetPage,
    doLegacyOrganizationListSetPageSize,
    doLegacyOrganizationListSetOrdering,
    doLegacyOrganizationListSetSearch,
    doMarkLegacyOrganizationListAsOutdated,
    doUpdateQuery,
    legacyOrganizationList,
    legacyOrganizationListIsLoading,
    legacyOrganizationListApiParams,
    queryObject,
  } = useConnect(
    'doLegacyOrganizationListClearParams',
    'doLegacyOrganizationListSetFilter',
    'doLegacyOrganizationListSetPage',
    'doLegacyOrganizationListSetPageSize',
    'doLegacyOrganizationListSetOrdering',
    'doLegacyOrganizationListSetSearch',
    'doMarkLegacyOrganizationListAsOutdated',
    'doUpdateQuery',
    'selectLegacyOrganizationList',
    'selectLegacyOrganizationListIsLoading',
    'selectLegacyOrganizationListApiParams',
    'selectQueryObject',
  )

  useQueryFilter({
    query: queryObject,
    apiParams: legacyOrganizationListApiParams,
    setFilter: doLegacyOrganizationListSetFilter,
    setPageSize: doLegacyOrganizationListSetPageSize,
  })

  useEffect(() => {
    doMarkLegacyOrganizationListAsOutdated()
  }, [])

  const handleClear = () => {
    doLegacyOrganizationListClearParams()
    doLegacyOrganizationListSetFilter()
  }

  const handlePageChange = async (page) => {
    doLegacyOrganizationListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doLegacyOrganizationListSetPageSize(size)
  }

  const handleMigrationWizardClick = () => {
    // TODO
  }

  if (!legacyOrganizationList.results) {
    return <ErrorComponent title="Legacy Organizations" callback={handleClear} />
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 0.5,
      valueGetter: (_, row) =>
        row.createdOn
          ? DateTime.fromISO(row.createdOn).toLocaleString(DateTime.DATETIME_MED)
          : '--',
      sortable: false,
    },
    {
      field: 'propertyCount',
      headerName: 'Properties',
      flex: 0.2,
      type: 'number',
      sortable: false,
    },
    {
      field: 'activePropertyCount',
      headerName: 'Active Properties',
      flex: 0.2,
      type: 'number',
      sortable: false,
    },
    {
      field: 'sensorsOnline',
      headerName: 'Online Sensors',
      flex: 0.2,
      type: 'number',
      sortable: false,
    },
    {
      field: 'sensorsOffline',
      headerName: 'Offline Sensors',
      flex: 0.2,
      type: 'number',
      sortable: false,
    },
  ]

  const rows = legacyOrganizationList?.results?.map((org) => ({
    id: org.id,
    name: org.name,
    createdOn: org.createdOn,
    propertyCount: org.propertyCount,
    activePropertyCount: org.activePropertyCount,
    sensorsOnline: org.sensorsOnline,
    sensorsOffline: org.sensorsOffline,
  }))

  const migrationWizardButton = (
    <Button onClick={handleMigrationWizardClick} variant="contained">
      Migration Wizard
    </Button>
  )

  return (
    <Box m={3} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Breadcrumbs
        links={[
          { label: 'Home', href: homeUrls.home },
          { label: 'Legacy Organizations' },
        ]}
      />
      <Typography variant="h3" mb={2}>
        Organizations V1
      </Typography>
      <Box display="flex">
        {/* TODO: update filter when BE will be ready */}
        <Filter
          disabled={legacyOrganizationListIsLoading}
          apiParams={legacyOrganizationListApiParams}
          setApiParams={doUpdateQuery}
        >
          <StaticSelect
            label="Active Properties"
            filterName="activeProperties"
            options={boolOptions}
          />
          <StaticSelect label="Active" filterName="active" options={boolOptions} />
        </Filter>
        <Box ml={2} flex={1} overflow="hidden" minHeight="900px">
          <List
            columnsAutosize
            title="Organizations"
            loading={legacyOrganizationListIsLoading}
            customAddButton={migrationWizardButton}
            showActions={false}
            columns={columns}
            page={legacyOrganizationList.current || 1}
            pageChange={handlePageChange}
            pageSize={legacyOrganizationList.pageSize}
            pageSizeChange={handlePageSizeChange}
            rows={rows}
            rowCount={legacyOrganizationList.count || 0}
            setSearch={doLegacyOrganizationListSetSearch}
            sortChange={doLegacyOrganizationListSetOrdering}
          />
        </Box>
      </Box>
    </Box>
  )
}
