import { createListBundle, defaultInitialState, getEntityFromUrl } from '@common/utils'

import propertyUrls from './urls'

const entityName = 'propertyEmail'

const propertyEmailListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/property_emails/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: { ...defaultInitialState, ordering: '-email_ts' },
  urlTest: (url, _, hash) =>
    url === propertyUrls.list || ['integrations'].includes(hash),
})

export default {
  ...propertyEmailListBundle,
  doPropertyEmailListDownloadEmail:
    (payload) =>
    async ({ apiFetch }) => {
      const filename = payload.filename
        .replaceAll('/', ' ')
        .trim()
        .replaceAll(' ', '-')
        .toLowerCase()

      const options = {
        method: 'GET',
        cancelationPrefix: entityName,
        download: true,
        filename: `${filename}.zip`,
      }

      try {
        return await apiFetch(`/property_emails/${payload?.id}/download/`, {}, options)
      } catch (err) {
        return err
      }
    },
}
