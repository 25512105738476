import { SvgIcon } from '@mui/material'

const path = `M 59.64 14.28 h -8.06 c 0.19 1.93 1.6 2.55 3.2 2.55 c 1.64 0 2.96 -0.37 4.05 -0.95 v 3.32 a 8.33 8.33 0 0 1 -4.56 1.1 c -4.01 0 -6.83 -2.5 -6.83 -7.48 c 0 -4.19 2.39 -7.52 6.3 -7.52 c 3.92 0 5.96 3.28 5.96 7.5 c 0 0.4 -0.04 1.26 -0.06 1.48 Z m -5.92 -5.62 c -1.03 0 -2.17 0.73 -2.17 2.58 h 4.25 c 0 -1.85 -1.07 -2.58 -2.08 -2.58 Z M 40.95 20.3 c -1.44 0 -2.32 -0.6 -2.9 -1.04 l -0.02 4.63 l -4.12 0.87 V 5.57 h 3.76 l 0.08 1.02 a 4.7 4.7 0 0 1 3.23 -1.29 c 2.9 0 5.62 2.6 5.62 7.4 c 0 5.23 -2.7 7.6 -5.65 7.6 Z M 40 8.95 c -0.95 0 -1.54 0.34 -1.97 0.81 l 0.02 6.12 c 0.4 0.44 0.98 0.78 1.95 0.78 c 1.52 0 2.54 -1.65 2.54 -3.87 c 0 -2.15 -1.04 -3.84 -2.54 -3.84 Z M 28.24 5.57 h 4.13 v 14.44 h -4.13 V 5.57 Z m 0 -4.7 L 32.37 0 v 3.36 l -4.13 0.88 V 0.88 Z m -4.32 9.35 v 9.79 H 19.8 V 5.57 h 3.7 l 0.12 1.22 c 1 -1.77 3.07 -1.41 3.62 -1.22 v 3.79 c -0.52 -0.17 -2.29 -0.43 -3.32 0.86 Z m -8.55 4.72 c 0 2.43 2.6 1.68 3.12 1.46 v 3.36 c -0.55 0.3 -1.54 0.54 -2.89 0.54 a 4.15 4.15 0 0 1 -4.27 -4.24 l 0.01 -13.17 l 4.02 -0.86 v 3.54 h 3.14 V 9.1 h -3.13 v 5.85 Z m -4.91 0.7 c 0 2.97 -2.31 4.66 -5.73 4.66 a 11.2 11.2 0 0 1 -4.46 -0.93 v -3.93 c 1.38 0.75 3.1 1.31 4.46 1.31 c 0.92 0 1.53 -0.24 1.53 -1 C 6.26 13.77 0 14.51 0 9.95 C 0 7.04 2.28 5.3 5.62 5.3 c 1.36 0 2.72 0.2 4.09 0.75 v 3.88 a 9.23 9.23 0 0 0 -4.1 -1.06 c -0.86 0 -1.44 0.25 -1.44 0.9 c 0 1.85 6.29 0.97 6.29 5.88 Z`

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.width
 * @param {string} props.height
 * @param {string} props.fill
 * @param {string} props.viewBox
 */
export default function Stripe(props) {
  const { width = '150', height = '100', fill = 'none', viewBox = '0 0 60 15' } = props
  return (
    <SvgIcon width={width} height={height} fill={fill} viewBox={viewBox}>
      <path fill="#6772e5" d={path} />
    </SvgIcon>
  )
}
