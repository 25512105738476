import { useEffect, useMemo, useState } from 'react'

import { uniq } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { AddOutlined, KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonGroup,
  Grid2,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { BoldText, Breadcrumbs, SearchBox } from '@common/components'
import { DynamicSelect } from '@common/components/Selects'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import {
  CheckboxControl,
  ConfirmationDialog,
  Filter,
  PageTitle,
} from '@rest/UI/components'

import ConfigureNav, { CONFIGURE_NAV_THRESHOLDS, navOptions } from '../ConfigureNav'
import AssignThreshold from './AssignThreshold'
import DeleteInterrupt from './DeleteInterrupt'
import EditThreshold from './EditThreshold'
import AddThreshold from './NewThresholdWizard/AddThreshold'
import ThresholdAccordionContainer from './ThresholdAccordionContainer'

export default function ThresholdList() {
  const [editOpen, setEditOpen] = useState(false)
  const [editId, setEditId] = useState('')
  const [groupChange, setGroupChange] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [profileToAlter, setProfileToAlter] = useState(null)
  const [profileToAssign, setProfileToAssign] = useState(null)
  const [newOpen, setNewOpen] = useState(false)
  const [menuOverflow, setMenuOverflow] = useState(false)
  const [thresholdsToDisplay, setThresholdsToDisplay] = useState('')
  const [checkedUnitGroups, setCheckedUnitGroups] = useState({})
  const [openGroupsList, setOpenGroupsList] = useState([])

  const {
    thresholdsList,
    doThresholdsListSetSearch,
    doThresholdsListSetFilter,
    thresholdsListParams,
    doMarkThresholdsListAsOutdated,
    doThresholdProfileDelete,
    currentOrganizationDetails: organization,
    currentAccountDetails: account,
    doUpdateUrl,
  } = useConnect(
    'selectThresholdsList',
    'doThresholdsListSetSearch',
    'doThresholdsListSetFilter',
    'selectThresholdsListParams',
    'doMarkThresholdsListAsOutdated',
    'doThresholdProfileDelete',
    'selectCurrentOrganizationDetails',
    'selectCurrentAccountDetails',
    'doUpdateUrl',
  )

  const isSmallScreen = useSmallScreen()

  const thresholdsListFilters = thresholdsListParams.filter

  const handleSetFilter = async ({ unassigned, property }) => {
    if (property !== thresholdsListFilters.property)
      await doThresholdsListSetFilter({ property })
    setThresholdsToDisplay(() => (unassigned === true ? 'unassigned' : ''))
  }

  useEffect(() => {
    if (thresholdsList) {
      setOpenGroupsList(thresholdsList.results.map((profile) => profile.id))
    }
  }, [thresholdsList])

  const changeAllowed = useMemo(
    () =>
      uniq(
        thresholdsList?.results
          .filter((threshold) => Object.keys(checkedUnitGroups).includes(threshold.id))
          .map((threshold) => threshold.property),
      ).length === 1,
    [thresholdsList, checkedUnitGroups],
  )

  return (
    <>
      {deleteOpen && profileToAlter && profileToAlter.assigned ? (
        <DeleteInterrupt
          profile={profileToAlter}
          deleteOpen={deleteOpen}
          setDeleteOpen={setDeleteOpen}
        />
      ) : (
        <ConfirmationDialog
          label="Delete Threshold Profile"
          open={deleteOpen}
          onClose={(success) => {
            setDeleteOpen(false)
            setMenuOverflow(false)
            setProfileToAlter(null)
            if (success === true) {
              doMarkThresholdsListAsOutdated()
            }
          }}
          isFormik={false}
          buttonText="Yes, delete profile"
          successMessage={`${profileToAlter?.name} Deleted`}
          formatError={(err) => parseApiErrors(err?.response)}
          onSave={async () => {
            await doThresholdProfileDelete(profileToAlter.id)
          }}
        >
          <Typography sx={{ my: 2.5 }}>
            Are you sure you want to delete <BoldText>{profileToAlter?.name}</BoldText>?
          </Typography>
        </ConfirmationDialog>
      )}
      <AddThreshold
        open={newOpen}
        onClose={(success) => {
          setNewOpen(false)
          if (success === true) {
            doMarkThresholdsListAsOutdated()
          }
        }}
      />
      {editId && (
        <EditThreshold
          open={editOpen}
          onClose={(success) => {
            setEditOpen(false)
            setMenuOverflow(false)
            setProfileToAlter(null)
            setEditId(null)
            if (success === true) {
              doMarkThresholdsListAsOutdated()
            }
          }}
          editId={editId}
          profileToEdit={profileToAlter}
        />
      )}
      {profileToAssign && (
        <AssignThreshold
          open={!!profileToAssign}
          profile={profileToAssign}
          onClose={(success) => {
            setMenuOverflow(false)
            setProfileToAssign(null)
            if (success === true) {
              doMarkThresholdsListAsOutdated()
            }
          }}
        />
      )}
      <Breadcrumbs
        links={[
          { label: organization.name },
          { label: 'Configure' },
          { label: 'Threshold Profiles' },
        ]}
      />
      {isSmallScreen && (
        <Select
          fullWidth
          variant="outlined"
          size="small"
          value={CONFIGURE_NAV_THRESHOLDS}
          onChange={(e) => {
            doUpdateUrl(navOptions.find((opt) => opt.value === e.target.value).href)
          }}
          MenuProps={{ disableScrollLock: true }}
          IconComponent={KeyboardArrowDown}
          sx={{ mb: 2, mt: 1 }}
        >
          {navOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      <Grid2 container spacing={3.5} sx={{ pt: 1 }}>
        {!isSmallScreen && <ConfigureNav selected={CONFIGURE_NAV_THRESHOLDS} />}
        <Grid2 size={{ xs: 12, md: 9.5 }}>
          <Stack
            direction={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'start' : 'center'}
            justifyContent="space-between"
          >
            <PageTitle>Threshold Profiles</PageTitle>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              width={isSmallScreen ? '100%' : null}
            >
              <SearchBox
                title="Profiles"
                onSetSearch={doThresholdsListSetSearch}
                sx={{
                  width: isSmallScreen ? '100%' : '220px',
                  mr: 2,
                  my: isSmallScreen ? 1 : 0,
                }}
              />
              <Filter
                filterParams={thresholdsListFilters}
                setFilterParams={handleSetFilter}
              >
                <DynamicSelect
                  key="Property"
                  label="Property"
                  filterName="property"
                  filters={{ account: account?.id }}
                  endpoint="properties"
                />
                <CheckboxControl filterName="unassigned" label="View Unused Profiles" />
              </Filter>
            </Stack>
          </Stack>
          <Typography variant="body2">
            Threshold profiles is a quick way to apply the same threshold
          </Typography>
          <Typography variant="body2">
            settings to multiple properties at the same time.
          </Typography>
          <Button
            fullWidth={isSmallScreen}
            variant="contained"
            startIcon={<AddOutlined />}
            onClick={() => setNewOpen(true)}
            sx={{ mt: 4 }}
          >
            New Profile
          </Button>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              my: 4,
            }}
          >
            <Tooltip
              title={
                !changeAllowed && Object.keys(checkedUnitGroups).length > 0
                  ? 'Only unit groups under single property could be reassigned'
                  : ''
              }
            >
              <span>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    visibility:
                      Object.keys(checkedUnitGroups).length > 0 ? 'visible' : 'hidden',
                  }}
                  disabled={!changeAllowed}
                  onClick={() => setGroupChange(true)}
                >
                  Change Profile
                </Button>
              </span>
            </Tooltip>
            <ButtonGroup
              variant="text"
              size="small"
              sx={{
                '.MuiButtonGroup-grouped:not(:last-of-type)': {
                  borderColor: 'grey.400',
                },
              }}
            >
              <Button sx={{ pr: 2 }} onClick={() => setOpenGroupsList([])}>
                Collapse All
              </Button>
              <Button
                sx={{ pl: 2 }}
                onClick={() =>
                  setOpenGroupsList(
                    thresholdsList?.results.map((profile) => profile.id) ?? [],
                  )
                }
              >
                Expand All
              </Button>
            </ButtonGroup>
          </Box>
          <ThresholdAccordionContainer
            type="configure"
            setProfileToAlter={setProfileToAlter}
            setProfileToAssign={setProfileToAssign}
            setEditId={setEditId}
            setEditOpen={setEditOpen}
            setDeleteOpen={setDeleteOpen}
            setMenuOverflow={setMenuOverflow}
            menuOverflow={menuOverflow}
            thresholdsToDisplay={thresholdsToDisplay}
            checkedUnitGroups={checkedUnitGroups}
            setCheckedUnitGroups={setCheckedUnitGroups}
            groupChange={groupChange}
            setGroupChange={setGroupChange}
            openGroupsList={openGroupsList}
            setOpenGroupsList={setOpenGroupsList}
          />
        </Grid2>
      </Grid2>
    </>
  )
}
