import { Group } from '@visx/group'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.fill
 * @param {React.Node} props.children
 * @param {number} props.top
 * @param {number} props.left
 * @param {number} [props.shadowMultiplayer]
 */
export default function GraphContainer({
  children,
  width,
  height,
  left,
  top,
  fill,
  shadowMultiplayer = 1,
}) {
  return (
    <>
      <rect
        x={left}
        y={top}
        width={width}
        height={height}
        fill={fill}
        style={{
          filter: `drop-shadow(0px 3px 1px rgba(0, 0, 0, ${0.2 * shadowMultiplayer}))
                drop-shadow(0px 2px 2px rgba(0, 0, 0, ${0.14 * shadowMultiplayer}))
                drop-shadow(0px 1px 5px rgba(0, 0, 0, ${0.12 * shadowMultiplayer}))`,
        }}
        pointerEvents="none"
      />
      <Group>{children}</Group>
    </>
  )
}
