import { createListBundle } from '@common/utils'

import unitUrls from './urls'

const entityName = 'unitDevice'

const unitDeviceListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params, store }) => {
    const unitId = params?.id || store.selectRouteParams().id

    return apiFetch(
      '/devices/',
      {
        ...params,
        unit: [unitId],
      },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (_, pattern) => pattern === unitUrls.entity,
})

export default unitDeviceListBundle
