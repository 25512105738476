import { forwardRef } from 'react'

import { CloseFullscreenRounded } from '@mui/icons-material'
import { Box, Dialog, Grow, Typography } from '@mui/material'

import MetricChart from '../MetricChart'

const Transition = forwardRef((props, ref) => <Grow ref={ref} {...props} />)

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.cardData]
 * @param {string} props.cardData.title
 * @param {string} props.cardData.metric
 * @param {Object} props.cardData.data
 * @param {string} props.cardData.data.key
 * @param {Object[]} props.cardData.data.data
 * @param {string} props.cardData.data.symbol
 * @param {string} props.cardData.data.color
 * @param {Object} props.cardData.dataTypes
 * @param {Object} props.cardData.dataInterval
 * @param {number} props.cardData.dataInterval.value
 * @param {string} props.cardData.dataInterval.type
 * @param {Object[]} props.cardData.requestedDateRange
 * @param {boolean} props.cardData.showTotal
 */
export default function FullscreenMetricCard({ open, onClose, cardData = undefined }) {
  const {
    title,
    metric,
    data,
    dataTypes,
    dataInterval,
    requestedDateRange,
    showTotal,
  } = cardData ?? {}

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ margin: 4 }}
      maxWidth={false}
      PaperProps={{
        style: {
          backgroundImage: 'unset',
          boxShadow: 'none',
          width: '100%',
          padding: 30,
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <Typography variant="h4">{title}</Typography>
        <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
          <CloseFullscreenRounded sx={{ fontSize: 28 }} />
        </Box>
      </Box>
      {cardData && (
        <MetricChart
          fullscreen
          id={`${metric}_fullscreen`}
          data={data}
          dataTypes={dataTypes}
          dataInterval={dataInterval}
          requestedDateRange={requestedDateRange}
          showTotal={showTotal}
        />
      )}
    </Dialog>
  )
}
