import { useMemo } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, CircularProgress, Divider, Typography } from '@mui/material'

import { MobileDetailRow } from '@common/components'
import { formatDateRangeOrdinal, toCamelCase } from '@common/utils'
import {
  getMetricFormattedName,
  getMetricPosition,
  METRICS_CUSTOM_INTERVAL,
} from '@common/utils/propertyDetailMetricsUtils'

import MetricsDetailItem from './MetricsDetailItem'
import { metricsSummaryEntries } from './propertyEventsBundle'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.intervalData
 * @param {String} props.intervalData.interval
 * @param {Object[]} props.intervalData.range
 * @param {boolean} [props.mobileLayout]
 */
export default function MetricsDetailStack({ intervalData, mobileLayout = false }) {
  const { propertyEventsMetrics, propertyEventsMetricsIsLoading } = useConnect(
    'selectPropertyEventsMetrics',
    'selectPropertyEventsMetricsIsLoading',
  )

  const defaultMetricsState = useMemo(
    () =>
      metricsSummaryEntries.reduce(
        (acc, entry) => ({ ...acc, [toCamelCase(entry.apiValue)]: null }),
        {},
      ),
    [],
  )

  const processedMetrics = useMemo(
    () =>
      Object.entries(propertyEventsMetrics ?? defaultMetricsState)
        .reduce(
          (acc, [key, value]) => [
            ...acc,
            {
              metric: key,
              value,
              position: getMetricPosition(key),
              name: getMetricFormattedName(key),
            },
          ],
          [],
        )
        .sort((a, b) => a.position - b.position),
    [propertyEventsMetrics],
  )

  const formattedDateRange = useMemo(() => {
    const formattedRange = formatDateRangeOrdinal(intervalData.range)
    return intervalData.interval !== METRICS_CUSTOM_INTERVAL
      ? `${intervalData.interval} - ${formattedRange}`
      : formattedRange
  }, [intervalData])

  const loadingBoxStyle = {
    width: 1,
    transitionProperty: 'opacity',
    transitionDuration: '300ms',
    opacity: propertyEventsMetricsIsLoading ? 0.35 : 1,
  }

  return mobileLayout ? (
    <Box display="flex" alignItems="center" justifyContent="center" width={1}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        sx={loadingBoxStyle}
      >
        <Typography variant="caption" color="grey.500" fontWeight={600}>
          {formattedDateRange}
        </Typography>
        <Divider sx={{ my: 0.2 }} />
        {processedMetrics &&
          processedMetrics.map(({ name, value }) => (
            <MobileDetailRow key={name} label={name} value={value || '--'} />
          ))}
      </Box>
      {propertyEventsMetricsIsLoading && (
        <CircularProgress size={32} sx={{ position: 'absolute' }} />
      )}
    </Box>
  ) : (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box sx={{ ...loadingBoxStyle, ml: 0.5 }}>
        <Typography variant="caption" color="grey.500" fontWeight={600}>
          {formattedDateRange}
        </Typography>
        <MetricsDetailItem metrics={processedMetrics} />
      </Box>
      {propertyEventsMetricsIsLoading && (
        <CircularProgress size={32} sx={{ position: 'absolute' }} />
      )}
    </Box>
  )
}
