import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  AirRounded,
  LocalAtm as ChargeIcon,
  SpeakerPhone as DeviceIcon,
  VolumeUp as NoiseIcon,
  WarningRounded as WarningIcon,
} from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { inflect } from 'inflection'

import { Breadcrumbs, Loading, SearchBox } from '@common/components'
import { SmokeIcon } from '@common/icons'
import { formatCurrency, parseApiErrors, useSmallScreen } from '@common/utils'
import propertyUrls from '@rest/pages/Properties/urls'
import { aqiToDetailData } from '@rest/UI/components'
import NavigationTabs from '@rest/UI/Navigation/NavigationTabs'
import { PrivilegeCheck } from '@rest/Utils'

import PropertyCard from './PropertyCard'

const tableViewThreshold = 10

export default function PropertyList() {
  const {
    currentAccountDetails: account,
    currentOrganizationDetails: organization,
    doPropertyFetchAll,
    doPropertyFetchCardSummaries,
    doSetCurrentProperty,
    doSetHeaderLevel,
    doShowSnackbar,
    flags,
  } = useConnect(
    'selectCurrentAccountDetails',
    'selectCurrentOrganizationDetails',
    'doPropertyFetchAll',
    'doPropertyFetchCardSummaries',
    'doUpdateUrl',
    'doSetCurrentProperty',
    'doSetHeaderLevel',
    'doShowSnackbar',
    'selectFlags',
  )

  const isSmallScreen = useSmallScreen()

  const [loading, setLoading] = useState(true)
  const [properties, setProperties] = useState(null)
  const [summaries, setSummaries] = useState(null)

  const [propertyGroupFilter, setPropertyGroupFilter] = useState('ALL')
  const [initialLoading, setInitialLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')

  const fetchData = useCallback(async ({ group }) => {
    setLoading(true)
    try {
      const propertiesResponse = await doPropertyFetchAll({ group })
      const summariesResponse = await doPropertyFetchCardSummaries()

      if (propertiesResponse && summariesResponse) {
        setProperties(propertiesResponse?.results)
        setSummaries(summariesResponse)

        setInitialLoading(false)
        setLoading(false)
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')

      setInitialLoading(false)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData({
      group: propertyGroupFilter === 'ALL' ? null : propertyGroupFilter,
    })
    setSearchTerm('')
  }, [propertyGroupFilter])

  useEffect(() => {
    doSetCurrentProperty(null)
    doSetHeaderLevel('account')
  }, [])

  const unfilteredPropertiesCount = useMemo(
    () => (properties?.filter((p) => p.account === account?.id) ?? []).length,
    [properties, account],
  )

  const results = useMemo(() => {
    const allProperties = properties?.filter((p) => p.account === account?.id) ?? []
    if (searchTerm) {
      const search = searchTerm.toLowerCase()
      return allProperties.filter((property) => {
        const name = property.name.toLowerCase()
        const city = property.city?.toLowerCase() ?? ''
        const state = property.state?.toLowerCase() ?? ''

        return name.includes(search) || city.includes(search) || state.includes(search)
      })
    }
    return allProperties
  }, [properties, searchTerm, account])

  const propertiesWithSummaries = useMemo(() => {
    if (results && summaries) {
      return results
        .filter((p) => p.account === account.id)
        .map((property) => {
          const summary = summaries.find((s) => s.propertyId === property.id)
          return { ...property, summary }
        })
    }
    return []
  }, [results, summaries])

  const TwoRowsItem = useCallback(
    ({ primary, secondary, linkTo }) => (
      <Box>
        {linkTo ? (
          <Link href={linkTo}>
            <Typography variant="body2">{primary}</Typography>
          </Link>
        ) : (
          <Typography variant="body2">{primary}</Typography>
        )}
        <Typography variant="caption" color="grey.600">
          {secondary}
        </Typography>
      </Box>
    ),
    [],
  )

  const numericFormatter = useCallback(
    (value) => (value === undefined || value === null ? '--' : value),
    [],
  )

  const showAqiColumn = useMemo(
    () =>
      Boolean(
        propertiesWithSummaries.find((property) =>
          property.expandedFlags.includes('AQI'),
        ),
      ),
    [propertiesWithSummaries],
  )

  if (initialLoading || !account || !organization) {
    return <Loading />
  }

  const columns = [
    {
      display: 'flex',
      field: 'name',
      headerName: 'Name',
      sortable: true,
      flex: 1,
      renderCell: ({ row }) => (
        <TwoRowsItem
          primary={row.name}
          secondary={row.city && row.state ? `${row.city}, ${row.state}` : null}
          linkTo={propertyUrls.entity.replace(':id', row.id)}
        />
      ),
    },
    {
      display: 'flex',
      field: 'connectivity',
      headerName: 'Connectivity',
      sortable: true,
      flex: 1,
      valueGetter: (_, row) => row.summary?.connectivityPct,
      renderCell: ({ row }) => {
        const { connectivityPct, totalOnline, totalOffline, totalDevices } =
          row.summary ?? {}

        if (totalDevices === 1) {
          return (
            <Box display="flex" flexDirection="row" alignItems="center">
              <DeviceIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography variant="body2">
                {totalOnline === 1 ? 'Online' : 'Offline'}
              </Typography>
            </Box>
          )
        }

        const primary = `${numericFormatter(connectivityPct)}% connectivity`
        const secondary = `${numericFormatter(totalOnline)} ${inflect(
          'sensor',
          totalOnline,
        )} online, ${numericFormatter(totalOffline)} offline`

        return (
          <Box display="flex" flexDirection="row" alignItems="center">
            <DeviceIcon fontSize="small" color="action" sx={{ mr: 1 }} />
            <TwoRowsItem primary={primary} secondary={secondary} />
            {connectivityPct < 95 && totalDevices > 0 && (
              <Tooltip
                ml={1.5}
                title="Connectivity below 95%, click to view offline sensors"
              >
                <Link
                  href={`${propertyUrls.entity.replace(
                    ':id',
                    row.id,
                  )}?status=OFFLINE#sensors`}
                >
                  <WarningIcon color="warning" />
                </Link>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
    {
      display: 'flex',
      field: 'charges',
      headerName: 'Charges',
      sortable: true,
      flex: 1,
      valueGetter: (_, row) => row.summary?.eventsCharged,
      renderCell: ({ row }) => {
        const { chargePct, eventsCharged, eventsTotal } = row.summary ?? {}

        const primary = `${numericFormatter(eventsCharged)} ${inflect(
          'charge',
          eventsCharged,
        )} last 7 days (${numericFormatter(chargePct)}%)`
        const secondary = `${numericFormatter(eventsTotal)} chargeable ${inflect(
          'events',
          eventsTotal,
        )}`

        return (
          <Box display="flex" alignItems="center">
            <SmokeIcon
              fontSize="small"
              color="action"
              sx={{ transform: 'rotate(-90deg)', mr: 1 }}
            />
            <TwoRowsItem primary={primary} secondary={secondary} />
          </Box>
        )
      },
    },
    {
      display: 'flex',
      field: 'events/net_charges',
      headerName: 'Events/Net Charges',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        const {
          noiseEventsTotal,
          noiseEventsFeedback,
          noiseResponsePct,
          chargesNet,
          chargeOpportunityPct,
          chargeOpportunity,
        } = row.summary ?? {}

        return (
          <PrivilegeCheck
            flags={['NOISE']}
            property={row}
            alternate={
              <Box display="flex" alignItems="center">
                <ChargeIcon fontSize="small" color="success" sx={{ mr: 1 }} />
                <TwoRowsItem
                  primary={`${formatCurrency(
                    chargesNet,
                    '--',
                  )} net charges last 7 days (${numericFormatter(
                    chargeOpportunityPct,
                  )}%)`}
                  secondary={`${formatCurrency(
                    chargeOpportunity,
                    '--',
                  )} total opportunity`}
                />
              </Box>
            }
          >
            <Box display="flex" alignItems="center">
              <NoiseIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              <TwoRowsItem
                primary={`${numericFormatter(noiseEventsTotal)} noise ${inflect(
                  'event',
                  noiseEventsTotal,
                )} last 7 days`}
                secondary={`${numericFormatter(noiseEventsFeedback)} ${inflect(
                  'response',
                  noiseEventsFeedback,
                )} (${numericFormatter(noiseResponsePct)}%)`}
              />
            </Box>
          </PrivilegeCheck>
        )
      },
    },
    {
      display: 'flex',
      field: 'aqi',
      headerName: 'AQI',
      sortable: true,
      type: 'number',
      flex: 0.2,
      valueGetter: (_, row) => row.summary?.aqi,
      renderCell: ({ row }) => {
        const index = row.summary?.aqi
        const { color, description } = aqiToDetailData(index)
        return (
          <PrivilegeCheck
            flags={['AQI']}
            property={row}
            alternate={
              <Typography variant="body2" color="grey.600">
                N/A
              </Typography>
            }
          >
            <Tooltip title={description}>
              <Box display="flex" alignItems="center">
                <AirRounded sx={{ color, fontSize: 18 }} />
                <Typography variant="body2" ml={1}>
                  {numericFormatter(index)}
                </Typography>
              </Box>
            </Tooltip>
          </PrivilegeCheck>
        )
      },
    },
  ]

  return (
    <>
      <Breadcrumbs links={[{ label: organization.name }, { label: account.name }]} />
      <NavigationTabs />

      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        alignItems="center"
        my={1}
        spacing={2}
      >
        {account?.propertyGroups?.length > 5 && (
          <FormControl
            fullWidth={isSmallScreen}
            data-testid="group_selector_form"
            sx={{ minWidth: 200 }}
          >
            <InputLabel id="demo-simple-select-label">Property Group</InputLabel>
            <Select
              autoWidth
              size="small"
              value={propertyGroupFilter}
              label="Property Group"
              onChange={(e) => setPropertyGroupFilter(e.target.value)}
              MenuProps={{ disableScrollLock: true }}
            >
              <MenuItem value="ALL">All Properties</MenuItem>
              {account.propertyGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {unfilteredPropertiesCount > tableViewThreshold && (
          <SearchBox
            title="properties, cities, states"
            minLength={0}
            onSetSearch={setSearchTerm}
          />
        )}
      </Stack>
      {unfilteredPropertiesCount < tableViewThreshold || isSmallScreen ? (
        <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {loading ? <CircularProgress sx={{ position: 'absolute' }} /> : null}
          <Box
            sx={{
              width: '100%',
              transitionProperty: 'opacity',
              transitionDuration: '300ms',
              opacity: loading ? 0.35 : 1,
              pointerEvents: loading ? 'none' : 'initial',
            }}
          >
            {unfilteredPropertiesCount === 0 ? (
              <div>
                <p>
                  There are no properties in {account.name}
                  {propertyGroupFilter !== 'ALL' &&
                    ` (${
                      account.propertyGroups.find(
                        (group) => group.id === propertyGroupFilter,
                      )?.name
                    } group)`}
                  .
                </p>
                <p>Please get in touch with Rest support if this is an error.</p>
              </div>
            ) : (
              <Grid2 container spacing={4} sx={{ pb: 5, pt: 2 }} alignItems="center">
                {propertiesWithSummaries.map((property) =>
                  property.account === account.id ? (
                    <Grid2 key={property.id} size={{ xs: 12, md: 6, lg: 4, xll: 3 }}>
                      <PropertyCard property={property} flags={flags} />
                    </Grid2>
                  ) : null,
                )}
              </Grid2>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          data-testid="properties_table"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& .MuiDataGrid-root, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeaders':
              { border: 'none' },
          }}
        >
          <DataGridPro
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            hideFooter
            loading={loading}
            columns={columns}
            columnVisibilityModel={{ aqi: showAqiColumn }}
            density="comfortable"
            rows={propertiesWithSummaries}
            sx={{
              mb: 1,
              '& .MuiDataGrid-columnSeparator': { display: 'none' },
              '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
              '& .clickableCell': (theme) => ({
                cursor: 'pointer',
                color: 'primary.main',
                ...theme.typography.subtitle2,
              }),
            }}
          />
        </Box>
      )}
    </>
  )
}
