import { useCallback } from 'react'

import { Box, Divider, Stack, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import orgUrls from '@portal/pages/Organizations/urls'
import userUrls from '@portal/pages/Users/urls'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {number} props.value
 */
function DeviceCount({ label, value }) {
  const isSmallScreen = useSmallScreen()

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant="body2"
        color="text.secondary"
        textTransform="uppercase"
        sx={{ fontSize: isSmallScreen ? 10 : 13 }}
      >
        {label}
      </Typography>
      <Typography variant="caption">{value || 0}</Typography>
    </Box>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} [props.property.csmOwnerName]
 * @param {string} [props.property.csmOwnerId]
 * @param {string} props.property.organization
 * @param {string} props.property.organizationName
 * @param {string} props.property.account
 * @param {string} props.property.accountName
 * @param {string} [props.property.groupName]
 * @param {string} [props.property.timezone]
 * @param {number} [props.property.unitCount]
 * @param {number} [props.property.deviceCount]
 * @param {string} [props.property.address1]
 * @param {string} [props.property.address2]
 * @param {string} [props.property.city]
 * @param {string} [props.property.state]
 * @param {string} [props.property.zipCode]
 * @param {number} [props.margin]
 * @param {number} [props.gap]
 */
export default function DetailsComponent({ property, margin = 0, gap = 0 }) {
  const isSmallScreen = useSmallScreen()

  const buildAddressText = useCallback(() => {
    const addressArr = []
    const locationArr = []
    if (property?.address1) addressArr.push(property.address1)
    if (property?.address2) addressArr.push(property.address2)
    if (property?.city) locationArr.push(property.city)
    if (property?.state) locationArr.push(property.state)
    if (property?.zipCode) locationArr.push(property.zipCode)

    if (!addressArr.length && !locationArr.length) {
      return null
    }

    return [addressArr.join(', '), locationArr.join(' ')].join(', ')
  }, [property])

  return (
    <DetailContainer margin={margin} gap={gap}>
      <DetailItem
        label="CSM Owner"
        value={property?.csmOwnerName}
        linkTo={userUrls.entity.replace(':id', property?.csmOwnerId)}
      />
      <DetailItem
        label="Organization"
        value={property?.organizationName}
        linkTo={orgUrls.entity.replace(':id', property?.organization)}
      />
      <DetailItem
        label="Account"
        value={property?.accountName}
        linkTo={accountUrls.entity.replace(':id', property?.account)}
      />
      {property?.groupName && <DetailItem label="Group" value={property?.groupName} />}
      <DetailItem label="Timezone" value={property?.timezone} />
      <DetailItem label="Total Units" value={property?.unitCount} />
      <DetailItem
        label="Total Devices"
        value={property}
        renderValue={(value) => (
          <Box width="fit-content">
            <Typography
              variant="h6"
              whiteSpace="pre-line"
              sx={{ fontSize: isSmallScreen ? 14 : 20 }}
            >
              {value?.deviceCount || 0}
            </Typography>
            {value?.deviceCount > 0 && (
              <>
                <Divider />
                <Stack spacing={3} direction="row">
                  <DeviceCount label="Online" value={value?.onlineSensorCount} />
                  <DeviceCount label="Offline" value={value?.offlineSensorCount} />
                  <DeviceCount label="Installed" value={value?.installedSensorCount} />
                  <DeviceCount label="RMA" value={value?.rmaSensorCount} />
                  <DeviceCount
                    label="RMA In Progress"
                    value={value?.rmaInProgressSensorCount}
                  />
                  <DeviceCount label="Shipped" value={value?.shippedSensorCount} />
                  <DeviceCount label="Other" value={value?.otherSensorCount} />
                </Stack>
              </>
            )}
          </Box>
        )}
      />
      <DetailItem label="Address" value={buildAddressText()} />
    </DetailContainer>
  )
}
