import { useMemo } from 'react'

import { Box } from '@mui/material'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'

import { noop } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.dataTestId]
 * @param {Function} [props.cellClick]
 * @param {boolean} [props.checkbox]
 * @param {Object[]} props.columns
 * @param {string} [props.columns[].field]
 * @param {string} [props.columns[].headerName]
 * @param {number} [props.columns[].width]
 * @param {number} props.page
 * @param {Function} [props.pageChange]
 * @param {number} [props.pageSize]
 * @param {Function} [props.pageSizeChange]
 * @param {Object[]} props.rows
 * @param {number} props.rowCount
 * @param {Function} [props.rowClick]
 * @param {Function} [props.sortChange]
 * @param {string} [props.noValuePlaceholder]
 * @param {Object} [props.sx]
 * @param {string[]} [props.currentOrdering]
 * @param {number} [props.detailPanelTogglePosition]
 */
export default function List({
  'data-testid': dataTestId = undefined,
  cellClick = noop,
  checkbox = false,
  columns,
  page,
  pageChange = noop,
  pageSize = 25,
  pageSizeChange = noop,
  rows,
  rowCount,
  rowClick = noop,
  sortChange = noop,
  noValuePlaceholder = '',
  sx = {},
  currentOrdering = [],
  detailPanelTogglePosition = 0,
  ...dataGridProps
}) {
  const currentSortModel = useMemo(() => {
    if (!currentOrdering?.length) {
      return undefined
    }

    const [sortField, sortDirection] = currentOrdering
    const column = columns.find(
      (col) => col.sortField === sortField || col.field === sortField,
    )
    if (column) {
      return [{ field: column.field, sort: sortDirection }]
    }
    return [{ field: sortField, sort: sortDirection }]
  }, [currentOrdering, columns])

  const handleSortChange = (sort) => {
    if (!sort.length) return sortChange(sort)
    const column = columns.find((col) => col.field === sort[0].field)
    const field = column.sortField ?? column.field
    return sortChange([field, sort[0].sort])
  }

  const modifiedColumns = columns.map((column) => ({
    display: column.renderCell ? 'flex' : 'block',
    valueGetter: (value) =>
      value === null || value === undefined || value === ''
        ? noValuePlaceholder
        : value,
    ...column,
  }))

  // set detail panel toggle position
  modifiedColumns.splice(detailPanelTogglePosition, 0, {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  })

  const onPaginationModelChange = (model) => {
    if (model.page !== page - 1) {
      pageChange(model.page + 1)
    }
    if (model.pageSize !== pageSize) {
      pageSizeChange(model.pageSize)
    }
  }

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        '& .MuiDataGrid-root': {
          '--DataGrid-pinnedBackground': 'transparent !important',
          '--DataGrid-containerBackground': 'transparent !important',
        },
        '& .MuiDataGrid-container--top:after': { height: 0 },
        '& .MuiDataGrid-root, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeaders':
          { border: 'none' },
      }}
    >
      <DataGridPro
        checkboxSelection={checkbox}
        columns={modifiedColumns}
        density="comfortable"
        disableColumnFilter
        disableRowSelectionOnClick
        paginationModel={{
          page: page - 1,
          pageSize,
        }}
        onPaginationModelChange={onPaginationModelChange}
        pagination
        paginationMode="server"
        onCellClick={cellClick}
        sortingOrder={['desc', 'asc']}
        sortingMode="server"
        onSortModelChange={handleSortChange}
        sortModel={currentSortModel}
        rows={rows}
        rowCount={rowCount}
        pageSizeOptions={[10, 25, 50, 100]}
        onRowClick={rowClick}
        sx={{
          '& .MuiDataGrid-columnSeparator': { display: 'none' },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
          '& .clickableCell': (theme) => ({
            cursor: 'pointer',
            color: 'primary.main',
            ...theme.typography.subtitle2,
          }),
          ...sx,
        }}
        {...dataGridProps}
      />
    </Box>
  )
}
