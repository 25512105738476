import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Send } from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { ErrorComponent, MobileList, MobileListDefaultCard } from '@common/components'
import { useSmallScreen } from '@common/utils'
import List from '@portal/UI/components/List'

import CommandForm from './CommandForm'

export default function CommandTab() {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [commandFormOpen, setCommandFormOpen] = useState(false)

  const isSmallScreen = useSmallScreen()

  const {
    routeParams: { id: deviceId },
    deviceCommands,
    deviceCommandsIsLoading,
    doDeviceFetchCommands,
    doDeviceSendCommand,
  } = useConnect(
    'selectRouteParams',
    'selectDeviceCommands',
    'selectDeviceCommandsIsLoading',
    'doDeviceFetchCommands',
    'doDeviceSendCommand',
  )

  useEffect(() => {
    doDeviceFetchCommands(deviceId)
  }, [])

  const processedCommands = useMemo(() => {
    const result = []
    deviceCommands?.forEach((row) => {
      const commands = Object.keys(row.data)
      commands.forEach((command) => {
        result.push({
          id: Math.random(),
          operation: row.operation,
          createdOn: row.createdOn,
          command,
          value: row.data[`${command}`],
        })
      })
    })
    return result
  }, [deviceCommands])

  const visibleRows = useMemo(
    () => processedCommands?.slice((page - 1) * pageSize, page * pageSize),
    [page, processedCommands],
  )

  const handlePageChange = async (newPage) => setPage(newPage)

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
  }

  const SendCommandButton = useCallback(
    ({ fullWidth }) => (
      <Button
        fullWidth={fullWidth}
        startIcon={<Send />}
        variant="outlined"
        component="label"
        onClick={() => setCommandFormOpen(true)}
      >
        Send command
      </Button>
    ),
    [],
  )

  const columns = [
    {
      field: 'operation',
      headerName: 'Operation',
      flex: 0.75,
      valueGetter: (_, row) => (row.operation ? titleize(row.operation) : '--'),
      sortable: false,
    },
    {
      field: 'command',
      headerName: 'Command',
      flex: 0.3,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 0.4,
      align: 'right',
      headerAlign: 'right',
      sortable: false,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      flex: 0.4,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (_, row) =>
        row.createdOn
          ? DateTime.fromISO(row.createdOn).toLocaleString(DateTime.DATETIME_MED)
          : '',
      sortable: false,
    },
  ]

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  if (!visibleRows && !deviceCommandsIsLoading)
    return (
      <ErrorComponent
        title="Device Commands"
        callback={() => doDeviceFetchCommands(deviceId)}
      />
    )

  return (
    <>
      <CommandForm
        devices={[deviceId]}
        open={commandFormOpen}
        onClose={() => {
          doDeviceFetchCommands(deviceId)
          setCommandFormOpen(false)
        }}
        onSave={doDeviceSendCommand}
      />
      {isSmallScreen ? (
        <Box width="100%">
          <SendCommandButton fullWidth />
          <MobileList
            title="Commands"
            loading={deviceCommandsIsLoading}
            showActions={false}
            itemBuilder={MobileListItem}
            page={page}
            pageChange={handlePageChange}
            pageSize={pageSize}
            pageSizeChange={handlePageSizeChange}
            rows={visibleRows}
            rowCount={processedCommands?.length || 0}
          />
        </Box>
      ) : (
        <List
          columnsAutosize
          title="Commands"
          loading={deviceCommandsIsLoading}
          showActions={false}
          customAddButton={<SendCommandButton />}
          columns={columns}
          page={page}
          pageChange={handlePageChange}
          pageSize={pageSize}
          pageSizeChange={handlePageSizeChange}
          rows={visibleRows}
          rowCount={processedCommands?.length || 0}
          sortChange={() => {}}
        />
      )}
    </>
  )
}
