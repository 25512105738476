import { Group, Rect, Text } from 'react-konva'

import { RECT_HORIZONTAL_ORIGIN, RECT_RADIUS, VERTICAL_GRID_SPACE } from './constants'
import { getRectWidth } from './Rects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {number} props.y
 * @param {boolean} props.visible
 * @param {Function} props.onMouseEnter
 * @param {Function} props.onMouseLeave
 * @param {Function} props.onClick
 */
export default function AddThresholdRect({
  editorWidth = undefined,
  y,
  visible,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) {
  const width = getRectWidth(editorWidth)
  return (
    <Group
      opacity={visible ? 1 : 0}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Rect
        cornerRadius={RECT_RADIUS}
        fill="#757575"
        x={RECT_HORIZONTAL_ORIGIN + 0.5}
        width={width}
        height={VERTICAL_GRID_SPACE - 2}
        y={y + 1}
      />
      <Text
        width={width}
        text="＋ NEW THRESHOLD"
        align="center"
        x={RECT_HORIZONTAL_ORIGIN}
        fill="white"
        fontSize={13}
        fontFamily="Inter"
        y={y + 6}
      />
    </Group>
  )
}
