import qs from 'query-string'

import { config, Project } from '@common/config'
import { getTokens } from '@common/config/utils'

import { REST_PROJECT } from '../config/project'

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const abortControllers = {}

const APIError = {
  get message() {
    return this.error.message
  },
  get status() {
    return this.error.status
  },
  get response() {
    return this.error.response
  },
  toString() {
    return this.message
  },
}

const apiErrorFactory = (message, status, response) =>
  Object.assign(Object.create(APIError), {
    error: {
      message,
      status,
      response,
    },
  })

const getApiFetch = (store) => {
  const apiFetch = async (path, body, opts = {}) => {
    const {
      headers: optHeaders,
      method = 'GET',
      useAuth = true,
      useOrgHeader = true,
      useAccountHeader = true,
      mock = false,
      mockData = {},
      mockTimeout = 250,
      download = false,
      filename = 'download.csv',
      cancelationPrefix = '',
      allowCancelation = true,
      project = '',
      ...options
    } = opts

    const authHeaders = {}

    const mockResponse = method === 'DELETE' ? {} : mockData

    const mockHandler = async () =>
      new Promise((resolve) => {
        setTimeout(() => resolve(mockResponse), mockTimeout)
      })

    if (mock) {
      const response = await mockHandler()
      return response
    }

    const { access, organization, account } = await getTokens(project)

    if (useAuth) {
      if (access) {
        authHeaders.Authorization = `Bearer ${access}`
      } else {
        console.warn('Attempted to make authenticated fetch with no access token.')
        await store.doAuthLogout()
        return false
      }
    }

    if (useOrgHeader) {
      const currentOrg = store.selectCurrentOrganization?.() ?? organization
      if (currentOrg) {
        authHeaders['X-Org'] = currentOrg
      }
    }

    if (useAccountHeader) {
      const currentAccount = store.selectCurrentAccount?.() ?? account
      if (currentAccount) {
        authHeaders['X-Account'] = currentAccount
      }
    }

    const headers = new Headers({
      ...DEFAULT_HEADERS,
      ...authHeaders,
      ...optHeaders,
    })

    let apiUrl = Project.isRest ? config.API_URL_REST : config.API_URL_PORTAL

    if (project) {
      apiUrl = project === REST_PROJECT ? config.API_URL_REST : config.API_URL_PORTAL
    }

    let url = `${apiUrl}${path}`
    if (method === 'GET' && body) {
      url += `?${qs.stringify(body)}`
    }

    const fetchOptions = { ...options, method, headers }
    if (body && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
      const isFormData = body instanceof FormData
      if (isFormData) {
        fetchOptions.body = body
        fetchOptions.headers = new Headers({
          ...authHeaders,
          ...optHeaders,
        })
      } else {
        fetchOptions.body = JSON.stringify(
          Array.isArray(body) ? [...body] : { ...body },
        )
      }
    }

    const controllerKey = [cancelationPrefix, path].join('_')
    if (!fetchOptions.signal && allowCancelation) {
      abortControllers[controllerKey]?.abort('AbortError')
      abortControllers[controllerKey] = new AbortController()
    }

    const response = await fetch(url, {
      ...fetchOptions,
      signal: fetchOptions.signal ?? abortControllers[controllerKey]?.signal,
    })

    delete abortControllers[controllerKey]

    if (download) {
      if (!response.ok) return false

      try {
        const blob = await response.blob()
        const objectUrl = globalThis.URL.createObjectURL(blob)
        const a = document.createElement('a')

        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = objectUrl
        a.download = filename
        a.click()
        globalThis.URL.revokeObjectURL(objectUrl)

        return true
      } catch (error) {
        return false
      }
    }

    const json = [204, 500].includes(response.status) ? {} : await response.json()

    if (!response.ok) {
      throw apiErrorFactory('Error: API request failed', response.status, json)
    }

    return json
  }

  return apiFetch
}

export default getApiFetch
