import { Group } from '@visx/group'
import { Line } from '@visx/shape'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.x
 * @param {number} props.top
 * @param {number} props.height
 * @param {string} props.color
 * @param {boolean} [props.showCaret]
 * @param {boolean} [props.dashed]
 */
export default function LineMarker({
  x,
  top,
  height,
  color,
  showCaret = true,
  dashed = false,
}) {
  return (
    <Group left={x} top={showCaret ? top - 4.5 : top} height={height}>
      {showCaret && (
        <g transform="translate(0,-4)">
          <polygon points="0,5 4,0 -4,0" fill={color} stroke={color} />
        </g>
      )}
      <Line
        from={{ y: 0 }}
        to={{ y: showCaret ? height + 4.5 : height }}
        stroke={color}
        strokeWidth={dashed ? 1 : 1.5}
        strokeDasharray={[dashed ? 4 : 0]}
      />
    </Group>
  )
}
