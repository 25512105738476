import { Box, Button, Typography } from '@mui/material'

import { useUpdateCheck } from 'react-update-notification'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.marginLeft]
 */
export default function NewVersionBanner({ marginLeft = undefined }) {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 1000 * 60 * 1, // 1 minute
    ignoreServerCache: true,
  })

  const isOutdated = status === 'available'

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{ marginLeft }}
      sx={{
        backgroundColor: 'success.main',
        px: isOutdated ? 2 : 0,
        py: isOutdated ? 0.5 : 0,
        transitionProperty: 'height',
        transitionDuration: '500ms',
        overflow: 'hidden',
        height: isOutdated ? 40 : 0,
      }}
    >
      <Typography color="white" fontSize={14}>
        New version is available! Refresh page to update.
      </Typography>
      <Button onClick={reloadPage} sx={{ color: 'white', fontSize: 12 }}>
        Refresh
      </Button>
    </Box>
  )
}
