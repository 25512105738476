import { useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect } from '@common/components/Selects'
import { getApiFetch, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {'organization'|'account'|'property'|'user'} [props.entity]
 * @param {Object} [props.instance]
 * @param {string} [props.instance.id]
 * @param {string} [props.instance.user]
 * @param {Object} [props.roleConfig]
 * @param {Object} [props.roleConfig.filters]
 * @param {boolean} [props.roleConfig.disabled]
 */
export default function MembershipForm({
  open,
  onClose,
  entity = undefined,
  instance = {},
  roleConfig = {},
}) {
  const [formikProps, setFormikProps] = useState({
    values: {},
    setValues: () => {},
    setFieldValue: () => {},
  })

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const validationSchema = yup.object().shape({
    userName: yup.string().max(40).required(),
    userEmail: yup.string().email().required(),
    role: yup.string().uuid().required(),
    organization: yup.string().uuid(),
    account: yup.string().uuid(),
    property: yup.string().uuid(),
  })

  const initialValues = {
    userName: '',
    userEmail: '',
    role: null,
    organization: null,
    account: null,
    property: null,
    noiseAlert: null,
    outageAlert: null,
    smokeAlert: null,
    smokeDigest: null,
  }

  if (instance) {
    const boolFields = ['smokeAlert', 'smokeDigest', 'noiseAlert', 'outageAlert']
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] =
        instance[field] ?? (boolFields.includes(field) ? false : '')
    })
  }

  const getSecondaryTextAttr = () => {
    const { organization, account, property } = initialValues
    if (organization) return 'organizationName'
    if (account || property) return 'accountName'
    return ''
  }

  const roleKey = entity === 'organization' ? entity : 'account'
  const defaultRoleFilters = { [entity]: initialValues[entity], key: roleKey }

  const createUser = async (formData) => {
    const usersResponse = await apiFetch(
      '/users/',
      {
        search: formData.userEmail,
      },
      { cancelationPrefix: 'membership_form' },
    )
    if (usersResponse.results.length === 0) {
      return apiFetch(
        '/users/',
        {
          email: formData.userEmail,
          name: formData.userName,
        },
        { method: 'POST', cancelationPrefix: 'membership_form' },
      )
    }
    return usersResponse.results[0]
  }

  const createMembership = (formData, userData) =>
    apiFetch(
      '/memberships/',
      {
        [entity]: formData[entity],
        role: formData.role,
        user: userData.id,
        ...(entity === 'property'
          ? {
              outageAlert: formData.outageAlert,
              noiseAlert: formData.noiseAlert,
              smokeAlert: formData.smokeAlert,
              smokeDigest: formData.smokeDigest,
            }
          : {}),
      },
      { method: 'POST', cancelationPrefix: 'membership_form' },
    )

  const onSubmit = async (formData) => {
    try {
      const userData = instance?.user
        ? { id: instance?.user }
        : await createUser(formData)
      await createMembership(formData, userData)
      doShowSnackbar('Membership has been successfully added', 'success')
      onClose(true)
    } catch (err) {
      const parsedError = err.message ?? parseApiErrors(err)
      doShowSnackbar(parsedError, 'error')
    }
  }

  const fieldVariant = 'standard'

  return (
    <FormDialog
      label={`${instance?.id ? 'Edit' : 'Add'} Membership`}
      open={open}
      onSave={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        flexDirection="column"
      >
        <Field
          required
          name="userName"
          label="Name"
          disabled={!!instance?.id}
          component={TextField}
          variant={fieldVariant}
          sx={{ width: '100%' }}
        />
        <Field
          required
          fullWidth
          name="userEmail"
          label="Email"
          disabled={!!instance?.id}
          component={TextField}
          variant={fieldVariant}
          sx={{ mt: 2 }}
        />
        <Field
          required
          name="role"
          label="Role"
          endpoint="roles"
          disabled={roleConfig?.disabled}
          filters={roleConfig?.filters || defaultRoleFilters}
          component={FormikDynamicSelect}
          secondaryTextAttr={getSecondaryTextAttr()}
          variant={fieldVariant}
          sx={{ mt: 2 }}
        />
        {entity === 'property' && (
          <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
            <Typography variant="caption">Notifications</Typography>
            <Box>
              <FormControlLabel
                label="Outage Alert"
                labelPlacement="end"
                control={
                  <Checkbox
                    size="small"
                    checked={!!formikProps?.values?.outageAlert}
                    onChange={(event) =>
                      formikProps?.setFieldValue('outageAlert', event.target.checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Noise Alert"
                labelPlacement="end"
                control={
                  <Checkbox
                    size="small"
                    checked={!!formikProps?.values?.noiseAlert}
                    onChange={(event) =>
                      formikProps?.setFieldValue('noiseAlert', event.target.checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Smoke Alert"
                labelPlacement="end"
                control={
                  <Checkbox
                    size="small"
                    checked={!!formikProps?.values?.smokeAlert}
                    onChange={(event) =>
                      formikProps?.setFieldValue('smokeAlert', event.target.checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Smoke Digest"
                labelPlacement="end"
                control={
                  <Checkbox
                    size="small"
                    checked={!!formikProps?.values?.smokeDigest}
                    onChange={(event) =>
                      formikProps?.setFieldValue('smokeDigest', event.target.checked)
                    }
                  />
                }
              />
            </Box>
          </Box>
        )}
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
