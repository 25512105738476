/* eslint-disable react/jsx-no-duplicate-props */
import { useEffect, useMemo, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { ExpandMore } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Popper,
  TextField,
  Typography,
} from '@mui/material'

import { debounce, getApiFetch, isAbortError, parseApiErrors } from '@common/utils'

const styles = () => ({
  popper: {
    maxWidth: 'fit-content',
  },
})

function MyPopper(props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.initialInput]
 * @param {string} [props.primaryTextAttr]
 * @param {string} [props.secondaryTextAttr]
 * @param {boolean} [props.alwaysShowPlaceholder]
 * @param {Object} [props.filters]
 * @param {string} [props.filterName]
 * @param {boolean} [props.forceActiveFilter=true]
 * @param {string} [props.defaultSearch]
 * @param {boolean} [props.multiple]
 * @param {string} [props.error]
 * @param {boolean} [props.showError]
 * @param {string|Object} [props.value]
 * @param {string} [props.variant]
 * @param {string} [props.endpoint]
 * @param {Function} [props.onChange]
 * @param {boolean} [props.getFullEntity]
 * @param {boolean} [props.useValueInRequest]
 * @param {Object} [props.inputStyle]
 * @param {Function} [props.formatInputValue]
 * @param {Function} [props.formatTagValue]
 * @param {Function} [props.optionRender]
 * @param {string} [props.helperText]
 * @param {string} [props.cancelationPrefix]
 */
export default function DynamicSelect(props) {
  const {
    label,
    value = undefined,
    initialInput = undefined,
    onChange = undefined,
    endpoint = '',
    filters = {},
    filterName,
    forceActiveFilter = true,
    defaultSearch = undefined,
    error = '',
    showError = false,
    multiple = false,
    primaryTextAttr = 'name',
    secondaryTextAttr = '',
    optionRender = undefined,
    alwaysShowPlaceholder = undefined,
    formatInputValue = undefined,
    formatTagValue = undefined,
    variant = 'outlined',
    getFullEntity = false,
    useValueInRequest = true,
    inputStyle = {},
    helperText = '',
    cancelationPrefix = undefined,
    ...rest
  } = props

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const isString = (val) => typeof val === 'string'
  const callEndpoint = (params, id) => {
    const url = `/${endpoint}/`
    return apiFetch(
      useValueInRequest && id ? url.concat(id) : url,
      { pageSize: 50, ...params },
      {
        method: 'GET',
        cancelationPrefix: cancelationPrefix
          ? `dynamic_select_${cancelationPrefix}`
          : 'dynamic_select',
      },
    )
  }

  const handleChange = (_, selected) => {
    let newValue = null
    if (onChange) {
      if (getFullEntity) {
        newValue = selected
      } else {
        newValue = multiple
          ? selected?.map((item) => (isString(item) ? item : item.id))
          : selected?.id
      }
      onChange(newValue)
    }
  }

  const handleInputChange = async (e, text) => {
    setLoading(true)
    try {
      const newVal = value ? '' : value // prevents filtering by value when value is selected
      const res = await callEndpoint(
        {
          ...filters,
          search: text || defaultSearch,
          ...(forceActiveFilter ? { active: true } : {}),
        },
        newVal,
      )
      if (res?.results) setOptions(res.results)
      if (res?.id) setOptions([res])
    } catch (err) {
      if (!isAbortError(err)) {
        const parsedError = parseApiErrors(err)
        doShowSnackbar(parsedError, 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleDropDown = async () => handleInputChange(null, '')

  const getOptionData = (option) =>
    isString(option) ? options?.find((opt) => opt.id === option) : option

  const getOptionLabel = (option) => {
    const optData = getOptionData(option)
    return optData?.[primaryTextAttr] ?? ''
  }

  const isOptionEqualToValue = (opt, val) => {
    if (!val) return false
    return isString(val) ? val?.includes(opt.id) : val?.id === opt?.id
  }

  const processedValue = useMemo(() => {
    if (!value) return multiple ? [] : null
    return multiple && typeof value === 'string' ? value.split(',') : value
  }, [value, multiple])

  useEffect(() => {
    if (options?.length === 0 && value) {
      handleInputChange(null, initialInput)
    }
  }, [value, initialInput])

  return (
    <Autocomplete
      value={processedValue}
      multiple={multiple}
      options={options}
      onChange={handleChange}
      onOpen={handleDropDown}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={(x) => x}
      onInputChange={debounce((e, text) => handleInputChange(e, text), 500)}
      sx={{ minWidth: '200px' }}
      PopperComponent={MyPopper}
      popupIcon={<ExpandMore />}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          required={rest.required}
          label={label}
          error={showError}
          helperText={showError ? error : helperText}
          placeholder={rest.placeholder}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            ...(formatInputValue
              ? { value: formatInputValue(getOptionData(processedValue)) || '' }
              : {}),
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...(alwaysShowPlaceholder ? { shrink: true } : {}),
          }}
          sx={{ ...params.sx, ...inputStyle }}
        />
      )}
      renderOption={(optProps, option) => (
        <li {...optProps} key={option.id}>
          {optionRender ? (
            optionRender(option)
          ) : (
            <Box>
              <Typography variant="h6">{option[primaryTextAttr]}</Typography>
              {secondaryTextAttr && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textTransform="uppercase"
                  fontSize="0.7rem"
                >
                  {option[secondaryTextAttr]}
                </Typography>
              )}
            </Box>
          )}
        </li>
      )}
      renderTags={(values, getProps) =>
        values.map((option, index) => {
          const optionData = getOptionData(option)
          const externalFormat = formatTagValue ? formatTagValue(optionData) : null
          const primary = optionData?.[primaryTextAttr]
          const secondary = secondaryTextAttr ? optionData?.[secondaryTextAttr] : null

          return (
            <Chip
              {...getProps({ index })}
              label={
                externalFormat ?? (secondary ? `${primary} - ${secondary}` : primary)
              }
            />
          )
        })
      }
    />
  )
}
