import { createEntityBundle } from '@common/utils'

const entityName = 'reservations'

const reservationBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'upload',
      handler: ({ apiFetch, payload }) => {
        const { property, file } = payload

        const formData = new FormData()
        formData.append('property', property)
        formData.append('file', file)

        return apiFetch('/reservations/upload/', formData, {
          method: 'POST',
          cancelationPrefix: entityName,
        })
      },
    },
  ],
})

export default reservationBundle
