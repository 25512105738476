export const PORTAL_PROJECT = 'portal'
export const REST_PROJECT = 'rest'

class Project {
  static get current() {
    return globalThis.location.pathname.startsWith('/portal')
      ? PORTAL_PROJECT
      : REST_PROJECT
  }

  static get isRest() {
    return this.current === REST_PROJECT
  }

  static get isPortal() {
    return this.current === PORTAL_PROJECT
  }

  static switch(url) {
    const { current } = this
    const newUrl = current === PORTAL_PROJECT ? '/' : '/portal'
    globalThis.location.replace(`${globalThis.location.origin}${url ?? newUrl}`)
  }
}

export default Project
