import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { ThresholdModalContent } from '@common/components/thresholdManagement'
import { parseApiErrors } from '@common/utils'

import { formatRule } from '../thresholdApiUtils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.onClose
 * @param {Function} props.setStep
 * @param {Object} props.profileData
 * @param {string} [props.profileData.level]
 * @param {number} [props.profileData.startTime]
 * @param {number} [props.profileData.endTime]
 * @param {number} [props.profileData.nrsIndoorValue]
 * @param {number} [props.profileData.nrsOutdoorValue]
 */
export default function Step3({ onClose, setStep, profileData }) {
  const {
    doNewThresholdProfileSave,
    doThresholdProfileAssign,
    doPropertyListFetchAll,
    doShowSnackbar,
  } = useConnect(
    'doNewThresholdProfileSave',
    'doThresholdProfileAssign',
    'doPropertyListFetchAll',
    'doShowSnackbar',
  )

  const [assignToProperty, setAssignToProperty] = useState(true)
  const [indoorThresholdSettings, setIndoorThresholdSettings] = useState([
    {
      id: Math.random(),
      startTime: profileData?.startTime,
      endTime: profileData?.endTime,
      highValue: profileData?.nrsIndoorValue,
      deviceStyle: 'INDOOR',
      name: 'Quiet Hours',
    },
  ])
  const [outdoorThresholdSettings, setOutdoorThresholdSettings] = useState([
    {
      id: Math.random(),
      startTime: profileData?.startTime,
      endTime: profileData?.endTime,
      highValue: profileData?.nrsOutdoorValue,
      deviceStyle: 'OUTDOOR',
      name: 'Quiet Hours',
    },
  ])
  const [isEditing, setIsEditing] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const [settingData, setSettingData] = useState({})

  const [propertyOptions, setPropertyOptions] = useState([])

  const fetchPropertyOptions = useCallback(async () => {
    try {
      const response = await doPropertyListFetchAll()
      if (response?.error) {
        throw response.error
      }
      setPropertyOptions(
        response?.results?.map((property) => ({
          id: property.id,
          name: property.name,
          effectiveNoiseThresholdProfile: property.effectiveNoiseThresholdProfile,
          effectiveNoiseThresholdProfileName:
            property.effectiveNoiseThresholdProfileName,
        })) ?? [],
      )
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useEffect(() => {
    fetchPropertyOptions()
  }, [])

  const initialValues = {
    profileName: '',
    description: '',
    property: undefined,
  }

  const validationSchema = Yup.object().shape({
    profileName: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s.-]+$/,
        'Only alphanumeric characters, dashes, spaces, and commas are allowed.',
      )
      .required('Please enter a name'),
    description: Yup.string(),
    property: Yup.string().uuid().required(),
  })

  const handleEditSetting = (setting) => {
    setIsEditing(true)
    setEditingId(setting.id)
    setSettingData(setting)
  }

  const cancelEditSetting = () => {
    setIsEditing(false)
    setEditingId(null)
    setSettingData(null)
  }

  const confirmEditSetting = (updatedThreshold) => {
    const newThreshArr =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const updateIndex = newThreshArr.findIndex((obj) => obj.id === updatedThreshold.id)
    if (updateIndex !== -1) {
      newThreshArr[updateIndex] = updatedThreshold
    } else {
      newThreshArr.push(updatedThreshold)
    }

    const setFn =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleAddSetting = (startTime, deviceStyle) => {
    setIsEditing(true)
    setEditingId('add_new')
    setSettingData({
      id: Math.random(),
      startTime,
      endTime: startTime === 23 ? 0 : startTime + 1,
      highValue: profileData?.nrsIndoorValue,
      deviceStyle,
      name: 'Quiet Hours',
    })
  }

  const handleDeleteSetting = (setting) => {
    const newThreshArr =
      setting.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const deleteIndex = newThreshArr.findIndex((obj) => obj.id === setting.id)
    if (deleteIndex !== -1) {
      newThreshArr.splice(deleteIndex, 1)
    }

    const setFn =
      setting.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleSubmit = async (values, helpers) => {
    const formattedSettings = [
      ...indoorThresholdSettings,
      ...outdoorThresholdSettings,
    ].map((setting) => formatRule(setting))

    const payload = {
      name: values.profileName,
      description: values.description || '',
      property: values.property,
      dataType: 'NOISE',
      rules: formattedSettings,
    }

    try {
      const response = await doNewThresholdProfileSave(payload)
      if (response.error) {
        helpers?.setErrors(response.error?.response)
        throw response.error
      }

      if (assignToProperty) {
        const assignResponse = await doThresholdProfileAssign({
          thresholdProfile: response.id,
          property: values.property,
        })
        if (assignResponse.error) {
          doShowSnackbar(`Unable to assign profile`, 'error')
        }
      }

      doShowSnackbar(`${values.profileName} Created`, 'success')
      setStep('one')
      onClose(true)
    } catch (error) {
      captureException(error)

      const parsedError = parseApiErrors(error?.response)
      doShowSnackbar(parsedError || `Unable to create ${values.profileName}`, 'error')
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      {({ values, dirty, isValid, setFieldValue }) => {
        const selectedProperty = propertyOptions.find(
          (option) => option.id === values.property,
        )

        return (
          <Form>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack mt={2} mb={0.5} sx={{ width: '315px' }}>
                <Field
                  required
                  component={TextField}
                  name="profileName"
                  label="Profile Name"
                  size="small"
                />
                <Field
                  component={TextField}
                  name="description"
                  label="Description (optional)"
                  size="small"
                  sx={{ my: 2 }}
                />
                <Field
                  required
                  disableClearable
                  component={StaticSelect}
                  options={propertyOptions}
                  onChange={(value) => {
                    const data = propertyOptions.find((option) => option.id === value)
                    setAssignToProperty(
                      data ? !data.effectiveNoiseThresholdProfile : false,
                    )
                  }}
                  getOptionLabel={(opt) =>
                    propertyOptions.find((option) => option.id === opt)?.name ??
                    opt?.name ??
                    opt
                  }
                  name="property"
                  label="Property"
                  size="small"
                />
              </Stack>
              <Stack direction="row" sx={{ height: '36px', mt: 1 }} spacing={2}>
                <Button size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="cancel"
                  sx={{ ':hover': { bgcolor: 'grey.400' } }}
                  onClick={() => setStep('two')}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={!isValid || !dirty}
                >
                  Save
                </Button>
              </Stack>
            </Box>
            {selectedProperty?.effectiveNoiseThresholdProfile && (
              <Box display="flex" justifyContent="space-between">
                <FormControlLabel
                  label="Assign to property"
                  labelPlacement="end"
                  sx={{ mt: 1 }}
                  disabled={!values.property}
                  control={
                    <Checkbox
                      checked={assignToProperty}
                      size="small"
                      onChange={() => setAssignToProperty((prev) => !prev)}
                    />
                  }
                />

                <Alert
                  severity="warning"
                  sx={{
                    visibility: selectedProperty?.effectiveNoiseThresholdProfile
                      ? 'visible'
                      : 'hidden',
                  }}
                >
                  <Typography variant="body2" whiteSpace="pre-line">
                    Property already has a threshold:{' '}
                    <Box component="span" display="inline" fontWeight="fontWeightBold">
                      {selectedProperty?.effectiveNoiseThresholdProfileName}
                    </Box>
                  </Typography>
                </Alert>
              </Box>
            )}
            <ThresholdModalContent
              indoorThresholdSettings={indoorThresholdSettings}
              setIndoorThresholdSettings={setIndoorThresholdSettings}
              outdoorThresholdSettings={outdoorThresholdSettings}
              setOutdoorThresholdSettings={setOutdoorThresholdSettings}
              handleAdd={handleAddSetting}
              handleEdit={handleEditSetting}
              handleDelete={
                indoorThresholdSettings.length > 1 ? handleDeleteSetting : null
              }
              cancelEdit={cancelEditSetting}
              confirmEdit={confirmEditSetting}
              isEditing={isEditing}
              editingId={editingId}
              settingData={settingData}
              setFieldValue={setFieldValue}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
