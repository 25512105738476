import { createListBundle, defaultInitialState, getEntityFromUrl } from '@common/utils'

import reservationUrls from './urls'

const entityName = 'reservation'

const reservationListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/reservations/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (url, _, hash) =>
    url === reservationUrls.list || reservationUrls.tabHash.includes(hash),
  initialState: {
    ...defaultInitialState,
    ordering: ['checkIn', 'desc'],
  },
})

export default reservationListBundle
