import { useConnect } from 'redux-bundler-hook'

import { CheckCircleOutlineRounded, DoNotDisturbRounded } from '@mui/icons-material'
import { Box, Button, Tooltip } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.row
 * @param {string} props.row.id
 * @param {string} props.row.eventType
 * @param {boolean} [props.fullWidth]
 * @param {Function} props.onApprovePressed
 * @param {Function} props.onRejectPressed
 */
export default function ReviewActions({
  row,
  onApprovePressed,
  onRejectPressed,
  fullWidth = false,
}) {
  const { isAtLeastEventReviewer } = useConnect('selectIsAtLeastEventReviewer')
  const { eventType } = row

  const isApproved = eventType === 'REVIEW_APPROVED'
  const isRejected = eventType === 'REVIEW_REJECTED'

  return (
    <Box flex={1} display="flex" gap={1} justifyContent="center">
      <Tooltip placement="top" title="Approve Event">
        <span style={fullWidth ? { width: '100%' } : null}>
          <Button
            fullWidth={fullWidth}
            size="small"
            color="success"
            startIcon={<CheckCircleOutlineRounded />}
            variant={isApproved ? 'contained' : 'outlined'}
            disabled={!isAtLeastEventReviewer}
            onClick={() => onApprovePressed(row)}
          >
            {isApproved ? 'Approved' : 'Approve'}
          </Button>
        </span>
      </Tooltip>
      <Tooltip placement="top" title="Reject Event">
        <span style={fullWidth ? { width: '100%' } : null}>
          <Button
            fullWidth={fullWidth}
            size="small"
            color="error"
            startIcon={<DoNotDisturbRounded />}
            variant={isRejected ? 'contained' : 'outlined'}
            onClick={() => onRejectPressed(row)}
            disabled={!isAtLeastEventReviewer}
          >
            {isRejected ? 'Rejected' : 'Reject'}
          </Button>
        </span>
      </Tooltip>
    </Box>
  )
}
