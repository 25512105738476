import { isEmpty, pickBy } from 'ramda'

import { DateTime } from 'luxon'

export function filterValidEntities(payload) {
  const validate = (val, key) =>
    ['organizationGroup', 'organization', 'account', 'property'].includes(key) &&
    Boolean(val)
  return pickBy(validate, payload)
}

export function validateEventPayload(payload) {
  const validEntities = filterValidEntities(payload)
  return !isEmpty(validEntities)
}

export const eventCsvHeaders = [
  'Event Id',
  'Alarm Id',
  'Child Count',
  'Parent Event Id',
  'Start Time',
  'End Time',
  'Local Start Time',
  'Local End Time',
  'Timezone',
  'Duration (seconds)',
  'Billable',
  'Oragnization Name',
  'Account Name',
  'Property Name',
  'Unit Name',
  'Feedback Created On',
  'Feedback Modified On',
  'Was Investigated',
  'Was Confirmed',
  'Unconfirmed Reason',
  'Smoke Type',
  'External Reservation Id',
  'Was Unit Booked',
  'Was Unit Occupied',
  'Guest Checkin',
  'Guest Checkout',
  'Inspected By',
  'Was Evidence Found',
  'Was Unit Collected',
  'Charge Amount',
  'Adjustment Amount',
  'Adjustment Reason',
  'Adjusted On',
  'Net Charge Amount',
  'Comments',
  'Modified By Id',
  'Last Modified By',
  'Deleted Reason',
]

export function getHeaderFields(events) {
  return events.map((row) => [
    row.id,
    row.metadata?.smoke?.alarmId ?? '',
    row.childCount ?? '',
    row.parentEvent ?? '',
    row.createdOn,
    row.endTime ?? '',
    row.createdOn ? DateTime.fromISO(row.createdOn).setZone(row.timeZone).toISO() : '',
    row.endTime ? DateTime.fromISO(row.endTime).setZone(row.timeZone).toISO() : '',
    row.timeZone,
    row.duration,
    row.billable,
    row.organizationName,
    row.accountName,
    row.propertyName,
    row.unitName,
    row.smokeFeedback?.createdOn ?? '',
    row.smokeFeedback?.modifiedOn ?? '',
    row.smokeFeedback?.wasInvestigated ?? '',
    row.smokeFeedback?.wasConfirmed ?? '',
    row.smokeFeedback?.unconfirmedReason ?? '',
    row.smokeFeedback?.smokeType ?? '',
    row.smokeFeedback?.externalReservationId ?? '',
    row.smokeFeedback?.wasUnitBooked ?? '',
    row.smokeFeedback?.wasUnitOccupied ?? '',
    row.smokeFeedback?.guestCheckIn ?? '',
    row.smokeFeedback?.guestCheckOut ?? '',
    row.smokeFeedback?.inspectedBy ?? '',
    row.smokeFeedback?.wasEvidenceFound ?? '',
    row.smokeFeedback?.wasFeeCollected ?? '',
    row.smokeFeedback?.chargeAmount ?? '',
    row.smokeFeedback?.adjustmentAmount ?? '',
    row.smokeFeedback?.adjustmentReason ?? '',
    row.smokeFeedback?.adjustmentOn ?? '',
    row.smokeFeedback?.netChargeAmount ?? '',
    row.smokeFeedback?.comments ?? '',
    row.smokeFeedback?.modifiedBy ?? '',
    row.smokeFeedback?.modifiedByEmail ?? '',
    row.deletedReason ?? '',
  ])
}
