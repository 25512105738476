import { useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Loading } from '@common/components'
import { TextField } from '@common/components/Form'
import { ThresholdModalContent } from '@common/components/thresholdManagement'
import { merge, parseApiErrors } from '@common/utils'

import { formatRule } from './utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} props.profileToEdit
 * @param {string} props.profileToEdit.id
 * @param {string} props.profileToEdit.name
 * @param {Object[]} [props.profileToEdit.rules]
 */
export default function EditThreshold({ open, onClose, profileToEdit = undefined }) {
  const [initialValues, setInitialValues] = useState({})
  const [indoorThresholdSettings, setIndoorThresholdSettings] = useState([])
  const [outdoorThresholdSettings, setOutdoorThresholdSettings] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const [settingData, setSettingData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const { doThresholdProfileSave, doShowSnackbar } = useConnect(
    'doThresholdProfileSave',
    'doShowSnackbar',
  )

  useEffect(() => {
    if (!profileToEdit) {
      setIsLoading(false)
      return
    }

    const formatProfile = (rule) => {
      const parseTime = (time) => {
        const timeArray = time.split(':')
        return parseInt(timeArray[0], 10)
      }
      return {
        ...rule,
        id: profileToEdit?.rules.indexOf(rule),
        startTime: parseTime(rule.startTime),
        endTime: parseTime(rule.endTime),
      }
    }

    const indoorRules = profileToEdit?.rules
      .filter((rule) => rule.deviceStyle === 'INDOOR')
      .map(formatProfile)
    const outdoorRules = profileToEdit?.rules
      .filter((rule) => rule.deviceStyle === 'OUTDOOR')
      .map(formatProfile)

    if (indoorRules && !isEmpty(indoorRules)) {
      setIndoorThresholdSettings(indoorRules)
    }
    if (outdoorRules && !isEmpty(outdoorRules)) {
      setOutdoorThresholdSettings(outdoorRules)
    }

    const values = merge(
      {
        name: '',
        description: '',
        rules: [],
        property: null,
      },
      profileToEdit,
    )
    setInitialValues(values)
    setIsLoading(false)
  }, [profileToEdit])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s.-]+$/,
        'Only alphanumeric characters, dashes, spaces, and commas are allowed.',
      )
      .required('Please enter a name'),
    description: Yup.string().matches(
      /^[a-zA-Z0-9\s.-]+$/,
      'Only alphanumeric characters, dashes, spaces, and commas are allowed.',
    ),
  })

  const handleSave = async (values) => {
    const formattedSettings = [
      ...indoorThresholdSettings,
      ...outdoorThresholdSettings,
    ].map((setting) => formatRule(setting))

    const payload = {
      id: profileToEdit.id,
      name: values.name,
      description: values.description,
      dataType: 'NOISE',
      rules: formattedSettings,
      property: values.property,
    }

    try {
      const response = await doThresholdProfileSave(payload)
      doShowSnackbar(`${values.name} Updated`, 'success')
      onClose(true)
      if (response.error) throw parseApiErrors(response)
    } catch (error) {
      doShowSnackbar(`Unable to update ${values.name}`, 'error')
      captureException(error)
    }
  }

  const cancelEditSetting = () => {
    setIsEditing(false)
    setEditingId(null)
    setSettingData(null)
  }

  const handleAddSetting = (startTime, deviceStyle) => {
    setIsEditing(true)
    setEditingId('add_new')
    setSettingData({
      id: Math.random(),
      startTime,
      endTime: startTime === 23 ? 0 : startTime + 1,
      highValue: 50,
      deviceStyle,
      name: 'Quiet Hours',
    })
  }

  const handleEditSetting = (setting) => {
    setIsEditing(true)
    setEditingId(setting.name)
    setSettingData(setting)
  }

  const handleDeleteSetting = (setting) => {
    const newThreshArr =
      setting.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const deleteIndex = newThreshArr.findIndex((obj) => obj.id === setting.id)
    if (deleteIndex !== -1) {
      newThreshArr.splice(deleteIndex, 1)
    }

    const setFn =
      setting.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const confirmEditSetting = (updatedThreshold) => {
    const newThreshArr =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const updateIndex = newThreshArr.findIndex((obj) => obj.id === updatedThreshold.id)
    if (updateIndex !== -1) {
      newThreshArr[updateIndex] = updatedThreshold
    } else {
      newThreshArr.push(updatedThreshold)
    }

    const setFn =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  return (
    <Dialog open={open} onClose={cancelEditSetting} disableScrollLock maxWidth="xl">
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, pb: 0, px: 4 }}
      >
        Edit Threshold Profile
        <IconButton onClick={onClose} sx={{ padding: 0, width: '32px' }}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      {isLoading && <Loading />}
      {!isLoading && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSave}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ dirty, isValid, setFieldValue }) => (
            <Form>
              <DialogContent sx={{ py: 0, px: 4, maxWidth: '1000px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Stack my={2} sx={{ width: '315px' }}>
                    <Field
                      component={TextField}
                      name="name"
                      label="Profile Name"
                      size="small"
                      autoFocus
                    />
                    <Field
                      component={TextField}
                      name="description"
                      label="Description (optional)"
                      size="small"
                      margin="normal"
                    />
                  </Stack>
                  <Stack direction="row" sx={{ height: '36px', mt: 1 }} spacing={2}>
                    <Button size="small" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      disabled={!isValid || !dirty}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>
                <ThresholdModalContent
                  indoorThresholdSettings={indoorThresholdSettings}
                  setIndoorThresholdSettings={setIndoorThresholdSettings}
                  outdoorThresholdSettings={outdoorThresholdSettings}
                  setOutdoorThresholdSettings={setOutdoorThresholdSettings}
                  handleAdd={handleAddSetting}
                  handleEdit={handleEditSetting}
                  handleDelete={
                    indoorThresholdSettings.length > 1 ? handleDeleteSetting : null
                  }
                  cancelEdit={cancelEditSetting}
                  confirmEdit={confirmEditSetting}
                  isEditing={isEditing}
                  editingId={editingId}
                  settingData={settingData}
                  setFieldValue={setFieldValue}
                />
              </DialogContent>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}
