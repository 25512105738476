import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { ErrorComponent, MobileList, MobileListDefaultCard } from '@common/components'
import { useSmallScreen } from '@common/utils'
import ZoneForm from '@portal/pages/Units/ZoneForm'
import DeleteModal from '@portal/UI/components/DeleteModal'
import List from '@portal/UI/components/List'

import { initialParams } from '../unitZoneListBundle'

export default function ZonesTab() {
  const [pageSize, setPageSize] = useState('')
  const [currentZone, setCurrentZone] = useState(null)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [rowIdForDelete, setRowIdForDelete] = useState(null)
  const [deleteError, setDeleteError] = useState(null)

  const isSmallScreen = useSmallScreen()

  const {
    doZoneDelete,
    doShowSnackbar,
    doUnitZoneListClearParams,
    doUnitZoneListSetFilter,
    doUnitZoneListSetPage,
    doUnitZoneListSetPageSize,
    doUnitZoneListSetOrdering,
    doUnitZoneListSetSearch,
    doMarkUnitZoneListAsOutdated,
    unitZoneList,
    unitZoneListIsLoading,
    routeParams: { id: unitId },
  } = useConnect(
    'doZoneDelete',
    'doShowSnackbar',
    'doUnitZoneListClearParams',
    'doUnitZoneListSetFilter',
    'doUnitZoneListSetPage',
    'doUnitZoneListSetPageSize',
    'doUnitZoneListSetOrdering',
    'doUnitZoneListSetSearch',
    'doMarkUnitZoneListAsOutdated',
    'selectUnitZoneList',
    'selectUnitZoneListIsLoading',
    'selectRouteParams',
  )

  const handleInitialParams = () => {
    doUnitZoneListClearParams()
    doUnitZoneListSetFilter(initialParams)
  }

  useEffect(() => handleInitialParams(), [])

  const handlePageChange = async (page) => doUnitZoneListSetPage(page)

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doUnitZoneListSetPageSize(size)
  }

  const handleZoneUpdate = (row) => {
    setCurrentZone(row)
    setEditFormOpen(true)
  }

  const handleZoneDelete = (zoneId) => {
    setRowIdForDelete(zoneId)
    setDeleteConfirmOpen(true)
  }

  const onDeleteFormClose = () => {
    setDeleteError(null)
    setDeleteConfirmOpen(false)
    setRowIdForDelete(null)
  }

  const onEditFormClose = () => {
    setCurrentZone(null)
    doMarkUnitZoneListAsOutdated()
    setEditFormOpen(false)
  }

  const handleDelete = async () => {
    try {
      await doZoneDelete(rowIdForDelete)
      doMarkUnitZoneListAsOutdated()
      doShowSnackbar('Successfully deleted zone')
      onDeleteFormClose()
    } catch (e) {
      setDeleteError(e.message || JSON.stringify(e))
    }
  }

  const listActions = {
    create: () => setEditFormOpen(true),
    update: handleZoneUpdate,
    delete: handleZoneDelete,
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.75,
    },
    {
      field: 'zoneSize',
      headerName: 'Size (SF)',
      flex: 0.25,
      type: 'number',
    },
  ]

  const rows = unitZoneList?.results?.map((zone) => ({
    id: zone.id,
    name: zone.name,
    unit: zone.unit,
    zoneSize: zone.zoneSize,
  }))

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  if (!unitZoneList.results)
    return <ErrorComponent title="Unit Zones" callback={handleInitialParams} />

  return (
    <>
      <ZoneForm
        open={editFormOpen}
        onClose={onEditFormClose}
        instance={currentZone ?? { unit: unitId }}
      />
      <DeleteModal
        open={deleteConfirmOpen}
        error={deleteError}
        onConfirmDelete={handleDelete}
        onCancelDelete={onDeleteFormClose}
      />
      {isSmallScreen ? (
        <MobileList
          title="Zones"
          loading={unitZoneListIsLoading}
          actions={listActions}
          itemBuilder={MobileListItem}
          listActions={[{ label: 'Add Zone', onClick: () => setEditFormOpen(true) }]}
          page={unitZoneList.current || 1}
          pageChange={handlePageChange}
          pageSize={unitZoneList.pageSize}
          pageSizeChange={handlePageSizeChange}
          rows={rows}
          rowCount={unitZoneList.count || 0}
          setSearch={doUnitZoneListSetSearch}
        />
      ) : (
        <Box
          marginTop={2}
          display="flex"
          flexDirection="column"
          height="700px"
          width="100%"
        >
          <List
            columnsAutosize
            title="Zones"
            loading={unitZoneListIsLoading}
            actions={listActions}
            columns={columns}
            page={unitZoneList.current || 1}
            pageChange={handlePageChange}
            pageSize={unitZoneList.pageSize}
            pageSizeChange={handlePageSizeChange}
            rows={rows}
            rowCount={unitZoneList.count || 0}
            setSearch={doUnitZoneListSetSearch}
            sortChange={doUnitZoneListSetOrdering}
          />
        </Box>
      )}
    </>
  )
}
