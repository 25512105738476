import { path } from 'ramda'

import { DateTime } from 'luxon'

import { isAbortError, isDateRangeOverlap } from '@common/utils'

const SENSOR_TESTING_UNIT_DATA_LOADING = 'SENSOR_TESTING_UNIT_DATA_LOADING'
const SENSOR_TESTING_UNIT_DATA_SUCCESS = 'SENSOR_TESTING_UNIT_DATA_SUCCESS'
const SENSOR_TESTING_UNIT_DATA_FAILED = 'SENSOR_TESTING_UNIT_DATA_FAILED'

const SENSOR_TESTING_DEVICES_CHART_LOADING = 'SENSOR_TESTING_DEVICES_CHART_LOADING'
const SENSOR_TESTING_DEVICES_CHART_SUCCESS = 'SENSOR_TESTING_DEVICES_CHART_SUCCESS'
const SENSOR_TESTING_DEVICES_CHART_FAILED = 'SENSOR_TESTING_DEVICES_CHART_FAILED'

const SENSOR_TESTING_RESET_STATE = 'SENSOR_TESTING_RESET_STATE'

const defaultState = {}

const entityName = 'sensorTesting'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === SENSOR_TESTING_RESET_STATE) {
      return defaultState
    }
    if (action.type === SENSOR_TESTING_UNIT_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === SENSOR_TESTING_UNIT_DATA_SUCCESS) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === SENSOR_TESTING_UNIT_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === SENSOR_TESTING_DEVICES_CHART_LOADING) {
      return { ...state, charts: { ...action.meta, payload: action.payload } }
    }
    if (action.type === SENSOR_TESTING_DEVICES_CHART_SUCCESS) {
      return { ...state, charts: { ...action.meta, data: action.payload } }
    }
    if (action.type === SENSOR_TESTING_DEVICES_CHART_FAILED) {
      return { ...state, charts: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectSensorTestingUnitDataIsLoading: ({ sensorTesting }) => {
    const status = path(['data', 'status'], sensorTesting)
    return status === 'loading'
  },
  selectSensorTestingDevicesChartsIsLoading: ({ sensorTesting }) => {
    const status = path(['charts', 'status'], sensorTesting)
    return status === 'loading'
  },
  selectSensorTestingUnitData: ({ sensorTesting }) =>
    path(['data', 'data'], sensorTesting),
  selectSensorTestingDevicesCharts: ({ sensorTesting }) =>
    path(['charts', 'data'], sensorTesting),
  selectSensorTestingUnitDataError: ({ sensorTesting }) =>
    path(['data', 'error'], sensorTesting),
  selectSensorTestingDevicesChartsError: ({ sensorTesting }) =>
    path(['charts', 'error'], sensorTesting),
  doResetSensorTestingState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: SENSOR_TESTING_RESET_STATE }),
  doFetchSensorTestingUnitData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: SENSOR_TESTING_UNIT_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { unit } = payload

        const unitResponse = await apiFetch(`/units/${unit}/`, null, {
          cancelationPrefix: entityName,
        })
        const devicesResponse = await apiFetch(
          `/devices/`,
          {
            unit,
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )

        const finalResult = {
          unit: unitResponse,
          devices: devicesResponse?.results ?? [],
        }

        dispatch({
          type: SENSOR_TESTING_UNIT_DATA_SUCCESS,
          payload: finalResult,
          meta: { status: 'succeeded' },
        })
        return finalResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: SENSOR_TESTING_UNIT_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchSensorTestingDevicesCharts:
    (payload) =>
    async ({ dispatch, apiFetch, store }) => {
      try {
        dispatch({
          type: SENSOR_TESTING_DEVICES_CHART_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { devices, types, timeRange } = payload
        const [start, end] = timeRange
        const profiles = store.selectSystemSmokeProfiles()

        const dataResolution = 1000

        const dataTypes = types.filter((type) => type !== 'probabilities')
        const responses =
          dataTypes.length > 0
            ? await Promise.all(
                devices.map((id) =>
                  apiFetch(
                    `/devices/${id}/chart/`,
                    {
                      dataTypes: types.filter((type) => type !== 'probabilities'),
                      resolution: dataResolution,
                      start,
                      end,
                    },
                    { cancelationPrefix: entityName },
                  ),
                ),
              )
            : []
        const processedCharts = responses.reduce((acc, data) => [...acc, ...data], [])

        const probabilitiesResponses = types.includes('probabilities')
          ? await Promise.all(
              devices.map((id) =>
                apiFetch(
                  `/devices/${id}/probability_chart/`,
                  {
                    start,
                    end,
                    profiles: profiles.map((profile) => profile.id),
                    resolution: dataResolution,
                  },
                  { cancelationPrefix: entityName },
                ),
              ),
            )
          : []
        const processedProbabilities = probabilitiesResponses
          .reduce((acc, data) => [...acc, ...data], [])
          .map((data) => ({
            ...data,
            data: data.data.map((dt) => ({
              ...dt,
              value: typeof dt.value === 'number' ? dt.value * 100 : dt.value,
            })),
          }))

        const scenariosResponse = await apiFetch(
          `/scenarios/`,
          {
            sourceDevice: devices.join(','),
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedScenarios = scenariosResponse?.results?.filter((scenario) =>
          isDateRangeOverlap(
            { start: scenario.readingStart, end: scenario.readingEnd },
            { start, end },
          ),
        )

        const eventsResponse = await apiFetch(
          `/events/`,
          {
            device: devices.join(','),
            createdOnAfter: DateTime.fromISO(start).minus({ days: 1 }),
            createdOnBefore: DateTime.fromISO(end).plus({ days: 1 }),
            includeChildEvents: true,
            includeDeletedEvents: true,
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedEvents = eventsResponse?.results?.map((event) => ({
          ...event,
          createdOn: DateTime.fromISO(event.createdOn),
          endTime: event.endTime ? DateTime.fromISO(event.endTime) : null,
        }))

        const alarmsResponses = await Promise.all(
          devices.map((id) =>
            apiFetch(
              `/alarms/`,
              {
                deviceId: id,
                startAfter: start,
                startBefore: end,
                pageSize: 9999,
              },
              { cancelationPrefix: entityName },
            ),
          ),
        )

        const processedAlarms = alarmsResponses
          ?.map((response) => response.results)
          ?.reduce((acc, data) => [...acc, ...data], [])
          ?.map((alarm) => {
            const relatedEvent = processedEvents.find(
              (event) => event.metadata?.smoke?.alarmId === alarm.id,
            )
            return {
              ...alarm,
              start: DateTime.fromISO(alarm.start),
              end: alarm.end ? DateTime.fromISO(alarm.end) : null,
              relatedEvent,
            }
          })

        const finalData = {
          charts: [...processedCharts, ...processedProbabilities],
          scenarios: processedScenarios ?? [],
          alarms: processedAlarms ?? [],
          events: processedEvents ?? [],
        }

        dispatch({
          type: SENSOR_TESTING_DEVICES_CHART_SUCCESS,
          payload: finalData,
          meta: { status: 'succeeded' },
        })
        return finalData
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: SENSOR_TESTING_DEVICES_CHART_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
