import '@rest/UI/Theme/main.css'

import React from 'react'
import { createRoot } from 'react-dom/client'

import { ReduxBundlerProvider } from 'redux-bundler-hook'

import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { LicenseInfo } from '@mui/x-license'

import * as Sentry from '@sentry/react'

import { config, getStoredTokens, Project } from '@common/config'
import cache from '@common/utils/cache'
import PortalApp from '@portal/App'
import createPortalStore from '@portal/bundles'
import { lightTheme as portalTheme } from '@portal/UI/Theme'
import RestApp from '@rest/App'
import createRestStore from '@rest/bundles'
import { defaultState as authDefaultState } from '@rest/bundles/auth'
import restTheme from '@rest/UI/Theme'

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: __version__,
    environment: config.ENVIRONMENT,
  })
}

if (config.MUI_PRO_LICENSE) {
  LicenseInfo.setLicenseKey(config.MUI_PRO_LICENSE)
}

if (globalThis.Cypress) {
  globalThis.cache = cache
}

cache.getAll().then((data) => {
  const { isRest } = Project

  if (data) {
    // eslint-disable-next-line no-console
    console.debug('hydrating store from cache:', data)
  }

  const { access } = getStoredTokens()

  const createStore = isRest ? createRestStore : createPortalStore
  const store = createStore({
    ...data,
    auth: {
      ...authDefaultState,
      authenticated: !!access,
    },
  })

  if (globalThis.Cypress) {
    globalThis.store = store
  }

  document.title = isRest ? 'Rest' : 'Rest Portal'

  if (isRest) {
    store.doMarkSystemAsOutdated()
    store.doMarkMeAsOutdated()
    store.doMarkHeaderAsOutdated()
  } else {
    store.doMarkSystemAsOutdated()
    store.doMarkMeAsOutdated()
  }

  const container = document.getElementById('app')
  const root = createRoot(container)
  const theme = isRest ? restTheme : portalTheme
  const app = (
    <React.StrictMode>
      <ReduxBundlerProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            localeText={isRest ? { start: 'Start Date', end: 'End Date' } : null}
          >
            {isRest ? <RestApp /> : <PortalApp />}
          </LocalizationProvider>
        </ThemeProvider>
      </ReduxBundlerProvider>
    </React.StrictMode>
  )

  root.render(app)
})
