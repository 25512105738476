import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'

import {
  Breadcrumbs,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { useQueryFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import { BooleanCell } from '@portal/UI/components/cells'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListExportButton from '@portal/UI/components/ListExportButton'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import boolOptions from '@portal/Utils/constants'

import OrganizationForm from './OrganizationForm'
import orgUrls from './urls'

export default function OrganizationList() {
  const [pageSize, setPageSize] = useState('')
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [rowIdForDelete, setRowIdForDelete] = useState('')
  const [saveFormOpen, setSaveFormOpen] = useState(false)
  const [currentOrganization, setCurrentOrganization] = useState({})
  const [error, setError] = useState('')

  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const isSmallScreen = useSmallScreen()

  const {
    doOrganizationListClearParams,
    doOrganizationListSetFilter,
    doOrganizationListSetPage,
    doOrganizationListSetPageSize,
    doOrganizationListSetOrdering,
    doMarkOrganizationListAsOutdated,
    doUpdateUrl,
    organizationList,
    organizationListRaw: { ordering = [] },
    organizationListIsLoading,
    organizationListApiParams,
    systemFeatureFlags,
    doOrganizationDelete,
    doShowSnackbar,
    doUpdateQuery,
    queryObject,
  } = useConnect(
    'doOrganizationListClearParams',
    'doOrganizationListSetFilter',
    'doOrganizationListSetPage',
    'doOrganizationListSetPageSize',
    'doOrganizationListSetOrdering',
    'doMarkOrganizationListAsOutdated',
    'doUpdateUrl',
    'selectOrganizationList',
    'selectOrganizationListRaw',
    'selectOrganizationListIsLoading',
    'selectOrganizationListApiParams',
    'selectSystemFeatureFlags',
    'doOrganizationDelete',
    'doShowSnackbar',
    'doUpdateQuery',
    'selectQueryObject',
  )

  useQueryFilter({
    query: queryObject,
    apiParams: organizationListApiParams,
    setFilter: doOrganizationListSetFilter,
    setPageSize: doOrganizationListSetPageSize,
  })

  useEffect(() => {
    doMarkOrganizationListAsOutdated()
  }, [])

  const handleClear = () => {
    doOrganizationListClearParams()
    doOrganizationListSetFilter()
  }

  const handlePageChange = async (page) => {
    doOrganizationListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doOrganizationListSetPageSize(size)
  }

  const handleRowClick = (org) => {
    const orgDetail = orgUrls.entity.replace(':id', org.id)
    doUpdateUrl(orgDetail)
  }

  const openConfirmDeleteModal = (orgId) => {
    setRowIdForDelete(orgId)
    setDeleteConfirmOpen(true)
  }

  const handleOrganizationSave = (row) => {
    setSaveFormOpen(true)
    setCurrentOrganization(row)
  }

  const handleDelete = async () => {
    try {
      await doOrganizationDelete(rowIdForDelete)
      doShowSnackbar('Successfully deleted Organization')
      await doMarkOrganizationListAsOutdated()
      setRowIdForDelete('')
      setDeleteConfirmOpen(false)
    } catch (e) {
      setError(e.message || JSON.stringify(e))
    }
  }

  const exportListConfig = {
    apiParams: organizationListApiParams,
    entity: 'organizations',
  }

  const listActions = {
    create: handleOrganizationSave,
    update: handleOrganizationSave,
    delete: openConfirmDeleteModal,
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      isMainCell: true,
      flex: 0.5,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 0.25,
      type: 'boolean',
      renderCell: BooleanCell,
      headerAlign: 'center',
    },
    {
      field: 'groupName',
      headerName: 'Group',
      flex: 0.25,
    },
    {
      field: 'accountCount',
      headerName: 'Accounts',
      flex: 0.25,
      type: 'number',
    },
    {
      field: 'propertyCount',
      headerName: 'Properties',
      flex: 0.25,
      type: 'number',
    },
    {
      field: 'unitCount',
      headerName: 'Units',
      flex: 0.25,
      type: 'number',
    },
    {
      field: 'deviceCount',
      headerName: 'Devices',
      flex: 0.25,
      type: 'number',
    },
  ]

  const MobileItemHeader = useCallback(({ row }) => {
    const StatusIcon = row.isActive ? CheckCircleOutline : CancelOutlined
    return (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={orgUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.name}
          </Typography>
        </Link>
        <StatusIcon
          color={row.isActive ? 'success' : 'warning'}
          sx={{ fontSize: 16, ml: 0.5 }}
        />
      </Box>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name', 'isActive'],
        multiRowFields: [
          ['accountCount', 'propertyCount'],
          ['unitCount', 'deviceCount'],
        ],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  if (!organizationList.results) {
    return <ErrorComponent title="Organizations" callback={handleClear} />
  }

  const rows = organizationList?.results?.map((org) => ({
    id: org.id,
    name: org.name,
    accountCount: org.accountCount,
    propertyCount: org.propertyCount,
    unitCount: org.unitCount,
    deviceCount: org.deviceCount,
    group: org.group,
    groupName: org.groupName,
    isActive: !org.deletedOn,
    orgScore: 0,
    accountsInSalesforce: org.accountsInSalesforce,
    accountsInStripe: org.accountsInStripe,
    accountsInV1: org.accountsInV1,
  }))

  return (
    <>
      <OrganizationForm
        open={saveFormOpen}
        onClose={() => {
          setCurrentOrganization({})
          doMarkOrganizationListAsOutdated()
          setSaveFormOpen(false)
        }}
        instance={currentOrganization}
      />
      <Box m={3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: 'Organizations' }]}
        />
        <ListPageTitle
          title="Organizations"
          onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
          menuItems={
            isSmallScreen
              ? [
                  { label: 'Add Organization', onClick: handleOrganizationSave },
                  {
                    label: 'Export',
                    onClick: () => {},
                    render: ({ onClose }) => (
                      <ListExportButton
                        mode="menuItem"
                        postExport={onClose}
                        {...exportListConfig}
                      />
                    ),
                  },
                ]
              : null
          }
          mb={2}
        />
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={organizationListIsLoading}
            apiParams={organizationListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <DynamicSelect
              label="Organization Groups"
              filterName="organization_group"
            />
            <StaticSelect
              label="Feature Flags"
              filterName="flag"
              getOptionLabel={(option) => option && option.replace('_', ' ')}
              options={systemFeatureFlags}
            />
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title="Organizations"
                loading={organizationListIsLoading}
                actions={listActions}
                itemBuilder={MobileListItem}
                page={organizationList.current || 1}
                pageChange={handlePageChange}
                pageSize={organizationList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={organizationList.count || 0}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                title="Organizations"
                loading={organizationListIsLoading}
                exportListConfig={exportListConfig}
                actions={listActions}
                columns={columns}
                page={organizationList.current || 1}
                pageChange={handlePageChange}
                pageSize={organizationList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={organizationList.count || 0}
                sortChange={doOrganizationListSetOrdering}
                currentOrdering={ordering}
              />
            )}
            <DeleteModal
              open={deleteConfirmOpen}
              error={error}
              onConfirmDelete={handleDelete}
              onCancelDelete={() => setDeleteConfirmOpen(false)}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
