export const addThresholdProfileText = {
  stepOneHeader:
    'Do you have sensitive neighbors? Does your community have strict ordinances?',
  stepOneBody:
    'If you answered yes, consider selecting “Allow Less Noise.” You can always nudge your thresholds later if you are receiving too many alerts.',
  stepTwoHeader: 'When are your quiet hours?',
  stepTwoBody:
    'In most neighborhoods, quiet hours are between 9:00 PM and 6:00 AM. We recommend starting them a little earlier so that if there is a problem, you will be made aware it before any ordinances take effect.',
}

export const hoursOfTheDay = [
  '12:00 AM',
  '1:00 AM',
  '2:00 AM',
  '3:00 AM',
  '4:00 AM',
  '5:00 AM',
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
  '10:00 PM',
  '11:00 PM',
]
