import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  EmailOutlined,
  FlipCameraAndroidRounded as SwitchIcon,
  Logout,
  PermIdentityOutlined,
  Person,
  PersonRounded,
  Settings,
} from '@mui/icons-material'
import { Button, Menu, MenuItem, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import UserAvatar from '@common/components/Avatar'
import { Project } from '@common/config'
import { useSmallScreen } from '@common/utils'
import { configureUrls } from '@rest/pages/Configure'
import { userSettingsUrls } from '@rest/pages/UserSettings'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isAuthenticated
 * @param {Object} props.me
 * @param {string} [props.me.name]
 * @param {string} [props.me.email]
 * @param {string} [props.me.image]
 * @param {Object} [props.me.role]
 * @param {string} [props.me.role.name]
 */
export default function UserMenu({ me, isAuthenticated }) {
  const [userAnchor, setUserAnchor] = useState(null)
  const openUserMenu = Boolean(userAnchor)
  const { doAuthLogout, isPortalUser, isStaff, doUpdateUrl } = useConnect(
    'doAuthLogout',
    'selectIsPortalUser',
    'selectIsStaff',
    'doUpdateUrl',
  )

  const isSmallScreen = useSmallScreen()

  const handleMenuOpen = (event) => setUserAnchor(event.currentTarget)
  const handleMenuClose = () => setUserAnchor(null)

  const openUserSettings = () => {
    handleMenuClose()
    doUpdateUrl(userSettingsUrls.userSettings)
  }

  const openConfigure = () => {
    handleMenuClose()
    doUpdateUrl(configureUrls.thresholdsList)
  }

  if (!isAuthenticated) {
    return (
      <Button href="/login" sx={{ color: 'white', fontSize: 12 }}>
        Sign In
      </Button>
    )
  }

  return (
    <List>
      <ListItemButton
        data-testid="user-nav-button"
        onClick={handleMenuOpen}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {isSmallScreen ? (
          <PersonRounded sx={{ color: 'white' }} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
              <Typography variant="body2">{me.name}</Typography>
            </Box>
            <UserAvatar name={me.name} image={me.image} />
          </Box>
        )}
      </ListItemButton>
      <Menu
        disableScrollLock
        open={openUserMenu}
        anchorEl={userAnchor}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main' }} variant="rounded">
              <Person />
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
              <Typography variant="body2">{me.name}</Typography>
              <Typography variant="caption" color="text.secondary">
                {me.role?.name}
              </Typography>
            </Box>
          </Box>
        </ListItem>
        <ListItem>
          <EmailOutlined sx={{ opacity: '.54' }} />
          <Typography variant="caption" color="text.secondary" ml={1}>
            {me.email}
          </Typography>
        </ListItem>
        <Divider sx={{ mb: 1 }} />
        <MenuItem onClick={openUserSettings}>
          <ListItemIcon>
            <PermIdentityOutlined />
          </ListItemIcon>
          <ListItemText primary="User Settings" />
        </MenuItem>
        {isStaff && (
          <MenuItem onClick={openConfigure}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Configure" />
          </MenuItem>
        )}
        {isPortalUser && (
          <MenuItem data-testid="switch-project-btn" onClick={() => Project.switch()}>
            <ListItemIcon>
              <SwitchIcon />
            </ListItemIcon>
            <ListItemText primary="Switch To Portal" />
          </MenuItem>
        )}
        <MenuItem data-testid="logout-btn" onClick={doAuthLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
        <Typography variant="caption" color="text.secondary" pt={2} px={2}>
          {__version__}
        </Typography>
      </Menu>
    </List>
  )
}
