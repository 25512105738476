import { useCallback, useEffect, useMemo, useState } from 'react'

import { equals } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Settings } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import { Button, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'

import { capitalize, singularize } from 'inflection'

import { SearchBox } from '@common/components'
import ListExportButton from '@portal/UI/components/ListExportButton'

import { MainCell } from './cells'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.title
 * @param {Object[]} [props.otherActions]
 * @param {string} [props.otherActions[].label]
 * @param {Object} [props.otherActions[].icon]
 * @param {Function} [props.otherActions[].onClick]
 * @param {Object} [props.actions]
 * @param {Function} [props.actions.create]
 * @param {Function} [props.actions.update]
 * @param {Function} [props.actions.delete]
 * @param {Object[]} [props.actions.bulk]
 * @param {Object} [props.actionsLabels]
 * @param {string} [props.actionsLabels.view]
 * @param {string} [props.actionsLabels.update]
 * @param {string} [props.actionsLabels.delete]
 * @param {Object} [props.actionsPermission]
 * @param {bool} [props.actionsPermission.default]
 * @param {bool} [props.actionsPermission.create]
 * @param {bool} [props.actionsPermission.update]
 * @param {bool} [props.actionsPermission.delete]
 * @param {bool} [props.actionsPermission.bulk]
 * @param {boolean} [props.showActions]
 * @param {boolean} [props.showBulkActions]
 * @param {boolean} [props.showAddButton]
 * @param {string} [props.addButtonText]
 * @param {React.Element} [props.customAddButton]
 * @param {Object} [props.exportListConfig]
 * @param {Object} [props.exportListConfig.apiParams]
 * @param {string} [props.exportListConfig.entity]
 * @param {Object[]} props.columns
 * @param {Object[]} [props.columns[].field]
 * @param {string} [props.columns[].headerName]
 * @param {number} [props.columns[].width]
 * @param {number} props.page
 * @param {Function} props.pageChange
 * @param {number} [props.pageSize]
 * @param {Function} props.pageSizeChange
 * @param {number[]} [props.pageSizeOptions]
 * @param {Object[]} props.rows
 * @param {number} props.rowCount
 * @param {Function} [props.rowClick]
 * @param {Function} props.sortChange
 * @param {Function} [props.setSearch]
 * @param {number} [props.searchTriggerLength]
 * @param {boolean} props.loading
 * @param {boolean} [props.queryDrivenSearch]
 * @param {boolean} [props.dynamicRowHeight]
 * @param {string[]} [props.currentOrdering]
 * @param {boolean} [props.resizableColumns]
 * @param {Function} [props.onListSettingsPressed]
 * @param {boolean} [props.columnsAutosize]
 */
export default function List({
  title,
  actions = {},
  actionsLabels = undefined,
  showActions = true,
  showAddButton = true,
  showBulkActions = true,
  addButtonText = '',
  otherActions = [],
  customAddButton = null,
  exportListConfig = undefined,
  columns,
  page,
  pageChange,
  pageSize = 25,
  pageSizeChange,
  pageSizeOptions = [10, 25, 50, 100],
  rows,
  rowCount,
  rowClick = null,
  sortChange,
  setSearch = null,
  loading,
  queryDrivenSearch = false,
  dynamicRowHeight = false,
  currentOrdering = [],
  actionsPermission = null,
  resizableColumns = false,
  onListSettingsPressed = undefined,
  searchTriggerLength = 3,
  ...rest
}) {
  const columnsAutosize = false // temporary disable autosizing feature

  const apiRef = useGridApiRef()

  const { doUpdateQuery, queryObject, isAtLeastAdmin } = useConnect(
    'doUpdateQuery',
    'selectQueryObject',
    'selectIsAtLeastAdmin',
  )

  const autosizeOptions = useMemo(
    () => ({
      includeHeaders: true,
      includeOutliers: true,
      expand: true,
      columns: columns.map((colDef) => colDef.field),
    }),
    [columns],
  )

  const [columnsSizes, setColumnsSizes] = useState(null)
  const [internalRows, setInternalRows] = useState(rows ?? [])
  const [internalSelectedIds, setInternalSelectedIds] = useState([])

  useEffect(() => {
    if (!equals(rows, internalRows)) {
      setInternalRows(rows)
    }
  }, [rows])

  const checkColumnsMatch = () =>
    apiRef.current &&
    autosizeOptions.columns.every((field) => apiRef.current.state.columns.lookup[field])

  useEffect(() => {
    if (columnsAutosize && columnsSizes) {
      queueMicrotask(() => {
        if (!checkColumnsMatch()) return
        apiRef.current?.autosizeColumns(autosizeOptions)
      })
    }
  }, [autosizeOptions, columnsSizes, columnsAutosize])

  const updateRows = useCallback(async () => {
    if (columnsAutosize) {
      if (!checkColumnsMatch()) return

      queueMicrotask(async () => {
        await apiRef.current?.autosizeColumns(autosizeOptions)

        const columnsData = apiRef.current?.state?.columns?.lookup ?? []
        const updatedSized = Object.keys(columnsData)
          .map((key) => {
            const colDef = columnsData[key]
            const width = colDef.computedWidth
            return { [key]: { width, hasBeenResized: colDef.hasBeenResized } }
          })
          .reduce((acc, item) => ({ ...acc, ...item }), {})
        setColumnsSizes(updatedSized)
      })
    }
  }, [autosizeOptions, internalRows, columnsAutosize])

  useEffect(() => {
    updateRows()
  }, [internalRows, autosizeOptions, columns])

  let resizeTimer
  const handleWindowResize = () => {
    if (columnsAutosize) {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        updateRows()
      }, 250)
    }
  }

  const componentRef = useCallback(
    (node) => {
      if (!node || !columnsAutosize) return
      const resizeObserver = new ResizeObserver(() => {
        handleWindowResize()
      })
      resizeObserver.observe(node)
    },
    [autosizeOptions, columnsAutosize],
  )

  const selectedIds = rest?.rowSelectionModel || internalSelectedIds
  const setSelectedIds = rest?.onRowSelectionModelChange || setInternalSelectedIds

  const requiredPermission = actionsPermission?.default || isAtLeastAdmin
  const shouldRenderRowActions = showActions && requiredPermission
  const shouldRenderAddButton =
    showAddButton && (actionsPermission?.create || requiredPermission)
  const shouldRenderBulkActions =
    !!actions?.bulk && (actionsPermission?.bulk || requiredPermission)

  const modifiedColumns = useMemo(
    () =>
      columns.map((column) => {
        let columnWithDefaultValue = {
          display: column.renderCell ? 'flex' : 'block',
          valueFormatter: (value) =>
            value === null || value === undefined || value === '' ? 'N/A' : value,
          ...column,
        }

        if (columnsAutosize) {
          delete columnWithDefaultValue.flex
          columnWithDefaultValue = {
            ...columnWithDefaultValue,
            width: columnsSizes?.[column.field]?.width ?? columnWithDefaultValue.width,
          }
        }

        if (columnWithDefaultValue.isMainCell && rowClick) {
          return {
            ...columnWithDefaultValue,
            renderCell: ({ value, row }) => {
              if (columnWithDefaultValue.isMainCellActive) {
                return columnWithDefaultValue.isMainCellActive(row) ? (
                  <MainCell
                    key={column.field ?? column.headerName}
                    label={value}
                    onClick={() => rowClick(row)}
                  />
                ) : (
                  value
                )
              }
              return (
                <MainCell
                  key={column.field ?? column.headerName}
                  label={value}
                  onClick={() => rowClick(row)}
                />
              )
            },
          }
        }
        return columnWithDefaultValue
      }),
    [columnsAutosize, columnsSizes, rowClick],
  )

  const handleCreateAction = (row) => actions?.create && actions.create(row)
  const handleUpdateAction = (row) => actions?.update && actions.update(row)
  const handleDeleteAction = (row) => actions?.delete && actions.delete(row)

  const handleSortChange = (sort) => {
    if (!sort.length) return sortChange(sort)
    const column = columns.find((col) => col.field === sort[0].field)
    const field = column.sortField ?? column.field
    return sortChange([field, sort[0].sort])
  }

  const onPaginationModelChange = (model) => {
    if (model.page !== page - 1) {
      pageChange(model.page + 1)
    }
    if (model.pageSize !== pageSize) {
      pageSizeChange(model.pageSize)
    }
  }

  const queryDrivenSearchHandler = (search) => {
    const queryParams = { ...queryObject, page: 1, search }
    if (!queryParams.search) {
      delete queryParams.search
    }
    doUpdateQuery(queryParams)
  }

  const addBtnText = addButtonText || `Add ${capitalize(singularize(title))}`

  const mapBulkButtons = () =>
    actions?.bulk?.map((action) => (
      <Button
        key={action.title}
        onClick={(event) => action.action(selectedIds, event.currentTarget)}
        variant="outlined"
        disabled={action.disabled ? action.disabled(selectedIds) : false}
        sx={{ my: 1, mr: 1 }}
      >
        {action.title}
      </Button>
    ))

  const columnsWithActions = [
    ...modifiedColumns,
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ row }) => [
        ...(rowClick && (row.isRowClickable ?? true)
          ? [
              <GridActionsCellItem
                icon={<InfoIcon />}
                label={actionsLabels?.view || 'View'}
                onClick={() => rowClick(row)}
                showInMenu
              />,
            ]
          : []),
        ...(actions?.update
          ? [
              <GridActionsCellItem
                icon={<EditIcon />}
                label={actionsLabels?.update || 'Edit'}
                onClick={() => handleUpdateAction(row)}
                showInMenu
              />,
            ]
          : []),
        ...(actions?.delete
          ? [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label={actionsLabels?.delete || 'Delete'}
                onClick={() => handleDeleteAction(row.id)}
                showInMenu
              />,
            ]
          : []),
        ...(otherActions.map(({ label, icon, disabled, onClick }) => (
          <GridActionsCellItem
            key={label}
            icon={icon}
            label={label}
            onClick={() => onClick(row)}
            disabled={disabled ? disabled(row) : false}
            showInMenu
          />
        )) || []),
      ],
    },
  ]

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          alignContent="center"
        >
          {setSearch || queryDrivenSearch ? (
            <SearchBox
              title={title}
              margin="dense"
              minLength={searchTriggerLength}
              onSetSearch={queryDrivenSearch ? queryDrivenSearchHandler : setSearch}
            />
          ) : null}
          {onListSettingsPressed && (
            <IconButton onClick={onListSettingsPressed}>
              <Settings />
            </IconButton>
          )}
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          {exportListConfig && <ListExportButton {...exportListConfig} />}
          {selectedIds.length > 0 && showBulkActions && mapBulkButtons()}
          {shouldRenderAddButton && !customAddButton && (
            <Button onClick={handleCreateAction} variant="contained">
              {addBtnText}
            </Button>
          )}
          {shouldRenderAddButton && customAddButton}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          '& .MuiDataGrid-root': {
            '--DataGrid-pinnedBackground': 'transparent !important',
            '--DataGrid-containerBackground': 'transparent !important',
          },
          '& .MuiDataGrid-container--top:after': { height: 0 },
          '& .MuiDataGrid-root, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeaders':
            { border: 'none' },
        }}
      >
        <DataGridPro
          autoHeight
          pagination
          disableColumnFilter
          disableRowSelectionOnClick
          apiRef={apiRef}
          ref={componentRef}
          checkboxSelection={shouldRenderBulkActions}
          columns={shouldRenderRowActions ? columnsWithActions : modifiedColumns}
          isRowSelectable={(params) => params?.row?.mutable ?? true}
          density="comfortable"
          paginationModel={{ page: page - 1, pageSize }}
          resizeThrottleMs={0}
          onPaginationModelChange={onPaginationModelChange}
          paginationMode="server"
          rows={internalRows}
          rowCount={rowCount}
          pageSizeOptions={pageSizeOptions}
          sortingMode="server"
          onSortModelChange={handleSortChange}
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={setSelectedIds}
          getRowHeight={() => (dynamicRowHeight ? 'auto' : 67)}
          sx={{
            '& .MuiDataGrid-columnSeparator, & .MuiDataGrid-columnSeparator:hover':
              resizableColumns ? { color: 'transparent' } : { display: 'none' },
            '& .MuiDataGrid-cell': { alignContent: 'center' },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
            ...(dynamicRowHeight && {
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                py: '18px',
              },
            }),
          }}
          loading={loading}
          sortModel={
            currentOrdering?.length === 2
              ? [{ field: currentOrdering[0], sort: currentOrdering[1] }]
              : undefined
          }
          {...rest}
        />
      </Box>
    </>
  )
}
