import { Box, Stack, Typography } from '@mui/material'

function NotFoundPage() {
  return (
    <Box
      display="flex"
      sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <Stack direction="column" alignItems="center">
        <Typography variant="h1">404</Typography>
        <Typography variant="h4">Page Not Found</Typography>
      </Stack>
    </Box>
  )
}

export default NotFoundPage
