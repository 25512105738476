import { Fragment, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'

import { titleize } from 'inflection'
import * as yup from 'yup'

import UserAvatar from '@common/components/Avatar'
import FormDialog from '@portal/UI/components/FormDialog'
import MembershipForm from '@portal/UI/components/MembershipForm'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} props.instance.name
 * @param {string} [props.instance.organization]
 * @param {string} [props.instance.roleLevel]
 * @param {Object[]} [props.instance.memberships]
 */
export default function RoleMembershipForm({ open, onClose, instance = {} }) {
  const [currentMemberships, setCurrentMemberships] = useState([])
  const [addMembershipFormOpen, setAddMembershipFormOpen] = useState(false)
  const { isAtLeastAdmin, doMembershipDelete, doMarkRolesListAsOutdated } = useConnect(
    'selectIsAtLeastAdmin',
    'doMembershipDelete',
    'doMarkRolesListAsOutdated',
  )
  const validationSchema = yup.object().shape({
    name: yup.string().max(30),
  })

  const initialValues = {
    id: '',
    name: '',
    organization: '',
    permissions: '',
    role: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const handleSave = async () => {
    const membershipsToDelete = instance?.memberships?.filter(
      (m) => !currentMemberships?.map((c) => c.id).includes(m.id),
    )
    const idsToDelete = membershipsToDelete?.map((m) => ({ id: m.id }))
    await doMembershipDelete(idsToDelete)
    doMarkRolesListAsOutdated()
    onClose()
  }

  const handleRemove = (id) => {
    setCurrentMemberships(currentMemberships.filter((m) => m.id !== id))
  }

  const handleAddMembership = () => setAddMembershipFormOpen(true)

  const formatSecondaryText = (mship) =>
    `${titleize(mship.entityType)}: ${mship.entityName}`

  useEffect(() => {
    setCurrentMemberships(instance?.memberships)
  }, [instance])

  const orgLevelRole = instance?.roleLevel?.includes('Organization')
  const entityType = orgLevelRole ? 'organization' : 'account'

  return (
    <>
      <MembershipForm
        open={addMembershipFormOpen}
        onClose={(saved) => {
          setAddMembershipFormOpen(false)
          if (saved === true) {
            doMarkRolesListAsOutdated()
            onClose()
          }
        }}
        entity={entityType}
        instance={{
          ...instance,
          role: instance?.id,
        }}
        roleConfig={{ filters: { id: instance?.id }, disabled: true }}
      />
      <FormDialog
        label={`Memberships for ${instance?.name}`}
        maxWidth="xs"
        open={open}
        onSave={handleSave}
        onClose={onClose}
        initialValues={initialValues}
        validationSchema={validationSchema}
        extraActionsPosition="left"
        extraActions={
          isAtLeastAdmin
            ? [
                {
                  label: 'Add Membership',
                  color: 'secondary',
                  onClick: handleAddMembership,
                },
              ]
            : []
        }
      >
        <List>
          {currentMemberships?.map((m, i) => (
            <Fragment key={m.id}>
              <ListItem
                key={m.id}
                alignItems="flex-start"
                secondaryAction={
                  <Box display="flex" gap={3}>
                    <IconButton
                      edge="end"
                      disabled={!isAtLeastAdmin}
                      onClick={() => handleRemove(m.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                }
              >
                <ListItemAvatar>
                  <UserAvatar name={titleize(m.name)} index={i} />
                </ListItemAvatar>
                <ListItemText
                  primary={titleize(m.name)}
                  secondary={formatSecondaryText(m)}
                />
              </ListItem>
              <Divider variant="middle" />
            </Fragment>
          ))}
        </List>
      </FormDialog>
    </>
  )
}
