import { useCallback, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import { titleize } from 'inflection'
import * as yup from 'yup'

import { FormikDynamicSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {'add'|'remove'} [props.operation]
 * @param {Object[]} [props.properties]
 */
export default function ShadowProfileForm({
  open,
  onClose,
  operation = null,
  properties = null,
}) {
  const [loading, setLoading] = useState(false)
  const [confirmationData, setConfirmationData] = useState(null)
  const [formikProps, setFormikProps] = useState({
    values: {},
    errors: {},
    setFieldValue: () => {},
  })

  const { doPropertyChangeShadowProfiles, doShowSnackbar } = useConnect(
    'doPropertyChangeShadowProfiles',
    'doShowSnackbar',
  )

  const initialValues = {
    profile: '',
  }

  const validationSchema = yup.object().shape({
    profile: yup.string().uuid().required('Smoke profile is required'),
  })

  const handleSave = useCallback(
    async (profile) => {
      setLoading(true)
      try {
        await doPropertyChangeShadowProfiles({ properties, profile, operation })
        doShowSnackbar(
          `Shadow profile has been ${operation === 'add' ? 'added' : 'removed'}`,
          'success',
        )
        onClose(true)
      } catch (err) {
        const errorMessage = parseApiErrors(err)
        doShowSnackbar(errorMessage, 'error')
      } finally {
        setLoading(false)
      }
    },
    [properties, operation],
  )

  return (
    <>
      <ConfirmationDialog
        open={!!confirmationData}
        title={`Confirm Shadow Profile ${titleize(operation || '')}`}
        message={`Affected properties:\n\n ${properties
          ?.map((property) => property.name)
          .join('\n')}`}
        fullWidth={false}
        onConfirm={() => {
          setConfirmationData(null)
          handleSave(confirmationData)
        }}
        onCancel={() => setConfirmationData(null)}
      />
      <FormDialog
        label={`${titleize(operation || '')} Shadow Profile`}
        open={open}
        onSave={(data) => setConfirmationData(data.profile)}
        onClose={onClose}
        isLoading={loading}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '2rem' }}>
          <Field
            fullWidth
            component={FormikDynamicSelect}
            useValueInRequest={false}
            label="Smoke Profile"
            name="profile"
            formatInputValue={(option) =>
              option ? `${option.modelKey} - ${option.name}` : option
            }
            value={formikProps.values.profile}
            showError={!!formikProps.errors.profile}
            error={formikProps.errors.profile}
            endpoint="smoke_profiles"
            secondaryTextAttr="modelKey"
            filters={{ status: 'AVAILABLE' }}
          />
        </Box>
        <FormikStatePropagator propSetter={setFormikProps} />
      </FormDialog>
    </>
  )
}
