import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'

import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.preSelectedReason]
 * @param {boolean} props.open
 * @param {string[]} [props.eventIds]
 * @param {Function} [props.onClose]
 * @param {boolean} [props.fetchPreGoLiveEvents]
 */
export default function DeleteEventModal({
  open,
  eventIds,
  onClose = () => {},
  preSelectedReason = '',
  fetchPreGoLiveEvents = false,
}) {
  const getDefaultDialogContentText = (numIds) => `Are you sure you want to remove ${
    numIds > 1 ? `${numIds} event(s)` : 'event'
  }?
    This will mark the event(s) as ‘removed.’
    The event will not be billed and will no longer be visible to users in the
    dashboard, but will still be visible in portal.
  `

  const [error, setError] = useState('')
  const [deletedExplanation, setDeletedExplanation] = useState('')
  const [deletedReason, setDeletedReason] = useState(preSelectedReason)
  const [idsToDelete, setIdsToDelete] = useState([])
  const [confirmDisabled, setConfirmDisabled] = useState(true)
  const [dialogContentText, setDialogContentText] = useState('')

  const {
    propertyDetails,
    eventDeletionReasons,
    doFetchPreGoLiveEvents,
    doMarkEventsListAsOutdated,
    doShowSnackbar,
    doDeleteEvent,
  } = useConnect(
    'selectPropertyDetails',
    'selectEventDeletionReasons',
    'doFetchPreGoLiveEvents',
    'doMarkEventsListAsOutdated',
    'doShowSnackbar',
    'doDeleteEvent',
  )

  const clearValues = () => {
    setError('')
    setDeletedExplanation('')
    setIdsToDelete([])
    setDeletedReason(preSelectedReason ?? '')
  }

  const fetchEvents = useCallback(async () => {
    try {
      const response = await doFetchPreGoLiveEvents({
        goLiveDate: propertyDetails?.property?.goLiveDate,
        propertyId: propertyDetails?.property?.id,
      })

      if (response?.error) throw response
      const results = response?.results
      setIdsToDelete(results?.map((event) => event.id))

      if (results?.length) {
        setDialogContentText(getDefaultDialogContentText(results?.length))
        setConfirmDisabled(false)
      } else {
        setDialogContentText(
          `No events found before the go-live date of ${propertyDetails?.property?.goLiveDate}`,
        )
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      setError(parsedError)
    }
  }, [propertyDetails?.property?.id])

  const handleConfirmDelete = async () => {
    setError('')
    setConfirmDisabled(true)
    const payload = idsToDelete?.map((id) => ({
      id,
      deletedReason,
      deletedExplanation,
    }))

    try {
      const response = await doDeleteEvent(payload)
      if (response?.error) throw response
      doMarkEventsListAsOutdated()
      doShowSnackbar('Event(s) removed successfully', 'success')
      setConfirmDisabled(false)
      onClose()
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      setError(parsedError)
      setConfirmDisabled(false)
    }
  }

  useEffect(() => clearValues(), [onClose])

  useEffect(() => {
    if (fetchPreGoLiveEvents) {
      fetchEvents()
    }
    setIdsToDelete(eventIds ?? [])
    setDialogContentText(getDefaultDialogContentText(eventIds?.length))
  }, [eventIds, open])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle color="primary" fontSize="1.5rem">
        Remove Event
      </DialogTitle>
      <DialogContent>
        {error && (
          <Box mb="1rem" display="flex" flexDirection="column" gap=".5rem">
            <Alert key={error} severity="error">
              <Typography fontSize={16}>{error}</Typography>
            </Alert>
          </Box>
        )}
        <DialogContentText>
          <Typography style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
            {dialogContentText}
          </Typography>
        </DialogContentText>
        <StaticSelect
          required
          sx={{ my: 2 }}
          variant="standard"
          label="Reason for removal"
          value={eventDeletionReasons.find((option) => option.id === deletedReason)}
          options={eventDeletionReasons}
          disabled={idsToDelete?.length === 0 && fetchPreGoLiveEvents}
          onChange={(value) => {
            setConfirmDisabled(!value)
            setDeletedReason(value)
          }}
          getOptionLabel={(option) => option?.label}
        />
        {deletedReason && (
          <TextField
            fullWidth
            label="Leave an explanation..."
            multiline
            rows={4}
            value={deletedExplanation}
            onChange={(e) => setDeletedExplanation(e.target.value)}
            disabled={idsToDelete?.length === 0 && fetchPreGoLiveEvents}
            variant="filled"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleConfirmDelete}
          disabled={confirmDisabled}
          color="secondary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
