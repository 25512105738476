import { createCacheBundle } from 'redux-bundler'

import cache from '@common/utils/cache'

const cacheBundle = createCacheBundle({
  cacheFn: cache.set,
  logger: cache.logger,
})

export default cacheBundle
