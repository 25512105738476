const debounce = (fn, wait = 100, immediate = false) => {
  let timeout

  const debounced = (...args) => {
    const later = () => {
      timeout = null
      if (!immediate) fn(...args)
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) fn(...args)
  }
  return debounced
}

export { debounce }
export default debounce
