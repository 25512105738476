import { useCallback, useMemo } from 'react'

import { compose, prop, sortBy, toLower } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { FormControlLabel, Stack } from '@mui/material'

import { Field } from 'formik'
import * as Yup from 'yup'

import { Switch, TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { getApiFetch, merge } from '@common/utils'
import { FormDialog } from '@rest/UI/components'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email('Please enter a valid email').required(),
  role: Yup.string().required().typeError('Role is a required field'),
  smokeAlert: Yup.bool(),
  smokeDigest: Yup.bool(),
  smokePerformanceReport: Yup.bool(),
  noiseAlert: Yup.bool(),
})

const sortByKey = sortBy(compose(toLower, prop('key')))

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {boolean} [props.property.liveSmokeAlerts]
 * @param {string[]} [props.property.expandedFlags]
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} props.instance.name
 * @param {string} props.instance.email
 * @param {boolean} props.instance.outageAlert
 * @param {boolean} props.instance.smokeAlert
 * @param {boolean} props.instance.smokeDigest
 * @param {boolean} props.instance.smokePerformanceReport
 */
export default function PropertyUserForm({ property, open, onClose, instance = {} }) {
  const { id: propertyId, liveSmokeAlerts } = property

  const {
    currentAccountDetails: { roles },
  } = useConnect('selectCurrentAccountDetails')

  const userRoles = useMemo(
    () =>
      sortByKey(roles).map((role) => ({
        id: role.id,
        label: role.name,
        level: role.level,
      })),
    [roles],
  )

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const initialValues = merge(
    {
      id: '',
      name: '',
      email: '',
      role: roles.find((role) => role.key === 'watcher')?.id ?? '',
      outageAlert: instance?.outageAlert ?? false,
      smokeAlert: liveSmokeAlerts,
      smokeDigest: true,
      smokePerformanceReport: true,
      noiseAlert: property.expandedFlags?.includes('NOISE') ?? false,
    },
    instance,
  )

  const onUserSave = useCallback(
    async (data) => {
      const result = await apiFetch(
        `/properties/${propertyId}/memberships/create/`,
        { ...data, phoneNumber: '' },
        { method: 'POST' },
      )
      return result
    },
    [apiFetch],
  )

  return (
    <FormDialog
      data-testid="property_user_dialog"
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      label={`${initialValues?.id ? 'Edit' : 'Add'} User`}
      validationSchema={validationSchema}
      onSave={onUserSave}
      successMessage="Successfully saved user information"
    >
      <Stack spacing={2} mt={0}>
        <Field
          data-testid="field-name"
          component={TextField}
          name="name"
          label="Name"
          autoFocus
        />
        <Field
          data-testid="field-email"
          component={TextField}
          name="email"
          label="Email Address"
        />
        <Field
          data-testid="role-field"
          component={StaticSelect}
          name="role"
          label="Role"
          options={userRoles}
          optionsFilter={(option) => option.level === 50}
          defaultValue={userRoles.find((role) => role.id === initialValues.role)}
        />
        <FormControlLabel
          control={
            <Field
              data-testid="outage-alert-toggle"
              type="checkbox"
              component={Switch}
              name="outageAlert"
            />
          }
          label="Receive outage alerts"
        />
        {liveSmokeAlerts && (
          <FormControlLabel
            control={
              <Field
                data-testid="smoke-alert-toggle"
                type="checkbox"
                component={Switch}
                name="smokeAlert"
              />
            }
            label="Receive real-time smoke alerts"
          />
        )}
        {property.expandedFlags?.includes('NOISE') && (
          <FormControlLabel
            control={
              <Field
                data-testid="noise-alert-toggle"
                type="checkbox"
                component={Switch}
                name="noiseAlert"
              />
            }
            label="Receive real-time noise alerts"
          />
        )}
        <FormControlLabel
          control={
            <Field
              data-testid="smoke-digest-toggle"
              type="checkbox"
              component={Switch}
              name="smokeDigest"
            />
          }
          label="Receive daily smoke event digests"
        />
        <FormControlLabel
          control={
            <Field
              data-testid="smoke-performance-toggle"
              type="checkbox"
              component={Switch}
              name="smokePerformanceReport"
            />
          }
          label="Receive weekly performance summary"
        />
      </Stack>
    </FormDialog>
  )
}
