import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Circle, CopyAll, Edit } from '@mui/icons-material'
import { Box, Button, Chip, Stack, Typography } from '@mui/material'

import { titleize } from 'inflection'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useHashFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import ListPageTitle from '@portal/UI/components/ListPageTitle'

import SmokeProfileForm from './SmokeProfileForm'
import ProfileTab from './Tabs/Profile'
import smokeProfilesUrls from './urls'

export default function SmokeProfileDetail() {
  const {
    isAtLeastAdmin,
    routeParams: { id: profileId },
    hashObject,
    doSmokeProfileFetch,
    doSmokeProfileSchemaFetch,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectRouteParams',
    'selectHashObject',
    'doSmokeProfileFetch',
    'doSmokeProfileSchemaFetch',
    'doShowSnackbar',
  )

  const isSmallScreen = useSmallScreen()

  const [tabValue, setTabValue] = useState(0)

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const [profileToEdit, setProfileToEdit] = useState(null)

  const tabs = [
    { label: 'Profile', component: <ProfileTab data={data} />, hash: 'profile' },
    {
      label: 'Audit Log',
      component: <AuditLogTab showObjectName objectId={profileId} include="property" />,
      hash: 'auditLog',
    },
  ]

  const fetchInitialData = useCallback(async () => {
    setLoading(true)
    try {
      const schemaResponse = await doSmokeProfileSchemaFetch()
      const profileResponse = await doSmokeProfileFetch({ id: profileId })

      if (schemaResponse?.error || profileResponse?.error) {
        throw schemaResponse.error || profileResponse.error
      }

      if (schemaResponse && profileResponse) {
        setData({ schema: schemaResponse, profile: profileResponse })
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setLoading(false)
    }
  }, [])

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  useEffect(() => {
    fetchInitialData()
  }, [])

  const handleEditClick = () => setProfileToEdit(data.profile)

  const StatusChip = useCallback(
    () => (
      <Chip
        icon={<Circle />}
        variant="outlined"
        size="small"
        label={titleize(data.profile.status)}
        color={data.profile.status !== 'ARCHIVED' ? 'success' : 'error'}
      />
    ),
    [data],
  )

  return (
    <>
      <SmokeProfileForm
        open={!!profileToEdit}
        onClose={(success) => {
          setProfileToEdit(null)
          if (success === true) {
            fetchInitialData()
          }
        }}
        profileId={profileToEdit?.id}
      />
      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[
            { label: 'Home', href: homeUrls.home },
            { label: 'Smoke Profiles', href: smokeProfilesUrls.list },
            {
              label: data
                ? `${data.profile.modelKey} - ${data.profile.name}`
                : 'Loading...',
            },
          ]}
        />
        {loading && <Loading />}
        {!loading && data && (
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" color="text.secondary">
                id: {profileId}
              </Typography>
              <CopyAll
                sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
                onClick={() => {
                  navigator.clipboard.writeText(profileId)
                  doShowSnackbar('ID copied to clipboard')
                }}
              />
            </Box>
            {isSmallScreen ? (
              <Box mb={1}>
                <ListPageTitle
                  title={data.profile.name}
                  menuItems={[
                    ...(isAtLeastAdmin
                      ? [{ label: 'Edit', onClick: handleEditClick }]
                      : []),
                  ]}
                />
                <Box display="flex" mt={0.5} gap={0.5}>
                  <StatusChip />
                </Box>
              </Box>
            ) : (
              <Box display="flex" alignItems="start" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  <Typography variant="h3">{data.profile.name}</Typography>
                  <StatusChip />
                </Stack>
                {isAtLeastAdmin && (
                  <Button
                    startIcon={<Edit />}
                    variant="outlined"
                    sx={{ mr: 2 }}
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            )}
            {data.profile.description && (
              <Typography variant="h6" fontSize={isSmallScreen ? 14 : 20}>
                {data.profile.description}
              </Typography>
            )}
            <Typography variant="body" fontSize={isSmallScreen ? 12 : 16}>
              Model: {data.profile.modelKey}
            </Typography>
            <TabComponent
              tabs={tabs}
              externalState={{ value: tabValue, setValue: setTabValue }}
              sx={{ mt: 2 }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}
