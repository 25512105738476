import { useState } from 'react'

import { Box, Typography } from '@mui/material'

import { Breadcrumbs } from '@common/components'
import { homeUrls } from '@portal/pages/Home'

import FinanceActionCard from './FinanceActionCard'
import PropertyInvoiceForm from './PropertyInvoiceForm'
import StripeTransactionForm from './StripeTransactionForm'

export default function FinanceDashboard() {
  const [stripeFormOpen, setStripeFormOpen] = useState(false)
  const [invoiceFormOpen, setInvoiceFormOpen] = useState(false)

  return (
    <>
      <StripeTransactionForm
        open={stripeFormOpen}
        onClose={() => setStripeFormOpen(false)}
      />
      <PropertyInvoiceForm
        open={invoiceFormOpen}
        onClose={() => setInvoiceFormOpen(false)}
      />

      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[
            { label: 'Home', href: homeUrls.home },
            { label: 'Finance Dashboard' },
          ]}
        />
        <Typography variant="h3" mb={4}>
          Finance Dashboard
        </Typography>
        <Box display="flex" gap={2}>
          <FinanceActionCard
            title="Stripe Transactions"
            description={`
              Generates a CSV containing Stripe transactions line-items
              within the supplied date range. You will receive an email
              containing a link to download the file shortly after exporting.
            `}
            actions={[{ title: 'Export', onClick: () => setStripeFormOpen(true) }]}
          />
          <FinanceActionCard
            title="Stripe Billing Preview"
            description={`
              Generates a preview of the Stripe invoice(s) for the selected
              properties within the supplied date range, with the option to export the results as a CSV.
            `}
            actions={[{ title: 'Export', onClick: () => setInvoiceFormOpen(true) }]}
          />
        </Box>
      </Box>
    </>
  )
}
