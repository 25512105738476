import { memo } from 'react'

import { Grid2 } from '@mui/material'

import EntityCard from '../EntityCard'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.groupedByEntityData
 * @param {Object} props.selectedMetrics
 * @param {bool} props.hideNonLiveProperties
 */
const GroupedByEntityView = memo(
  ({ groupedByEntityData, selectedMetrics, hideNonLiveProperties }) =>
    Object.entries(groupedByEntityData).map(([key, data]) => {
      const shouldFilterByLiveParam =
        hideNonLiveProperties && data.length && 'live' in data[0]
      if (shouldFilterByLiveParam) {
        return data[0].live ? (
          <Grid2 key={key} size={{ xll: 6, sm: 12 }}>
            <EntityCard dataKey={key} selectedMetrics={selectedMetrics} data={data} />
          </Grid2>
        ) : null
      }

      return (
        <Grid2 key={key} size={{ xll: 6, sm: 12 }}>
          <EntityCard dataKey={key} selectedMetrics={selectedMetrics} data={data} />
        </Grid2>
      )
    }),
)

export default GroupedByEntityView
