import { createListBundle, defaultInitialState, parseApiErrors } from '@common/utils'

import propertyUrls from '../urls'

const entityName = 'propertyDevice'

const propertyDeviceListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const propertyId = params?.id || store.selectRouteParams().id
    return apiFetch(
      '/devices/',
      {
        ...params,
        property: propertyId,
      },
      { cancelationPrefix: entityName },
    )
  },
  urlTest: (_, pattern) => pattern === propertyUrls.entity,
  initialState: {
    ...defaultInitialState,
    ordering: ['zone__name', 'asc'],
  },
  searchTest: () => true,
})

export default {
  ...propertyDeviceListBundle,
  doPropertyDeviceListExport:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectPropertyDeviceListApiParams()
      const propertyId = store.selectRouteParams().id
      const { count = 9999 } = store.selectPropertyDeviceList()
      try {
        const response = await apiFetch(
          '/devices/',
          {
            ...apiParams,
            property: propertyId,
            pageSize: count,
          },
          { cancelationPrefix: entityName },
        )
        if (response.error) throw parseApiErrors(response)
        return response.results
      } catch (error) {
        store.doShowSnackbar('Unable to export devices', 'error')
      }
      return []
    },
}
