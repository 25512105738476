import { useEffect, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'

import { debounce, getApiFetch, isAbortError, parseApiErrors } from '@common/utils'

const debouncedSearch = debounce((fn, value) => fn(value), 500)

/**
 * FreeSoloDynamicSelect is a select component that fetches and renders a dynamic list of data and allows freesolo input.
 *  This component is designed to be used with formik.
 * @param {Object} props - The props for the component.
 * @param {Object} props.form - form supplied by formik.
 * @param {Function} props.form.setFieldValue
 * @param {Object} props.form.errors
 * @param {boolean} props.form.touched
 * @param {string} props.label - The label to display in the input component (TextField).
 * @param {string} props.field - Name of the formik field.
 * @param {string} props.altField - The name of the formik field to store the freesolo value of the input. This keeps the id of the zone and the new zone name separate.
 * @param {string} props.endpoint - The name of the endpoint as a string. ("accounts" for /accounts/, "properties" for /properties/).
 * @param {Object} props.filters - filterValue pairs that correspond to filters on the backend for that entity.
 * @param {string} props.primaryTextAttr - The key of the attribute in the options that will be displayed as the primary text .
 * @param {string} props.secondaryTextAttr - The key of the attribute in the options that will be displayed as the secondary text.
 * @param {boolean} props.disabled - Whether or not the input is disabled.
 * @param {Object} [props.sx] - Custom styles to apply to input.
 */
export default function FreeSoloDynamicSelect({
  form = '',
  label,
  field = '',
  altField,
  initialValue = undefined,
  endpoint,
  filters = {},
  primaryTextAttr = 'name',
  secondaryTextAttr = undefined,
  disabled = false,
  sx = {},
}) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentInputValue, setCurrentInputValue] = useState('')

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const isString = (val) => typeof val === 'string'

  const callEndpoint = (params) => {
    const url = `/${endpoint}/`
    return apiFetch(
      url,
      { pageSize: 50, ...params },
      { method: 'GET', cancelationPrefix: 'free_solo_dynamic_select' },
    )
  }

  const handleChange = (event, value) => {
    if (!event || ['blur', 'keydown'].includes(event?.type)) return
    const parsedValue = value?.id || value
    form?.setFieldValue(field?.name, parsedValue)
    form?.setFieldValue(altField, '')
  }

  const getValue = () => {
    const value = field?.value ?? ''
    const valueOption = options?.find((option) => option.id === value)
    return valueOption || value
  }

  const getInputValue = () => {
    const valueOption = options?.find((option) => option.id === field?.value)
    return valueOption?.name || currentInputValue
  }

  const fetchOptions = async (text) => {
    setLoading(true)
    try {
      const res = await callEndpoint({ ...filters, search: text, active: true })
      if (res?.results) setOptions(res.results)
      if (res?.id) setOptions([res])
    } catch (err) {
      if (!isAbortError(err)) {
        const parsedError = parseApiErrors(err)
        doShowSnackbar(parsedError, 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = async (event, value) => {
    if (!event || event?.type === 'click') return
    form?.setFieldValue(altField, value)
    form?.setFieldValue(field?.name, '')
    setCurrentInputValue(value)

    debouncedSearch(fetchOptions, value)
  }

  const handleDropDown = async () => fetchOptions(null)

  const getOptionData = (option) =>
    isString(option) ? options?.find((opt) => opt.id === option) : option

  const getOptionLabel = (option) => {
    const optData = getOptionData(option)
    return optData?.[primaryTextAttr] ?? ''
  }

  const error = form?.errors?.[field?.name] && form?.touched?.[field?.name]
  const helperText =
    form?.errors?.[field?.name] &&
    form?.touched?.[field?.name] &&
    form?.errors?.[field?.name]

  useEffect(() => {
    if (options?.length === 0 && initialValue) {
      fetchOptions(null)
    }
  }, [initialValue])

  return (
    <Autocomplete
      freeSolo
      forcePopupIcon
      id="autocomplete"
      disabled={disabled}
      value={getValue()}
      inputValue={getInputValue()}
      onOpen={handleDropDown}
      getOptionLabel={getOptionLabel}
      options={options ?? []}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          variant="standard"
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{ m: 0 }}
        />
      )}
      renderOption={(optProps, option) => (
        <li {...optProps} key={option.id}>
          <Box>
            <Typography variant="h6">{option[primaryTextAttr]}</Typography>
            {secondaryTextAttr && (
              <Typography
                variant="body2"
                color="text.secondary"
                textTransform="uppercase"
                fontSize="0.7rem"
              >
                {option[secondaryTextAttr]}
              </Typography>
            )}
          </Box>
        </li>
      )}
      sx={sx}
    />
  )
}
