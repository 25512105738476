export const hoursOfTheDay = [
  '12:00 AM',
  '1:00 AM',
  '2:00 AM',
  '3:00 AM',
  '4:00 AM',
  '5:00 AM',
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
  '10:00 PM',
  '11:00 PM',
]

export const thresholdSensitivityValues = [
  { value: 15, sensitivity: 'Very High Sensitivity', label: '15 NRS' },
  { value: 20, sensitivity: 'High Sensitivity', label: '20 NRS' },
  { value: 25, sensitivity: 'High Sensitivity', label: '25 NRS' },
  { value: 30, sensitivity: 'High Sensitivity', label: '30 NRS' },
  { value: 35, sensitivity: 'Medium Sensitivity', label: '35 NRS' },
  { value: 40, sensitivity: 'Medium Sensitivity', label: '40 NRS' },
  { value: 45, sensitivity: 'Medium Sensitivity', label: '45 NRS' },
  { value: 50, sensitivity: 'Medium Sensitivity', label: '50 NRS' },
  { value: 55, sensitivity: 'Medium Sensitivity', label: '55 NRS' },
  { value: 60, sensitivity: 'Low Sensitivity', label: '60 NRS' },
  { value: 65, sensitivity: 'Low Sensitivity', label: '65 NRS' },
  { value: 70, sensitivity: 'Low Sensitivity', label: '70 NRS' },
  { value: 75, sensitivity: 'Low Sensitivity', label: '75 NRS' },
  { value: 80, sensitivity: 'Low Sensitivity', label: '80 NRS' },
  { value: 85, sensitivity: 'Low Sensitivity', label: '85 NRS' },
  { value: 90, sensitivity: 'Very Low Sensitivity', label: '90 NRS' },
  { value: 95, sensitivity: 'Very Low Sensitivity', label: '95 NRS' },
]
