import React, { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { ErrorOutlineRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { StaticSelect } from '@common/components/Selects'
import { getApiFetch, parseApiErrors } from '@common/utils'

import { downloadFile, dummyRow, getTemplate } from './utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {'org'|'property'|'unit'} props.level
 * @param {Function} props.onImport
 * @param {Function} props.onClose
 * @param {React.Element} [props.startIcon]
 * @param {boolean} [props.fullWidth]
 */
export default function CSVPicker({
  level,
  startIcon = null,
  onImport,
  onClose,
  fullWidth = false,
}) {
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const [file, setFile] = useState(null)
  const [modifiedFile, setModifiedFile] = useState(null)

  const [rows, setRows] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [errors, setErrors] = useState({})
  const [nonFieldErrors, setNonFieldErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const [selectedProperty, setSelectedProperty] = useState(null)
  const [propertyOptions, setPropertyOptions] = useState(null)

  const hasErrors = !isEmpty(errors) || !isEmpty(nonFieldErrors)

  const fileReader = new FileReader()
  const { currentAccount, doShowSnackbar } = useConnect(
    'selectCurrentAccount',
    'doShowSnackbar',
  )

  const fetchProperties = useCallback(async () => {
    try {
      const response = await apiFetch(`/properties/`, {
        account: currentAccount,
        pageSize: 999,
      })
      const options = response.results.map((prop) => ({
        label: prop.name,
        value: prop.id,
      }))
      setPropertyOptions(options)

      if (options.length === 1) {
        setSelectedProperty(options[0])
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [currentAccount])

  useEffect(() => {
    if (level === 'org' && dialogOpen) {
      fetchProperties()
    }
  }, [dialogOpen])

  const processCsv = (string) => {
    const headerRow = string.slice(0, string.indexOf('\n')).trim().split(',')
    const dataRows = string.slice(string.indexOf('\n') + 1).split('\n')

    const processedRows = dataRows.filter(Boolean).map((i) => {
      const rowValues = i.trim().split(',')
      const obj = headerRow.reduce((acc, header, index) => {
        acc[header] = rowValues[index]
        return acc
      }, {})
      return obj
    })

    setRows(processedRows)
  }

  const readFile = () => {
    if (file) {
      fileReader.onload = (e) => {
        const fileContent = e.target.result
        const fileRows = fileContent.split('\n')

        // remove example data from file
        const dummyRowIndex = fileRows.findIndex(
          (row) => row === `${Object.values(dummyRow).join(',')},`,
        )
        if (dummyRowIndex !== -1) {
          fileRows.splice(dummyRowIndex, 1)
        }
        const modifiedContent = fileRows.length > 1 ? fileRows.join('\n') : ''

        const blob = new Blob([modifiedContent], { type: 'text/csv' })
        setModifiedFile(blob)

        processCsv(modifiedContent)
      }
      fileReader.readAsText(file)
    }
  }

  const handleOnChange = (e) => {
    setAnchorEl(null)
    setFile(e.target.files[0])
  }
  const handleClose = () => {
    setDialogOpen(false)
    setAnchorEl(null)
    setFile(null)
    setRows([])
  }

  const processErrors = (payload) =>
    payload.file?.reduce((acc, err) => {
      const rowRegex = /Row (\d+):/
      const errorRegex = /:\s*(.*)/

      const rowMatch = err.match(rowRegex)
      const errorMatch = err.match(errorRegex)
      const rowNumber = rowMatch[1] - 2
      if (acc[rowNumber]) {
        acc[rowNumber].push(errorMatch[1])
      } else {
        acc[rowNumber] = [errorMatch[1]]
      }
      return acc
    }, {}) ?? {}

  const handleOnImport = async () => {
    try {
      setLoading(true)
      const res = await onImport({
        content: rows,
        property: selectedProperty?.value,
        file: modifiedFile,
      })

      if (res.error) {
        const { nonFieldErrors: generalErrors } = res.error.response

        if (generalErrors?.length) {
          setNonFieldErrors(generalErrors)
        } else {
          const rowErrors = processErrors(res.error.response)
          if (Object.keys(rowErrors).length > 0) {
            setErrors(rowErrors)
          } else {
            const parsedError = parseApiErrors(res.error?.response)
            doShowSnackbar(parsedError, 'error')
          }
        }
      } else {
        setDialogOpen(false)
        doShowSnackbar('Reservations have been successfully uploaded')
        onClose(true)
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setLoading(false)
    }
  }

  const primaryTypographyProps = {
    align: 'center',
    variant: 'body2',
    sx: { textTransform: 'uppercase' },
  }

  const headerKeys = rows.length ? Object.keys({ ...rows[0] }) : []

  const generateKey = (prefix) =>
    `${prefix}_${Number(new Date().getTime()) / Math.random()}`

  const renderCell = (val, idx, errorMessage) => {
    const validatedValue = val === '' ? errorMessage : val
    return (
      <TableCell key={generateKey(val)} align={idx ? 'right' : 'left'}>
        <Typography
          variant="body2"
          color={errorMessage ? 'error' : 'text.secondary'}
          fontSize={12}
          fontWeight={errorMessage ? 'bold' : 'regular'}
        >
          {validatedValue}
        </Typography>
      </TableCell>
    )
  }

  const toolTipStyles = { sx: { '& .MuiTooltip-tooltip': { width: '150px' } } }

  useEffect(() => {
    setErrors({})
    setNonFieldErrors([])
    readFile()
    if (file) setDialogOpen(true)
  }, [file])

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h4" color="primary">
            {file?.name?.toUpperCase()}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ fontSize: '10px' }}>
          {level === 'org' && (
            <StaticSelect
              options={propertyOptions ?? []}
              value={selectedProperty}
              label="Select Property"
              size="small"
              sx={{ my: 2 }}
              disabled={rows.length === 0}
              onChange={(property) => {
                setErrors({})
                setNonFieldErrors([])
                setSelectedProperty(property)
              }}
            />
          )}
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headerKeys.map((key, idx) => (
                  <TableCell key={key} align={idx ? 'right' : 'left'}>
                    {key}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIdx) => {
                const errorMessage = errors?.[rowIdx]?.join(`\n`)

                return (
                  <React.Fragment key={generateKey(row.name)}>
                    <TableRow>
                      {Object.values(row).map((val, idx) =>
                        renderCell(val, idx, errorMessage),
                      )}
                    </TableRow>
                    {errorMessage && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Stack direction="row" alignItems="center">
                            <ErrorOutlineRounded color="error" sx={{ fontSize: 18 }} />
                            <Typography variant="caption" color="error" ml={1}>
                              {errorMessage}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
          {rows.length === 0 && (
            <Box display="flex" justifyContent="center">
              <Typography variant="body2" color="error">
                CSV file is empty!
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {hasErrors && (
            <Box ml={4} mb={2} flexGrow={1}>
              {nonFieldErrors?.length ? (
                nonFieldErrors.map((err) => (
                  <Typography key={err} variant="body2" color="error">
                    {err}
                  </Typography>
                ))
              ) : (
                <>
                  <Typography variant="body2" color="error">
                    {file?.name} contains errors in the fields indicated above.
                  </Typography>
                  <Typography variant="body2" color="error">
                    Please correct these errors and try again.
                  </Typography>
                </>
              )}
            </Box>
          )}

          <Box display="flex" gap={3}>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              disabled={
                hasErrors ||
                loading ||
                (level === 'org' && !selectedProperty) ||
                rows.length === 0
              }
              onClick={handleOnImport}
              autoFocus
            >
              Import
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Button
        fullWidth={fullWidth}
        startIcon={startIcon}
        variant="outlined"
        component="label"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Reservation Bulk Upload
      </Button>
      <Popover
        disableScrollLock
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ padding: 0 }}>
          <Tooltip
            title="Upload a CSV file containing reservations in the format provided below."
            PopperProps={toolTipStyles}
            enterDelay={800}
            placement="left"
          >
            <ListItem sx={{ padding: 0 }}>
              <ListItemButton component="label">
                <ListItemText
                  primary="Upload CSV"
                  primaryTypographyProps={primaryTypographyProps}
                />
                <input hidden type="file" accept=".csv" onChange={handleOnChange} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
          <Divider variant="middle" />
          <Tooltip
            title="Download a blank CSV template."
            PopperProps={toolTipStyles}
            enterDelay={800}
            placement="left"
          >
            <ListItem sx={{ padding: 0 }}>
              <ListItemButton
                onClick={() => {
                  downloadFile(getTemplate(dummyRow))
                  setAnchorEl(null)
                }}
              >
                <ListItemText
                  primary="Download Template"
                  primaryTypographyProps={primaryTypographyProps}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
      </Popover>
    </>
  )
}
