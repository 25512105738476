import { createEntityBundle } from '@common/utils'

const entityName = 'smoke_profile_ensembles'

const smokeProfileEnsembleBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'make_default',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload}/make_default/`, null, {
          method: 'PUT',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default smokeProfileEnsembleBundle
