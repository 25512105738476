import { Grid2, Link, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {string} [props.labelIcon]
 * @param {string|number|string[]} [props.value]
 * @param {string} [props.linkTo]
 * @param {Function} [props.renderValue]
 * @param {Object} [props.sx]
 * @param {Object} [props.titleSx]
 */
export default function DetailItem({
  label,
  labelIcon = undefined,
  value = '',
  renderValue = undefined,
  linkTo = '',
  titleSx = {},
  sx = {},
}) {
  const isSmallScreen = useSmallScreen()

  return (
    <Grid2>
      <Typography
        variant="body2"
        color="text.secondary"
        textTransform="uppercase"
        sx={{ fontSize: isSmallScreen ? 10 : 14, ...titleSx }}
        display="flex"
        alignItems="center"
        gap={0.5}
      >
        {label} {labelIcon && labelIcon}
      </Typography>
      {!!renderValue && renderValue(value)}
      {!renderValue && (
        <Typography
          variant="h6"
          whiteSpace="pre-line"
          sx={{ fontSize: isSmallScreen ? 14 : 20, ...sx }}
        >
          {linkTo?.length ? (
            <Link href={linkTo}>{value || 'N/A'}</Link>
          ) : (
            value || 'N/A'
          )}
        </Typography>
      )}
    </Grid2>
  )
}
