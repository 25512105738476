import { TextField } from '@mui/material'

import { StaticSelect } from '@common/components/Selects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.isNew]
 * @param {string} [props.placeholder]
 * @param {string} [props.error]
 * @param {boolean} [props.preallocateHelperTextSpace]
 * @param {boolean} [props.isSmallScreen]
 */
export default function StaticSelectCell({
  isNew = false,
  error = '',
  placeholder = '',
  preallocateHelperTextSpace = false,
  isSmallScreen = false,
  ...rest
}) {
  const errorMessage = error.trim()

  return (
    <StaticSelect
      sx={{ width: '100%' }}
      componentsProps={{ paper: { sx: { width: '150%' } } }}
      clearOnBlur
      clearOnEscape
      disableClearable
      hideLabel={rest?.hideLabel}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={rest?.hideLabel ? null : rest?.label}
          placeholder={placeholder}
          helperText={preallocateHelperTextSpace ? errorMessage || ' ' : errorMessage}
          error={errorMessage.length > 0}
          sx={
            isNew && !errorMessage.length && !isSmallScreen
              ? { border: '1px solid black', borderRadius: '4px' }
              : {}
          }
        />
      )}
      {...rest}
    />
  )
}
