import { useCallback, useEffect, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { TextField } from '@common/components/Form'
import { ThresholdModalContent } from '@common/components/thresholdManagement'
import { getApiFetch } from '@common/utils'

import { formatRule } from '../utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.propertyId]
 * @param {Function} props.onClose
 * @param {Function} props.setStep
 * @param {Object} props.profileData
 * @param {number} [props.profileData.startTime]
 * @param {number} [props.profileData.endTime]
 * @param {number} [props.profileData.nrsIndoorValue]
 * @param {number} [props.profileData.nrsOutdoorValue]
 */
export default function Step3({
  onClose,
  setStep,
  profileData,
  propertyId = undefined,
}) {
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const { doThresholdProfileSave, doThresholdProfileAssign, doShowSnackbar } =
    useConnect('doThresholdProfileSave', 'doThresholdProfileAssign', 'doShowSnackbar')
  const [indoorThresholdSettings, setIndoorThresholdSettings] = useState([
    {
      startTime: profileData?.startTime,
      endTime: profileData?.endTime,
      highValue: profileData?.nrsIndoorValue,
      deviceStyle: 'INDOOR',
      name: 'Quiet Hours',
    },
  ])
  const [outdoorThresholdSettings, setOutdoorThresholdSettings] = useState([
    {
      startTime: profileData?.startTime,
      endTime: profileData?.endTime,
      highValue: profileData?.nrsOutdoorValue,
      deviceStyle: 'OUTDOOR',
      name: 'Quiet Hours',
    },
  ])
  const [isEditing, setIsEditing] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const [settingData, setSettingData] = useState({})
  const [assignToProperty, setAssignToProperty] = useState(false)
  const [propertyData, setPropertyData] = useState(null)

  const fetchPropertyInfo = useCallback(async () => {
    try {
      const response = await apiFetch(`/properties/${propertyId}/`, null, {
        cancelationPrefix: 'thresholds_editor',
      })
      setPropertyData(response)
    } catch (err) {
      setPropertyData('error')
    }
  }, [propertyId])

  useEffect(() => {
    if (propertyId) {
      fetchPropertyInfo()
    } else {
      setPropertyData(null)
    }
  }, [propertyId])

  const initialValues = {
    name: '',
    description: '',
    property: propertyId,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s.-]+$/,
        'Only alphanumeric characters, dashes, spaces, and commas are allowed.',
      )
      .required('Please enter a name'),
    description: Yup.string(),
  })

  const cancelEditSetting = () => {
    setIsEditing(false)
    setEditingId(null)
    setSettingData(null)
  }

  const handleAddSetting = (startTime, deviceStyle) => {
    setIsEditing(true)
    setEditingId('add_new')
    setSettingData({
      id: Math.random(),
      startTime,
      endTime: startTime === 23 ? 0 : startTime + 1,
      highValue: profileData?.nrsOutdoorValue,
      deviceStyle,
      name: 'Quiet Hours',
    })
  }

  const handleEditSetting = (setting) => {
    setIsEditing(true)
    setEditingId(setting.id)
    setSettingData(setting)
  }

  const handleDeleteSetting = (setting) => {
    const newThreshArr =
      setting.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const deleteIndex = newThreshArr.findIndex((obj) => obj.id === setting.id)
    if (deleteIndex !== -1) {
      newThreshArr.splice(deleteIndex, 1)
    }

    const setFn =
      setting.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const confirmEditSetting = (updatedThreshold) => {
    const newThreshArr =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? [...indoorThresholdSettings]
        : [...outdoorThresholdSettings]

    const updateIndex = newThreshArr.findIndex((obj) => obj.id === updatedThreshold.id)
    if (updateIndex !== -1) {
      newThreshArr[updateIndex] = updatedThreshold
    } else {
      newThreshArr.push(updatedThreshold)
    }

    const setFn =
      updatedThreshold.deviceStyle === 'INDOOR'
        ? setIndoorThresholdSettings
        : setOutdoorThresholdSettings

    setFn(newThreshArr)
    cancelEditSetting()
  }

  const handleSubmit = async (values, helpers) => {
    const formattedSettings = [
      ...indoorThresholdSettings,
      // ...outdoorThresholdSettings, // do not create outdoor rules for now
    ].map((setting) => formatRule(setting))

    const payload = {
      name: values.name,
      description: values.description || '',
      dataType: 'NOISE',
      rules: formattedSettings,
      property: values.property,
    }

    try {
      const response = await doThresholdProfileSave(payload)
      if (response.error) {
        helpers?.setErrors(response.error?.response)
        throw response.error
      }

      if (assignToProperty) {
        const assignResponse = await doThresholdProfileAssign({
          thresholdProfile: response.id,
          property: propertyId,
        })
        if (assignResponse.error) {
          doShowSnackbar(`Unable to assign profile`, 'error')
        }
      }

      doShowSnackbar(`${values.name} Created`, 'success')
      setStep('one')
      onClose(true)
    } catch (error) {
      doShowSnackbar(`Unable to create ${values.name}`, 'error')
      captureException(error)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, setFieldValue }) => (
        <Form>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack mt={2}>
              <Field
                component={TextField}
                name="name"
                label="Profile Name"
                size="small"
              />
              <Field
                component={TextField}
                name="description"
                label="Description (optional)"
                size="small"
                margin="normal"
              />
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="end">
              <Stack direction="row" sx={{ height: '36px', mt: 1 }} spacing={2}>
                <Button size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="cancel"
                  sx={{ ':hover': { bgcolor: 'grey.400' } }}
                  onClick={() => setStep('two')}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  disabled={!isValid || !dirty}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <FormControlLabel
              label="Assign to property"
              labelPlacement="end"
              sx={{ mt: 1 }}
              control={
                <Checkbox
                  checked={assignToProperty}
                  size="small"
                  onChange={() => setAssignToProperty((prev) => !prev)}
                />
              }
            />

            <Alert
              severity="warning"
              sx={{
                visibility:
                  assignToProperty && propertyData?.effectiveNoiseThresholdProfile
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <Typography variant="body2" whiteSpace="pre-line">
                Property already has a threshold:{' '}
                <Box display="inline" fontWeight="fontWeightBold">
                  {propertyData?.effectiveNoiseThresholdProfileName}
                </Box>
              </Typography>
            </Alert>
          </Box>
          <ThresholdModalContent
            indoorThresholdSettings={indoorThresholdSettings}
            setIndoorThresholdSettings={setIndoorThresholdSettings}
            outdoorThresholdSettings={outdoorThresholdSettings}
            setOutdoorThresholdSettings={setOutdoorThresholdSettings}
            handleAdd={handleAddSetting}
            handleEdit={handleEditSetting}
            handleDelete={
              indoorThresholdSettings.length > 1 ? handleDeleteSetting : null
            }
            cancelEdit={cancelEditSetting}
            confirmEdit={confirmEditSetting}
            isEditing={isEditing}
            editingId={editingId}
            settingData={settingData}
            setFieldValue={setFieldValue}
          />
        </Form>
      )}
    </Formik>
  )
}
