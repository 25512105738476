import { useMemo } from 'react'

import { Chip } from '@mui/material'

import { titleize } from 'inflection'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.status
 * @param {string} [props.size]
 */
export default function InvoiceStatusChip({ status, size = undefined }) {
  const label = useMemo(() => titleize(status.toLowerCase()), [status])

  const color = useMemo(() => {
    switch (status) {
      case 'PAID':
        return 'success.main'
      case 'PAST_DUE':
        return 'error.main'
      case 'WAITING_FOR_PAYMENT':
        return '#cbf5fd'
      case 'VOIDED':
      default:
        return 'grey.500'
    }
  }, [status])

  const textColor = useMemo(
    () => (status === 'WAITING_FOR_PAYMENT' ? 'grey.800' : 'white'),
    [status],
  )

  return (
    <Chip
      size={size ?? 'small'}
      label={label}
      sx={{
        backgroundColor: color,
        '& .MuiChip-label': { color: textColor },
      }}
    />
  )
}
