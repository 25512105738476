import { useState } from 'react'

import { Group, Layer, Stage } from 'react-konva'

import AddThresholdRect from './AddThresholdRect'
import { CANVAS_HEIGHT, CANVAS_WIDTH, colors } from './constants'
import ThresholdRect from './ThresholdRect'
import Timeline from './Timeline'
import {
  getOpenHours,
  hourToCanvasGridLine,
  setCursorStyle,
  toCircularBuffer,
  wrapsMidnight,
} from './utils'
import WrappedThresholdRect from './WrappedThresholdRect'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {'INDOOR'|'OUTDOOR'} props.deviceStyle
 * @param {Function} props.onAddSetting
 * @param {Function} props.onEditSetting
 * @param {Function} [props.onDeleteSetting]
 * @param {Function} props.onResizeSetting
 * @param {Function} props.onDragSetting
 * @param {boolean} props.isEditing
 * @param {number|string} [props.editingId]
 * @param {Object[]} props.thresholdSettings
 * @param {number} props.thresholdSettings[].id
 * @param {number} props.thresholdSettings[].highValue
 * @param {number} props.thresholdSettings[].startTime
 * @param {number} props.thresholdSettings[].endTime
 * @param {string} props.thresholdSettings[].deviceStyle
 * @param {Function} props.setFieldValue
 * @param {number} [props.editorWidth]
 */
export default function ThresholdEditor({
  deviceStyle,
  onAddSetting,
  onDeleteSetting = undefined,
  onResizeSetting,
  onDragSetting,
  onEditSetting,
  isEditing,
  editingId = null,
  thresholdSettings,
  setFieldValue,
  editorWidth = undefined,
}) {
  const openHours = getOpenHours(thresholdSettings)
  const [hoveredHour, setHoveredHour] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const thresholdSettingsCircularBuffer = toCircularBuffer(thresholdSettings)

  return (
    <Stage width={editorWidth ?? CANVAS_WIDTH} height={CANVAS_HEIGHT}>
      <Layer>
        <Timeline width={editorWidth} />
      </Layer>
      <Layer>
        <Group>
          {thresholdSettingsCircularBuffer.map((setting) => {
            const Component = wrapsMidnight(setting.startTime, setting.endTime)
              ? WrappedThresholdRect
              : ThresholdRect
            return (
              <Component
                id={setting.id}
                editorWidth={editorWidth}
                NRS={setting.highValue}
                key={setting.startTime}
                start={setting.startTime}
                end={setting.endTime}
                color={colors[setting.highValue]}
                next={setting.next}
                label={setting.name}
                previous={setting.previous}
                onDelete={
                  onDeleteSetting
                    ? () => {
                        onDeleteSetting(setting)
                        setFieldValue('setting', null)
                      }
                    : null
                }
                onEdit={() => onEditSetting(setting)}
                onResizeStart={() => setIsDragging(true)}
                onResizeEnd={(startTime, endTime) => {
                  setIsDragging(false)
                  onResizeSetting({
                    id: setting.id,
                    startTime,
                    endTime,
                    deviceStyle: setting.deviceStyle,
                  })
                }}
                onDragStart={() => {
                  setIsDragging(true)
                }}
                onDragEnd={(startTime, endTime) => {
                  onDragSetting({
                    id: setting.id,
                    startTime,
                    endTime,
                    deviceStyle: setting.deviceStyle,
                  })
                  setIsDragging(false)
                }}
                isDragging={isDragging}
                isEditing={isEditing}
                editingId={editingId}
              />
            )
          })}
          {onAddSetting &&
            !(isDragging || isEditing) &&
            openHours.map((startTime) => (
              <AddThresholdRect
                editorWidth={editorWidth}
                y={hourToCanvasGridLine(startTime)}
                key={startTime}
                onClick={() => onAddSetting(startTime, deviceStyle)}
                visible={startTime === hoveredHour}
                onMouseEnter={(e) => {
                  setHoveredHour(startTime)
                  setCursorStyle(e, 'pointer')
                }}
                onMouseLeave={(e) => {
                  setHoveredHour(null)
                  setCursorStyle(e, 'default')
                }}
              />
            ))}
        </Group>
      </Layer>
    </Stage>
  )
}
