import { Box, Checkbox, Typography } from '@mui/material'

import { formatSubitemCount } from '@rest/Utils/formatMenuStrings'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.id]
 * @param {string} props.name
 * @param {number} props.childrenCount
 * @param {string[]} props.checkedGroups
 * @param {Function} props.onClick
 */
export default function UnitGroupItem({
  id = undefined,
  name,
  childrenCount,
  checkedGroups,
  onClick,
}) {
  return (
    <Box display="flex" key={id ?? Math.random()} alignItems="center">
      <Checkbox
        disableRipple
        edge="start"
        size="small"
        onClick={() => onClick(id)}
        indeterminate={!id}
        disabled={!id}
        checked={checkedGroups.includes(id)}
      />
      <Typography>
        Unit Group - {name} ({formatSubitemCount('Unit', childrenCount)})
      </Typography>
    </Box>
  )
}
