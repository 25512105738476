import { clamp } from 'ramda'

import {
  CANVAS_HEIGHT,
  HANDLE_HEIGHT,
  RECT_MARGIN,
  TIMELINE_GRID_END,
  TIMELINE_GRID_ORIGIN,
  VERTICAL_GRID_SPACE,
} from './constants'

/**
 *
 * @param {number} hour A positive integer
 * @returns The formatted hour with the appropriate meridiem
 */
export const formatHour = (hour) => {
  if (hour % 24 === 0) return '12 AM'
  if (hour % 24 === 12) return '12 PM'
  if (hour % 24 > 12) return `${hour - 12} PM`
  return `${hour % 24} AM`
}

export const getDurationLabel = (start, end) =>
  `${formatHour(start)} - ${formatHour(end)}`

/**
 *
 * @param {number} start Hour of the beginning of the setting
 * @param {number} end Hour of the end of the setting
 * @returns true if the setting begins on one day and ends the following
 */
export const wrapsMidnight = (start, end) => end <= start && end !== 0

/**
 * @param {number} hour
 * @returns vertical coordinate of grid line with corresponding hour mark
 */
export const hourToCanvasGridLine = (hour) =>
  clamp(
    TIMELINE_GRID_ORIGIN,
    TIMELINE_GRID_END,
    TIMELINE_GRID_ORIGIN + hour * VERTICAL_GRID_SPACE,
  )

/**
 *
 * @param {number} y vertical coordinate on timeline
 * @returns the vertical coordinate of the nearest grid line (hour mark)
 */
export const roundToNearestGridLine = (y) =>
  clamp(
    TIMELINE_GRID_ORIGIN,
    TIMELINE_GRID_END,
    TIMELINE_GRID_ORIGIN + VERTICAL_GRID_SPACE * Math.round(y / VERTICAL_GRID_SPACE),
  )
/**
 *
 * @param {number} start Hour of the beginning of the setting
 * @param {number} end Hour of the end of the setting
 * @returns {Object} {top, bottom} location on canvas
 */
export const settingToRectBounds = (start, end) => ({
  top: hourToCanvasGridLine(start),
  bottom: end === 0 ? TIMELINE_GRID_END : hourToCanvasGridLine(end),
})

/**
 * @description used to update position of Rect's after drag/resize
 * @param {number} y vertical coordinate on timeline
 * @returns the vertical coordinate of the nearest grid line + margin
 */
export const roundToNearestRectTop = (y) => roundToNearestGridLine(y) + RECT_MARGIN

/**
 *
 * @param {number} y vertical coordinate on timeline
 * @returns the hour label of the nearest grid line
 */
export const roundToNearestHour = (y) =>
  Math.round((roundToNearestGridLine(y) - TIMELINE_GRID_ORIGIN) / VERTICAL_GRID_SPACE)

/**
 *
 * @param {number} y Vertical coordinate of target
 * @param {bool} wraps Whether the target setting wraps
 * @param {number} previous End hour of previous chronological setting
 * @param {number} next Start hour of next chronological setting
 * @returns Boolean - whether the target collides with next/previous setting or edge of canvas
 */
export const resizeCollisionDetected = (
  y,
  currentStart,
  currentEnd,
  edge,
  previous,
  next,
) => {
  const collidesWithTopOfCanvas = y < VERTICAL_GRID_SPACE
  const collidesWithPreviousSetting =
    edge === 'top' &&
    previous &&
    currentStart >= previous &&
    y < VERTICAL_GRID_SPACE * previous
  const collidesWithBottomOfCanvas = y >= CANVAS_HEIGHT - VERTICAL_GRID_SPACE
  const collidesWithNextSetting =
    edge === 'bottom' && next && currentEnd <= next && y > VERTICAL_GRID_SPACE * next

  return (
    collidesWithTopOfCanvas ||
    collidesWithPreviousSetting ||
    collidesWithBottomOfCanvas ||
    collidesWithNextSetting
  )
}

/**
 *
 * @param {{x,y}} pos coordinates of the top-left corner of the dragged node
 * @param {number} currentStart start hour of setting
 * @param {number} currentEnd end hour of setting
 * @param {node} dragHandleRef ref of the drag handle
 * @param {node} otherDragHandleRef ref of the other (non-dragged) drag handle
 * @param {node} rectRef ref of the setting rectangle
 * @param {node} labelsRef ref of the labels
 * @param {string} draggedEdge the edge of the setting being dragged
 * @param {number} previous the hour of the previous chronological setting
 * @param {number} next the hour of the next chronological setting
 * @returns {x,y} the new coordinates of the top-left corner of the dragged node
 */
export function verticalResizeHandler(
  pos,
  currentStart,
  currentEnd,
  dragHandleRef,
  otherDragHandleRef,
  rectRef,
  labelsRef,
  draggedEdge,
  previous,
  next,
) {
  const { x, y: currentHandleY } = dragHandleRef.getAbsolutePosition()
  const { y: otherHandleY } = otherDragHandleRef.getAbsolutePosition()

  if (draggedEdge === 'top') {
    if (
      resizeCollisionDetected(pos.y, currentStart, currentEnd, 'top', previous, next)
    ) {
      return { x, y: currentHandleY }
    }
    const hourLongY = hourToCanvasGridLine(currentEnd - 1)
    const y = roundToNearestGridLine(Math.min(pos.y, hourLongY))
    if (y !== currentHandleY) {
      rectRef.setAttrs({
        y: y + RECT_MARGIN,
        height: otherHandleY - y + HANDLE_HEIGHT - 2 * RECT_MARGIN,
      })
      labelsRef.setAttr('y', y)
    }
    return { x, y }
  }
  if (draggedEdge === 'bottom') {
    if (
      resizeCollisionDetected(pos.y, currentStart, currentEnd, 'bottom', previous, next)
    ) {
      return { x, y: currentHandleY }
    }
    const hourLongY = hourToCanvasGridLine(currentStart + 1)
    const y = roundToNearestGridLine(Math.max(pos.y, hourLongY))
    if (y !== currentHandleY) {
      const topOfRect = rectRef.getAbsolutePosition().y
      rectRef.setAttr('height', y - topOfRect - RECT_MARGIN)
    }
    return { x, y }
  }
  return { x, y: pos.y }
}

/**
 *
 * @param {number} y the vertical coordinate of the top of the dragged node
 * @param {number} bottomY the vertical coordinate of the bottom of the dragged node
 * @param {number} currentStart the hour of the start of the dragged setting
 * @param {number} currentEnd the hour of the end of the dragged setting
 * @param {number} previous the hour of the end of the chronologically previous setting
 * @param {number} next the hour of the start of the chronologically next setting
 * @returns {bool} whether the dragged setting will collide with the top or bottom of canvas, or another setting
 */
export function dragCollisionDetected(
  y,
  bottomY,
  currentStart,
  currentEnd,
  previous,
  next,
) {
  const collidesWithTopOfCanvas = y < 0
  const collidesWithBottomOfCanvas = bottomY >= CANVAS_HEIGHT - VERTICAL_GRID_SPACE
  const collidesWithPreviousSetting =
    previous && currentStart >= previous && y < VERTICAL_GRID_SPACE * previous
  const collidesWithNextSetting =
    next && currentEnd <= next && bottomY >= VERTICAL_GRID_SPACE * next

  return (
    collidesWithTopOfCanvas ||
    collidesWithPreviousSetting ||
    collidesWithBottomOfCanvas ||
    collidesWithNextSetting
  )
}

/**
 *
 * @param {{x,y}} pos Coordinates of the top-right corner of dragged node
 * @param {number} currentStart hour of start of dragged setting (before being dragged)
 * @param {number} currentEnd hour of end of dragged setting (before being dragged)
 * @param {node} rectRef the ref of the dragged rectangle
 * @param {node} labelsRef the ref of the labels for the setting
 * @param {number} previous the hour of the end of the chronologically previous setting
 * @param {number} next the hour of the start of the chronologically next setting
 * @returns {x,y} The new coordinates of the top-left corner of dragged node
 */
export function verticalDragHandler(
  pos,
  currentStart,
  currentEnd,
  rectRef,
  overlayRef,
  labelsRef,
  previous,
  next,
) {
  const { x, y: currentTopOfRect } = overlayRef.getAbsolutePosition()
  const { height } = overlayRef.getClientRect()
  const bottomY = pos.y + height
  if (dragCollisionDetected(pos.y, bottomY, currentStart, currentEnd, previous, next)) {
    return { x, y: currentTopOfRect }
  }
  const newTopOfRect = roundToNearestGridLine(pos.y)
  labelsRef.setAttr('y', newTopOfRect + RECT_MARGIN)
  rectRef.setAttr('y', newTopOfRect + RECT_MARGIN)
  return { x, y: newTopOfRect }
}

/**
 * @description Helper function for setting the cursor style in the react-konva setting
 * @param {Event} e The mouse event
 * @param {string} cursorStyle The desired cursor style, e.g. 'pointer', 'default', etc.
 */
export function setCursorStyle(e, cursorStyle) {
  const container = e.target.getStage()?.container()
  if (container) container.style.cursor = cursorStyle
}

/**
 * @description helper function to determine which hours already have a threshold setting
 * @param {Array} settings An array of threshold settings with {startTime, endTime}
 * @returns An array of hours with a threshold setting active
 */
export function getBlockedHours(settings) {
  if (!settings) return []
  const hours = settings.flatMap(({ startTime, endTime }) => {
    if (endTime > startTime) {
      return Array.from({ length: endTime - startTime }, (_, i) => i + startTime)
    }
    return [
      ...Array.from({ length: 24 - startTime }, (_, i) => i + startTime),
      ...Array.from({ length: endTime }, (_, i) => i),
    ]
  })
  return Array.from(new Set(hours))
}

/**
 * @description helper function to determine which hours do not have a threshold setting
 * @param {Array} settings An array of threshold settings with {startTime, endTime}
 * @returns An array of hours without a threshold setting active
 */
export function getOpenHours(settings) {
  const blockedHours = getBlockedHours(settings)
  return Array.from({ length: 24 }, (_, i) => i).filter(
    (hour) => !blockedHours.includes(hour),
  )
}

/**
 *
 * @param {{x,y}} pos The top-left coordinate of the resize handle rect
 * @param {node} dragHandleRef the ref of the resize handle rect
 * @param {node} otherDragHandleRef the ref of the resize handle rect not being dragged
 * @param {node} rectRef the ref of the setting rect
 * @param {node} labelsRef the ref of the label on the setting
 * @param {number} previous the hour of the end of the previous chronological setting
 * @returns {x, y} updated coordinates of the top-left coordinate of the resize handle rect
 */
export function handleResizeWrappedSettingStart(
  pos,
  dragHandleRef,
  otherDragHandleRef,
  rectRef,
  labelsRef,
  previous,
) {
  const { x, y: currentHandleY } = dragHandleRef.getAbsolutePosition()
  const cannotShortenSetting = pos.y > TIMELINE_GRID_END - VERTICAL_GRID_SPACE
  const collidesWithPreviousSetting = pos.y < hourToCanvasGridLine(previous)
  const collidesWithSelf = pos.y < otherDragHandleRef.getAbsolutePosition().y
  if (collidesWithPreviousSetting || cannotShortenSetting || collidesWithSelf) {
    return { x, y: currentHandleY }
  }
  const newHandleY = roundToNearestGridLine(pos.y)
  if (newHandleY !== currentHandleY) {
    rectRef.setAttrs({
      y: newHandleY + RECT_MARGIN,
      height: TIMELINE_GRID_END - newHandleY,
    })
    labelsRef.setAttr('y', newHandleY)
  }
  return { x, y: newHandleY }
}

/**
 *
 * @param {{x,y}} pos The top-left coordinate of the resize handle rect
 * @param {node} dragHandleRef the ref of the resize handle rect
 * @param {node} otherDragHandleRef the ref of the resize handle rect not being dragged
 * @param {node} rectRef the ref of the setting rect
 * @param {number} previous the hour of the start of the next chronological setting
 * @returns {x, y} updated coordinates of the top-left coordinate of the resize handle rect
 */
export function handleResizeWrappedSettingEnd(
  pos,
  dragHandleRef,
  otherDragHandleRef,
  rectRef,
  next,
) {
  const { x, y: currentHandleY } = dragHandleRef.getAbsolutePosition()
  const cannotShortenSetting = pos.y < TIMELINE_GRID_ORIGIN + VERTICAL_GRID_SPACE
  const collidesWithNextSetting = next !== null && pos.y > hourToCanvasGridLine(next)
  const collidesWithSelf = pos.y > otherDragHandleRef.getAbsolutePosition().y
  if (collidesWithNextSetting || cannotShortenSetting || collidesWithSelf) {
    return { x, y: currentHandleY }
  }
  const newHandleY = roundToNearestGridLine(pos.y)
  if (newHandleY !== currentHandleY) {
    rectRef.setAttrs({
      height: newHandleY - RECT_MARGIN,
      y: newHandleY - RECT_MARGIN,
    })
  }
  return { x, y: pos.y }
}

/**
 *
 * @param {Array} settings Array of {startTime, endTime} setting objects
 * @returns a circular buffer, i.e. an array with each item having a
 * pointer to the next and previous item
 */
export function toCircularBuffer(settings) {
  if (!settings.length) return settings
  if (settings.length === 1)
    return [{ ...settings[0], next: undefined, previous: undefined }]
  const arr = []
  const sorted = settings.sort((a, b) => {
    if (a.startTime < b.startTime) return -1
    if (a.startTime > b.startTime) return 1
    return 0
  })
  for (let i = 0; i < sorted.length; i += 1) {
    arr.push({
      next: i === sorted.length - 1 ? sorted[0].startTime : sorted[i + 1].startTime,
      previous: i === 0 ? sorted[sorted.length - 1].endTime : sorted[i - 1].endTime,
      ...sorted[i],
    })
  }
  return arr
}

/**
 *
 * @param {number} start vertical coordinate of start of Rect
 * @param {number} height height of Rect
 * @returns {Array} array with [start, end] hours of setting
 */
export function rectToSetting(start, height) {
  return [roundToNearestHour(start), roundToNearestHour(start + height)]
}
