import { Text } from 'react-konva'

import { RECT_HORIZONTAL_ORIGIN } from './constants'
import { getRectWidth } from './Rects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {string} props.label
 * @param {number} props.y
 * @param {number} props.dy
 */
export function DurationLabel({ editorWidth = undefined, label, y, dy }) {
  return (
    <Text
      text={label}
      x={RECT_HORIZONTAL_ORIGIN + 17}
      y={y + dy}
      fontFamily="Degular"
      fontSize={editorWidth < 300 ? 18 : 20}
      fill="white"
    />
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {number} props.NRS
 * @param {number} props.y
 * @param {number} props.dy
 */
export function NRSLabel({ editorWidth = undefined, NRS, y, dy }) {
  return (
    <Text
      text={`${NRS} NRS`}
      x={RECT_HORIZONTAL_ORIGIN + getRectWidth(editorWidth) - 60 - 18}
      y={y + dy}
      fontFamily="Inter"
      width={60}
      align="right"
      fontSize={editorWidth < 300 ? 12 : 14}
      fill="white"
    />
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {string} props.label
 * @param {number} props.y
 * @param {number} props.dy
 */
export function SettingLabel({ editorWidth = undefined, label, y, dy }) {
  return (
    <Text
      text={label.toUpperCase()}
      x={RECT_HORIZONTAL_ORIGIN + 17}
      y={y + dy}
      fontFamily="Inter"
      fontSize={editorWidth ? 12 : 14}
      fill="white"
    />
  )
}
