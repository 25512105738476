import { NavigateNext as NavigateNextIcon } from '@mui/icons-material'
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Grid2,
  TextField,
  Typography,
} from '@mui/material'

import { curveMonotoneX } from '@visx/curve'

import { Loading } from '@common/components'
import { Chart } from '@common/components/chart'
import { humanizeTimezone } from '@common/utils'

import { canvasStyles, noteStyles } from './Report.styles'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.loading
 * @param {Object} props.instance
 * @param {string} props.instance.accountName
 * @param {string} props.instance.propertyName
 * @param {string} props.instance.unitName
 * @param {string} props.organizationName
 * @param {string} props.timezone
 * @param {Object[]} [props.chartData]
 * @param {number} [props.thresholdValue]
 * @param {boolean} props.exporting
 * @param {boolean} props.markers
 * @param {string} props.feedback
 * @param {Object} props.initialFeedback
 * @param {string} props.initialFeedback.comments
 * @param {Function} props.onSaveFeedback
 * @param {Function} props.onChangeFeedback
 */
export default function ReportData({
  instance,
  organizationName,
  timezone,
  loading,
  chartData = null,
  thresholdValue = null,
  markers,
  initialFeedback,
  feedback,
  onSaveFeedback,
  onChangeFeedback,
  exporting,
}) {
  const breadCrumbs = [
    { label: organizationName },
    { label: instance.accountName },
    { label: instance.propertyName },
  ]

  const chartFontColor = exporting ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'

  return (
    <div
      id={exporting ? 'printable_canvas' : 'report_canvas'}
      style={
        exporting
          ? {
              ...canvasStyles,
              backgroundColor: 'white',
              position: 'absolute',
              left: '-9999px',
            }
          : canvasStyles
      }
    >
      <Typography variant="h5" sx={{ color: exporting ? 'primary' : 'white' }}>
        Noise Event
      </Typography>
      <MuiBreadcrumbs
        separator={
          <NavigateNextIcon
            sx={{ fontSize: 16, color: exporting ? 'primary' : 'white' }}
          />
        }
        aria-label="breadcrumb"
      >
        {breadCrumbs.map((link) => (
          <Typography
            key={link.label}
            color={exporting ? 'primary' : 'white'}
            variant="caption"
          >
            {link.label}
          </Typography>
        ))}
      </MuiBreadcrumbs>
      <Typography variant="h4" sx={{ color: exporting ? 'primary' : 'white' }}>
        {instance.unitName}
      </Typography>
      {loading && !chartData && <Loading color="white" height={140} />}
      {chartData && thresholdValue && (
        <Grid2 container>
          <Grid2 size={{ xs: 12 }}>
            <Chart
              id="nrs"
              data={chartData}
              dataTypes={[
                {
                  name: 'nrs',
                  unit: 'nrs',
                  color: exporting ? '#5e475d' : 'white',
                  visible: true,
                },
              ]}
              yMin={0}
              yMax={100}
              curve={curveMonotoneX}
              graphPadding={{ right: -25, left: -10 }}
              horizontalGuidelines={[
                {
                  value: thresholdValue,
                  color: 'rgba(255, 0, 0, 0.4)',
                  unit: 'nrs',
                  dotted: true,
                },
              ]}
              markers={markers}
              settings={{
                showGridRows: true,
                showCursor: false,
                bottomAxisProps: {
                  hideAxisLine: true,
                  tick: { fill: chartFontColor },
                },
                rightAxisProps: {
                  hideTicks: true,
                  hideAxisLine: true,
                  hideLabel: true,
                  tick: {
                    fill: chartFontColor,
                    fontSize: '8px',
                  },
                },
                gridRows: {
                  opacity: 0.3,
                  strokeWidth: 2,
                },
              }}
            />
          </Grid2>
          <Grid2
            size={{ xs: 12 }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: '14px', color: chartFontColor }}>
              Timezone: {humanizeTimezone(timezone)}
            </Typography>
          </Grid2>
          <Grid2 data-html2canvas-ignore="true" size={{ xs: 12 }}>
            <Typography sx={{ fontSize: '16px', color: chartFontColor, mb: 0.5 }}>
              Feedback
            </Typography>
            <TextField
              fullWidth
              multiline
              variant="filled"
              value={feedback}
              onChange={(event) => onChangeFeedback(event.target.value)}
              InputProps={{
                disableUnderline: true,
                sx: { padding: 1 },
                style: exporting
                  ? {
                      ...noteStyles,
                      background: 'rgba(0, 0, 0, 0.05)',
                      color: 'rgba(0, 0, 0, 0.7)',
                    }
                  : noteStyles,
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Button
                disabled={!feedback || feedback === initialFeedback.comments}
                variant="contained"
                onClick={onSaveFeedback}
                sx={{
                  mt: 1,
                  '&.Mui-disabled': {
                    background: '#24345c',
                    color: 'grey',
                  },
                }}
              >
                Save Feedback
              </Button>
            </Box>
          </Grid2>
        </Grid2>
      )}
    </div>
  )
}
