import { forwardRef } from 'react'

import { Rect as KonvaRect } from 'react-konva'

import {
  CANVAS_WIDTH,
  RECT_HORIZONTAL_ORIGIN,
  RECT_MARGIN,
  TIMELINE_GRID_END,
  TIMELINE_GRID_ORIGIN,
} from './constants'

// horizontal dimension of NRS setting rectangle
export const getRectWidth = (width) => (width ?? CANVAS_WIDTH) - 65

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {number} props.bottom
 * @param {string} props.color
 */
export const TopRect = forwardRef(
  ({ editorWidth = undefined, bottom, color, ...props }, ref) => (
    <KonvaRect
      ref={ref}
      y={bottom - RECT_MARGIN}
      x={RECT_HORIZONTAL_ORIGIN + 0.5}
      cornerRadius={[11, 11, 0, 0]}
      height={bottom - TIMELINE_GRID_ORIGIN - RECT_MARGIN}
      width={getRectWidth(editorWidth)}
      fill={color}
      stroke={color}
      strokeWidth={1}
      scaleY={-1}
      {...props}
    />
  ),
)

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {number} props.top
 * @param {number} props.bottom
 * @param {string} props.color
 */
export const Rect = forwardRef(
  ({ editorWidth = undefined, top, bottom, color, ...props }, ref) => (
    <KonvaRect
      x={RECT_HORIZONTAL_ORIGIN + 0.5}
      cornerRadius={11}
      y={top + RECT_MARGIN}
      height={bottom - top - 2 * RECT_MARGIN}
      width={getRectWidth(editorWidth)}
      fill={color}
      stroke={color}
      strokeWidth={1}
      ref={ref}
      {...props}
    />
  ),
)

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {number} props.top
 * @param {string} props.color
 */
export const BottomRect = forwardRef(
  ({ editorWidth = undefined, top, color, ...props }, ref) => (
    <KonvaRect
      ref={ref}
      y={top + RECT_MARGIN}
      height={TIMELINE_GRID_END - (top + RECT_MARGIN)}
      x={RECT_HORIZONTAL_ORIGIN + 0.5}
      cornerRadius={[11, 11, 0, 0]}
      width={getRectWidth(editorWidth)}
      fill={color}
      stroke={color}
      strokeWidth={1}
      {...props}
    />
  ),
)
