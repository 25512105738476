import { Box, Typography } from '@mui/material'

import { capitalize } from 'inflection'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.category
 * @param {string} [props.subcategory]
 */
export default function CategoryLabel({ category, subcategory = null }) {
  return (
    <Box>
      <Typography>{capitalize(category)}</Typography>
      {subcategory && <Typography variant="caption">{subcategory}</Typography>}
    </Box>
  )
}
