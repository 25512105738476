import { useCallback, useEffect, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { titleize } from 'inflection'
import * as yup from 'yup'

import { StaticMultiSelect } from '@common/components/Selects'
import { getApiFetch, isAbortError, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'

import FormikStatePropagator from './FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {'organization'|'account'|'property'} props.entityType
 * @param {Object} props.entity
 * @param {string} props.entity.id
 * @param {string} props.entity.name
 * @param {string[]} props.entity.flags
 * @param {string[]} props.entity.expandedFlags
 */
export default function FeatureFlagsConfiguration({
  open,
  onClose,
  entityType,
  entity = null,
}) {
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const [loading, setLoading] = useState(false)
  const [availableFlags, setAvailableFlags] = useState(null)

  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [formikProps, setFormikProps] = useState({
    values: {},
    setFieldValue: () => {},
  })

  const fetchFlags = useCallback(async () => {
    try {
      const flagsResponse = await apiFetch(
        `/flags/`,
        { pageSize: 999 },
        { method: 'GET', cancelationPrefix: 'flag_configuration' },
      )
      setAvailableFlags(flagsResponse?.results)
    } catch (err) {
      if (!isAbortError(err)) {
        const errMessage = parseApiErrors(err?.response)
        doShowSnackbar(errMessage, 'error')
      }
    }
  }, [entity, entityType])

  const updateFlags = useCallback(
    async (params) => {
      setLoading(true)
      try {
        await apiFetch(
          `/flags/`,
          {
            [params.entityType]: params.id,
            flags: params.selectedFlags.map((obj) => obj.id),
          },
          { method: 'POST' },
        )
        doShowSnackbar(`${titleize(entityType)} flags have been updated`, 'success')
      } catch (err) {
        const errMessage = parseApiErrors(err?.response)
        doShowSnackbar(errMessage, 'error')
      } finally {
        setLoading(false)
      }
    },
    [entity, entityType],
  )

  useEffect(() => {
    if (open) {
      fetchFlags()
    } else {
      setAvailableFlags(null)
    }
  }, [open])

  const validationSchema = yup.object().shape({
    id: yup.string().uuid(),
    entityType: yup.string(),
    selectedFlags: yup.array().of(yup.object()),
  })

  const initialValues = {
    id: '',
    entityType,
    selectedFlags: [],
  }

  if (entity) {
    initialValues.id = entity.id
    initialValues.selectedFlags = entity.flags.map((flag) => ({
      id: flag,
      label: flag,
    }))
  }

  const saveForm = async (params) => {
    await updateFlags(params)
    onClose(true)
  }

  return (
    <FormDialog
      label="Feature Flags Configuration"
      open={open}
      onSave={saveForm}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={loading}
    >
      <StaticMultiSelect
        sx={{ maxWidth: '100%' }}
        options={availableFlags
          ?.filter(
            (flag) =>
              !(entity.expandedFlags ?? [])
                .filter((inheritedFlag) => !entity.flags.includes(inheritedFlag))
                .includes(flag.key),
          )
          ?.map((flag) => ({
            id: flag.key,
            label: flag.key,
            subtitle: flag.description,
          }))}
        disabled={!availableFlags}
        label={`${titleize(entityType)} Flags`}
        size="small"
        value={formikProps.values.selectedFlags ?? []}
        onChange={(flags) => formikProps.setFieldValue('selectedFlags', flags)}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
      />
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
