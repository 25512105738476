import Avatar from '@mui/material/Avatar'

export function getAvatarInitials(name) {
  if (name.split(' ').length > 1) {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
  return name.charAt(0)
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.name]
 * @param {string} [props.image]
 * @param {number} [props.index]
 * @param {number} [props.size]
 * @param {number} [props.fontSize]
 * @param {Object} [props.sx]
 */
export default function UserAvatar({
  name = '',
  image = '',
  index = 0,
  fontSize = 16,
  size = undefined,
  sx = {},
}) {
  const avatarColors = ['#00796B', '#D97E2A', '#29B6F6', '#536DFE']

  if (image) {
    return (
      <Avatar
        data-testid="user_avatar"
        alt={`Avatar for ${name}`}
        src={image}
        sx={{ height: size, width: size, ...sx }}
      />
    )
  }

  return (
    <Avatar
      data-testid="user_avatar"
      alt={`Avatar for ${name}`}
      sx={[
        {
          bgcolor: avatarColors[index % avatarColors.length],
          height: size,
          width: size,
          fontSize,
          ...sx,
        },
      ]}
    >
      {getAvatarInitials(name)}
    </Avatar>
  )
}
