import { createRouteBundle } from 'redux-bundler'

import NotFoundPage from '@common/components/NotFoundPage'
import { AccountDetail, AccountList } from '@portal/pages/Accounts'
import accountUrls from '@portal/pages/Accounts/urls'
import { DeviceDetails, Devices } from '@portal/pages/Devices'
import deviceUrls from '@portal/pages/Devices/urls'
import { Events } from '@portal/pages/EventDashboard'
import eventUrls from '@portal/pages/EventDashboard/urls'
import { FinanceDashboard } from '@portal/pages/Finance'
import financeUrls from '@portal/pages/Finance/urls'
import { Dashboard } from '@portal/pages/Home'
import homeUrls from '@portal/pages/Home/urls'
import LegacyOrganizations from '@portal/pages/LegacyOrganizations'
import legacyOrgUrls from '@portal/pages/LegacyOrganizations/urls'
import Login from '@portal/pages/Login'
import loginUrls from '@portal/pages/Login/urls'
import { OrganizationDetail, Organizations } from '@portal/pages/Organizations'
import orgUrls from '@portal/pages/Organizations/urls'
import { Properties, PropertyDetails } from '@portal/pages/Properties'
import propertyUrls from '@portal/pages/Properties/urls'
import { PropertySmokeProfiles } from '@portal/pages/PropertySmokeProfiles'
import propertySmokeProfilesUrls from '@portal/pages/PropertySmokeProfiles/urls'
import { Reservations } from '@portal/pages/Reservations'
import reservationUrls from '@portal/pages/Reservations/urls'
import { ScenarioDetails, Scenarios } from '@portal/pages/Scenarios'
import scenarioUrls from '@portal/pages/Scenarios/urls'
import { ScheduledReportPreferences } from '@portal/pages/ScheduledReportPreferences'
import scheduledReportPrefUrls from '@portal/pages/ScheduledReportPreferences/urls'
import SensorTesting from '@portal/pages/SensorTesting/SensorTesting'
import sensorTestingUrls from '@portal/pages/SensorTesting/urls'
import { ShipbobOrders } from '@portal/pages/ShipbobOrders'
import shipbobOrdersUrls from '@portal/pages/ShipbobOrders/urls'
import { SmokeMetrics } from '@portal/pages/SmokeMetrics'
import smokeMetricsUrls from '@portal/pages/SmokeMetrics/urls'
import {
  SmokeProfileEnsembleDetail,
  SmokeProfileEnsembles,
  smokeProfileEnsemblesUrls,
} from '@portal/pages/SmokeProfileEnsembles'
import { SmokeProfileDetail, SmokeProfiles } from '@portal/pages/SmokeProfiles'
import smokeProfilesUrls from '@portal/pages/SmokeProfiles/urls'
import { ThresholdManagement } from '@portal/pages/ThresholdManagement'
import ThresholdDetails from '@portal/pages/ThresholdManagement/ThresholdDetails'
import thresholdManagementUrls from '@portal/pages/ThresholdManagement/urls'
import { UnitDetail, UnitList } from '@portal/pages/Units'
import unitUrls from '@portal/pages/Units/urls'
import { UserDetails, Users } from '@portal/pages/Users'
import userUrls from '@portal/pages/Users/urls'

export default createRouteBundle({
  [homeUrls.home]: {
    showSidebar: true,
    component: Dashboard,
  },
  [accountUrls.list]: AccountList,
  [accountUrls.entity]: AccountDetail,
  [userUrls.list]: Users,
  [userUrls.entity]: UserDetails,
  [propertyUrls.list]: Properties,
  [propertyUrls.entity]: PropertyDetails,
  [deviceUrls.list]: Devices,
  [deviceUrls.entity]: DeviceDetails,
  [eventUrls.list]: Events,
  [reservationUrls.list]: Reservations,
  [scheduledReportPrefUrls.list]: ScheduledReportPreferences,
  [scenarioUrls.list]: Scenarios,
  [scenarioUrls.entity]: ScenarioDetails,
  [sensorTestingUrls.testing]: SensorTesting,
  [shipbobOrdersUrls.list]: ShipbobOrders,
  [orgUrls.list]: Organizations,
  [orgUrls.entity]: OrganizationDetail,
  [legacyOrgUrls.list]: LegacyOrganizations,
  [unitUrls.list]: UnitList,
  [unitUrls.entity]: UnitDetail,
  [financeUrls.dashboard]: FinanceDashboard,
  [smokeMetricsUrls.metrics]: SmokeMetrics,
  [smokeProfilesUrls.list]: SmokeProfiles,
  [smokeProfilesUrls.entity]: SmokeProfileDetail,
  [smokeProfileEnsemblesUrls.list]: SmokeProfileEnsembles,
  [smokeProfileEnsemblesUrls.entity]: SmokeProfileEnsembleDetail,
  [propertySmokeProfilesUrls.list]: PropertySmokeProfiles,
  [thresholdManagementUrls.list]: ThresholdManagement,
  [thresholdManagementUrls.entity]: ThresholdDetails,
  [loginUrls.login]: {
    component: Login,
    publicAccess: true,
    showSidebar: false,
  },
  '*': NotFoundPage,
})
