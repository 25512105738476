import { useCallback, useEffect, useState } from 'react'

import { pipe, replace, toUpper, trim } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'

import { titleize } from 'inflection'

import { StaticSelect } from '@common/components/Selects'
import { getApiFetch, isAbortError, parseApiErrors } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.profile]
 * @param {string} [props.profile.id]
 * @param {string} [props.profile.name]
 * @param {string} [props.profile.property]
 * @param {string} [props.profile.propertyName]
 * @param {Object[]} [props.profile.rules]
 */
export default function AssignThreshold({ open, onClose, profile = undefined }) {
  const [currentEntity, setCurrentEntity] = useState('unitGroup')

  const [selectedProperty, setSelectedProperty] = useState(null)
  const [selectedUnitGroup, setSelectedUnitGroup] = useState(null)

  const [propertyOptions, setPropertyOptions] = useState([])
  const [unitGroupOptions, setUnitGroupOptions] = useState([])

  const [unitGroupsLoading, setUnitGroupsLoading] = useState(false)

  const { doThresholdProfileAssign, doShowSnackbar } = useConnect(
    'doThresholdProfileAssign',
    'doShowSnackbar',
  )

  const entityMap = {
    property: {
      options: propertyOptions,
      setOptions: setPropertyOptions,
      selected: selectedProperty,
      setSelected: setSelectedProperty,
    },
    unitGroup: {
      options: unitGroupOptions,
      setOptions: setUnitGroupOptions,
      selected: selectedUnitGroup,
      setSelected: setSelectedUnitGroup,
    },
  }

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const fetchUnitGroupsOptions = useCallback(async () => {
    try {
      setUnitGroupsLoading(true)

      const response = await apiFetch(
        `/unit_groups/`,
        {
          property: profile.property,
          pageSize: 999,
        },
        { cancelationPrefix: 'assign_threshold_form' },
      )
      const groupsOptions =
        response?.results?.map((item) => ({
          id: item.id,
          name: item.name,
        })) ?? []
      entityMap.unitGroup.setOptions(groupsOptions)
    } catch (err) {
      if (!isAbortError) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    } finally {
      setUnitGroupsLoading(false)
    }
  }, [profile])

  useEffect(() => {
    const defaultEntity = 'unitGroup'
    setCurrentEntity(defaultEntity)

    if (open) {
      fetchUnitGroupsOptions()
    } else {
      setSelectedProperty(null)
      setSelectedUnitGroup(null)
    }
  }, [open])

  const hasSelectedEntities = selectedProperty || selectedUnitGroup

  const resetAdjacentEntities = (entity) => {
    Object.keys(entityMap)
      .filter((e) => e !== entity)
      .map((ent) => entityMap[ent].setSelected(null))
  }

  const handleAssign = useCallback(async () => {
    try {
      const response = await doThresholdProfileAssign({
        thresholdProfile: profile.id,
        ...(selectedUnitGroup ? { unitGroup: selectedUnitGroup.id } : {}),
        ...(selectedProperty ? { property: selectedProperty.id } : {}),
      })
      if (response.error) {
        throw response.error
      }

      const { name: entityName } = selectedProperty || selectedUnitGroup
      doShowSnackbar(`Profile assigned to ${entityName}`, 'success')
      onClose(true)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [profile, selectedProperty, selectedUnitGroup])

  useEffect(() => {
    if (profile?.property && currentEntity === 'property') {
      const option = {
        id: profile.property,
        name: profile.propertyName,
      }
      entityMap[currentEntity].setOptions([option])
      entityMap[currentEntity].setSelected(option)
    }
  }, [currentEntity, profile])

  const formatEntity = pipe(replace(/([A-Z])/g, ' $1'), toUpper, trim)

  return (
    <Dialog fullWidth open={open} onClose={onClose} disableScrollLock maxWidth="sm">
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, pb: 2, px: 4 }}
      >
        Assign Profile To
        <IconButton onClick={onClose} sx={{ padding: 0, width: '32px' }}>
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" sx={{ mx: 4 }} />
      <DialogContent sx={{ pb: 4, px: 4, maxWidth: '1000px' }}>
        <Stack direction="column" spacing={2}>
          {Object.keys(entityMap).length > 1 && (
            <ToggleButtonGroup
              exclusive
              color="primary"
              value={currentEntity}
              size="small"
              onChange={(_, selectedValue) => {
                resetAdjacentEntities(selectedValue)
                setCurrentEntity(selectedValue)
              }}
            >
              {Object.keys(entityMap).map((ent) => (
                <ToggleButton
                  key={ent}
                  value={ent}
                  disabled={ent === currentEntity || ent === 'propertyGroup'}
                  sx={{ py: 0.4, fontSize: 12 }}
                >
                  {formatEntity(ent)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
          {entityMap[currentEntity] && (
            <StaticSelect
              fullWidth
              options={entityMap[currentEntity].options}
              label={titleize(formatEntity(currentEntity))}
              disabled={currentEntity === 'property' || unitGroupsLoading}
              value={entityMap[currentEntity].selected}
              onChange={(value) =>
                entityMap[currentEntity].setSelected(
                  entityMap[currentEntity].options.find((item) => item.id === value),
                )
              }
              getOptionLabel={(opt) =>
                opt?.name ??
                entityMap[currentEntity].options.find((option) => option.id === opt)
                  ?.name ??
                ''
              }
              variant="outlined"
              size="small"
            />
          )}
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={!hasSelectedEntities}
              onClick={handleAssign}
            >
              Assign
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
