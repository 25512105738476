import {
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_PM,
} from '@common/utils/grafanaViewDataProcessor'

export const COLOR_7F66B3 = '#7F66B3'
export const COLOR_669CC3 = '#669CC3'
export const COLOR_CF8473 = '#CF8473'
export const COLOR_344B78 = '#344B78'
export const COLOR_547E7C = '#547E7C'
export const COLOR_C55A86 = '#C55A86'
export const COLOR_F5B961 = '#F5B961'
export const COLOR_B8B8B8 = '#B8B8B8'
export const COLOR_EC304A = '#EC304A'

export const RESERVATION_COLOR = '#4A6FB5'

const eventTypesMeta = {
  mc_1p0_mean: {
    key: 'mc_1p0_mean',
    systemName: 'mc_1p0_mean',
    tooltipLabel: 'PM 0.3-1.0',
    color: COLOR_7F66B3,
    category: CATEGORY_PM,
    visible: true,
  },
  mc_2p5_mean: {
    key: 'mc_2p5_mean',
    systemName: 'mc_2p5_mean',
    tooltipLabel: 'PM 1.0-2.5',
    color: COLOR_669CC3,
    category: CATEGORY_PM,
    visible: true,
  },
  mc_4p0_mean: {
    key: 'mc_4p0_mean',
    systemName: 'mc_4p0_mean',
    tooltipLabel: 'PM 2.5-4.0',
    color: COLOR_CF8473,
    category: CATEGORY_PM,
    visible: true,
  },
  mc_10p0_mean: {
    key: 'mc_10p0_mean',
    systemName: 'mc_10p0_mean',
    tooltipLabel: 'PM 4.0-10.0',
    color: COLOR_344B78,
    category: CATEGORY_PM,
    visible: true,
  },
  tpm_mean: {
    key: 'tpm_mean',
    systemName: 'tpm_mean',
    tooltipLabel: 'particle size',
    color: COLOR_EC304A,
    category: CATEGORY_PM,
    unit: 'particle size',
    visible: true,
  },

  temp: {
    key: 'temp',
    systemName: 'temp_mean',
    tooltipLabel: 'temp',
    color: COLOR_7F66B3,
    category: CATEGORY_ENVIRONMENT,
    visible: true,
  },
  rel_hum: {
    key: 'rel_hum',
    systemName: 'hum_mean',
    tooltipLabel: 'rel_hum',
    color: COLOR_669CC3,
    category: CATEGORY_ENVIRONMENT,
    visible: true,
  },
  abs_hum: {
    key: 'abs_hum',
    tooltipLabel: 'abs_hum',
    color: COLOR_CF8473,
    category: CATEGORY_ENVIRONMENT,
    unit: 'g/m³',
    template: '{{ value }} g/m³',
    visible: true,
  },

  tvoc_mean: {
    key: 'tvoc_mean',
    systemName: 'tvoc_mean',
    tooltipLabel: 'tvoc',
    color: COLOR_7F66B3,
    category: CATEGORY_CO2_TVOC,
    visible: true,
  },
  eco2_mean: {
    key: 'eco2_mean',
    systemName: 'eco2_mean',
    tooltipLabel: 'co2',
    color: COLOR_669CC3,
    category: CATEGORY_CO2_TVOC,
    visible: true,
  },
}

export function getEventTypesMeta(systemDataTypes) {
  return Object.fromEntries(
    Object.entries(eventTypesMeta).map(([k, v]) => {
      const systemDataType = systemDataTypes.find((type) =>
        type.key.startsWith(v.systemName),
      )
      let { template, unit } = v
      if (systemDataType) {
        template ??= systemDataType.template
        unit ??= systemDataType.template.replace('{{ value }}', '').trim()
        if (unit.startsWith('/')) {
          unit = `#${unit}`
        }
      }
      return [k, { ...v, unit, template }]
    }),
  )
}
