import { useConnect } from 'redux-bundler-hook'

import { Box, Divider } from '@mui/material'

import { TabComponent } from '@common/components'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import AccountList from '@portal/pages/Accounts/Accounts'
import PropertiesList from '@portal/pages/Properties/Properties'
import DetailItem from '@portal/UI/components/DetailItem'
import DetailsFeatureFlagsRow from '@portal/UI/components/DetailsFeatureFlagsRow'

export default function OrganizationTab() {
  const {
    organizationFetch: organization,
    doOrganizationFetch,
    doShowSnackbar,
  } = useConnect('selectOrganizationFetch', 'doOrganizationFetch', 'doShowSnackbar')
  const isSmallScreen = useSmallScreen()

  const tabs = [
    {
      label: 'Accounts',
      component: <AccountList renderAsTab organizationId={organization?.id} />,
    },
    {
      label: 'Properties',
      component: <PropertiesList renderAsTab organizationId={organization?.id} />,
    },
  ]

  return (
    <Box>
      <Box
        display="flex"
        my={isSmallScreen ? 1 : 2}
        gap={isSmallScreen ? 1.5 : 10}
        sx={{ textAlign: isSmallScreen ? 'start' : 'center' }}
      >
        <DetailItem label="Group" value={organization?.groupName} />
        <DetailItem label="Accounts" value={organization?.accountCount} />
        <DetailItem label="Devices" value={organization?.deviceCount} />
        <DetailItem label="Properties" value={organization?.propertyCount} />
        <DetailItem label="Units" value={organization?.unitCount} />
      </Box>
      <Divider />
      <DetailsFeatureFlagsRow
        entity={organization}
        entityType="organization"
        onEditSuccess={async () => {
          try {
            await doOrganizationFetch(organization.id)
          } catch (err) {
            const parsedError = parseApiErrors(err?.response)
            doShowSnackbar(parsedError, 'error')
          }
        }}
      />
      <Box sx={{ my: 1 }}>
        <TabComponent tabs={tabs} />
      </Box>
    </Box>
  )
}
