import { Box, Stack } from '@mui/material'

import { getValueFromColumnDef } from './MobileList'
import MobileListCardRow from './MobileListCardRow'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.row
 * @param {Object[]} props.columns
 * @param {React.Node} [props.header]
 * @param {React.Node} [props.footer]
 * @param {string[]} [props.ignoredFields]
 * @param {string[][]} [props.multiRowFields]
 * @param {Function} [props.rowStyleBuilder]
 */
export default function MobileListDefaultCard({
  row,
  columns,
  header = undefined,
  footer = undefined,
  ignoredFields = undefined,
  multiRowFields = undefined,
  rowStyleBuilder = undefined,
}) {
  const filteredColumns =
    multiRowFields || ignoredFields
      ? columns.filter(
          (column) =>
            ![
              ...(ignoredFields ?? []),
              ...(multiRowFields
                ? multiRowFields.reduce((acc, fields) => [...acc, ...fields], [])
                : []),
            ].includes(column.field),
        )
      : columns

  return (
    <Stack spacing={0.5}>
      {header}
      {filteredColumns.map((column) => {
        const value = getValueFromColumnDef({ row, column })
        return (
          <MobileListCardRow
            key={column.field}
            label={column.headerName}
            value={value}
            {...(rowStyleBuilder ? rowStyleBuilder({ row, column }) ?? {} : {})}
          />
        )
      })}
      {multiRowFields?.map((fields) => (
        <Box
          key={`row_${fields.join('_')}`}
          display={fields.length > 1 ? 'flex' : 'block'}
          justifyContent="space-between"
        >
          {columns
            .filter((column) => fields.includes(column.field))
            .map((column) => {
              const value = getValueFromColumnDef({ row, column })
              return (
                <MobileListCardRow
                  key={column.field}
                  label={column.headerName}
                  value={value}
                  {...(rowStyleBuilder ? rowStyleBuilder({ row, column }) ?? {} : {})}
                />
              )
            })}
        </Box>
      ))}
      {footer}
    </Stack>
  )
}
