import React from 'react'

import { Axis, AxisBottom } from '@visx/axis'
import { RectClipPath } from '@visx/clip-path'
import { curveStepBefore } from '@visx/curve'
import { GridRows } from '@visx/grid'
import { Group } from '@visx/group'
import { scaleLinear, scaleTime } from '@visx/scale'
import { Line, LinePath } from '@visx/shape'
import { Text } from '@visx/text'
import { DateTime } from 'luxon'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.lines
 * @param {Object[]} props.lines[].data
 * @param {Object[]} [props.guidelines]
 * @param {number} [props.guidelines[].value]
 * @param {string} [props.guidelines[].label]
 * @param {string} [props.guidelines[].color]
 * @param {string} props.timezone
 * @param {number} props.width
 * @param {number} props.height
 * @param {boolean} props.hideAxis
 * @param {string} props.yLabel
 */
export default function LineChart({
  width,
  height,
  lines,
  guidelines = [],
  hideAxis = false,
  timezone,
  yLabel = 'smoke detection score',
}) {
  const margin = {
    top: 10,
    left: 50,
    right: hideAxis ? 0 : -35,
    bottom: 20,
  }
  const graphLeft = margin.left
  const graphRight = width - margin.right
  const graphTop = margin.top
  const graphBottom = graphTop + height
  const graphWidth = graphRight - graphLeft
  const graphHeight = graphBottom - graphTop
  const svgHeight = height + margin.top + margin.bottom

  const xMin = lines[0].data[0].ts
  const xMax = lines[0].data[lines[0].data.length - 1].ts
  const timeScale = scaleTime({ domain: [xMin, xMax], range: [graphLeft, graphWidth] })

  const yScale = scaleLinear({ domain: [0, 100], range: [graphBottom, graphTop] })
  const defined = (d) => d && d.value !== null && d.value > -1

  const getDate = (d) => DateTime.fromMillis(d.ts)
  const getValue = (d) => d.value
  const x = (d) => timeScale(getDate(d))
  const y = (d) => yScale(getValue(d))

  return (
    <div>
      <svg height={svgHeight} width={width} viewBox={`0 0 ${width} ${svgHeight}`}>
        <GridRows
          scale={yScale}
          stroke="#ccc"
          strokeWidth={0.5}
          left={graphLeft}
          width={Math.max(0, graphWidth - graphLeft)}
          height={graphHeight}
        />
        {!hideAxis && (
          <Axis
            orientation="left"
            label={yLabel}
            labelProps={{
              fontSize: 13,
              x: -245,
              fontFamily: 'Inter, sans-serif',
            }}
            strokeWidth={0}
            hideTicks
            left={35}
            scale={yScale}
            tickLabelProps={() => ({
              fill: 'rgba(0, 0, 0, 0.90)',
              fontSize: '10px',
              fontFamily: 'Inter, sans-serif',
            })}
          />
        )}
        {hideAxis && (
          <Axis
            orientation="left"
            strokeWidth={0}
            hideTicks
            tickValues={[0, 50, 100]}
            left={35}
            scale={yScale}
            tickLabelProps={() => ({
              fill: 'rgba(0, 0, 0, 0.90)',
              fontSize: '10px',
              fontFamily: 'Inter, sans-serif',
            })}
          />
        )}
        {guidelines.map((guide) => (
          <React.Fragment key={guide.label}>
            {guide.label && (
              <Text
                fontFamily="Inter, sans-serif"
                verticalAnchor="start"
                y={graphTop}
                x={graphRight}
                dx={-135}
                dy={2}
                fontSize={12}
                fill={guide.color}
              >
                {guide.label}
              </Text>
            )}
            <Line
              from={{
                x: graphLeft,
                y: yScale(guide.value),
              }}
              to={{ x: graphRight - margin.left, y: yScale(guide.value) }}
              stroke={guide.color}
              strokeWidth={1}
            />
          </React.Fragment>
        ))}
        {lines.map((entity) => (
          <Group key={entity.key}>
            <RectClipPath
              id={`full-${entity.key}`}
              height={svgHeight}
              width={width}
              y={11}
            />
            <LinePath
              data={entity.data}
              defined={defined}
              x={x}
              y={y}
              stroke={entity.color}
              strokeWidth={1}
              clipPath={`url(#full-${entity.key})`}
            />
            <RectClipPath id={`red-${entity.key}`} height={11} width={width} />
            <LinePath
              data={entity.data}
              defined={defined}
              x={x}
              y={y}
              stroke="#ff0000"
              strokeWidth={3}
              clipPath={`url(#red-${entity.key})`}
            />
          </Group>
        ))}
        {!hideAxis && (
          <AxisBottom
            hideTicks
            hideAxisLine
            scale={timeScale}
            curve={curveStepBefore}
            top={graphBottom}
            orientation="bottom"
            tickFormat={(d) =>
              DateTime.fromJSDate(d).setZone(timezone).toFormat('hh:mm a')
            }
            tickLabelProps={() => ({
              fill: 'rgba(0, 0, 0, 0.90)',
              fontSize: '10px',
              fontFamily: 'Inter, sans-serif',
            })}
          />
        )}
      </svg>
    </div>
  )
}
