import { Tooltip, Typography } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {Object} [props.props]
 * @param {Object} [props.toolTipProps]
 */
export default function TooltipCell({ label, props = {}, toolTipProps = {} }) {
  return (
    <Tooltip
      title={label}
      placement="top"
      slotProps={{ tooltip: { sx: { maxWidth: '850px' } } }}
      {...toolTipProps}
    >
      <Typography noWrap fontSize={14} {...props}>
        {label}
      </Typography>
    </Tooltip>
  )
}
