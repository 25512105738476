import { ClipPath } from '@visx/clip-path'
import { Area, LinePath } from '@visx/shape'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Function} props.curve
 * @param {number} props.yMax
 * @param {number} props.yMin
 * @param {Object[]} [props.data]
 * @param {number} [props.data[].date]
 * @param {number} [props.data[].y]
 * @param {number} [props.data[].threshold]
 * @param {Function} props.defined
 * @param {Function} props.x
 * @param {Function} props.y
 * @param {Function} props.threshold
 * @param {string} [props.color]
 */
export default function VerticalThresholdedLine({
  id,
  data = [],
  x,
  y,
  yMax,
  yMin,
  defined,
  threshold,
  curve,
  color = undefined,
}) {
  return (
    <>
      <Area curve={curve} data={data} x={x} y1={threshold} defined={defined}>
        {({ path }) => {
          const belowPath = path.y0(yMax)(data)
          const abovePath = path.y0(yMin)(data)

          return (
            <g>
              <ClipPath id={`threshold-clip-below-${id}`}>
                <path d={belowPath || ''} />
              </ClipPath>
              <ClipPath id={`threshold-clip-above-${id}`}>
                <path d={abovePath || ''} />
              </ClipPath>
            </g>
          )
        }}
      </Area>
      <LinePath
        curve={curve}
        data={data}
        defined={defined}
        x={x}
        y={y}
        stroke={color}
        strokeWidth={2}
        fill="none"
        clipPath={`url(#threshold-clip-below-${id})`}
      />
      <LinePath
        curve={curve}
        data={data}
        defined={(d) => defined(d) && threshold(d) > -1}
        x={x}
        y={y}
        stroke="red"
        strokeWidth={2}
        fill="none"
        clipPath={`url(#threshold-clip-above-${id})`}
      />
    </>
  )
}
