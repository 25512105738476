import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'

import { Breadcrumbs } from '@common/components'
import { useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'

import EventDashboardContent from './Tabs/Dashboard/EventDashboardContent'
import EventReviewContent from './Tabs/Review/EventReviewContent'

export default function EventDashboard() {
  const isSmallScreen = useSmallScreen()

  const { currentRole } = useConnect('selectCurrentRole')

  const tabs = [
    { position: 0, label: 'Event Dashboard', component: EventDashboardContent },
    { position: 1, label: 'Event Review Queue', component: EventReviewContent },
  ]

  const [tabIndex, setTabIndex] = useState(currentRole !== 'EVENT_REVIEWER' ? 0 : 1)

  const ContentComponent = tabs.find((tab) => tab.position === tabIndex).component

  return (
    <Box m={3} display="flex" flexDirection="column">
      <Breadcrumbs
        links={[{ label: 'Home', href: homeUrls.home }, { label: 'Events' }]}
      />
      <Box sx={{ mb: 4 }}>
        {isSmallScreen ? (
          <FormControl fullWidth size="small" sx={{ my: 1 }}>
            <Select
              value={tabIndex}
              IconComponent={KeyboardArrowDown}
              onChange={(event) => setTabIndex(event.target.value)}
            >
              {tabs.map((option, index) => (
                <MenuItem key={option.label} value={index}>
                  {option.label} View
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Tabs value={tabIndex} textColor="primary" indicatorColor="primary">
            {tabs.map(({ label, position }) => (
              <Tab
                key={`${label}_${position}`}
                onClick={() => setTabIndex(position)}
                label={
                  <Typography variant="h5" sx={{ textTransform: 'none' }}>
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        )}
      </Box>
      <ContentComponent />
    </Box>
  )
}
