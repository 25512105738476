import { DateTime } from 'luxon'

import { DetailStack } from '..'

const DEFAULT_VALUE = '--'
const SIZE = 14

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string|[]} [props.cellValue]
 */
export default function StackedListCell({ cellValue }) {
  if (!cellValue) {
    return DEFAULT_VALUE
  }

  if (Array.isArray(cellValue)) {
    if (cellValue.length === 2) {
      return <DetailStack label={cellValue[0]} value={cellValue[1]} size={SIZE} />
    }

    return cellValue.join(' ')
  }

  const date = DateTime.fromISO(cellValue)

  if (date) {
    return (
      <DetailStack
        label={date.toLocaleString(DateTime.DATE_MED)}
        value={date.toLocaleString(DateTime.TIME_SIMPLE)}
        size={SIZE}
      />
    )
  }

  return cellValue || DEFAULT_VALUE
}
