const colorsPool = [
  '#7F66B3',
  '#669CC3',
  '#CF8473',
  '#344B78',
  '#547E7C',
  '#C55A86',
  '#F5B961',
  '#B8B8B8',
]

export default function getSensorTestingTypesMeta({
  data,
  systemDataTypes,
  systemSmokeProfiles,
  devices,
  tooltipBuilder,
}) {
  const tagsMeta = devices
    .map((device) =>
      systemSmokeProfiles.reduce((acc, profile, i) => {
        const key = `${profile.id}:device:${device?.id}`
        return {
          ...acc,
          [key]: {
            key,
            name: key,
            tooltipLabel: `[${device?.zoneName}]: ${profile.modelKey} - ${profile.name}`,
            color: colorsPool[i % colorsPool.length],
            unit: '%',
            template: '{{ value }} %',
            visible: true,
            scatter: true,
            scatterShowNull: true,
            scatterNullRenderValue: 50,
          },
        }
      }, {}),
    )
    .reduce((acc, item) => ({ ...acc, ...item }), {})

  const dataTypes = data
    .map((dt, i) => {
      const systemDataType = systemDataTypes.find((type) =>
        type.key.startsWith(dt.dataType),
      )
      let template = `{{ value }}`
      let unit = ''
      if (systemDataType) {
        template = systemDataType.template
        unit = systemDataType.template.replace('{{ value }}', '').trim()
      }
      return {
        [dt.key]: {
          key: dt.key,
          name: dt.key,
          unit: dt.dataType === 'nrs' ? 'NRS' : unit,
          template,
          tooltipLabel: tooltipBuilder ? tooltipBuilder(dt) : `${dt.name}`,
          color: colorsPool[i % colorsPool.length],
          visible: true,
        },
      }
    })
    .reduce((acc, item) => ({ ...acc, ...item }), {})

  return { ...dataTypes, ...tagsMeta }
}
