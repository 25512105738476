import { memo } from 'react'

import { Stack, ToggleButtonGroup, Typography } from '@mui/material'

import { ToggleButtonWithTooltip } from '@common/components'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.selectedInterval
 * @param {string} [props.selectedInterval.tooltip]
 * @param {string} [props.selectedInterval.label]
 * @param {Function} props.setSelectedInterval
 * @param {Object} props.availableIntervals
 * @param {boolean} props.disabled
 */
const AggregationSelector = memo(
  ({ selectedInterval, setSelectedInterval, availableIntervals, disabled }) => (
    <Stack direction="column" spacing={1} ml={1}>
      <Typography variant="body1">Aggregation</Typography>
      <ToggleButtonGroup
        color="primary"
        value={selectedInterval}
        exclusive
        onChange={(_, selectedValue) => {
          if (selectedValue !== null) {
            setSelectedInterval(selectedValue)
          }
        }}
        disabled={disabled}
      >
        {Object.keys(availableIntervals).map((key) => {
          const interval = availableIntervals[key]
          return (
            <ToggleButtonWithTooltip
              key={key}
              value={interval}
              tooltip={interval.tooltip}
              disabled={selectedInterval === interval}
              sx={{ py: 0.8 }}
            >
              <div>{interval.label}</div>
            </ToggleButtonWithTooltip>
          )
        })}
      </ToggleButtonGroup>
    </Stack>
  ),
)

export default AggregationSelector
