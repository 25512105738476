import { useCallback } from 'react'

import { Box, Link, Stack, Typography } from '@mui/material'

export default function PrivacyStatement() {
  const Section = useCallback(
    ({ title, children }) => (
      <Box>
        <Typography variant="h6" fontWeight="bold" fontStyle="italic">
          {title}
        </Typography>
        <Typography>{children}</Typography>
      </Box>
    ),
    [],
  )

  return (
    <Stack>
      <Typography variant="h4">Privacy Statement</Typography>
      <Typography variant="body1" mt={1}>
        Welcome to Rest smoking detection software. Your privacy is paramount to us.
        This Privacy Statement outlines our practices concerning the handling of your
        personal data when you use this web service.
      </Typography>

      <Box display="flex" flexDirection="column" mt={4} gap={2}>
        <Section title="Information Collection">
          Personal Information: We collect personal information such as your name, email
          address, and contact details when you register or interact with our services.
          This information is essential for providing you with personalized and
          efficient service.
        </Section>
        <Section title="Use of Information">
          The personal data we collect is used to maintain the quality of our service,
          notify you of changes to our service, provide customer support, conduct
          analytical reviews aimed at improving our services, ensure the security of our
          services, and comply with legal obligations. Communication with Marriott
          employees will strictly pertain to customer service related to transactions or
          inquiries about our services.
        </Section>
        <Section title="Disclosure of Information">
          We may share your information with trusted third-party service providers who
          assist us in operating our website, conducting our business, or servicing you,
          so long as those parties agree to keep this information confidential. We may
          also release your information when we believe release is appropriate to comply
          with the law, enforce our site policies, or protect ours or others rights,
          property, or safety.
        </Section>
        <Section title="Security of Data">
          The security of your personal data is critical to us. We protect the
          transmission of your personal data using commercially acceptable encryption,
          such as Secure Socket Layer (SSL). We also implement additional security
          measures at the data storage level to prevent unauthorized access, disclosure,
          alteration, or destruction of your personal information, username, password,
          transaction information, and data stored on our site.
        </Section>
        <Section title="Changes to This Privacy Statement">
          We may update our Privacy Statement periodically. We will notify you of any
          changes by posting the new Privacy Statement on this page. We encourage you to
          review this Privacy Statement periodically for any changes.
        </Section>
        <Section title="Contact Us">
          If you have any questions about this Privacy Statement, please contact us at{' '}
          <Link href="mailto:support@restsensor.com">support@restsensor.com</Link>.
        </Section>
      </Box>
    </Stack>
  )
}
