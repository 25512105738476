import { useState } from 'react'

import { Close, ExpandLess, ExpandMore, LaunchOutlined } from '@mui/icons-material'
import {
  Box,
  Card,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 */
export default function GuestMessagingInfo({ open, onClose }) {
  const [accordionOpen, setAccordionOpen] = useState(false)

  const rowStyle = { p: 0, pt: 1.5 }
  const headerStyle = { p: 0, color: 'grey.500', backgroundColor: 'transparent' }

  const titleStyle = { variant: 'h6', fontWeight: '600' }
  const textStyle = { variant: 'body2', color: 'grey.600' }
  const accordionIconStyle = { fontSize: 'large', sx: { color: 'grey.500' } }

  const autoresolveHelpLink =
    'https://help.noiseaware.io/hc/en-us/articles/4408047697939-AutoResolve-by-NoiseAware'

  const attributes = [
    { tag: '{{ sensor_name }}', description: 'Displays the sensor name' },
    { tag: '{{ property_name }}', description: 'Displays the property name' },
    { tag: '{{ start_time }}', description: 'Displays event start time' },
    { tag: '{{ escalation_stage }}', description: 'Displays the escalation stage' },
    { tag: '{{ guest_name }}', description: 'Displays the guest name' },
    { tag: '{{ org_name }}', description: 'Displays the organization name' },
    { tag: '{{ unit_name }}', description: 'Displays the unit name' },
  ]

  return (
    <Dialog
      fullWidth
      data-testid="messaging_info_modal"
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableScrollLock
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        Guest Messaging
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ mx: 3 }} />
      <DialogContent>
        <Typography {...titleStyle} sx={{ mb: 2 }}>
          How Guest Messaging Works
        </Typography>
        <Typography {...textStyle}>
          {`The guest will receive up to two notifications if they are consistently noisy.
          The first message is sent as soon as 10 minutes after they cross the noise
          threshold for your property, depending on the guest's noise trends.`}
        </Typography>
        <Typography {...textStyle} sx={{ my: 2 }}>
          If the noise continues for another 10 minutes, then they are sent a follow up
          message.
        </Typography>
        <Typography {...textStyle} sx={{ mb: 4 }}>
          {`If the noise continues for another 10 minutes, then you are sent a
          notification of the guest's continual noise. It is up to you how you would like
          to proceed, but we recommend a call to the guest or a visit to the property
          when possible. No further messages will be automated at this point.`}
        </Typography>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setAccordionOpen(!accordionOpen)}
        >
          <Typography {...titleStyle} sx={{ my: 2 }}>
            Customizing Automated Messages
          </Typography>
          {accordionOpen ? (
            <ExpandLess {...accordionIconStyle} />
          ) : (
            <ExpandMore {...accordionIconStyle} />
          )}
        </Box>
        <Collapse in={accordionOpen}>
          <Typography {...textStyle}>
            Messages are fully customizable. We recommend keeping messages no longer
            that 160 characters. This will keep the message short and visible, giving a
            mindful attention for the prompt. Note: Character count is an approximation
            when using variables.
          </Typography>
          <Card
            elevation={0}
            sx={{
              py: 2,
              px: 4,
              my: 2,
              backgroundColor: 'grey.50',
              borderRadius: '8px',
            }}
          >
            <Typography {...titleStyle} color="grey.600">
              Message Attributes
            </Typography>
            <Typography {...textStyle}>
              The variables below can be used in your messages to include attributes
              about the guest, alert or the property.
            </Typography>
            <Box sx={{ ml: 2, mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ th: { border: 0, py: 1 } }}>
                    <TableCell sx={headerStyle}>Attribute</TableCell>
                    <TableCell sx={headerStyle}>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attributes.map((row) => (
                    <TableRow
                      key={row.tag}
                      sx={{ '&:last-child td': { border: 0 }, td: { pb: 1 } }}
                    >
                      <TableCell sx={{ ...rowStyle, color: 'grey.800' }}>
                        {row.tag}
                      </TableCell>
                      <TableCell sx={rowStyle}>{row.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Collapse>
        <Divider />
        <Link
          href={autoresolveHelpLink}
          target="_blank"
          display="flex"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography>Learn more about Guest Messaging</Typography>
          <LaunchOutlined fontSize="small" color="primary.main" sx={{ ml: 1 }} />
        </Link>
      </DialogContent>
    </Dialog>
  )
}
