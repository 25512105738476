import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { LineAxis } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  SearchBox,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { useQueryFilter } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import propertiesUrls from '@portal/pages/Properties/urls'
import { BooleanCell, MainCell } from '@portal/UI/components/cells'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/ProList'
import boolOptions from '@portal/Utils/constants'

import RulesRow from './RulesRow'
import thresholdManagementUrls from './urls'

function ListEmptyState() {
  const {
    thresholdProfileListRaw: { filter, search },
  } = useConnect('selectThresholdProfileListRaw')

  const activeFilters =
    search !== '' ||
    filter?.orgazation ||
    filter?.account ||
    filter?.property ||
    filter?.active

  return (
    <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
      <LineAxis sx={{ fontSize: 60, mb: 2 }} />
      {activeFilters
        ? 'No threshold profiles match the selected filters'
        : 'No threshold profiles have been assigned to this property yet.'}
    </Stack>
  )
}

export default function ThresholdManagement() {
  const [pageSize, setPageSize] = useState('')

  const {
    thresholdProfileList,
    thresholdProfileListApiParams,
    thresholdProfileListIsLoading,
    queryObject,
    doMarkThresholdProfileListAsOutdated,
    doThresholdProfileListSetOrdering,
    doThresholdProfileListClearParams,
    doThresholdProfileListSetFilter,
    doThresholdProfileListSetPage,
    doThresholdProfileListSetPageSize,
    doUpdateQuery,
    doUpdateUrl,
  } = useConnect(
    'selectThresholdProfileList',
    'selectThresholdProfileListApiParams',
    'selectThresholdProfileListIsLoading',
    'selectQueryObject',
    'doMarkThresholdProfileListAsOutdated',
    'doThresholdProfileListSetOrdering',
    'doThresholdProfileListClearParams',
    'doThresholdProfileListSetFilter',
    'doThresholdProfileListSetPage',
    'doThresholdProfileListSetPageSize',
    'doUpdateQuery',
    'doUpdateUrl',
  )

  useQueryFilter({
    query: queryObject,
    apiParams: thresholdProfileListApiParams,
    setFilter: doThresholdProfileListSetFilter,
    setPageSize: doThresholdProfileListSetPageSize,
    defaultParams: { page: 1, pageSize: 25 },
  })

  useEffect(() => {
    doMarkThresholdProfileListAsOutdated()
  }, [])

  const handleClear = () => {
    doThresholdProfileListClearParams()
    doThresholdProfileListSetFilter('')
  }

  const handlePageChange = async (page) => {
    doThresholdProfileListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doThresholdProfileListSetPageSize(size)
  }

  const handleRowClick = (profile) => {
    const updatedId = thresholdManagementUrls.entity.replace(':id', profile.id)
    doUpdateUrl(updatedId)
  }

  const queryDrivenSearchHandler = (search) => {
    const queryParams = { ...queryObject, page: 1, search }
    if (!queryParams.search) {
      delete queryParams.search
    }
    doUpdateQuery(queryParams)
  }

  const detailRowContent = useCallback(
    ({ row }) => (row.rules.length === 0 ? null : <RulesRow rules={row.rules} />),
    [thresholdProfileList],
  )

  const detailRowHeight = useCallback(() => 'auto', [])

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }) => (
        <MainCell label={row.name} onClick={() => handleRowClick(row)} />
      ),
      flex: 0.5,
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 1,
    },
    {
      field: 'property',
      headerName: 'Property',
      sortable: false,
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.propertyName}
          url={propertiesUrls.entity.replace(':id', row.property)}
        />
      ),
      flex: 0.5,
    },
    {
      field: 'assigned',
      headerName: 'Assigned',
      flex: 0.3,
      type: 'boolean',
      renderCell: BooleanCell,
      headerAlign: 'center',
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      sortable: false,
      flex: 0.3,
    },
    {
      field: 'rules',
      headerName: 'Rules',
      sortable: false,
      valueGetter: (_, row) => row.rules.length,
      flex: 0.2,
    },
  ]

  const rows = thresholdProfileList?.results?.map((profile) => ({
    id: profile.id,
    property: profile.property,
    propertyName: profile.propertyName,
    name: profile.name,
    description: profile.description,
    dataType: profile.dataType,
    rules: profile.rules.filter((rule) => rule.deviceStyle === 'INDOOR'), // remove outdoor for now
    assigned: profile.assigned,
  }))

  if (!thresholdProfileList.results)
    return <ErrorComponent title="Threshold Profiles" callback={handleClear} />

  return (
    <Box m={3} display="flex" flexDirection="column">
      <Breadcrumbs
        links={[
          { label: 'Home', href: homeUrls.home },
          { label: 'Threshold Management' },
        ]}
      />
      <Typography variant="h3" mb={2}>
        Threshold Management
      </Typography>
      <Box display="flex">
        <Filter
          disabled={thresholdProfileListIsLoading}
          apiParams={thresholdProfileListApiParams}
          setApiParams={doUpdateQuery}
        >
          <DynamicSelect label="Organizations" filterName="organization" />
          <DynamicSelect label="Accounts" filterName="account" />
          <DynamicSelect label="Properties" filterName="property" />
          <StaticSelect label="Assigned" filterName="assigned" options={boolOptions} />
        </Filter>
        <Box ml={2} flex={1} overflow="hidden" minHeight="900px">
          <SearchBox
            title="Threshold Profiles"
            onSetSearch={queryDrivenSearchHandler}
          />
          <List
            autoHeight
            loading={thresholdProfileListIsLoading}
            columns={columns}
            page={thresholdProfileList.current || 1}
            pageChange={handlePageChange}
            pageSize={thresholdProfileList.pageSize}
            pageSizeChange={handlePageSizeChange}
            rows={rows}
            rowCount={thresholdProfileList.count || 0}
            sortChange={doThresholdProfileListSetOrdering}
            hideFooter={(thresholdProfileList?.numPages ?? 0) <= 1}
            getDetailPanelContent={detailRowContent}
            getDetailPanelHeight={detailRowHeight}
            slots={{ noRowsOverlay: ListEmptyState }}
          />
        </Box>
      </Box>
    </Box>
  )
}
