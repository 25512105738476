import { Field, useFormikContext } from 'formik'

import { StaticSelect } from '@common/components/Selects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string[]} props.availableCommands
 * @param {string[]} props.nonValueCommands
 * @param {string} [props.commandValue]
 * @param {Function} props.setCommandValue
 * @param {Object} props.sx
 */
export default function CommandField({
  availableCommands,
  nonValueCommands,
  commandValue = '',
  setCommandValue,
  sx,
}) {
  const form = useFormikContext()

  const { value: commandFieldValue } = form.getFieldProps('command')
  if (commandFieldValue !== commandValue) {
    setCommandValue(commandFieldValue)
  }

  return (
    <Field
      required
      component={StaticSelect}
      label="Command"
      name="command"
      variant="standard"
      options={availableCommands}
      onChange={(value) => {
        setCommandValue(value)
        form.setFieldValue('command', value)
        if (nonValueCommands.includes(value)) {
          form.setFieldValue('operation', null)
        }
      }}
      sx={sx}
    />
  )
}
