import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { ArrowBack, NotificationsPaused } from '@mui/icons-material'
import { Box, Button, Paper, Stack, Tooltip, Typography } from '@mui/material'

import {
  Breadcrumbs,
  ErrorMessage,
  Loading,
  MobileDetailRow,
  TabComponent,
} from '@common/components'
import {
  humanizedAgo,
  parseApiErrors,
  useHashFilter,
  useSmallScreen,
} from '@common/utils'
import propertyUrls from '@rest/pages/Properties/urls'
import { AqiDetailItem, DetailStack } from '@rest/UI/components'
import { useFlag } from '@rest/Utils'

import DeviceStatusChip from '../Properties/PropertyDevices/DeviceStatusChip'
import { Reservations } from '../Reservations'
import DevicesTab from './tabs/DevicesTab'
import EventsTab from './tabs/EventsTab'

export default function UnitDetail() {
  const {
    routeParams: { id: unitId },
    hashObject,
    currentOrganizationDetails: organization,
    currentAccountDetails: account,
    unitDetails,
    unitDetailsIsLoading,
    unitDetailsError,
    doFetchUnitDetails,
    doResetUnitDetailsState,
    doSetCurrentProperty,
    doSetHeaderLevel,
    doShowSnackbar,
  } = useConnect(
    'selectRouteParams',
    'selectHashObject',
    'selectCurrentOrganizationDetails',
    'selectCurrentAccountDetails',
    'selectUnitDetails',
    'selectUnitDetailsIsLoading',
    'selectUnitDetailsError',
    'doFetchUnitDetails',
    'doResetUnitDetailsState',
    'doSetCurrentProperty',
    'doSetHeaderLevel',
    'doShowSnackbar',
  )

  const isSmallScreen = useSmallScreen()

  const [tabValue, setTabValue] = useState(0)

  const reservationEnabled = useFlag(['RESERVATION_INPUT'], unitDetails?.property)
  const aqiEnabled = useFlag(['AQI'], unitDetails?.property)

  const tabs = useMemo(() => {
    if (unitDetails) {
      const { property, unit } = unitDetails
      return [
        {
          label: 'Events',
          component: <EventsTab property={property} unit={unit} />,
          hash: 'events',
        },
        {
          label: 'Sensors',
          component: <DevicesTab property={property} />,
          hash: 'sensors',
        },
        ...(reservationEnabled
          ? [
              {
                label: 'Reservations',
                component: <Reservations level="unit" />,
                hash: 'reservations',
              },
            ]
          : []),
      ]
    }
    return []
  }, [unitDetails])

  const fetchDetails = useCallback(async (id) => {
    try {
      await doFetchUnitDetails(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useHashFilter(isEmpty(hashObject) ? tabs[0]?.hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  useEffect(() => {
    fetchDetails(unitId)
    return () => {
      doResetUnitDetailsState()
    }
  }, [unitId])

  useEffect(() => {
    const { property } = unitDetails ?? {}
    if (property) {
      doSetCurrentProperty(property.id)
      doSetHeaderLevel('property')
    }
  }, [unitDetails?.property])

  const unitDetailsItems = useMemo(() => {
    const { unit, unitLastSeen } = unitDetails ?? {}
    if (unit) {
      const details = [
        { label: 'Sensors', text: unit.deviceCount },
        {
          label: 'Most Recent Data',
          text: unitLastSeen ? humanizedAgo(unitLastSeen) : null,
        },
      ]
      if (aqiEnabled) {
        details.push({ label: 'AQI', text: unit.dailyAqi })
      }
      return details
    }
    return []
  }, [unitDetails?.unit])

  if (unitDetailsIsLoading) {
    return <Loading />
  }

  if (unitDetailsError || (!unitDetailsIsLoading && !unitDetails)) {
    return (
      <ErrorMessage>
        {parseApiErrors(unitDetailsError?.response) || 'Oops. Something went wrong'}
      </ErrorMessage>
    )
  }

  const { property, unit } = unitDetails

  return (
    <>
      <Breadcrumbs
        links={[
          { label: organization.name },
          { href: propertyUrls.list, label: account.name },
          {
            href: propertyUrls.entity.replace(':id', property.id),
            label: property.name,
          },
          { label: unit.name },
        ]}
      />
      <Box mt={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button
            startIcon={<ArrowBack />}
            href={`${propertyUrls.entity.replace(':id', unit.property)}#units`}
            size={isSmallScreen ? 'small' : 'medium'}
          >
            BACK TO PROPERTY DETAILS
          </Button>
          {!isSmallScreen && (
            <Typography variant="caption" color="text.secondary">
              id: {unitId}
            </Typography>
          )}
        </Stack>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" width="100%">
            <Stack sx={{ ml: isSmallScreen ? 0 : 3 }} direction="column" width="100%">
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant={isSmallScreen ? 'h5' : 'h4'}>
                  {unit.name}
                </Typography>
                <DeviceStatusChip status={unit.status} />
                {unit.snoozed && (
                  <Tooltip title="Snoozed">
                    <NotificationsPaused color="secondary" />
                  </Tooltip>
                )}
              </Box>

              {isSmallScreen ? (
                <Box mt={1.5} display="flex" flexDirection="column">
                  {unitDetailsItems.map((detail) => (
                    <MobileDetailRow
                      key={detail.label}
                      label={detail.label}
                      value={detail.text}
                      renderValue={
                        detail.label.includes('AQI')
                          ? (value) => <AqiDetailItem index={value} />
                          : null
                      }
                    />
                  ))}
                </Box>
              ) : (
                <Box mt={1.5} display="flex" gap={4}>
                  {unitDetailsItems.map((detail) => (
                    <DetailStack
                      key={detail.label}
                      label={detail.label}
                      value={detail.text}
                      renderValue={
                        detail.label.includes('AQI')
                          ? (value) => <AqiDetailItem index={value} />
                          : null
                      }
                    />
                  ))}
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pb: 2 }}>
        <Paper elevation={2} sx={{ mt: 4, bgcolor: 'grey.50' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabComponent
              tabs={tabs}
              externalState={{ value: tabValue, setValue: setTabValue }}
              color="secondary"
              contentSx={{ p: isSmallScreen ? 0 : 2 }}
              tabsSx={{ mx: 2 }}
              sx={isSmallScreen ? { m: 2 } : null}
            />
          </Box>
        </Paper>
      </Box>
    </>
  )
}
