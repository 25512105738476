import { createListBundle } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'

const entityName = 'propertyReview'

const propertyReviewsListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) =>
    apiFetch(
      '/property_reviews/',
      { property: store.selectRouteParams().id, ...params },
      { cancelationPrefix: entityName },
    ),
  urlTest: (_, pattern, hash) => propertyUrls.entity === pattern && hash === 'property',
})

export default propertyReviewsListBundle
