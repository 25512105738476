import { Dialog, DialogContent } from '@mui/material'

import PropertyEventListRowDetail from './PropertyEventListRowDetail'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} props.data
 * @param {Object} props.data.row
 * @param {Object} props.data.property
 * @param {string} [props.data.property.timezone]
 * @param {number} [props.data.property.unitCount]
 * @param {Object} props.data.reportActions
 * @param {Function} props.data.reportActions.setEventReportOpen
 * @param {Function} props.data.reportActions.setOldEventReportOpen
 * @param {Function} props.data.reportActions.setActiveInstance
 */
export default function AdditionalOccurenciesModal({
  open,
  onClose,
  data = undefined,
}) {
  if (!data) return null

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <PropertyEventListRowDetail
          {...data.row}
          mobileView
          zone={data.property.timezone}
          isPrunit={data.property.unitCount === 1}
          onReportClick={(childRow) => {
            const isSmoke = childRow?.eventClass === 'SMOKE'
            if (!isSmoke || childRow?.metadata?.smoke?.alarmTag) {
              data.reportActions.setEventReportOpen(true)
            } else {
              data.reportActions.setOldEventReportOpen(true)
            }
            data.reportActions.setActiveInstance(childRow)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
