import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Edit, PlayArrowRounded, Settings, StopRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'

import { DateTime } from 'luxon'

import { Breadcrumbs } from '@common/components'
import { Chart } from '@common/components/chart'
import { DynamicSelect, StaticMultiSelect } from '@common/components/Selects'
import { parseApiErrors, useLocalStorage } from '@common/utils'
import { days, hours, minutes } from '@common/utils/durations'
import accountUrls from '@portal/pages/Accounts/urls'
import deviceUrls from '@portal/pages/Devices/urls'
import { homeUrls } from '@portal/pages/Home'
import organizationUrls from '@portal/pages/Organizations/urls'
import propertyUrls from '@portal/pages/Properties/urls'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'

import {
  GRAFANA_GREEN,
  GRAFANA_RED,
  GRAFANA_YELLOW,
} from '../EventDashboard/EventModal/eventTypesMeta'
import BeginTestForm from './BeginTestForm'
import getSensorTestingTypesMeta from './sensorTestingTypesMeta'
import StatusChip from './StatusChip'

const TEST_MIN_DURATION_IN_MINUTES = 15

const dataTypesOptions = [
  {
    id: 'mc_1p0_mean',
    label: 'PM 0.3-1.0 (subtracted) ',
  },
  { id: 'nrs', label: 'NRS' },
  { id: 'eco2_mean', label: 'CO2' },
  { id: 'tvoc_mean', label: 'TVOC' },
  { id: 'temp_mean', label: 'Temp' },
  { id: 'hum_mean', label: 'RH' },
  { id: 'probabilities', label: 'Probabilities' },
]

const dateOptions = [
  { title: 'TEST', value: null },
  { title: '15M', value: 15 * minutes },
  { title: '30M', value: 30 * minutes },
  { title: '1H', value: 1 * hours },
  { title: '3H', value: 3 * hours },
  { title: '6H', value: 6 * hours },
  { title: '1D', value: 1 * days },
]

const deviceTableRowStyle = { p: 0, pt: 1.5 }
const deviceTableHeaderStyle = { p: 0, color: 'grey.600' }

const refreshTimeInMillis = 60000

export default function SensorTesting() {
  const defaultDataTypes = [dataTypesOptions[0]]
  const defaultTimeRangeOption = dateOptions[3].value

  const defaultTimeRange = [DateTime.now().minus({ hours: 1 }), DateTime.now()]

  const [chartData, setChartData] = useState(null)

  const [settingsPopoverAnchor, setSettingsPopoverAnchor] = useState(null)

  const [selectedUnit, setSelectedUnit] = useState(null)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [selectedDataTypes, setSelectedDataTypes] = useState(defaultDataTypes)
  const [selectedDateTimeRange, setSelectedDateTimeRange] = useState(defaultTimeRange)

  const [unitSelectorState, setUnitSelectorState] = useState(null)
  const [dataTypesSelectorState, setDataTypesSelectorState] = useState(defaultDataTypes)

  const [beginTestFormData, setBeginTestFormData] = useState(null)
  const [endTestFormOpen, setEndTestFormOpen] = useState(false)
  const [abortTestFormOpen, setAbortTestFormOpen] = useState(false)
  const [dateTimeRangeOption, setDateTimeRangeOption] = useState(defaultTimeRangeOption)
  const [chartAutoRefresh, setChartAutoRefresh] = useState(true)
  const [showStartEndPoints, setShowStartEndPoints] = useState(true)

  const [testData, setTestData] = useLocalStorage('sensorTestingData', {})

  const {
    isAtLeastDataScience,
    sensorTestingUnitDataIsLoading,
    sensorTestingDevicesChartsIsLoading,
    sensorTestingUnitData,
    sensorTestingDevicesCharts,
    sensorTestingUnitDataError,
    sensorTestingDevicesChartsError,
    systemDataTypes,
    systemSmokeProfiles,
    queryObject,
    doFetchSensorTestingUnitData,
    doFetchSensorTestingDevicesCharts,
    doScenarioCreateFromReadings,
    doResetSensorTestingState,
    doShowSnackbar,
    doUpdateQuery,
  } = useConnect(
    'selectIsAtLeastDataScience',
    'selectSensorTestingUnitDataIsLoading',
    'selectSensorTestingDevicesChartsIsLoading',
    'selectSensorTestingUnitData',
    'selectSensorTestingDevicesCharts',
    'selectSensorTestingUnitDataError',
    'selectSensorTestingDevicesChartsError',
    'selectSystemDataTypes',
    'selectSystemSmokeProfiles',
    'selectQueryObject',
    'doFetchSensorTestingUnitData',
    'doFetchSensorTestingDevicesCharts',
    'doScenarioCreateFromReadings',
    'doResetSensorTestingState',
    'doShowSnackbar',
    'doUpdateQuery',
  )

  const unitTestData = useMemo(
    () => testData[unitSelectorState],
    [testData, unitSelectorState],
  )

  const getTestDateTimeRange = () => [
    DateTime.fromISO(unitTestData.start).minus({ minutes: 5 }),
    DateTime.now(),
  ]

  const handleError = (err) => {
    const parsedError = parseApiErrors(err)
    doShowSnackbar(parsedError, 'error')
  }

  useEffect(
    () => () => {
      doResetSensorTestingState()
      setSelectedUnit(null)
      setSelectedDevices([])
    },
    [],
  )

  useEffect(() => {
    setUnitSelectorState(queryObject.unit)
  }, [queryObject])

  useEffect(() => {
    if (unitSelectorState) {
      doFetchSensorTestingUnitData({ unit: unitSelectorState }).catch(handleError)
    } else {
      doResetSensorTestingState()
    }
    setSelectedDevices([])
  }, [unitSelectorState])

  useEffect(() => {
    setSelectedUnit(sensorTestingUnitData?.unit)
    if (unitTestData?.devices) {
      setSelectedDevices(unitTestData.devices)
    } else if (sensorTestingUnitData) {
      setSelectedDevices(sensorTestingUnitData.devices.map((device) => device.id))
    }
  }, [sensorTestingUnitData])

  useEffect(() => {
    let refreshInterval
    if (
      unitSelectorState &&
      selectedDevices.length > 0 &&
      selectedDataTypes?.length > 0
    ) {
      doFetchSensorTestingDevicesCharts({
        devices: selectedDevices,
        types: selectedDataTypes.map((type) => type.id),
        timeRange: selectedDateTimeRange,
      }).catch(handleError)
      if (chartAutoRefresh) {
        refreshInterval = setInterval(() => {
          const newTimeRange = dateTimeRangeOption
            ? [
                DateTime.now().minus({
                  milliseconds: dateTimeRangeOption,
                }),
                DateTime.now(),
              ]
            : getTestDateTimeRange()
          setSelectedDateTimeRange(newTimeRange)
        }, refreshTimeInMillis)
      }
    }
    return () => clearInterval(refreshInterval)
  }, [selectedDevices, selectedDataTypes, selectedDateTimeRange, chartAutoRefresh])

  useEffect(() => {
    if (sensorTestingDevicesCharts) {
      setChartData(sensorTestingDevicesCharts.charts)
    }
  }, [sensorTestingDevicesCharts])

  useEffect(() => {
    if (sensorTestingUnitDataError) {
      handleError(sensorTestingUnitDataError)
    }
  }, [sensorTestingUnitDataError])

  useEffect(() => {
    if (sensorTestingDevicesChartsError) {
      handleError(sensorTestingDevicesChartsError)
    }
  }, [sensorTestingDevicesChartsError])

  const tooltipBuilder = useCallback(
    (data) => {
      const typeOption = dataTypesOptions.find((option) => option.id === data.dataType)
      return `[${data?.name}]: ${typeOption?.label}`
    },
    [dataTypesOptions],
  )

  const dataTypes = useMemo(
    () =>
      getSensorTestingTypesMeta({
        data: chartData ?? [],
        systemDataTypes,
        systemSmokeProfiles: selectedDataTypes.find(
          (type) => type.id === 'probabilities',
        )
          ? systemSmokeProfiles
          : [],
        devices: selectedDevices.map((id) =>
          sensorTestingUnitData?.devices?.find((device) => device.id === id),
        ),
        tooltipBuilder: selectedDataTypes.length > 1 ? tooltipBuilder : null,
      }),
    [chartData, systemDataTypes, selectedDataTypes, sensorTestingUnitData],
  )

  const transformedData = useMemo(
    () =>
      chartData
        ?.reduce((acc, dt) => {
          const points = dt.data.reduce((arr, pointData) => {
            const d = [
              ...arr,
              {
                time: DateTime.fromISO(pointData.time),
                [`${dt.key}`]: pointData.value,
              },
            ]
            return d
          }, [])

          return [...acc, ...points].reduce((result, point) => {
            const { time, ...rest } = point
            const existingPoint = result.find((item) => item.time.ts === time.ts)

            if (existingPoint) {
              Object.assign(existingPoint, rest)
            } else {
              result.push(point)
            }

            return result
          }, [])
        }, [])
        .sort((a, b) => a.time - b.time),
    [chartData, dataTypes],
  )

  const onDeviceChecked = ({ event, device }) => {
    const isChecked = event.target.checked
    setSelectedDevices((prev) => {
      const arr = [...prev]
      if (isChecked) {
        arr.push(device.id)
      } else {
        const index = arr.indexOf(device.id)
        if (index > -1) {
          arr.splice(index, 1)
        }
      }
      return arr
    })
  }

  const onBeginTestPressed = () => setBeginTestFormData({})

  const onEndTestPressed = () => setEndTestFormOpen(true)

  const removeTestDataFromStorage = () => {
    const updatedData = { ...testData }
    delete updatedData[selectedUnit?.id]
    setTestData(updatedData)
    if (!dateTimeRangeOption) {
      setDateTimeRangeOption(defaultTimeRangeOption)
      setSelectedDateTimeRange(defaultTimeRange)
    }
  }

  const handleBeginTest = (formData) => {
    setTestData({
      ...testData,
      [selectedUnit.id]: {
        devices: testData[selectedUnit.id]?.devices ?? [...selectedDevices],
        start: testData[selectedUnit.id]?.start ?? DateTime.now().toISO(),
        notes: formData.notes,
        tags: [...formData.scenarioTags],
      },
    })
  }

  const handleEndTest = async () => {
    try {
      const result = await Promise.all(
        selectedDevices.map((deviceId) => {
          const deviceData = sensorTestingUnitData.devices.find(
            (device) => device.id === deviceId,
          )
          const scenarioName = `[${selectedUnit.propertyName}] ${
            deviceData.zoneName
          } - ${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)}`
          return doScenarioCreateFromReadings({
            name: scenarioName,
            description: unitTestData.notes
              ? `Testing screen\n${unitTestData.notes}`
              : 'Testing screen',
            readingStart: unitTestData.start,
            readingEnd: DateTime.now().toISO(),
            sourceDevice: deviceId,
            deviceModel: deviceData.model,
            scenarioTags: unitTestData.tags,
          })
        }),
      )
      const errorResult = result.find((item) => item.error)
      if (errorResult) {
        throw errorResult.error.response || 'Oops. An unexpected error occurred'
      }

      doShowSnackbar('Scenarios were successfully created', 'success')
      setSelectedDateTimeRange(
        dateTimeRangeOption
          ? [
              DateTime.now().minus({
                milliseconds: dateTimeRangeOption,
              }),
              DateTime.now(),
            ]
          : getTestDateTimeRange(),
      )
    } catch (err) {
      const parsedError = parseApiErrors(err)
      doShowSnackbar(parsedError, 'error')
    } finally {
      removeTestDataFromStorage()
    }
  }

  const UnitCardItem = useCallback(({ label, child, value, linkTo }) => {
    if (
      (child === undefined || child === null) &&
      (value === undefined || value === null)
    ) {
      throw Error('child or value should be provided!')
    }

    if (child) {
      return (
        <Box display="flex" gap={1}>
          <Typography>
            <b>{label}:</b>
          </Typography>
          {child}
        </Box>
      )
    }
    return (
      <Typography>
        <b>{label}:</b>{' '}
        {linkTo ? <Link href={linkTo}>{value || '--'}</Link> : value || '--'}
      </Typography>
    )
  }, [])

  const hasData =
    transformedData && transformedData.length > 0 && selectedDevices.length > 0

  const testArea = useMemo(
    () =>
      unitTestData
        ? [
            {
              id: selectedUnit?.id,
              start: DateTime.fromISO(unitTestData.start),
              end: DateTime.now(),
              payload: {
                testing: 'In Progress',
                start: DateTime.fromISO(unitTestData.start).toLocaleString(
                  DateTime.DATETIME_MED,
                ),
              },
              style: {
                dashedBackground: true,
                filledBackground: false,
                area: { color: 'grey', opacity: 0.1 },
                handle: { color: 'grey', dashArray: [4, 4], opacity: 0.6 },
              },
              priority: 0,
            },
          ]
        : [],
    [transformedData, unitTestData],
  )

  const scenariosAreas = useMemo(
    () =>
      sensorTestingDevicesCharts?.scenarios.map((scenario) => ({
        id: scenario.id,
        start: DateTime.fromISO(scenario.readingStart),
        end: DateTime.fromISO(scenario.readingEnd),
        payload: {
          label: `Scenario - ${scenario.name}`,
          createdOn: DateTime.fromISO(scenario.createdOn).toLocaleString(
            DateTime.DATETIME_MED,
          ),
          start: DateTime.fromISO(scenario.readingStart).toLocaleString(
            DateTime.DATETIME_MED,
          ),
          end: DateTime.fromISO(scenario.readingEnd).toLocaleString(
            DateTime.DATETIME_MED,
          ),
          ...scenario.labels.reduce(
            (acc, label, index) => ({
              ...acc,
              [index !== 0 ? `label_${index}_start` : 'labelStart']: DateTime.fromISO(
                label.start,
              ).toLocaleString(DateTime.DATETIME_MED),
              [index !== 0 ? `label_${index}_end` : 'labelEnd']: DateTime.fromISO(
                label.end,
              ).toLocaleString(DateTime.DATETIME_MED),
            }),
            {},
          ),
        },
        style: {
          dashedBackground: false,
          filledBackground: true,
          area: { color: '#5CBFB0', opacity: 0.1 },
          handle: { color: '#5CBFB0', dashArray: [4, 4], opacity: 0.6 },
        },
        priority: 10,
      })) ?? [],
    [transformedData, unitTestData],
  )

  const alertsAreas = useMemo(() => {
    const getAlarmColor = (alarm, unit) => {
      if (alarm.rejections) {
        return GRAFANA_RED
      }
      const relatedEvent = sensorTestingDevicesCharts?.events?.find(
        (event) => event.metadata?.smoke?.alarmId === alarm.id,
      )
      if (relatedEvent?.deletedOn) {
        return '#7F66B3'
      }
      return unit?.snooze ? GRAFANA_YELLOW : GRAFANA_GREEN
    }

    return (
      sensorTestingDevicesCharts?.alarms?.map((alarm) => {
        const formattedLabel = `${alarm.rejections ? 'REJECTED' : ''} Alarm ${alarm.id}`
        const formattedStart = alarm.start.toLocaleString(DateTime.DATETIME_MED)
        const formattedEnd =
          alarm.end?.toLocaleString(DateTime.DATETIME_MED) ?? 'Ongoing'
        const profile = systemSmokeProfiles.find((item) => item.id === alarm.tag)

        const color = getAlarmColor(alarm, selectedUnit)

        return {
          id: alarm.id,
          start: alarm.start,
          end: alarm.end ?? DateTime.now(),
          payload: {
            label: formattedLabel,
            start: formattedStart,
            end: formattedEnd,
            modelTag: profile ? `${profile.modelKey} - ${profile.name}` : null,
            rejections: alarm.rejections,
            metadata: JSON.stringify(alarm.metadata, null, 2),
          },
          style: {
            dashedBackground: false,
            filledBackground: true,
            area: { color, opacity: 0.1 },
            handle: { color, dashArray: [4, 4], opacity: 0.6 },
          },
          priority: alarm.rejections ? 20 : 30,
        }
      }) ?? []
    )
  }, [transformedData, unitTestData])

  const getCurrentTestDurationInMinutes = (start) => {
    const startTime = DateTime.fromISO(start)
    const currentTime = DateTime.now()
    const diffInMillis = currentTime - startTime
    return Math.floor(diffInMillis / 60000)
  }

  const isActionButtonDisabled =
    !hasData ||
    (unitTestData &&
      getCurrentTestDurationInMinutes(unitTestData.start) <
        TEST_MIN_DURATION_IN_MINUTES)

  const LoadingContainer = useCallback(
    ({ children, isLoading }) => (
      <Box
        display="flex"
        sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}
      >
        {isLoading && <CircularProgress sx={{ position: 'absolute' }} />}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            transitionProperty: 'opacity',
            transitionDuration: '300ms',
            opacity: isLoading ? 0.35 : 1,
            pointerEvents: isLoading ? 'none' : 'initial',
          }}
        >
          {children}
        </Box>
      </Box>
    ),
    [],
  )

  const chartDataTypes = useMemo(() => {
    if (transformedData) {
      const dataKeys = new Set()
      transformedData?.forEach((data) => {
        Object.keys(data).forEach((dataKey) => dataKeys.add(dataKey))
      })

      return Object.entries(dataTypes)
        .filter(([key]) => dataKeys.has(key))
        .map((entries) => entries[1])
    }
    return Object.values(dataTypes)
  }, [transformedData, dataTypes])

  return (
    <>
      <BeginTestForm
        open={!!beginTestFormData}
        instance={beginTestFormData}
        onClose={() => setBeginTestFormData(null)}
        onAbort={() => {
          setAbortTestFormOpen(true)
          setBeginTestFormData(null)
        }}
        onBegin={(data) => {
          handleBeginTest(data)
          setBeginTestFormData(null)
        }}
      />
      <ConfirmationDialog
        title="End test?"
        message="Are you sure you wish to conclude the test?"
        open={endTestFormOpen}
        onConfirm={() => {
          handleEndTest()
          setEndTestFormOpen(false)
        }}
        onCancel={() => setEndTestFormOpen(false)}
      />
      <ConfirmationDialog
        title="Abort test?"
        message="Are you sure you wish to abort the test?"
        open={abortTestFormOpen}
        onConfirm={() => {
          removeTestDataFromStorage()
          setAbortTestFormOpen(false)
        }}
        onCancel={() => setAbortTestFormOpen(false)}
      />
      <Popover
        open={!!settingsPopoverAnchor}
        onClose={() => setSettingsPopoverAnchor(null)}
        anchorEl={settingsPopoverAnchor}
        anchorOrigin={{ vertical: 50, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack direction="column" gap={1} px={2} pt={1} pb={0.5}>
          <FormControlLabel
            key="visible_points"
            label="Show start/end points"
            labelPlacement="end"
            slotProps={{ typography: { fontSize: 12 } }}
            control={
              <Checkbox
                checked={showStartEndPoints}
                size="small"
                onChange={() => setShowStartEndPoints((v) => !v)}
              />
            }
          />
          <FormControlLabel
            key="refresh"
            label="Auto-refresh"
            labelPlacement="end"
            slotProps={{ typography: { fontSize: 12 } }}
            control={
              <Checkbox
                checked={chartAutoRefresh}
                size="small"
                onChange={() => setChartAutoRefresh((v) => !v)}
              />
            }
          />
        </Stack>
      </Popover>
      <Box display="flex" flexDirection="column" m={3}>
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: 'Sensor Testing' }]}
        />
        <Typography variant="h3" mb={2}>
          Sensor Testing
        </Typography>
        <DynamicSelect
          label="Units"
          endpoint="units"
          filters={{ deviceCountMin: 1 }}
          secondaryTextAttr="propertyName"
          useValueInRequest={false}
          value={unitSelectorState}
          initialInput={selectedUnit?.name}
          onChange={(unit) => doUpdateQuery({ unit })}
          disabled={sensorTestingUnitDataIsLoading}
          sx={{ mt: 4 }}
        />
        <Stack direction="row" gap={2} mt={2}>
          {unitSelectorState && (
            <Card sx={{ width: '100%', minHeight: 214, p: 2 }}>
              <Typography variant="h5">Unit Details</Typography>
              <Divider style={{ marginTop: 8, marginBottom: 12 }} />
              <LoadingContainer isLoading={sensorTestingUnitDataIsLoading}>
                {selectedUnit && (
                  <Stack direction="column" gap={1}>
                    <UnitCardItem
                      label="Organization"
                      value={selectedUnit.organizationName}
                      linkTo={organizationUrls.entity.replace(
                        ':id',
                        selectedUnit.organization,
                      )}
                    />
                    <UnitCardItem
                      label="Account"
                      value={selectedUnit.accountName}
                      linkTo={accountUrls.entity.replace(':id', selectedUnit.account)}
                    />
                    <UnitCardItem
                      label="Property"
                      value={selectedUnit.propertyName}
                      linkTo={propertyUrls.entity.replace(':id', selectedUnit.property)}
                    />
                    <UnitCardItem
                      label="Status"
                      child={<StatusChip status={selectedUnit.status} />}
                    />
                  </Stack>
                )}
              </LoadingContainer>
            </Card>
          )}
          {unitSelectorState && (
            <Card sx={{ width: '100%', p: 2 }}>
              <Typography variant="h5">Unit Devices</Typography>
              <Divider style={{ marginTop: 8, marginBottom: 12 }} />
              <LoadingContainer isLoading={sensorTestingUnitDataIsLoading}>
                {sensorTestingUnitData?.devices?.length === 0 && (
                  <Typography variant="h4" color="grey" textAlign="center">
                    No Devices Found
                  </Typography>
                )}
                {sensorTestingUnitData?.devices?.length > 0 && (
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow sx={{ th: { border: 0 } }}>
                        <TableCell sx={deviceTableHeaderStyle} />
                        <TableCell sx={deviceTableHeaderStyle}>Mac</TableCell>
                        <TableCell sx={deviceTableHeaderStyle}>Zone</TableCell>
                        <TableCell sx={deviceTableHeaderStyle}>Status</TableCell>
                        <TableCell sx={deviceTableHeaderStyle}>Firmware</TableCell>
                        <TableCell sx={deviceTableHeaderStyle}>Scenarios</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sensorTestingUnitData.devices.map((device) => (
                        <TableRow key={device.id} sx={{ td: { border: 0 } }}>
                          <TableCell padding="checkbox" sx={deviceTableRowStyle}>
                            <Checkbox
                              size="small"
                              checked={selectedDevices.includes(device.id)}
                              onChange={(event) => onDeviceChecked({ event, device })}
                            />
                          </TableCell>
                          <TableCell sx={deviceTableRowStyle}>
                            <Link href={deviceUrls.entity.replace(':id', device.id)}>
                              {device.mainMac}
                            </Link>
                          </TableCell>
                          <TableCell sx={deviceTableRowStyle}>
                            {device.zoneName}
                          </TableCell>
                          <TableCell sx={deviceTableRowStyle}>
                            <StatusChip status={device.status} />
                          </TableCell>
                          <TableCell sx={deviceTableRowStyle}>
                            {device.firmwareVersion || '--'}
                          </TableCell>
                          <TableCell sx={deviceTableRowStyle}>
                            <Link
                              href={`${deviceUrls.entity.replace(
                                ':id',
                                device.id,
                              )}#scenarios`}
                            >
                              View
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </LoadingContainer>
            </Card>
          )}
        </Stack>
        {selectedUnit && (
          <Card sx={{ width: '100%', p: 2, mt: 2 }}>
            <LoadingContainer isLoading={sensorTestingDevicesChartsIsLoading}>
              <Stack direction="row" mb={4} justifyContent="space-between">
                <Box display="flex" gap={2}>
                  <Tooltip
                    title={
                      unitTestData && isActionButtonDisabled
                        ? 'Test must last at least 15 minutes'
                        : ''
                    }
                  >
                    {isAtLeastDataScience && (
                      <span>
                        <Button
                          disabled={isActionButtonDisabled}
                          variant="contained"
                          startIcon={
                            unitTestData ? <StopRounded /> : <PlayArrowRounded />
                          }
                          color={unitTestData ? 'error' : 'success'}
                          onClick={
                            unitTestData
                              ? () => onEndTestPressed()
                              : () => onBeginTestPressed()
                          }
                        >
                          {unitTestData ? 'End Test' : 'Begin Test'}
                        </Button>
                      </span>
                    )}
                  </Tooltip>
                  {unitTestData && (
                    <Box display="flex" gap={1} alignItems="center">
                      {unitTestData.tags.map((tag) => (
                        <Chip key={tag} label={tag} />
                      ))}
                      <IconButton
                        onClick={() =>
                          setBeginTestFormData({
                            scenarioTags: unitTestData.tags,
                            notes: unitTestData.notes ?? '',
                          })
                        }
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box display="flex" gap={2}>
                  <Button
                    variant="outlined"
                    startIcon={<Settings />}
                    onClick={(event) => setSettingsPopoverAnchor(event.currentTarget)}
                  >
                    Settings
                  </Button>
                  <StaticMultiSelect
                    disableClearable
                    options={dataTypesOptions}
                    label="Data Types"
                    size="small"
                    value={dataTypesSelectorState}
                    onChange={(values) => setDataTypesSelectorState(values)}
                    onSave={(types) => setSelectedDataTypes(types)}
                    limitTags={3}
                  />
                  <ToggleButtonGroup
                    exclusive
                    onChange={(_, value) => {
                      setSelectedDateTimeRange(
                        value
                          ? [
                              DateTime.now().minus({ milliseconds: value }),
                              DateTime.now(),
                            ]
                          : getTestDateTimeRange(),
                      )
                      setDateTimeRangeOption(value)
                    }}
                    size="small"
                    variant="outlined"
                  >
                    {dateOptions.map((option) => {
                      if (
                        option.title !== 'TEST' ||
                        (option.title === 'TEST' && unitTestData)
                      ) {
                        return (
                          <ToggleButton
                            key={option.value}
                            value={option.value}
                            selected={dateTimeRangeOption === option.value}
                          >
                            {option.title}
                          </ToggleButton>
                        )
                      }
                      return null
                    })}
                  </ToggleButtonGroup>
                </Box>
              </Stack>
              {hasData && (
                <Chart
                  id="testing_data_chart"
                  data={transformedData}
                  dataTypes={chartDataTypes}
                  highlightedAreas={[...testArea, ...scenariosAreas, ...alertsAreas]}
                  settings={{
                    alwaysShowStartEndPoints: showStartEndPoints,
                    showNotAvailableInTooltip: true,
                    tooltip: { notAvailableText: 'null' },
                  }}
                />
              )}
              {!hasData && !sensorTestingDevicesChartsIsLoading && (
                <Typography variant="h4" color="grey" textAlign="center" m={6}>
                  No Data Available
                </Typography>
              )}
            </LoadingContainer>
          </Card>
        )}
      </Box>
    </>
  )
}
