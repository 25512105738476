import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Circle, CircleNotifications, SettingsSuggest } from '@mui/icons-material'
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material'

import { titleize } from 'inflection'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  ListTextTooltipWrapper,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors, useQueryFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import smokeProfileEnsemblesUrls from '@portal/pages/SmokeProfileEnsembles/urls'
import { smokeProfilesUrls } from '@portal/pages/SmokeProfiles'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import SmokeProfilePreviewDialog from '@portal/UI/components/SmokeProfilePreviewDialog'

import SmokeProfileEnsebleForm from './SmokeProfileEnsembleForm'

const PAGE_TITLE = 'Smoke Profile Ensembles'

export default function SmokeProfileEnsembles() {
  const isSmallScreen = useSmallScreen()

  const {
    doSmokeProfileEnsemblesListClearParams,
    doSmokeProfileEnsemblesListSetFilter,
    doSmokeProfileEnsemblesListSetPage,
    doSmokeProfileEnsemblesListSetPageSize,
    doSmokeProfileEnsemblesListSetOrdering,
    doSmokeProfileEnsemblesListBulkPreview,
    doSmokeProfileEnsemblesListBulkArchive,
    doSmokeProfileEnsemblesListBulkUnarchive,
    doSmokeProfileEnsembleMakeDefault,
    doMarkSmokeProfileEnsemblesListAsOutdated,
    smokeProfileEnsemblesList,
    smokeProfileEnsemblesListRaw: { ordering = [] },
    smokeProfileEnsemblesListIsLoading,
    smokeProfileEnsemblesListApiParams,
    systemSmokeProfileEnsembleStatuses,
    systemSmokeProfileEnsembleWindowTypes,
    systemSmokeProfiles,
    isSuperuser,
    queryObject,
    doFetchSystem,
    doShowSnackbar,
    doUpdateQuery,
    doUpdateUrl,
  } = useConnect(
    'doSmokeProfileEnsemblesListClearParams',
    'doSmokeProfileEnsemblesListSetFilter',
    'doSmokeProfileEnsemblesListSetPage',
    'doSmokeProfileEnsemblesListSetPageSize',
    'doSmokeProfileEnsemblesListSetOrdering',
    'doSmokeProfileEnsemblesListBulkPreview',
    'doSmokeProfileEnsemblesListBulkArchive',
    'doSmokeProfileEnsemblesListBulkUnarchive',
    'doSmokeProfileEnsembleMakeDefault',
    'doMarkSmokeProfileEnsemblesListAsOutdated',
    'selectSmokeProfileEnsemblesList',
    'selectSmokeProfileEnsemblesListRaw',
    'selectSmokeProfileEnsemblesListIsLoading',
    'selectSmokeProfileEnsemblesListApiParams',
    'selectSystemSmokeProfileEnsembleStatuses',
    'selectSystemSmokeProfileEnsembleWindowTypes',
    'selectSystemSmokeProfiles',
    'selectIsSuperuser',
    'selectQueryObject',
    'doFetchSystem',
    'doShowSnackbar',
    'doUpdateQuery',
    'doUpdateUrl',
  )

  useQueryFilter({
    query: queryObject,
    apiParams: smokeProfileEnsemblesListApiParams,
    setFilter: doSmokeProfileEnsemblesListSetFilter,
    setPageSize: doSmokeProfileEnsemblesListSetPageSize,
  })

  useEffect(() => {
    doSmokeProfileEnsemblesListClearParams()
    doUpdateQuery({
      status: systemSmokeProfileEnsembleStatuses
        .map((status) => status.value)
        .filter((status) => status !== 'ARCHIVED')
        .join(','),
    })
  }, [])

  const [previewData, setPreviewData] = useState(null)
  const [profileToEdit, setProfileToEdit] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const handleClear = () => {
    doSmokeProfileEnsemblesListClearParams()
    doSmokeProfileEnsemblesListSetFilter()
  }

  const handlePageChange = async (page) => {
    doSmokeProfileEnsemblesListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    doSmokeProfileEnsemblesListSetPageSize(size)
  }

  const archiveProfiles = useCallback(async (ids) => {
    try {
      await doSmokeProfileEnsemblesListBulkArchive({ ids })

      setSelectedIds([])
      doMarkSmokeProfileEnsemblesListAsOutdated()
      doFetchSystem()
      doShowSnackbar(
        `Ensemble${ids.length > 1 ? 's' : ''} successfully archived`,
        'success',
      )
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const handleBulkArchive = useCallback(async (ids) => {
    try {
      const previewResult = await doSmokeProfileEnsemblesListBulkPreview({
        ids,
        operation: 'archive',
      })
      setPreviewData({ type: 'archive', messages: previewResult, ids })
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const handleBulkUnarchive = useCallback(async (ids) => {
    try {
      await doSmokeProfileEnsemblesListBulkUnarchive({ ids })
      setSelectedIds([])
      doMarkSmokeProfileEnsemblesListAsOutdated()
      doFetchSystem()
      doShowSnackbar(
        `Ensemble${ids.length > 1 ? 's' : ''} successfully unarchived`,
        'success',
      )
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const makeProfileAsDefault = useCallback(async (id) => {
    try {
      await doSmokeProfileEnsembleMakeDefault(id)

      doMarkSmokeProfileEnsemblesListAsOutdated()
      doFetchSystem()
      doShowSnackbar('Ensemble successfully set as default', 'success')
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const handleMakeDefault = useCallback(async (id) => {
    try {
      const previewResult = await doSmokeProfileEnsemblesListBulkPreview({
        ids: [id],
        operation: 'make_default',
      })
      setPreviewData({ type: 'make_default', messages: previewResult, ids: [id] })
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const statusesOptions = useMemo(
    () =>
      systemSmokeProfileEnsembleStatuses.map((item) => ({
        id: item.value,
        name: item.name,
      })),
    [systemSmokeProfileEnsembleStatuses],
  )

  const getProfileName = useCallback(
    ({ id }) => {
      const profileData = systemSmokeProfiles.find((profile) => profile.id === id)
      if (profileData) {
        return `${profileData.modelKey} - ${profileData.name}`
      }
      return null
    },
    [systemSmokeProfiles],
  )

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.65,
      minWidth: 250,
      renderCell: ({ row }) => {
        const href = smokeProfileEnsemblesUrls.entity.replace(':id', row.id)
        const style = {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }

        const Icon = row.status === 'DEFAULT_ALERTING' ? CircleNotifications : Circle

        return (
          <Stack direction="row" alignItems="center" gap={1} sx={style}>
            <Tooltip arrow title={titleize(row.status)}>
              <Icon
                color={row.status === 'ARCHIVED' ? 'error' : 'success'}
                sx={{ fontSize: 18 }}
              />
            </Tooltip>
            <Stack direction="column" sx={style}>
              <ListTextTooltipWrapper tooltip={row.name}>
                <Link href={href}>
                  <Typography variant="body2" sx={style}>
                    {row.name}
                  </Typography>
                </Link>
              </ListTextTooltipWrapper>
              <ListTextTooltipWrapper tooltip={row.description}>
                <Typography variant="caption" color="grey.700" sx={style}>
                  {row.description}
                </Typography>
              </ListTextTooltipWrapper>
            </Stack>
          </Stack>
        )
      },
    },
    {
      field: 'inUse',
      headerName: 'In Use',
      sortable: false,
      flex: 0.35,
      minWidth: 80,
      renderCell: ({ row }) => (
        <Tooltip
          arrow
          placement="right-start"
          title={
            <div
              style={{ whiteSpace: 'pre-line' }}
            >{`Alerting Properties: ${row.alertingProperties.length}`}</div>
          }
        >
          <Typography variant="body2" fontSize={isSmallScreen ? 12 : 14}>
            {!isEmpty(row.alertingProperties) ? 'Yes' : 'No'}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'primaryProfile',
      headerName: 'Primary Profile',
      sortable: false,
      flex: 0.5,
      minWidth: 250,
      renderCell: ({ row }) => (
        <ClickableCell
          label={getProfileName({ id: row.primaryProfile })}
          url={smokeProfilesUrls.entity.replace(':id', row.primaryProfile)}
        />
      ),
    },
    {
      field: 'windowType',
      headerName: 'Window Type',
      sortable: false,
      flex: 0.3,
      minWidth: 150,
      renderCell: ({ row }) => {
        const { windowType } = row
        const tooltipText =
          systemSmokeProfileEnsembleWindowTypes.find(
            (type) => type.value === windowType,
          )?.name ?? ''
        return (
          <Tooltip title={tooltipText}>
            <Typography variant={isSmallScreen ? 'caption' : 'body2'}>
              {windowType ? titleize(windowType) : '--'}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'additionalVotesNeeded',
      headerName: 'Additional Votes Needed',
      sortable: false,
      flex: 0.35,
      minWidth: 200,
    },
    {
      field: 'secondaryProfiles',
      headerName: 'Secondary Profiles',
      sortable: false,
      flex: 0.5,
      minWidth: 250,
      renderCell: ({ row }) =>
        row.secondaryProfiles.length > 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            gap={1}
            sx={{ px: 1, pt: 0.5, pb: 1 }}
          >
            {row.secondaryProfiles.map((profile) => (
              <Link
                key={profile}
                href={smokeProfilesUrls.entity.replace(':id', profile)}
              >
                <Typography variant="body2">
                  {getProfileName({ id: profile })}
                </Typography>
              </Link>
            ))}
          </Box>
        ) : (
          '--'
        ),
      renderMobile: ({ row }) =>
        row.secondaryProfiles.length > 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            alignItems="end"
            justifyContent="start"
            gap={1}
            sx={{ py: 0.5 }}
          >
            {row.secondaryProfiles.map((profile) => (
              <Typography
                key={profile}
                variant="caption"
                onClick={() =>
                  doUpdateUrl(smokeProfilesUrls.entity.replace(':id', profile))
                }
                color="primary"
                textAlign="end"
                sx={{ textDecoration: 'underline' }}
              >
                {getProfileName({ id: profile }) || '--'}
              </Typography>
            ))}
          </Box>
        ) : (
          '--'
        ),
    },
  ]

  const MobileItemHeader = useCallback(({ row }) => {
    const Icon = row.status === 'DEFAULT_ALERTING' ? CircleNotifications : Circle
    const href = smokeProfilesUrls.entity.replace(':id', row.primaryProfile)

    return (
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ '&&': { mb: 1 } }}>
        <Icon
          color={row.status === 'ARCHIVED' ? 'error' : 'success'}
          sx={{ fontSize: 14 }}
        />
        <Stack direction="column">
          <ListTextTooltipWrapper tooltip={row.name}>
            <Link href={href}>
              <Typography variant="body2">{row.name}</Typography>
            </Link>
          </ListTextTooltipWrapper>
          <ListTextTooltipWrapper tooltip={row.description}>
            <Typography variant="caption" color="grey.700">
              {row.description}
            </Typography>
          </ListTextTooltipWrapper>
        </Stack>
      </Stack>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name'],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  const listActions = isSuperuser
    ? {
        bulk: [
          { action: handleBulkArchive, title: 'Archive' },
          { action: handleBulkUnarchive, title: 'Un-Archive' },
        ],
        create: () => setProfileToEdit({}),
        update: (profile) => setProfileToEdit(profile),
      }
    : null

  const listOtherActions = [
    ...(isSuperuser
      ? [
          {
            label: 'Set As Default',
            icon: <SettingsSuggest />,
            disabled: (row) => ['ARCHIVED', 'DEFAULT_ALERTING'].includes(row.status),
            onClick: (row) => handleMakeDefault(row.id),
          },
        ]
      : []),
  ]

  if (!smokeProfileEnsemblesList.results) {
    return (
      <ErrorComponent title={PAGE_TITLE} buttonTitle="Refresh" callback={handleClear} />
    )
  }

  return (
    <>
      <SmokeProfileEnsebleForm
        open={!!profileToEdit}
        onClose={(success) => {
          if (success === true) {
            doMarkSmokeProfileEnsemblesListAsOutdated()
          }
          setProfileToEdit(null)
        }}
        profile={isEmpty(profileToEdit) ? null : profileToEdit}
      />
      <SmokeProfilePreviewDialog
        previewData={previewData}
        onConfirm={(data) => {
          if (data.type === 'archive') {
            archiveProfiles(data.ids)
          }
          if (data.type === 'make_default') {
            makeProfileAsDefault(data.ids[0])
          }
          setPreviewData(null)
        }}
        onCancel={() => setPreviewData(null)}
      />

      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: PAGE_TITLE }]}
        />
        <ListPageTitle
          title={PAGE_TITLE}
          onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
          menuItems={
            isSmallScreen && isSuperuser
              ? [
                  {
                    label: 'Add Smoke Profile Ensemble',
                    onClick: () => setProfileToEdit({}),
                  },
                  ...(selectedIds?.length
                    ? [
                        { label: 'Archive', onClick: handleBulkArchive },
                        { label: 'Un-Archive', onClick: handleBulkUnarchive },
                      ]
                    : []),
                ]
              : null
          }
          mb={2}
        />
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={smokeProfileEnsemblesListIsLoading}
            apiParams={smokeProfileEnsemblesListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect
              multiple
              disableCloseOnSelect
              label="Status"
              filterName="status"
              options={statusesOptions}
            />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title={PAGE_TITLE}
                loading={smokeProfileEnsemblesListIsLoading}
                otherActions={listOtherActions}
                actions={listActions}
                itemBuilder={MobileListItem}
                page={smokeProfileEnsemblesList.current || 1}
                pageChange={handlePageChange}
                pageSize={smokeProfileEnsemblesList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={smokeProfileEnsemblesList?.results}
                rowCount={smokeProfileEnsemblesList.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                dynamicRowHeight
                title={PAGE_TITLE}
                loading={smokeProfileEnsemblesListIsLoading}
                showAddButton={isSuperuser && !isSmallScreen}
                otherActions={listOtherActions}
                actions={listActions}
                columns={columns}
                page={smokeProfileEnsemblesList.current || 1}
                pageChange={handlePageChange}
                pageSize={smokeProfileEnsemblesList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={smokeProfileEnsemblesList?.results}
                rowCount={smokeProfileEnsemblesList.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                sortChange={doSmokeProfileEnsemblesListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
