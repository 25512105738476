import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { PeopleAltRounded, PeopleOutlined, VpnKeyRounded } from '@mui/icons-material'
import { AvatarGroup, Box, Button, Stack, Tooltip, Typography } from '@mui/material'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { ClickableCell, MobileList, MobileListDefaultCard } from '@common/components'
import UserAvatar from '@common/components/Avatar'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import accUrls from '@portal/pages/Accounts/urls'
import List from '@portal/UI/components/List'

import PermissionForm from './PermissionForm'
import RoleForm from './RoleForm'
import RoleMembershipForm from './RoleMembershipForm'

export default function RolesTab() {
  const [selectedIds, setSelectedIds] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [roleFormOpen, setRoleFormOpen] = useState(false)
  const [membershipFormOpen, setMembershipFormOpen] = useState(false)
  const [permissionFormOpen, setPermissionFormOpen] = useState(false)

  const isSmallScreen = useSmallScreen()

  const {
    routeInfo,
    rolesList,
    rolesListIsLoading,
    rolesListRaw: { ordering = [] },
    isAtLeastAdmin,
    doRolesListSetPage,
    doRolesListSetPageSize,
    doRolesListSetOrdering,
    doRoleDelete,
    doShowSnackbar,
    doMarkRolesListAsOutdated,
  } = useConnect(
    'selectRouteInfo',
    'selectRolesList',
    'selectRolesListIsLoading',
    'selectRolesListRaw',
    'selectIsAtLeastAdmin',
    'doRolesListSetPage',
    'doRolesListSetPageSize',
    'doRolesListSetOrdering',
    'doRoleDelete',
    'doShowSnackbar',
    'doMarkRolesListAsOutdated',
  )

  const isOrgTab = routeInfo?.url?.includes('organizations')
  const entityType = isOrgTab ? 'organization' : 'account'

  const containsImmutableRoles = (ids) =>
    rolesList?.results?.filter((role) => ids.includes(role.id)).some((r) => !r?.mutable)

  const handleDelete = async (ids) => {
    const payload = typeof ids === 'string' ? [ids] : ids
    const immutableRoles = containsImmutableRoles(payload)

    if (immutableRoles) {
      doShowSnackbar('Cannot delete immutable Role', 'error')
      return
    }

    const bulkPayload = payload.map((id) => ({ id }))
    const res = await doRoleDelete(bulkPayload)

    if (res?.error) {
      doShowSnackbar(parseApiErrors(res?.error?.response?.[0]), 'error')
      return
    }

    doMarkRolesListAsOutdated()
    const msg = `Successfully deleted ${bulkPayload.length} Role${
      bulkPayload.length > 1 ? 's' : ''
    }`
    doShowSnackbar(msg, 'success')
    setSelectedIds([])
  }

  const handlePageChange = async (page) => doRolesListSetPage(page)
  const handleSortChange = async (sort) => doRolesListSetOrdering(sort)
  const handlePageSizeChange = (size) => doRolesListSetPageSize(size)

  const listActions = {
    ...(isAtLeastAdmin
      ? {
          create: () => {
            setSelectedRole({ [entityType]: routeInfo?.params?.id })
            setRoleFormOpen(true)
          },
          update: (role) => {
            setSelectedRole(role)
            setRoleFormOpen(true)
          },
          delete: handleDelete,
          bulk: [{ action: handleDelete, title: 'Delete' }],
        }
      : {}),
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Role Name',
      flex: 1,
    },
    ...(isOrgTab
      ? [
          {
            field: 'accountName',
            headerName: 'Account',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.accountName}
                url={accUrls.entity.replace(':id', row.account)}
              />
            ),
            flex: 0.75,
            sortable: false,
          },
        ]
      : []),
    {
      field: 'members',
      headerName: 'Members',
      renderCell: ({ row }) =>
        row?.memberships?.length ? (
          <AvatarGroup
            data-testid="user_avatars"
            max={5}
            total={row.memberships.length}
            slotProps={{ additionalAvatar: { sx: { bgcolor: '#000' } } }}
          >
            {row.memberships.map((m, i) => (
              <UserAvatar name={titleize(m.name)} index={i} key={m.id} />
            ))}
          </AvatarGroup>
        ) : (
          <Stack direction="row">
            <PeopleOutlined />
            <Typography ml={1}>0 Members</Typography>
          </Stack>
        ),
      renderMobile: ({ row }) => (
        <Stack direction="row" alignItems="center">
          <PeopleOutlined sx={{ fontSize: 14 }} />
          <Typography ml={0.5} variant="caption">
            {row.memberships.length} Members
          </Typography>
        </Stack>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: 'modifiedOn',
      headerName: 'Last Modified',
      valueGetter: (_, row) =>
        row.modifiedOn
          ? DateTime.fromISO(row.modifiedOn)
              .toLocaleString(DateTime.DATE_FULL)
              .toUpperCase()
          : '',
      // valueFormatter: (value) => value ?? 'N/A',
      flex: 1,
      sortable: false,
    },
    {
      field: 'role',
      headerName: 'Role Level',
      valueGetter: (_, row) => row.roleLevel,
      flex: 1,
      sortable: false,
    },
    {
      field: 'permissions',
      sortable: false,
      width: 300,
      headerName: 'Permissions/Memberships',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box flex={1} display="flex" gap={1} justifyContent="center">
          <Tooltip placement="top" title="Edit Permissions">
            <span>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setSelectedRole(params.row)
                  setPermissionFormOpen(true)
                }}
              >
                Permissions
              </Button>
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Edit Memberships">
            <span>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setSelectedRole(params.row)
                  setMembershipFormOpen(true)
                }}
              >
                Memberships
              </Button>
            </span>
          </Tooltip>
        </Box>
      ),
      renderMobile: ({ row }) => {
        const style = {
          fontSize: isSmallScreen ? 12 : 14,
          textDecoration: 'underline',
          cursor: 'pointer',
          color: '#4840ba',
        }

        return (
          <Box display="flex" flexDirection="column" gap={0.5} alignItems="end">
            <Typography
              onClick={() => {
                setSelectedRole(row)
                setPermissionFormOpen(true)
              }}
              sx={style}
            >
              Edit Permissions
            </Typography>
            <Typography
              onClick={() => {
                setSelectedRole(row)
                setMembershipFormOpen(true)
              }}
              sx={style}
            >
              Edit Memberships
            </Typography>
          </Box>
        )
      },
    },
  ]

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  useEffect(() => {
    doMarkRolesListAsOutdated()
  }, [])

  return (
    <Box>
      <RoleForm
        open={roleFormOpen}
        instance={selectedRole}
        onClose={() => {
          setSelectedRole(null)
          setRoleFormOpen(false)
        }}
        isOrgTab={isOrgTab}
      />
      <RoleMembershipForm
        open={membershipFormOpen}
        instance={selectedRole}
        onClose={() => {
          setSelectedRole(null)
          setMembershipFormOpen(false)
        }}
      />
      <PermissionForm
        role={selectedRole}
        open={permissionFormOpen}
        instance={selectedRole}
        onClose={() => {
          setSelectedRole(null)
          setPermissionFormOpen(false)
        }}
      />
      <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
        {isSmallScreen ? (
          <MobileList
            queryDrivenSearch
            title="Roles"
            actions={listActions}
            itemBuilder={MobileListItem}
            loading={rolesListIsLoading}
            listActions={[
              {
                label: 'Add Role',
                onClick: () => {
                  setSelectedRole({ [entityType]: routeInfo?.params?.id })
                  setRoleFormOpen(true)
                },
              },
              ...(selectedIds?.length
                ? [
                    {
                      onClick: () => handleDelete(selectedIds),
                      label: 'Delete Selected',
                    },
                  ]
                : []),
            ]}
            otherActions={[
              {
                label: 'Permissions',
                icon: <VpnKeyRounded />,
                onClick: (row) => {
                  setSelectedRole(row)
                  setPermissionFormOpen(true)
                },
              },
              {
                label: 'Memberships',
                icon: <PeopleAltRounded />,
                onClick: (row) => {
                  setSelectedRole(row)
                  setMembershipFormOpen(true)
                },
              },
            ]}
            pageChange={handlePageChange}
            sortChange={handleSortChange}
            pageSizeChange={handlePageSizeChange}
            pageSize={rolesList?.pageSize}
            rows={rolesList?.results}
            page={rolesList?.current}
            rowCount={rolesList?.count}
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={setSelectedIds}
          />
        ) : (
          <List
            columnsAutosize
            queryDrivenSearch
            title="Roles"
            actions={listActions}
            columns={columns}
            loading={rolesListIsLoading}
            pageChange={handlePageChange}
            sortChange={handleSortChange}
            pageSizeChange={handlePageSizeChange}
            pageSize={rolesList?.pageSize}
            rows={rolesList?.results}
            page={rolesList?.current}
            rowCount={rolesList?.count ?? 0}
            currentOrdering={ordering}
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={setSelectedIds}
          />
        )}
      </Box>
    </Box>
  )
}
