import { useConnect } from 'redux-bundler-hook'

import { FileDownload } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { parseApiErrors } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.interval
 * @param {Object} props.requestParams
 * @param {string} props.requestParams.start
 * @param {string} props.requestParams.end
 * @param {string[]} props.requestParams.metrics
 */
export default function ExportMetrics({ requestParams, interval, ...props }) {
  const {
    isAtLeastAdmin,
    smokeMetrics,
    smokeMetricsIsLoading,
    smokeMetricsExportIsLoading,
    doExportSmokeMetrics,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectSmokeMetrics',
    'selectSmokeMetricsIsLoading',
    'selectSmokeMetricsExportIsLoading',
    'doExportSmokeMetrics',
    'doShowSnackbar',
  )

  const payload = { ...requestParams }
  delete payload.metrics // export all available metrics

  return (
    isAtLeastAdmin && (
      <Tooltip title="Export CSV">
        <div>
          <IconButton
            disabled={
              smokeMetricsExportIsLoading || smokeMetricsIsLoading || !smokeMetrics
            }
            onClick={async () => {
              try {
                await doExportSmokeMetrics(payload, interval)
              } catch (err) {
                const parsedError = parseApiErrors(err?.response)
                doShowSnackbar(parsedError, 'error')
              }
            }}
            {...props}
          >
            <FileDownload />
          </IconButton>
        </div>
      </Tooltip>
    )
  )
}
