import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Close } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { Form, Formik } from 'formik'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.dataTestId]
 * @param {Object} props.children
 * @param {string} props.label
 * @param {Object} props.initialValues
 * @param {string} [props.maxWidth]
 * @param {Function} props.onClose
 * @param {Function} props.onSave
 * @param {boolean} props.open
 * @param {Object} props.validationSchema
 * @param {string} [props.successMessage]
 * @param {string} [props.saveButtonText]
 * @param {boolean} [props.bypassDirtyState]
 * @param {boolean} [props.isSaveEnabled]
 * @param {string} [props.saveTooltip]
 */
export default function FormDialog({
  'data-testid': dataTestId = undefined,
  children,
  label,
  initialValues,
  maxWidth = 'sm',
  onClose,
  onSave,
  open,
  validationSchema,
  successMessage = 'Successfully saved information',
  saveButtonText = 'Submit',
  bypassDirtyState = false,
  isSaveEnabled = true,
  saveTooltip = undefined,
}) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = async (values) => {
    setIsSaving(true)
    try {
      const result = await onSave({ id: initialValues?.id, ...values })
      if (result) {
        doShowSnackbar(successMessage, 'success')
        onClose(true)
      }
    } catch (error) {
      captureException(error)
      doShowSnackbar('Unable to save information', 'error')
    }
    setIsSaving(false)
  }

  return (
    <div>
      <Dialog
        data-testid={dataTestId}
        fullWidth
        maxWidth={maxWidth ?? 'sm'}
        open={open}
        onClose={onClose}
        disableScrollLock
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {label}
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 10, right: 10 }}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ mx: 3 }} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ dirty, isValid }) => (
            <Form>
              <DialogContent>{children}</DialogContent>
              <DialogActions
                sx={{
                  paddingBottom: '25px',
                  paddingRight: '25px',
                  paddingTop: '0px',
                }}
              >
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Tooltip title={saveTooltip}>
                  <span style={{ paddingLeft: 10 }}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={
                        !isSaveEnabled ||
                        !isValid ||
                        (!bypassDirtyState && !dirty) ||
                        isSaving
                      }
                    >
                      {saveButtonText}
                    </Button>
                  </span>
                </Tooltip>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  )
}
