import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

export const SIDEBAR_WIDTH = 256
export const DEFAULT_MARGIN = 60

const openedMixin = (theme) => ({
  width: SIDEBAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(7),
  '& .MuiAvatar-root': {
    marginLeft: '-8px',
  },
})

export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: SIDEBAR_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const logoStyles = {
  width: 120,
  margin: '16px auto 10px 20px',
}

export const miniLogo = {
  width: 24,
  height: 24,
  marginTop: 20,
  marginBottom: 6,
  marginLeft: 16,
}

export const sidebarStyles = {
  width: SIDEBAR_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: SIDEBAR_WIDTH,
    boxSizing: 'border-box',
  },
}

export const sidebarBottomNavigation = {
  position: 'absolute',
  bottom: 0,
  width: SIDEBAR_WIDTH - 1,
}

export const baseArrowStyles = {
  position: 'fixed',
  top: '20px',
  color: '#757575',
  backgroundColor: '#10171f',
  borderRadius: '4px',
  height: 22,
  width: 22,
  boxShadow: '-1px 0px 3px 1px white',
  '&:hover': {
    backgroundColor: '#10171f',
  },
  zIndex: 1,
}

export const rightArrowStyles = {
  left: '46px',
}

export const leftArrowStyles = {
  left: '243px',
}

export const rightEventDrawerArrowStyles = {
  right: '243px',
}

export const leftEventDrawerArrowStyles = {
  right: '46px',
}
