import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'

import urls from '@rest/pages/Properties/urls'
import { formatItemCount, formatSubitemCount } from '@rest/Utils/formatMenuStrings'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.properties
 * @param {string} props.properties[].id
 * @param {string} props.properties[].name
 * @param {string} [props.properties[].account]
 * @param {string} [props.properties[].propertyType]
 * @param {string} [props.properties[].timezone]
 * @param {string} [props.properties[].address1]
 * @param {string} [props.properties[].address2]
 * @param {string} [props.properties[].city]
 * @param {string} [props.properties[].state]
 * @param {string} [props.properties[].country]
 * @param {string} [props.properties[].zipCode]
 * @param {number} [props.properties[].unitCount]
 * @param {number} [props.properties[].deviceCount]
 * @param {Object[]} [props.properties[].events]
 * @param {string} [props.properties[].events[].type]
 * @param {string} [props.properties[].events[].duration]
 */
export default function PropertyMenu({ properties }) {
  const {
    routeParams: { id: propertyId },
    routeInfo: { pattern },
    doSetCurrentProperty,
    currentProperty,
    doUpdateUrl,
  } = useConnect(
    'selectRouteParams',
    'selectRouteInfo',
    'doSetCurrentProperty',
    'selectCurrentProperty',
    'doUpdateUrl',
  )
  const [propertyAnchor, setPropertyAnchor] = useState(null)
  const openPropertyMenu = Boolean(propertyAnchor)

  useEffect(() => {
    doSetCurrentProperty(propertyId)
  }, [])

  const handlePropertyClick = (id) => {
    setPropertyAnchor(null)
    doSetCurrentProperty(id)
    if (pattern.indexOf('/properties/:id') !== -1) {
      doUpdateUrl(pattern.replace(':id', id))
    } else {
      doUpdateUrl(urls.entity.replace(':id', id))
    }
  }
  if (!propertyId || !currentProperty) return null

  return (
    <>
      <Typography color="grey.300">/</Typography>
      <Button
        data-testid="property-button"
        onClick={(e) => setPropertyAnchor(e.currentTarget)}
        endIcon={<KeyboardArrowDown />}
        sx={{ p: 0, px: '3px', mx: 1, color: 'white' }}
      >
        {currentProperty.name}
      </Button>
      <Menu
        disableScrollLock
        data-testid="property-menu"
        open={openPropertyMenu}
        anchorEl={propertyAnchor}
        onClose={() => setPropertyAnchor(null)}
        sx={{ '& .MuiMenu-list': { minWidth: '300px', maxHeight: '400px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant="caption" color="text.secondary" ml={2}>
          {formatItemCount('Property', properties.length)}
        </Typography>
        {properties.map((property) => (
          <MenuItem
            key={property.id}
            selected={property.id === currentProperty}
            onClick={() => {
              handlePropertyClick(property.id)
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Typography>{property.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {formatSubitemCount('Property', 1)}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
