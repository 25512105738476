import { createListBundle } from '@common/utils'
import propertyUrls from '@rest/pages/Properties/urls'

import configureUrls from '../urls'
import thresholdListData from './__fixtures__/thresholdListData.json'

const entityName = 'thresholds'

const thresholdsListBundleRaw = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/threshold_profiles/', params, {
      cancelationPrefix: entityName,
      mockData: thresholdListData,
      mockTimeout: 1000,
      mock: false,
    }),
  urlTest: (url, pattern) =>
    url === configureUrls.thresholdsList || pattern === propertyUrls.thresholds,
})

export default {
  ...thresholdsListBundleRaw,
  doNewThresholdProfileSave:
    (payload) =>
    async ({ apiFetch, store }) => {
      const accountId = store.selectCurrentAccount()
      const data = { ...payload }
      if (!payload.property) {
        data.account = accountId
      }
      const response = await apiFetch(`/threshold_profiles/`, data, {
        method: 'POST',
        cancelationPrefix: entityName,
      })
      return response
    },
  doSingleThresholdProfileFetch:
    (payload) =>
    async ({ apiFetch }) => {
      const response = await apiFetch(`/threshold_profiles/${payload}/`, null, {
        cancelationPrefix: entityName,
      })
      return response
    },
  doThresholdProfileUpdate:
    (payload) =>
    async ({ apiFetch }) => {
      const response = await apiFetch(
        '/threshold_profiles/',
        { id: payload.id, ...payload },
        { method: 'PUT', cancelationPrefix: entityName },
      )
      return response
    },
  doThresholdProfileDelete:
    (params) =>
    async ({ apiFetch }) => {
      await apiFetch(
        '/threshold_profiles/',
        { id: params },
        { method: 'DELETE', cancelationPrefix: entityName },
      )
    },
  doThresholdProfileAssign:
    (payload) =>
    async ({ apiFetch }) => {
      const response = await apiFetch(`/threshold_profiles/assign/`, payload, {
        method: 'POST',
        cancelationPrefix: entityName,
      })
      return response
    },
}
