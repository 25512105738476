import { createEntityBundle } from '@common/utils'

const entityName = 'organizations'

const organizationBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'fetch_v1',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload}/v1Org`,
          {},
          { method: 'GET', cancelationPrefix: entityName },
        ),
    },
    {
      actionName: 'fetch_salesforce',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload}/salesforceAccount`,
          {},
          { method: 'GET', cancelationPrefix: entityName },
        ),
    },
    {
      actionName: 'fetch_stripe',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload}/stripeCustomer`,
          {},
          { method: 'GET', cancelationPrefix: entityName },
        ),
    },
  ],
})

export default organizationBundle
