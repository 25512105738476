import { Group } from '@visx/group'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.x0
 * @param {number} props.x1
 * @param {number} props.y
 * @param {number} props.height
 * @param {number} [props.limitX0]
 * @param {number} [props.limitX1]
 */
export default function SelectionRect({
  x0,
  x1,
  y,
  height,
  limitX0 = undefined,
  limitX1 = undefined,
}) {
  const color = '#000000'
  const opacity = 0.4

  const hasLimitX0 = limitX0 !== undefined
  const hasLimitX1 = limitX1 !== undefined
  const width =
    (hasLimitX1 ? Math.min(limitX1, x1) : x1) -
    (hasLimitX0 ? Math.max(limitX0, x0) : x0)

  return (
    <>
      {(!hasLimitX0 || (x0 >= limitX0 - 2 && x0 <= limitX1)) && (
        <Group left={x0} top={y} height={height}>
          <g transform="translate(0,-4)">
            <polygon
              points="0,5 4,0 -4,0"
              fill={color}
              stroke={color}
              opacity={opacity}
            />
          </g>
        </Group>
      )}
      {(!hasLimitX1 || (x1 <= limitX1 + 2 && x1 >= limitX0)) && (
        <Group left={x0 + (x1 - x0)} top={y} height={height}>
          <g transform="translate(0,-4)">
            <polygon
              points="0,5 4,0 -4,0"
              fill={color}
              stroke={color}
              opacity={opacity}
            />
          </g>
        </Group>
      )}
      <rect
        x={hasLimitX0 ? Math.max(limitX0, x0) : x0}
        width={width}
        height={height}
        y={y}
        fill={color}
        opacity={opacity}
      />
    </>
  )
}
