import { useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { KeyboardArrowDown } from '@mui/icons-material'
import { FormControl, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { Breadcrumbs } from '@common/components'
import { useHashFilter, useSmallScreen } from '@common/utils'
import ClientMap from '@portal/pages/Home/ClientMap/ClientMap'
import CompanyScoreboard from '@portal/pages/Home/CompanyScoreboard'
import Outages from '@portal/pages/Home/Outages/Outages'
import PropertyScoreboard from '@portal/pages/Home/PropertyScoreboard'

import homeUrls from './urls'

export const CLIENT_MAP_HASH = 'clientMap'
export const DASHBOARD_OUTAGES_HASH = 'outages'
export const DASHBOARD_PROPERTY_SCOREBOARD_HASH = 'propertyScoreboard'
export const DASHBOARD_COMPANY_SCOREBOARD_HASH = 'companyScoreboard'

export default function Dashboard() {
  const isSmallScreen = useSmallScreen()

  const { hashObject, doUpdateHash } = useConnect('selectHashObject', 'doUpdateHash')

  const tabs = [
    { position: 0, label: 'Client Map', component: ClientMap, hash: CLIENT_MAP_HASH },
    { position: 1, label: 'Outages', component: Outages, hash: DASHBOARD_OUTAGES_HASH },
    {
      position: 2,
      label: 'Property Smoke Scoreboard',
      component: PropertyScoreboard,
      hash: DASHBOARD_PROPERTY_SCOREBOARD_HASH,
    },
    {
      position: 3,
      label: 'Company Smoke Scoreboard',
      component: CompanyScoreboard,
      hash: DASHBOARD_COMPANY_SCOREBOARD_HASH,
    },
  ]

  const [tabIndex, setTabIndex] = useState(0)

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const index = tabs.findIndex((item) => item.hash === hash)
    if (index !== -1) {
      setTabIndex(index)
    }
  })

  const handleHashUpdate = ({ hash }) => {
    if (hash) {
      doUpdateHash(hash)
    } else {
      doUpdateHash('')
    }
  }

  const ContentComponent = tabs.find((tab) => tab.position === tabIndex).component

  return (
    <Box mx={3} mt={3}>
      {isSmallScreen && (
        <Breadcrumbs links={[{ label: 'Home', href: homeUrls.home }]} />
      )}
      <Box sx={{ mb: 4 }}>
        {isSmallScreen ? (
          <FormControl fullWidth size="small" sx={{ my: 1 }}>
            <Select
              value={tabIndex}
              IconComponent={KeyboardArrowDown}
              onChange={(event) => handleHashUpdate(tabs[event.target.value])}
            >
              {tabs.map((option, index) => (
                <MenuItem key={option.label} value={index}>
                  {option.label} View
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Tabs value={tabIndex} textColor="primary" indicatorColor="primary">
            {tabs.map(({ label, position }) => (
              <Tab
                key={`${label}_${position}`}
                onClick={() => handleHashUpdate(tabs[position])}
                label={
                  <Typography variant="h5" sx={{ textTransform: 'none' }}>
                    {label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        )}
      </Box>
      <ContentComponent />
    </Box>
  )
}
