import { useEffect, useState } from 'react'

import { useReduxBundlerStore } from 'redux-bundler-hook'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { getApiFetch } from '@common/utils'
import Picker from '@portal/UI/components/Picker'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 */
export default function StripeTransactionForm({ open, onClose }) {
  const initValues = { dateAfter: null, dateBefore: null }
  const [dateRange, setDateRange] = useState(initValues)
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const successMsg = 'Exporting, you will receive an email shortly.'
  const errorMsg =
    'There was an error exporting Stripe transactions, please try again later.'

  const handleExport = async () => {
    const { dateAfter, dateBefore } = dateRange
    const payload = {
      start: dateAfter.set({ hour: 0, minute: 0, second: 0 }).toISO(),
      end: dateBefore.set({ hour: 23, minute: 59, second: 59 }).toISO(),
    }
    const opts = { method: 'POST', cancelationPrefix: 'transaction_form' }

    const success = await apiFetch('/stripe/export_transactions/', payload, opts)
    if (success) store?.doShowSnackbar(successMsg)
    else store?.doShowSnackbar(errorMsg, 'error')
    onClose()
  }

  useEffect(() => {
    setDateRange(initValues)
  }, [open])

  const maxDate = dateRange?.dateAfter ? dateRange.dateAfter.plus({ days: 31 }) : null

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: '1.7rem', textAlign: 'center' }}>
        Export Stripe Transactions
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-around" alignContent="center" p={1}>
          <Picker
            range
            sx={{ width: '45%' }}
            type="date"
            label="Created"
            disableMaskedInput
            conditionSeparator=""
            filterName="date"
            lowerCondition="After"
            upperCondition="Before"
            onChange={setDateRange}
            value={dateRange}
            maxDate={maxDate}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          color="secondary"
          onClick={handleExport}
          disabled={!dateRange.dateAfter || !dateRange.dateBefore}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
