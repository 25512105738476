import { Group } from '@visx/group'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.x
 * @param {number} props.height
 * @param {boolean} props.isBrushActive
 */
export default function BrushHandle({ x, height, isBrushActive }) {
  const handleWidth = 4.5
  const handleHeight = 25
  const corner = 2.5

  if (isBrushActive) {
    return (
      <Group left={x} top={(height - handleHeight) / 2}>
        <rect
          style={{
            fill: 'white',
            stroke: 'black',
            strokeWidth: '1px',
            cursor: 'ew-resize',
          }}
          width={handleWidth}
          height={handleHeight}
          rx={corner}
          ry={corner}
        />
      </Group>
    )
  }
  return null
}
