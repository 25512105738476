import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.doInstallationListSetPage
 * @param {Object} props.results
 * @param {number} props.results.current
 * @param {number} props.results.count
 * @param {number} props.results.numPages
 * @param {number} props.results.previous
 * @param {number} props.results.next
 * @param {Object[]} props.results.results
 */
export default function Pagination({ doInstallationListSetPage, results }) {
  return (
    <Stack direction="row" alignItems="center" sx={{ pt: 3, pb: 3 }}>
      <Button
        onClick={() => doInstallationListSetPage(results.previous)}
        disabled={!results?.previous}
        sx={{ mr: 2, pt: 1 }}
      >
        Previous
      </Button>

      <Typography variant="h5">
        {results?.count > 0 ? (
          <span>
            Page {results.current} of {results.numPages} ({results.count} total)
          </span>
        ) : (
          <span>No Results</span>
        )}
      </Typography>

      <Button
        onClick={() => doInstallationListSetPage(results.next)}
        disabled={!results?.next}
        sx={{ ml: 2, pt: 1 }}
      >
        Next
      </Button>
    </Stack>
  )
}
