import { path } from 'ramda'

import { DateTime } from 'luxon'

import { fetchAndProcessMetricsData, isAbortError } from '@common/utils'
import { formatCurrency } from '@common/utils/formatters'
import metrics from '@portal/pages/SmokeMetrics/metrics'

const SMOKE_SCOREBOARD_DATA_LOADING = 'SMOKE_SCOREBOARD_DATA_LOADING'
const SMOKE_SCOREBOARD_DATA_LOADED = 'SMOKE_SCOREBOARD_DATA_LOADED'
const SMOKE_SCOREBOARD_DATA_FAILED = 'SMOKE_SCOREBOARD_DATA_FAILED'

const SMOKE_SCOREBOARD_HEALTH_SCORES_LOADING = 'SMOKE_SCOREBOARD_HEALTH_SCORES_LOADING'
const SMOKE_SCOREBOARD_HEALTH_SCORES_LOADED = 'SMOKE_SCOREBOARD_HEALTH_SCORES_LOADED'
const SMOKE_SCOREBOARD_HEALTH_SCORES_FAILED = 'SMOKE_SCOREBOARD_HEALTH_SCORES_FAILED'

const SMOKE_SCOREBOARD_RESET_STATE = 'SMOKE_SCOREBOARD_RESET_STATE'

const defaultState = {}

const entityName = 'smokeScoreboard'

export const roundUp = (num, precision) => {
  const formattedPrecision = 10 ** precision
  return Math.ceil(num * formattedPrecision) / formattedPrecision
}

const getDateRange = ({ interval, period }) => {
  const now = DateTime.now()

  let start
  let end
  if (interval.value === 30) {
    const base = period === 'current' ? now : now.minus({ months: 1 })

    start = base.startOf('month')
    end = base
  } else if (interval.value === 7) {
    const base = period === 'current' ? now : now.minus({ weeks: 1 })

    start = base.minus({ weeks: 1 })
    end = base
  }

  return [start, end]
}

const getPeriodData = ({ periodData, property }) => {
  const result = {
    [metrics.eventsTotal.apiValue]: null,
    [metrics.incidentRate.apiValue]: null,
    [metrics.grossChargeRate.apiValue]: null,
    [metrics.chargeRate.apiValue]: null,
    [metrics.netCharges.apiValue]: null,
    [metrics.connectivity.apiValue]: null,
  }

  Object.values(periodData).forEach((data) => {
    const propData = data.find((d) => property.id === d.key.split(':')[1])
    if (propData) {
      const value = propData.data.reduce((acc, item) => acc + item.rawValue, 0)
      const rawValue = propData.symbol === '%' ? value / propData.data.length : value

      let formattedValue = rawValue
      let template = propData.symbol
        ? `${propData.symbolPosition === 'left' ? propData.symbol : ''}{{value}}${
            propData.symbolPosition === 'right' ? propData.symbol : ''
          }`
        : '{{value}}'
      let roundTo

      if (propData.symbol === '%') {
        roundTo = 1
        formattedValue = `${roundUp(formattedValue, roundTo)}${propData.symbol}`
      } else if (propData.symbol === '$') {
        template = `{{value}}`
        formattedValue = formatCurrency(formattedValue)
      }
      result[propData.metric] = {
        formatted: formattedValue,
        raw: rawValue,
        total: {
          type: propData.symbol === '%' ? 'avg' : 'sum',
          value: parseFloat(propData.total.replace(/[^0-9.]/g, '')),
        },
        formatting: {
          symbol: propData.symbol,
          template,
          roundTo,
        },
      }
    }
  })

  return result
}

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === SMOKE_SCOREBOARD_RESET_STATE) {
      return defaultState
    }
    if (action.type === SMOKE_SCOREBOARD_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === SMOKE_SCOREBOARD_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === SMOKE_SCOREBOARD_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === SMOKE_SCOREBOARD_HEALTH_SCORES_LOADING) {
      return { ...state, healthScores: { ...action.meta, payload: action.payload } }
    }
    if (action.type === SMOKE_SCOREBOARD_HEALTH_SCORES_LOADED) {
      return { ...state, healthScores: { ...action.meta, data: action.payload } }
    }
    if (action.type === SMOKE_SCOREBOARD_HEALTH_SCORES_FAILED) {
      return { ...state, healthScores: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectSmokeScoreboardDataIsLoading: ({ smokeScoreboard }) => {
    const status = path(['data', 'status'], smokeScoreboard)
    return status === 'loading'
  },
  selectSmokeScoreboardHealthScoresIsLoading: ({ smokeScoreboard }) => {
    const status = path(['healthScores', 'status'], smokeScoreboard)
    return status === 'loading'
  },
  selectSmokeScoreboardData: ({ smokeScoreboard }) =>
    path(['data', 'data'], smokeScoreboard),
  selectSmokeScoreboardHealthScores: ({ smokeScoreboard }) =>
    path(['healthScores', 'data'], smokeScoreboard),
  selectSmokeScoreboardDataError: ({ smokeScoreboard }) =>
    path(['data', 'error'], smokeScoreboard),
  selectSmokeScoreboardHealthScoresError: ({ smokeScoreboard }) =>
    path(['healthScores', 'error'], smokeScoreboard),
  doResetSmokeScoreboardState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: SMOKE_SCOREBOARD_RESET_STATE }),
  doFetchSmokeScoreboardData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      const { organizations, interval } = payload

      const [currentPeriodStart, currentPeriodEnd] = getDateRange({
        interval,
        period: 'current',
      })
      const [prevPeriodStart, prevPeriodEnd] = getDateRange({
        interval,
        period: 'previous',
      })

      try {
        dispatch({
          type: SMOKE_SCOREBOARD_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const propertiesResponse = await apiFetch(
          '/properties/',
          {
            active: true,
            pageSize: 9999,
            organization: organizations.join(','),
          },
          { cancelationPrefix: entityName },
        )
        const propetiesIds = propertiesResponse?.results?.map((prop) => prop.id)

        const metricsToFetch = [
          metrics.eventsTotal,
          metrics.incidentRate,
          metrics.grossChargeRate,
          metrics.chargeRate,
          metrics.netCharges,
          metrics.connectivity,
        ].map((item) => item.apiValue)
        const currentPeriodMetricsResponse = await fetchAndProcessMetricsData({
          apiFetch,
          payload: {
            start: currentPeriodStart.toFormat('yyyy-MM-dd'),
            end: currentPeriodEnd.toFormat('yyyy-MM-dd'),
            properties: propetiesIds?.join(','),
            metrics: metricsToFetch,
            includeTotals: true,
          },
          intervalType: interval.type,
          interval: interval.value,
          chartColors: [],
          metricsToProject: [],
        })
        const previousPeriodMetricsResponse = await fetchAndProcessMetricsData({
          apiFetch,
          payload: {
            start: prevPeriodStart.toFormat('yyyy-MM-dd'),
            end: prevPeriodEnd.toFormat('yyyy-MM-dd'),
            properties: propetiesIds?.join(','),
            metrics: metricsToFetch,
            includeTotals: true,
          },
          intervalType: interval.type,
          interval: interval.value,
          chartColors: [],
          metricsToProject: [],
        })

        const propertiesCount = {
          propertiesTotal: 0,
          propertiesLive: 0,
          propertiesPending: 0,
        }
        const processedProperties = propertiesResponse?.results?.map((property) => {
          propertiesCount.propertiesTotal += 1
          const goLiveDate = property.goLiveDate
            ? DateTime.fromISO(property.goLiveDate)
            : null
          let isLive = false
          if (goLiveDate) {
            if (goLiveDate <= DateTime.now()) {
              isLive = true
              propertiesCount.propertiesLive += 1
            } else {
              propertiesCount.propertiesPending += 1
            }
          }

          // Period data
          const currentPeriod = getPeriodData({
            periodData: currentPeriodMetricsResponse,
            property,
          })
          const previousPeriod = getPeriodData({
            periodData: previousPeriodMetricsResponse,
            property,
          })

          return {
            id: property.id,
            name: property.name,
            organization: property.organization,
            organizationName: property.organizationName,
            account: property.account,
            accountName: property.accountName,
            installedSensorCount: property.installedSensorCount ?? 0,
            liveSensorCount: property.liveSensorCount ?? 0,
            currentPeriod,
            previousPeriod,
            isLive,
          }
        })

        const processedData = {
          ...propertiesCount,
          currentPeriodRange: [currentPeriodStart, currentPeriodEnd],
          previousPeriodRange: [prevPeriodStart, prevPeriodEnd],
          properties: processedProperties,
          requestData: {
            properties: propetiesIds,
            start: currentPeriodStart,
            end: currentPeriodEnd,
          },
        }

        dispatch({
          type: SMOKE_SCOREBOARD_DATA_LOADED,
          payload: processedData,
          meta: { status: 'succeeded' },
        })
        return processedData
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: SMOKE_SCOREBOARD_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchSmokeScoreboardHeathScores:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      const { start, end, properties, signal } = payload

      try {
        dispatch({
          type: SMOKE_SCOREBOARD_HEALTH_SCORES_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const healthScores = await apiFetch(
          '/properties/health_scores/',
          {
            properties,
            start: start.toFormat('yyyy-MM-dd'),
            end: end.toFormat('yyyy-MM-dd'),
          },
          { signal },
        )

        const processedData = properties.reduce(
          (acc, propertyId) => ({
            ...acc,
            [propertyId]: { current: null, previous: null },
          }),
          {},
        )

        healthScores.current.forEach((data) => {
          processedData[data.property].current = {
            start: data.start,
            end: data.end,
            score: data.healthScore,
          }
        })
        healthScores.previous.forEach((data) => {
          processedData[data.property].previous = {
            start: data.start,
            end: data.end,
            score: data.healthScore,
          }
        })

        dispatch({
          type: SMOKE_SCOREBOARD_HEALTH_SCORES_LOADED,
          payload: processedData,
          meta: { status: 'succeeded' },
        })
        return processedData
      } catch (err) {
        dispatch({
          type: SMOKE_SCOREBOARD_HEALTH_SCORES_FAILED,
          payload: err,
          meta: { status: 'failed' },
        })
        throw err
      }
    },
}
