import { Stack, Typography } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {string|number|Object} [props.value]
 * @param {number} [props.size]
 * @param {Function} [props.renderValue]
 */
export default function DetailStack({
  label,
  value = undefined,
  size = 16,
  renderValue = undefined,
  ...rest
}) {
  return (
    <Stack {...rest}>
      <Typography variant="caption" color="grey.500" fontWeight={600}>
        {label}
      </Typography>
      {renderValue && renderValue(value)}
      {!renderValue && (
        <Typography variant="body1" color="black" fontSize={size} whiteSpace="pre-line">
          {value === null || value === undefined || value === '' ? '--' : `${value}`}
        </Typography>
      )}
    </Stack>
  )
}
