import { equals, pick } from 'ramda'

export const DEFAULT_PROCESS = 'Default (Royalton)'
export const LEGACY_PROCESS = 'Legacy (Live Alerts)'
export const GROUPING_PROCESS = 'Reservation Grouping'

export const digestDeliveryOptions = [
  { label: '12:00AM', id: '0' },
  { label: '1:00AM', id: '1' },
  { label: '2:00AM', id: '2' },
  { label: '3:00AM', id: '3' },
  { label: '4:00AM', id: '4' },
  { label: '5:00AM', id: '5' },
  { label: '6:00AM', id: '6' },
  { label: '7:00AM', id: '7' },
  { label: '8:00AM', id: '8' },
  { label: '9:00AM', id: '9' },
  { label: '10:00AM', id: '10' },
  { label: '11:00AM', id: '11' },
  { label: '12:00PM', id: '12' },
  { label: '1:00PM', id: '13' },
  { label: '2:00PM', id: '14' },
  { label: '3:00PM', id: '15' },
  { label: '4:00PM', id: '16' },
  { label: '5:00PM', id: '17' },
  { label: '6:00PM', id: '18' },
  { label: '7:00PM', id: '19' },
  { label: '8:00PM', id: '20' },
  { label: '9:00PM', id: '21' },
  { label: '10:00PM', id: '22' },
  { label: '11:00PM', id: '23' },
]

export const propertyProcessConfig = {
  [DEFAULT_PROCESS]: {
    desc: 'Events are grouped noon-to-noon and suppressed until the smoke digest goes out',
    fields: {
      liveSmokeAlerts: false,
      reservationGrouping: false,
    },
  },
  [LEGACY_PROCESS]: {
    desc: 'Live events, live alerts',
    fields: {
      liveSmokeAlerts: true,
      reservationGrouping: false,
    },
  },
  [GROUPING_PROCESS]: {
    desc: 'Events are grouped by reservation and suppressed until the smoke digest goes out',
    fields: {
      liveSmokeAlerts: false,
      reservationGrouping: true,
    },
  },
}

export const getExistingPropertyProcess = (property) => {
  if (!property) return undefined
  const existingConfig = pick(
    Object.keys(propertyProcessConfig[DEFAULT_PROCESS].fields),
    property,
  )

  const matchedConfig = Object.entries(propertyProcessConfig).find(([, config]) =>
    equals(config.fields, existingConfig),
  )

  return matchedConfig ? matchedConfig[0] : undefined
}
