import { createListBundle, parseApiErrors } from '@common/utils'

import propertyUrls from '../urls'

const entityName = 'propertyUser'

const propertyUserListBundleRaw = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const propertyId = params?.id || store.selectRouteParams().id
    return apiFetch(`/properties/${propertyId}/memberships/`, params, {
      cancelationPrefix: entityName,
    })
  },
  urlTest: (_, pattern) => pattern === propertyUrls.entity,
})

const propertyUserListBundle = {
  ...propertyUserListBundleRaw,
  doPropertyUserDelete:
    (payload) =>
    async ({ apiFetch }) => {
      const { id, propertyId } = payload

      const response = await apiFetch(
        `/properties/${propertyId}/memberships/${id}`,
        null,
        { method: 'DELETE', cancelationPrefix: entityName },
      )
      return response
    },
  doPropertyUserListExport:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectPropertyUserListApiParams()
      const propertyId = store.selectRouteParams().id
      const { count = 9999 } = store.selectPropertyUserList()
      try {
        const response = await apiFetch(
          `/properties/${propertyId}/memberships/`,
          {
            ...apiParams,
            pageSize: count,
          },
          { cancelationPrefix: entityName },
        )
        if (response.error) throw response.error
        return response.results
      } catch (error) {
        const parsedError = parseApiErrors(error?.response)
        store.doShowSnackbar(parsedError || 'Unable to export events', 'error')
      }
      return []
    },
}

export default propertyUserListBundle
