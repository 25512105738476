const sanitizeFileName = (name) => name.replace(/[^a-z0-9]/gi, '_')

export const dummyRow = {
  'Unit Name': 'Room 101',
  'Guest Name': 'John Doe',
  'Phone Number': '+1323412-3123',
  'Check In': '2023-11-10 00:00',
  'Check Out': '2023-11-11 00:00',
}

export const getTemplate = (overwrite = {}) => {
  const headers = 'Unit Name,Guest Name,Phone Number,Check In,Check Out\n'
  const getData = () => {
    const headerList = headers.trim().split(',')

    const defaultRow = headerList
      .map((header, index) =>
        overwrite[header]
          ? `${overwrite[header]}${index !== headerList.length - 1 ? ',' : ''}`
          : ',',
      )
      .join('')

    return [headers, overwrite && defaultRow]
  }

  return {
    data: getData(),
    fileName: 'reservations.csv',
    fileType: 'text/csv',
  }
}

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob(data, { type: fileType })

  const a = document.createElement('a')
  a.download = sanitizeFileName(fileName)
  a.download = fileName
  a.href = globalThis.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: globalThis,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}
