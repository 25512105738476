import { timeFormat } from '@visx/vendor/d3-time-format'
import { DateTime } from 'luxon'

const formatMillisecond = timeFormat('.%L')
const formatSecond = timeFormat(':%S')
const formatMinute = timeFormat('%I:%M')
const formatHour = timeFormat('%I %p')
const formatDay = timeFormat('%a %d')
const formatWeek = timeFormat('%b %d')
const formatMonth = timeFormat('%B')
const formatYear = timeFormat('%Y')

const multiFormat = ({ date, prevDate, defaultFormatter }) => {
  if (prevDate) {
    if (prevDate.year !== date.year) {
      return formatYear(date)
    }
    if (prevDate.month !== date.month) {
      return formatMonth(date)
    }
    if (prevDate.day !== date.day) {
      return formatDay(date)
    }
    if (prevDate.weekNumber !== date.weekNumber) {
      return formatWeek(date)
    }
    if (prevDate.hour !== date.hour) {
      return formatHour(date)
    }
    if (prevDate.minute !== date.minute) {
      return formatMinute(date)
    }
    if (prevDate.second !== date.second) {
      return formatSecond(date)
    }
    if (prevDate.millisecond !== date.millisecond) {
      return formatMillisecond(date)
    }
  }

  return defaultFormatter(date)
}

export function tzAwareTickFormat(data, scale, zone) {
  const { time, index, tickValues } = data

  const defaultTickFormat = scale.tickFormat()
  const ticks = tickValues ?? scale.ticks()

  const currentTime = DateTime.fromJSDate(time)
  const zonedTime = currentTime.setZone(zone)

  const currentOffset = currentTime.offset * 60000
  const zonedOffset = zonedTime.offset * 60000

  let prevTime
  let prevOffset
  let prevZonedOffset
  if (index !== 0) {
    prevTime = DateTime.fromJSDate(ticks[index - 1])
    const prevZonedTime = prevTime.setZone(zone)

    prevOffset = prevTime.offset * 60000
    prevZonedOffset = prevZonedTime.offset * 60000
  }

  return multiFormat({
    defaultFormatter: defaultTickFormat,
    date: DateTime.fromMillis(currentTime.toMillis() - currentOffset + zonedOffset),
    prevDate:
      index !== 0
        ? DateTime.fromMillis(prevTime.toMillis() - prevOffset + prevZonedOffset)
        : null,
  })
}

export function addAlphaToColor(color, opacity) {
  if (color) {
    const opacityValue = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    return color + opacityValue.toString(16).toUpperCase()
  }
  return null
}

export function generateFixedBottomTicks({ graphData, count }) {
  const parseTime = (t) => {
    if (DateTime.isDateTime(t)) {
      return t.toJSDate()
    }
    if (typeof t === 'string') {
      return DateTime.fromISO(t).toJSDate()
    }
    return t
  }

  if (graphData.length <= 1) {
    return graphData.map((d) => parseTime(d.time))
  }

  const dates = []

  const start = graphData.find((e) => e.time)?.time
  const end = graphData.findLast((e) => e.time)?.time

  if (!start || !end) return dates

  const parsedStart = parseTime(start)
  const parsedEnd = parseTime(end)

  const interval = (parsedEnd - parsedStart) / count
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    const date = new Date(parsedStart.getTime() + i * interval)
    dates.push(date)
  }

  return dates
}
