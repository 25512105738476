import sanitizeFileName from './sanitizeFileName'

export const noop = () => null

export const merge = (initialValues, instance) => {
  const finalValue = {}
  Object.keys(initialValues).forEach((field) => {
    finalValue[field] = instance?.[field] ?? initialValues[field]
  })
  return finalValue
}

export const mergeArrays = (arr1, arr2, predicate = (a, b) => a === b) => {
  const copy = [...arr1]
  arr2.forEach((arr2Item) =>
    copy.some((arr1Item) => predicate(arr2Item, arr1Item)) ? null : copy.push(arr2Item),
  )
  return copy
}

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob(data, { type: fileType })

  const a = document.createElement('a')
  a.download = sanitizeFileName(fileName)
  a.download = fileName
  a.href = globalThis.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: globalThis,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}
