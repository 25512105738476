import { useCallback, useMemo, useRef, useState } from 'react'

import { path } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'

import { Field, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import { FormDialog } from '@rest/UI/components'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.chargeType
 * @param {Function} props.handleChargeTypeChange
 * @param {string} props.fee
 */
function ChargeTypeSelection({ chargeType, handleChargeTypeChange, fee }) {
  const formik = useFormikContext()
  return (
    <FormControl>
      <FormLabel id="charge-type-group">
        Select charge amount for smoking event:
      </FormLabel>
      <RadioGroup
        aria-labelledby="charge-type-group"
        name="chargeType"
        value={chargeType}
        onChange={(event) => handleChargeTypeChange(event, formik)}
      >
        <FormControlLabel
          value="default"
          control={<Radio />}
          label={`$${fee} - property policy amount`}
        />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {Object} [props.instance.smokeFeedback]
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string} props.property.name
 * @param {string} [props.property.smokingFee]
 */
export default function PostCharge({ onClose, instance = {}, property }) {
  const { doEventFeedbackSave, smokeAdjustmentReasons } = useConnect(
    'doEventFeedbackSave',
    'selectSmokeAdjustmentReasons',
  )

  const initialValues = {
    chargeAmount: property.smokingFee,
    chargeType: 'default',
    comments: '',
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        chargeAmount: Yup.number()
          .typeError('Please enter a valid amount')
          .test(
            'is-decimal',
            'Please enter a valid amount',
            (value) =>
              value === undefined ||
              value === 0 ||
              `${value}`.match(/^[1-9]\d*(\.\d{0,2})?$/),
          )
          .required('Please enter a charge amount'),
        adjustmentReason: Yup.string().when('chargeAmount', {
          is: (val) => Number(val) !== Number(property.smokingFee),
          then: (field) => field.required('Please select a reason for adjustment'),
          otherwise: (field) => field.nullable(),
        }),
        comments: Yup.string().when('adjustmentReason', {
          is: (val) => val === 'OTHER',
          then: (field) =>
            field
              .required('Please enter a comment for adjustment')
              .matches(/\s*(?:[\w.]\s*){10,}$/, 'Please enter at least 10 characters'),
          otherwise: (field) => field.nullable(),
        }),
      }),
    [property.smokingFee],
  )

  const onFeedbackSave = useCallback(
    async (data) => {
      const result = await doEventFeedbackSave({
        ...data,
        id: path(['smokeFeedback', 'id'], instance),
        property: property.id,
        event: instance.id,
        netChargeAmount: data.chargeAmount,
        chargeAmount: property.smokingFee,
        adjustmentAmount: Number(data.chargeAmount) - Number(property.smokingFee),
      })
      if (result.error) throw parseApiErrors(result)
      return result
    },
    [doEventFeedbackSave, instance],
  )

  const fieldRef = useRef(null)

  const [chargeType, setChargeType] = useState('default')

  const handleChargeTypeChange = (event, formik) => {
    setChargeType(event.target.value)
    formik.setFieldValue('chargeType', event.target.value)
    if (event.target.value === 'default') {
      formik.setFieldValue('chargeAmount', property.smokingFee)
    } else {
      formik.setFieldValue('chargeAmount', '')
    }
  }

  return (
    <FormDialog
      open
      data-testid="post_charge_dialog"
      onClose={onClose}
      initialValues={initialValues}
      label="Smoking Event Charge"
      validationSchema={validationSchema}
      onSave={onFeedbackSave}
      successMessage="Successfully saved charge information"
      bypassDirtyState
    >
      <Stack spacing={2} mt={0}>
        <ChargeTypeSelection
          chargeType={chargeType}
          handleChargeTypeChange={handleChargeTypeChange}
          fee={property.smokingFee}
        />

        {chargeType === 'other' && (
          <>
            <Field
              component={TextField}
              name="chargeAmount"
              label="Charge Amount"
              InputProps={{
                inputRef: fieldRef,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              autoFocus
            />

            <Field
              component={StaticSelect}
              name="adjustmentReason"
              label="Adjustment Reason"
              options={smokeAdjustmentReasons}
            />
          </>
        )}
        <Field
          component={TextField}
          name="comments"
          label="Comments"
          InputProps={{ multiline: true }}
        />
      </Stack>
    </FormDialog>
  )
}
