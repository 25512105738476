import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'

import { SearchBox } from '@common/components'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.ordering
 * @param {Function} props.setOrdering
 * @param {string} props.search
 * @param {Function} props.doInstallationListSetSearch
 * @param {string} props.photoType
 * @param {Function} props.setPhotoType
 * @param {boolean} props.reviewed
 * @param {Function} props.setReviewed
 */
export default function Filters({
  ordering,
  setOrdering,
  search,
  doInstallationListSetSearch,
  photoType,
  setPhotoType,
  reviewed,
  setReviewed,
}) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <FormControl sx={{ m: 1, mr: 1, ml: 0, minWidth: 200 }}>
        <SearchBox
          label="Search"
          onSetSearch={doInstallationListSetSearch}
          initialValue={search}
          sx={{}}
          size="normal"
          margin="none"
        />
      </FormControl>
      <FormControl sx={{ m: 1, mr: 3, minWidth: 250 }}>
        <InputLabel id="demo-simple-select-label">Ordering</InputLabel>
        <Select
          value={ordering}
          label="Ordering"
          autoWidth
          onChange={(e) => setOrdering(e.target.value)}
        >
          <MenuItem value="unit__name">Unit Name</MenuItem>
          <MenuItem value="-created_on">Newest First</MenuItem>
          <MenuItem value="created_on">Oldest First</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, mr: 3, minWidth: 250 }}>
        <InputLabel id="demo-simple-select-label">Photo Type</InputLabel>
        <Select
          value={photoType}
          label="Photo Type"
          autoWidth
          onChange={(e) => setPhotoType(e.target.value)}
        >
          <MenuItem value="ALL">All Types</MenuItem>
          <MenuItem value="PLATE">Room Plate</MenuItem>
          <MenuItem value="WALL">Install Location</MenuItem>
          <MenuItem value="OTHER">Other</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={reviewed}
            onChange={(_, checked) => {
              setReviewed(checked)
            }}
          />
        }
        label="Include reviewed photos"
      />
    </Stack>
  )
}
