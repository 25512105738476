import { useCallback, useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CloseOutlined } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  Typography,
} from '@mui/material'

import { parseApiErrors, useSmallScreen } from '@common/utils'

import AdjustmentsPieChart from './AdjustmentsPieChart'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.anchorEl
 * @param {Object[]} props.dateRange
 * @param {Object} props.reasonsById
 * @param {string} [props.unitId]
 * @param {Function} [props.onClose]
 * @param {Function} [props.onAdjustmentClick]
 */
export default function AdjustmentSummaryModal({
  anchorEl,
  dateRange,
  reasonsById,
  unitId = undefined,
  onClose = () => {},
  onAdjustmentClick = () => {},
}) {
  const isSmallScreen = useSmallScreen()

  const {
    adjustmentSummary,
    adjustmentSummaryError,
    adjustmentSummaryIsLoading,
    doFetchAdjustmentSummary,
    doShowSnackbar,
  } = useConnect(
    'selectAdjustmentSummary',
    'selectAdjustmentSummaryError',
    'selectAdjustmentSummaryIsLoading',
    'doFetchAdjustmentSummary',
    'doShowSnackbar',
  )

  const fetchAdjustmentSummary = useCallback(async () => {
    try {
      await doFetchAdjustmentSummary({ range: dateRange, unitId })
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [dateRange, unitId])

  useEffect(() => {
    if (dateRange) {
      fetchAdjustmentSummary()
    }
  }, [dateRange])

  const handleAdjustmentClick = (reason) => {
    if (onAdjustmentClick) {
      const events = adjustmentSummary.eventsByAdjustment[reason] ?? []
      onAdjustmentClick({ reason, eventsIds: events.map((event) => event.id) })
    }
  }

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 50, horizontal: -10 }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      transitionDuration={150}
      onClose={onClose}
    >
      <Box sx={{ p: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="600">
            Adjustment Summary
          </Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Box>
        <Divider sx={{ my: 2 }} />
        {!adjustmentSummaryIsLoading && adjustmentSummary?.eventsTotal > 0 && (
          <Typography variant="body2" mb={1} color="grey.600">
            Click on an adjustment reason to filter events
          </Typography>
        )}
        {adjustmentSummaryIsLoading && (
          <Box display="flex" justifyContent="center" p={20}>
            <CircularProgress />
          </Box>
        )}
        {!adjustmentSummaryIsLoading && adjustmentSummaryError && (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              Oops! Something went wrong!
            </Typography>
          </Box>
        )}
        {!adjustmentSummaryIsLoading && adjustmentSummary?.eventsTotal === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography variant="body2" color="grey">
              No data for selected time period
            </Typography>
          </Box>
        )}
        {!adjustmentSummaryIsLoading && adjustmentSummary?.eventsTotal > 0 && (
          <Box>
            <Typography component="span">
              <Box fontWeight="fontWeightMedium" display="inline">
                {adjustmentSummary.eventsTotal}
              </Box>{' '}
              events,{' '}
              <Box fontWeight="fontWeightMedium" display="inline">
                {adjustmentSummary.eventWithAdjustments}
              </Box>{' '}
              with adjustments (
              <Box fontWeight="fontWeightMedium" display="inline">
                {adjustmentSummary.eventWithAdjustmentsPercentage}%
              </Box>
              )
            </Typography>
            <AdjustmentsPieChart
              data={adjustmentSummary.adjustmentsCount}
              reasonsById={reasonsById}
              height={isSmallScreen ? 100 : 300}
              width={isSmallScreen ? 100 : 300}
              onAdjustmentClick={(reason) => {
                handleAdjustmentClick(reason)
                onClose()
              }}
              sx={{ mt: 4, mb: 1.5 }}
            />
          </Box>
        )}
      </Box>
    </Popover>
  )
}
