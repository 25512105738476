import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { humanize } from 'inflection'

import { MobileList, MobileListDefaultCard } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { formatCurrency, useSmallScreen } from '@common/utils'
import { MainCell } from '@portal/UI/components/cells'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'

import InvoiceDetailModal from './InvoiceDetailModal'
import InvoiceStatusChip from './InvoiceStatusChip'

const invoiceStatuses = ['WAITING_FOR_PAYMENT', 'PAID', 'PAST_DUE', 'VOIDED', 'UPDATED']

export default function Invoices() {
  const isSmallScreen = useSmallScreen()
  const [invoiceDetail, setInvoiceDetail] = useState(null)

  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    doPropertyInvoiceListClearParams,
    doPropertyInvoiceListSetFilter,
    doPropertyInvoiceListSetPage,
    doPropertyInvoiceListSetPageSize,
    doPropertyInvoiceListSetOrdering,
    doPropertyInvoiceListSetSearch,
    propertyInvoiceList,
    propertyInvoiceListRaw: { ordering = [] },
    propertyInvoiceListIsLoading,
    propertyInvoiceListApiParams,
  } = useConnect(
    'doPropertyInvoiceListClearParams',
    'doPropertyInvoiceListSetFilter',
    'doPropertyInvoiceListSetPage',
    'doPropertyInvoiceListSetPageSize',
    'doPropertyInvoiceListSetOrdering',
    'doPropertyInvoiceListSetSearch',
    'selectPropertyInvoiceList',
    'selectPropertyInvoiceListRaw',
    'selectPropertyInvoiceListIsLoading',
    'selectPropertyInvoiceListApiParams',
  )

  useEffect(() => {
    doPropertyInvoiceListClearParams()
  }, [])

  const statusOptions = useMemo(
    () => invoiceStatuses.map((status) => ({ id: status, label: humanize(status) })),
    [],
  )

  const columns = [
    {
      field: 'stripeInvoiceNumber',
      headerName: 'Invoice Number',
      renderCell: ({ row }) => (
        <MainCell
          label={row.stripeInvoiceNumber}
          onClick={() => setInvoiceDetail(row)}
        />
      ),
      flex: 0.7,
      sortable: false,
    },
    {
      field: 'gracePeriod',
      headerName: 'Grace Period',
      flex: 0.6,
      sortable: false,
    },
    {
      field: 'invoiceCreatedDate',
      headerName: 'Created On',
      flex: 0.7,
    },
    {
      field: 'payByDate',
      headerName: 'Pay By',
      flex: 0.5,
    },
    {
      field: 'amountDue',
      headerName: 'Amount Due',
      valueFormatter: (value) => (value ? formatCurrency(value) : 'N/A'),
      renderMobile: ({ row }) =>
        row.amountDue ? formatCurrency(row.amountDue) : 'N/A',
      flex: 0.7,
    },
    {
      field: 'invoicePaymentStatus',
      headerName: 'Status',
      valueFormatter: (value) => (value ? humanize(value) : 'N/A'),
      renderCell: ({ row }) => <InvoiceStatusChip status={row.invoicePaymentStatus} />,
      flex: 0.6,
    },
  ]

  const rows = propertyInvoiceList?.results?.map((invoice) => ({
    id: invoice.id,
    property: invoice.property,
    stripeInvoiceNumber: invoice.stripeInvoiceNumber,
    stripeInvoiceId: invoice.stripeInvoiceId,
    stripeCustomerId: invoice.stripeCustomerId,
    hostedInvoiceUrl: invoice.hostedInvoiceUrl,
    gracePeriod: invoice.gracePeriod,
    invoiceCreatedDate: invoice.invoiceCreatedDate,
    payByDate: invoice.payByDate,
    amountDue: invoice.amountDue,
    invoicePaymentStatus: invoice.invoicePaymentStatus,
  }))

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  return (
    <>
      <InvoiceDetailModal
        open={!!invoiceDetail}
        onClose={() => setInvoiceDetail(null)}
        invoice={invoiceDetail}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '900px',
        }}
      >
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={propertyInvoiceListIsLoading}
            apiParams={propertyInvoiceListApiParams}
            setApiParams={doPropertyInvoiceListSetFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect
              label="Status"
              filterName="invoicePaymentStatus"
              options={statusOptions}
            />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                title="Invoices"
                loading={propertyInvoiceListIsLoading}
                showActions={false}
                itemBuilder={MobileListItem}
                onFilterPressed={() => setFilterModalOpen(true)}
                page={propertyInvoiceList?.current || 1}
                pageChange={doPropertyInvoiceListSetPage}
                pageSize={propertyInvoiceList?.pageSize}
                pageSizeChange={doPropertyInvoiceListSetPageSize}
                rows={rows}
                rowCount={propertyInvoiceList?.count || 0}
                setSearch={doPropertyInvoiceListSetSearch}
              />
            ) : (
              <List
                title="Invoices"
                loading={propertyInvoiceListIsLoading}
                showActions={false}
                showAddButton={false}
                columns={columns}
                page={propertyInvoiceList?.current || 1}
                pageChange={doPropertyInvoiceListSetPage}
                pageSize={propertyInvoiceList?.pageSize}
                pageSizeChange={doPropertyInvoiceListSetPageSize}
                rows={rows}
                rowCount={propertyInvoiceList?.count || 0}
                setSearch={doPropertyInvoiceListSetSearch}
                sortChange={doPropertyInvoiceListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
