import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { useQueryFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import orgUrls from '@portal/pages/Organizations/urls'
import { BooleanCell } from '@portal/UI/components/cells'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListExportButton from '@portal/UI/components/ListExportButton'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import boolOptions from '@portal/Utils/constants'

import AccountForm from './AccountForm'
import accountUrls from './urls'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 * @param {string} [props.organizationId]
 */
export default function AccountList({ renderAsTab = false, organizationId = '' }) {
  const [pageSize, setPageSize] = useState('')
  const [saveFormOpen, setSaveFormOpen] = useState(false)
  const [deleteError, setDeleteError] = useState(null)
  const [currentAccount, setCurrentAccount] = useState(null)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [rowIdForDelete, setRowIdForDelete] = useState(null)
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    doAccountDelete,
    doShowSnackbar,
    doAccountListClearParams,
    doAccountListSetFilter,
    doAccountListSetPage,
    doAccountListSetPageSize,
    doAccountListSetOrdering,
    doMarkAccountListAsOutdated,
    doUpdateUrl,
    doUpdateQuery,
    accountList,
    accountListRaw: { ordering = [] },
    accountListIsLoading,
    systemFeatureFlags,
    accountListApiParams,
    queryObject,
  } = useConnect(
    'doAccountDelete',
    'doShowSnackbar',
    'doAccountListClearParams',
    'doAccountListSetFilter',
    'doAccountListSetPage',
    'doAccountListSetPageSize',
    'doAccountListSetOrdering',
    'doMarkAccountListAsOutdated',
    'doUpdateUrl',
    'doUpdateQuery',
    'selectAccountList',
    'selectAccountListRaw',
    'selectAccountListIsLoading',
    'selectSystemFeatureFlags',
    'selectAccountListApiParams',
    'selectQueryObject',
  )

  useEffect(() => {
    doMarkAccountListAsOutdated()
  }, [])

  const isSmallScreen = useSmallScreen()

  useQueryFilter({
    query: queryObject,
    apiParams: accountListApiParams,
    setFilter: doAccountListSetFilter,
    setPageSize: doAccountListSetPageSize,
  })

  const handleClear = () => {
    doAccountListClearParams()
    doAccountListSetFilter('')
  }

  const handlePageChange = async (page) => {
    doAccountListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doAccountListSetPageSize(size)
  }

  const handleRowClick = (acc) => {
    const accountDetail = accountUrls.entity.replace(':id', acc.id)
    doUpdateUrl(accountDetail)
  }

  const handleAccountUpdate = (row) => {
    setSaveFormOpen(true)
    setCurrentAccount(row)
  }

  const handleAccountDelete = (row) => {
    setDeleteFormOpen(true)
    setRowIdForDelete(row)
  }

  const handleDeleteClose = () => {
    setDeleteFormOpen(false)
    setRowIdForDelete(null)
  }

  const deleteAccount = async () => {
    try {
      await doAccountDelete(rowIdForDelete)
      doMarkAccountListAsOutdated()
      doShowSnackbar('Successfully deleted account')
      handleDeleteClose()
    } catch (e) {
      setDeleteError(e)
    }
  }

  const exportListConfig = {
    apiParams: accountListApiParams,
    entity: 'accounts',
  }

  const listActions = {
    create: () => setSaveFormOpen(true),
    update: handleAccountUpdate,
    delete: handleAccountDelete,
  }

  const columns = [
    { field: 'name', headerName: 'Name', isMainCell: true, flex: 1 },
    {
      field: 'active',
      sortField: 'deleted_on',
      headerName: 'Active',
      renderCell: BooleanCell,
      headerAlign: 'center',
      flex: 0.25,
    },
    ...(organizationId
      ? []
      : [
          {
            field: 'organizationName',
            sortField: 'organization__name',
            headerName: 'Organization',
            renderCell: ({ row }) => (
              <ClickableCell
                label={row.organizationName}
                url={orgUrls.entity.replace(':id', row.organization)}
              />
            ),
            flex: 0.75,
          },
        ]),
    {
      field: 'propertyCount',
      sortField: 'property_count',
      headerName: 'Properties',
      flex: 0.4,
    },
    { field: 'unitCount', sortField: 'unit_count', headerName: 'Units', flex: 0.25 },
    {
      field: 'deviceCount',
      sortField: 'device_count',
      headerName: 'Devices',
      flex: 0.3,
    },
    { field: 'score', headerName: 'Score', flex: 0.25 },
  ]

  const rows = accountList?.results?.map((acc) => ({
    id: acc.id,
    name: acc.name,
    organization: acc.organization,
    organizationName: acc.organizationName,
    propertyCount: acc.propertyCount,
    unitCount: acc.unitCount,
    deviceCount: acc.deviceCount,
    active: !acc.deletedOn,
    score: 0, // backend not implemented yet
    salesForce: acc.isInSalesforce,
    stripe: acc.isInStripe,
    v1: acc.isInV1,
  }))

  const MobileItemHeader = useCallback(({ row }) => {
    const StatusIcon = row.active ? CheckCircleOutline : CancelOutlined
    return (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={accountUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.name}
          </Typography>
        </Link>
        <StatusIcon
          color={row.active ? 'success' : 'warning'}
          sx={{ fontSize: 16, ml: 0.5 }}
        />
      </Box>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name', 'active'],
        multiRowFields: [
          ['propertyCount', 'unitCount'],
          ['deviceCount', 'score'],
        ],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  if (!accountList.results)
    return <ErrorComponent title="Accounts" callback={handleClear} />

  return (
    <>
      <DeleteModal
        open={deleteFormOpen}
        error={deleteError}
        onConfirmDelete={deleteAccount}
        onCancelDelete={handleDeleteClose}
      />
      <AccountForm
        open={saveFormOpen}
        onClose={() => {
          setCurrentAccount(null)
          doMarkAccountListAsOutdated()
          setSaveFormOpen(false)
        }}
        instance={currentAccount ?? { organization: organizationId }}
      />
      <Box
        m={renderAsTab ? 0 : 3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!renderAsTab && (
          <>
            <Breadcrumbs
              links={[{ label: 'Home', href: homeUrls.home }, { label: 'Accounts' }]}
            />
            <ListPageTitle
              title="Accounts"
              onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
              menuItems={
                isSmallScreen
                  ? [
                      { label: 'Add Account', onClick: () => setSaveFormOpen(true) },
                      {
                        label: 'Export',
                        onClick: () => {},
                        render: ({ onClose, key }) => (
                          <ListExportButton
                            key={key}
                            mode="menuItem"
                            postExport={onClose}
                            {...exportListConfig}
                          />
                        ),
                      },
                    ]
                  : null
              }
              mb={2}
            />
          </>
        )}
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={accountListIsLoading}
            apiParams={accountListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            {!renderAsTab && (
              <>
                <DynamicSelect
                  label="Organization Groups"
                  filterName="organization_group"
                  size="small"
                />
                <DynamicSelect
                  label="Organizations"
                  filterName="organization"
                  size="small"
                />
                <StaticSelect
                  label="Feature Flags"
                  filterName="flag"
                  getOptionLabel={(option) => option && option.replace('_', ' ')}
                  options={systemFeatureFlags}
                  size="small"
                />
              </>
            )}
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title="Accounts"
                loading={accountListIsLoading}
                actions={listActions}
                itemBuilder={MobileListItem}
                onFilterPressed={renderAsTab ? () => setFilterModalOpen(true) : null}
                listActions={
                  renderAsTab
                    ? [{ label: 'Add Account', onClick: () => setSaveFormOpen(true) }]
                    : null
                }
                page={accountList.current || 1}
                pageChange={handlePageChange}
                pageSize={accountList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={accountList.count || 0}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                title="Accounts"
                loading={accountListIsLoading}
                exportListConfig={renderAsTab ? null : exportListConfig}
                actions={listActions}
                columns={columns}
                page={accountList.current || 1}
                pageChange={handlePageChange}
                pageSize={accountList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowClick={handleRowClick}
                rowCount={accountList.count || 0}
                sortChange={doAccountListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
