import { forwardRef } from 'react'

import { Rect } from 'react-konva'

import { HANDLE_HEIGHT, RECT_HORIZONTAL_ORIGIN, VERTICAL_GRID_SPACE } from './constants'
import { getRectWidth } from './Rects'
import { roundToNearestHour, setCursorStyle } from './utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {number} props.y
 * @param {Function} props.onDragEnd
 * @param {Function} props.onDragStart
 * @param {Function} props.dragBoundFunc
 * @param {boolean} props.draggable
 */
const ResizeHandle = forwardRef(
  (
    { editorWidth = undefined, y, onDragEnd, onDragStart, dragBoundFunc, draggable },
    ref,
  ) => (
    <Rect
      x={RECT_HORIZONTAL_ORIGIN}
      y={y}
      ref={ref}
      height={HANDLE_HEIGHT}
      width={getRectWidth(editorWidth)}
      draggable={draggable}
      onMouseEnter={(e) => setCursorStyle(e, 'ns-resize')}
      onMouseLeave={(e) => setCursorStyle(e, 'default')}
      onDragStart={onDragStart}
      onDragEnd={(e) => onDragEnd(roundToNearestHour(e.target.y()))}
      dragDistance={VERTICAL_GRID_SPACE / 4}
      dragBoundFunc={(pos) => dragBoundFunc(pos, ref)}
    />
  ),
)

export default ResizeHandle
