/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react'

import { ascend, compose, descend, equals, prop, sortWith } from 'ramda'

import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledItem = styled('li')(() => ({
  '&:hover > button': {
    visibility: 'visible',
  },
}))

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.dataTestId]
 * @param {string} [props.label]
 * @param {Function} [props.onChange]
 * @param {Function} [props.onSave]
 * @param {Function} [props.onOpen]
 * @param {Object[]} [props.options] - Could be string[] as well
 * @param {number|string} [props.options[].id]
 * @param {string} [props.options[].label]
 * @param {string} [props.options[].name]
 * @param {boolean} [props.required]
 * @param {string[]|number[]|Object[]} [props.value]
 * @param {string} [props.variant]
 * @param {boolean} [props.hideLabel]
 * @param {number} [props.chipMaxWidth]
 * @param {boolean} [props.disableSaveOnClear]
 * @param {Object} [props.sx]
 * @param {string|number} [props.sx.minWidth]
 * @param {Function} [props.onlyButtonCallback]
 * @param {boolean} [props.onlyButton]
 * @param {Function} [props.isOptionEqualToValue]
 * @param {string[]} [props.sortByFields]
 * @param {'asc'|'desc'} [props.sortOrder]
 * @param {boolean} [props.dynamicWidth]
 * @param {boolean} [props.disableSort]
 * @param {boolean} [props.disableClearable]
 * @param {string} [props.error]
 */
export default function StaticMultiSelect({
  'data-testid': dataTestId = undefined,
  label = undefined,
  options = [],
  value = null,
  onChange = () => {},
  onSave = () => {},
  onOpen = () => {},
  variant = 'outlined',
  required = false,
  hideLabel = false,
  chipMaxWidth = 138,
  disableSaveOnClear = false,
  onlyButton = false,
  onlyButtonCallback = () => {},
  isOptionEqualToValue = undefined,
  sortByFields = undefined,
  sortOrder = 'asc',
  sx = {},
  dynamicWidth = false,
  disableSort = false,
  disableClearable = false,
  error = '',
  ...rest
}) {
  const [open, setOpen] = useState(false)

  const latestSavedValue = useRef(value)

  const handleChange = (event, newValue) => {
    const isRemovePressed =
      event.type === 'keydown' || ['svg', 'path'].includes(event.target.tagName)
    onChange(newValue)
    if (isRemovePressed && !open && !disableSaveOnClear) {
      onSave(newValue)
      latestSavedValue.current = newValue
    }
  }

  const handleSave = () => {
    if (latestSavedValue.current !== value) {
      onSave(value)
    }
    latestSavedValue.current = value
  }

  const handleEqualityCheck = (opt, val) => equals(opt, val)

  const getOptionLabel = (option) => option.label ?? option.name ?? option

  const sortOrderFn = sortOrder === 'asc' ? ascend : descend
  const sortFn = sortWith(
    sortByFields
      ? sortByFields.map((field) =>
          sortOrderFn(
            compose(
              (x) => (typeof x === 'string' ? x.toLowerCase() : x ?? ''),
              prop(field),
            ),
          ),
        )
      : [
          sortOrderFn(
            compose(
              (x) => (typeof x === 'string' ? x.toLowerCase() : x ?? ''),
              prop(options.at(0)?.label ? 'label' : 'name'),
            ),
          ),
        ],
  )

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disableClearable={disableClearable}
      data-testid={dataTestId}
      open={open}
      options={disableSort ? options : sortFn(options)}
      value={value}
      onChange={handleChange}
      onOpen={() => {
        if (onOpen) onOpen()
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
        handleSave()
      }}
      isOptionEqualToValue={isOptionEqualToValue ?? handleEqualityCheck}
      getOptionLabel={getOptionLabel}
      sx={{
        ...(!dynamicWidth ? { minWidth: '400px', maxWidth: '400px' } : {}),
        '& .MuiOutlinedInput-root': {
          paddingRight: '20px!important',
        },
        ...sx,
      }}
      popupIcon={<ExpandMore />}
      slotProps={{
        popper: {
          style: {
            maxWidth: 'fit-content',
            ...(!dynamicWidth ? { minWidth: sx.minWidth ?? '400px' } : {}),
          },
          placement: 'bottom-start',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={hideLabel ? null : label}
          placeholder={hideLabel ? label : null}
          variant={variant}
          required={required}
          InputLabelProps={{ shrink: hideLabel ? !hideLabel : value?.length >= 1 }}
          error={!!error}
          helperText={error}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <StyledItem
          {...props}
          key={option.id}
          style={{
            paddingLeft: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
          }}
          {...(disableClearable && selected && value?.length === 1
            ? { onClick: null }
            : {})}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            checked={selected}
            disabled={disableClearable && selected && value?.length === 1}
          />
          <Stack direction="column" sx={{ overflow: 'hidden' }}>
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {option.label ?? option.name}
            </span>
            {option.subtitle && (
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                  color: 'grey',
                }}
              >
                {option.subtitle}
              </span>
            )}
          </Stack>
          {onlyButton && (
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                onChange([option])
                onlyButtonCallback()
              }}
              sx={{
                marginLeft: 'auto',
                visibility: 'hidden',
              }}
            >
              only
            </Button>
          )}
        </StyledItem>
      )}
      renderTags={
        rest?.limitTags
          ? (tagValue, getTagProps) => {
              const numTags = tagValue.length
              const { limitTags } = rest

              return (
                <>
                  {sortFn(tagValue)
                    .slice(0, limitTags)
                    .map((option, index) => {
                      const tagProps = getTagProps({ index })
                      return (
                        <Tooltip key={option.id ?? Math.random()} title={option.label}>
                          <Chip
                            {...tagProps}
                            key={tagProps?.key}
                            label={option.label ?? option.name}
                            size="small"
                            style={{ maxWidth: chipMaxWidth }}
                            onDelete={null}
                          />
                        </Tooltip>
                      )
                    })}
                  {numTags > limitTags && ` +${numTags - limitTags}`}
                </>
              )
            }
          : null
      }
      {...rest}
    />
  )
}
