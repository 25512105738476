import { DateTime } from 'luxon'

export default function isDateRangeOverlap(r1, r2) {
  const formattedRange1 = {
    start: DateTime.fromISO(r1.start),
    end: DateTime.fromISO(r1.end),
  }
  const formattedRange2 = {
    start: DateTime.fromISO(r2.start),
    end: DateTime.fromISO(r2.end),
  }

  return (
    formattedRange1.start <= formattedRange2.end &&
    formattedRange2.start <= formattedRange1.end
  )
}
