import { useEffect, useState } from 'react'

import { clamp } from 'ramda'

import { localPoint } from '@visx/event'
import { Group } from '@visx/group'
import { Line } from '@visx/shape'

const defaultStyle = {
  color: '#616161',
  strokeWidth: 2,
  caretSize: 9,
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Object} props.chartRef
 * @param {Object} [props.chartRef.current]
 * @param {Function} [props.chartRef.current.getBoundingClientRect]
 * @param {(Function|string|Object)[]} props.selectorIdState
 * @param {boolean} [props.showCursor]
 * @param {boolean} [props.showCaret]
 * @param {Function} props.scale
 * @param {Object} [props.date]
 * @param {number} props.xMin
 * @param {number} props.xMax
 * @param {number} props.graphHeight
 * @param {number} props.graphWidth
 * @param {number} props.graphTop
 * @param {number} props.graphLeft
 * @param {Function} props.onMouseMove
 * @param {Function} props.onMouseLeave
 * @param {Function} [props.onMouseDown]
 * @param {Function} [props.onMouseUp]
 * @param {Object} [props.style]
 * @param {string} [props.style.color]
 * @param {number} [props.style.strokeWidth]
 * @param {number} [props.style.caretSize]
 */
export default function Cursor({
  id,
  chartRef,
  selectorIdState,
  showCursor = false,
  showCaret = false,
  scale,
  date = null,
  xMin,
  xMax,
  graphHeight,
  graphWidth,
  graphTop,
  graphLeft,
  onMouseMove,
  onMouseLeave,
  onMouseDown = () => {},
  onMouseUp = () => {},
  style = {},
}) {
  const componentStyle = { ...defaultStyle, ...style }

  const [selectorId, setSelectorId] = selectorIdState

  const activeCurrent = selectorId === id
  const activeAny = selectorId !== null

  const handleCursorChange = (event) => {
    event.preventDefault()
    if (!activeCurrent) return

    const containerRect = chartRef?.current?.getBoundingClientRect()

    const localX = event.pageX - containerRect.left
    const xValue = scale.invert(localX)
    const limitedX = clamp(xMin, xMax, xValue)
    const x = typeof limitedX === 'number' ? new Date(limitedX) : limitedX

    onMouseMove(event, x)
  }

  const [pressed, setPressed] = useState(false)

  const handleCursorUp = (event) => {
    if (pressed) {
      onMouseLeave(event)
    }

    setSelectorId(null)
    setPressed(false)
    onMouseUp(event)
  }

  useEffect(() => {
    if (pressed) {
      globalThis.addEventListener('mousemove', handleCursorChange)
      globalThis.addEventListener('mouseup', handleCursorUp)
    } else {
      globalThis.removeEventListener('mousemove', handleCursorChange)
      globalThis.removeEventListener('mouseup', handleCursorUp)
    }

    return () => {
      globalThis.removeEventListener('mousemove', handleCursorChange)
      globalThis.removeEventListener('mouseup', handleCursorUp)
    }
  }, [pressed, date])

  return (
    showCursor && (
      <>
        {date && (
          <Group left={scale(date)} pointerEvents="none">
            <Line
              from={{ x: 0, y: graphTop }}
              to={{ x: 0, y: graphTop + graphHeight }}
              stroke={componentStyle.color}
              strokeWidth={componentStyle.strokeWidth}
            />
            {showCaret && (
              <Group top={graphTop - componentStyle.caretSize}>
                <polygon
                  points={`0,${componentStyle.caretSize} ${
                    componentStyle.caretSize / 1.5
                  },0 -${componentStyle.caretSize / 1.5},0`}
                  fill={componentStyle.color}
                  stroke={componentStyle.color}
                />
              </Group>
            )}
          </Group>
        )}
        <rect
          id={`cursorTarget_${id}`}
          x={graphLeft}
          y={graphTop}
          height={graphHeight}
          width={graphWidth}
          onMouseMove={(event) => {
            event.preventDefault()
            if (pressed && activeAny) return

            const { x } = localPoint(event) || { x: 0 }
            const xValue = scale.invert(x)
            onMouseMove(event, xValue)
          }}
          onMouseLeave={(event) => {
            if (pressed || activeAny) return

            setSelectorId(null)
            onMouseLeave(event)
          }}
          onMouseDown={(event) => {
            event.preventDefault()
            setPressed(true)
            setSelectorId(id)
            onMouseDown(event)
          }}
          onMouseUp={(event) => {
            if (!activeCurrent) return

            setSelectorId(null)
            setPressed(false)
            onMouseUp(event)
          }}
          fill="transparent"
        />
      </>
    )
  )
}
