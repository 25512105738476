import { safeLocalStorage } from 'safe-storage'

import config from './config'
import Project, { REST_PROJECT } from './project'

const AUTH_TOKEN_KEY = `${Project.current}_auth_token`

// do a token refresh if the access token is going to expire in fewer than this many seconds
const REFRESH_BUFFER = 30

const EMPTY_TOKEN = Object.freeze({ access: null, refresh: null })

export const tokenIsExpired = (token) => {
  let parsedToken

  try {
    const decoded = atob(token.split('.')[1])
    parsedToken = JSON.parse(decoded)
  } catch (error) {
    console.error(error)
    return true
  }

  const expiresAt = parsedToken.exp
  if (!expiresAt) return true

  const now = new Date().getTime()
  const expiresIn = (expiresAt * 1000 - now) / 1000 // in seconds
  return expiresIn <= REFRESH_BUFFER
}

const getStorageKey = (project) =>
  project ? [project, ...AUTH_TOKEN_KEY.split('_').slice(1)].join('_') : AUTH_TOKEN_KEY

export const getStoredTokens = (project) => {
  const storageKey = getStorageKey(project)
  const storedTokens = safeLocalStorage.getItem(storageKey)
  let tokens = EMPTY_TOKEN

  if (storedTokens) {
    try {
      tokens = JSON.parse(storedTokens)
    } catch (err) {
      safeLocalStorage.removeItem(AUTH_TOKEN_KEY)
    }
  }

  return tokens
}

export const setTokens = (payload, project) => {
  if (payload) {
    const storageKey = getStorageKey(project)
    safeLocalStorage.setItem(storageKey, JSON.stringify(payload))
  }
  return payload
}

export const clearTokens = () => {
  safeLocalStorage.removeItem(AUTH_TOKEN_KEY)
}

export const refreshTokens = async (project) => {
  const storedTokens = getStoredTokens(project)

  let apiUrl = Project.isRest ? config.API_URL_REST : config.API_URL_PORTAL
  if (project) {
    apiUrl = project === REST_PROJECT ? config.API_URL_REST : config.API_URL_PORTAL
  }

  const response = await fetch(`${apiUrl}/token/refresh/`, {
    body: JSON.stringify({ refresh: storedTokens.refresh }),
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })

  if (response.status !== 200) {
    return EMPTY_TOKEN
  }

  let newTokens

  try {
    newTokens = await response.json()
  } catch (err) {
    return EMPTY_TOKEN
  }

  return setTokens(newTokens)
}

/**
 * retrieve tokens from localstorage
 * check if refresh token is expired, clear tokens (logout) if so
 * check if access token is expired, refresh if so
 * always returns an object containing valid or null tokens, never an expired one
 */
export const getTokens = async (project) => {
  let tokens = getStoredTokens(project)

  const { access, refresh } = tokens

  if (!refresh || tokenIsExpired(refresh)) {
    clearTokens()
    return EMPTY_TOKEN
  }

  if (access && tokenIsExpired(access)) {
    tokens = await refreshTokens(project)
  }

  return tokens
}
