/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { equals } from 'ramda'

import { Circle } from '@visx/shape'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.cx === next.cx &&
    prev.cy === next.cy &&
    prev.r === next.r &&
    prev.fill === next.fill &&
    prev.stroke === next.stroke &&
    prev.strokeWidth === next.strokeWidth &&
    prev.graphWidth &&
    next.graphWidth &&
    equals(prev.padding, next.padding)
  )
}

/**
 * 'graphWidth' and 'padding' not used directly in the component
 * but only in `propsAreEqual` function to properly rerender component
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {number} props.cx
 * @param {number} props.cy
 * @param {number} props.r
 * @param {string} [props.fill]
 * @param {string} [props.stroke]
 * @param {number} [props.strokeWidth]
 * @param {number} [props.graphWidth]
 * @param {Object} [props.padding]
 */
const MemoCircle = memo(
  ({
    id,
    cx,
    cy,
    r,
    fill = undefined,
    stroke = undefined,
    strokeWidth = undefined,
    graphWidth = undefined,
    padding = undefined,
  }) => (
    <Circle
      key={id}
      cx={cx}
      cy={cy}
      r={r}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      pointerEvents="none"
    />
  ),
  propsAreEqual,
)

export default MemoCircle
