import { path } from 'ramda'

import { DateTime } from 'luxon'

import { formatCurrency, isAbortError, toCamelCase } from '@common/utils'
import { getMetricsRequestTimeframe } from '@common/utils/propertyDetailMetricsUtils'

import metrics from '../SmokeMetrics/metrics'

const PROPERTY_DETAILS_DATA_LOADING = 'PROPERTY_DETAILS_DATA_LOADING'
const PROPERTY_DETAILS_DATA_LOADED = 'PROPERTY_DETAILS_DATA_LOADED'
const PROPERTY_DETAILS_DATA_FAILED = 'PROPERTY_DETAILS_DATA_FAILED'

const PROPERTY_DETAILS_METRICS_LOADING = 'PROPERTY_DETAILS_METRICS_LOADING'
const PROPERTY_DETAILS_METRICS_LOADED = 'PROPERTY_DETAILS_METRICS_LOADED'
const PROPERTY_DETAILS_METRICS_FAILED = 'PROPERTY_DETAILS_METRICS_FAILED'

const PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADING =
  'PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADING'
const PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADED =
  'PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADED'
const PROPERTY_DETAILS_DEVICE_CONNECTIVITY_FAILED =
  'PROPERTY_DETAILS_DEVICE_CONNECTIVITY_FAILED'

const PROPERTY_DETAILS_RESET_STATE = 'PROPERTY_DETAILS_RESET_STATE'

const defaultState = {}

const entityName = 'propertyDetails'

async function fetchMetricsSummary({ apiFetch, property, startTime, endTime }) {
  const metricsToFetch = [
    metrics.eventsTotal,
    metrics.eventsCharged,
    metrics.chargeRate,
    metrics.netCharges,
    metrics.netChargesPerEvent,
  ].map((item) => item.apiValue)

  const result = metricsToFetch.reduce(
    (acc, metric) => ({ ...acc, [toCamelCase(metric)]: 'N/A' }),
    {},
  )

  const metricsResponse = await apiFetch(
    `/reports/chart/`,
    {
      properties: [property],
      start: startTime.toFormat('yyyy-MM-dd'),
      end: endTime.toFormat('yyyy-MM-dd'),
      interval: 1,
      intervalType: 'days',
      includeTotals: true,
      metrics: metricsToFetch,
    },
    { method: 'GET', cancelationPrefix: entityName },
  )

  if (Array.isArray(metricsResponse)) {
    metricsResponse.forEach((metricData) => {
      const formattedMetricName = toCamelCase(metricData.metric)
      result[formattedMetricName] = metricData?.total || 'N/A'
    })
  }

  return result
}

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === PROPERTY_DETAILS_RESET_STATE) {
      return defaultState
    }
    if (action.type === PROPERTY_DETAILS_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_METRICS_LOADING) {
      return { ...state, metrics: { ...action.meta, payload: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_METRICS_LOADED) {
      return { ...state, metrics: { ...action.meta, data: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_METRICS_FAILED) {
      return { ...state, metrics: { ...action.meta, error: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADING) {
      return { ...state, connectivity: { ...action.meta, payload: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADED) {
      return { ...state, connectivity: { ...action.meta, data: action.payload } }
    }
    if (action.type === PROPERTY_DETAILS_DEVICE_CONNECTIVITY_FAILED) {
      return { ...state, connectivity: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectPropertyDetailsIsLoading: ({ propertyDetails }) => {
    const status = path(['data', 'status'], propertyDetails)
    return status === 'loading'
  },
  selectPropertyDetailsMetricsIsLoading: ({ propertyDetails }) => {
    const status = path(['metrics', 'status'], propertyDetails)
    return status === 'loading'
  },
  selectPropertyDetailsDeviceConnectivityIsLoading: ({ propertyDetails }) => {
    const status = path(['connectivity', 'status'], propertyDetails)
    return status === 'loading'
  },
  selectPropertyDetails: ({ propertyDetails }) =>
    path(['data', 'data'], propertyDetails),
  selectPropertyDetailsMetrics: ({ propertyDetails }) =>
    path(['metrics', 'data'], propertyDetails),
  selectPropertyDetailsDeviceConnectivity: ({ propertyDetails }) =>
    path(['connectivity', 'data'], propertyDetails),
  selectPropertyDetailsError: ({ propertyDetails }) =>
    path(['data', 'error'], propertyDetails),
  selectPropertyDetailsMetricsError: ({ propertyDetails }) =>
    path(['metrics', 'error'], propertyDetails),
  selectPropertyDetailDeviceConnectivityError: ({ propertyDetails }) =>
    path(['connectivity', 'error'], propertyDetails),
  doResetPropertyDetailsState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: PROPERTY_DETAILS_RESET_STATE }),
  doFetchPropertyMetrics:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: PROPERTY_DETAILS_METRICS_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { property, interval } = payload
        const { startTime, endTime } = getMetricsRequestTimeframe(interval)
        const now = DateTime.now().setZone(property.timezone)

        const apiResults = await Promise.allSettled([
          apiFetch(
            `/properties/health_scores/`,
            {
              properties: property,
              start: now.startOf('month').toFormat('yyyy-MM-dd'),
              end: now.toFormat('yyyy-MM-dd'),
            },
            { cancelationPrefix: entityName },
          ),
          fetchMetricsSummary({ apiFetch, property, startTime, endTime }),
        ])

        const healthScoresPromiseData = apiResults[0]
        const metricsPromiseData = apiResults[1]

        const healthScoresResult =
          healthScoresPromiseData.status === 'fulfilled'
            ? healthScoresPromiseData.value
            : null
        const metricsResult =
          metricsPromiseData.status === 'fulfilled' ? metricsPromiseData.value : null

        const buildHealthScoresData = () => {
          if (!healthScoresResult) return {}

          const prev = healthScoresResult.previous?.[0]
          const current = healthScoresResult.current?.[0]

          return {
            healthScorePrev: {
              score: prev?.healthScore ? `${Math.round(prev.healthScore)}%` : null,
              invoiceAmount: prev?.invoiceAmount
                ? formatCurrency(prev.invoiceAmount)
                : null,
            },
            healthScoreCurrent: {
              score: current?.healthScore
                ? `${Math.round(current.healthScore)}%`
                : null,
              invoiceAmount: current?.invoiceAmount
                ? formatCurrency(current.invoiceAmount)
                : null,
            },
          }
        }

        const finalMetrics = {
          ...(metricsResult ?? {}),
          ...buildHealthScoresData(),
        }

        await dispatch({
          type: PROPERTY_DETAILS_METRICS_LOADED,
          payload: finalMetrics,
          meta: { status: 'succeeded' },
        })
        return finalMetrics
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_DETAILS_METRICS_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchPropertyDetails:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: PROPERTY_DETAILS_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const apiResults = await Promise.allSettled([
          apiFetch(`/properties/${payload}/`, null, { cancelationPrefix: entityName }),
          apiFetch(`/flags/`, null, { cancelationPrefix: entityName }),
        ])

        const propertyPromiseData = apiResults[0]
        const flagsPromiseData = apiResults[1]

        const propertyResult =
          propertyPromiseData.status === 'fulfilled' ? propertyPromiseData.value : null
        const flagsResult =
          flagsPromiseData.status === 'fulfilled' ? flagsPromiseData.value : null

        if (!propertyResult) {
          throw propertyPromiseData.reason ?? new Error('Unexpected error')
        }

        const data = {
          property: propertyResult,
          flags: flagsResult?.results ?? [],
        }

        await dispatch({
          type: PROPERTY_DETAILS_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_DETAILS_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchPropertyDeviceConnectivity:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const connectivityResponse = await apiFetch(
          `/properties/${payload}/get_device_connectivity/`,
          null,
          { cancelationPrefix: entityName },
        )

        await dispatch({
          type: PROPERTY_DETAILS_DEVICE_CONNECTIVITY_LOADED,
          payload: connectivityResponse,
          meta: { status: 'succeeded' },
        })
        return connectivityResponse
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_DETAILS_DEVICE_CONNECTIVITY_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
