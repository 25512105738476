import { createEntityBundle } from '@common/utils'

const entityName = 'threshold_profiles'

const thresholdProfileBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'assign',
      handler: ({ apiFetch, payload }) =>
        apiFetch(`/threshold_profiles/assign/`, payload, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'hierarchy_fetch',
      handler: ({ apiFetch, payload }) =>
        apiFetch(`/threshold_profiles/${payload.id}/assignment_hierarchy/`, payload, {
          method: 'GET',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default thresholdProfileBundle
