import { createListBundle, defaultInitialState } from '@common/utils'
import accUrls from '@portal/pages/Accounts/urls'
import orgUrls from '@portal/pages/Organizations/urls'

const entityName = 'roles'

const roleListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params, store }) => {
    const routeInfo = store.selectRouteInfo()
    const isOrgTab = routeInfo?.pattern.includes('organization')
    const entityType = isOrgTab ? 'organization' : 'account'
    const entityId = store.selectRouteParams().id

    return apiFetch(
      '/roles/',
      { [entityType]: entityId, ...params },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['name', 'asc'],
  },
  urlTest: (_, pattern, hash) =>
    [orgUrls.entity, accUrls.entity].includes(pattern) && hash === 'roles',
})

export default roleListBundle
