import { useCallback, useState } from 'react'

import { FilterList, MoreVertRounded } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.title
 * @param {Function} [props.onFilterPressed]
 * @param {Object[]} [props.menuItems]
 * @param {string} [props.menuItems[].label]
 * @param {Function} [props.menuItems[].onClick]
 * @param {Function} [props.menuItems[].render]
 */
export default function ListPageTitle({
  title,
  onFilterPressed = undefined,
  menuItems = undefined,
  ...props
}) {
  const isSmallScreen = useSmallScreen()

  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleMenuClose = useCallback(() => setMenuAnchor(null), [])

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" {...props}>
      <Typography variant={isSmallScreen ? 'h6' : 'h3'}>{title}</Typography>
      <Box display="flex" flexDirection="row" gap={1}>
        {onFilterPressed && (
          <IconButton color="primary" size="small" onClick={onFilterPressed}>
            <FilterList fontSize="12" />
          </IconButton>
        )}
        {menuItems?.length ? (
          <>
            <IconButton
              color="primary"
              size="small"
              onClick={(event) => setMenuAnchor(event.currentTarget)}
            >
              <MoreVertRounded fontSize="12" />
            </IconButton>

            <Menu
              id="title_menu"
              anchorEl={menuAnchor}
              open={!!menuAnchor}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {menuItems.map((item) => {
                if (item.render) {
                  return item.render({
                    key: item.label,
                    label: item.label,
                    onClick: item.onClick,
                    onClose: handleMenuClose,
                  })
                }
                return (
                  <MenuItem
                    key={item.label}
                    onClick={(e) => {
                      item.onClick(e)
                      handleMenuClose()
                    }}
                  >
                    {item.label}
                  </MenuItem>
                )
              })}
            </Menu>
          </>
        ) : null}
      </Box>
    </Box>
  )
}
