import { Box, Tooltip } from '@mui/material'

import { capitalize } from 'inflection'

import { NoiseAware, SalesForce, Stripe } from '@common/icons'

export const getIntegrationIcon = (integration) => {
  const icons = {
    SalesForce: <SalesForce />,
    Stripe: <Stripe />,
    v1: <NoiseAware />,
  }
  return (
    <Tooltip title={capitalize(integration)} placement="top">
      <Box> {icons[integration]} </Box>
    </Tooltip>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.row
 * @param {boolean} [props.row.v1]
 * @param {boolean} [props.row.stripe]
 * @param {boolean} [props.row.salesForce]
 */
export default function IntegrationCell({ row }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      width="100%"
      height="100%"
    >
      {row?.v1 && getIntegrationIcon('v1')}
      {row?.stripe && getIntegrationIcon('Stripe')}
      {row?.salesForce && getIntegrationIcon('SalesForce')}
    </Box>
  )
}
