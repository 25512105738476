import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  IconButton,
  MenuItem,
  Stack,
  TextField as MUITextField,
  Typography,
} from '@mui/material'

import { Field } from 'formik'
import { capitalize } from 'inflection'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect, StaticSelect } from '@common/components/Selects'
import { formatCron, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

import UsersEmailSelector from './UsersEmailSelector'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.field
 * @param {Object} [props.errors]
 * @param {string} [props.value]
 * @param {Object[]} props.options
 * @param {Function} props.onChange
 * @param {Object} props.fieldStyles
 */
function DefaultOptionSelector({
  field,
  value = '',
  options,
  onChange,
  fieldStyles,
  errors = {},
  ...rest
}) {
  return (
    <MUITextField
      select
      error={!!errors?.[field]}
      helperText={errors?.[field]}
      label={capitalize(field)}
      variant="standard"
      onChange={(e) => onChange(e, field)}
      value={value}
      sx={fieldStyles}
      {...rest}
    >
      <MenuItem value={null}>None</MenuItem>
      {options.map((opt) => (
        <MenuItem key={opt.value} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </MUITextField>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {boolean} [props.isTab]
 * @param {Object[]} props.intervalOptions
 * @param {Object[]} props.lookbackOptions
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} props.instance.name
 * @param {string} [props.instance.interval]
 * @param {string} [props.instance.lookback]
 */
export default function ReportPreferenceForm({
  open,
  onClose,
  instance,
  lookbackOptions,
  intervalOptions,
  isTab,
}) {
  const [formikProps, setFormikProps] = useState()
  const [selectedCronOption, setSelectedCronOption] = useState('')
  const [selectedLookbackOption, setSelectedLookbackOption] = useState('')

  const {
    doScheduledReportPreferenceSave,
    doShowSnackbar,
    systemReportClasses,
    systemTimeZones,
  } = useConnect(
    'doScheduledReportPreferenceSave',
    'doShowSnackbar',
    'selectSystemReportClasses',
    'selectSystemTimeZones',
  )

  const validationSchema = yup.object().shape({
    users: yup.array().of(yup.string().email()).isRequired,
    report: yup.string().isRequired,
    interval: yup.string().isRequired,
    lookback: yup.string().nullable(),
    organization: yup.string(),
    account: yup.string(),
    property: yup.string(),
    propertyGroup: yup.string(),
    timezone: yup.string(),
  })

  const initialValues = {
    id: '',
    users: [],
    organization: '',
    account: '',
    property: '',
    propertyGroup: '',
    report: '',
    interval: '',
    lookback: null,
    timezone: null,
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? initialValues[field]
    })
  }

  const fieldStyles = { flex: 1 }
  const containerStyles = {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '1rem',
    my: 1,
  }

  const getReportClassOptionLabel = (opt) =>
    typeof opt === 'string'
      ? systemReportClasses?.find((report) => report.id === opt)?.label
      : opt.label

  const setSelectedFieldMap = {
    lookback: setSelectedLookbackOption,
    interval: setSelectedCronOption,
  }

  const handleDefaultSelectorChange = (event, field) => {
    const {
      target: { value },
    } = event
    const formikValue = value === 'Custom' ? '' : value

    setSelectedFieldMap[field](value)
    formikProps.setFieldValue(field, formikValue)
  }

  const handleClearField = (field) => {
    setSelectedFieldMap[field](null)
    formikProps.setFieldValue(field, null)
  }

  const handleClose = () => {
    setSelectedCronOption('')
    setSelectedLookbackOption('')
    onClose()
  }

  const getDefaultOption = (field) => {
    const defaultOptions = field === 'lookback' ? lookbackOptions : intervalOptions
    return defaultOptions.find((opt) => opt.value === instance?.[field])
  }

  const cronPreview = () => (
    <Typography fontSize="0.7rem" color="text.secondary">
      {formatCron(formikProps?.values?.interval)}
    </Typography>
  )

  const disableLookback = formikProps?.values?.report === 'SMOKE_DIGEST'

  useEffect(() => {
    if (disableLookback) {
      const initialLookback = instance?.lookback ?? null
      setSelectedLookbackOption(initialLookback ? 'Custom' : '')
      formikProps.setFieldValue('lookback', initialLookback)
    }
  }, [formikProps?.values?.report])

  useEffect(() => {
    const { interval, lookback } = instance ?? {}
    const isDefaultCron = getDefaultOption('interval')
    const isDefaultLookback = getDefaultOption('lookback')

    if (isDefaultCron) setSelectedCronOption(isDefaultCron.value)
    else if (interval) setSelectedCronOption('Custom')
    else setSelectedCronOption('')

    if (isDefaultLookback) setSelectedLookbackOption(isDefaultLookback.value)
    else if (lookback) setSelectedLookbackOption('Custom')
    else setSelectedLookbackOption('')
  }, [instance])

  const handleSave = useCallback(async (values) => {
    try {
      const result = await doScheduledReportPreferenceSave(values)
      if (result.error) {
        throw result.error
      }
      onClose(true)
    } catch (err) {
      const parsedMessage = parseApiErrors(err?.response)
      doShowSnackbar(parsedMessage, 'error')
    }
  }, [])

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Scheduled Report`}
      open={open}
      onSave={handleSave}
      onClose={handleClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Field
        required
        name="users"
        label="Users"
        variant="standard"
        component={UsersEmailSelector}
        disabled={isTab}
        sx={fieldStyles}
      />
      <Box sx={containerStyles}>
        <Field
          required
          name="report"
          label="Report Class"
          variant="standard"
          component={StaticSelect}
          options={systemReportClasses}
          getOptionLabel={getReportClassOptionLabel}
          isOptionEqualToValue={(opt, val) => opt.id === val}
          sx={{ ...fieldStyles, mt: 2 }}
        />
        <Field
          name="timezone"
          label="Timezone"
          variant="standard"
          component={StaticSelect}
          options={systemTimeZones}
          sx={{ ...fieldStyles, mt: 2 }}
        />
      </Box>
      <Box sx={containerStyles}>
        <Stack sx={fieldStyles}>
          {selectedCronOption === 'Custom' ? (
            <Box display="flex" alignItems="flex-end" mr={2}>
              <Box display="flex" flexDirection="column" flex={1}>
                <Field
                  required
                  component={TextField}
                  sx={fieldStyles}
                  label="Interval"
                  name="interval"
                  placeholder="e.g. '0 0 * * *'"
                />
                {cronPreview()}
              </Box>
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleClearField('interval')}
              >
                <ClearIcon />
              </IconButton>
            </Box>
          ) : (
            <>
              <DefaultOptionSelector
                required
                field="interval"
                value={selectedCronOption}
                options={intervalOptions}
                onChange={handleDefaultSelectorChange}
                fieldStyles={fieldStyles}
                errors={formikProps?.errors}
              />
              {cronPreview()}
            </>
          )}
        </Stack>
        <Stack sx={fieldStyles}>
          {selectedLookbackOption === 'Custom' ? (
            <Box display="flex" alignItems="flex-end" mr={2}>
              <Field
                disabled={disableLookback}
                component={TextField}
                name="lookback"
                label="Lookback"
                placeholder="00:00:00"
                sx={fieldStyles}
              />
              {!disableLookback && (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleClearField('lookback')}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          ) : (
            <>
              <DefaultOptionSelector
                disabled={disableLookback}
                field="lookback"
                value={selectedLookbackOption}
                options={lookbackOptions}
                onChange={handleDefaultSelectorChange}
                fieldStyles={fieldStyles}
                errors={formikProps?.errors}
              />
              <Typography fontSize="0.7rem" color="text.secondary">
                {formikProps?.values?.lookback}
              </Typography>
            </>
          )}
        </Stack>
      </Box>
      <Typography variant="h6" mt="1rem" color="secondary">
        Choose one of the following (optional):
      </Typography>
      <Box sx={containerStyles}>
        <Field
          name="organization"
          label="Organization"
          endpoint="organizations"
          variant="standard"
          component={FormikDynamicSelect}
          sx={fieldStyles}
        />
        <Field
          name="account"
          label="Account"
          endpoint="accounts"
          variant="standard"
          component={FormikDynamicSelect}
          secondaryTextAttr="organizationName"
          sx={fieldStyles}
        />
      </Box>
      <Box sx={containerStyles}>
        <Field
          name="propertyGroup"
          label="Property Group"
          endpoint="property_groups"
          variant="standard"
          component={FormikDynamicSelect}
          secondaryTextAttr="accountName"
          sx={fieldStyles}
        />
        <Field
          name="property"
          label="Property"
          endpoint="properties"
          variant="standard"
          component={FormikDynamicSelect}
          secondaryTextAttr="accountName"
          sx={fieldStyles}
        />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
