import cronstrue from 'cronstrue'
import { DateTime, Interval } from 'luxon'

export function formatDuration(start, end) {
  const startDateTime = typeof start === 'string' ? DateTime.fromISO(start) : start
  const endDateTime = typeof start === 'string' ? DateTime.fromISO(end) : end

  if (!startDateTime.isValid || !endDateTime.isValid) return ''
  if (startDateTime > endDateTime) return ''

  return Interval.fromDateTimes(startDateTime, endDateTime)
    .toDuration(['weeks', 'days', 'hours', 'minutes'])
    .toHuman({ unitDisplay: 'short' })
    .split(',')
    .filter((time) => parseInt(time.trim().charAt(0), 10) > 0)
    .map((selectedTime) => {
      const arr = selectedTime.trim().split(' ')
      arr[0] = Math.round(arr[0])
      return `${arr[0]} ${arr[1]}`
    })
    .join(' ')
}

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function formatCurrency(value, defaultValue = 'N/A') {
  return Number.isNaN(Number(value)) || value === null
    ? defaultValue
    : usdFormatter.format(value)
}

export function formatChargeAmount(netChargeAmount, chargeAmount) {
  const [netCharge, charge] = [Number(netChargeAmount), Number(chargeAmount)]
  const value = formatCurrency(netCharge, '-')
  const asterisk = !Number.isNaN(netCharge) && netCharge !== charge ? '*' : ''
  return `${value}${asterisk}`
}

export function formatCron(cron) {
  try {
    return cronstrue.toString(cron)
  } catch (e) {
    return ''
  }
}

export const formatScenarioName = (propertyName, unitName, createdOn) => {
  const dateTime = DateTime.isDateTime(createdOn)
    ? createdOn
    : DateTime.fromISO(createdOn)
  const part1 = `${propertyName}] ${unitName}`
  const part2 = dateTime.isValid
    ? dateTime.toLocaleString(DateTime.DATETIME_SHORT)
    : null
  return `[${part2 ? [part1, part2].join(' - ') : part1}]`
}

export function formatAsMacAddress(input) {
  const cleaned = input.replace(/[^a-zA-Z0-9]/g, '')
  if (cleaned.length !== 12) return null
  return cleaned.match(/.{1,2}/g).join(':')
}

function formatDayWithOrdinal(day) {
  const suffix =
    day > 0
      ? ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10]
      : ''
  return `${day}${suffix}`
}

/**
 * Formats a date range with ordinal suffixes for the day numbers and includes the year only when necessary.
 *
 * @param {Object[]} range - An array containing two Luxon DateTime objects: the start and end of the range.
 * @param {string} [separator] - The separator string to place between the formatted start and end dates.
 * @returns {string} - A formatted string representing the date range, including ordinal suffixes for day numbers.
 *
 * @example
 * const range = [DateTime.fromISO('2024-12-01'), DateTime.fromISO('2024-12-03')];
 * const formatted = formatDateRangeOrdinal(range);
 * console.log(formatted);
 * // Output: "Dec. 1st to Dec. 3rd"
 *
 * const rangeDifferentYear = [
 *   DateTime.fromISO('2024-12-31'),
 *   DateTime.fromISO('2025-01-01')
 * ];
 * const formattedWithYear = formatDateRangeOrdinal(rangeDifferentYear);
 * console.log(formattedWithYear);
 * // Output: "Dec. 31st, 2024 to Jan. 1st, 2025"
 */
export function formatDateRangeOrdinal(range, separator = 'to') {
  const [start, end] = range

  const isDifferentYear = start.year !== end.year
  const startDayOrdinal = formatDayWithOrdinal(start.day)
  const endDayOrdinal = formatDayWithOrdinal(end.day)
  const formattedStart = isDifferentYear
    ? `${start.toFormat('MMM.')} ${startDayOrdinal}, ${start.toFormat('yyyy')}`
    : `${start.toFormat('MMM.')} ${startDayOrdinal}`
  const formattedEnd = isDifferentYear
    ? `${end.toFormat('MMM.')} ${endDayOrdinal}, ${end.toFormat('yyyy')}`
    : `${end.toFormat('MMM.')} ${endDayOrdinal}`

  return `${formattedStart} ${separator} ${formattedEnd}`
}
