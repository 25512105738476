import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  styled,
  TextField,
  Typography,
} from '@mui/material'

import { DateTime } from 'luxon'

import { Chart } from '@common/components/chart'

const StyledSlider = styled(Slider)({
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: 'translateX(0%)',
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: 'translateX(-100%)',
  },
})

const STRATEGY_ALL = 'all'
const STRATEGY_ONLY = 'only'

const dataTypes = [
  {
    label: '< 1µm',
    color: '#7F66B3',
    category: 'smoke - mass concentration',
    visible: true,
    hasMinMax: true,
    unit: 'μg/m³',
    template: '{{ value }} μg/m³',
    name: 'mc_1p0',
  },
]

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Function} props.onApply
 * @param {Object[]} props.readings
 * @param {string} [props.readings[].time]
 * @param {number} [props.readings[].mc_1p0_mean]
 */
export default function GenerateLabelsModal({
  open,
  onClose,
  onApply,
  readings = undefined,
}) {
  const dataTypeKey = 'mc_1p0_mean'

  const [thresholdRange, setThresholdRange] = useState(null)

  const [currentThreshold, setCurrentThreshold] = useState(0)
  const [currentBuffer, setCurrentBuffer] = useState(1)
  const [currentStrategy, setCurrentStrategy] = useState('only')
  const [generatedLabels, setGeneratedLabels] = useState(null)

  const roundTwoDigits = useCallback((value) => Math.round(value * 100) / 100, [])

  useEffect(() => {
    if (readings && !thresholdRange) {
      const dataTypeValues = readings.map((reading) => reading[dataTypeKey])
      setThresholdRange([
        roundTwoDigits(Math.min(...dataTypeValues)),
        roundTwoDigits(Math.max(...dataTypeValues)),
      ])
    }
    if (!readings) {
      setGeneratedLabels(null)
    }
  }, [readings])

  useEffect(() => {
    if (thresholdRange && (thresholdRange[0] !== 0 || thresholdRange[1] !== 0)) {
      const average = roundTwoDigits(
        thresholdRange[0] + (thresholdRange[1] - thresholdRange[0]) / 2,
      )
      setCurrentThreshold(average)
    }
  }, [thresholdRange])

  const handleBufferChange = useCallback((event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault()
      return
    }
    setCurrentBuffer(Number(event.target.value))
  }, [])

  const generateLabels = () => {
    const labels = []

    if (currentStrategy === STRATEGY_ALL) {
      const firstMatchIndex = readings.findIndex(
        (reading) => reading[dataTypeKey] >= currentThreshold,
      )
      if (firstMatchIndex !== -1) {
        labels.push({
          start: readings[Math.max(0, firstMatchIndex - currentBuffer)].time,
          end: readings.at(-1).time,
        })
      }
    } else if (currentStrategy === STRATEGY_ONLY) {
      let start

      readings.forEach((reading, index) => {
        if (reading[dataTypeKey] >= currentThreshold) {
          if (!start) start = readings[Math.max(0, index - currentBuffer)].time
        } else if (start) {
          labels.push({
            start,
            end: readings[Math.min(readings.length - 1, index + currentBuffer)].time,
          })
          start = null
        }
      })

      if (start) {
        labels.push({ start, end: readings.at(-1).time })
        start = null
      }
    } else {
      throw Error(`Unsupported strategy: ${currentStrategy}`)
    }

    setGeneratedLabels(labels)
  }

  const processedReadings = useMemo(
    () =>
      readings?.map((reading) => ({
        mc_1p0_mean: reading[dataTypeKey],
        time: DateTime.fromISO(reading.time),
      })),
    [readings],
  )

  const graphLabels = useMemo(
    () =>
      generatedLabels?.map((label) => ({
        ...label,
        id: Math.random(),
        start: DateTime.fromISO(label.start),
        end: DateTime.fromISO(label.end),
        style: {
          handle: { opacity: 0.3 },
          pattern: { opacity: 0.3 },
        },
      })),
    [generatedLabels],
  )

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <Box>
        <DialogContent>
          <Typography component="span">
            <div style={{ display: 'inline', fontWeight: 'bold' }}>Datatype:</div>{' '}
            {dataTypeKey}
          </Typography>
          {thresholdRange && (
            <>
              <Typography fontWeight="bold" mt={1}>
                Label threshold:
              </Typography>
              <Box sx={{ mt: 4, mb: 2 }}>
                <StyledSlider
                  size="small"
                  min={thresholdRange[0]}
                  max={thresholdRange[1]}
                  value={currentThreshold}
                  onChange={(event) => setCurrentThreshold(event.target.value)}
                  step={0.01}
                  valueLabelDisplay="on"
                  marks={thresholdRange.map((range) => ({
                    value: range,
                    label: range,
                  }))}
                />
              </Box>
            </>
          )}
          <Typography fontWeight="bold" mb={1}>
            Buffer size:
          </Typography>
          <TextField
            fullWidth
            type="number"
            value={currentBuffer}
            onChange={handleBufferChange}
            sx={{ mb: 2 }}
          />
          <Typography fontWeight="bold" mb={1}>
            Labeling Strategy:
          </Typography>
          <FormControl>
            <RadioGroup
              value={currentStrategy}
              onChange={(event) => setCurrentStrategy(event.target.value)}
            >
              <FormControlLabel
                value={STRATEGY_ONLY}
                control={<Radio />}
                label="Only values > threshold"
              />
              <FormControlLabel
                value={STRATEGY_ALL}
                control={<Radio />}
                label="All timestamps after the first value > threshold"
              />
            </RadioGroup>
          </FormControl>
          {processedReadings && (
            <>
              <Typography fontWeight="bold" mb={1}>
                Result:
              </Typography>
              <Chart
                id={dataTypes.category}
                data={processedReadings}
                dataTypes={dataTypes}
                highlightedAreas={graphLabels}
                settings={{ showDateTooltip: false, showCursor: false }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={generateLabels}>Generate</Button>
          <Button disabled={!generatedLabels} onClick={() => onApply(generatedLabels)}>
            Apply
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
