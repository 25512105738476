import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import logo from '@assets/logo_dark.svg'
import { Loading } from '@common/components'
import { clearTokens } from '@common/config'
import cache from '@common/utils/cache'
import loginUrls from '@rest/pages/Login/urls'

import { loginBoxStyles, loginLogoStyles } from './login.styles'

export default function EphemeralLogin() {
  const {
    doResetMe,
    doAuthLogin,
    doUpdateUrl,
    routeParams: { token },
    queryObject: { next = '/' },
    meRaw: { data: me, currentAccount, currentOrganization },
  } = useConnect(
    'doResetMe',
    'doAuthLogin',
    'doUpdateUrl',
    'selectRouteParams',
    'selectQueryObject',
    'selectMeRaw',
  )

  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    async function login() {
      try {
        clearTokens()
        await cache.clear()
        const result = await doAuthLogin({ token }, '/ephemeral/')
        if (result.error) {
          doUpdateUrl(loginUrls.login)
        } else {
          await doResetMe()
          setFetched(true)
        }
      } catch {
        doUpdateUrl(loginUrls.login)
      }
    }
    login()
  }, [])

  useEffect(() => {
    if (me?.id && fetched) {
      doUpdateUrl(next)
    }
  }, [me, currentAccount, currentOrganization, fetched])

  return (
    <Box style={loginBoxStyles}>
      <img alt="Rest" src={logo} style={loginLogoStyles} />

      <Box sx={{ mt: 5, width: 500 }}>
        <Loading height={1} />
      </Box>
    </Box>
  )
}
