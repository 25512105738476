import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import Box from '@mui/material/Box'

import { Loading } from '@common/components'

import Filters from './Filters'
import Pagination from './Pagination'
import PhotoDialog from './PhotoDialog'
import PhotoGrid from './PhotoGrid'

export default function Installations() {
  const {
    isAtLeastAdmin,
    installationList: results,
    installationListIsLoading: isLoading,
    installationListRaw: { search },
    doInstallationListSetPage,
    doInstallationListSetFilter,
    doInstallationListSetSearch,
    doInstallationListSetOrdering,
    doFetchInstallationList,
    doInstallationListVerify,
    doInstallationListRemove,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectInstallationList',
    'selectInstallationListIsLoading',
    'selectInstallationListRaw',
    'doInstallationListSetPage',
    'doInstallationListSetFilter',
    'doInstallationListSetSearch',
    'doInstallationListSetOrdering',
    'doFetchInstallationList',
    'doInstallationListVerify',
    'doInstallationListRemove',
  )

  const [initialLoading, setInitialLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(null)
  const [photoType, setPhotoType] = useState('ALL')
  const [ordering, setOrdering] = useState('unit__name')
  const [reviewed, setReviewed] = useState(true)

  useEffect(() => {
    doInstallationListSetFilter({
      reviewed,
      category: photoType === 'ALL' ? '' : photoType,
    })
  }, [photoType, reviewed])

  useEffect(() => {
    doInstallationListSetOrdering(ordering)
  }, [ordering])

  useEffect(() => {
    doInstallationListSetSearch('')
    async function fetchData() {
      await doFetchInstallationList()

      setInitialLoading(false)
    }
    fetchData()
  }, [])

  const openImage = useCallback(
    (install) => () => {
      setCurrentImage(install)
    },
    [results],
  )

  if (initialLoading) {
    return <Loading />
  }

  return (
    <Box m={5} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Filters
        ordering={ordering}
        setOrdering={setOrdering}
        search={search}
        doInstallationListSetSearch={doInstallationListSetSearch}
        photoType={photoType}
        setPhotoType={setPhotoType}
        reviewed={reviewed}
        setReviewed={setReviewed}
      />

      <Pagination
        results={results}
        doInstallationListSetPage={doInstallationListSetPage}
      />

      {currentImage ? (
        <PhotoDialog
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
          isAtLeastAdmin={isAtLeastAdmin}
          doInstallationListVerify={doInstallationListVerify}
          doInstallationListRemove={doInstallationListRemove}
        />
      ) : null}

      {isLoading ? <Loading /> : <PhotoGrid results={results} openImage={openImage} />}

      {results?.numPages > 1 && (
        <Pagination
          results={results}
          doInstallationListSetPage={doInstallationListSetPage}
        />
      )}
    </Box>
  )
}
