import React, { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material'
import { Box, Divider, List, ListItem, Paper, Tab, Tabs, Tooltip } from '@mui/material'

import { ControlPosition, MapControl } from '@vis.gl/react-google-maps'

import urls from '@portal/pages/Properties/urls'
import DetailItem from '@portal/UI/components/DetailItem'

/**
 * A component that handles control logic for outage and non-location properties.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.outageProps
 * @param {string} props.outageProps[].id
 * @param {string} props.outageProps[].name
 * @param {string} props.outageProps[].outageCategory
 * @param {Object[]} props.nonLocationProps
 * @param {string} props.nonLocationProps[].id
 * @param {string} props.nonLocationProps[].name
 * @param {string} props.nonLocationProps[].unknownReason
 * @param {Function} props.setSelectedProperty
 */
export default function Control({
  nonLocationProps,
  outageProps,
  setSelectedProperty,
}) {
  const [selectedTab, setSelectedTab] = useState(0)

  const linkStyles = {
    color: 'primary.main',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  }

  const { doUpdateUrl } = useConnect('doUpdateUrl')
  const handleTabChange = (_, tab) => setSelectedTab(tab)
  const hasCurrentOutage = (prop) => Boolean(prop.outageStart && !prop.outageEnd)
  const applyLeftMargin = (prop) => (hasCurrentOutage(prop) ? {} : { marginLeft: 5 })

  return (
    <MapControl position={ControlPosition.TOP_RIGHT}>
      <Paper
        elevation={3}
        sx={{ padding: 1, width: '300px', height: '500px', mr: 2, mt: 2 }}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Property Outages" />
          <Tab label="Unknown Locations" />
        </Tabs>
        <Box sx={{ overflowY: 'auto', height: '90%' }}>
          {selectedTab === 0 && (
            <List>
              {outageProps.map((prop, i) => (
                <React.Fragment key={prop.id}>
                  <ListItem
                    key={prop.id}
                    sx={{ gap: 2, p: 1 }}
                    onClick={() => setSelectedProperty(prop)}
                  >
                    <Tooltip placement="top" title={prop.outageCategory}>
                      <ErrorOutlineIcon color="error" />
                    </Tooltip>
                    <DetailItem
                      titleSx={{ fontSize: 14 }}
                      sx={{ ...linkStyles, fontSize: 16 }}
                      value={prop.name}
                      label={prop.organizationName}
                    />
                  </ListItem>
                  {i !== outageProps.length - 1 && (
                    <Divider sx={{ width: '90%', ml: 'auto' }} component="li" />
                  )}
                </React.Fragment>
              ))}
            </List>
          )}
          {selectedTab === 1 && (
            <List>
              {nonLocationProps.map((prop, i) => (
                <React.Fragment key={prop.id}>
                  <ListItem
                    key={prop.id}
                    sx={{ gap: 2, p: 1 }}
                    onClick={() => doUpdateUrl(urls.entity.replace(':id', prop.id))}
                  >
                    {hasCurrentOutage(prop) && (
                      <Tooltip placement="top" title={prop.unknownReason}>
                        <ErrorOutlineIcon color="error" />
                      </Tooltip>
                    )}
                    <DetailItem
                      titleSx={{ fontSize: 14, ...applyLeftMargin(prop) }}
                      sx={{ ...linkStyles, fontSize: 16, ...applyLeftMargin(prop) }}
                      value={prop.name}
                      label={prop.organizationName}
                    />
                  </ListItem>
                  {i !== nonLocationProps.length - 1 && (
                    <Divider sx={{ width: '90%', ml: 'auto' }} component="li" />
                  )}
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      </Paper>
    </MapControl>
  )
}
