import { path } from 'ramda'

import { DateTime } from 'luxon'

import { isAbortError, isDateRangeOverlap } from '@common/utils'

const DEVICE_CHART_DATA_LOADING = 'DEVICE_CHART_DATA_LOADING'
const DEVICE_CHART_DATA_SUCCESS = 'DEVICE_CHART_DATA_SUCCESS'
const DEVICE_CHART_DATA_FAILED = 'DEVICE_CHART_DATA_FAILED'

const DEVICE_CHART_RESET_STATE = 'DEVICE_CHART_RESET_STATE'

const defaultState = {}

const entityName = 'deviceChart'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === DEVICE_CHART_RESET_STATE) {
      return defaultState
    }
    if (action.type === DEVICE_CHART_DATA_LOADING) {
      return { ...state, chart: { ...action.meta, payload: action.payload } }
    }
    if (action.type === DEVICE_CHART_DATA_SUCCESS) {
      return { ...state, chart: { ...action.meta, data: action.payload } }
    }
    if (action.type === DEVICE_CHART_DATA_FAILED) {
      return { ...state, chart: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectDeviceChartDataIsLoading: ({ deviceChart }) => {
    const status = path(['chart', 'status'], deviceChart)
    return status === 'loading'
  },
  selectDeviceChartData: ({ deviceChart }) => path(['chart', 'data'], deviceChart),
  selectDeviceChartDataError: ({ deviceChart }) =>
    path(['chart', 'error'], deviceChart),
  doResetDeviceChartState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: DEVICE_CHART_RESET_STATE }),
  doFetchDeviceChartData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: DEVICE_CHART_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { device, start, end, dataTypes } = payload
        const { id: deviceId, unitId } = device

        const unitResponse = unitId
          ? await apiFetch(`/units/${unitId}/`, null, {
              cancelationPrefix: entityName,
            })
          : null

        const eventsResponse = await apiFetch(
          `/events/`,
          {
            device: deviceId,
            createdOnAfter: DateTime.fromISO(start).minus({ days: 1 }),
            createdOnBefore: DateTime.fromISO(end).plus({ days: 1 }),
            includeChildEvents: true,
            includeDeletedEvents: true,
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedEvents = eventsResponse?.results?.map((event) => ({
          ...event,
          createdOn: DateTime.fromISO(event.createdOn),
          endTime: event.endTime ? DateTime.fromISO(event.endTime) : null,
        }))

        const chartResponse = await apiFetch(
          `/devices/${deviceId}/chart/`,
          {
            resolution: 1000,
            start,
            end,
            dataTypes,
          },
          { cancelationPrefix: entityName },
        )
        const processedChart = chartResponse?.map((chartData) => ({
          ...chartData,
          time: DateTime.fromISO(chartData.time),
        }))

        const alarmsResponse = await apiFetch(
          `/alarms/`,
          {
            deviceId,
            startAfter: DateTime.fromISO(start).minus({ days: 1 }),
            startBefore: DateTime.fromISO(end).plus({ days: 1 }),
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedAlarms = alarmsResponse?.results?.map((alarm) => {
          const relatedEvent = processedEvents?.find(
            (event) => event.metadata?.smoke?.alarmId === alarm.id,
          )
          return {
            ...alarm,
            start: DateTime.fromISO(alarm.start),
            end: alarm.end ? DateTime.fromISO(alarm.end) : null,
            relatedEvent,
          }
        })

        const reservationsResponse = await apiFetch(
          `/reservations/`,
          {
            unit: unitId,
            pageSize: 999,
          },
          { cancelationPrefix: entityName },
        )
        const processedReservations = reservationsResponse?.results
          ?.map((reservation) => ({
            ...reservation,
            checkIn: DateTime.fromISO(reservation.checkIn),
            checkOut: DateTime.fromISO(reservation.checkOut),
          }))
          .filter((reservation) =>
            isDateRangeOverlap(
              { start: reservation.checkIn, end: reservation.checkOut },
              { start, end },
            ),
          )

        const scenariosResponse = await apiFetch(
          `/scenarios/`,
          {
            sourceDevice: deviceId,
            pageSize: 9999,
          },
          { cancelationPrefix: entityName },
        )
        const processedScenarios = scenariosResponse?.results?.filter((scenario) =>
          isDateRangeOverlap(
            { start: scenario.readingStart, end: scenario.readingEnd },
            { start, end },
          ),
        )

        const finalResult = {
          unit: unitResponse ?? {},
          chart: processedChart ?? [],
          alarms: processedAlarms ?? [],
          events: processedEvents ?? [],
          reservations: processedReservations ?? [],
          scenarios: processedScenarios ?? [],
        }

        dispatch({
          type: DEVICE_CHART_DATA_SUCCESS,
          payload: finalResult,
          meta: { status: 'succeeded' },
        })
        return finalResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: DEVICE_CHART_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
