/* eslint-disable import/prefer-default-export */
import { pathOr } from 'ramda'

export const formatCSVData = (data, headers) => {
  const processRow = (row) => {
    const values = []
    row.forEach((value) => {
      let result = value === null ? '' : value.toString()
      result = result.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) {
        result = `"${result}"`
      }
      values.push(result)
    })
    return `${values.join(',')}\n`
  }

  let csvFile = processRow(
    Object.values(typeof headers === 'function' ? headers(data[0] ?? {}) : headers),
  )
  data.forEach((row) => {
    const headerFields = Object.keys(
      typeof headers === 'function' ? headers(row) : headers,
    )
    const dataToExport = {}
    headerFields.forEach((header) => {
      const headerData = pathOr(null, header.split('.'), row)
      dataToExport[header] = headerData
    })

    csvFile += processRow(Object.values(dataToExport))
  })
  return csvFile
}
