/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { equals } from 'ramda'

import HighlightedArea from '../components/HighlightedArea'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    prev.width === next.width &&
    prev.height === next.height &&
    prev.offsetLeft === next.offsetLeft &&
    prev.offsetTop === next.offsetTop &&
    prev.minWidth === next.minWidth &&
    prev.x0 === next.x0 &&
    prev.x1 === next.x1 &&
    prev.graphWidth === next.graphWidth &&
    equals(prev.tooltipData, next.tooltipData) &&
    equals(prev.style, next.style) &&
    equals(prev.padding, next.padding)
  )
}

/**
 * 'graphWidth' and 'padding' not used directly in the component
 * but only in `propsAreEqual` function to properly rerender component
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string|number} props.id
 * @param {number} props.height
 * @param {number} props.width
 * @param {number} props.x0
 * @param {number} props.x1
 * @param {number} [props.offsetLeft]
 * @param {number} [props.offsetTop]
 * @param {number} [props.minWidth]
 * @param {Object} [props.tooltipData]
 * @param {Object} [props.style]
 * @param {boolean} [props.style.dashedBackground]
 * @param {Object} [props.style.handle]
 * @param {number} [props.style.handle.width]
 * @param {number[]} [props.style.handle.dashArray]
 * @param {string} [props.style.handle.color]
 * @param {number} [props.style.handle.opacity]
 * @param {Object} [props.style.area]
 * @param {string} [props.style.area.color]
 * @param {number} [props.style.area.opacity]
 * @param {number} [props.graphWidth]
 * @param {Object} [props.padding]
 */
const MemoHighlightedArea = memo(
  ({
    id,
    width,
    height,
    offsetLeft = 0,
    offsetTop = 0,
    minWidth = undefined,
    x0,
    x1,
    tooltipData = undefined,
    style = null,
    graphWidth = undefined,
    padding = undefined,
  }) => (
    <HighlightedArea
      key={id}
      id={id}
      width={width}
      height={height}
      offsetLeft={offsetLeft}
      offsetTop={offsetTop}
      minWidth={minWidth}
      x0={x0}
      x1={x1}
      tooltipData={tooltipData}
      style={style}
    />
  ),
  propsAreEqual,
)

export default MemoHighlightedArea
