import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'

import { isAbortError, parseApiErrors, useSmallScreen } from '@common/utils'
import {
  getMetricFormattedName,
  getMetricPosition,
  METRICS_30_DAYS_INTERVAL,
  METRICS_MTD_INTERVAL,
  METRICS_PREV_MONTH_INTERVAL,
} from '@common/utils/propertyDetailMetricsUtils'

const intervalOptions = [
  METRICS_30_DAYS_INTERVAL,
  METRICS_MTD_INTERVAL,
  METRICS_PREV_MONTH_INTERVAL,
]

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string} [props.property.salesforceAccountId]
 */
export default function MetricsBlock({ property }) {
  const isSmallScreen = useSmallScreen()

  const {
    propertyDetailsMetrics,
    propertyDetailsMetricsIsLoading,
    doFetchPropertyMetrics,
    doShowSnackbar,
  } = useConnect(
    'selectPropertyDetailsMetrics',
    'selectPropertyDetailsMetricsIsLoading',
    'doFetchPropertyMetrics',
    'doShowSnackbar',
  )

  const [interval, setInterval] = useState(METRICS_30_DAYS_INTERVAL)
  const [menuAnchor, setMenuAnchor] = useState(null)

  const fetchMetricsData = useCallback(async () => {
    try {
      await doFetchPropertyMetrics({ property: property.id, interval })
    } catch (err) {
      if (!isAbortError(err)) {
        const parsedError = parseApiErrors(err)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [property, interval])

  useEffect(() => {
    if (property) fetchMetricsData()
  }, [property, interval])

  const isBillingConfigured = useMemo(() => property?.salesforceAccountId, [property])

  const processedMetrics = useMemo(
    () =>
      Object.entries(propertyDetailsMetrics ?? {})
        .reduce(
          (acc, [key, value]) => [
            ...acc,
            {
              metric: key,
              value,
              position: getMetricPosition(key),
              name: getMetricFormattedName(key),
            },
          ],
          [],
        )
        .sort((a, b) => a.position - b.position),
    [propertyDetailsMetrics],
  )

  const getValueColor = useCallback((metric, value) => {
    const defaultColor = 'black'
    const numValue =
      typeof value === 'string' ? Number(value.replace(/[^-0-9]/g, '')) : value

    if (typeof numValue !== 'number') return defaultColor

    if (metric.startsWith('healthScore')) {
      if (numValue > 50) {
        return 'success.main'
      }
      if (numValue >= 25 && numValue <= 50) {
        return 'warning.main'
      }
      return 'error.main'
    }

    return defaultColor
  }, [])

  const SelectorArrow = menuAnchor ? ArrowDropUpRounded : ArrowDropDownRounded

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
      >
        {intervalOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              setInterval(option)
              setMenuAnchor(null)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      <Stack
        direction={isSmallScreen ? 'column' : 'row'}
        spacing={isSmallScreen ? 0 : 3}
        alignItems={isSmallScreen ? 'center' : 'start'}
        my={1}
      >
        <Stack sx={{ mt: 2, width: 'fit-content' }}>
          <Box
            display="flex"
            alignItems="end"
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              variant="caption"
              color="grey.500"
              fontWeight={600}
              fontSize={isSmallScreen ? 10 : 12}
            >
              {interval}
            </Typography>
            <SelectorArrow sx={{ color: 'grey.500' }} />
          </Box>
          <Divider />
          {propertyDetailsMetricsIsLoading ? (
            <CircularProgress size={20} sx={{ mt: 2, mb: 1 }} />
          ) : (
            <Box display="flex" gap={3}>
              {processedMetrics
                .filter((data) => !data.metric.startsWith('healthScore'))
                .map(({ metric, name, value }) => (
                  <Stack
                    key={`${name}_${value}`}
                    direction="column"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="caption"
                      color="grey.500"
                      fontWeight={600}
                      fontSize={isSmallScreen ? 10 : 12}
                    >
                      {name}
                    </Typography>
                    <Typography
                      variant="body1"
                      color={getValueColor(metric, value)}
                      whiteSpace="pre-line"
                      fontSize={isSmallScreen ? 14 : 16}
                    >
                      {value ?? '--'}
                    </Typography>
                  </Stack>
                ))}
            </Box>
          )}
        </Stack>
        {isBillingConfigured && !propertyDetailsMetricsIsLoading && (
          <Stack sx={{ mt: 2, pt: 0.4 }}>
            <Box display="flex" gap={3}>
              {processedMetrics
                .filter((data) => data.metric.startsWith('healthScore'))
                .map(({ metric, name, value }) => (
                  <Stack sx={{ width: 'fit-content' }}>
                    <Typography
                      variant="caption"
                      color="grey.500"
                      fontWeight={600}
                      fontSize={isSmallScreen ? 10 : 12}
                    >
                      {name}
                    </Typography>
                    <Divider />
                    <Stack key={`${name}_${value}`} direction="column">
                      <Box height="19.9px" />
                      <Box display="flex" gap={0.5}>
                        <Typography
                          variant="body1"
                          color={getValueColor(metric, value.score)}
                          whiteSpace="pre-line"
                          fontSize={isSmallScreen ? 14 : 16}
                        >
                          {value.score ?? '--'}
                        </Typography>
                        {value.invoiceAmount && (
                          <Typography
                            variant="body1"
                            color="black"
                            whiteSpace="pre-line"
                            fontSize={isSmallScreen ? 14 : 16}
                          >
                            ({value.invoiceAmount})
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                ))}
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  )
}
