import { useConnect } from 'redux-bundler-hook'

import { Box, Divider, Grid2, Typography } from '@mui/material'

import { TabComponent } from '@common/components'
import { useSmallScreen } from '@common/utils'
import accountUrls from '@portal/pages/Accounts/urls'
import DevicesList from '@portal/pages/Devices/Devices'
import orgUrls from '@portal/pages/Organizations/urls'
import propertyUrls from '@portal/pages/Properties/urls'
import DetailItem from '@portal/UI/components/DetailItem'

import ZonesTab from './Zones'

export default function UnitTab() {
  const { unitFetch: unit } = useConnect('selectUnitFetch')
  const isSmallScreen = useSmallScreen()

  if (!unit) return <Typography>Device not found.</Typography>

  const tabs = [
    {
      label: 'Devices',
      component: <DevicesList renderAsTab unitId={unit?.id} />,
    },
    { label: 'Zones', component: <ZonesTab /> },
  ]

  return (
    <Box>
      <Grid2 container my={isSmallScreen ? 1 : 2} direction="column">
        <Grid2 container display="flex" mb={2} gap={isSmallScreen ? 1.5 : 10}>
          <DetailItem
            label="Organization"
            value={unit.organizationName}
            linkTo={orgUrls.entity.replace(':id', unit.organization)}
          />
          <DetailItem
            label="Account"
            value={unit.accountName}
            linkTo={accountUrls.entity.replace(':id', unit.account)}
          />
          <DetailItem
            label="Property"
            value={unit.propertyName}
            linkTo={propertyUrls.entity.replace(':id', unit.property)}
          />
        </Grid2>
        <Grid2 container display="flex" gap={isSmallScreen ? 1.5 : 10}>
          <DetailItem label="Group" value={unit.group} />
          <DetailItem label="Floor" value={unit.floor} />
          <DetailItem label="Size" value={unit.unitSize} />
        </Grid2>
      </Grid2>
      <Divider />
      <Box sx={{ my: 1 }}>
        <TabComponent tabs={tabs} />
      </Box>
    </Box>
  )
}
