import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import LockResetIcon from '@mui/icons-material/LockReset'
import { Link } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { DateTime } from 'luxon'

import { Breadcrumbs, MobileList, MobileListDefaultCard } from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { parseApiErrors, useQueryFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import { BooleanCell, IntegrationCell } from '@portal/UI/components/cells'
import { getIntegrationIcon } from '@portal/UI/components/cells/IntegrationCell'
import DeleteModal from '@portal/UI/components/DeleteModal'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import ResetPasswordModal from '@portal/UI/components/ResetPasswordModal'
import boolOptions from '@portal/Utils/constants'

import userUrls from './urls'
import UserForm from './UserForm'
import ReactivateUserDialog from './UserReactivateDialog'

export default function UserList() {
  const [pageSize, setPageSize] = useState('')
  const [open, setOpen] = useState(false)
  const [rowId, setRowId] = useState('')
  const [error, setError] = useState('')
  const [saveFormOpen, setSaveFormOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [selectedIds, setSelectedIds] = useState([])
  const [reactivateFormOpen, setReactivateFormOpen] = useState(false)

  const {
    doUserListBulkReactivate,
    doMarkUserListAsOutdated,
    doUserListSetPageSize,
    doUserListSetPage,
    doUserListSetOrdering,
    doUserListSetFilter,
    doUserListClearParams,
    userList,
    userListRaw: { ordering = [] },
    userListIsLoading,
    doUpdateUrl,
    doUpdateQuery,
    doUserDelete,
    doPasswordReset,
    doShowSnackbar,
    userListApiParams,
    queryObject,
    systemPortalRoles,
  } = useConnect(
    'doUserListBulkReactivate',
    'doMarkUserListAsOutdated',
    'doUserListSetPageSize',
    'doUserListSetPage',
    'doUserListSetOrdering',
    'doUserListSetFilter',
    'doUserListClearParams',
    'selectUserList',
    'selectUserListRaw',
    'selectUserListIsLoading',
    'doUpdateUrl',
    'doUpdateQuery',
    'doUserDelete',
    'doPasswordReset',
    'doShowSnackbar',
    'selectUserListApiParams',
    'selectQueryObject',
    'selectSystemPortalRoles',
  )

  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [userEmailToResetPassword, setUserEmailToResetPassword] = useState('')
  const [resetPasswordError, setResetPasswordError] = useState(null)
  const openResetPasswordModal = () => setIsResetPasswordModalOpen(true)
  const closeResetPasswordModal = () => setIsResetPasswordModalOpen(false)

  const isSmallScreen = useSmallScreen()

  const handleClickOnRowResetPassword = (row) => {
    setUserEmailToResetPassword(row.email)
    openResetPasswordModal()
  }
  const handleResetPassword = async () => {
    try {
      await doPasswordReset(userEmailToResetPassword)
      closeResetPasswordModal()
      doShowSnackbar('A reset-password email was sent to the user', 'success')
    } catch (err) {
      setResetPasswordError(JSON.stringify(err))
    }
  }

  useQueryFilter({
    query: queryObject,
    apiParams: userListApiParams,
    setFilter: doUserListSetFilter,
    setPageSize: doUserListSetPageSize,
  })

  useEffect(() => {
    setCurrentUser({})
    doMarkUserListAsOutdated()
  }, [])

  const handleClear = () => {
    doUserListClearParams()
    doUserListSetFilter()
  }

  const handlePageChange = async (pageNumber) => {
    doUserListSetPage(pageNumber)
  }

  const handleRowClick = (params) => {
    const updatedId = userUrls.entity.replace(':id', params.id)
    doUpdateUrl(updatedId)
  }

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
    doUserListSetPageSize(size)
  }

  const handleUserSave = (row) => {
    setSaveFormOpen(true)
    setCurrentUser(row)
  }

  const openConfirmDeleteModal = (id) => {
    setRowId(id)
    setOpen(true)
  }

  const openConfirmReactivateModal = async (ids) => {
    setSelectedIds(ids)
    setReactivateFormOpen(true)
  }
  const reactivateUsers = async () => {
    const res = await doUserListBulkReactivate(selectedIds)
    const errorMessage = parseApiErrors(res?.error?.response)

    if (errorMessage) {
      doShowSnackbar(errorMessage, 'error')
    } else {
      doMarkUserListAsOutdated()
      setSelectedIds([])
      doShowSnackbar(
        `Successfully reactivated ${selectedIds.length} user(s)`,
        'success',
      )
    }
    setReactivateFormOpen(false)
  }

  const handleDelete = async () => {
    try {
      await doUserDelete(rowId)
      await doMarkUserListAsOutdated()
      setRowId('')
      setOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  if (!userList.results) {
    return (
      <Box m={5}>
        <Typography variant="h3" mb={2}>
          Users
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Typography>
            We seem to have encountered a problem. Please clear your search/filter and
            try again.
          </Typography>
          <Button
            variant="contained"
            color="error"
            sx={{ marginTop: '24px' }}
            onClick={handleClear}
          >
            Clear All
          </Button>
        </Box>
      </Box>
    )
  }

  const dateFormat = (row) =>
    row.lastLogin
      ? DateTime.fromISO(row.lastLogin).toLocaleString(DateTime.DATETIME_MED)
      : ''

  const listActions = {
    delete: openConfirmDeleteModal,
    update: handleUserSave,
    create: handleUserSave,
    bulk: [
      {
        action: openConfirmReactivateModal,
        title: 'Reactivate Users',
      },
    ],
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 0.75, isMainCell: true, sortable: true },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 0.5,
      renderCell: BooleanCell,
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'portalRole',
      headerName: 'Portal Role',
      flex: 0.5,
      renderCell: ({ row }) =>
        systemPortalRoles?.find((role) => role.id === row.portalRole)?.label ?? '',
      sortable: false,
    },
    { field: 'email', headerName: 'E-mail', flex: 1, sortable: true },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 0.6, sortable: false },
    {
      field: 'lastLogin',
      headerName: 'Last Seen',
      flex: 0.75,
      sortable: true,
      valueGetter: (_, row) => dateFormat(row),
    },
    {
      field: 'integrations',
      headerName: 'Integrations',
      renderCell: IntegrationCell,
      renderMobile: ({ row }) =>
        row?.v1 || row?.stripe || row?.salesForce ? (
          <Box display="flex" gap={1} ml={0.5}>
            {row?.v1 && getIntegrationIcon('v1')}
            {row?.stripe && getIntegrationIcon('Stripe')}
            {row?.salesForce && getIntegrationIcon('SalesForce')}
          </Box>
        ) : null,
      headerAlign: 'center',
      flex: 0.6,
    },
  ]

  const rows = userList?.results.map((user) => ({
    name: user.name,
    email: user.email,
    phoneNumber: user.phoneNumber,
    lastLogin: user.lastLogin,
    isActive: user.isActive,
    portalRole: user.portalRole,
    salesForce: user.isInSalesforce,
    stripe: user.isInStripe,
    v1: user.isInV1,
    id: user.id,
  }))

  const MobileItemHeader = useCallback(({ row }) => {
    const StatusIcon = row.isActive ? CheckCircleOutline : CancelOutlined
    return (
      <Box display="flex" alignItems="center" sx={{ '&&': { mb: 1 } }}>
        <Link href={userUrls.entity.replace(':id', row.id)}>
          <Typography variant="caption" fontWeight="bold">
            {row.name || '--'}
          </Typography>
        </Link>
        <StatusIcon
          color={row.isActive ? 'success' : 'warning'}
          sx={{ fontSize: 16, ml: 0.5 }}
        />
      </Box>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name', 'isActive'],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  const otherActions = [
    {
      label: 'Reset Password',
      icon: <LockResetIcon />,
      onClick: handleClickOnRowResetPassword,
    },
  ]

  return (
    <>
      <ReactivateUserDialog
        open={reactivateFormOpen}
        onConfirm={reactivateUsers}
        onCancel={() => setReactivateFormOpen(false)}
      />
      <UserForm
        open={saveFormOpen}
        onClose={() => {
          setCurrentUser({})
          doMarkUserListAsOutdated()
          setSaveFormOpen(false)
        }}
        instance={currentUser}
      />
      <Box m={3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: 'Users' }]}
        />
        <ListPageTitle
          title="Users"
          onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
          menuItems={
            isSmallScreen ? [{ label: 'Add User', onClick: handleUserSave }] : null
          }
          mb={2}
        />
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={userListIsLoading}
            apiParams={userListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <DynamicSelect label="Organizations" filterName="organization" />
            <StaticSelect
              label="3rd Party Integrations"
              filterName="hasIntegrations"
              options={boolOptions}
            />
            <StaticSelect label="Active" filterName="active" options={boolOptions} />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title="Users"
                loading={userListIsLoading}
                actions={listActions}
                itemBuilder={MobileListItem}
                otherActions={otherActions}
                page={userList.current || 1}
                pageChange={handlePageChange}
                pageSize={userList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={userList.count || 0}
                rowClick={handleRowClick}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                title="Users"
                loading={userListIsLoading}
                actions={listActions}
                otherActions={otherActions}
                columns={columns}
                page={userList.current || 1}
                pageChange={handlePageChange}
                pageSize={userList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={rows}
                rowCount={userList.count || 0}
                rowClick={handleRowClick}
                sortChange={doUserListSetOrdering}
                currentOrdering={ordering}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
              />
            )}
            <DeleteModal
              open={open}
              error={error}
              onConfirmDelete={handleDelete}
              onCancelDelete={() => setOpen(false)}
            />
            <ResetPasswordModal
              open={isResetPasswordModalOpen}
              onConfirmResetPassword={handleResetPassword}
              onCancelResetPassword={closeResetPasswordModal}
              error={resetPasswordError}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
