import { createListBundle } from '@common/utils'

import smokeProfileUrls from './urls'

const SMOKE_PROFILE_LIST_BULK_PREVIEW_STARTED =
  'SMOKE_PROFILE_LIST_BULK_PREVIEW_STARTED'
const SMOKE_PROFILE_LIST_BULK_PREVIEW_SUCCEEDED =
  'SMOKE_PROFILE_LIST_BULK_PREVIEW_SUCCEEDED'
const SMOKE_PROFILE_LIST_BULK_PREVIEW_FAILED = 'SMOKE_PROFILE_LIST_BULK_PREVIEW_FAILED'

const SMOKE_PROFILE_LIST_BULK_ARCHIVE_STARTED =
  'SMOKE_PROFILE_LIST_BULK_ARCHIVE_STARTED'
const SMOKE_PROFILE_LIST_BULK_ARCHIVE_SUCCEEDED =
  'SMOKE_PROFILE_LIST_BULK_ARCHIVE_SUCCEEDED'
const SMOKE_PROFILE_LIST_BULK_ARCHIVE_FAILED = 'SMOKE_PROFILE_LIST_BULK_ARCHIVE_FAILED'

const SMOKE_PROFILE_LIST_BULK_UNARCHIVE_STARTED =
  'SMOKE_PROFILE_LIST_BULK_UNARCHIVE_STARTED'
const SMOKE_PROFILE_LIST_BULK_UNARCHIVE_SUCCEEDED =
  'SMOKE_PROFILE_LIST_BULK_UNARCHIVE_SUCCEEDED'
const SMOKE_PROFILE_LIST_BULK_UNARCHIVE_FAILED =
  'SMOKE_PROFILE_LIST_BULK_UNARCHIVE_FAILED'

const entityName = 'smokeProfile'

const smokeProfileListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/smoke_profiles/', params, { cancelationPrefix: entityName }),
  urlTest: (url) => url === smokeProfileUrls.list,
})

export default {
  ...smokeProfileListBundle,
  doSmokeProfileListBulkPreview:
    ({ ids, operation }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: SMOKE_PROFILE_LIST_BULK_PREVIEW_STARTED })

        const result = await apiFetch(
          '/smoke_profiles/preview/',
          ids.map((id) => ({ profile: id, operation })),
          { method: 'POST', cancelationPrefix: entityName },
        )

        dispatch({ type: SMOKE_PROFILE_LIST_BULK_PREVIEW_SUCCEEDED, data: result })
        return result
      } catch (err) {
        dispatch({ type: SMOKE_PROFILE_LIST_BULK_PREVIEW_FAILED })
        return err
      }
    },
  doSmokeProfileListBulkArchive:
    ({ ids }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: SMOKE_PROFILE_LIST_BULK_ARCHIVE_STARTED })

        await apiFetch(
          '/smoke_profiles/archive/',
          ids.map((id) => ({ id })),
          { method: 'PUT', cancelationPrefix: entityName },
        )

        return dispatch({ type: SMOKE_PROFILE_LIST_BULK_ARCHIVE_SUCCEEDED })
      } catch (err) {
        dispatch({ type: SMOKE_PROFILE_LIST_BULK_ARCHIVE_FAILED })
        return err
      }
    },
  doSmokeProfileListBulkUnarchive:
    ({ ids }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: SMOKE_PROFILE_LIST_BULK_UNARCHIVE_STARTED })

        await apiFetch(
          '/smoke_profiles/unarchive/',
          ids.map((id) => ({ id })),
          { method: 'PUT', cancelationPrefix: entityName },
        )

        return dispatch({ type: SMOKE_PROFILE_LIST_BULK_UNARCHIVE_SUCCEEDED })
      } catch (err) {
        dispatch({ type: SMOKE_PROFILE_LIST_BULK_UNARCHIVE_FAILED })
        return err
      }
    },
}
