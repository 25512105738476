import { toCamelCase } from '@common/utils'

const apiValues = [
  'events_total',
  'events_charged',
  'incident_rate',
  'charge_rate',
  'gross_charge_rate',
  'adjustment_rate',
  'net_charges',
  'net_charges_per_event',
  'net_charges_per_device',
  'net_charges_per_charged_event',
  'missed_revenue',
  'connectivity',
]

export default apiValues.reduce((acc, value, index) => {
  const obj = { [`${toCamelCase(value)}`]: { position: index, apiValue: value } }
  return { ...acc, ...obj }
}, {})
