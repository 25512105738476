import MuiTextField from '@mui/material/TextField'

import { getIn } from 'formik'
import { humanize, underscore } from 'inflection'

import { Project } from '@common/config'

const formatError = (err) => humanize(underscore(err))

const formatErrors = ({ field, form }) => {
  const error = getIn(form.errors, field.name)
  if (!error) return [false, '']
  const errorText = Array.isArray(error) ? error.map(formatError) : formatError(error)
  return [true, errorText]
}

function TextField(formikProps) {
  const { field, form, showErrorsOnTouched, ...props } = formikProps
  const [hasError, errorText] = formatErrors(formikProps)
  const { touched } = form.getFieldMeta(field.name)
  const showError = form.dirty && hasError && (showErrorsOnTouched ? touched : true)

  const portalSpecificProps = Project.isPortal
    ? {
        variant: 'standard',
        sx: { width: '288px' },
      }
    : {}

  return (
    <MuiTextField
      error={showError}
      helperText={showError && errorText}
      {...field}
      {...portalSpecificProps}
      {...props}
    />
  )
}

export default TextField
