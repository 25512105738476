export const loginBoxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 143px)',
}

export const loginFormStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
  width: 500,
  justifyContent: 'center',
}

export const loginLogoStyles = {
  width: 300,
  marginBottom: 50,
}
