import { createListBundle } from '@common/utils'

import shipbobOrdersUrls from './urls'

const entityName = 'shipbobOrders'

const shipbobOrdersListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/shipbob_orders/', params, { cancelationPrefix: entityName }),
  urlTest: (url) => url === shipbobOrdersUrls.list,
})

export default shipbobOrdersListBundle
