import { useRef, useState } from 'react'

import { Button, Divider, Stack } from '@mui/material'

import { Breadcrumbs } from '@common/components'
import { useSmallScreen } from '@common/utils'
import { PageTitle, VerticalTabPanel } from '@rest/UI/components'

import Profile from './Profile/Profile'

export default function UserSettings() {
  const isSmallScreen = useSmallScreen()
  const tabs = [{ label: 'Profile', name: 'My Profile', component: Profile }]

  const [activeTab, setActiveTab] = useState(tabs[0].name)
  const [hasChanges, setHasChanges] = useState(false)

  const updateHeader = (index) => setActiveTab(tabs[index]?.name ?? '??')
  const formRef = useRef()

  const handleSave = () => formRef.current?.handleSubmit()
  const updateSaveButton = (isDirty) => setHasChanges(isDirty)

  return (
    <>
      <Breadcrumbs links={[{ label: 'Settings' }, { label: activeTab }]} />
      <Stack justifyContent="center" spacing={3} pt={1}>
        <Stack
          direction={isSmallScreen ? 'column' : 'row'}
          justifyContent="space-between"
        >
          <PageTitle mb={0}>{`User Settings > ${activeTab}`}</PageTitle>
          {activeTab !== 'Notifications' && (
            <Stack
              direction="row"
              justifyContent="end"
              spacing={2}
              mt={isSmallScreen ? 2 : 0}
            >
              <Button
                fullWidth={isSmallScreen}
                data-testid="cancel-profile"
                variant="contained"
                size="large"
                color="cancel"
                disabled={!hasChanges}
                onClick={() => formRef.current?.resetForm()}
              >
                Cancel
              </Button>
              <Button
                fullWidth={isSmallScreen}
                data-testid="save-profile"
                variant="contained"
                size="large"
                onClick={handleSave}
                disabled={!hasChanges}
              >
                Save
              </Button>
            </Stack>
          )}
        </Stack>

        <Divider />
        <VerticalTabPanel
          tabs={tabs}
          onTabSelect={updateHeader}
          formRef={formRef}
          onUpdate={updateSaveButton}
        />
      </Stack>
    </>
  )
}
