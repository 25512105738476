import { useCallback, useEffect, useMemo, useState } from 'react'

import { ChevronRight, DomainDisabledOutlined, ExpandMore } from '@mui/icons-material'
import { Box, Checkbox, Stack, Typography } from '@mui/material'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'

import { pluralize } from 'inflection'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.hierarchy
 * @param {Object[]} props.hierarchy.propertyGroups
 * @param {Function} props.onChange
 */
export default function HierarchyTree({ hierarchy, onChange = () => {} }) {
  const [selectedNodes, setSelectedNodes] = useState([])

  useEffect(() => {
    onChange(selectedNodes)
  }, [selectedNodes])

  const generateParentIds = useCallback((input) => {
    const stack = [{ data: input }]
    const newData = { ...input }

    while (stack.length > 0) {
      const { data, parent } = stack.pop()

      if (Array.isArray(data)) {
        data.forEach((item) => stack.push({ data: item, parent }))
      } else if (typeof data === 'object' && data !== null) {
        data.parent = parent
        Object.values(data).forEach((value) => {
          if (typeof value === 'object') {
            stack.push({ data: value, parent: data.id || null })
          }
        })
      }
    }

    return newData
  }, [])

  const generateNoGroupIds = useCallback(
    (data, prefix) =>
      data.map((item) => {
        const newItem = { ...item }
        if (!newItem.id) {
          newItem.id = `${prefix}:${newItem.name.replace(/ /g, '-').toLowerCase()}`
        }

        const updatedProperties = newItem.properties?.map((property) => {
          const updatedProperty = { ...property }

          if (updatedProperty.unitGroups) {
            updatedProperty.unitGroups = generateNoGroupIds(
              updatedProperty.unitGroups,
              `property:${updatedProperty.id}:unit-group`,
            )
          }

          return updatedProperty
        })
        if (updatedProperties) {
          newItem.properties = updatedProperties
        }
        return newItem
      }),
    [],
  )

  const transformData = useCallback((inputData) => {
    function transformNode(node, childrenType) {
      const result = { ...node, childrenType }
      if (node[childrenType] && node[childrenType].length > 0) {
        result.children = node[childrenType].map((child) =>
          transformNode(child, childrenType === 'properties' ? 'unitGroups' : 'units'),
        )
        delete result[childrenType]
      } else {
        delete result.children
        delete result.childrenType
      }
      return result
    }

    if (inputData.propertyGroups && inputData.propertyGroups.length > 0) {
      return inputData.propertyGroups.map((group) => transformNode(group, 'properties'))
    }

    return []
  }, [])

  const transformedData = useMemo(() => {
    const withNoGroupIds = generateNoGroupIds(
      hierarchy.propertyGroups,
      'property-group',
    )
    const withParentIds = generateParentIds({ propertyGroups: withNoGroupIds })
    return transformData(withParentIds)
  }, [hierarchy])

  const getAllIds = useCallback((node, idList = []) => {
    if (
      !(node.parent && node.parent.includes('no-group')) &&
      !node.id.includes('no-group') &&
      node.childrenType
    ) {
      idList.push(node.id)
    }

    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList))
    }
    return idList
  }, [])

  const searchInTree = useCallback((graph, targetId) => {
    const queue = [...graph]

    while (queue.length > 0) {
      const currNode = queue.shift()
      if (currNode.id === targetId) {
        return currNode
      }
      if (currNode.children) {
        queue.push(...currNode.children)
      }
    }
    return [] // Target node not found
  }, [])

  const getAllChild = useCallback(
    (id) => getAllIds(searchInTree(transformedData, id)),
    [transformedData],
  )

  const getAllFathers = useCallback(
    (id, list = []) => {
      const node = searchInTree(transformedData, id)
      if (node.parent) {
        list.push(node.parent)

        return getAllFathers(node.parent, list)
      }

      return list
    },
    [transformedData],
  )

  const isAllChildrenChecked = useCallback(
    (node, list) => {
      const allChild = getAllChild(node.id)
      const nodeIdIndex = allChild.indexOf(node.id)
      allChild.splice(nodeIdIndex, 1)

      return allChild.every((nodeId) => selectedNodes.concat(list).includes(nodeId))
    },
    [selectedNodes],
  )

  const handleNodeSelect = useCallback(
    (event, nodeId) => {
      event.stopPropagation()
      const allChild = getAllChild(nodeId)
      const fathers = getAllFathers(nodeId)

      if (selectedNodes.includes(nodeId)) {
        setSelectedNodes((prevSelectedNodes) =>
          prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id)),
        )
      } else {
        const toBeChecked = [...allChild]
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < fathers.length; ++i) {
          if (
            isAllChildrenChecked(searchInTree(transformedData, fathers[i]), toBeChecked)
          ) {
            toBeChecked.push(fathers[i])
          }
        }
        setSelectedNodes((prevSelectedNodes) =>
          [...prevSelectedNodes].concat(toBeChecked),
        )
      }
    },
    [transformedData, selectedNodes, setSelectedNodes],
  )

  const defaultExpandedIds = useMemo(() => {
    const ids = []

    function collectIds(obj) {
      if (obj.id) {
        ids.push(obj.id)
      }
      if (obj.children) {
        obj.children.forEach(collectIds)
      }
    }

    transformedData.forEach(collectIds)

    return ids
  }, [transformedData])

  const renderTree = useCallback(
    (nodes) => {
      // do not render units
      if (!nodes.childrenType) {
        return null
      }

      const prefix = (() => {
        if (nodes.childrenType === 'properties') {
          return 'Property Group'
        }
        if (nodes.childrenType === 'units') {
          return 'Unit Group'
        }
        return null
      })()

      const suffix = (() => {
        if (nodes.childrenType === 'units') {
          const count = nodes.children.length
          return `(${count} ${count > 1 ? pluralize('unit') : 'unit'})`
        }
        return null
      })()

      const checked =
        selectedNodes.indexOf(nodes.id) !== -1 ||
        (selectedNodes.indexOf(nodes.parent) !== -1 && !nodes.childrenType)

      return (
        <TreeItem
          key={nodes.id}
          itemId={nodes.id}
          onClick={(event) => {
            event.stopPropagation()
          }}
          label={
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={checked}
                disabled={!nodes.childrenType || nodes.id.includes('no-group')}
                indeterminate={
                  !checked && (!nodes.childrenType || nodes.id.includes('no-group'))
                }
                tabIndex={-1}
                disableRipple
                onClick={(event) => handleNodeSelect(event, nodes.id)}
              />
              {prefix ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{ fontStyle: 'italic' }}
                  >{`${prefix}`}</Typography>
                  <Typography variant="h6">&nbsp;{`— ${nodes.name}`}</Typography>
                </>
              ) : (
                <Typography variant="h6">{nodes.name}</Typography>
              )}
              {suffix && <Typography variant="h6">&nbsp;{suffix}</Typography>}
            </Box>
          }
          sx={{
            backgroundColor: 'white',
            '.Mui-selected,.Mui-focused': {
              backgroundColor: 'white',
            },
            '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label':
              {
                backgroundColor: 'white',
              },
            '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label':
              {
                backgroundColor: 'white',
              },
          }}
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node)).filter((node) => node)
            : null}
        </TreeItem>
      )
    },
    [selectedNodes, handleNodeSelect],
  )

  if (transformedData.length === 0) {
    return (
      <Stack sx={{ mt: 5 }} alignItems="center" justifyContent="center">
        <DomainDisabledOutlined sx={{ fontSize: 60, mb: 2 }} />
        This threshold profile is not assigned.
      </Stack>
    )
  }

  return (
    <Box display="flex" flexWrap="wrap" gap={2}>
      {transformedData[0].children[0].children.map((data) => (
        <SimpleTreeView
          multiSelect
          key={data.id}
          slots={{ expandIcon: ChevronRight, collapseIcon: ExpandMore }}
          defaultExpandedItems={defaultExpandedIds}
          selectedItems={selectedNodes}
        >
          {renderTree(data)}
        </SimpleTreeView>
      ))}
    </Box>
  )
}
