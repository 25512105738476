import { useEffect, useState } from 'react'

import { Box, Button, Modal, Stack, TextField } from '@mui/material'

import { pluralize, titleize } from 'inflection'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onSave
 * @param {Function} props.onCancel
 * @param {'week'|'month'} props.entity
 */
export default function MonthWeekModal({ open, onSave, onCancel, entity }) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (!open) {
      setValue('')
    }
  }, [open])

  const min = 1
  const max = 100
  const isError = value < min || value > max

  return (
    <Modal open={open}>
      <Box sx={style}>
        <TextField
          variant="standard"
          type="number"
          onChange={(event) => setValue(parseInt(event.target.value, 10))}
          error={isError}
          helperText={
            isError ? `Number should be less than ${max} and greater than ${min}` : null
          }
          label={`${titleize(pluralize(entity))} count`}
          value={value}
          sx={{ minWidth: 300, maxWidth: 300 }}
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" mt={2}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button disabled={!value} onClick={() => onSave(value)}>
            OK
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
