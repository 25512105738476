import { createListBundle, isAbortError } from '@common/utils'

import propertyUrls from './urls'

const PROPERTY_LIST_FETCH_ALL = 'PROPERTY_LIST_FETCH_ALL'

const entityName = 'property'

const propertyListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/properties/', params, { cancelationPrefix: entityName }),
  urlTest: (url) => url === propertyUrls.list,
})

export default {
  ...propertyListBundle,
  doPropertyListFetchAll:
    (payload) =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/properties/`,
          { pageSize: 500, ...payload },
          { method: 'GET', cancelationPrefix: entityName },
        )
        dispatch({ type: PROPERTY_LIST_FETCH_ALL, payload: response.results })
        return response
      } catch (err) {
        if (!isAbortError(err)) return err
        return null
      }
    },
}
