import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import {
  EmailOutlined,
  FlipCameraAndroidRounded as SwitchIcon,
  Logout,
  Person,
  UnfoldMoreOutlined,
} from '@mui/icons-material'
import { Menu, MenuItem, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import UserAvatar from '@common/components/Avatar'
import { Project } from '@common/config'
import { getApiFetch } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.me
 * @param {string} props.me.id
 * @param {string} props.me.name
 * @param {string} props.me.email
 * @param {string} [props.me.image]
 */
export default function UserMenu({ me }) {
  const [membershipsLoading, setMembershipsLoading] = useState(false)
  const [switchToRestAllowed, setSwitchToRestAllowed] = useState(false)
  const [userAnchor, setUserAnchor] = useState(null)

  const openUserMenu = Boolean(userAnchor)
  const { doAuthLogout } = useConnect('doAuthLogout')

  const handleMenuOpen = (event) => setUserAnchor(event.currentTarget)
  const handleMenuClose = () => setUserAnchor(null)

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const checkActiveMemberships = useCallback(async () => {
    try {
      setMembershipsLoading(true)
      const response = await apiFetch(
        '/memberships/',
        { active: true, pageSize: 1, user: me.id },
        { method: 'GET', cancelationPrefix: 'user_menu' },
      )
      setSwitchToRestAllowed(response.count > 0)
    } catch (err) {
      setSwitchToRestAllowed(false)
    } finally {
      setMembershipsLoading(false)
    }
  }, [me])

  useEffect(() => {
    if (userAnchor) checkActiveMemberships()
  }, [userAnchor])

  const switchToRestHelperText = useMemo(() => {
    if (membershipsLoading) {
      return 'Loading...'
    }
    return !switchToRestAllowed ? 'No Memberships Found' : null
  }, [switchToRestAllowed, membershipsLoading])

  return (
    <List>
      <ListItemButton
        onClick={handleMenuOpen}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <UserAvatar name={me.name} image={me.image} />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
            <Typography variant="body2">{me.name}</Typography>
          </Box>
        </Box>
        <UnfoldMoreOutlined color="text.secondary" />
      </ListItemButton>
      <Menu
        open={openUserMenu}
        anchorEl={userAnchor}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <Person />
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
              <Typography variant="body2">{me.name}</Typography>
            </Box>
          </Box>
        </ListItem>
        <ListItem>
          <EmailOutlined sx={{ opacity: '.54' }} />
          <Typography variant="caption" color="text.secondary" ml={1}>
            {me.email}
          </Typography>
        </ListItem>
        <Divider sx={{ mb: 1 }} />
        <MenuItem
          disabled={!switchToRestAllowed || membershipsLoading}
          onClick={() => Project.switch()}
        >
          <ListItemIcon>
            <SwitchIcon />
          </ListItemIcon>
          <ListItemText primary="Switch to Rest" secondary={switchToRestHelperText} />
        </MenuItem>
        <MenuItem onClick={doAuthLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
        <Typography variant="caption" color="text.secondary" pt={2} px={2}>
          {__version__} &middot; Terms and Conditions &middot; Privacy Policy
        </Typography>
      </Menu>
    </List>
  )
}
