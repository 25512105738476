import { path, prop, sortBy } from 'ramda'

const GROUPS_LIST_FETCH_ALL_LOADING = 'GROUPS_LIST_FETCH_ALL_LOADING'
const GROUPS_LIST_FETCH_ALL_LOADED = 'GROUPS_LIST_FETCH_ALL_LOADED'
const GROUPS_LIST_FETCH_ALL_FAILED = 'GROUPS_LIST_FETCH_ALL_FAILED'

const entityName = 'orgGroupSelector'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === GROUPS_LIST_FETCH_ALL_LOADING) {
      return {
        ...state,
        groups: { ...action.meta, payload: action.payload, data: action.previousData },
      }
    }
    if (action.type === GROUPS_LIST_FETCH_ALL_LOADED) {
      return { ...state, groups: { ...action.meta, data: action.payload } }
    }
    if (action.type === GROUPS_LIST_FETCH_ALL_FAILED) {
      return {
        ...state,
        groups: { ...action.meta, error: action.payload, data: action.previousData },
      }
    }
    return state || {}
  },

  doOrgGroupSelectorListFetchAll:
    (payload) =>
    async ({ apiFetch, dispatch, store }) => {
      const previousData = store.selectAllOrgGroupSelectorGroups()

      try {
        dispatch({
          type: GROUPS_LIST_FETCH_ALL_LOADING,
          payload,
          previousData,
          meta: { status: 'loading' },
        })

        const response = await apiFetch(
          `/organization_groups/`,
          { ordering: 'name', pageSize: 999, ...payload },
          { method: 'GET', cancelationPrefix: entityName },
        )
        const organizations = await apiFetch(
          `/organizations/`,
          { active: true, ordering: 'name', pageSize: 999 },
          { method: 'GET', cancelationPrefix: entityName },
        )

        const mappedGroups =
          response?.results?.map((group) => ({
            id: group.id,
            name: group.name,
          })) ?? []

        const noGroupOption = {
          id: null,
          name: 'No Group',
          orgsIds: organizations?.results
            ?.filter((org) => !org.group)
            .map((org) => org.id),
        }

        const finalResult = sortBy(prop('name'))([...mappedGroups, noGroupOption])

        dispatch({
          type: GROUPS_LIST_FETCH_ALL_LOADED,
          payload: finalResult,
          meta: { status: 'succeeded' },
        })
        return finalResult
      } catch (err) {
        dispatch({
          type: GROUPS_LIST_FETCH_ALL_FAILED,
          payload: err,
          previousData,
          meta: { status: 'failed' },
        })
        throw err
      }
    },
  selectAllOrgGroupSelectorGroups: ({ orgGroupSelector }) =>
    path(['groups', 'data'], orgGroupSelector),
}
