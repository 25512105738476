import { useEffect, useState } from 'react'

import { Box, Button, Modal, Stack } from '@mui/material'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro'

import { DateTime } from 'luxon'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onSave
 * @param {Function} props.onCancel
 */
export default function CustomDateModal({ open, onSave, onCancel }) {
  const [dateRangeValue, setDateRangeValue] = useState([null, null])

  useEffect(() => {
    if (!open) {
      setDateRangeValue([null, null])
    }
  }, [open])

  return (
    <Modal data-testid="custom_date_modal" open={open}>
      <Box sx={style}>
        <StaticDateRangePicker
          calendars={2}
          maxDate={DateTime.now()}
          value={dateRangeValue}
          onChange={setDateRangeValue}
          slotProps={{
            actionBar: {
              sx: { display: 'none' },
            },
          }}
        />
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={dateRangeValue.some((value) => value === null)}
            onClick={() => onSave(dateRangeValue)}
          >
            OK
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
