import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

export const entityName = 'system'
export const PRE_GO_LIVE_EVENT = 'PRE_GO_LIVE_EVENT'
export const CLEANING_MAINTENANCE_OCCURRED = 'CLEANING_MAINTENANCE_OCCURRED'
export const UNBOOKED_ROOM = 'UNBOOKED_ROOM'
export const ABNORMAL_EVENT = 'ABNORMAL_EVENT'
export const CREDIT_FOR_CLIENT = 'CREDIT_FOR_CLIENT'
export const UNABLE_TO_CHARGE = 'UNABLE_TO_CHARGE'

const system = createAsyncResourceBundle({
  name: entityName,
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: async ({ apiFetch }) =>
    apiFetch('/system/', null, { ancelationPrefix: entityName }),
})

export default {
  ...system,
  reactSystemFetch: createSelector(
    'selectIsAuthenticated',
    'selectSystemShouldUpdate',
    (authenticated, shouldUpdate) => {
      if (authenticated && shouldUpdate) {
        return { actionCreator: 'doFetchSystem' }
      }
      return undefined
    },
  ),
  selectSystemEventClasses: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.eventClasses,
  ),
  selectSystemEventTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.eventTypes,
  ),
  selectSytemDataCategories: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.dataCategories,
  ),
  selectSystemDataTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.dataTypes,
  ),
  selectSystemDeviceStatus: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.deviceStatus,
  ),
  selectSystemDeviceHealthStatus: createSelector(
    'selectSystem',
    (systemBundle) =>
      systemBundle?.deviceHealthStatus?.reduce(
        (acc, current) => ({ ...acc, [current.value]: current.label }),
        {},
      ) ?? {},
  ),
  selectSystemDeviceStyles: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.deviceStyles,
  ),
  selectSystemDeviceModels: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.deviceModels?.map((model) => ({
      name: model.name,
      label: model.key,
      id: model.id,
    })),
  ),
  selectSystemFeatureFlags: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.featureFlags,
  ),
  selectSystemPropertyTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.propertyTypes,
  ),
  selectSystemUnitStatus: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.unitStatus,
  ),
  selectSystemScenarioTags: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.scenarioTags?.map((obj) => obj.key),
  ),
  selectSystemTimeZones: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.timeZones,
  ),
  selectReportMetrics: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.reportMetrics,
  ),
  selectSystemSmokeAutoChargeTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeAutoChargeTypes,
  ),
  selectSystemSmokeProfiles: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeProfiles,
  ),
  selectSystemSmokeProfileStatuses: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeProfileStatuses,
  ),
  selectSystemSmokeProfileEnsembleStatuses: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeProfileEnsembleStatuses,
  ),
  selectSystemSmokeProfileEnsembleWindowTypes: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeProfileEnsembleWindowTypes,
  ),
  selectSystemSmokeProfileEnsembles: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeProfileEnsembles,
  ),
  selectSystemSmokeModelKeys: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeModelKeys,
  ),
  selectSystemReportClasses: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.reportClasses?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSmokeAdjustmentReasons: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.smokeAdjustmentReasons ?? [],
  ),
  selectSystemPortalRoles: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.portalRoles?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSystemRoleLevels: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.roleLevels,
  ),
  selectSystemBillingCadences: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.billingCadences?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSystemBillingTypes: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.billingTypes?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectSystemPermissions: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.permissions,
  ),
  selectSystemPermissionCategories: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.permissionCategories,
  ),
  selectAutochargeParamsSchema: createSelector(
    'selectSystem',
    (systemBundle) => systemBundle?.autochargeParamsSchema,
  ),
  selectSmokeReportVersions: createSelector('selectSystem', (systemBundle) =>
    systemBundle?.smokeReportVersions?.map((item) => ({
      id: item.value,
      label: item.name,
    })),
  ),
  selectPropertyBrands: () => [
    'Marriott',
    'Hilton',
    'Hyatt',
    'IHG',
    'Wyndham',
    'Fairmont',
    'Choice',
  ],
  selectEventDeletionReasons: () => [
    { id: PRE_GO_LIVE_EVENT, label: 'Pre-Go Live Event' },
    { id: CLEANING_MAINTENANCE_OCCURRED, label: 'Cleaning / Maintenance Occurred' },
    { id: UNBOOKED_ROOM, label: 'Unbooked Room' },
    { id: ABNORMAL_EVENT, label: 'Abnormal Event' },
    { id: CREDIT_FOR_CLIENT, label: 'Credit for Client' },
    { id: UNABLE_TO_CHARGE, label: 'Unable to Charge Group' },
  ],
}
