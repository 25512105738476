import Typography from '@mui/material/Typography'

import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'

export default function ReactivateUserDialog({ open, onConfirm, onCancel }) {
  const reactivateMessage = `Are you sure you want to reactivate user(s)?

  Memberships for these users will not be reactivated.
  They will need to be re-created manually.`

  return (
    <ConfirmationDialog
      fullWidth={false}
      title="Reactivate Users"
      message={<Typography whiteSpace="pre-line">{reactivateMessage}</Typography>}
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}
