import { createListBundle } from '@common/utils'

import smokeProfileEnsemblesUrls from './urls'

const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_STARTED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_STARTED'
const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_SUCCEEDED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_SUCCEEDED'
const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_FAILED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_FAILED'

const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_STARTED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_STARTED'
const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_SUCCEEDED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_SUCCEEDED'
const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_FAILED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_FAILED'

const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_STARTED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_STARTED'
const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_SUCCEEDED =
  'SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_SUCCEEDED'
const SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_FAILED =
  'SMOKE_PROFILE_LIST_BULK_UNARCHIVE_FAILED'

const entityName = 'smokeProfileEnsembles'

const smokeProfileEnsemblesListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/smoke_profile_ensembles/', params, { cancelationPrefix: entityName }),
  urlTest: (url) => url === smokeProfileEnsemblesUrls.list,
})

export default {
  ...smokeProfileEnsemblesListBundle,
  doSmokeProfileEnsemblesListBulkPreview:
    ({ ids, operation }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_STARTED })

        const result = await apiFetch(
          '/smoke_profile_ensembles/preview/',
          ids.map((id) => ({ ensemble: id, operation })),
          { method: 'POST', cancelationPrefix: entityName },
        )

        dispatch({
          type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_SUCCEEDED,
          data: result,
        })
        return result
      } catch (err) {
        dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_PREVIEW_FAILED })
        return err
      }
    },
  doSmokeProfileEnsemblesListBulkArchive:
    ({ ids }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_STARTED })

        await apiFetch(
          '/smoke_profile_ensembles/archive/',
          ids.map((id) => ({ id })),
          { method: 'PUT', cancelationPrefix: entityName },
        )

        return dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_SUCCEEDED })
      } catch (err) {
        dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_ARCHIVE_FAILED })
        return err
      }
    },
  doSmokeProfileEnsemblesListBulkUnarchive:
    ({ ids }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_STARTED })

        await apiFetch(
          '/smoke_profile_ensembles/unarchive/',
          ids.map((id) => ({ id })),
          { method: 'PUT', cancelationPrefix: entityName },
        )

        return dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_SUCCEEDED })
      } catch (err) {
        dispatch({ type: SMOKE_PROFILE_ENSEMBLES_LIST_BULK_UNARCHIVE_FAILED })
        return err
      }
    },
}
