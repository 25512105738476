import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, Divider } from '@mui/material'

import { TabComponent } from '@common/components'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import DevicesList from '@portal/pages/Devices/Devices'
import { Outages } from '@portal/pages/Home/Outages'
import Reservations from '@portal/pages/Reservations/Reservations'
import UnitForm from '@portal/pages/Units/UnitForm'
import UnitList from '@portal/pages/Units/Units'
import DeleteModal from '@portal/UI/components/DeleteModal'
import VerticalTabs from '@portal/UI/components/VerticalTabs'

import PropertyBillingForm from '../../Form/PropertyBillingForm'
import PropertySmokeConfiguration from '../../Form/PropertySmokeConfiguration'
import PropertyWifiForm from '../../Form/PropertyWifiForm'
import { Invoices } from '../Invoices'
import { Reviews } from '../Reviews'
import ThresholdsTab from '../Thresholds'
import BillingComponent from './BillingComponent'
import DetailsComponent from './DetailsComponent'
import FeatureFlagsComponent from './FeatureFlagsComponent'
import NoiseComponent from './NoiseComponent'
import SmokeComponent from './SmokeComponent'
import WifiComponent from './WifiComponent'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.propertyDetails]
 * @param {Object} [props.propertyDetails.property]
 * @param {string} [props.propertyDetails.property.id]
 * @param {string[]} [props.propertyDetails.property.expandedFlags]
 * @param {string} [props.propertyDetails.property.alertingSmokeProfile]
 * @param {string} [props.propertyDetails.property.secondOpinionSmokeProfile]
 * @param {string} [props.propertyDetails.property.salesforceAccountId]
 * @param {string} [props.propertyDetails.property.tripadvisorId]
 * @param {string} [props.propertyDetails.property.googlePlaceId]
 * @param {Object[]} [props.propertyDetails.flags]
 */
export default function PropertyDetail({ propertyDetails = undefined }) {
  const [tabValue, setTabValue] = useState(0)
  const [currentUnit, setCurrentUnit] = useState(null)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [rowIdForDelete, setRowIdForDelete] = useState(null)
  const [deleteError, setDeleteError] = useState(null)
  const [editSmokeFormOpen, setEditSmokeFormOpen] = useState(false)
  const [billingFormOpen, setBillingFormOpen] = useState(false)
  const [wifiFormOpen, setWifiFormOpen] = useState(false)
  const [currentProperty, setCurrentProperty] = useState(null)

  const isSmallScreen = useSmallScreen()

  const {
    isAtLeastAdmin,
    systemSmokeProfileEnsembles,
    doShowSnackbar,
    doUnitDelete,
    doUnitListClearParams,
    doUnitListSetFilter,
    doMarkUnitListAsOutdated,
    doFetchPropertyDetails,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectSystemSmokeProfileEnsembles',
    'doShowSnackbar',
    'doUnitDelete',
    'doUnitListClearParams',
    'doUnitListSetFilter',
    'doMarkUnitListAsOutdated',
    'doFetchPropertyDetails',
  )

  const fetchPropertyDetails = useCallback(async (id) => {
    try {
      await doFetchPropertyDetails(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const handleSmokeEditClick = () => {
    setEditSmokeFormOpen(true)
    setCurrentProperty(propertyDetails?.property)
  }

  const handleInitialParams = () => {
    doUnitListClearParams()
    doUnitListSetFilter({ active: true })
  }

  const isSmokeProperty = useMemo(
    () => propertyDetails?.property.expandedFlags?.includes('SMOKE'),
    [propertyDetails?.property],
  )

  const smokeEnsembleProfileData = useMemo(() => {
    if (propertyDetails?.property.alertingSmokeProfileEnsemble) {
      return systemSmokeProfileEnsembles.find(
        (profile) =>
          profile.id === propertyDetails?.property.alertingSmokeProfileEnsemble,
      )
    }
    return systemSmokeProfileEnsembles.find(
      (profile) => profile.status === 'DEFAULT_ALERTING',
    )
  }, [propertyDetails?.property, systemSmokeProfileEnsembles])

  useEffect(() => handleInitialParams(), [])

  const onDeleteFormClose = () => {
    setDeleteError(null)
    setDeleteConfirmOpen(false)
    setRowIdForDelete(null)
  }

  const onEditFormClose = () => {
    setCurrentUnit(null)
    doMarkUnitListAsOutdated()
    setEditFormOpen(false)
  }

  const handleDelete = async () => {
    try {
      await doUnitDelete(rowIdForDelete)
      doMarkUnitListAsOutdated()
      doShowSnackbar('Successfully deleted unit')
      onDeleteFormClose()
    } catch (e) {
      setDeleteError(e.message || JSON.stringify(e))
    }
  }

  const tabs = [
    {
      label: 'Units',
      component: <UnitList renderAsTab property={propertyDetails?.property} />,
    },
    {
      label: 'Devices',
      component: <DevicesList renderAsTab propertyId={propertyDetails?.property.id} />,
    },
    {
      label: 'Reservations',
      component: <Reservations renderAsTab property={propertyDetails?.property} />,
    },
    {
      label: 'Outages',
      component: <Outages renderAsTab />,
    },
  ]

  if (propertyDetails?.property.expandedFlags?.includes('NOISE')) {
    tabs.splice(1, 0, {
      label: 'Thresholds',
      component: <ThresholdsTab />,
    })
  }

  if (propertyDetails?.property?.salesforceAccountId) {
    tabs.push({
      label: 'Invoices',
      component: <Invoices />,
    })
  }

  if (
    propertyDetails?.property?.tripadvisorId ||
    propertyDetails?.property?.googlePlaceId
  ) {
    tabs.push({
      label: 'Reviews',
      component: <Reviews property={propertyDetails?.property} />,
    })
  }

  const detailsGap = isSmallScreen ? 2 : 4

  const detailsMargin = isSmallScreen ? 1 : 2

  const verticalTabs = [
    {
      label: 'Property Details',
      component: (
        <DetailsComponent
          property={propertyDetails?.property}
          margin={detailsMargin}
          gap={detailsGap}
        />
      ),
    },
    {
      label: 'Smoke Configuration',
      disabled: !isSmokeProperty,
      component: (
        <SmokeComponent
          smokeEnsembleProfileData={smokeEnsembleProfileData}
          property={propertyDetails?.property}
          onEdit={handleSmokeEditClick}
          margin={detailsMargin}
          gap={detailsGap}
        />
      ),
    },
    {
      label: 'Noise Configuration',
      disabled: !propertyDetails?.property.expandedFlags?.includes('NOISE'),
      component: <NoiseComponent margin={detailsMargin} gap={detailsGap} />,
    },
    {
      label: 'Billing Configuration',
      component: (
        <BillingComponent
          property={propertyDetails?.property}
          margin={detailsMargin}
          gap={detailsGap}
          onEdit={isAtLeastAdmin ? () => setBillingFormOpen(true) : null}
        />
      ),
    },
    {
      label: 'Feature Flags',
      component: (
        <FeatureFlagsComponent
          property={propertyDetails?.property}
          availableFlags={propertyDetails?.flags}
          onEditSuccess={() => fetchPropertyDetails(propertyDetails?.property.id)}
        />
      ),
    },
    {
      label: 'Wi-Fi Configuration',
      disabled: !propertyDetails?.property.expandedFlags.includes(
        'SSID_WIFI_CRED_GET_UPDATE',
      ),
      component: (
        <WifiComponent
          property={propertyDetails?.property}
          onEdit={isAtLeastAdmin ? () => setWifiFormOpen(true) : null}
          margin={detailsMargin}
          gap={detailsGap}
        />
      ),
    },
  ]

  return (
    <>
      <UnitForm
        open={editFormOpen}
        onClose={onEditFormClose}
        instance={currentUnit ?? { property: propertyDetails?.property.id }}
      />
      <DeleteModal
        open={deleteConfirmOpen}
        error={deleteError}
        onConfirmDelete={handleDelete}
        onCancelDelete={onDeleteFormClose}
      />
      <PropertyBillingForm
        instance={propertyDetails?.property}
        open={billingFormOpen}
        onClose={(success) => {
          setBillingFormOpen(false)
          if (success === true) {
            fetchPropertyDetails(propertyDetails?.property.id)
          }
        }}
      />
      <PropertyWifiForm
        instance={propertyDetails?.property}
        open={wifiFormOpen}
        onClose={(success) => {
          setWifiFormOpen(false)
          if (success === true) {
            fetchPropertyDetails(propertyDetails?.property.id)
          }
        }}
      />
      {currentProperty && (
        <PropertySmokeConfiguration
          open={editSmokeFormOpen}
          onClose={(success) => {
            setCurrentProperty(null)
            setEditSmokeFormOpen(false)
            if (success === true) {
              fetchPropertyDetails(currentProperty.id)
            }
          }}
          instance={currentProperty}
        />
      )}
      <Box>
        <Divider />
        <VerticalTabs tabs={verticalTabs} tabsMinWidth={218} />
        <Divider />
        <TabComponent
          tabs={tabs}
          externalState={{ value: tabValue, setValue: setTabValue }}
        />
      </Box>
    </>
  )
}
