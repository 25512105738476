import './PhoneNumber.css'

import { getIn } from 'formik'
import { humanize, underscore } from 'inflection'
import MuiPhoneNumber from 'material-ui-phone-number'

import { Project } from '@common/config'

const formatError = (err) => humanize(underscore(err))

const formatErrors = ({ field, form }) => {
  const error = getIn(form.errors, field.name)
  if (!error) return [false, '']
  const errorText = Array.isArray(error) ? error.map(formatError) : formatError(error)
  return [true, errorText]
}

function PhoneNumber(formikProps) {
  const { field, form, showErrorsOnTouched, ...props } = formikProps
  const [hasError, errorText] = formatErrors(formikProps)
  const { touched } = form.getFieldMeta(field.name)
  const showError =
    form.dirty && touched && hasError && (showErrorsOnTouched ? touched : true)

  const extraProps = Project.isPortal
    ? {
        variant: 'standard',
        sx: { width: '288px' },
      }
    : {
        variant: 'outlined',
        sx: { width: '100%' },
      }

  return (
    <MuiPhoneNumber
      defaultCountry="us"
      error={showError}
      helperText={showError && errorText}
      name={field.name}
      onBlur={field.onBlur}
      onChange={(v) => form.setFieldValue(field.name, v.replace(/[()\s]/g, ''))}
      value={getIn(form.values, field.name)}
      {...props}
      {...extraProps}
      disableAreaCodes
    />
  )
}

export default PhoneNumber
