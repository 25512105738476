import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.error
 * @param {boolean} props.open
 * @param {Function} props.onClose
 */
export default function AutoChargeTestErrorModal({ error, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{ marginY: 'auto', maxHeight: '1200px' }}
    >
      <DialogTitle color="primary" fontSize="1.5rem">
        Autocharge Test Failed
      </DialogTitle>
      <DialogContent sx={{ mt: 2.5, px: 4 }}>
        <pre
          style={{ backgroundColor: 'lightgray', padding: '20px', borderRadius: '5px' }}
        >
          {error}
        </pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
