import { memo, useEffect, useRef, useState } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

function isOverflowing(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.value
 * @param {number} props.width
 * @param {Function} [props.overflowChanged]
 *
 * @description
 * https://mui.com/x/react-data-grid/column-definition/#expand-cell-renderer
 */
const GridCellExpand = memo((props) => {
  const { width, value, overflowChanged = undefined } = props
  const wrapper = useRef(null)
  const cellDiv = useRef(null)
  const cellValue = useRef(null)
  const [showFullCell, setShowFullCell] = useState(false)
  const [keepCell, setKeepCell] = useState(false)
  const [showPopover, setShowPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    if (!keepCell) {
      setAnchorEl(null)
    }
  }

  const handleOnClick = () => {
    setKeepCell(!keepCell)
  }

  useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyUp(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
        setKeepCell(false)
      }
    }

    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [setShowFullCell, showFullCell])

  useEffect(() => {
    const overflowing = isOverflowing(cellValue.current)
    setShowPopover(overflowing)
    overflowChanged?.(overflowing)
  }, [cellValue.current?.clientWidth])

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopover && (
        <Popover
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: width - 20,
          }}
          onClose={handleMouseLeave}
          disableRestoreFocus
          disableScrollLock
          sx={{
            pointerEvents: 'none',
            width,
          }}
        >
          <Paper elevation={1} sx={{ width, pr: 3 }}>
            <Typography variant="body2" style={{ padding: 8, whiteSpace: 'pre-wrap' }}>
              {value}
            </Typography>
          </Paper>
        </Popover>
      )}
    </Box>
  )
})

/**
 * @param {Object} props - The props for the render function.
 * @param {Object} props.colDef
 * @param {string} [props.value]
 * @param {Function} [props.overflowChanged]
 */
export default function renderCellExpand(props) {
  return (
    <GridCellExpand
      value={props.value || ''}
      width={props.colDef.computedWidth}
      overflowChanged={props.overflowChanged}
    />
  )
}
