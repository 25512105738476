import { inflect } from 'inflection'

export const formatItemCount = (noun, number) => {
  if (!number) return ''
  return `${inflect(noun, number)} (${number})`
}

export const formatSubitemCount = (noun, number) => {
  if (!number) return `0 ${inflect(noun, 0)}`
  return `${number} ${inflect(noun, number)}`
}
