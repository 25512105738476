import { getAsyncActionIdentifiers } from '@common/bundles/utils'
import { getApiFetch } from '@common/utils'

export const defaultState = {
  reset: {
    status: null,
    message: null,
  },
}

const resetPasswordActions = getAsyncActionIdentifiers('reset', 'password')

const resetPasswordBundle = {
  name: 'resetPassword',
  getExtraArgs: (store) => ({
    apiFetch: getApiFetch(store),
  }),
  reducer: (state, action) => {
    switch (action.type) {
      case resetPasswordActions.types.start:
        return {
          ...state,
          reset: {
            ...defaultState.reset,
            status: 'started',
          },
        }
      case resetPasswordActions.types.failed:
        return {
          ...state,
          reset: { ...defaultState.reset, status: 'failed' },
        }
      case resetPasswordActions.types.succeeded:
        return {
          ...state,
          reset: { ...defaultState.reset, status: 'succeeded' },
        }
      default:
        return state || defaultState
    }
  },
  selectForgotPassword: (state) => state.resetPassword,
  [resetPasswordActions.actionName]:
    (email) =>
    async ({ apiFetch, dispatch }) => {
      const { types } = resetPasswordActions
      dispatch({ type: types.started })
      try {
        await apiFetch(
          '/password/forgot/',
          { email },
          {
            method: 'POST',
          },
        )
        dispatch({ type: types.succeeded })
      } catch (error) {
        dispatch({ type: types.failed })
        throw error
      }
    },
}

export default resetPasswordBundle
