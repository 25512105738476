import { forwardRef } from 'react'

import { Group } from 'react-konva'

import { noop } from '@common/utils'

import { DeleteButton, EditButton } from './Buttons'
import {
  RECT_HORIZONTAL_ORIGIN,
  RECT_MARGIN,
  TIMELINE_GRID_END,
  TIMELINE_GRID_ORIGIN,
  VERTICAL_GRID_SPACE,
} from './constants'
import { BottomRect, getRectWidth, TopRect } from './Rects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {boolean} props.visible
 * @param {number} props.top
 * @param {boolean} props.draggable
 * @param {Function} props.onMouseEnter
 * @param {Function} props.onMouseLeave
 * @param {Function} [props.onTouchStart]
 * @param {Function} [props.onTouchEnd]
 * @param {Function} props.onEdit
 * @param {Function} props.onDelete
 */
export const BottomHoverOverlayRect = forwardRef(
  (
    {
      editorWidth = undefined,
      top,
      onMouseEnter,
      onMouseLeave,
      onTouchStart = noop,
      onTouchEnd = noop,
      onEdit,
      onDelete,
      visible,
      draggable,
      ...props
    },
    ref,
  ) => {
    const color = visible ? '#757575D8' : '#FFFFFF00'
    const rectWidth = getRectWidth(editorWidth)

    return (
      <Group
        ref={ref}
        draggable={draggable}
        height={TIMELINE_GRID_END - (top + RECT_MARGIN)}
        y={top}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        {...props}
      >
        <BottomRect
          editorWidth={editorWidth}
          color={color}
          top={0}
          height={TIMELINE_GRID_END - (top + RECT_MARGIN)}
          dragDistance={VERTICAL_GRID_SPACE}
        />
        {visible && (
          <>
            <EditButton
              onClick={onEdit}
              y={(TIMELINE_GRID_END - top) / 2 - 16}
              x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 - (onDelete ? 70 : 25))}
            />
            {onDelete && (
              <DeleteButton
                onClick={onDelete}
                y={(TIMELINE_GRID_END - top) / 2 - 16}
                x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 + 12)}
              />
            )}
          </>
        )}
      </Group>
    )
  },
)

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} [props.editorWidth]
 * @param {boolean} props.visible
 * @param {number} props.bottom
 * @param {boolean} props.draggable
 * @param {Function} props.onMouseEnter
 * @param {Function} props.onMouseLeave
 * @param {Function} [props.onTouchStart]
 * @param {Function} [props.onTouchEnd]
 * @param {Function} props.onEdit
 * @param {Function} props.onDelete
 */
export const TopHoverOverlayRect = forwardRef(
  (
    {
      editorWidth = undefined,
      bottom,
      visible,
      onEdit,
      onDelete,
      onMouseEnter,
      onMouseLeave,
      onTouchStart = noop,
      onTouchEnd = noop,
      draggable,
      ...props
    },
    ref,
  ) => {
    const color = visible ? '#757575D8' : '#FFFFFF00'
    const rectWidth = getRectWidth(editorWidth)

    return (
      <Group
        ref={ref}
        draggable={draggable}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        dragDistance={VERTICAL_GRID_SPACE}
        height={bottom - TIMELINE_GRID_ORIGIN}
        y={0}
        {...props}
      >
        <TopRect editorWidth={editorWidth} color={color} bottom={bottom} />
        {visible && (
          <>
            <EditButton
              onClick={onEdit}
              y={bottom / 2 - 16}
              x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 - (onDelete ? 70 : 25))}
            />
            {onDelete && (
              <DeleteButton
                onClick={onDelete}
                y={bottom / 2 - 16}
                x={RECT_HORIZONTAL_ORIGIN + (rectWidth / 2 + 12)}
              />
            )}
          </>
        )}
      </Group>
    )
  },
)
