import { useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { OpenInFullRounded } from '@mui/icons-material'
import { Box, Card, Typography } from '@mui/material'

import { titleize } from 'inflection'

import FullscreenMetricCard from './components/FullscreenMetricCard'
import MetricChart from './MetricChart'
import MetricTable from './MetricTable'
import getMetricTypesMeta from './metricTypesMeta'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.metric
 * @param {Object[]} props.data
 * @param {string} props.data[].metric
 * @param {Object[]} props.data[].data
 * @param {Object} props.dataInterval
 * @param {number} props.dataInterval.value
 * @param {string} props.dataInterval.type
 * @param {string} props.selectedView
 * @param {Object[]} props.requestedDateRange
 * @param {boolean} props.showTotal
 * @param {Object} [props.sx]
 */
export default function MetricCard({
  metric,
  data,
  dataInterval,
  requestedDateRange,
  selectedView,
  showTotal,
  sx = {},
}) {
  const { reportMetrics } = useConnect('selectReportMetrics')

  const [showFullscreenButton, setShowFullscreenButton] = useState(false)
  const [openFullscreenCard, setOpenFullscreenCard] = useState(false)

  const systemMetric = reportMetrics.find((obj) => obj.key === metric)
  const cardTitle = systemMetric?.name ?? titleize(metric)
  const dataTypes = getMetricTypesMeta(data)

  return (
    <>
      <FullscreenMetricCard
        open={openFullscreenCard}
        onClose={() => setOpenFullscreenCard(false)}
        cardData={{
          title: cardTitle,
          metric,
          data,
          dataTypes,
          dataInterval,
          requestedDateRange,
          showTotal,
        }}
      />
      <Card
        key={metric}
        sx={sx}
        onMouseEnter={() => setShowFullscreenButton(true)}
        onMouseLeave={() => setShowFullscreenButton(false)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minHeight={40}
          px={2}
          pt={2}
        >
          <Typography variant="body2">{cardTitle}</Typography>
          <Box
            onClick={() => setOpenFullscreenCard(true)}
            sx={{
              display: showFullscreenButton ? 'block' : 'none',
              cursor: 'pointer',
            }}
          >
            <OpenInFullRounded sx={{ fontSize: 16 }} />
          </Box>
        </Box>
        {selectedView === 'graph' ? (
          <MetricChart
            id={metric}
            data={data}
            dataTypes={dataTypes}
            dataInterval={dataInterval}
            requestedDateRange={requestedDateRange}
            showTotal={showTotal}
          />
        ) : (
          <MetricTable data={data} groupBy="metric" />
        )}
      </Card>
    </>
  )
}
