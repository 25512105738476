import '@portal/UI/components/DateRangePicker.less'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { equals } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Book as BookIcon,
  FileDownload,
  FilterListRounded,
  KeyboardArrowDown,
  RestartAlt,
  SsidChartRounded,
  ZoomOut,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { DateSelectionModal } from '@common/components'
import { Chart, MemoChartMinimap } from '@common/components/chart'
import { StaticMultiSelect } from '@common/components/Selects'
import {
  hashToObj,
  objToHash,
  parseApiErrors,
  useLocalStorage,
  useSmallScreen,
} from '@common/utils'
import { days, hours } from '@common/utils/durations'
import {
  GRAFANA_DARK_BLUE,
  GRAFANA_GREEN,
  GRAFANA_RED,
  GRAFANA_YELLOW,
} from '@portal/pages/EventDashboard/EventModal/eventTypesMeta'
import EventViewModal from '@portal/pages/EventDashboard/EventModal/EventViewModal'
import ScenarioForm from '@portal/pages/Scenarios/ScenarioForm'
import { darkTheme } from '@portal/UI/Theme'

import CategoryLabel from './CategoryLabel'
import DataFilterModal from './DataFilterModal'
import {
  DIAGNOSTIC_CATEGORY,
  ENVIRONMENT_CATEGORY,
  getDeviceMetaData,
  NOISE_CATEGORY,
  OCCUPANCY_GATEGORY,
  SMOKE_CATEGORY,
  SMOKE_MASS_CONCENTRATION_CATEGORY,
  SMOKE_NUMBER_COUNT_CATEGORY,
} from './deviceMetaData'
import ScenarioOverlapConfirmation from './ScenarioOverlapConfirmation'

const availableDateOptions = [
  { title: '1H', value: 1 * hours },
  { title: '3H', value: 3 * hours },
  { title: '6H', value: 6 * hours },
  { title: '12H', value: 12 * hours },
  { title: '1D', value: 1 * days },
  { title: '2D', value: 2 * days },
  { title: '3D', value: 3 * days },
  { title: '7D', value: 7 * days },
  { title: '30D', value: 30 * days },
  { title: 'Custom', value: 'custom' },
]

const categoryPosition = [
  SMOKE_MASS_CONCENTRATION_CATEGORY,
  SMOKE_CATEGORY,
  SMOKE_NUMBER_COUNT_CATEGORY,
  NOISE_CATEGORY,
  ENVIRONMENT_CATEGORY,
  DIAGNOSTIC_CATEGORY,
  OCCUPANCY_GATEGORY,
].reduce((acc, category, index) => ({ ...acc, [category]: index }), {})

const ALARM_OPENED = 'alarm_opened'
const ALARM_REJECTED = 'alarm_rejected'
const EVENTS = 'events'
const RESERVATIONS = 'reservations'
const SCENARIOS = 'scenarios'

const areaSwitchers = [ALARM_OPENED, ALARM_REJECTED, RESERVATIONS, EVENTS, SCENARIOS]
const areaTooltipDateFormat = 'yyyy-MM-dd HH:mm:ss'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.device
 * @param {string} props.device.id
 * @param {string} props.device.model
 * @param {string} props.device.timezone
 */
export default function DeviceDataBrowser({ device }) {
  const [grafanaViewDevice, setGrafanaViewDevice] = useState(null)
  const [filterPopoverAnchor, setFilterPopoverAnchor] = useState(null)
  const [filterModalData, setFilterModalData] = useState(null)

  const [selectorId, setSelectorId] = useState(null)
  const [selectorStart, setSelectorStart] = useState(null)
  const [selectorEnd, setSelectorEnd] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const selectedAreaHistory = useRef([])

  const [currentData, setCurrentData] = useState({ chart: [] })
  const [initialChartData, setInitialChartData] = useState([])
  const [cursor, setCursor] = useState(null)

  const initialDate = useRef(null)

  const isSmallScreen = useSmallScreen()

  const {
    isAtLeastAdmin,
    deviceChartData,
    deviceChartDataIsLoading,
    systemDataTypes,
    hashObject,
    routeParams: { id: deviceId },
    systemSmokeProfiles,
    doFetchDeviceChartData,
    doScenarioCreateFromReadings,
    doDeviceExport,
    queryObject,
    doUpdateHash,
    doUpdateQuery,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectDeviceChartData',
    'selectDeviceChartDataIsLoading',
    'selectSystemDataTypes',
    'selectHashObject',
    'selectRouteParams',
    'selectSystemSmokeProfiles',
    'doFetchDeviceChartData',
    'doScenarioCreateFromReadings',
    'doDeviceExport',
    'selectQueryObject',
    'doUpdateHash',
    'doUpdateQuery',
    'doShowSnackbar',
  )

  useEffect(() => {
    if (queryObject?.grafanaDevice) {
      setGrafanaViewDevice(queryObject.grafanaDevice)
    }
  }, [queryObject])

  const getDtRange = () => {
    let end = DateTime.now()
    let start = end.minus({ hours: 24 })

    if (hashObject.device) {
      const objHash = hashToObj(hashObject?.device)
      if (objHash.end) {
        end = DateTime.fromMillis(Number(objHash.end), { zone: device?.timezone })
        start = end.minus({ hours: 24 })
      }
      if (objHash.start) {
        start = DateTime.fromMillis(Number(objHash.start), { zone: device?.timezone })
      }
    }

    return [start, end]
  }

  const [openDateSelectionModal, setOpenDateSelectionModal] = useState(false)
  const [minMaxVisible, setMinMaxVisible] = useLocalStorage('minMaxVisible', false)
  const [dateTimeRange, setDateTimeRange] = useState(getDtRange())
  const [selectedCategories, setSelectedCategories] = useState([])

  const [visibleAreas, setVisibleAreas] = useLocalStorage(
    'dataBrowserVisibleAreas',
    areaSwitchers.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
  )

  const deviceMetaData = getDeviceMetaData(systemDataTypes)

  const deviceDataByCategory = Object.entries(deviceMetaData).reduce(
    (acc, [name, value]) => ({
      ...acc,
      [value.category]:
        value.category in acc
          ? [...acc[value.category], { name, ...value }]
          : [{ name, ...value }],
    }),
    {},
  )

  const defaultVisibleData = Object.entries(deviceDataByCategory).reduce(
    (acc, [category, data]) => ({
      ...acc,
      [category]: data.reduce((a, d) => ({ ...a, [d.name]: d.visible }), {}),
    }),
    {},
  )

  const [scenarioOverlapFormOpen, setScenarioOverlapFormOpen] = useState(false)
  const [scenarioFormOpen, setScenarioFormOpen] = useState(false)
  const [visibleDataByCategory, setVisibleDataByCategory] = useLocalStorage(
    'visibleData',
    defaultVisibleData,
  )

  const buildCategoryOptionFromKey = (key) => {
    const parsedKey = key.split('-')
    const label =
      parsedKey.length > 1
        ? `${titleize(parsedKey.at(0).trim())} ${parsedKey
            .at(-1)
            .replace(/(\w)\w*\W*/g, (_, i) => i.toUpperCase())}`
        : titleize(parsedKey.at(0).trim())
    return {
      id: key,
      label,
      subtitle: parsedKey.at(1)?.trim(),
    }
  }

  const availableCategoriesOptions = Object.keys(visibleDataByCategory)
    .sort((a, b) => categoryPosition[a] - categoryPosition[b])
    .map((key) => buildCategoryOptionFromKey(key))

  const visibleCategories = Object.entries(visibleDataByCategory).reduce(
    (acc, [dt, data]) =>
      Object.values(data).filter(Boolean).length ? [...acc, dt] : acc,
    [],
  )

  useEffect(() => {
    const updatedOptions = visibleCategories.map((key) =>
      buildCategoryOptionFromKey(key),
    )
    setSelectedCategories(updatedOptions)
  }, [visibleDataByCategory])

  const categoryHasData = (dts, category) => {
    const catsWithData = dts.reduce((accumulator, dt) => {
      const data = currentData.chart.find((d) => (d?.key?.startsWith(dt) ? null : dt))
      return data ? [...accumulator, dt.category] : accumulator
    }, [])
    return catsWithData.includes(category)
  }

  const setDtVisibilityByCategory = (category) =>
    Object.keys(visibleDataByCategory[category]).reduce(
      (acc, dt) => ({ ...acc, [dt]: !visibleCategories.includes(category) }),
      {},
    )

  const handleToggleCategoryVisibility = (category) =>
    setVisibleDataByCategory((d) => ({
      ...d,
      [category]: setDtVisibilityByCategory(category),
    }))

  const handleCategoriesVisibilityChange = (params) => {
    const updatedCategories = params.map((p) => p.id)
    if (!equals(visibleCategories, updatedCategories)) {
      const updatedVisibleData = availableCategoriesOptions.reduce((acc, option) => {
        const category = option.id
        return {
          ...acc,
          [category]: Object.keys(visibleDataByCategory[category]).reduce(
            (acc2, dt) => ({ ...acc2, [dt]: updatedCategories.includes(category) }),
            {},
          ),
        }
      }, {})
      setVisibleDataByCategory(updatedVisibleData)
    }
  }

  const shouldHideCategory = (dataType, category) => {
    const categoryDts = visibleDataByCategory[category]
    const { [dataType]: currDt, ...dts } = categoryDts
    return !Object.values(dts).filter(Boolean).length
  }

  const handleToggleDtVisibility = (dt, category) =>
    shouldHideCategory(dt, category)
      ? handleToggleCategoryVisibility(category)
      : setVisibleDataByCategory((d) => ({
          ...d,
          [category]: { ...d[category], [dt]: !d[category][dt] },
        }))

  const [dateTimeRangeOption, setDateTimeRangeOption] = useState(
    availableDateOptions[4].value,
  )
  const handleDateTimeRangeChange = (_, value) => {
    if (value === 'custom') {
      setOpenDateSelectionModal(true)
      return
    }
    setDateTimeRange([DateTime.now().minus({ milliseconds: value }), DateTime.now()])
    setDateTimeRangeOption(value)
  }

  const handleZoomOut = () => {
    selectedAreaHistory.current.splice(-1)
    const latestHistoryArea = selectedAreaHistory.current.at(-1)
    setSelectedArea(
      latestHistoryArea ? { ...latestHistoryArea, updateBrush: true } : null,
    )
  }

  const handleZoomReset = () => {
    selectedAreaHistory.current.length = 0
    setSelectedArea(null)
  }

  const getDataTypesToFetch = () =>
    Object.values(visibleDataByCategory).reduce((acc, data) => {
      const apiValues = Object.entries(data).reduce((acc2, d) => {
        const updated = [...acc2]
        if (d[1]) {
          const meta = deviceMetaData[d[0]]
          if (meta.hasMinMax) {
            return minMaxVisible
              ? [...acc2, `${d[0]}_mean`, `${d[0]}_min`, `${d[0]}_max`]
              : [...acc2, `${d[0]}_mean`]
          }
          return [...acc2, d[0]]
        }
        return updated
      }, [])
      return [...acc, ...apiValues]
    }, [])

  const fetchDeviceChartData = useCallback(async (payload) => {
    try {
      await doFetchDeviceChartData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useEffect(() => {
    if (initialDate.current) {
      const start = selectedArea?.start ?? initialDate.current.start
      const end = selectedArea?.end ?? initialDate.current.end
      const parsedStart = DateTime.isDateTime(start)
        ? start
        : DateTime.fromJSDate(start)
      const parsedEnd = DateTime.isDateTime(end) ? end : DateTime.fromJSDate(end)
      const hash = objToHash({
        start: parsedStart.toMillis(),
        end: parsedEnd.toMillis(),
      })
      const dataTypesToFetch = getDataTypesToFetch()

      doUpdateHash({ device: hash }, { maintainScrollPosition: true, replace: true })
      if (dataTypesToFetch.length > 0) {
        fetchDeviceChartData({
          device,
          start: parsedStart.toISO(),
          end: parsedEnd.toISO(),
          dataTypes: dataTypesToFetch,
        })
      }
    }
  }, [selectedArea, visibleDataByCategory, minMaxVisible])

  useEffect(() => {
    if (!deviceChartDataIsLoading) {
      const filteredData = deviceChartData?.chart.filter(
        (item) => item.key.split(':').at(-1) === deviceId,
      )
      if (!selectedArea && filteredData) {
        setInitialChartData(filteredData)
      }
      setCurrentData({
        ...deviceChartData,
        chart: filteredData ?? [],
      })
    }
  }, [deviceChartData])

  useEffect(() => {
    if (device) {
      const [start, end] = dateTimeRange
      const hash = objToHash({ start: start.toMillis(), end: end.toMillis() })
      const dataTypesToFetch = getDataTypesToFetch()

      initialDate.current = { start, end }

      handleZoomReset()
      doUpdateHash({ device: hash }, { maintainScrollPosition: true, replace: true })
      if (dataTypesToFetch.length > 0) {
        fetchDeviceChartData({
          device,
          start: start.toISO(),
          end: end.toISO(),
          dataTypes: dataTypesToFetch,
        })
      }
    }
  }, [dateTimeRange])

  const refreshChartData = useCallback(() => {
    const start = selectedArea?.start ?? initialDate.current.start
    const end = selectedArea?.end ?? initialDate.current.end
    const parsedStart = DateTime.isDateTime(start) ? start : DateTime.fromJSDate(start)
    const parsedEnd = DateTime.isDateTime(end) ? end : DateTime.fromJSDate(end)

    const dataTypesToFetch = getDataTypesToFetch()
    if (dataTypesToFetch.length > 0) {
      fetchDeviceChartData({
        device,
        start: parsedStart.toISO(),
        end: parsedEnd.toISO(),
        dataTypes: dataTypesToFetch,
      })
    }
  }, [selectedArea, dateTimeRange])

  useEffect(() => {
    const atLeastOneTimestamp = ['start', 'end'].some((ts) =>
      hashObject?.device?.includes(ts),
    )

    if (device && atLeastOneTimestamp) setDateTimeRangeOption('custom')
  }, [])

  const formatData = (data, dataTypes) => {
    const dtKeys = dataTypes.map((dt) => dt.name)
    const chartData = data.filter((datum) =>
      dtKeys.some((key) => datum.dataType.includes(key)),
    )
    const numPoints = chartData?.[0]?.data.length
    return Array.from({ length: numPoints }).map((_, i) =>
      chartData.reduce((acc, datum) => {
        const { time, value } = datum.data[i]
        return { ...acc, time: DateTime.fromISO(time), [datum.dataType]: value }
      }, {}),
    )
  }

  const processedDeviceData = useMemo(
    () =>
      Object.entries(deviceDataByCategory).reduce(
        (acc, [key, dataTypes]) => ({
          ...acc,
          [`${key}_data`]: categoryHasData(dataTypes, key)
            ? {
                current: formatData(initialChartData, dataTypes),
                zoomed: formatData(currentData.chart, dataTypes),
              }
            : {},
        }),
        {},
      ),
    [currentData],
  )

  const minimapData = useMemo(() => {
    const data = Object.values(processedDeviceData).find((dt) => dt.current?.length > 0)
    if (data?.current?.length > 0) {
      return data.current
    }
    return null
  }, [processedDeviceData])

  const getAreaStyle = useCallback(
    ({ color }) => ({
      dashedBackground: false,
      filledBackground: true,
      area: { color, opacity: 0.1 },
      handle: { color, dashArray: [4, 4], opacity: 0.6 },
    }),
    [],
  )

  const alertsAreas = useMemo(() => {
    const getAlarmColor = (alarm, unit) => {
      if (alarm.rejections) {
        return GRAFANA_RED
      }
      const relatedEvent = currentData.events?.find(
        (event) => event.metadata?.smoke?.alarmId === alarm.id,
      )
      if (relatedEvent?.deletedOn) {
        return '#7F66B3'
      }
      return unit.snooze ? GRAFANA_YELLOW : GRAFANA_GREEN
    }

    return currentData.alarms
      ?.filter((alarm) => {
        const isRejected = alarm.rejections
        const showOpened = visibleAreas[ALARM_OPENED]
          ? !alarm.rejections
          : alarm.rejections
        const showRejected = visibleAreas[ALARM_REJECTED]
          ? alarm.rejections
          : !alarm.rejections
        return (isRejected && showRejected) || (!isRejected && showOpened)
      })
      .map((alarm) => {
        const formattedLabel = `${alarm.rejections ? 'REJECTED' : ''} Alarm ${alarm.id}`
        const formattedStart = alarm.start.toFormat(areaTooltipDateFormat)
        const formattedEnd = alarm.end?.toFormat(areaTooltipDateFormat) ?? 'Ongoing'
        const profile = systemSmokeProfiles.find((item) => item.id === alarm.tag)

        return {
          id: alarm.id,
          start: alarm.start,
          end: alarm.end ?? DateTime.now(),
          payload: {
            label: formattedLabel,
            start: formattedStart,
            end: formattedEnd,
            modelTag: profile ? `${profile.modelKey} - ${profile.name}` : null,
            rejections: alarm.rejections,
            metadata: JSON.stringify(alarm.metadata, null, 2),
          },
          style: getAreaStyle({ color: getAlarmColor(alarm, currentData.unit) }),
          priority: alarm.rejections ? 30 : 40,
        }
      })
  }, [currentData, visibleAreas[ALARM_OPENED], visibleAreas[ALARM_REJECTED]])

  const eventsAreas = useMemo(() => {
    const getEventColor = (eventClass) => {
      switch (eventClass) {
        case 'DISCONNECT':
        case 'BULK_DISCONNECT':
          return '#B8B8B8'
        case 'NOISE':
        case 'ACTIONABLE_NOISE_1':
        case 'ACTIONABLE_NOISE_2':
        case 'ACTIONABLE_NOISE_3':
          return '#547E7C'
        case 'SMOKE':
          return '#6E3F3F'
        case 'OCCUPANCY':
        case 'ELEVATED_OCCUPANCY':
          return '#613D75'
        default:
          return '#344B78'
      }
    }

    return visibleAreas[EVENTS]
      ? currentData.events
          ?.filter(
            (event) =>
              !event.deletedOn &&
              !currentData.alarms?.find((alarm) => alarm.relatedEvent?.id === event.id),
          )
          ?.map((event) => {
            const formattedStart = event.createdOn.toFormat(areaTooltipDateFormat)
            const formattedEnd =
              event.endTime?.toFormat(areaTooltipDateFormat) ?? 'Ongoing'

            return {
              id: event.id,
              start: event.createdOn,
              end: event.endTime ?? DateTime.now(),
              payload: {
                label: 'Event',
                start: formattedStart,
                end: formattedEnd,
                eventClass: event.eventClass,
                isChildEvent: event.parentEvent ? 'Yes' : 'No',
              },
              style: getAreaStyle({ color: getEventColor(event.eventClass) }),
              priority: 20,
            }
          })
      : []
  }, [currentData, visibleAreas[EVENTS]])

  const reservationsAreas = useMemo(
    () =>
      visibleAreas[RESERVATIONS]
        ? currentData.reservations?.map((reservation) => ({
            id: reservation.id,
            start: reservation.checkIn,
            end: reservation.checkOut,
            payload: {
              label: 'Reservation',
              checkIn: DateTime.fromISO(reservation.checkIn).toFormat(
                areaTooltipDateFormat,
              ),
              checkOut: DateTime.fromISO(reservation.checkOut).toFormat(
                areaTooltipDateFormat,
              ),
            },
            style: getAreaStyle({ color: GRAFANA_DARK_BLUE }),
            priority: 0,
          }))
        : [],
    [currentData, visibleAreas[RESERVATIONS]],
  )

  const scenariosAreas = useMemo(
    () =>
      visibleAreas[SCENARIOS]
        ? currentData?.scenarios?.map((scenario) => ({
            id: scenario.id,
            start: DateTime.fromISO(scenario.readingStart),
            end: DateTime.fromISO(scenario.readingEnd),
            payload: {
              label: `Scenario - ${scenario.name}`,
              createdOn: DateTime.fromISO(scenario.createdOn).toFormat(
                areaTooltipDateFormat,
              ),
              start: DateTime.fromISO(scenario.readingStart).toFormat(
                areaTooltipDateFormat,
              ),
              end: DateTime.fromISO(scenario.readingEnd).toFormat(
                areaTooltipDateFormat,
              ),
              ...scenario.labels.reduce(
                (acc, label, index) => ({
                  ...acc,
                  [index !== 0 ? `label_${index}_start` : 'labelStart']:
                    DateTime.fromISO(label.start).toFormat(areaTooltipDateFormat),
                  [index !== 0 ? `label_${index}_end` : 'labelEnd']: DateTime.fromISO(
                    label.end,
                  ).toFormat(areaTooltipDateFormat),
                }),
                {},
              ),
            },
            style: getAreaStyle({ color: '#5CBFB0' }),
            priority: 10,
          }))
        : [],
    [currentData, visibleAreas[SCENARIOS]],
  )

  const handleExport = useCallback(async () => {
    try {
      const timeRange = selectedArea
        ? [selectedArea.start, selectedArea.end]
        : dateTimeRange
      const [start, end] = timeRange
      const result = await doDeviceExport({
        id: deviceId,
        data: { timeGt: start.toISO(), timeLt: end.toISO() },
      })
      if (result.error) {
        throw result.error
      }

      doShowSnackbar(
        'Device export started, you will receive an email soon.',
        'success',
      )
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [selectedArea, dateTimeRange, deviceId])

  const FiltersButton = useCallback(
    () => (
      <Button
        fullWidth
        variant="outlined"
        startIcon={<FilterListRounded />}
        onClick={(event) => setFilterPopoverAnchor(event.currentTarget)}
      >
        Filters
      </Button>
    ),
    [],
  )

  const GrafanaViewButton = useCallback(
    ({ fullWidth }) => (
      <Button
        fullWidth={fullWidth}
        startIcon={<SsidChartRounded />}
        variant="outlined"
        onClick={() =>
          doUpdateQuery({ grafanaDevice: deviceId }, { maintainScrollPosition: true })
        }
      >
        Grafana View
      </Button>
    ),
    [deviceId],
  )

  const SaveScenarioButton = useCallback(
    ({ fullWidth }) => (
      <Button
        fullWidth={fullWidth}
        startIcon={<BookIcon />}
        variant="outlined"
        onClick={() => {
          const { scenarios } = currentData
          if (scenarios.length > 0) {
            setScenarioOverlapFormOpen(true)
          } else {
            setScenarioFormOpen(true)
          }
        }}
      >
        Save Scenario
      </Button>
    ),
    [currentData],
  )

  const categorySelectorProps = useMemo(
    () => ({
      options: availableCategoriesOptions,
      label: 'Categories',
      size: 'small',
      value: selectedCategories,
      onChange: setSelectedCategories,
      onSave: handleCategoriesVisibilityChange,
    }),
    [
      availableCategoriesOptions,
      selectedCategories,
      setSelectedCategories,
      handleCategoriesVisibilityChange,
    ],
  )

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <EventViewModal
          id={grafanaViewDevice}
          entity="device"
          initialTimeRange={
            selectedArea ? [selectedArea.start, selectedArea.end] : dateTimeRange
          }
          onClose={() => {
            doUpdateQuery({}, { maintainScrollPosition: true })
            setGrafanaViewDevice(null)
          }}
        />
      </ThemeProvider>
      <ScenarioOverlapConfirmation
        open={scenarioOverlapFormOpen}
        scenarios={currentData?.scenarios ?? []}
        timeRange={
          selectedArea ? [selectedArea.start, selectedArea.end] : dateTimeRange
        }
        onClose={() => setScenarioOverlapFormOpen(false)}
        onOverwrite={() => {
          refreshChartData()
          setScenarioOverlapFormOpen(false)
        }}
        onContinue={() => {
          setScenarioFormOpen(true)
          setScenarioOverlapFormOpen(false)
        }}
      />
      <ScenarioForm
        open={scenarioFormOpen}
        onClose={(success) => {
          setScenarioFormOpen(false)
          if (success === true) {
            refreshChartData()
          }
        }}
        onSave={doScenarioCreateFromReadings}
        instance={{
          sourceDevice: device.id,
          deviceModel: device.model,
          readingStart: selectedArea?.start ?? dateTimeRange[0],
          readingEnd: selectedArea?.end ?? dateTimeRange[1],
        }}
      />
      <DateSelectionModal
        open={openDateSelectionModal}
        onCancel={() => setOpenDateSelectionModal(false)}
        onSave={(dateRange) => {
          setDateTimeRange(dateRange)
          setDateTimeRangeOption('custom')
          setOpenDateSelectionModal(false)
        }}
      />
      <DataFilterModal
        open={!!filterModalData}
        onClose={() => setFilterModalData(null)}
        visibleDataByCategory={visibleDataByCategory}
        loading={deviceChartDataIsLoading}
        data={filterModalData}
      />
      <Popover
        open={!!filterPopoverAnchor}
        onClose={() => setFilterPopoverAnchor(null)}
        anchorEl={filterPopoverAnchor}
        anchorOrigin={{ vertical: 50, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack direction="column" gap={1} px={2} pt={1} pb={0.5}>
          <FormControlLabel
            key="minmax"
            label="Show Min/Max"
            labelPlacement="end"
            slotProps={{ typography: { fontSize: 12 } }}
            control={
              <Checkbox
                checked={minMaxVisible}
                size="small"
                onChange={() => setMinMaxVisible((v) => !v)}
              />
            }
          />
          <Divider />
          {areaSwitchers.map((key) => (
            <FormControlLabel
              key={key}
              label={titleize(key)}
              labelPlacement="end"
              slotProps={{ typography: { fontSize: 12 } }}
              control={
                <Checkbox
                  checked={visibleAreas[key]}
                  size="small"
                  onChange={() =>
                    setVisibleAreas((areas) => ({
                      ...areas,
                      [key]: !areas[key],
                    }))
                  }
                />
              }
            />
          ))}
        </Stack>
      </Popover>
      <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
        {deviceChartDataIsLoading && <CircularProgress sx={{ position: 'absolute' }} />}
        <Box
          sx={{
            width: '100%',
            transitionProperty: 'opacity',
            transitionDuration: '300ms',
            opacity: deviceChartDataIsLoading ? 0.35 : 1,
            pointerEvents: deviceChartDataIsLoading ? 'none' : 'initial',
          }}
        >
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {isSmallScreen && (
              <Box display="flex" flexDirection="column" gap={1.5}>
                <StaticMultiSelect
                  dynamicWidth
                  limitTags={2}
                  {...categorySelectorProps}
                />
                <FormControl fullWidth size="small">
                  <InputLabel>Timeframe</InputLabel>
                  <Select
                    value={dateTimeRangeOption}
                    label="Timeframe"
                    IconComponent={KeyboardArrowDown}
                    onChange={(event) =>
                      handleDateTimeRangeChange(null, event.target.value)
                    }
                  >
                    {availableDateOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box display="flex" flexDirection="row" gap={1}>
                  <GrafanaViewButton fullWidth />
                  {isAtLeastAdmin && <SaveScenarioButton fullWidth />}
                </Box>
                <Box display="flex" flexDirection="row" gap={1}>
                  <FiltersButton />
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<FileDownload />}
                    onClick={handleExport}
                  >
                    Export
                  </Button>
                </Box>
              </Box>
            )}
            {!isSmallScreen && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                my={2}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <StaticMultiSelect limitTags={3} {...categorySelectorProps} />
                  <FiltersButton />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={2}
                >
                  <ToggleButtonGroup
                    exclusive
                    onChange={handleDateTimeRangeChange}
                    size="small"
                    variant="outlined"
                  >
                    {availableDateOptions.map((option) => {
                      const customTooltipFormat = 'yyyy-MM-dd'
                      const tooltipText = (() => {
                        const showCustomDateTooltip =
                          option.value === 'custom' && dateTimeRangeOption === 'custom'
                        if (showCustomDateTooltip) {
                          const start = dateTimeRange[0]?.toFormat(customTooltipFormat)
                          const end = dateTimeRange[1]?.toFormat(customTooltipFormat)
                          return `${start} - ${end}`
                        }
                        return null
                      })()
                      return (
                        <Tooltip key={option.value} title={tooltipText}>
                          <ToggleButton
                            value={option.value}
                            selected={dateTimeRangeOption === option.value}
                          >
                            {option.title}
                          </ToggleButton>
                        </Tooltip>
                      )
                    })}
                  </ToggleButtonGroup>
                  <GrafanaViewButton />
                  {isAtLeastAdmin && <SaveScenarioButton />}
                  <Button
                    variant="outlined"
                    startIcon={<FileDownload />}
                    onClick={handleExport}
                  >
                    Export
                  </Button>
                </Box>
              </Box>
            )}
            {selectedArea && (
              <Box display="flex" gap={1} mb={2}>
                <Button
                  startIcon={<ZoomOut />}
                  variant="outlined"
                  onClick={handleZoomOut}
                >
                  Zoom Out
                </Button>
                <Button
                  startIcon={<RestartAlt />}
                  variant="outlined"
                  onClick={handleZoomReset}
                >
                  Reset Zoom
                </Button>
              </Box>
            )}
            {processedDeviceData && minimapData && !isSmallScreen && (
              <Box sx={{ mb: 1 }}>
                <MemoChartMinimap
                  id="chart_minimap"
                  data={minimapData}
                  selectedArea={selectedArea}
                  onChange={(area) => {
                    setSelectedArea(area)
                    selectedAreaHistory.current.push(area)
                    setSelectorStart(null)
                    setSelectorEnd(null)
                  }}
                  onMove={(area) => {
                    setSelectorStart(area.start)
                    setSelectorEnd(area.end)
                  }}
                />
              </Box>
            )}
            {Object.entries(deviceDataByCategory)
              .sort((a, b) => categoryPosition[a[0]] - categoryPosition[b[0]])
              .reduce((acc, [key, dataTypes], i) => {
                if (!visibleCategories.includes(key)) return acc
                return [
                  ...acc,
                  <Box
                    key={`${key}_data`}
                    sx={{
                      display: 'flex',
                      flexDirection: isSmallScreen ? 'column' : 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                      mt: i !== 0 ? 4 : 2,
                    }}
                  >
                    {categoryHasData(dataTypes, key) ? (
                      <>
                        {isSmallScreen && (
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <CategoryLabel
                              category={key.split('-')[0]}
                              subcategory={key.split('-')[1]}
                            />
                            <IconButton
                              color="primary"
                              onClick={() =>
                                setFilterModalData({
                                  dataTypes,
                                  key,
                                  visibleDataByCategory,
                                  handleToggleDtVisibility,
                                })
                              }
                            >
                              <FilterListRounded sx={{ fontSize: 18 }} />
                            </IconButton>
                          </Box>
                        )}
                        {!isSmallScreen && (
                          <FormGroup
                            style={{ width: 200, flex: 'none', margin: 'none' }}
                          >
                            <FormLabel component="legend">
                              <CategoryLabel
                                category={key.split('-')[0]}
                                subcategory={key.split('-')[1]}
                              />
                            </FormLabel>
                            {dataTypes.map(({ color, name, label }) => (
                              <FormControlLabel
                                sx={{ color, mr: 0 }}
                                key={name}
                                label={
                                  <Box>
                                    {label}
                                    <Box sx={{ fontSize: 12 }}>{name}</Box>
                                  </Box>
                                }
                                control={
                                  <Checkbox
                                    sx={{ color, '&.Mui-checked': { color } }}
                                    checked={visibleDataByCategory[key][name]}
                                    onChange={() => handleToggleDtVisibility(name, key)}
                                  />
                                }
                              />
                            ))}
                          </FormGroup>
                        )}
                        {processedDeviceData[`${key}_data`]?.current?.length > 0 ? (
                          <Chart
                            id={key}
                            data={processedDeviceData[`${key}_data`].current}
                            zoomedData={processedDeviceData[`${key}_data`].zoomed}
                            minMaxVisible={minMaxVisible}
                            dataTypes={dataTypes.filter(
                              (dt) => visibleDataByCategory[key][dt.name],
                            )}
                            highlightedAreas={[
                              ...alertsAreas,
                              ...reservationsAreas,
                              ...eventsAreas,
                              ...scenariosAreas,
                            ]}
                            cursorState={[cursor, setCursor]}
                            zoomState={{
                              selectorIdState: [selectorId, setSelectorId],
                              selectorStartState: [selectorStart, setSelectorStart],
                              selectorEndState: [selectorEnd, setSelectorEnd],
                              selectedAreaState: [selectedArea, setSelectedArea],
                              historyRef: selectedAreaHistory,
                            }}
                            settings={{
                              rightAxisProps: { hideAxis: isSmallScreen },
                              bottomAxisProps: { fontSize: isSmallScreen ? 10 : null },
                            }}
                          />
                        ) : (
                          <Typography
                            variant={isSmallScreen ? 'h6' : 'h4'}
                            color="grey"
                            width="100%"
                            textAlign="center"
                          >
                            No Data Available
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        m={5}
                        justifyContent="center"
                      >
                        <Typography variant="h5" mb={1} color="text.secondary">
                          No {titleize(key)} data to display.
                        </Typography>
                      </Box>
                    )}
                  </Box>,
                ]
              }, [])}
          </Box>
        </Box>
      </Box>
    </>
  )
}
