import { any, compose, identity, join, path, prop, toUpper } from 'ramda'
import { createSelector } from 'redux-bundler'

import { camelize, singularize } from 'inflection'

import { isAbortError } from './parseApiErrors'

const defaultEntityState = {
  fetch: {
    current: null,
    entity: null,
    error: null,
    meta: null,
    response: null,
  },
  save: {},
  delete: {},
}

/* underscore(['a', 'b', 'c']) => 'a_b_c' */
const underscore = join('_')

/* constantize(['a', 'b', 'c']) => 'A_B_C' */
const constantize = compose(toUpper, underscore)

/**
 * @typedef {Object} BundleActionConfig
 * @property {string} singularBundleName - The singular name of the bundle
 * @property {string} actionName - The name of the action (e.g., 'fetch', 'save', 'delete')
 * @property {function} prepareData - Function to transform data before sending to handler
 * @property {function} handler - Async action handler for the action
 */
/**
 * build the default actions that an entity bundle comes with
 * @param  {string} apiUrl The path the resource is available at in the API (e.g., devices)
 * @param  {string} singularBundleName The singular name of the bundle
 * @return {BundleActionConfig[]} Corresponding entity actions
 */
const entityBundleActions = (apiUrl, singularBundleName) => [
  {
    singularBundleName,
    actionName: 'fetch',
    prepareData: (payload) => {
      if (typeof payload === 'number' || typeof payload === 'string') {
        return { id: payload }
      }
      return payload
    },
    handler: ({ apiFetch, payload, allowCancelation }) =>
      apiFetch(`/${apiUrl}/${payload.id}/`, payload.params, { allowCancelation }),
  },
  {
    singularBundleName,
    actionName: 'save',
    handler: ({ apiFetch, payload, allowCancelation }) => {
      const isUpdate = Array.isArray(payload)
        ? any(prop('id'), payload)
        : prop('id', payload)
      const method = isUpdate ? 'PUT' : 'POST'
      return apiFetch(`/${apiUrl}/`, payload, { method, allowCancelation })
    },
  },
  {
    singularBundleName,
    actionName: 'delete',
    prepareData: (payload) => {
      if (typeof payload === 'number' || typeof payload === 'string') {
        return { id: payload }
      }
      return payload
    },
    handler: ({ apiFetch, payload, allowCancelation }) =>
      apiFetch(`/${apiUrl}/`, payload, { method: 'DELETE', allowCancelation }),
  },
]

const DEFAULT_ASYNC_ACTIONS = ['start', 'succeed', 'fail']
/**
 * @typedef {Object} AsyncActionCreatorTypes
 * @property {Object} types - Map of step to redux action { start: 'DEVICE_FETCH_START', ... }
 */
/**
 * Given an async action name and a bundle name
 * Return the corresponding action types (start, succeed, fail)
 * @param  {string} actionName (e.g., fetch)
 * @param  {string} singularBundleName (e.g., device)
 * @return {AsyncActionCreatorTypes}
 */
const getAsyncActionTypes = (actionName, singularBundleName) => {
  const typePrefix = constantize([singularBundleName, actionName])
  const asyncActionTypes = DEFAULT_ASYNC_ACTIONS.reduce(
    (acc, asyncAction) => ({
      ...acc,
      [asyncAction]: constantize([typePrefix, asyncAction]),
    }),
    { prefix: typePrefix },
  )
  return asyncActionTypes
}

/**
 * @typedef {Object} AsyncActionCreatorNameAndTypes
 * @property {string} actionCreatorName - The name of the action (doDeviceFetch)
 * @property {Object} types - Map of step to redux action { start: 'DEVICE_FETCH_START', ... }
 */
/**
 * Given an async action name and a bundle name, return the action creator name,
 * and the corresponding action types (start, succeed, fail)
 * @param  {string} actionName (e.g., fetch)
 * @param  {string} singularBundleName (e.g., device)
 * @return {AsyncActionCreatorNameAndTypes}
 */
const getAsyncActionIdentifiers = (actionName, singularBundleName) => ({
  types: getAsyncActionTypes(actionName, singularBundleName),
  actionCreatorName: camelize(underscore(['do', singularBundleName, actionName]), true),
})

/**
 * Given an object of async action types, return reducers for each action
 * @param  {string} actionName (e.g., fetch)
 * @param  {AsyncActionCreatorTypes} types
 * @return {Object} Map of type name (DEVICE_FETCH_START) to reducer function
 */
const defaultAsyncReducersFactory = (actionName, types) => ({
  [types.start]: (state = {}, action = {}) => {
    if (action.type !== types.start) return state

    return {
      ...state,
      [actionName]: {
        ...state[actionName],
        meta: action.meta,
        current: action.payload,
        response: null,
        error: null,
      },
    }
  },
  [types.succeed]: (state = {}, action = {}) => {
    if (action.type !== types.succeed) return state

    return {
      ...state,
      [actionName]: {
        ...state[actionName],
        meta: action.meta,
        response: action.payload,
        error: null,
        entity: 'id' in (action.payload ?? {}) ? action.payload : state.entity,
      },
    }
  },
  [types.fail]: (state = {}, action = {}) => {
    if (action.type !== types.fail || !action.payload) return state

    return {
      ...state,
      [actionName]: {
        ...state[actionName],
        meta: action.meta,
        error: action.payload?.error ?? action.payload,
      },
    }
  },
})

/**
 * Add action creator and reducer into bundle from a BundleActionConfig.
 * Modifies `reducerConfig` and `bundle` in place.
 * An async action refers to an action that involves a request.
 * @param  {BundleActionConfig} actionConfig
 * @param  {Object} reducerConfig
 * @param  {Object} bundle
 * @param {bool} allowCancelation
 */
const addAsyncAction = (actionConfig, reducerConfig, bundle, allowCancelation) => {
  const {
    actionName,
    singularBundleName,
    handler,
    prepareData = identity,
  } = actionConfig

  const identifiers = getAsyncActionIdentifiers(actionName, singularBundleName)
  const { types } = identifiers

  const creator =
    (rawPayload) =>
    async ({ dispatch, ...handlerArgs }) => {
      const payload = prepareData(rawPayload)

      dispatch({
        type: types.start,
        payload,
        meta: { status: 'loading' },
      })

      try {
        const response = await handler({
          ...handlerArgs,
          allowCancelation,
          cancelationPrefix: singularBundleName,
          dispatch,
          payload,
          apiUrl: bundle.apiUrl,
        })

        dispatch({
          type: types.succeed,
          payload: response,
          meta: { status: 'succeeded' },
        })

        return response
      } catch (error) {
        if (!isAbortError(error)) {
          dispatch({
            type: types.fail,
            payload: error,
            meta: { status: 'failed' },
          })

          throw error
        }
        return null
      }
    }

  const actionCreator = Object.assign(creator, identifiers, { handler })
  Object.assign(bundle, { [actionCreator.actionCreatorName]: actionCreator })
  Object.assign(
    reducerConfig,
    defaultAsyncReducersFactory(actionName, actionCreator.types),
  )
}

/**
 * Returns the default selectors for an entity bundle, by action
 * @param  {string[]} allBundleActions - array of action names in the bundle (e.g., ['fetch','save','delete'])
 * @param  {string} singularBundleName (e.g., device)
 */
const entitySelectors = (allBundleActions, singularBundleName) =>
  allBundleActions.reduce(
    (selectors, actionName) => {
      const action = camelize(actionName)
      const selectName = `select${camelize(singularBundleName)}${action}`
      const rootSelectorName = `${selectName}Root`
      const responseSelectorName = `${selectName}Response`
      const statusSelectorName = `${selectName}Status`
      const errorSelectorName = `${selectName}Error`

      return {
        ...selectors,
        [rootSelectorName]: path([singularBundleName, actionName]),
        [selectName]: createSelector(rootSelectorName, prop('entity')),
        [responseSelectorName]: createSelector(rootSelectorName, prop('response')),
        [statusSelectorName]: createSelector(
          rootSelectorName,
          path(['meta', 'status']),
        ),
        [errorSelectorName]: createSelector(rootSelectorName, prop('error')),
      }
    },
    {
      [`select${camelize(singularBundleName)}`]: prop(singularBundleName),
    },
  )

/**
 * Given an entity, create a bundle with appropriate action creators, reducers, selectors.
 * @param  {Object} config
 * @param  {string} config.name - The name of the entity, plural (e.g., devices)
 * @param  {string} [config.singularBundleName=singularize(name)] - The name of the entity, singular (e.g., device)
 * @param  {string} [config.apiUrl=name] - The path the resource is available at in the API (e.g., devices)
 * @param  {Object[]} [config.customActions=[]] - Custom actions for entity bundle in addition to default ones
 * @param {bool} [config.allowCancelation=true] - Cancel a pending request if the new one is started
 */
const createEntityBundle = (config) => {
  const {
    name,
    singularBundleName = singularize(name),
    customActions = [],
    apiUrl = name,
    allowCancelation = true,
    ...bundleProps
  } = config

  const bundle = { name, apiUrl }
  const reducerConfig = {}

  const bundleActions = entityBundleActions(apiUrl, singularBundleName)

  const allBundleActions = []

  bundleActions.forEach((actionConfig) => {
    allBundleActions.push(actionConfig.actionName)
    addAsyncAction({ ...actionConfig }, reducerConfig, bundle, allowCancelation)
  })

  customActions.forEach((actionConfig) => {
    allBundleActions.push(actionConfig.actionName)
    defaultEntityState[actionConfig.actionName] = {}
    addAsyncAction(
      { ...actionConfig, singularBundleName },
      reducerConfig,
      bundle,
      allowCancelation,
    )
  })

  const finalBundle = {
    ...bundle,
    ...bundleProps,
    reducer: (state = defaultEntityState, action = {}) => {
      const reducer = reducerConfig[action.type]
      return reducer ? reducer(state, action) : state
    },
    ...entitySelectors(allBundleActions, singularBundleName),
    name: singularBundleName,
  }

  return finalBundle
}

export default createEntityBundle
