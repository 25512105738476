import { useCallback, useMemo, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { FormikDynamicSelect, StaticSelect } from '@common/components/Selects'
import { getApiFetch, parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.data]
 * @param {string} props.data.id
 * @param {string} [props.data.alertingSmokeProfileEnsemble]
 * @param {string[]} props.data.shadowSmokeProfiles
 */
export default function ModifyProfileForm({ open, onClose, data = null }) {
  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const [formikProps, setFormikProps] = useState({
    values: {},
    errors: {},
    setFieldValue: () => {},
  })

  const { systemSmokeProfileEnsembles, doShowSnackbar } = useConnect(
    'selectSystemSmokeProfileEnsembles',
    'doShowSnackbar',
  )

  const initialValues = {
    id: data?.id,
    alertingSmokeProfileEnsemble: data?.alertingSmokeProfileEnsemble,
    shadowSmokeProfiles: data?.shadowSmokeProfiles ?? [],
  }

  const validationSchema = yup.object().shape({
    id: yup.string().uuid(),
    alertingSmokeProfileEnsemble: yup.string().uuid().nullable(),
    shadowSmokeProfiles: yup.array().of(yup.string().uuid()),
  })

  const handleSave = useCallback(
    async ({
      id,
      alertingSmokeProfileEnsemble,
      shadowSmokeProfiles,
      secondOpinionSmokeProfile,
    }) => {
      try {
        await apiFetch(
          '/properties/smoke_profiles/',
          {
            id,
            alertingSmokeProfileEnsemble,
            shadowSmokeProfiles,
            secondOpinionSmokeProfile,
          },
          { method: 'PUT', cancelationPrefix: 'modify_profile_form' },
        )
        doShowSnackbar(`Property profiles have been updated`, 'success')
        onClose(true)
      } catch (err) {
        const errorMessage = parseApiErrors(err)
        doShowSnackbar(errorMessage, 'error')
      }
    },
    [data],
  )

  const formatFieldVlaue = useCallback(
    (option) => (option ? `${option.modelKey} - ${option.name}` : option),
    [],
  )

  const ensemblesOptions = useMemo(
    () =>
      systemSmokeProfileEnsembles
        ?.filter((profile) =>
          ['AVAILABLE', 'DEFAULT_ALERTING'].includes(profile.status),
        )
        ?.map((profile) => ({ id: profile.id, label: profile.name })),
    [systemSmokeProfileEnsembles],
  )

  return (
    <FormDialog
      label="Edit Property Profiles"
      open={open}
      onSave={handleSave}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box>
        <Field
          fullWidth
          alwaysShowPlaceholder
          name="alertingSmokeProfileEnsemble"
          label="Alerting Smoke Profile Ensemble"
          variant="standard"
          component={StaticSelect}
          options={ensemblesOptions}
          placeholder="Default"
          inputStyle={{ '& ::placeholder': { fontStyle: 'italic' } }}
        />
        <Field
          fullWidth
          multiple
          disableCloseOnSelect
          component={FormikDynamicSelect}
          useValueInRequest={false}
          label="Shadow Smoke Profiles"
          name="shadowSmokeProfiles"
          formatTagValue={formatFieldVlaue}
          value={formikProps.values.shadowSmokeProfiles}
          showError={!!formikProps.errors.shadowSmokeProfiles}
          error={formikProps.errors.shadowSmokeProfiles}
          endpoint="smoke_profiles"
          secondaryTextAttr="modelKey"
          filters={{ status: 'AVAILABLE' }}
          cancelationPrefix="shadow"
          sx={{ mt: 2 }}
        />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
