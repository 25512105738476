import { memo, useMemo, useState } from 'react'

import { equals, pipe, replace, toUpper, trim } from 'ramda'

import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'
import { Button, Menu, MenuItem, Stack, ToggleButton, Typography } from '@mui/material'

import { humanize, pluralize, titleize } from 'inflection'

import { StaticMultiSelect } from '@common/components/Selects'

export const formatEntity = pipe(replace(/([A-Z])/g, ' $1'), toUpper, trim)

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.entityMap
 * @param {Function} [props.entityMap.filter]
 * @param {Object[]} [props.entityMap.current]
 * @param {Function} [props.entityMap.fetch]
 * @param {Object[]} [props.entityMap.selected]
 * @param {Function} [props.entityMap.setSelected]
 * @param {Function} [props.entityMap.groupBy]
 * @param {string} props.currentEntity
 * @param {boolean} props.disabled
 * @param {Function} props.onEntityTypeChange
 * @param {Function} props.onEntitiesChange
 * @param {Function} props.onOptionChanged
 * @param {Function} props.onSave
 * @param {Function} props.onOpen
 */
const EntitySelector = memo(
  ({
    entityMap,
    currentEntity,
    disabled,
    onEntityTypeChange,
    onEntitiesChange,
    onOptionChanged,
    onSave,
    onOpen,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const MIN_WIDTH = 400

    const getButtonTitle = (ent) => pluralize(formatEntity(ent))

    const options = useMemo(() => {
      const entityData = entityMap[currentEntity]
      return entityData.filter
        ? entityData.current?.filter(entityData.filter)
        : entityData.current
    }, [entityMap, currentEntity])

    return (
      <>
        <Menu
          id="entity-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ vertical: -6, horizontal: 'left' }}
        >
          {Object.keys(entityMap).map((option) => (
            <MenuItem
              key={option}
              selected={option === currentEntity}
              disabled={disabled}
              onClick={() => {
                onEntityTypeChange(option)
                setAnchorEl(null)
              }}
              sx={{ width: anchorEl?.offsetWidth }}
            >
              {humanize(getButtonTitle(option))}
            </MenuItem>
          ))}
        </Menu>

        <Stack direction="column" spacing={1}>
          <ToggleButton
            selected={!!anchorEl}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            disabled={disabled}
            value=""
            sx={{ py: 0.4, fontSize: 12 }}
          >
            {getButtonTitle(currentEntity)}
            {anchorEl ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />}
          </ToggleButton>
          <StaticMultiSelect
            required
            onlyButton
            disableSaveOnClear // stops refetching/setting old data when cleared
            label={titleize(pluralize(formatEntity(currentEntity)))}
            onSave={onSave}
            onOpen={onOpen}
            onChange={onEntitiesChange}
            options={options}
            value={entityMap[currentEntity].selected || []}
            groupBy={entityMap[currentEntity].groupBy}
            sortByFields={entityMap[currentEntity].sortByFields}
            disabled={disabled}
            size="small"
            sx={{ minWidth: MIN_WIDTH }}
            renderGroup={(params) => (
              <li key={params.key}>
                <Typography
                  variant="h6"
                  sx={{
                    px: 2,
                    py: 1,
                    display: 'flex',
                    '&:hover > button': { visibility: 'visible' },
                    backgroundColor: 'primary.main',
                    color: 'white',
                  }}
                >
                  {params.group}
                  <Button
                    variant="text"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      entityMap[currentEntity].setSelected(
                        options.filter((ent) =>
                          equals(entityMap[currentEntity].groupBy(ent), params.group),
                        ),
                      )
                      onOptionChanged()
                    }}
                    sx={{
                      marginLeft: 'auto',
                      visibility: 'hidden',
                      color: 'white',
                    }}
                  >
                    only
                  </Button>
                </Typography>
                <ul style={{ padding: 0 }}>{params.children}</ul>
              </li>
            )}
            limitTags={1}
          />
        </Stack>
      </>
    )
  },
)

export default EntitySelector
