import { useEffect, useRef } from 'react'

import { equals } from 'ramda'
import { useReduxBundlerStore } from 'redux-bundler-hook'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function formatQueryParams(params) {
  const result = {}
  const isObject = (value) =>
    value && typeof value === 'object' && value.constructor === Object

  Object.keys(params).forEach((key) => {
    const value = params[key]

    if (isObject(value)) {
      result[key] = formatQueryParams(value)
    } else if (typeof value === 'boolean') {
      result[key] = value
    } else if (
      typeof value === 'string' &&
      ['true', 'false'].includes(value.toLowerCase())
    ) {
      result[key] = value.toLowerCase() === 'true'
    } else {
      result[key] = !isNaN(value) ? parseInt(value, 10) : value
    }
  })
  return result
}

export default function useQueryFilter({
  query,
  apiParams,
  setFilter,
  setPageSize,
  defaultParams,
  ignoreIf,
}) {
  const previousData = usePrevious({ query, apiParams })
  const store = useReduxBundlerStore()

  const initialParams = defaultParams ?? { page: 1, pageSize: 25, active: true }

  const pushParams = (params) => {
    store.doUpdateQuery(params)
    setFilter(formatQueryParams(params))
    if (params.pageSize) {
      setPageSize(params.pageSize)
    }
  }

  useEffect(() => {
    if (ignoreIf && ignoreIf(query)) {
      return
    }

    const formattedQuery = formatQueryParams(query)
    const formattedApiParams = formatQueryParams(apiParams)

    if (!previousData) {
      const hasInitialQuery = Object.keys(formattedQuery).length > 0
      pushParams(hasInitialQuery ? formattedQuery : initialParams)
      return
    }

    if (!equals(formattedQuery, formattedApiParams)) {
      let params
      if (!equals(previousData?.query, query)) {
        params = query
      } else if (!equals(previousData?.apiParams, apiParams)) {
        params = apiParams
      }
      if (params) {
        pushParams(params)
      }
    }
  }, [query, apiParams])
}
