import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'

import { BoldText } from '@common/components'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.deleteOpen
 * @param {Function} props.setDeleteOpen
 * @param {Object} props.profile
 * @param {string} [props.profile.id]
 * @param {string} [props.profile.name]
 * @param {Object[]} [props.profile.assignedUnits]
 */
export default function DeleteInterrupt({ profile, deleteOpen, setDeleteOpen }) {
  return (
    <Dialog
      open={deleteOpen}
      onClose={() => setDeleteOpen(false)}
      disableScrollLock
      maxWidth="xs"
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 4, px: 4 }}
      >
        Delete Threshold Profile
        <IconButton
          onClick={() => setDeleteOpen(false)}
          sx={{ padding: 0, width: '32px' }}
        >
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" sx={{ mx: 4 }} />
      <DialogContent sx={{ py: 0, px: 4, maxWidth: '400px' }}>
        <Typography sx={{ my: 2.5 }}>
          The threshold profile <BoldText>{profile?.name}</BoldText> cannot be deleted
          because it is in use.
        </Typography>
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}
      >
        <Button
          color="primary"
          variant="contained"
          fullWidth
          sx={{ mb: 2 }}
          onClick={() => setDeleteOpen(false)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
