import { Link, Typography } from '@mui/material'

import ListTextTooltipWrapper from '@common/components/ListTextTooltipWrapper'
import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.label]
 * @param {string} props.url
 * @param {Function} [props.renderNoValue]
 */
export default function ClickableCell({
  label = '',
  url,
  renderNoValue = undefined,
  ...params
}) {
  const isSmallScreen = useSmallScreen()
  const fontSize = isSmallScreen ? 12 : 14

  if (label) {
    return (
      <ListTextTooltipWrapper tooltip={label}>
        <Link noWrap href={url} onClick={(e) => e.stopPropagation()}>
          <Typography noWrap fontSize={fontSize} {...params}>
            {label}
          </Typography>
        </Link>
      </ListTextTooltipWrapper>
    )
  }
  return renderNoValue ? (
    renderNoValue()
  ) : (
    <Typography variant={isSmallScreen ? 'caption' : 'body1'} fontSize={fontSize}>
      --
    </Typography>
  )
}
