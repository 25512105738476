import { createListBundle, defaultInitialState, getEntityFromUrl } from '@common/utils'

import unitUrls from './urls'

const UNIT_LIST_BULK_SNOOZE_STARTED = 'UNIT_LIST_BULK_SNOOZE_STARTED'
const UNIT_LIST_BULK_SNOOZE_SUCCEEDED = 'UNIT_LIST_BULK_SNOOZE_SUCCEEDED'
const UNIT_LIST_BULK_SNOOZE_FAILED = 'UNIT_LIST_BULK_SNOOZE_FAILED'

const entityName = 'unit'

const unitListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/units/',
      {
        ...params,
        ...(entityType && entityId && { [entityType]: entityId }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['name', 'asc'],
  },
  urlTest: (url, _, hash) => url === unitUrls.list || unitUrls.tabHash.includes(hash),
})

export default {
  ...unitListBundle,
  doUnitListBulkUpdateSnooze:
    ({ ids, snooze }) =>
    async ({ apiFetch, dispatch }) => {
      try {
        dispatch({ type: UNIT_LIST_BULK_SNOOZE_STARTED })

        const snoozePayload = ids.map((id) => ({ id, snooze }))
        await apiFetch('/units/', snoozePayload, {
          method: 'PUT',
          cancelationPrefix: entityName,
        })

        return dispatch({ type: UNIT_LIST_BULK_SNOOZE_SUCCEEDED })
      } catch (err) {
        dispatch({ type: UNIT_LIST_BULK_SNOOZE_FAILED })
        return err
      }
    },
}
