import { useCallback } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Close as CloseIcon } from '@mui/icons-material'
import {
  Alert,
  Box,
  IconButton,
  Portal,
  Slide,
  Snackbar as MuiSnackbar,
} from '@mui/material'

import { Project } from '@common/config'

function Snackbar() {
  const { isRest } = Project

  const {
    snackbar: { message, open, severity },
    doCloseSnackbar,
  } = useConnect('selectSnackbar', 'doCloseSnackbar')

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={doCloseSnackbar}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  const Transition = useCallback(
    (props) => <Slide {...props} direction={isRest ? 'up' : 'left'} />,
    [],
  )

  const finalMessage =
    !message && severity === 'error' ? 'Oops. Something went wrong' : message

  return (
    <Portal>
      <MuiSnackbar
        data-testid="snackbar"
        open={open}
        anchorOrigin={
          isRest
            ? { vertical: 'bottom', horizontal: 'center' }
            : { vertical: 'top', horizontal: 'right' }
        }
        autoHideDuration={isRest ? 3500 : 2000}
        action={action}
        onClose={doCloseSnackbar}
        TransitionComponent={Transition}
      >
        <Alert
          onClose={doCloseSnackbar}
          severity={severity}
          sx={
            isRest
              ? { width: '100%', bgcolor: 'black', color: 'muted.contrastText' }
              : null
          }
        >
          <Box whiteSpace="pre-line">{finalMessage}</Box>
        </Alert>
      </MuiSnackbar>
    </Portal>
  )
}

export default Snackbar
