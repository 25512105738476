import { createEntityBundle } from '@common/utils'

const entityName = 'accounts'

const accountBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'fetch_v1',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload}/v1_org/`,
          {},
          { method: 'GET', cancelationPrefix: entityName },
        ),
    },
    {
      actionName: 'fetch_salesforce',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload}/salesforce_account/`,
          {},
          { method: 'GET', cancelationPrefix: entityName },
        ),
    },
    {
      // https://stripe.com/docs/api/customers/object?lang=python
      actionName: 'fetch_stripe',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload}/stripe_customer/`,
          {},
          { method: 'GET', cancelationPrefix: entityName },
        ),
    },
    {
      actionName: 'move',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload.id}/move/`, payload.payload, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
  ],
})

export default accountBundle
