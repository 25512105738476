import { useMemo, useState } from 'react'

import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, FormControl, MenuItem, Select, Tab, Tabs } from '@mui/material'

import { useSmallScreen } from '@common/utils'

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {React.ReactNode} props.children
 * @param {number} props.index
 * @param {number} props.value
 */
function TabPanel({ children, value, index, ...other }) {
  return (
    <Box
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      role="tabpanel"
      hidden={value !== index}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ ml: 2 }}>{children}</Box>}
    </Box>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.tabs
 * @param {string} props.tabs[].label
 * @param {Function} props.tabs[].component
 * @param {Function} [props.tabs[].onEdit]
 * @param {number} [props.tabsMinWidth]
 */
export default function VerticalTabs({ tabs, tabsMinWidth = undefined, ...rest }) {
  const [value, setValue] = useState(0)

  const isSmallScreen = useSmallScreen()

  const enabledTabs = useMemo(() => tabs.filter((tab) => !tab?.disabled), [tabs])

  return (
    <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'}>
      {isSmallScreen ? (
        <FormControl fullWidth size="small" sx={{ my: 1 }}>
          <Select
            fullWidth
            value={value}
            IconComponent={KeyboardArrowDown}
            onChange={(event) => setValue(event.target.value)}
          >
            {tabs.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label} View
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Tabs
          orientation="vertical"
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          sx={{
            minWidth: tabsMinWidth,
            borderRight: 1,
            borderColor: 'divider',
            '&& .MuiTab-root': { alignItems: 'baseline' },
          }}
        >
          {enabledTabs.map((tab, index) => (
            <Tab
              label={tab.label}
              key={tab.label}
              sx={{ textAlign: 'start' }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      )}
      {enabledTabs.map((tab, index) => (
        <TabPanel value={value} key={tab.label} index={index}>
          <Box {...rest}>{tab.component}</Box>
        </TabPanel>
      ))}
    </Box>
  )
}
