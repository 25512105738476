import { useCallback, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material'
import { Box, Chip, Menu, MenuItem, Typography } from '@mui/material'

import { humanize } from 'inflection'

import { DateSelectionModal } from '@common/components'
import { formatDateRangeOrdinal } from '@common/utils'
import {
  getMetricsRequestTimeframe,
  METRICS_30_DAYS_INTERVAL,
  METRICS_7_DAYS_INTERVAL,
  METRICS_CUSTOM_INTERVAL,
  METRICS_MTD_INTERVAL,
  METRICS_PREV_MONTH_INTERVAL,
} from '@common/utils/propertyDetailMetricsUtils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.intervalData
 * @param {String} props.intervalData.interval
 * @param {Object} props.currentFilter
 * @param {string} [props.currentFilter.reason]
 * @param {Object} props.reasonsById
 * @param {Object[]} props.intervalData.range
 * @param {Function} props.onChange
 */
export default function EventsIntervalPicker({
  intervalData,
  currentFilter,
  reasonsById,
  onChange,
}) {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [customDateSelectorOpen, setCustomDateSelectorOpen] = useState(false)

  const { propertyEventListIsLoading, doPropertyEventListSetFilter } = useConnect(
    'selectPropertyEventListIsLoading',
    'doPropertyEventListSetFilter',
  )

  const SelectorArrow = menuAnchor ? ArrowDropUpRounded : ArrowDropDownRounded

  const intervalOptions = [
    METRICS_7_DAYS_INTERVAL,
    METRICS_30_DAYS_INTERVAL,
    METRICS_MTD_INTERVAL,
    METRICS_PREV_MONTH_INTERVAL,
    METRICS_CUSTOM_INTERVAL,
  ]

  const formattedCustomRange = useMemo(() => {
    if (intervalData.interval === METRICS_CUSTOM_INTERVAL) {
      return formatDateRangeOrdinal(intervalData.range)
    }
    return null
  }, [intervalData])

  const getFormattedReason = useCallback(
    (reason) => reasonsById[reason] || humanize(reason),
    [reasonsById],
  )

  const FilterChip = useCallback(
    () => (
      <Chip
        component="span"
        label={getFormattedReason(currentFilter.reason)}
        size="small"
        onDelete={() => {
          doPropertyEventListSetFilter({ ...currentFilter, id: null, reason: null })
        }}
      />
    ),
    [currentFilter],
  )

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setMenuAnchor(null)}
      >
        {intervalOptions.map((option) => (
          <MenuItem
            key={option}
            selected={option === intervalData.interval}
            onClick={() => {
              if (option !== METRICS_CUSTOM_INTERVAL) {
                const range = getMetricsRequestTimeframe(option)
                onChange({ interval: option, range: [range.startTime, range.endTime] })
              } else {
                setCustomDateSelectorOpen(true)
              }
              setMenuAnchor(null)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      <DateSelectionModal
        open={customDateSelectorOpen}
        onCancel={() => setCustomDateSelectorOpen(false)}
        onSave={(range) => {
          onChange({ interval: METRICS_CUSTOM_INTERVAL, range })
          setCustomDateSelectorOpen(false)
        }}
      />
      <Box display="flex" alignItems="center">
        <Typography
          color={propertyEventListIsLoading ? 'grey.500' : null}
          textAlign="center"
        >
          Viewing events from the{' '}
          <Box
            data-testid="interval_picker"
            component="span"
            fontWeight={600}
            display="inline-flex"
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            sx={{ cursor: propertyEventListIsLoading ? 'default' : 'pointer' }}
          >
            {intervalData.interval}
            {formattedCustomRange ? ` (${formattedCustomRange})` : null}
            <SelectorArrow
              sx={{ color: propertyEventListIsLoading ? 'grey.500' : null }}
            />
          </Box>
        </Typography>
        {currentFilter?.reason && (
          <Box component="span" display="inline-flex" alignItems="center" gap={1}>
            with adjustment
            <FilterChip />
          </Box>
        )}
      </Box>
    </>
  )
}
