import { createEntityBundle, isAbortError } from '@common/utils'

const entityName = 'properties'

const propertyBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'fetch_all',
      handler: ({ apiFetch, payload }) => {
        const { group } = payload ?? {}
        try {
          return apiFetch(
            '/properties/',
            { pageSize: 999, ...(group ? { group } : {}) },
            { method: 'GET', cancelationPrefix: entityName },
          )
        } catch (err) {
          if (!isAbortError(err)) throw err
          return null
        }
      },
    },
    {
      actionName: 'fetch_card_summaries',
      handler: ({ apiFetch }) => {
        try {
          return apiFetch('/properties/card_summaries/', null, {
            method: 'GET',
            cancelationPrefix: entityName,
          })
        } catch (err) {
          if (!isAbortError(err)) throw err
          return null
        }
      },
    },
  ],
})

export default propertyBundle
