import { ReportProblemOutlined } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

import { capitalize } from 'inflection'

import { Project } from '@common/config'

const errorMessageStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: Project.isRest ? 'calc(100vh - 143px)' : '100vh',
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.title
 * @param {string} [props.buttonTitle]
 * @param {Function} props.callback
 */
export function ErrorComponent({ callback, title, buttonTitle = 'Go Back' }) {
  return (
    <Box m={5}>
      <Typography variant="h3" mb={2}>
        {' '}
        {capitalize(title)}{' '}
      </Typography>
      <Box m={10} align="center">
        <Typography mb={2}>
          We seem to have encountered a problem. Please go back and try again.
        </Typography>
        <Button variant="contained" color="error" onClick={callback}>
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.children
 */
export function ErrorMessage({ children }) {
  return (
    <div style={errorMessageStyles}>
      <ReportProblemOutlined fontSize="inherit" style={{ fontSize: 75 }} />
      <Typography variant="h4">{children}</Typography>
    </div>
  )
}
