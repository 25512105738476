import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import FormDialog from '@portal/UI/components/FormDialog'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} [props.instance.id]
 * @param {string} [props.instance.name]
 * @param {number} [props.instance.zoneSize]
 * @param {string} [props.instance.unit]
 */
export default function ZoneForm({ open, onClose, instance = {} }) {
  const { doZoneSave } = useConnect('doZoneSave')

  const validationSchema = yup.object().shape({
    name: yup.string().max(25).required(),
    zoneSize: yup.number().integer().positive().nullable(true),
  })

  const initialValues = {
    id: '',
    name: '',
    zoneSize: '',
    unit: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const saveZone = async (zone) => {
    const payload = {}

    Object.keys(zone).forEach((field) => {
      const fieldValue = zone[field]

      if (fieldValue === '') {
        const schemaField = validationSchema.fields[field]

        if (schemaField?.spec?.nullable) {
          payload[field] = null
        }
      } else {
        payload[field] = fieldValue
      }
    })

    return doZoneSave(payload)
  }

  return (
    <FormDialog
      label={`${initialValues?.id ? 'Edit' : 'Add'} Zone`}
      open={open}
      onSave={saveZone}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '2rem' }}>
        <Field component={TextField} label="Name" name="name" />
        <Field component={TextField} label="Size (in sq ft)" name="zoneSize" />
      </Box>
    </FormDialog>
  )
}
