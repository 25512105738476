import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import { createListBundle, defaultInitialState, isAbortError } from '@common/utils'

import orgUrls from './urls'

const ORGANIZATION_LIST_FETCH_ALL = 'ORGANIZATION_LIST_FETCH_ALL'

const entityName = 'organization'

const organizationListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/organizations/', params, { cancelationPrefix: entityName }),
  initialState: {
    ...defaultInitialState,
    ordering: ['deviceCount', 'desc'],
  },
  urlTest: (url) => url === orgUrls.list,
})

export default {
  ...organizationListBundle,
  reducer: reduceReducers(organizationListBundle.reducer, (state, action) => {
    switch (action.type) {
      case ORGANIZATION_LIST_FETCH_ALL:
        return { ...state, allOrganizations: action.payload }
      default:
        return state
    }
  }),
  doOrganizationListFetchAll:
    (payload) =>
    async ({ apiFetch, dispatch }) => {
      try {
        const response = await apiFetch(
          `/organizations/`,
          { ordering: 'name', pageSize: 500, ...payload },
          { method: 'GET', cancelationPrefix: entityName },
        )
        return dispatch({
          type: ORGANIZATION_LIST_FETCH_ALL,
          payload: response.results,
        })
      } catch (err) {
        if (!isAbortError(err)) return err
        return null
      }
    },
  selectAllOrganizations: createSelector(
    'selectOrganizationListRaw',
    (orgListRaw) => orgListRaw?.allOrganizations,
  ),
}
