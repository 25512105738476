import { useEffect, useState } from 'react'

import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'

import * as yup from 'yup'

import { debounce, getApiFetch, isAbortError, parseApiErrors } from '@common/utils'

const debouncedSearch = debounce((fn, value) => fn(value), 500)

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.form]
 * @param {Function} [props.form.setFieldValue]
 * @param {Function} [props.form.setFieldError]
 * @param {Object} [props.form.errors]
 * @param {Object} [props.form.touched]
 * @param {Object} [props.field]
 * @param {string} [props.field.name]
 * @param {Object[]} [props.field.value]
 * @param {boolean} [props.disabled]
 * @param {string} props.label
 * @param {Object} [props.sx]
 */
export default function UsersEmailSelector({
  form = '',
  label,
  field = '',
  disabled = false,
  sx = {},
  ...rest
}) {
  const { doShowSnackbar } = useConnect('doShowSnackbar')

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const fetchOptions = async (text) => {
    setLoading(true)
    try {
      const res = await apiFetch(
        '/users/',
        { pageSize: 50, ...{ search: text, active: true } },
        { method: 'GET', cancelationPrefix: 'email_selector' },
      )
      if (res?.results) setOptions(res.results)
    } catch (err) {
      if (!isAbortError) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOptions(null)
  }, [])

  const handleChange = (event, value) => {
    if (!event || event?.type === 'blur') return
    const processedData = value.map((item) =>
      typeof item === 'string'
        ? { email: item }
        : { email: item.email, name: item.name },
    )
    const schema = yup.string().email()
    const invalidEmail = processedData.some((data) => !schema.isValidSync(data.email))
    if (invalidEmail) {
      form?.setFieldError(field?.name, 'Entered email is not valid')
    } else {
      form?.setFieldValue(field?.name, processedData)
    }
  }

  const isOptionEqualToValue = (opt, val) => {
    if (!val) return false
    return (opt?.email ?? opt) === val.email
  }

  const handleInputChange = async (event, value) => {
    if (!event || event?.type === 'click') return
    form?.setFieldError(field?.name, null)

    debouncedSearch(fetchOptions, value)
  }

  const handleDropDown = async () => fetchOptions(null)

  const errorData = form.errors[field.name]
  const helperText = Array.isArray(errorData)
    ? errorData?.map((item) => Object.values(item).join(',')).join(',')
    : errorData

  const value = field?.value ?? []

  return (
    <Autocomplete
      freeSolo
      multiple
      forcePopupIcon
      disabled={disabled}
      value={value}
      onOpen={handleDropDown}
      getOptionLabel={(option) => option.email}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options ?? []}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={rest.required}
          label={label}
          margin="normal"
          variant="standard"
          error={!!errorData}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            required: value.length === 0,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{ m: 0 }}
        />
      )}
      renderOption={(optProps, option) => (
        <li {...optProps} key={option.id}>
          <Box>
            <Typography variant="h6">{option.email}</Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              textTransform="uppercase"
              fontSize="0.7rem"
            >
              {option.name}
            </Typography>
          </Box>
        </li>
      )}
      sx={sx}
      {...rest}
    />
  )
}
