import { useState } from 'react'

import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { DateTime } from 'luxon'

import DeleteModal from '@portal/UI/components/DeleteModal'

const formatDate = (date, format = DateTime.DATETIME_MED_WITH_SECONDS) =>
  date ? DateTime.fromISO(date).toLocaleString(format) : 'N/A'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.currentImage
 * @param {string} props.currentImage.id
 * @param {string} props.currentImage.photoUrl
 * @param {string} props.currentImage.deviceMac
 * @param {string} props.currentImage.unitName
 * @param {boolean} [props.currentImage.reviewed]
 * @param {string} [props.currentImage.reviewedByName]
 * @param {string} [props.currentImage.reviewedOn]
 * @param {Function} props.setCurrentImage
 * @param {boolean} props.isAtLeastAdmin
 * @param {Function} props.doInstallationListVerify
 * @param {Function} props.doInstallationListRemove
 */
export default function PhotoDialog({
  currentImage,
  setCurrentImage,
  isAtLeastAdmin,
  doInstallationListVerify,
  doInstallationListRemove,
}) {
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)

  return (
    <Dialog
      onClose={() => setCurrentImage(null)}
      open
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <Card>
        <CardContent sx={{ pb: 1 }}>
          <Typography variant="h5">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <span>{currentImage.deviceMac}</span>
              <span>{currentImage.unitName}</span>
            </Stack>
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            {currentImage.reviewed ? (
              <Typography variant="body">{`Reviewed By ${
                currentImage.reviewedByName
              } on ${formatDate(
                currentImage.reviewedOn,
                DateTime.DATETIME_SHORT,
              )}`}</Typography>
            ) : (
              <Button
                size="small"
                variant="contained"
                color="primary"
                sx={{ my: 1 }}
                disabled={!isAtLeastAdmin}
                onClick={async () => {
                  await doInstallationListVerify(currentImage.id)
                  setCurrentImage(null)
                }}
              >
                Verify Installation
              </Button>
            )}
            <Button
              size="small"
              color="error"
              variant="contained"
              sx={{ my: 1 }}
              disabled={!isAtLeastAdmin}
              onClick={() => {
                setDeleteFormOpen(true)
              }}
            >
              Delete Photo
            </Button>
          </Stack>
        </CardContent>
        <CardMedia
          component="img"
          image={currentImage.photoUrl}
          alt={currentImage.deviceMac}
        />
      </Card>
      <DeleteModal
        open={deleteFormOpen}
        onConfirmDelete={async () => {
          await doInstallationListRemove(currentImage.id)
          setCurrentImage(null)
        }}
        onCancelDelete={() => {
          setDeleteFormOpen(false)
        }}
      />
    </Dialog>
  )
}
