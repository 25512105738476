/* eslint-disable no-unused-vars */
import { memo } from 'react'

import { equals } from 'ramda'

import HorizontalThresholdedLine from '../components/HorizontalThresholdedLine'

function propsAreEqual(prev, next) {
  return (
    prev.id === next.id &&
    equals(prev.data, next.data) &&
    prev.color === next.color &&
    prev.width === next.width &&
    prev.height === next.height &&
    prev.curve === next.curve &&
    prev.yScale === next.yScale &&
    prev.upperBound === next.upperBound &&
    equals(prev.padding, next.padding)
  )
}

/**
 * 'padding' and 'yScale' not used directly in the component
 * but only in `propsAreEqual` function to properly rerender component
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Object[]} props.data
 * @param {string} props.color
 * @param {Function} props.x
 * @param {Function} props.y
 * @param {number} props.upperBound
 * @param {Function} props.curve
 * @param {number} props.height
 * @param {number} props.width
 * @param {Function} props.defined
 * @param {Function} props.yScale
 * @param {Object} [props.padding]
 */
const MemoHorizontalThresholdedLine = memo(
  ({
    id,
    data,
    color,
    x,
    y,
    upperBound,
    height,
    width,
    curve,
    defined,
    padding = undefined,
    yScale,
  }) => (
    <HorizontalThresholdedLine
      id={id}
      data={data}
      color={color}
      x={x}
      y={y}
      upperBound={upperBound}
      height={height}
      width={width}
      curve={curve}
      defined={defined}
    />
  ),
  propsAreEqual,
)

export default MemoHorizontalThresholdedLine
