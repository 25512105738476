import { Box, Link, Typography } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {string} props.value
 * @param {string} [props.linkTo]
 */
export default function HeaderItem({ label, value, linkTo = undefined }) {
  return (
    <Typography variant="body2">
      <Box component="span" fontWeight="fontWeightMedium" display="block">
        {label}
      </Box>
      {linkTo ? <Link href={linkTo}>{value}</Link> : value}
    </Typography>
  )
}
