import { useEffect, useState } from 'react'

import { useReduxBundlerStore } from 'redux-bundler-hook'

import { Assessment as AssessmentIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { DateTime } from 'luxon'

import { Loading } from '@common/components'
import { getApiFetch, isAbortError } from '@common/utils'

const formatDate = (date) => date.toLocaleString(DateTime.DATETIME_MED)

const SHOW_PARENT = false

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {string} props.zone
 * @param {Function} props.onReportClick
 * @param {boolean} props.isPrunit
 * @param {boolean} [props.mobileView]
 */
export default function PropertyEventListRowDetail({
  mobileView = false,
  zone,
  isPrunit,
  onReportClick,
  ...parentEvent
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [events, setEvents] = useState([])

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  useEffect(() => {
    async function fetchChildrenEvents() {
      try {
        setIsLoading(true)

        const response = await apiFetch(
          '/events/',
          {
            parentEvent: parentEvent.id,
            includeChildEvents: 1,
            ordering: 'createdOn',
          },
          { cancelationPrefix: 'row_detail' },
        )
        if (response?.results) {
          if (SHOW_PARENT) {
            setEvents([parentEvent, ...response.results])
          } else {
            setEvents(response.results)
          }
        } else {
          setEvents([])
        }

        setIsLoading(false)
      } catch (e) {
        if (!isAbortError(e)) {
          setEvents(false)
          setIsLoading(false)
        }
      }
    }
    fetchChildrenEvents()
  }, [])

  if (isLoading) {
    return (
      <Box sx={{ p: 3, minHeight: 150 }}>
        <Loading height={150} />
      </Box>
    )
  }

  if (events === false || events.length === 0 || !events) {
    return <Box sx={{ p: 3 }}>Something went wrong. Please contact support.</Box>
  }

  return (
    <Box
      data-testid="event_details_row"
      sx={{
        ...(!mobileView
          ? {
              pl: 7,
              pb: 3,
              pr: 1,
              minHeight: 175,
            }
          : {}),
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <h5>{SHOW_PARENT ? 'Occurrences' : 'Additional Occurrences'}</h5>
      <TableContainer data-testid="events_table" component={Paper}>
        <Table>
          <TableHead>
            <TableRow data-testid="events_table_header">
              {!isPrunit ? <TableCell>Unit</TableCell> : null}
              <TableCell align={isPrunit ? 'left' : 'right'}>Detected On</TableCell>
              <TableCell align="right">Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="events_table_rows">
            {events.map((ev, index) => (
              <TableRow
                key={ev.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {!isPrunit ? (
                  <TableCell component="th" scope="row">
                    {ev.unitName}
                  </TableCell>
                ) : null}
                <TableCell align={isPrunit ? 'left' : 'right'}>
                  {formatDate(DateTime.fromISO(ev.createdOn, { zone }))}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    startIcon={<AssessmentIcon />}
                    size="small"
                    color="secondary"
                    onClick={() => onReportClick(ev, index)}
                    disabled={!ev.endTime}
                  >
                    Report
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
