import { createEntityBundle } from '@common/utils'

const entityName = 'properties'

const propertyBundle = createEntityBundle({
  name: entityName,
  customActions: [
    {
      actionName: 'import_csv',
      handler: async ({ apiFetch, payload }) => {
        const data = payload.data?.reduce(
          (acc, row) => [
            ...acc,
            row.propertyType
              ? { ...row, propertyType: row.propertyType.toUpperCase() }
              : row,
          ],
          [],
        )

        return apiFetch(`/accounts/${payload?.account}/property_csv/`, data, {
          method: 'POST',
          cancelationPrefix: entityName,
        })
      },
    },
    {
      actionName: 'move',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(`/${apiUrl}/${payload.id}/move/`, payload.payload, {
          method: 'POST',
          cancelationPrefix: entityName,
        }),
    },
    {
      actionName: 'test_autocharge',
      handler: ({ apiFetch, apiUrl, payload }) =>
        apiFetch(
          `/${apiUrl}/${payload}/test_autocharge/`,
          {},
          {
            method: 'POST',
            cancelationPrefix: entityName,
          },
        ),
    },
  ],
})

export default propertyBundle
