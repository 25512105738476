import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import AuditLogTab from '@portal/UI/components/AuditLogTab'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {string} [props.maxWidth]
 * @param {Function} [props.onClose]
 * @param {boolean} props.open
 * @param {string} props.smokeFeedbackId
 * @param {string} props.tz
 */
export default function AuditLogModal({
  open,
  label,
  onClose = () => {},
  maxWidth = 'lg',
  smokeFeedbackId,
  tz,
}) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      PaperProps={{ sx: { minHeight: '700px' } }}
    >
      <DialogTitle sx={{ fontSize: '1.7rem' }}>{label}</DialogTitle>
      <DialogContent>
        <AuditLogTab
          excludeDateFilter
          objectId={smokeFeedbackId}
          filterDrawOpen={false}
          tz={tz}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
