import { Stack, Typography } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {React.ElementType} props.Icon
 * @param {string} [props.color]
 * @param {string} props.text
 */
export default function LineCheck({ Icon, color = 'success', text, ...props }) {
  return (
    <Stack direction="row" alignItems="center" spacing={1} {...props}>
      <Icon color={color} />
      <Typography variant="h5">{text}</Typography>
    </Stack>
  )
}
