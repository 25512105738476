import { useEffect, useState } from 'react'

import { uniq } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { List } from '@mui/material'

import * as yup from 'yup'

import { getIcon } from '@common/icons'
import FormDialog from '@portal/UI/components/FormDialog'

import PermissionsAccordion from './PermissionsAccordion'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} props.instance.name
 * @param {string[]} [props.instance.permissions]
 * @param {Object} [props.role]
 * @param {string} props.role.id
 * @param {string} props.role.name
 * @param {string} props.role.level
 * @param {string} props.role.roleLevel
 * @param {string} [props.role.organization]
 * @param {string} [props.role.account]
 */
export default function PermissionForm({ open, onClose, instance = {}, role = {} }) {
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const {
    doRoleSave,
    doMarkRolesListAsOutdated,
    systemPermissionCategories,
    systemPermissions,
    isAtLeastAdmin,
    systemRoleLevels,
  } = useConnect(
    'doRoleSave',
    'doMarkRolesListAsOutdated',
    'selectSystemPermissionCategories',
    'selectSystemPermissions',
    'selectIsAtLeastAdmin',
    'selectSystemRoleLevels',
  )

  const categoryIconMapping = {
    'Account Management': 'folder',
    'Billing and Subscription': 'document',
    'User Management': 'people',
    'Property Management': 'door',
    'Sensor Management': 'device',
    'Notifications and Alerts': 'notification',
    Reservations: 'bed',
    Integrations: 'integration',
    Smoke: 'folder',
  }

  const getRoleLevel = () =>
    systemRoleLevels.find((l) => l.name === role?.roleLevel)?.value

  const accordionItems = systemPermissionCategories.map((category) => ({
    id: category.id,
    primaryText: category.name,
    icon: getIcon(categoryIconMapping[category.name]),
    subitems: systemPermissions
      .filter((p) => p.level >= getRoleLevel())
      .filter((p) => p.category === category.id)
      .map((p) => ({
        id: p.id,
        label: p.name,
        description: p.description,
        permissions: [],
        checked: selectedPermissions.includes(p.id),
      })),
  }))

  const addPermissions = (ids) =>
    setSelectedPermissions(uniq([...selectedPermissions, ...ids]))

  const removePermissions = (ids) =>
    setSelectedPermissions((selected) => selected.filter((p) => !ids.includes(p)))

  const handleSave = async () => {
    const res = await doRoleSave({
      id: role.id,
      name: role.name,
      organization: role.organization,
      permissions: selectedPermissions,
      account: role.account,
    })
    if (res?.id) doMarkRolesListAsOutdated()
    return res
  }

  useEffect(() => setSelectedPermissions(instance?.permissions ?? []), [open])

  return (
    <FormDialog
      label="Edit Permissions"
      open={open}
      onSave={handleSave}
      onClose={onClose}
      initialValues={{}}
      validationSchema={yup.object().shape({})}
    >
      <List sx={{ '& .MuiListItemIcon-root': { minWidth: '40px' } }}>
        {accordionItems.map((item) => (
          <PermissionsAccordion
            key={item.primaryText}
            icon={item.icon}
            disabled={!isAtLeastAdmin}
            primaryText={item.primaryText}
            secondaryText={item.secondaryText}
            subitems={item.subitems}
            onAddPermissions={addPermissions}
            onRemovePermissions={removePermissions}
          />
        ))}
      </List>
    </FormDialog>
  )
}
