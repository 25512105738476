import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, Card, Typography } from '@mui/material'

import { StaticMultiSelect } from '@common/components/Selects'

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  border: '1px solid black',
  borderRadius: 0.5,
  alignItems: 'center',
  justifyContent: 'center',
  p: 1,
  gap: 0.5,
}

const statusTextStyle = {
  variant: 'body2',
  whiteSpace: 'pre-line',
  textAlign: 'center',
}

export default function CompanyScoreboard() {
  const { allOrganizations, doOrganizationListFetchAll } = useConnect(
    'selectAllOrganizations',
    'doOrganizationListFetchAll',
  )

  const [selectedOrgs, setSelectedOrgs] = useState(null)

  useEffect(() => {
    doOrganizationListFetchAll({ active: true })
  }, [])

  useEffect(() => {
    if (!selectedOrgs && allOrganizations) {
      setSelectedOrgs(allOrganizations)
    }
  }, [allOrganizations])

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <StaticMultiSelect
          required
          onlyButton
          dynamicWidth
          disableSaveOnClear
          size="small"
          label="Organizations"
          sortByFields={['deviceCount']}
          sortOrder="desc"
          onSave={() => {
            // TODO
          }}
          onOpen={() => doOrganizationListFetchAll({ active: true })}
          onChange={(orgs) => setSelectedOrgs(orgs)}
          options={allOrganizations}
          value={selectedOrgs ?? []}
          sx={{ minWidth: 434, maxWidth: 500 }}
          limitTags={3}
        />
      </Box>
      {/* Data Section */}
      <Box display="flex" flexDirection="row" width={1} gap={2}>
        {/* Left Column */}
        <Box display="flex" flexDirection="column" sx={{ width: '55%', gap: 1 }}>
          <Box>
            <Card sx={{ ...cardStyle, gap: 0 }}>
              <Typography>107 Hotels Contracted</Typography>
              <Typography>61 Hotels Live / 15712 Rooms Live</Typography>
            </Card>
          </Box>
          <Box display="flex" flexDirection="row" sx={{ gap: 1 }}>
            <Box display="flex" flexDirection="row" gap={1} sx={{ width: '60%' }}>
              <Box display="flex" flexDirection="column" gap={1} sx={{ width: 1 / 2 }}>
                <Card sx={cardStyle}>
                  <Typography variant="h4">41</Typography>
                  <Typography variant="body2">Highgate Live Hotels</Typography>
                </Card>
                <Card sx={cardStyle}>
                  <Typography variant="h4">11,733</Typography>
                  <Typography variant="body2">Highgate Live Rooms</Typography>
                </Card>
              </Box>
              <Box display="flex" flexDirection="column" gap={1} sx={{ width: 1 / 2 }}>
                <Card sx={cardStyle}>
                  <Typography variant="h4">20</Typography>
                  <Typography variant="body2">Non-Highgate Live Hotels</Typography>
                </Card>
                <Card sx={cardStyle}>
                  <Typography variant="h4">3,979</Typography>
                  <Typography variant="body2">Non-Highgate Live Hotels</Typography>
                </Card>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" sx={{ width: '40%', gap: 2 }}>
              <Card sx={{ ...cardStyle, backgroundColor: 'grey.200' }}>
                <Typography {...statusTextStyle}>(6) Installed - Going live</Typography>
              </Card>
              <Card sx={{ ...cardStyle, backgroundColor: 'grey.200' }}>
                <Typography {...statusTextStyle}>
                  (4) Contracted - Installing next 30
                </Typography>
              </Card>
              <Card sx={{ ...cardStyle, backgroundColor: 'grey.200' }}>
                <Typography {...statusTextStyle}>
                  (10) Contracted - Installing 30+
                </Typography>
              </Card>
              <Card sx={{ ...cardStyle, backgroundColor: 'grey.200' }}>
                <Typography {...statusTextStyle}>
                  (26) Contracted - On Hold{`\n`}e.g., Blocked by brand
                </Typography>
              </Card>
            </Box>
          </Box>
        </Box>

        {/* Right Column */}
        <Box display="flex" flexDirection="column" sx={{ width: '45%', gap: 1 }}>
          <Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
            <Card sx={{ ...cardStyle, gap: 0 }}>
              <Typography>Q4</Typography>
              <Typography>Results</Typography>
            </Card>
            <Box display="flex" flexDirection="row" gap={1}>
              <Box display="flex" flexDirection="column" gap={1} sx={{ width: 1 / 2 }}>
                <Card sx={cardStyle}>
                  <Typography variant="h4">59%</Typography>
                  <Typography variant="body2">Charge Rate % HVT</Typography>
                  <Typography variant="caption" fontStyle="italic">
                    Excluding Select Service
                  </Typography>
                </Card>
                <Card sx={cardStyle}>
                  <Typography variant="h4">$758k</Typography>
                  <Typography variant="body2">HVT $ Net Charges</Typography>
                  <Typography variant="caption" fontStyle="italic">
                    Excluding Select Service
                  </Typography>
                </Card>
              </Box>
              <Box display="flex" flexDirection="column" gap={1} sx={{ width: 1 / 2 }}>
                <Card sx={cardStyle}>
                  <Typography variant="h4">52.4%</Typography>
                  <Typography variant="body2">Non-Highgate Charge Rate %</Typography>
                </Card>
                <Card sx={cardStyle}>
                  <Typography variant="h4">$53k</Typography>
                  <Typography variant="body2">Non-Highgate $ Net Charges</Typography>
                </Card>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Charts Section */}
      <Box display="flex" flexDirection="row" gap={1.5} mt={6}>
        <Card
          sx={{
            ...cardStyle,
            width: 1,
            height: 400,
            alignItems: 'start',
          }}
        >
          <Typography variant="h6">
            Total Non-Highgate Live Rooms by Hotel Group
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: 1,
              width: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography textAlign="center">Chart...</Typography>
          </Box>
        </Card>
        <Card sx={{ ...cardStyle, width: 1, height: 400, alignItems: 'start' }}>
          <Typography variant="h6">
            Total Rooms Installed, Highgatea and Rest
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: 1,
              width: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography textAlign="center">Chart...</Typography>
          </Box>
        </Card>
        <Card sx={{ ...cardStyle, width: 1, height: 400, alignItems: 'start' }}>
          <Typography variant="h6">
            Total Net Charges for All Live Hotels 2023
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: 1,
              width: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography textAlign="center">Chart...</Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}
