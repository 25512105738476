import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Circle } from '@mui/icons-material'
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material'

import { titleize } from 'inflection'

import {
  Breadcrumbs,
  ErrorComponent,
  ListTextTooltipWrapper,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors, useQueryFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import SmokeProfilePreviewDialog from '@portal/UI/components/SmokeProfilePreviewDialog'

import SmokeProfileForm from './SmokeProfileForm'
import smokeProfilesUrls from './urls'

export default function SmokeProfiles() {
  const [schema, setSchema] = useState(null)

  const [previewData, setPreviewData] = useState(null)
  const [profileToEdit, setProfileToEdit] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    doSmokeProfileListClearParams,
    doSmokeProfileListSetFilter,
    doSmokeProfileListSetPage,
    doSmokeProfileListSetPageSize,
    doSmokeProfileListSetOrdering,
    doMarkSmokeProfileListAsOutdated,
    doSmokeProfileListBulkArchive,
    doSmokeProfileListBulkUnarchive,
    doSmokeProfileListBulkPreview,
    doSmokeProfileSchemaFetch,
    smokeProfileList,
    smokeProfileListRaw: { ordering = [] },
    smokeProfileListIsLoading,
    smokeProfileListApiParams,
    systemSmokeProfileStatuses,
    systemSmokeModelKeys,
    isSuperuser,
    queryObject,
    doShowSnackbar,
    doUpdateQuery,
    doUpdateUrl,
  } = useConnect(
    'doSmokeProfileListClearParams',
    'doSmokeProfileListSetFilter',
    'doSmokeProfileListSetPage',
    'doSmokeProfileListSetPageSize',
    'doSmokeProfileListSetOrdering',
    'doMarkSmokeProfileListAsOutdated',
    'doSmokeProfileListBulkArchive',
    'doSmokeProfileListBulkUnarchive',
    'doSmokeProfileListBulkPreview',
    'doSmokeProfileSchemaFetch',
    'selectSmokeProfileList',
    'selectSmokeProfileListRaw',
    'selectSmokeProfileListIsLoading',
    'selectSmokeProfileListApiParams',
    'selectSystemSmokeProfileStatuses',
    'selectSystemSmokeModelKeys',
    'selectIsSuperuser',
    'selectQueryObject',
    'doShowSnackbar',
    'doUpdateQuery',
    'doUpdateUrl',
  )

  const isSmallScreen = useSmallScreen()

  useQueryFilter({
    query: queryObject,
    apiParams: smokeProfileListApiParams,
    setFilter: doSmokeProfileListSetFilter,
    setPageSize: doSmokeProfileListSetPageSize,
  })

  const fetchSchema = useCallback(async () => {
    try {
      const schemaResult = await doSmokeProfileSchemaFetch()
      setSchema(schemaResult)
    } catch (err) {
      setSchema({ error: err })
    }
  }, [])

  useEffect(() => {
    doSmokeProfileListClearParams()
    doUpdateQuery({
      status: systemSmokeProfileStatuses
        .map((status) => status.value)
        .filter((status) => status !== 'ARCHIVED' && status !== 'DEFAULT_ALERTING') // remove DEFAULT_ALERTING filter after system package is updated
        .join(','),
    })

    fetchSchema()
  }, [])

  const handleClear = () => {
    doSmokeProfileListClearParams()
    doSmokeProfileListSetFilter()
  }

  const handlePageChange = async (page) => {
    doSmokeProfileListSetPage(page)
  }

  const handlePageSizeChange = (size) => {
    doSmokeProfileListSetPageSize(size)
  }

  const handleRowClick = (row) => {
    const profileRoute = smokeProfilesUrls.entity.replace(':id', row.id)
    doUpdateUrl(profileRoute)
  }

  const archiveProfiles = useCallback(async (ids) => {
    try {
      await doSmokeProfileListBulkArchive({ ids })

      setSelectedIds([])
      doMarkSmokeProfileListAsOutdated()
      doShowSnackbar(
        `Profile${ids.length > 1 ? 's' : ''} successfully archived`,
        'success',
      )
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const handleBulkArchive = useCallback(async (ids) => {
    try {
      const previewResult = await doSmokeProfileListBulkPreview({
        ids,
        operation: 'archive',
      })
      setPreviewData({ type: 'archive', messages: previewResult, ids })
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const handleBulkUnarchive = useCallback(async (ids) => {
    try {
      await doSmokeProfileListBulkUnarchive({ ids })

      setSelectedIds([])
      doMarkSmokeProfileListAsOutdated()
      doShowSnackbar(
        `Profile${ids.length > 1 ? 's' : ''} successfully unarchived`,
        'success',
      )
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const statusesOptions = useMemo(
    () =>
      systemSmokeProfileStatuses
        .filter((item) => item.value !== 'DEFAULT_ALERTING') // remove this filter after system package is updated
        .map((item) => ({
          id: item.value,
          name: item.name,
        })),
    [systemSmokeProfileStatuses],
  )

  const buildParamColumnName = useCallback((field) => {
    const words = field.label.split(' ')
    if (words[0] === 'Filter') {
      return titleize(words.slice(1).join(' '))
    }
    return field.label
  }, [])

  const listActions = isSuperuser
    ? {
        bulk: [
          { action: handleBulkArchive, title: 'Archive' },
          { action: handleBulkUnarchive, title: 'Un-Archive' },
        ],
        create: () => setProfileToEdit({}),
        update: (profile) => setProfileToEdit(profile),
      }
    : null

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.65,
      minWidth: 200,
      renderCell: ({ row }) => {
        const name = `${row.modelKey} - ${row.name}`
        const href = smokeProfilesUrls.entity.replace(':id', row.id)
        const style = {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }

        return (
          <Stack direction="row" alignItems="center" gap={1} sx={style}>
            <Tooltip arrow title={titleize(row.status)}>
              <Circle
                color={row.status === 'ARCHIVED' ? 'error' : 'success'}
                sx={{ fontSize: 18 }}
              />
            </Tooltip>
            <Stack direction="column" sx={style}>
              <ListTextTooltipWrapper tooltip={name}>
                <Link href={href}>
                  <Typography variant="body2" sx={style}>
                    {name}
                  </Typography>
                </Link>
              </ListTextTooltipWrapper>
              <ListTextTooltipWrapper tooltip={row.description}>
                <Typography variant="caption" color="grey.700" sx={style}>
                  {row.description}
                </Typography>
              </ListTextTooltipWrapper>
            </Stack>
          </Stack>
        )
      },
    },
    {
      field: 'inUse',
      headerName: 'In Use',
      sortable: false,
      flex: 0.15,
      renderCell: ({ row }) => {
        const isActive =
          !isEmpty(row.alertingProperties) || !isEmpty(row.shadowProperties)
        const tooltipData = Object.entries({
          'Alerting Properties': row.alertingProperties.length,
          'Shadow Properties': row.shadowProperties.length,
        })
          .map(([key, value]) => `${key}: ${value}`)
          .join('\n')

        return (
          <Tooltip
            arrow
            placement="right-start"
            title={<div style={{ whiteSpace: 'pre-line' }}>{tooltipData}</div>}
          >
            <Typography variant="body2" fontSize={isSmallScreen ? 12 : 14}>
              {isActive ? 'Yes' : 'No'}
            </Typography>
          </Tooltip>
        )
      },
    },
    ...(schema
      ? schema
          .filter(
            (field) =>
              field.name.endsWith('Probability') || field.name.endsWith('Threshold'),
          )
          .map((field) => ({
            field: field.name,
            headerName: buildParamColumnName(field),
            sortable: false,
            flex: 0.3,
            valueGetter: (_, row) => row[field.name] ?? '--',
          }))
      : []),
  ]

  const MobileItemHeader = useCallback(({ row }) => {
    const name = `${row.modelKey} - ${row.name}`
    const href = smokeProfilesUrls.entity.replace(':id', row.id)
    return (
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ '&&': { mb: 1 } }}>
        <Circle
          color={row.status === 'ARCHIVED' ? 'error' : 'success'}
          sx={{ fontSize: 14 }}
        />
        <Stack direction="column">
          <ListTextTooltipWrapper tooltip={name}>
            <Link href={href}>
              <Typography variant="body2">{name}</Typography>
            </Link>
          </ListTextTooltipWrapper>
          <ListTextTooltipWrapper tooltip={row.description}>
            <Typography variant="caption" color="grey.700">
              {row.description}
            </Typography>
          </ListTextTooltipWrapper>
        </Stack>
      </Stack>
    )
  }, [])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['name'],
        header: <MobileItemHeader row={row} />,
      }),
    [columns],
  )

  if (!smokeProfileList.results || (schema && schema.error)) {
    return (
      <ErrorComponent
        title="Smoke Profiles"
        callback={() => {
          if (schema?.error) {
            fetchSchema()
          }
          handleClear()
        }}
      />
    )
  }

  return (
    <>
      <SmokeProfileForm
        open={!!profileToEdit}
        onClose={() => setProfileToEdit(null)}
        profileId={profileToEdit?.id}
      />
      <SmokeProfilePreviewDialog
        previewData={previewData}
        onConfirm={(data) => {
          if (data.type === 'archive') {
            archiveProfiles(data.ids)
          }
          setPreviewData(null)
        }}
        onCancel={() => setPreviewData(null)}
      />
      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: 'Smoke Profiles' }]}
        />
        <ListPageTitle
          title="Smoke Profiles"
          onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
          menuItems={
            isSmallScreen && isSuperuser
              ? [
                  { label: 'Add Smoke Profile', onClick: () => setProfileToEdit({}) },
                  ...(selectedIds?.length
                    ? [
                        { label: 'Archive', onClick: handleBulkArchive },
                        { label: 'Un-Archive', onClick: handleBulkUnarchive },
                      ]
                    : []),
                ]
              : null
          }
          mb={2}
        />
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={smokeProfileListIsLoading}
            apiParams={smokeProfileListApiParams}
            setApiParams={doUpdateQuery}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect
              label="Model"
              filterName="modelKey"
              options={systemSmokeModelKeys.map((model) => model.key)}
            />
            <StaticSelect
              multiple
              disableCloseOnSelect
              label="Status"
              filterName="status"
              options={statusesOptions}
            />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                queryDrivenSearch
                title="Smoke Profiles"
                loading={smokeProfileListIsLoading}
                actions={listActions}
                itemBuilder={MobileListItem}
                page={smokeProfileList.current || 1}
                pageChange={handlePageChange}
                pageSize={smokeProfileList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={smokeProfileList?.results}
                rowClick={handleRowClick}
                rowCount={smokeProfileList.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
              />
            ) : (
              <List
                columnsAutosize
                queryDrivenSearch
                title="Smoke Profiles"
                loading={smokeProfileListIsLoading}
                showAddButton={isSuperuser && !isSmallScreen}
                actions={listActions}
                columns={columns}
                page={smokeProfileList.current || 1}
                pageChange={handlePageChange}
                pageSize={smokeProfileList.pageSize}
                pageSizeChange={handlePageSizeChange}
                rows={smokeProfileList?.results}
                rowClick={handleRowClick}
                rowCount={smokeProfileList.count || 0}
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={setSelectedIds}
                sortChange={doSmokeProfileListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
